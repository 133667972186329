import styled, { css } from 'styled-components'

import { ImgLite } from '@avantstay/avantstay-ui'
import {
  _2XS_Bold,
  _3XS_Normal,
  _S_Medium,
  _XS_Bold,
  Button,
  mainColors,
  medias,
  rgba,
  semanticColors,
  zIndex,
} from '@avantstay/backoffice-core'

import { FilePreviewType } from '@new/__ui/UploadArea/_types'
import { FilePreviewMode } from '@new/__ui/UploadArea/FilePreview/_enums'

import { getFileSizeByType } from '../_utils'
import { ExtensionProps, FileUploadStatus, FileWrapperProps, ImageContainerProps } from './_types'

const DOC_HEIGHT_DESKTOP = '450px'
const DOC_HEIGHT_MOBILE = '225px'

const IMAGE_WIDTH = '80vw'
const IMAGE_HEIGHT = '90vh'

// View Mode Container
export const Container = styled.div<{ viewMode?: FilePreviewMode }>`
  ${({ viewMode }) =>
    viewMode === FilePreviewMode.list
      ? `
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 700px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${mainColors.neutralMedium};

    ${medias.MD_SCREEN`
      flex-direction: row;
    `}
  `
      : `
    margin-right: 10px;
  `}
`

// File Preview
export const DocumentPreviewContainer = styled.div<FileWrapperProps>`
  width: 100%;
  top: 0;
  position: relative;
`

export const FilePreviewContainer = styled.div<FileWrapperProps>`
  position: relative;
  min-width: ${({ filePreviewType, viewMode }) =>
    getFileSizeByType(filePreviewType, viewMode).width}px;
  height: ${({ filePreviewType, viewMode }) =>
    getFileSizeByType(filePreviewType, viewMode).height}px;

  cursor: pointer;

  ${({ filePreviewType }) =>
    filePreviewType === FilePreviewType.photoGallery &&
    `
    padding-top: 30px;
  `}
`

export const Image = styled(ImgLite)<{ $hasFlatBorder?: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 4px;

  ${({ $hasFlatBorder }) =>
    $hasFlatBorder &&
    `
    border-radius: 0 0 4px 4px;
  `}
`

export const DocumentPreview = styled(Image)`
  height: ${DOC_HEIGHT_MOBILE};
  max-height: ${DOC_HEIGHT_MOBILE};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  ${medias.MD_SCREEN`
    min-height: ${DOC_HEIGHT_DESKTOP};
  `}
`

export const Cover = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 96px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${_S_Medium};
  color: ${mainColors.white};
  padding: 2px 9px;
  background: ${mainColors.primaryMedium};
  border-radius: 2px 2px 0 0;
  transform: translate(-50%, 0);
`

export const MakeAsCoverButton = styled(Cover)`
  display: none;
  border: 1.5px solid ${mainColors.white};
  border-bottom: 0;
`

const getImagePlaceHolderBorder = ({ error, $isCover, warning }: ImageContainerProps) => {
  const resetImageBorderRadius = `${Image} {
    border-radius: 0;
  }`

  if (error) {
    return `
      border: 2px solid ${semanticColors.failureMedium};
      ${resetImageBorderRadius};
    `
  }

  if ($isCover) {
    return `
      border: 2px solid ${mainColors.primaryMedium};
      ${resetImageBorderRadius};
    `
  }

  if (warning) {
    return `
      border: 2px solid ${semanticColors.warningDark};
      ${resetImageBorderRadius};
    `
  }

  return ''
}

export const ImagePlaceholder = styled.div<ImageContainerProps>`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;

  ${({ $hasFlatBorder }) =>
    $hasFlatBorder &&
    `
    border-radius: 0 0 4px 4px;
  `}
  ${({ error, $isCover, warning }) => getImagePlaceHolderBorder({ error, $isCover, warning })}
  &:hover {
    ${MakeAsCoverButton} {
      display: flex;
    }
  }
`

export const ImageOverlay = styled.div<FileUploadStatus>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2px;

  ${({ warning }) =>
    warning &&
    `
    background: ${rgba(semanticColors.warningDark, 0.4)};
  `}

  ${({ error }) =>
    error &&
    `
    background: ${rgba(semanticColors.failureMedium, 0.4)};
  `}
`

export const DocumentPlaceholder = styled(ImagePlaceholder)`
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${mainColors.neutralLight};
  border-radius: 2px;
  height: ${DOC_HEIGHT_MOBILE};

  ${medias.MD_SCREEN`
    height: ${DOC_HEIGHT_DESKTOP};
  `}
`

export const FileContainer = styled(ImagePlaceholder)`
  position: relative;
  margin-bottom: 6px;
  border: 2px solid ${mainColors.neutralLight};
  border-radius: 2px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 42px;
  }
`

export const Extension = styled.div<ExtensionProps>`
  ${_XS_Bold};
  padding: 2px 7px;
  border-radius: 1px;
  position: absolute;
  background: ${({ background }) => background ?? mainColors.accentMedium};
  color: ${({ color }) => color ?? mainColors.white};
  left: 16px;
  top: 42px;
  font-weight: bold;
  text-transform: uppercase;
`

export const Error = styled.p`
  ${_2XS_Bold};
  color: ${mainColors.white};
`

// Upload Progress
export const ProgressContainer = styled.div`
  position: absolute;
  bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export const ProgressValue = styled.div`
  ${_3XS_Normal};
  color: ${mainColors.white};
  width: 25px;
  text-align: right;
`

export const CarouselContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: ${zIndex.MODAL};
  background-color: ${rgba(mainColors.primaryDark, 0.8)};
`

export const CarouselBackground = styled.div`
  position: relative;
  width: ${IMAGE_WIDTH};
  height: ${IMAGE_HEIGHT};
  background-color: ${mainColors.neutralMedium};
  border-radius: 2px;
`

export const ImagePreview = styled(ImgLite)`
  width: ${IMAGE_WIDTH};
  height: ${IMAGE_HEIGHT};
  background-size: contain;
  border-radius: 2px;
`

const carouselButton = css`
  padding: 12px;
  border: 2px solid ${mainColors.white};
  border-radius: 50%;
  background-color: ${mainColors.primaryDark};
  z-index: ${zIndex.PORTAL};
`

export const CloseCarouselButton = styled(Button.Flat)`
  position: absolute;
  top: 20px;
  right: 20px;
  ${carouselButton};

  svg {
    width: 15px;
    height: 15px;
    color: ${mainColors.white};
  }
`

export const CarouselArrowButton = styled(Button.Flat)<{ isPrevious?: boolean }>`
  position: absolute;
  top: 50%;
  ${({ isPrevious }) => (isPrevious ? 'left: 20px;' : 'right: 20px;')};
  ${carouselButton};

  svg {
    width: 15px;
    height: 15px;
    color: ${mainColors.white};
  }

  &:disabled {
    display: none;
    background-color: ${mainColors.neutralDark};
    border-color: ${mainColors.neutralDark};

    svg {
      color: ${mainColors.neutralExtraDark};
    }
  }
`

export const ActionWrapper = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${rgba(mainColors.primaryMedium, 0.5)};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 8px;
    height: 8px;
    color: ${mainColors.white};
  }
`
