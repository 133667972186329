import React, { Ref } from 'react'

function _IconPaperClip(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.40002 5.5999C4.40002 3.38738 5.91402 1.3999 8.00002 1.3999C10.086 1.3999 11.6 3.38738 11.6 5.5999V9.99937C11.6 10.3307 11.3314 10.5994 11 10.5994C10.6687 10.5994 10.4 10.3307 10.4 9.99937V5.5999C10.4 3.83597 9.22772 2.5999 8.00002 2.5999C6.77232 2.5999 5.60002 3.83597 5.60002 5.5999V11.5999C5.60002 12.7011 6.32461 13.3999 7.00002 13.3999C7.67544 13.3999 8.40002 12.7011 8.40002 11.5999V5.5999C8.40002 5.16146 8.12316 4.9999 8.00002 4.9999C7.87689 4.9999 7.60002 5.16146 7.60002 5.5999V10.7999C7.60002 11.1313 7.3314 11.3999 7.00002 11.3999C6.66865 11.3999 6.40002 11.1313 6.40002 10.7999V5.5999C6.40002 4.71287 7.01859 3.7999 8.00002 3.7999C8.98146 3.7999 9.60002 4.71287 9.60002 5.5999V11.5999C9.60002 13.1497 8.53375 14.5999 7.00002 14.5999C5.4663 14.5999 4.40002 13.1497 4.40002 11.5999V5.5999Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconPaperClip = React.forwardRef(_IconPaperClip)
