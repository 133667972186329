import { StayCycle } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { BadgeColors } from '../components/Badge'

export const badgeColorsByStayCycle = {
  [StayCycle.preStay]: BadgeColors.neutral,
  [StayCycle.inStay]: BadgeColors.accent,
  [StayCycle.postStay]: BadgeColors.success,
}

export const stayCycleLabel: Record<StayCycle, string> = {
  [StayCycle.preStay]: 'Pre-Stay',
  [StayCycle.inStay]: 'In-Stay',
  [StayCycle.postStay]: 'Post-Stay',
}
