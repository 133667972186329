import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconPlusSquare(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} ref={ref}>
      <g clipPath="url(#clip0_50899_163624)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.4375 2.5C2.91973 2.5 2.5 2.91973 2.5 3.4375V16.5625C2.5 17.0803 2.91973 17.5 3.4375 17.5H16.5625C17.0803 17.5 17.5 17.0803 17.5 16.5625V3.4375C17.5 2.91973 17.0803 2.5 16.5625 2.5H3.4375ZM0.625 3.4375C0.625 1.8842 1.8842 0.625 3.4375 0.625H16.5625C18.1158 0.625 19.375 1.8842 19.375 3.4375V16.5625C19.375 18.1158 18.1158 19.375 16.5625 19.375H3.4375C1.8842 19.375 0.625 18.1158 0.625 16.5625V3.4375ZM10 5.3125C10.5178 5.3125 10.9375 5.73223 10.9375 6.25V9.0625H13.75C14.2678 9.0625 14.6875 9.48223 14.6875 10C14.6875 10.5178 14.2678 10.9375 13.75 10.9375H10.9375V13.75C10.9375 14.2678 10.5178 14.6875 10 14.6875C9.48223 14.6875 9.0625 14.2678 9.0625 13.75V10.9375H6.25C5.73223 10.9375 5.3125 10.5178 5.3125 10C5.3125 9.48223 5.73223 9.0625 6.25 9.0625H9.0625V6.25C9.0625 5.73223 9.48223 5.3125 10 5.3125Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export const IconPlusSquare = forwardRef(_IconPlusSquare)
