// @ts-nocheck
import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { IconChevronDown, IconChevronUp } from '@avantstay/backoffice-vectors'

import { Badge } from '../Badge'
import * as S from './BadgeDropDown.styles'
import { BadgeDropDownProps, BadgeDropDownRefProps } from './BadgeDropDown.types'

export const BadgeDropDown = forwardRef<BadgeDropDownRefProps, BadgeDropDownProps>(
  ({ selectedOption, optionsList, onSelectOption }, ref) => {
    const [toggleDropDown, setToggleDropdown] = useState(false)

    const availableOptionsList = optionsList.filter(
      optionItem => !optionItem.value.includes(selectedOption.value),
    )

    useImperativeHandle(ref, () => ({
      closeDropDown: () => setToggleDropdown(false),
    }))

    return (
      <S.Wrapper>
        <Badge
          badgeColor={selectedOption.color}
          onClick={() => setToggleDropdown(!toggleDropDown)}
          interactive
        >
          {selectedOption.label} {toggleDropDown ? <IconChevronUp /> : <IconChevronDown />}
        </Badge>
        <S.SimpleFloatingContainer
          show={toggleDropDown}
          onClickOut={() => setToggleDropdown(false)}
          margin="6px 0 0"
        >
          {availableOptionsList.map(item => (
            <S.SimpleItem key={item.value} onClick={() => onSelectOption(item.value)}>
              <S.Dot color={item.color?.color} />
              {item.label}
            </S.SimpleItem>
          ))}
        </S.SimpleFloatingContainer>
      </S.Wrapper>
    )
  },
)
