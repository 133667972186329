import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'

// eslint-disable-next-line import/no-cycle
import { CreatePropertyFormValues } from '@new/domains/properties/screens/viewMode/components/CreatePropertyAttachmentBar/PropertyInfoForm/PropertyInfoForm.utils'

export default {
  async getCityList(query: string) {
    return arriereBackoffice.queries.cities({
      __alias: 'getCityList',
      __args: {
        name: query,
      },
      id: true,
      name: true,
      state: {
        name: true,
      },
    })
  },

  async createHome(createHomesForm: CreatePropertyFormValues) {
    if (createHomesForm === undefined) {
      return undefined
    }

    return arriereBackoffice.mutations.createHome({
      __args: {
        ...createHomesForm,
      },
      id: true,
      name: true,
    })
  },
}
