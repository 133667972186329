import { css } from 'styled-components'

export const regularFont = css`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
`

// 5XL - 40px

export const _5XL_Bold = css`
  ${regularFont};
  font-weight: bold;
  font-size: 40px;
`

// 4XL - 28px

export const _4XL_Bold = css`
  ${regularFont};
  font-size: 28px;
  font-weight: bold;
`

export const _4XL_Light = css`
  ${_4XL_Bold};
  font-weight: 300;
`

// 3XL - 26px

export const _3XL_Normal = css`
  ${regularFont};
  font-size: 26px;
  font-weight: normal;
`

export const _3XL_Bold = css`
  ${_3XL_Normal};
  font-weight: bold;
`

// 2XL - 22px
export const _2XL_Normal = css`
  ${regularFont};
  font-size: 22px;
  font-weight: normal;
`

export const _2XL_Bold = css`
  ${_2XL_Normal};
  font-weight: bold;
`

export const _2XL_Light = css`
  ${_2XL_Normal};
  font-weight: 300;
`

// XL - 18px

export const _XL_Normal = css`
  ${regularFont};
  font-size: 18px;
  font-weight: normal;
`

export const _XL_Medium = css`
  ${_XL_Normal};
  font-weight: 500;
`

export const _XL_Bold = css`
  ${_XL_Normal};
  font-weight: bold;
`

export const _XL_Light = css`
  ${_XL_Normal};
  font-weight: 300;
`

// L - 16px

export const _L_Normal = css`
  ${regularFont};
  font-size: 16px;
  font-weight: normal;
`

export const _L_Normal_Crossed = css`
  ${_L_Normal};
  text-decoration-line: line-through;
`

export const _L_Medium = css`
  ${_L_Normal};
  font-weight: 500;
`

export const _L_Bold = css`
  ${_L_Normal};
  font-weight: bold;
`

export const _L_Light = css`
  ${_L_Normal};
  font-weight: 300;
`

export const _L_Light_Crossed = css`
  ${_L_Light};
  text-decoration-line: line-through;
`

// M - 14px

export const _M_Normal = css`
  ${regularFont};
  font-size: 14px;
  font-weight: normal;
`

export const _M_Bold = css`
  ${_M_Normal};
  font-weight: bold;
`

export const _M_Bold_Crossed = css`
  ${_M_Bold};
  text-decoration-line: line-through;
`

export const _M_Medium = css`
  ${_M_Normal};
  font-weight: 500;
`

export const _M_Medium_Crossed = css`
  ${_M_Medium};
  text-decoration-line: line-through;
`

export const _M_Medium_Underline = css`
  ${_M_Medium};
  text-decoration-line: underline;
`

export const _M_Normal_Crossed = css`
  ${_M_Normal};
  text-decoration-line: line-through;
`

export const _M_Normal_Italic = css`
  ${_M_Normal};
  font-style: italic;
`

export const _M_Light = css`
  ${_M_Normal};
  font-weight: 300;
`

export const _M_Light_Underline = css`
  ${_M_Light};
  text-decoration-line: underline;
`

export const _M_Light_Crossed = css`
  ${_M_Light};
  text-decoration-line: line-through;
`

// S - 13px

export const _S_Normal = css`
  ${regularFont};
  font-size: 13px;
  font-weight: normal;
`

export const _S_Normal_Crossed = css`
  ${_S_Normal};
  text-decoration-line: line-through;
`

export const _S_Medium = css`
  ${_S_Normal};
  font-weight: 500;
`

export const _S_Bold = css`
  ${_S_Normal};
  font-weight: 700;
`

export const _S_Light = css`
  ${_S_Normal};
  font-weight: 300;
`

// XS - 12px

export const _XS_Normal = css`
  ${regularFont};
  font-size: 12px;
  font-weight: normal;
`

export const _XS_Bold = css`
  ${_XS_Normal};
  font-weight: bold;
`

export const _XS_Medium = css`
  ${_XS_Normal};
  font-weight: 500;
`

export const _XS_Medium_Underline = css`
  ${_XS_Medium};
  text-decoration-line: underline;
`

export const _XS_Light = css`
  ${_XS_Normal};
  font-weight: 300;
`

// 2XS - 11px

export const _2XS_Normal = css`
  ${regularFont};
  font-size: 11px;
  font-weight: normal;
`

export const _2XS_Medium = css`
  ${regularFont};
  font-size: 11px;
  font-weight: 500;
`

export const _2XS_Bold = css`
  ${_2XS_Normal};
  font-weight: bold;
`

export const _2XS_Normal_Crossed = css`
  ${_2XS_Normal};
  text-decoration-line: line-through;
`

export const _2XS_Light = css`
  ${_2XS_Normal};
  font-weight: 300;
`

// 3XS - 10px

export const _3XS_Normal = css`
  ${regularFont};
  font-size: 10px;
  font-weight: normal;
`

export const _3XS_Bold = css`
  ${_3XS_Normal};
  font-weight: bold;
`

export const _3XS_Medium = css`
  ${_3XS_Normal};
  font-weight: 500;
`
