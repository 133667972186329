import React from 'react'

import { formatMoney, moneyMask, TextField } from '@avantstay/backoffice-core'

// eslint-disable-next-line import/no-cycle
import { jsonParseCustomFieldValue } from '@utils/customFields'

import {
  CustomFieldProps,
  CustomFieldTypeReturn,
  IInputProps,
  IMountComponentProps,
} from '../_types'
import * as S from './types.styles'

function Component({ customField: { value } }: IMountComponentProps) {
  return (
    <S.Text>
      {typeof value === 'string' && value ? formatMoney(JSON.parse(value || '')) : value}
    </S.Text>
  )
}

function ComponentWriteMode({
  inputProps,
  error,
  warning,
  customField,
  isReadOnly,
  disabled,
}: IMountComponentProps) {
  if (isReadOnly) return <Component customField={customField} />

  return (
    <TextField.Masked
      {...(inputProps as IInputProps)}
      mask={moneyMask}
      error={error}
      warning={warning}
      disabled={disabled}
    />
  )
}

const parseValueToShow = (customField: CustomFieldProps) => {
  const value = jsonParseCustomFieldValue(customField)

  if (value !== 0 && !value) return null
  if (typeof value === 'number') return value

  return parseFloat(value.replace(/[$,]/g, ''))
}

const parseValueToSave = (customField: CustomFieldProps) => {
  const value = jsonParseCustomFieldValue(customField)
  if (value !== 0 && !value) return null
  if (!customField.value) return null

  if (typeof customField.value === 'number') return JSON.stringify(customField.value)

  return JSON.stringify(parseFloat(customField.value?.replace(/[$,]/g, '')))
}

export default {
  read: { Component },
  write: { Component: ComponentWriteMode },
  parseValueToShow,
  parseValueToSave,
} as CustomFieldTypeReturn
