import styled from 'styled-components'

import { mainColors } from '../../styles/colors'

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: max-content;

  svg {
    color: ${mainColors.neutralExtraDark};
    width: 16px;
    outline: none;

    &:hover {
      cursor: pointer;
      color: ${mainColors.neutralExtremelyDark2};
    }
  }
`
