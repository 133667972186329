import styled from 'styled-components'

import { IconCalendar } from '@avantstay/backoffice-vectors'

import { _M_Medium, _M_Normal, _XS_Medium, mainColors, medias, zIndex } from '../../styles'
import { Badge } from '../Badge'
import { DefaultLink as DefaultLinkComponent } from '../Link'

export const ReviewTimelineContainer = styled.div`
  position: relative;
  padding: 20px;

  ${medias.MD_SCREEN`
    padding: 0 40px;

      &:not(:first-child) {
        padding-top: 40px;
      }

      &:last-child {
        padding-bottom: 40px;
      }
  `};
`

export const TimeLine = styled.div`
  position: absolute;
  width: 1px;
  top: -20px;
  left: 161px;
  bottom: 20px;
  border-left: 1px dashed ${mainColors.neutralDark};
`

export const ReviewHeader = styled.div`
  display: grid;
  grid-template-columns: 90px 36px 1fr;
  gap: 10px;
  align-items: center;
  margin-left: 162px;
`

export const BadgeStyled = styled(Badge)`
  max-height: 20px;
`

export const CalendarIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: ${mainColors.neutralLight};
  border-radius: 50%;
`

export const IconCalendarStyled = styled(IconCalendar)`
  height: 20px;
  width: 20px;
  color: ${mainColors.neutralExtraDark};
`

export const BookingInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const GuestName = styled.p`
  ${_M_Medium};
`

export const DefaultLink = styled(DefaultLinkComponent)`
  ${_XS_Medium};
`

export const ReviewContainer = styled.div`
  display: flex;
  gap: 24px;
`

export const TimelineDot = styled.div`
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  margin-top: 32px;
  margin-right: 10px;
  background-color: ${mainColors.accentMedium};
  border: 4px solid ${mainColors.accentExtremelyLight};
  border-radius: 50%;
  z-index: ${zIndex.DEFAULT};
`

export const CreatedAtDate = styled.p`
  ${_M_Normal};
  color: ${mainColors.neutralExtremelyDark};
  line-height: 150%;
  max-width: 90px;
  margin-top: 16px;
`
