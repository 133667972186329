import styled, { css } from 'styled-components'

import { mainColors, semanticColors, shadowColors } from '../../../styles/colors'
import { rgba } from '../../../utils/cssUtils'

export const Container = styled.div<{ isFocused: boolean }>`
  border: 1px solid ${({ isFocused }) => (isFocused ? mainColors.neutralMedium : 'transparent')};
  ${({ isFocused }) => isFocused && `box-shadow: 0px 4px 16px ${rgba(shadowColors.blur, 0.4)};`}
  border-radius: 2px;
  display: flex;
  width: fit-content;
`

const buttonStyles = css`
  background-color: transparent;
  padding: 10px 19px;
  height: 44px;
  display: flex;
  align-items: center;

  & + button,
  & + div {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-left: 1px solid ${mainColors.neutralDark};
      height: 50%;
    }
  }
`

export const TriggerButton = styled.button`
  ${buttonStyles};

  &:hover,
  &:focus,
  &:active {
    color: ${mainColors.accentMedium};
  }
`

export const ActionGroup = styled.div<{ isFocused: boolean }>`
  display: flex;
  width: fit-content;
  opacity: ${({ isFocused }) => (isFocused ? '1' : '0')};
`

interface ActionButtonProps {
  isDanger: boolean
}

export const ActionButton = styled.button<ActionButtonProps>`
  ${buttonStyles};
  color: ${mainColors.neutralExtraDark};

  &:hover,
  &:focus,
  &:active {
    color: ${({ isDanger }) => (isDanger ? semanticColors.failureMedium : mainColors.neutralDark)};
  }
`
