import styled, { css } from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _L_Bold, _M_Bold, _M_Normal, _S_Normal } from '../../styles/typography'
import { ellipsis } from '../../styles/utils'
import { HomeInfoState } from './HomeInfo.types'

const minWidth = css`
  min-width: 50px;
`

export const HomeInfoRoot = styled.div<{ maxWidth?: number }>`
  flex-grow: 1;
  align-items: center;
  display: flex;
  ${minWidth};
  ${props => (props.maxWidth ? `max-width: ${props.maxWidth}px` : '')};
`

export const HomeTitleAndRegion = styled.div`
  padding: 0 10px;
  ${minWidth};
  line-height: 1.4;
`

export const HomeTitle = styled.div<{ state?: HomeInfoState; isLarge?: boolean }>`
  display: flex;
  align-items: center;
  ${props => (props.isLarge ? _L_Bold : _M_Bold)};
  ${ellipsis};
  color: ${props =>
    props.state === HomeInfoState.Active ? mainColors.black : mainColors.neutralExtremelyDark};
`

export const HomeRegion = styled.div<{ state?: HomeInfoState; isLarge?: boolean }>`
  ${props => (props.isLarge ? _M_Normal : _S_Normal)};
  ${ellipsis};
  color: ${props =>
    props.state === HomeInfoState.Active
      ? mainColors.neutralExtremelyDark
      : mainColors.neutralExtraDark};
`

export const HomeUrl = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  margin-left: 8px;
  color: ${mainColors.neutralExtraDark};

  &:hover {
    cursor: pointer;
    color: ${mainColors.neutralExtremelyDark};
  }
`
