import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconZoom(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 22 20" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.44412 2.33203C4.62133 2.33203 2.33301 4.62035 2.33301 7.44314C2.33301 10.2659 4.62133 12.5543 7.44412 12.5543C8.61518 12.5543 9.69426 12.1604 10.5561 11.498L12.5283 13.4701C12.7886 13.7305 13.2107 13.7305 13.4711 13.4701C13.7314 13.2098 13.7314 12.7876 13.4711 12.5273L11.4989 10.5551C12.1614 9.69328 12.5552 8.61421 12.5552 7.44314C12.5552 4.62035 10.2669 2.33203 7.44412 2.33203ZM10.1704 10.0583C10.8216 9.37959 11.2219 8.45811 11.2219 7.44314C11.2219 5.35673 9.53053 3.66536 7.44412 3.66536C5.35771 3.66536 3.66634 5.35673 3.66634 7.44314C3.66634 9.52955 5.35771 11.2209 7.44412 11.2209C8.45908 11.2209 9.38056 10.8207 10.0593 10.1694C10.0754 10.149 10.0928 10.1294 10.1116 10.1106C10.1304 10.0918 10.15 10.0744 10.1704 10.0583ZM7.46634 4.9987C7.83453 4.9987 8.13301 5.29717 8.13301 5.66536V6.66536H9.13301C9.5012 6.66536 9.79967 6.96384 9.79967 7.33203C9.79967 7.70022 9.5012 7.9987 9.13301 7.9987H8.13301V8.9987C8.13301 9.36689 7.83453 9.66537 7.46634 9.66537C7.09815 9.66537 6.79967 9.36689 6.79967 8.9987V7.9987H5.79967C5.43148 7.9987 5.13301 7.70022 5.13301 7.33203C5.13301 6.96384 5.43148 6.66536 5.79967 6.66536H6.79967V5.66536C6.79967 5.29717 7.09815 4.9987 7.46634 4.9987Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconZoom = forwardRef(_IconZoom)
