import React, { Ref } from 'react'

function _IconEdit(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.003 5.70078C16.8327 5.70078 16.664 5.73433 16.5067 5.79951C16.3493 5.86469 16.2063 5.96023 16.0859 6.08067L6.68557 15.481L5.99772 18.0031L8.51985 17.3153L17.9202 7.91495C18.0406 7.79451 18.1362 7.65153 18.2013 7.49416C18.2665 7.3368 18.3001 7.16814 18.3001 6.99781C18.3001 6.82748 18.2665 6.65882 18.2013 6.50146C18.1362 6.3441 18.0406 6.20111 17.9202 6.08067C17.7997 5.96023 17.6567 5.86469 17.4994 5.79951C17.342 5.73433 17.1734 5.70078 17.003 5.70078ZM15.9709 4.50608C16.2981 4.37054 16.6489 4.30078 17.003 4.30078C17.3572 4.30078 17.7079 4.37054 18.0351 4.50608C18.3624 4.64162 18.6597 4.84028 18.9101 5.09072C19.1606 5.34117 19.3592 5.63848 19.4948 5.9657C19.6303 6.29292 19.7001 6.64363 19.7001 6.99781C19.7001 7.35199 19.6303 7.7027 19.4948 8.02992C19.3592 8.35714 19.1606 8.65446 18.9101 8.9049L9.37835 18.4367C9.29221 18.5228 9.18509 18.585 9.06756 18.617L5.18425 19.6761C4.9419 19.7422 4.68272 19.6734 4.50509 19.4958C4.32747 19.3181 4.25864 19.0589 4.32473 18.8166L5.38382 14.9333C5.41587 14.8158 5.47804 14.7086 5.56418 14.6225L15.0959 5.09072C15.3464 4.84028 15.6437 4.64162 15.9709 4.50608Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconEdit = React.forwardRef(_IconEdit)
