import React, { FC, useState } from 'react'

import { FlexContainer } from '../FlexContainer'
import { InfoMessage } from './InfoMessage'
import * as S from './InfoMessage.styles'
import { InfoMessageCollapsibleProps } from './InfoMessage.types'

export const InfoMessageCollapse: FC<InfoMessageCollapsibleProps> = ({
  className,
  collapsedContent,
  margin,
  message,
  messageType,
  showIcon = true,
  collapseInitialValue = false,
}) => {
  const [isOpen, setIsOpen] = useState(collapseInitialValue)

  const renderMessageComponent = () => (
    <FlexContainer noWrap>
      {message} <S.IconChevron scale={isOpen ? -1 : 1} />
    </FlexContainer>
  )

  return (
    <S.CollapsibleContainer margin={margin} type={messageType} className={className}>
      <div onClick={() => setIsOpen(!isOpen)}>
        <InfoMessage
          message={renderMessageComponent()}
          messageType={messageType}
          showIcon={showIcon}
        />
      </div>
      {isOpen ? (
        <S.CollapsibleContent type={messageType}>{collapsedContent}</S.CollapsibleContent>
      ) : null}
    </S.CollapsibleContainer>
  )
}
