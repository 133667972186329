import React, { Ref } from 'react'

function _IconTaxesAndFees(
  props: React.SVGProps<SVGSVGElement>,
  ref?: Ref<SVGSVGElement>,
): React.ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 5.41667C2.5 3.80584 3.80584 2.5 5.41667 2.5C7.0275 2.5 8.33333 3.80584 8.33333 5.41667C8.33333 7.0275 7.0275 8.33333 5.41667 8.33333C3.80584 8.33333 2.5 7.0275 2.5 5.41667ZM5.41667 4.16667C4.72631 4.16667 4.16667 4.72631 4.16667 5.41667C4.16667 6.10702 4.72631 6.66667 5.41667 6.66667C6.10702 6.66667 6.66667 6.10702 6.66667 5.41667C6.66667 4.72631 6.10702 4.16667 5.41667 4.16667ZM16.4226 3.57741C16.748 3.90285 16.748 4.43049 16.4226 4.75592L4.75592 16.4226C4.43049 16.748 3.90285 16.748 3.57741 16.4226C3.25197 16.0972 3.25197 15.5695 3.57741 15.2441L15.2441 3.57741C15.5695 3.25197 16.0972 3.25197 16.4226 3.57741ZM16.0154 10.2083C15.9229 9.84891 15.5966 9.58333 15.2083 9.58333C14.8073 9.58333 14.4724 9.86664 14.3929 10.244C13.6235 10.3933 13.0021 10.9963 12.8488 11.7896C12.6714 12.7072 13.1694 13.6217 14.0365 13.9706L15.757 14.6591C15.8833 14.7102 15.9558 14.8436 15.9299 14.9774C15.904 15.1113 15.7866 15.2081 15.6503 15.2083H15.2086H15.2083H13.9584C13.4981 15.2083 13.125 15.5814 13.125 16.0417C13.125 16.5019 13.4981 16.875 13.9584 16.875H14.4013C14.4938 17.2344 14.82 17.5 15.2083 17.5C15.6095 17.5 15.9444 17.2166 16.0238 16.8391C16.7926 16.6895 17.413 16.0866 17.5663 15.2937C17.7436 14.3761 17.2456 13.4616 16.3786 13.1127L14.6581 12.4242C14.5318 12.3731 14.4593 12.2397 14.4851 12.1059C14.511 11.972 14.6282 11.8752 14.7646 11.875H16.4584C16.9186 11.875 17.2917 11.5019 17.2917 11.0417C17.2917 10.5814 16.9186 10.2083 16.4584 10.2083H16.0154Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconTaxesAndFees = React.forwardRef(_IconTaxesAndFees)
