import {
  mainColors,
  riskChannelsColors,
  semanticColors,
  tapeChartColors,
} from '@avantstay/backoffice-core'

import { PeriodSectionKind } from './PeriodSection.types'

interface Colors {
  color: string
  background: string
  bgColorClicked?: string
}

interface ColorsCollection {
  default: Colors
  active: Colors
  stripes: Colors
}

const colorsByPeriodSectionKind: Record<PeriodSectionKind, ColorsCollection> = {
  // Booking

  [PeriodSectionKind.booked]: {
    default: {
      color: mainColors.primaryMedium,
      background: mainColors.neutralDark,
      bgColorClicked: mainColors.neutralExtraDark,
    },
    active: {
      color: mainColors.white,
      background: mainColors.primaryDark,
      bgColorClicked: mainColors.primaryDark,
    },
    stripes: { color: mainColors.neutralDark, background: mainColors.neutralExtraLight },
  },
  [PeriodSectionKind.checkedIn]: {
    default: {
      color: mainColors.accentMedium,
      background: mainColors.accentExtremelyLight,
      bgColorClicked: mainColors.accentLightMedium,
    },
    active: { color: mainColors.white, background: mainColors.accentMedium },
    stripes: { color: mainColors.accentMedium, background: mainColors.accentExtremelyLight },
  },
  [PeriodSectionKind.checkedOut]: {
    default: {
      color: semanticColors.successMedium,
      background: semanticColors.successExtraLight,
      bgColorClicked: semanticColors.successLightMedium,
    },
    active: { color: mainColors.white, background: semanticColors.successMedium },
    stripes: { color: semanticColors.successMedium, background: semanticColors.successExtraLight },
  },
  [PeriodSectionKind.nonCompliant]: {
    default: {
      color: riskChannelsColors.channelPersona,
      background: riskChannelsColors.channelPersona,
    },
    active: { color: mainColors.white, background: riskChannelsColors.channelPersona },
    stripes: {
      color: riskChannelsColors.channelPersona,
      background: riskChannelsColors.channelPersona,
    },
  },
  [PeriodSectionKind.rejected]: {
    default: { color: mainColors.primaryMedium, background: mainColors.neutralDark },
    active: { color: mainColors.white, background: mainColors.primaryMedium },
    stripes: { color: mainColors.primaryMedium, background: mainColors.neutralDark },
  },

  // Payment

  [PeriodSectionKind.waitingPayment]: {
    default: {
      color: tapeChartColors.notPaidPrimaryColor,
      background: tapeChartColors.notPaidSecondaryColor,
    },
    active: { color: mainColors.white, background: tapeChartColors.notPaidPrimaryColor },
    stripes: {
      color: tapeChartColors.notPaidPrimaryColor,
      background: tapeChartColors.notPaidSecondaryColor,
    },
  },
  [PeriodSectionKind.paid]: {
    default: {
      color: tapeChartColors.fullyPaidPrimaryColor,
      background: tapeChartColors.fullyPaidSecondaryColor,
    },
    active: { color: mainColors.white, background: tapeChartColors.fullyPaidPrimaryColor },
    stripes: {
      color: tapeChartColors.fullyPaidPrimaryColor,
      background: tapeChartColors.fullyPaidSecondaryColor,
    },
  },
  [PeriodSectionKind.partiallyPaid]: {
    default: {
      color: tapeChartColors.partiallyPaidPrimaryColor,
      background: tapeChartColors.partiallyPaidSecondaryColor,
    },
    active: { color: mainColors.white, background: tapeChartColors.partiallyPaidPrimaryColor },
    stripes: {
      color: tapeChartColors.partiallyPaidPrimaryColor,
      background: tapeChartColors.partiallyPaidSecondaryColor,
    },
  },
  [PeriodSectionKind.overPaid]: {
    default: {
      color: tapeChartColors.overPaidPrimaryColor,
      background: tapeChartColors.overPaidSecondaryColor,
    },
    active: { color: mainColors.white, background: tapeChartColors.overPaidPrimaryColor },
    stripes: {
      color: tapeChartColors.overPaidPrimaryColor,
      background: tapeChartColors.overPaidSecondaryColor,
    },
  },

  // Others
  [PeriodSectionKind.block]: {
    default: { color: semanticColors.warningDark, background: semanticColors.warningExtraLight },
    active: { color: mainColors.white, background: semanticColors.warningDark },
    stripes: { color: semanticColors.warningDark, background: semanticColors.warningExtraLight },
  },
  [PeriodSectionKind.childBlock]: {
    default: { color: mainColors.neutralExtraDark, background: mainColors.neutralLight },
    active: { color: mainColors.white, background: mainColors.neutralExtraDark },
    stripes: { color: mainColors.neutralExtraDark, background: mainColors.neutralLight },
  },
  [PeriodSectionKind.conflict]: {
    default: { color: mainColors.white, background: semanticColors.failureMedium },
    active: { color: mainColors.white, background: mainColors.white },
    stripes: { color: mainColors.white, background: semanticColors.failureMedium },
  },

  [PeriodSectionKind.revenue]: {
    default: { color: mainColors.neutralExtremelyDark, background: mainColors.neutralLight },
    active: { color: mainColors.neutralExtremelyDark, background: mainColors.neutralLight },
    stripes: { color: mainColors.white, background: semanticColors.failureMedium },
  },

  [PeriodSectionKind.generic]: {
    default: { color: mainColors.primaryMedium, background: mainColors.neutralDark },
    active: { color: mainColors.white, background: mainColors.primaryMedium },
    stripes: { color: mainColors.primaryMedium, background: mainColors.neutralDark },
  },

  [PeriodSectionKind.backToBack]: {
    default: { color: mainColors.primaryMedium, background: mainColors.neutralDark },
    active: { color: mainColors.white, background: mainColors.primaryMedium },
    stripes: { color: mainColors.primaryMedium, background: mainColors.neutralDark },
  },
}

export { colorsByPeriodSectionKind }
