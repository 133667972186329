import styled from 'styled-components'

type FlexContainerProps = {
  justify?: 'space-between' | 'space-around' | 'flex-start' | 'center' | 'flex-end'
  direction?: 'row' | 'column'
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'stretch'
  noWrap?: boolean
  flex?: string
  full?: boolean
  height?: string
  gap?: number
}

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  justify-content: ${props => (props.justify ? props.justify : 'space-between')};
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  flex: ${props => (props.flex ? props.flex : 1)};
  width: ${props => (props.full ? '100%' : 'auto')};
  height: ${({ height }) => height};
  gap: ${({ gap }) => (gap ? `${gap}px` : 'unset')};
`
