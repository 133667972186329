import styled from 'styled-components'

import { CustomFieldRestriction } from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { mainColors, semanticColors } from '@avantstay/backoffice-core'

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

export const Item = styled.div`
  margin: 0 8px 8px 0;
  cursor: pointer;
`

export const ImageContainer = styled.div<{ imageUrl: string | null }>`
  width: 104px;
  height: 104px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${p => (p.imageUrl ? `url(${p.imageUrl})` : mainColors.white)};
  background-size: cover;
`

export const areaStylesByRestriction = {
  [CustomFieldRestriction.essential]: {
    border: `2px dashed ${semanticColors.warningMedium}`,
  },
  [CustomFieldRestriction.required]: {
    border: `2px dashed ${semanticColors.failureMedium}`,
  },
  [CustomFieldRestriction.optional]: {},
}
