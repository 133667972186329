import React, { Ref } from 'react'

function _IconDownload(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        d="M12.75 4.5C12.75 4.08579 12.4142 3.75 12 3.75C11.5858 3.75 11.25 4.08579 11.25 4.5V12.6891L8.36383 9.80292C8.07093 9.51003 7.59606 9.51003 7.30317 9.80292C7.01027 10.0958 7.01027 10.5707 7.30317 10.8636L11.4584 15.0188C11.5949 15.1613 11.7871 15.25 12 15.25C12.1718 15.25 12.3301 15.1922 12.4566 15.0951C12.4825 15.0752 12.5072 15.0536 12.5305 15.0302L16.6972 10.8636C16.9901 10.5707 16.9901 10.0958 16.6972 9.80292C16.4043 9.51003 15.9294 9.51003 15.6365 9.80292L12.75 12.6894V4.5ZM4.5 13.75C4.91421 13.75 5.25 14.0858 5.25 14.5V17.8333C5.25 18.0764 5.34658 18.3096 5.51849 18.4815C5.69039 18.6534 5.92355 18.75 6.16667 18.75H17.8333C18.0764 18.75 18.3096 18.6534 18.4815 18.4815C18.6534 18.3096 18.75 18.0764 18.75 17.8333V14.5C18.75 14.0858 19.0858 13.75 19.5 13.75C19.9142 13.75 20.25 14.0858 20.25 14.5V17.8333C20.25 18.4743 19.9954 19.089 19.5422 19.5422C19.089 19.9954 18.4743 20.25 17.8333 20.25H6.16667C5.52573 20.25 4.91104 19.9954 4.45783 19.5422C4.00461 19.089 3.75 18.4743 3.75 17.8333V14.5C3.75 14.0858 4.08579 13.75 4.5 13.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconDownload = React.forwardRef(_IconDownload)
