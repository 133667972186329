import React, { Ref } from 'react'

function _IconIot(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        d="M19.7772 4.22293C24.0714 8.51843 24.0714 15.4814 19.7772 19.7769C19.3867 20.1675 18.7536 20.1676 18.363 19.7771C17.9724 19.3867 17.9723 18.7535 18.3628 18.3629C21.8762 14.8484 21.8762 9.15143 18.3628 5.63693C17.9723 5.24635 17.9724 4.61318 18.363 4.22272C18.7536 3.83225 19.3867 3.83235 19.7772 4.22293ZM5.637 4.22272C6.02758 4.61318 6.02768 5.24635 5.63721 5.63693C2.12378 9.15143 2.12378 14.8484 5.63721 18.3629C6.02768 18.7535 6.02758 19.3867 5.637 19.7771C5.24642 20.1676 4.61325 20.1675 4.22279 19.7769C-0.071414 15.4814 -0.071414 8.51843 4.22279 4.22293C4.61325 3.83235 5.24642 3.83225 5.637 4.22272ZM16.9467 7.05243C18.2612 8.36541 18.9997 10.1471 18.9997 12.0049C18.9997 13.8628 18.2612 15.6445 16.9467 16.9574C16.556 17.3477 15.9228 17.3474 15.5325 16.9566C15.1422 16.5659 15.1425 15.9327 15.5333 15.5424C16.4722 14.6046 16.9997 13.332 16.9997 12.0049C16.9997 10.6779 16.4722 9.40527 15.5333 8.46743C15.1425 8.07713 15.1422 7.44396 15.5325 7.05322C15.9228 6.66248 16.556 6.66212 16.9467 7.05243ZM8.4675 7.04322C8.85781 7.43396 8.85746 8.06712 8.46671 8.45743C7.52783 9.39527 7.00028 10.6679 7.00028 11.9949C7.00028 13.322 7.52783 14.5946 8.46671 15.5324C8.85746 15.9227 8.85781 16.5559 8.4675 16.9466C8.07719 17.3374 7.44403 17.3377 7.05329 16.9474C5.73885 15.6345 5.00028 13.8528 5.00028 11.9949C5.00028 10.1371 5.73885 8.35541 7.05329 7.04243C7.44403 6.65212 8.07719 6.65248 8.4675 7.04322ZM12 8.99993C13.6569 8.99993 15 10.3431 15 11.9999C15 13.6568 13.6569 14.9999 12 14.9999C10.3431 14.9999 9 13.6568 9 11.9999C9 10.3431 10.3431 8.99993 12 8.99993ZM12 10.9999C11.4477 10.9999 11 11.4476 11 11.9999C11 12.5522 11.4477 12.9999 12 12.9999C12.5523 12.9999 13 12.5522 13 11.9999C13 11.4476 12.5523 10.9999 12 10.9999Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconIot = React.forwardRef(_IconIot)
