import React, { forwardRef, useRef, useState } from 'react'

import { IconValueHidden, IconValueRevealed } from '@avantstay/backoffice-vectors'

import { useOnClickOutside } from '../../hooks'
import * as S from './TextField.styles'
import { TextFieldProps, TextFieldSizeProps } from './TextField.types'
import { InfoIcon, TooltipWrapper } from './TextFieldElements'

function createTextFieldComponent(
  Component: typeof S.TextFieldDefault | typeof S.TextFieldWithMask,
  size?: TextFieldSizeProps,
) {
  return forwardRef(
    (
      {
        afterValue,
        beforeValue,
        dataCy,
        defaultValue,
        disabled,
        disableOnlyInput,
        error,
        hasElementOnLeft = false,
        helpText,
        hideErrorIcon,
        inputLeftIcon,
        inputLeftIconWidth,
        inputRightIcon,
        inputWidth,
        mask,
        message,
        onChange,
        onClick,
        placeholder,
        readOnly,
        tag,
        tagAlign,
        type,
        value,
        warning,
        widthMaxContent,
        hideMaskGuide,
        showErrorTooltip = true,
        ...inputProps
      }: TextFieldProps & React.InputHTMLAttributes<HTMLInputElement>,
      ref,
    ) => {
      const parentRef = useRef<HTMLDivElement | null>(null)
      const inputRef = useRef<HTMLInputElement | null>(null)
      const [focus, setFocus] = useState(false)
      const [inputType, setInputType] = useState<string>(type ?? 'text')

      useOnClickOutside(parentRef, () => setFocus(false))

      const renderIssuesInfo = () => (
        <S.TextFieldIssueContainer
          {...size}
          hideErrorIcon={hideErrorIcon}
          tagAlign={tagAlign}
          type={type}
        >
          {(() => {
            if (error || warning) {
              return (
                <InfoIcon error={error} warning={warning} showErrorTooltip={showErrorTooltip} />
              )
            }

            if (tag) {
              return <S.TagContainer tagAlign={tagAlign}>{tag}</S.TagContainer>
            }

            return null
          })()}
        </S.TextFieldIssueContainer>
      )

      return (
        <TooltipWrapper message={message}>
          <S.TextFieldInputContainer
            ref={parentRef}
            readOnly={readOnly}
            disabled={disableOnlyInput ? false : disabled}
            error={error}
            hasElementOnLeft={hasElementOnLeft}
            warning={warning}
            mask={mask}
            focus={focus}
            onClick={e => {
              onClick?.(e as React.MouseEvent<HTMLInputElement, MouseEvent>)
              setFocus(true)
              inputRef?.current?.focus()
            }}
            {...size}
            {...inputProps}
          >
            {inputLeftIcon ? (
              <S.InputIconContainer
                hasElementOnLeft={hasElementOnLeft}
                inputLeftIconWidth={inputLeftIconWidth}
              >
                {inputLeftIcon}
              </S.InputIconContainer>
            ) : null}

            {beforeValue ? <S.InputBeforeAfter>{beforeValue}</S.InputBeforeAfter> : null}

            {/* @ts-ignore */}
            <Component
              data-cy={dataCy}
              type={inputType}
              placeholder={placeholder}
              readOnly={readOnly}
              disabled={disabled}
              error={error}
              warning={warning}
              mask={mask}
              onChange={onChange}
              onClick={onClick}
              value={value}
              width={inputWidth}
              defaultValue={defaultValue}
              tagAlign={tagAlign}
              {...inputProps}
              ref={ref || inputRef}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              guide={!hideMaskGuide}
              {...size}
              {...(widthMaxContent
                ? { style: { width: `${value.length || placeholder?.length || 0}ch` } }
                : {})}
            />
            {afterValue ? <S.InputBeforeAfter>{afterValue}</S.InputBeforeAfter> : null}

            {!error && !warning && inputRightIcon ? (
              <S.InputIconContainer>{inputRightIcon}</S.InputIconContainer>
            ) : null}

            {!error && !warning && type === 'password' ? (
              <S.InputIconContainer>
                {inputType === 'password' ? (
                  <IconValueHidden onClick={() => setInputType('text')} />
                ) : (
                  <IconValueRevealed onClick={() => setInputType('password')} />
                )}
              </S.InputIconContainer>
            ) : null}

            {renderIssuesInfo()}
          </S.TextFieldInputContainer>

          {(error || warning) && !showErrorTooltip ? (
            <S.InfoMessage $hasError={Boolean(error)} $hasWarning={Boolean(warning)}>
              {error || warning}
            </S.InfoMessage>
          ) : null}

          {helpText ? <S.HelpTag>{helpText}</S.HelpTag> : null}
        </TooltipWrapper>
      )
    },
  )
}

const TextField = () => createTextFieldComponent(S.TextFieldDefault)

TextField.Default = createTextFieldComponent(S.TextFieldDefault)
TextField.Masked = createTextFieldComponent(S.TextFieldWithMask)
TextField.Tag = createTextFieldComponent(S.TextFieldDefault)
TextField.Tiny = createTextFieldComponent(S.TextFieldDefault, { tiny: true })
TextField.Small = createTextFieldComponent(S.TextFieldDefault, { small: true })
TextField.SmallMasked = createTextFieldComponent(S.TextFieldWithMask, { small: true })
TextField.SmallTag = createTextFieldComponent(S.TextFieldDefault, { small: true })

export { TextField }
