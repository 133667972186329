import arriereBackoffice, { LeadSource } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { CreateAssetInput } from '@arriere/types/Global.types'

export default {
  async createAssetMutation({
    context,
    extension,
    public: isPublic,
    targetId,
    size,
    fileName,
  }: CreateAssetInput) {
    const { data, errors } = await arriereBackoffice.mutations.createAsset.raw({
      __args: {
        assetRequest: {
          context,
          extension,
          public: isPublic,
          targetId,
          size,
          fileName,
        },
      },
      url: true,
      assetId: true,
      externalUrl: true,
    })
    if (errors?.length) {
      throw new Error(errors[errors?.length - 1]?.message)
    }
    return data
  },
  getLeadSourceActiveAccounts({ source }: { source?: LeadSource }) {
    return arriereBackoffice.queries
      .leadSourceAccounts({
        __args: {
          source,
        },
        name: true,
        active: true,
      })
      .then(results => results.filter(it => it.active))
  },
}
