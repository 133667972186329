import styled from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _XS_Medium } from '../../styles/typography'

export const BadgesContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  height: max-content;
  margin-top: 6px;

  & > div {
    margin-top: 4px;
    margin-right: 4px;
  }
`

export const HiddenBadgesContainer = styled.div`
  height: 100%;
  max-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 16px;

  & > div:not(:last-child) {
    margin-bottom: 7px;
  }
`

export const HiddenBadgesQuantity = styled.span`
  ${_XS_Medium};
  color: ${mainColors.accentMedium};
  margin-top: 4px;
  margin-left: 4px;
`
