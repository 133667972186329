import React, { Ref } from 'react'

function _IconCalendar(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0356 2.15468C11.0356 1.79311 10.7383 1.5 10.3715 1.5C10.0048 1.5 9.70751 1.79311 9.70751 2.15468V2.6691H6.29249V2.15468C6.29249 1.79311 5.99519 1.5 5.62846 1.5C5.26172 1.5 4.96443 1.79311 4.96443 2.15468V2.6691H3.8498C2.82818 2.6691 2 3.48562 2 4.49284V6.83097V12.6763C2 13.6835 2.82818 14.5 3.8498 14.5H12.1502C13.1718 14.5 14 13.6835 14 12.6763V6.83097V4.49284C14 3.48562 13.1718 2.6691 12.1502 2.6691H11.0356V2.15468ZM12.6719 6.1763V4.49284C12.6719 4.20875 12.4383 3.97845 12.1502 3.97845H11.0356V4.49281C11.0356 4.85437 10.7383 5.14748 10.3715 5.14748C10.0048 5.14748 9.70751 4.85437 9.70751 4.49281V3.97845H6.29249V4.49281C6.29249 4.85437 5.99519 5.14748 5.62846 5.14748C5.26172 5.14748 4.96443 4.85437 4.96443 4.49281V3.97845H3.8498C3.56165 3.97845 3.32806 4.20875 3.32806 4.49284V6.1763H12.6719ZM3.32806 7.48565H12.6719V12.6763C12.6719 12.9604 12.4383 13.1907 12.1502 13.1907H3.8498C3.56165 13.1907 3.32806 12.9604 3.32806 12.6763V7.48565Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconCalendar = React.forwardRef(_IconCalendar)
