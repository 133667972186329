import React, { Ref } from 'react'

function _IconPdf(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" {...props} ref={ref}>
      <path
        d="M10.5884 55.4706V4.52942C10.5884 3.97713 11.0361 3.52942 11.5884 3.52942H38.8237L52.9413 19.4118V55.4706C52.9413 56.0229 52.4936 56.4706 51.9413 56.4706H11.5884C11.0361 56.4706 10.5884 56.0229 10.5884 55.4706Z"
        fill="#F2F5F7"
      />
      <path
        d="M38.8237 18.4118V3.52942L52.9414 19.4118H39.8237C39.2714 19.4118 38.8237 18.9641 38.8237 18.4118Z"
        fill="#E9EEF1"
      />
      <rect x="5.29395" y="28.2353" width="38.8235" height="19.4118" rx="1" fill="#EC4678" />
      <path
        d="M14.4347 39.699V43.2353H12.3667V33.1985H16.2821C17.0358 33.1985 17.6976 33.3364 18.2674 33.6122C18.8419 33.8879 19.2831 34.2808 19.591 34.7909C19.8989 35.2964 20.0528 35.8732 20.0528 36.5212C20.0528 37.5046 19.715 38.2813 19.0395 38.8511C18.3685 39.4164 17.4379 39.699 16.2477 39.699H14.4347ZM14.4347 38.0239H16.2821C16.829 38.0239 17.2449 37.8952 17.5298 37.6379C17.8194 37.3805 17.9641 37.0129 17.9641 36.5349C17.9641 36.0432 17.8194 35.6457 17.5298 35.3424C17.2403 35.0391 16.8405 34.8828 16.3304 34.8736H14.4347V38.0239Z"
        fill="white"
      />
      <path
        d="M21.4798 43.2353V33.1985H24.568C25.4503 33.1985 26.2385 33.3985 26.9324 33.7983C27.6309 34.1935 28.1755 34.7587 28.5661 35.494C28.9568 36.2247 29.1521 37.0565 29.1521 37.9894V38.4513C29.1521 39.3842 28.9591 40.2137 28.573 40.9398C28.1916 41.6659 27.6516 42.2289 26.9531 42.6287C26.2546 43.0285 25.4664 43.2307 24.5887 43.2353H21.4798ZM23.5478 34.8736V41.574H24.5473C25.3561 41.574 25.9742 41.3098 26.4016 40.7813C26.829 40.2528 27.0473 39.4968 27.0565 38.5133V37.9826C27.0565 36.9623 26.8451 36.1903 26.4223 35.6664C25.9995 35.1379 25.3814 34.8736 24.568 34.8736H23.5478Z"
        fill="white"
      />
      <path
        d="M36.7003 39.1337H32.7298V43.2353H30.6617V33.1985H37.1967V34.8736H32.7298V37.4655H36.7003V39.1337Z"
        fill="white"
      />
    </svg>
  )
}

export const IconPdf = React.forwardRef(_IconPdf)
