import React, { Ref } from 'react'

function _IconReloadFromSL(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m11.2943 3.52643c.3054-.21383.7263-.13962.9401.16575l1.716 2.45064c.364.52035.242 1.24047-.2825 1.60793-.6864.48026-1.309.91629-2.2241 1.55706l-.1232.08629c-.3054.21384-.7263.13964-.9401-.16572-.2138-.30537-.1396-.72626.1657-.9401l.1229-.08606.0049-.00341 1.4159-.99144c-.2727-.0472-.5534-.07182-.8403-.07182-2.70056 0-4.88983 2.18943-4.88983 4.89015 0 1.5751.74396 2.9761 1.90239 3.8716.29494.228.3492.6519.1212.9469-.22801.2949-.65193.3492-.94687.1212-1.47485-1.1402-2.42672-2.9288-2.42672-4.9397 0-3.44623 2.79361-6.24015 6.23983-6.24015.2854 0 .5665.0192.8421.05641l-.9631-1.37543c-.2139-.30538-.1397-.72627.1657-.9401zm5.3701 3.63955c-.2901-.23402-.7151-.18849-.9491.10169-.234.29019-.1885.71514.1017.94916 1.1126.89731 1.8223 2.26967 1.8223 3.80847 0 2.7007-2.1892 4.8899-4.8899 4.8899-.2066 0-.41-.0128-.6096-.0375.3809-.2667.7908-.5537 1.2865-.9008l.0047-.0034.1223-.0856c.3054-.2139.3796-.6348.1658-.9401-.2139-.3054-.6348-.3796-.9401-.1657l-.1224.0857c-.9155.6411-1.5383 1.0772-2.2249 1.5576-.52457.3675-.6465 1.0876-.2825 1.6079l1.716 2.4507c.2138.3054.6347.3796.94.1657.3054-.2138.3796-.6347.1658-.9401l-1.0496-1.4989c.271.0359.5475.0545.828.0545 3.4463 0 6.2399-2.7936 6.2399-6.2399 0-1.9641-.9079-3.71663-2.3249-4.85932z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconReloadFromSL = React.forwardRef(_IconReloadFromSL)
