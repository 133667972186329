import React, { Ref } from 'react'

function _IconTeams(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7C6 5.34315 7.34315 4 9 4C10.6569 4 12 5.34315 12 7C12 8.65685 10.6569 10 9 10C7.34315 10 6 8.65685 6 7ZM9 2C6.23858 2 4 4.23858 4 7C4 9.76142 6.23858 12 9 12C11.7614 12 14 9.76142 14 7C14 4.23858 11.7614 2 9 2ZM5 14C3.67392 14 2.40215 14.5268 1.46447 15.4645C0.526784 16.4021 0 17.6739 0 19V21C0 21.5523 0.447715 22 1 22C1.55228 22 2 21.5523 2 21V19C2 18.2044 2.31607 17.4413 2.87868 16.8787C3.44129 16.3161 4.20435 16 5 16H13C13.7956 16 14.5587 16.3161 15.1213 16.8787C15.6839 17.4413 16 18.2043 16 19V21C16 21.5523 16.4477 22 17 22C17.5523 22 18 21.5523 18 21V19C18 17.6739 17.4732 16.4021 16.5355 15.4645C15.5979 14.5268 14.3261 14 13 14H5ZM19.0318 14.88C19.1698 14.3453 19.7153 14.0237 20.25 14.1618C21.3227 14.4387 22.273 15.0641 22.9517 15.9397C23.6304 16.8152 23.9992 17.8914 24 18.9993V21C24 21.5523 23.5523 22 23 22C22.4477 22 22 21.5523 22 21V19.0007C21.9994 18.3361 21.7782 17.6902 21.371 17.165C20.9638 16.6396 20.3936 16.2644 19.75 16.0982C19.2153 15.9602 18.8937 15.4148 19.0318 14.88ZM16.248 2.16125C15.713 2.02426 15.1682 2.34694 15.0312 2.88196C14.8943 3.41699 15.2169 3.96176 15.752 4.09875C16.3973 4.26398 16.9692 4.63928 17.3777 5.16548C17.7861 5.69169 18.0078 6.33887 18.0078 7.005C18.0078 7.67113 17.7861 8.31831 17.3777 8.84452C16.9692 9.37073 16.3973 9.74603 15.752 9.91125C15.2169 10.0482 14.8943 10.593 15.0312 11.128C15.1682 11.6631 15.713 11.9857 16.248 11.8488C17.3236 11.5734 18.2768 10.9479 18.9576 10.0709C19.6383 9.19385 20.0078 8.11521 20.0078 7.005C20.0078 5.89479 19.6383 4.81615 18.9576 3.93914C18.2768 3.06213 17.3236 2.43663 16.248 2.16125Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconTeams = React.forwardRef(_IconTeams)
