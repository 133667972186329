export default function (src: string) {
  return new Promise((resolve, reject) => {
    const scriptElement = document.createElement('script')
    scriptElement.async = true
    scriptElement.type = 'text/javascript'

    scriptElement.onerror = reject
    scriptElement.onload = resolve

    document.body.appendChild(scriptElement)
    scriptElement.src = src
  })
}
