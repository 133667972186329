import { mainColors } from '../../../styles'
import { CSSProperties } from 'react'
import styled from 'styled-components'

export const horizontalStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginBottom: '15px',
}

export const MAX_DRAG_DROP_GRID_WIDTH = 700

export const Placeholder = styled.div`
  min-width: 104px;
  max-width: 160px;
  width: 100%;
  height: 188px;
  border: 2px dotted ${mainColors.neutralMedium};
  margin-bottom: 15px;
`

export const PlaceholderContainer = styled.div<{
  isDraggingOver: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: ${MAX_DRAG_DROP_GRID_WIDTH}px;
  width: 100%;
  position: absolute;
  z-index: 0;
  margin-top: 0;

  ${props =>
    !props.isDraggingOver &&
    `
    visibility: hidden;
  `}
`
