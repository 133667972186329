import React, { Ref } from 'react'

function _IconPlay(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.76751 1.76853C4.03507 1.62246 4.36104 1.63413 4.61746 1.79898L16.2841 9.29898C16.5226 9.45231 16.6668 9.71641 16.6668 9.99996C16.6668 10.2835 16.5226 10.5476 16.2841 10.7009L4.61746 18.2009C4.36104 18.3658 4.03507 18.3775 3.76751 18.2314C3.49994 18.0853 3.3335 17.8048 3.3335 17.5V2.49996C3.3335 2.19512 3.49994 1.91461 3.76751 1.76853ZM5.00016 4.02635V15.9736L14.2924 9.99996L5.00016 4.02635Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconPlay = React.forwardRef(_IconPlay)
