// @ts-nocheck
import { BounceInRight } from 'animate-css-styled-components'
import styled from 'styled-components'

import { IconClose } from '@avantstay/backoffice-vectors'

import { mainColors } from '../../styles/colors'
import { rgba } from '../../utils/cssUtils'
import { BASE_COLOR_BY_EVENT_TYPE, EventType, MARGIN_BETWEEN_ALERTS } from './Alert.definitions'

export const Root = styled.div`
  pointer-events: all;
  margin-bottom: ${MARGIN_BETWEEN_ALERTS}px;
  text-align: right;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Container = styled(BounceInRight)<{ eventType: EventType }>`
  display: inline-block;
  width: auto;
  max-width: 325px;
  min-width: 160px;
  padding: 14px;
  background-color: ${mainColors.white};
  box-shadow: 0 2px 24px 0 ${rgba(mainColors.black, 0.08)};
  border-left: 5px solid ${props => BASE_COLOR_BY_EVENT_TYPE[props.eventType]};
  color: ${props => BASE_COLOR_BY_EVENT_TYPE[props.eventType]};
  text-align: left;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h4`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;

  svg {
    margin-right: 4px;
  }
`

export const Message = styled.p`
  margin-top: 8px;
  color: ${mainColors.neutralExtraDark};
  font-size: 12px;
  font-weight: normal;
`

export const CloseIcon = styled(IconClose)`
  cursor: pointer;
`

export const ActionsContainer = styled.div`
  margin-top: 8px;
  display: flex;
`

export const Action = styled.label<{ color: string }>`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  color: ${props => props.color};
  margin-right: 10px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
