import styled from 'styled-components'
import 'tippy.js/themes/light.css'

import { _2XS_Normal, rgba, shadowColors } from '@avantstay/backoffice-core'

import Tippy from '@tippyjs/react'

import { ITooltipStyled } from './_types'

export const TooltipStyled = styled(Tippy)<ITooltipStyled>`
  &.tippy-box[data-theme~='light'] {
    background: ${props => props.backgroundColor};
    color: ${props => props.color};
    ${_2XS_Normal};
    padding: 4px 12px;
    text-align: center;
    -webkit-box-shadow: 0 4px 16px 0 ${rgba(shadowColors.blur, 0.4)};
    -moz-box-shadow: 0 4px 16px 0 ${rgba(shadowColors.blur, 0.4)};
    box-shadow: 0 4px 16px 0 ${rgba(shadowColors.blur, 0.4)};
  }

  &.tippy-box[data-theme~='light'][data-placement^='top'] > .tippy-arrow:before {
    border-top-color: ${props => props.backgroundColor};
  }

  &.tippy-box[data-theme~='light'][data-placement^='bottom'] > .tippy-arrow:before {
    border-bottom-color: ${props => props.backgroundColor};
  }
`
