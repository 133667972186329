import React from 'react'

function _IconInvoice(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3C3 1.89543 3.89543 1 5 1H19C20.1046 1 21 1.89543 21 3V14C21 14.5523 20.5523 15 20 15C19.4477 15 19 14.5523 19 14V3.5C19 3.22386 18.7761 3 18.5 3L5.5 3C5.22386 3 5 3.22386 5 3.5V20.5C5 20.7761 5.22386 21 5.5 21H12C12.5523 21 13 21.4477 13 22C13 22.5523 12.5523 23 12 23H5C3.89543 23 3 22.1046 3 21V3ZM7 11C7 11.5523 7.44772 12 8 12H16C16.5523 12 17 11.5523 17 11C17 10.4477 16.5523 10 16 10H8C7.44772 10 7 10.4477 7 11ZM8 8C7.44772 8 7 7.55229 7 7C7 6.44772 7.44772 6 8 6H16C16.5523 6 17 6.44772 17 7C17 7.55229 16.5523 8 16 8H8ZM7 15C7 15.5523 7.44772 16 8 16H10C10.5523 16 11 15.5523 11 15C11 14.4477 10.5523 14 10 14H8C7.44772 14 7 14.4477 7 15ZM18.5 16C18.5 15.4477 18.0523 15 17.5 15C16.9477 15 16.5 15.4477 16.5 16V16.1731C15.9863 16.2127 15.5015 16.4344 15.1346 16.8013C14.7283 17.2076 14.5 17.7587 14.5 18.3333C14.5 18.908 14.7283 19.4591 15.1346 19.8654C15.5409 20.2717 16.092 20.5 16.6667 20.5H18.3333C18.3775 20.5 18.4199 20.5175 18.4512 20.5488C18.4824 20.5801 18.5 20.6225 18.5 20.6667C18.5 20.7109 18.4824 20.7533 18.4512 20.7845C18.4199 20.8158 18.3775 20.8333 18.3333 20.8333H15.5C14.9477 20.8333 14.5 21.281 14.5 21.8333C14.5 22.3856 14.9477 22.8333 15.5 22.8333H16.5V23C16.5 23.5523 16.9477 24 17.5 24C18.0523 24 18.5 23.5523 18.5 23V22.8269C19.0137 22.7873 19.4985 22.5656 19.8654 22.1987C20.2717 21.7924 20.5 21.2413 20.5 20.6667C20.5 20.092 20.2717 19.5409 19.8654 19.1346C19.4591 18.7283 18.908 18.5 18.3333 18.5H16.6667C16.6225 18.5 16.5801 18.4824 16.5488 18.4512C16.5176 18.4199 16.5 18.3775 16.5 18.3333C16.5 18.2891 16.5176 18.2467 16.5488 18.2155C16.5801 18.1842 16.6225 18.1667 16.6667 18.1667H19.1667C19.719 18.1667 20.1667 17.7189 20.1667 17.1667C20.1667 16.6144 19.719 16.1667 19.1667 16.1667H18.5V16Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconInvoice = React.memo(React.forwardRef(_IconInvoice))

export { IconInvoice }
