import React, { useRef, useState } from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/high-res.css'

import isDescendant from '@avantstay/avantstay-ui/lib/utils/isDescendant'

import { InfoTooltip } from '../../components/InfoTooltip/InfoTooltip'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import * as S from './PhoneField.styles'
import { PhoneFieldProps } from './PhoneField.types'

const _PhoneField = ({
  autoFocus = true,
  copyNumbersOnly = true,
  disabled = false,
  isSmall = false,
  required = true,
  error,
  value,
  warning,
  onChange,
  dataCy,
  shouldDisplayRightIcon = false,
  tooltipMessage,
  tooltipPlacement = 'bottom',
  ...props
}: PhoneFieldProps): JSX.Element => {
  const [focus, setFocus] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(containerRef, event => {
    const isEventFromInside = isDescendant(containerRef?.current, event.target)
    if (!isEventFromInside) {
      setFocus(false)
    }
  })

  return (
    <S.Container
      ref={containerRef}
      focus={focus}
      error={error}
      warning={warning}
      disabled={disabled}
      isSmall={isSmall}
    >
      <ReactPhoneInput
        country="us"
        value={value}
        copyNumbersOnly={copyNumbersOnly}
        disableDropdown={disabled}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        inputProps={{
          name: 'phone',
          required,
          autoFocus,
          disabled,
          ...props,
        }}
        data-cy={dataCy}
        containerStyle={isSmall ? S.SmallContainerStyle : S.ContainerStyle}
        inputStyle={isSmall ? S.SmallInputStyle : S.InputStyle}
        buttonStyle={isSmall ? S.SmallButtonStyle : S.ButtonStyle}
        dropdownStyle={S.DropdownStyle}
      />

      {(error || warning || shouldDisplayRightIcon) && (
        <S.IssueContainer>
          <InfoTooltip
            error={error}
            warning={warning}
            info={tooltipMessage}
            placement={tooltipPlacement}
          />
        </S.IssueContainer>
      )}
    </S.Container>
  )
}

const PhoneField = React.memo(_PhoneField)

export { PhoneField }
