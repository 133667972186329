import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
`

export const Head = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Tail = styled.div`
  white-space: nowrap;
`

export const EllipsisContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`
