import React, { Ref } from 'react'

function _IconFeatured(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.44853 1.11267C8.3643 0.942021 8.19049 0.833984 8.00018 0.833984C7.80987 0.833984 7.63606 0.942021 7.55182 1.11267L5.60805 5.05055L1.2612 5.68591C1.07292 5.71343 0.916576 5.84543 0.857888 6.02643C0.799199 6.20744 0.848335 6.40607 0.984644 6.53883L4.12958 9.602L3.38737 13.9295C3.3552 14.1171 3.43232 14.3066 3.58631 14.4185C3.7403 14.5304 3.94445 14.5451 4.1129 14.4565L8.00018 12.4122L11.8875 14.4565C12.0559 14.5451 12.2601 14.5304 12.414 14.4185C12.568 14.3066 12.6452 14.1171 12.613 13.9295L11.8708 9.602L15.0157 6.53883C15.152 6.40607 15.2012 6.20744 15.1425 6.02643C15.0838 5.84543 14.9274 5.71343 14.7392 5.68591L10.3923 5.05055L8.44853 1.11267Z"
        fill="#FFBD5A"
      />
    </svg>
  )
}

export const IconFeatured = React.forwardRef(_IconFeatured)
