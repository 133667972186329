import styled, { css, keyframes } from 'styled-components'

import { IconChevronDown } from '@avantstay/backoffice-vectors'

import { _L_Normal, _M_Medium, _XS_Bold, mainColors } from '../../styles'
import { CollapseTheme } from './Collapse.types'

const slideIn = keyframes`
  0% {
    max-height: 0;
  }

  100% {
    max-height: 10000px;
  }
`

const slideOut = keyframes`
  0% {
    max-height: 10000px;
  }

  100% {
    max-height: 0;
  }
`

export const Container = styled.div<{
  hasBorder?: boolean
  hasChevronAtStart?: boolean
  theme?: CollapseTheme
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 14px;
  position: relative;

  ${({ hasBorder, hasChevronAtStart }) =>
    hasBorder &&
    `
    padding-bottom: 14px;

    &:not(:last-child) {
      &::after {
        content: '';
        height: 1px;
        width: ${hasChevronAtStart ? 'calc(100% - 22px)' : '100%'};
        margin-left: ${hasChevronAtStart ? '22px;' : '0'};
        margin-top: 14px;
        background-color: ${mainColors.neutralMedium};
      }
    }
  `}

  ${({ theme, hasBorder }) =>
    theme !== CollapseTheme.default &&
    `
    margin-bottom: 0;

    ${
      hasBorder &&
      `
    margin-top: 0;
    padding-bottom: 0;

    &:not(:last-child) {
       padding-bottom: 0;

       &:after {
         content: '';
         position: absolute;
         width: 100%;
         bottom: 0;
         left: 0;
       }
    }
  `
    }
`}
`

interface TitleProps {
  justifyContent?: string
  iconSize?: number
  isCollapseOpen?: boolean
  hasChevronAtStart?: boolean
}

export const IconChevronDownStyled = styled(IconChevronDown)``

export const Title = styled.div<TitleProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'space-between'};
  flex: 1;
  ${_L_Normal};
  color: ${mainColors.neutralExtremelyDark};

  ${({ hasChevronAtStart }) =>
    hasChevronAtStart &&
    `
    flex-direction: row-reverse;
    justify-content: left;
    gap: 6px;
  `}

  svg {
    width: ${({ iconSize }) => `${iconSize ?? 24}px`};
    height: ${({ iconSize }) => `${iconSize ?? 24}px`};
    color: ${mainColors.neutralExtraDark};
  }

  ${IconChevronDownStyled} {
    ${({ isCollapseOpen }) => isCollapseOpen && `transform: scaleY(-1);`}
  }
`

export const TitleGroup = styled.div<{ theme?: CollapseTheme }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ theme }) =>
    theme === CollapseTheme.collapseListSmall &&
    `
      padding: 0;

    ${Title} {
      ${_XS_Bold};
      color: ${mainColors.neutralExtremelyDark};
      justify-content: flex-end;
      flex-direction: row-reverse;
      gap: 8px;
      padding: 14px 0;


      svg {
        width: 16px;
        height: 16px;
      }
    }
  `};

  ${({ theme }) =>
    theme === CollapseTheme.collapseList &&
    `
    padding: 0 30px;

    ${Title} {
      ${_M_Medium};
      color: ${mainColors.black};
      justify-content: flex-end;
      flex-direction: row-reverse;
      gap: 6px;
      padding: 8px 0;
      border-bottom: 1px solid ${mainColors.neutralMedium};

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &.active {
      background-color: ${mainColors.neutralExtraLight};
      ${Title} {
        border-bottom: none;
      }
    }

    &:hover {
      background-color: ${mainColors.neutralExtraLight};
    }
  `};
`

export const Content = styled.div<{ theme?: CollapseTheme; isContentVisible: boolean }>`
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid ${mainColors.neutralMedium};
  overflow: hidden;
  animation-duration: 0.4s;
  animation-fill-mode: both;

  ${({ theme }) =>
    theme === CollapseTheme.collapseList &&
    `
    flex-direction: column;
    padding: 0 30px;
    background-color: ${mainColors.neutralExtraLight};
    border-bottom: none;
  `}

  ${({ theme }) =>
    theme === CollapseTheme.collapseListSmall &&
    `
    flex-direction: column;
    padding: 0 20px;
    background-color: none;
    border-bottom: none;
  `}

  ${({ isContentVisible }) =>
    isContentVisible
      ? css`
          animation-name: ${slideIn};
        `
      : css`
          animation-name: ${slideOut};
        `}
`
