import styled from 'styled-components'

import { mainColors, semanticColors } from '@avantstay/backoffice-core'

export interface ProgressBarProps {
  percentage: number
}

export const Progress = styled.div<ProgressBarProps>`
  height: 4px;
  width: ${({ percentage }) => percentage}px;
  border-radius: 20px;
  background: ${mainColors.neutralLight};
  color: ${mainColors.neutralDark};
`

export const Bar = styled(Progress)<ProgressBarProps>`
  background: ${semanticColors.successMedium};
  transition: 0.5s ease;
`
