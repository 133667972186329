import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconPinSquare(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" {...props} ref={ref}>
      <path
        d="M0.75 2.75C0.75 1.64543 1.64543 0.75 2.75 0.75H15.25C16.3546 0.75 17.25 1.64543 17.25 2.75V13.7773C17.25 14.8819 16.3546 15.7773 15.25 15.7773H11.8581C11.6114 15.7773 11.3735 15.8685 11.19 16.0333L9.33408 17.7C9.14405 17.8706 8.85595 17.8706 8.66592 17.7L6.81005 16.0333C6.62652 15.8685 6.38855 15.7773 6.14188 15.7773H2.75C1.64543 15.7773 0.75 14.8819 0.75 13.7773V2.75Z"
        fill="currentColor"
        stroke="white"
      />
    </svg>
  )
}

export const IconPinSquare = forwardRef(_IconPinSquare)
