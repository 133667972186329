import React from 'react'

import { BadgeDefault } from './Badge.styles'
import { BadgeProps } from './Badge.types'

const Badge = React.forwardRef(
  (
    {
      children,
      badgeColor,
      className,
      interactive,
      styles,
      onClick,
      hasDropdown,
      dataCy,
      dashed = false,
      inactive = false,
    }: BadgeProps,
    ref,
  ) => (
    <BadgeDefault
      badgeColor={badgeColor}
      interactive={interactive}
      className={className}
      style={{ ...styles }}
      // @ts-ignore https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
      ref={ref}
      onClick={onClick}
      hasDropdown={hasDropdown}
      data-cy={dataCy}
      dashed={dashed}
      inactive={inactive}
    >
      {children}
    </BadgeDefault>
  ),
)

export { Badge }
