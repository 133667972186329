import React, { Ref } from 'react'

function _IconSuitcase(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.09497 0.919922C6.5231 0.919922 5.97465 1.1471 5.57027 1.55147C5.1659 1.95585 4.93872 2.5043 4.93872 3.07617V3.73242H2.87622C1.68536 3.73242 0.719971 4.69781 0.719971 5.88867V12.9199C0.719971 14.1108 1.68536 15.0762 2.87622 15.0762H5.68872H11.3137H14.1262C15.3171 15.0762 16.2825 14.1108 16.2825 12.9199V5.88867C16.2825 4.69781 15.3171 3.73242 14.1262 3.73242H12.0637V3.07617C12.0637 2.5043 11.8365 1.95585 11.4322 1.55147C11.0278 1.1471 10.4793 0.919922 9.90747 0.919922H7.09497ZM10.5637 3.73242V3.07617C10.5637 2.90212 10.4946 2.7352 10.3715 2.61213C10.2484 2.48906 10.0815 2.41992 9.90747 2.41992H7.09497C6.92092 2.41992 6.754 2.48906 6.63093 2.61213C6.50786 2.7352 6.43872 2.90212 6.43872 3.07617V3.73242H10.5637ZM6.43872 5.23242H10.5637V13.5762H6.43872V5.23242ZM4.93872 5.23242H2.87622C2.51378 5.23242 2.21997 5.52623 2.21997 5.88867V12.9199C2.21997 13.2824 2.51378 13.5762 2.87622 13.5762H4.93872V5.23242ZM12.0637 13.5762V5.23242H14.1262C14.4887 5.23242 14.7825 5.52623 14.7825 5.88867V12.9199C14.7825 13.2824 14.4887 13.5762 14.1262 13.5762H12.0637Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconSuitcase = React.forwardRef(_IconSuitcase)
