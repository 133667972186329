export enum zIndex {
  DEFAULT = 1,
  TABLE_PORTAL,
  TABLE_HEADER,
  TABLE_CONTENT,
  EDITABLE_ACTION_BAR,
  HOVERED_DIVISION,
  FIXED_TABLE_COLUMN,
  FIXED_TABLE_HEADER,
  TOOLTIP,
  FIXED_ACTIONS_CONTAINER,
  TABLE_AVATAR,
  FILTERS,
  HEADER,
  SIDEBAR_SHADOW,
  FTC_LOADING_OVERLAY,
  FLOATING_CONTAINER_TOGGLER,
  PORTAL,
  FOLD_OUT_MENU,
  MOBILE_TABLE_CARD,
  MODAL = 80,
  FIXED_ACTIONS_MOBILE_CONTAINER = 100,
  GLOBAL_LOADING,
  INFINITE = 9999,
}
