import styled from 'styled-components'

import { mainColors } from '../../../../styles'

const CONTENT_WIDTH = 248

export const ErrorWrapper = styled.div`
  height: 100%;
  width: ${CONTENT_WIDTH}px;
  padding: 16px;
  background: ${mainColors.white};
  display: flex;
  justify-content: center;
`
