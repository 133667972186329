import styled, { css } from 'styled-components'

import { mainColors, semanticColors } from '../../styles/colors'
import { _S_Medium } from '../../styles/typography'
import {
  ToggleBooleanType,
  ToggleFieldLabelProps,
  ToggleFieldProps,
  ToggleInputProps,
} from './ToggleField.types'

const Container = styled.div`
  position: relative;
  min-height: 24px;
  width: 100%;
`

const ToggleDefault = styled.label`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 2px 12px;
  background: ${mainColors.neutralLight};
  border: 2px solid transparent;
  border-radius: 20px;
  cursor: pointer;

  span {
    color: ${mainColors.neutralExtremelyDark};
  }
`

const inputStyled = ({ fill, toggleType }: ToggleInputProps) => {
  if (fill) {
    return css`
      background-color: ${toggleType === ToggleBooleanType.false
        ? semanticColors.failureMedium
        : mainColors.accentMedium};

      span {
        color: ${mainColors.white};
      }
    `
  }

  return css`
    border-color: ${toggleType === ToggleBooleanType.false
      ? semanticColors.failureMedium
      : mainColors.accentMedium};

    span {
      color: ${toggleType === ToggleBooleanType.false
        ? semanticColors.failureMedium
        : mainColors.accentMedium};
    }
  `
}

const HiddenInput = styled.input<ToggleFieldLabelProps>`
  opacity: 0;
  cursor: pointer;
  position: absolute;
  height: 100%;

  &:hover + ${ToggleDefault} {
    span {
      color: ${({ toggleType }) =>
        toggleType === ToggleBooleanType.false
          ? semanticColors.failureMedium
          : mainColors.accentMedium};
    }
  }

  &:checked + ${ToggleDefault} {
    ${({ fill, toggleType }) => inputStyled({ fill, toggleType })}
  }
`

const Title = styled.span`
  ${_S_Medium}
  margin: 0 auto;
`

const ToggleFieldContainer = styled.div<ToggleFieldProps>`
  background: ${mainColors.neutralLight};
  border-radius: 22px;
  padding: 3px;
  height: 30px;
  width: ${props => (props.autofill ? '100%' : 'max-content')};

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 8px;
  grid-template-areas: '. .';
  align-items: center;
`

export { Container, ToggleDefault, HiddenInput, Title, ToggleFieldContainer }
