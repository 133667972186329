// @ts-nocheck
import { css } from 'styled-components'

export const MEDIUM_DESKTOP_SCREEN_IN_PIXELS = 1120

export const sizes = {
  XS_SCREEN: '24em', // 384px
  SM_SCREEN: '35.5em', // 568px
  MD_SCREEN: '48em', // 768px
  LG_SCREEN: '56em', // 896px
  XL_SCREEN: '64em', // 1024px
  XL_MEDIUM_SCREEN: '70em', // 1120px
  XXL_SCREEN: '80em', // 1280px
  XXXL_SCREEN: '120em', // 1920px
}

const screenResolution = (literals: TemplateStringsArray, ...p: any[]) =>
  css`
    @media (min-resolution: 240dpi) {
      ${css(literals, ...p)}
    }
  `

const _medias = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (literals: TemplateStringsArray, ...p: any[]) =>
    css`
      @media (min-width: ${sizes[label]}) {
        ${css(literals, ...p)}
      }
    `.join('')
  return acc
}, {} as Record<keyof typeof sizes, (l: TemplateStringsArray, ...p: any[]) => string>)

export const medias = { ..._medias, HIGH_DPI: screenResolution }
