import { IS_PRODUCTION } from '@utils/constants'

export const identifyCurrentUser = (user: { id: string; email: string; name: string } | false) => {
  if (IS_PRODUCTION) {
    if (user) {
      ;(window as any).FS &&
        (window as any).FS.identify(user.id, {
          displayName: user.name,
          email: user.email,
        })
      ;(window as any).DD_RUM &&
        (window as any).DD_RUM.addRumGlobalContext('usr', {
          name: user.name,
          email: user.email,
        })
    } else {
      ;(window as any).FS && (window as any).FS.identify(false)
    }
  }
}
