import React, { useCallback, useState } from 'react'

import { Alert } from './Alert'
import { AlertProps } from './Alert.definitions'
import * as S from './AlertContext.styles'
import { useSubscribeEvent } from './hooks/useSubscribeEvent'

export const AlertContext = (): JSX.Element => {
  const [alerts, setAlerts] = useState<AlertProps[]>([])
  const [maxHeight, setMaxHeight] = useState(0)

  const handleAddAlert = useCallback((alert: AlertProps) => {
    setAlerts(prev => [...prev, alert])
  }, [])

  const handleSetMaxHeight = useCallback((height: number) => {
    setMaxHeight(prev => prev + height)
  }, [])

  const handleRemoveAlert = useCallback(
    (itemId: string) => (height: number) => {
      setAlerts(prev => prev.filter(({ id }) => id !== itemId))
      setMaxHeight(prev => prev - height)
    },
    [],
  )

  useSubscribeEvent(handleAddAlert)

  return (
    <S.Root maxHeight={maxHeight}>
      <S.AlertsContainer>
        {alerts.map(it => (
          <Alert
            key={`${it.id}${it.eventType}`}
            alert={it}
            onLayout={handleSetMaxHeight}
            onClose={handleRemoveAlert(it.id)}
          />
        ))}
      </S.AlertsContainer>
    </S.Root>
  )
}
