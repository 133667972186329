import React, { Ref } from 'react'

function _IconIdDoc(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        d="M9.59961 16H7.71973V8.17969H9.59961V16ZM10.8503 16V8.17969H13.3694C14.0605 8.17969 14.6817 8.33634 15.2332 8.64966C15.7846 8.96297 16.2152 9.40519 16.5249 9.97632C16.8346 10.5474 16.9913 11.1875 16.9949 11.8965V12.2563C16.9949 12.9725 16.8436 13.6143 16.541 14.1819C16.2384 14.7494 15.8123 15.1934 15.2627 15.5139C14.7131 15.8344 14.0999 15.9964 13.4231 16H10.8503ZM12.7356 9.63525V14.5498H13.3909C13.9316 14.5498 14.3469 14.3573 14.637 13.9724C14.927 13.5875 15.072 13.0155 15.072 12.2563V11.918C15.072 11.1624 14.927 10.5931 14.637 10.21C14.3469 9.82682 13.9244 9.63525 13.3694 9.63525H12.7356ZM13 1C13.2387 1 13.4695 1.08539 13.6508 1.24074L20.6508 7.24074C20.8724 7.43073 21 7.70808 21 8V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V4C3 2.34315 4.34315 1 6 1H13ZM12.6301 3H6C5.44772 3 5 3.44772 5 4V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19V8.45994L12.6301 3Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconIdDoc = React.forwardRef(_IconIdDoc)
