import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

import { IconAlertError, IconAlertInfo, IconAlertSuccess } from '@avantstay/backoffice-vectors'

import {
  AlertProps as AlertType,
  BASE_COLOR_BY_EVENT_TYPE,
  DEFAULT_TITLE_BY_EVENT_TYPE,
  EventType,
  MARGIN_BETWEEN_ALERTS,
} from './Alert.definitions'
import * as S from './Alert.styles'

type AlertProps = {
  alert: AlertType
  onLayout: (height: number) => void
  onClose: (height: number) => void
}

// FC: Cleaning Fee
const Icon: React.FC<{
  eventType: EventType
}> = ({ eventType }): JSX.Element | null => {
  switch (eventType) {
    case EventType.ERROR:
      return <IconAlertError />
    case EventType.INFO:
      return <IconAlertInfo />
    case EventType.SUCCESS:
      return <IconAlertSuccess />
    default:
      return null
  }
}

function _Alert({ alert, onLayout, onClose }: AlertProps): JSX.Element {
  const closeTimeout = useRef<any>(null)
  const containerRef = useRef(null)

  const baseColor = BASE_COLOR_BY_EVENT_TYPE[alert.eventType]
  const defaultTimeout = 7000

  const getTotalHeight = useCallback(() => {
    return (containerRef as any).current.offsetHeight + MARGIN_BETWEEN_ALERTS
  }, [containerRef])

  const handleOnClose = useCallback(() => {
    clearTimeout(closeTimeout.current)
    onClose(getTotalHeight())
  }, [getTotalHeight, onClose])

  useLayoutEffect(() => {
    onLayout(getTotalHeight())
  }, [getTotalHeight, onLayout])

  useEffect(() => {
    closeTimeout.current = setTimeout(() => {
      handleOnClose()
    }, alert.timeout || defaultTimeout)

    return () => clearTimeout(closeTimeout.current)
  }, [closeTimeout, alert.timeout, handleOnClose])

  return (
    <S.Root ref={containerRef}>
      <S.Container eventType={alert.eventType}>
        <S.Header>
          <S.Title>
            <Icon eventType={alert.eventType} />
            {alert.title || DEFAULT_TITLE_BY_EVENT_TYPE[alert.eventType]}
          </S.Title>

          <S.CloseIcon onClick={handleOnClose} />
        </S.Header>

        {alert.message && <S.Message dangerouslySetInnerHTML={{ __html: alert.message }} />}

        {alert.actions && (
          <S.ActionsContainer>
            {alert.actions.map((action, key) => (
              <S.Action key={key} color={action.color || baseColor} onClick={action.onClick}>
                {action.label}
              </S.Action>
            ))}
          </S.ActionsContainer>
        )}
      </S.Container>
    </S.Root>
  )
}

// If you need to wrap this component with HOCs, use them here
const Alert = _Alert

export { Alert }
