import styled from 'styled-components'

import { mainColors } from '../../styles/colors'
import { rgba } from '../../utils/cssUtils'

export const AvatarContainer = styled.div`
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
  flex-grow: 0;
  flex-shrink: 0;
  box-shadow: 0 0 0 4px ${rgba(mainColors.white, 0.1)};
`

export const Initials = styled.span<{ isActive?: boolean; size: number }>`
  font-weight: bold;
  color: ${props => (props.isActive ? mainColors.white : mainColors.neutralExtraDark)};
  font-size: ${props => props.size * 0.35};
  line-height: ${props => props.size}px;
`
