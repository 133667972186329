import React, { Ref } from 'react'

function _IconFeaturedOff(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99989 0.783203C7.79055 0.783203 7.59936 0.902043 7.5067 1.08976L5.57454 5.00409L1.25367 5.63565C1.04657 5.66593 0.874592 5.81113 0.810034 6.01023C0.745476 6.20934 0.799526 6.42783 0.949466 6.57387L4.07557 9.61869L3.3378 13.9202C3.30241 14.1266 3.38724 14.3351 3.55663 14.4582C3.72602 14.5812 3.95058 14.5974 4.13588 14.5L7.99989 12.468L11.8639 14.5C12.0492 14.5974 12.2738 14.5812 12.4431 14.4582C12.6125 14.3351 12.6974 14.1266 12.662 13.9202L11.9242 9.61869L15.0503 6.57387C15.2002 6.42783 15.2543 6.20934 15.1897 6.01023C15.1252 5.81113 14.9532 5.66593 14.7461 5.63565L10.4252 5.00409L8.49308 1.08976C8.40042 0.902043 8.20923 0.783203 7.99989 0.783203ZM6.43308 5.74998L7.99989 2.57579L9.5667 5.74998C9.64674 5.91215 9.80139 6.0246 9.98034 6.05075L13.4849 6.563L10.9495 9.03254C10.8197 9.1589 10.7605 9.34102 10.7911 9.51951L11.3894 13.0076L8.25588 11.3597C8.09562 11.2755 7.90415 11.2755 7.74389 11.3597L4.61039 13.0076L5.20864 9.51951C5.23925 9.34102 5.18004 9.1589 5.05031 9.03254L2.51485 6.563L6.01943 6.05075C6.19838 6.0246 6.35303 5.91215 6.43308 5.74998Z"
        fill="#D4DBDF"
      />
    </svg>
  )
}

export const IconFeaturedOff = React.forwardRef(_IconFeaturedOff)
