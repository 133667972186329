import styled from 'styled-components'

export const SearchInputContainer = styled.div<{ width?: number; inputTextAlign?: string }>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};

  ${({ inputTextAlign }) =>
    inputTextAlign &&
    `
    input {
      text-align: ${inputTextAlign};
      padding: 0 4px;
    }
  `}
`
