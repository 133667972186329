import React, { Ref } from 'react'

function _IconBedCount(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.26209 2.84998C3.55431 2.84998 3.01525 3.44164 3.01525 4.1278V6.45106V7.1587C2.36295 7.33289 1.8999 7.94581 1.8999 8.64527V11.0977V11.0978V12.6467C1.8999 12.9247 2.12527 13.1501 2.40329 13.1501C2.6813 13.1501 2.90667 12.9247 2.90667 12.6467V11.601H13.0523V12.6467C13.0523 12.9248 13.2777 13.1501 13.5557 13.1501C13.8337 13.1501 14.0591 12.9248 14.0591 12.6467V11.0979C14.0591 11.0899 14.0589 11.082 14.0586 11.0741V8.64527C14.0586 7.94592 13.5957 7.33309 12.9435 7.15878V4.1278C12.9435 3.44164 12.4045 2.84998 11.6967 2.84998H4.26209ZM12.4312 8.11607C12.4342 8.11612 12.4372 8.11615 12.4401 8.11615C12.4431 8.11615 12.4461 8.11612 12.4491 8.11607H12.5639C12.8142 8.11607 13.0518 8.33343 13.0518 8.64527V10.5943H2.90667V8.64527C2.90667 8.33343 3.14428 8.11607 3.39457 8.11607H3.5097C3.51267 8.11612 3.51565 8.11615 3.51863 8.11615C3.52162 8.11615 3.5246 8.11612 3.52757 8.11607H7.97333L7.97935 8.1161H7.97939L7.98541 8.11607H12.4312ZM11.9368 5.19659V4.1278C11.9368 3.95853 11.8101 3.85674 11.6967 3.85674H4.26209C4.14867 3.85674 4.02202 3.95853 4.02202 4.1278V5.19658C4.09936 5.18129 4.17958 5.17324 4.26209 5.17324H7.23593C7.51849 5.17324 7.77415 5.26754 7.97937 5.4245C8.18459 5.26754 8.44025 5.17324 8.72281 5.17324H11.6966C11.7792 5.17324 11.8594 5.18129 11.9368 5.19659ZM4.02202 6.45106V7.1093H7.47597V6.45106L7.47598 6.44659C7.47377 6.28001 7.34835 6.18001 7.23593 6.18001H4.26209C4.14867 6.18001 4.02202 6.2818 4.02202 6.45106ZM11.9367 6.45106V7.1093H8.48277V6.45106L8.48277 6.44662C8.48496 6.28002 8.61039 6.18001 8.72281 6.18001H11.6966C11.8101 6.18001 11.9367 6.2818 11.9367 6.45106Z"
        fill="#94A4AD"
      />
    </svg>
  )
}

export const IconBedCount = React.forwardRef(_IconBedCount)
