import styled from 'styled-components'

import { IconChevronRight } from '@avantstay/backoffice-vectors'

import { Button } from '../../components/Button'
import { mainColors, shadowColors } from '../../styles/colors'
import { _M_Normal } from '../../styles/typography'
import { zIndex } from '../../styles/zIndex'
import { rgba } from '../../utils/cssUtils'

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 175px;
  padding: 8px 0;
  background-color: ${mainColors.white};
  border: 1px solid ${mainColors.neutralLight};
  border-radius: 2px;
  box-shadow: 0 4px 16px 0 ${rgba(shadowColors.blur, 0.4)};
  transform: translate3d(28px, 0, 0);
  z-index: ${zIndex.DEFAULT};
`

interface DropdownLinkProps {
  disabled?: boolean
}

export const DropdownLink = styled.div<DropdownLinkProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 15px;
  ${_M_Normal};
  color: ${mainColors.neutralExtremelyDark};
  cursor: pointer;

  &:hover {
    background-color: ${mainColors.neutralLight};
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: ${mainColors.neutralMedium};
    cursor: not-allowed;
  `}
`

export const DropdownLinkIcon = styled(IconChevronRight)`
  margin-left: 8px;
  color: ${mainColors.neutralExtraDark};
`

export const DropdownLinkText = styled.div`
  flex-grow: 1;
  text-align: left;
  line-height: 1.4;
`

export const DropdownLinkContent = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: calc(100%);
  min-width: 160px;
  padding: 16px;
  background-color: ${mainColors.white};
  border: 1px solid ${mainColors.neutralLight};
  border-radius: 2px;
  box-shadow: 0 4px 16px 0 ${rgba(shadowColors.blur, 0.4)};
  z-index: ${zIndex.MODAL};
`

interface ChildrenButtonProps {
  isActive?: boolean
  iconWidth?: string
}

export const ChildrenButton = styled(Button.Flat)<ChildrenButtonProps>`
  width: fit-content;
  height: fit-content;
  padding: 0;

  svg {
    ${({ iconWidth }) => iconWidth && `width: ${iconWidth};`}
    color: ${props => (props.isActive ? mainColors.accentMedium : mainColors.neutralDark)};

    &:hover {
      color: ${mainColors.accentMedium};
    }
  }
`
