import styled from 'styled-components'

import { mainColors, shadowColors } from '../../../../styles'
import { rgba } from '../../../../utils'

export const EmptyWrapper = styled.div`
  height: 100%;
  width: 248px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${mainColors.neutralMedium};
  background-color: ${mainColors.white};
  box-shadow: 0 6px 32px 0 ${rgba(shadowColors.neutralShadow, 0.2)};

  svg {
    min-width: 44px;
  }
`
