import React from 'react'

function _IconAlertSuccess(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props} ref={ref}>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" fillOpacity=".01" d="M0 0h16v16H0z" />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0-1.273A5.727 5.727 0 1 0 8 2.273a5.727 5.727 0 0 0 0 11.454zm-.756-4.704l3.495-3.389a.636.636 0 0 1 .886.914l-3.938 3.818a.636.636 0 0 1-.886 0L5.011 8.63a.636.636 0 1 1 .887-.913l1.346 1.306z"
        />
      </g>
    </svg>
  )
}

const IconAlertSuccess = React.memo(React.forwardRef(_IconAlertSuccess))

export { IconAlertSuccess }
