import React from 'react'

function _IconAlertInfo(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props} ref={ref}>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" fillOpacity=".01" d="M0 16h16V0H0z" />
        <path
          fill="currentColor"
          d="M8.04 1a7.04 7.04 0 1 0 0 14.08A7.04 7.04 0 0 0 8.04 1zm0 1.28a5.76 5.76 0 1 1 0 11.52 5.76 5.76 0 0 1 0-11.52zM7.4 10.6a.64.64 0 1 0 1.28 0V8.04a.64.64 0 1 0-1.28 0v2.56zm.64-5.76a.64.64 0 1 0 0 1.28.64.64 0 0 0 0-1.28z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.28"
          d="M8 5.44"
        />
      </g>
    </svg>
  )
}

const IconAlertInfo = React.memo(React.forwardRef(_IconAlertInfo))

export { IconAlertInfo }
