import React, { forwardRef, Ref, SVGProps } from 'react'

function _Separator(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="270" height="2" viewBox="0 0 270 2" fill="none" {...props} ref={ref}>
      <path d="M0 1H270" stroke="url(#paint0_linear_23998_173776)" />
      <defs>
        <linearGradient
          id="paint0_linear_23998_173776"
          x1="0"
          y1="1"
          x2="270"
          y2="1.00002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9EEF1" stopOpacity="0" />
          <stop offset="0.111574" stopColor="#E9EEF1" />
          <stop offset="0.889271" stopColor="#E9EEF1" />
          <stop offset="1" stopColor="#E9EEF1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const Separator = forwardRef(_Separator)
