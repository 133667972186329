import React, { useMemo } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'

import { getNewUuid } from '@avantstay/backoffice-core'

import { IDragAndDropResultProps, IDragAndDropWrapperProps } from '../_types'
import { DragAndDropItem } from './DragAndDropItem'
import * as S from './DragAndDropWrapper.styles'

function mapAndInvoke(onDragEnd: (result: IDragAndDropResultProps) => void) {
  return function ({ source, destination }: DropResult): void {
    if (destination != null) {
      const result: IDragAndDropResultProps = {
        source: {
          id: source.droppableId,
          index: source.index,
        },
        destination: {
          id: destination.droppableId,
          index: destination.index,
        },
      }
      onDragEnd(result)
    }
  }
}

export function DragAndDropWrapper<T>({
  onDragEnd,
  chunks,
  direction,
  render,
  itemsPerLine,
}: IDragAndDropWrapperProps<T>) {
  const hash = useMemo(() => getNewUuid(), [])
  return (
    <DragDropContext onDragEnd={mapAndInvoke(onDragEnd)}>
      {chunks.map(({ id: droppableId, items }, rowIndex: number) => (
        <Droppable key={`${droppableId}-${hash}`} droppableId={droppableId} direction={direction}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={direction === 'horizontal' ? S.horizontalStyle : undefined}
              {...provided.droppableProps}
            >
              <S.PlaceholderContainer isDraggingOver={snapshot.isDraggingOver}>
                {items.map((_, key) => (
                  <S.Placeholder key={`drag-drop-placeholder-${key}`} />
                ))}
              </S.PlaceholderContainer>
              {items.map((item, index) => {
                const itemIndex = rowIndex === 0 ? index : rowIndex * itemsPerLine + index
                return (
                  <DragAndDropItem
                    key={`${hash}-${droppableId}-${itemIndex}`}
                    item={item}
                    index={itemIndex}
                    render={render}
                  />
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </DragDropContext>
  )
}
