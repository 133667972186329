import { trackedAction } from 'mobx-async'
import { Alert } from '@avantstay/backoffice-core'
import { arriere } from '@arriere/index'
import { CreateAssetInput } from '@arriere/types/Global.types'
import { trackError } from '@tracking/customEventReport'

class _UploadFileStore {
  @trackedAction
  async createAsset(assetInput: CreateAssetInput) {
    try {
      const response = await arriere.global.createAssetMutation({
        ...assetInput,
      })

      if (response) {
        return response
      } else {
        throw new Error('Error uploading file, please try again.')
      }
    } catch (error) {
      trackError('Error uploading file', { error, ...assetInput })
      Alert.Error(`Error uploading file: ${error}`)
    }
  }
}

export const UploadFileStore = new _UploadFileStore()
