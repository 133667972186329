import React, { Ref } from 'react'

function _IconStats(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7071 4.29289C24.0976 4.68342 24.0976 5.31658 23.7071 5.70711L14.2071 15.2071C13.8166 15.5976 13.1834 15.5976 12.7929 15.2071L8.5 10.9142L1.70711 17.7071C1.31658 18.0976 0.683417 18.0976 0.292893 17.7071C-0.097631 17.3166 -0.097631 16.6834 0.292893 16.2929L7.79289 8.79289C8.18342 8.40237 8.81658 8.40237 9.20711 8.79289L13.5 13.0858L22.2929 4.29289C22.6834 3.90237 23.3166 3.90237 23.7071 4.29289Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5C16 4.44772 16.4477 4 17 4H23C23.5523 4 24 4.44772 24 5V11C24 11.5523 23.5523 12 23 12C22.4477 12 22 11.5523 22 11V6H17C16.4477 6 16 5.55228 16 5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconStats = React.forwardRef(_IconStats)
