import React from 'react'

import { IconClose } from '@avantstay/backoffice-vectors'

import { OptionProps } from '../SelectField.types'
import * as S from './MultiValue.styles'

interface MultiValueProps<OptionValueType> {
  option: OptionProps<OptionValueType>
  handleDeselect: (option: OptionProps<OptionValueType>) => void
}

export function MultiValue<OptionValueType>({
  option,
  handleDeselect,
}: MultiValueProps<OptionValueType>) {
  const { label, subtitle } = option
  return (
    <S.MultiValue>
      <S.MultiValueLabel>
        <S.SelectedLabel>{label}</S.SelectedLabel>
        {subtitle && <S.SelectedSubtitle>{subtitle}</S.SelectedSubtitle>}
      </S.MultiValueLabel>
      <S.MultiValueDeselect type="button" onClick={() => handleDeselect(option)}>
        <IconClose />
      </S.MultiValueDeselect>
    </S.MultiValue>
  )
}
