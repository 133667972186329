import React, { useState } from 'react'
import { followCursor as followCursorPlugin } from 'tippy.js'

import * as S from './Tooltip.styles'
import { TooltipProps, TooltipTheme } from './Tooltip.types'

export const Tooltip = ({
  children,
  content,
  theme = TooltipTheme.dark,
  placement = 'right',
  followCursor = false,
  plugins = [],
  isVisible = true,
  ...props
}: TooltipProps): JSX.Element => {
  const [showContent, setShowContent] = useState(false)
  const autoLoadedPlugins = []

  if (followCursor !== false) {
    autoLoadedPlugins.push(followCursorPlugin)
  }

  const getContent = () => {
    if (!showContent) {
      return null
    }

    return typeof content === 'function' ? content() : content
  }

  const tooltipContent = getContent()

  return (
    <S.Container
      theme={theme}
      content={tooltipContent}
      placement={placement}
      followCursor={followCursor}
      data-cy="tapechart-tooltip"
      plugins={[...autoLoadedPlugins, ...plugins]}
      isVisible={!!tooltipContent && isVisible}
      onShow={() => {
        setShowContent(true)
      }}
      onHide={() => {
        setShowContent(false)
      }}
      animation={false}
      {...props}
    >
      {children}
    </S.Container>
  )
}
