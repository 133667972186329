import styled from 'styled-components'

import { IconClose as IconCloseComponent } from '@avantstay/backoffice-vectors'

import { _S_Normal, ellipsis, mainColors } from '../../../styles'

export const AppliedFiltersBox = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 10px;
  background-color: ${mainColors.neutralMedium};
  border-radius: 2px;
`

export const FilterName = styled.p`
  ${_S_Normal};
  color: ${mainColors.black};
  min-width: max-content;
`

export const SelectedFilters = styled.p<{ isCapitalized: boolean }>`
  ${_S_Normal};
  color: ${mainColors.neutralExtremelyDark};
  ${({ isCapitalized }) => isCapitalized && 'text-transform: capitalize'};
  ${ellipsis};
`

const SIDEBAR_MAX_WIDTH = 230

const ICON_CLOSE_WIDTH_PLUS_PADDING = 46

export const ClickableArea = styled.div<{ showFullSize?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: ${({ showFullSize }) =>
    showFullSize ? '100%' : `calc(${SIDEBAR_MAX_WIDTH}px - ${ICON_CLOSE_WIDTH_PLUS_PADDING}px);`};
`

export const IconClose = styled(IconCloseComponent)`
  color: ${mainColors.neutralExtraDark};
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    color: ${mainColors.neutralExtremelyDark};
  }
`
