import { isEmpty, isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { FieldHeader, getNewUuid, useIsMobile } from '@avantstay/backoffice-core'

import { IconInfo } from '@avantstay/backoffice-vectors'

import { DragAndDropDirection } from '@new/__global/components/DragAndDropGrid/_types'
import { DragAndDropGrid } from '@new/__global/components/DragAndDropGrid/DragAndDropGrid'
import { FilePreviewMode } from '@new/__ui/UploadArea/FilePreview/_enums'

import { FilePreviewType, IDragAreaStatus, UploadAreaProps, UploadAreaType } from './_types'
import { getFilesWithErrorsIndexes, getPreviewTitleByType } from './_utils'
import FilePreview from './FilePreview'
import useManageAssets, { AssetWithDocumentProps } from './hooks/useManageAssets'
import * as S from './UploadArea.styles'

const ITEMS_PER_LINE_GRID = 4
const ITEMS_PER_LINE_GRID_MOBILE = 2
const ITEMS_PER_LINE_LIST = 1

// Keep this export clean, free of wrapping HOCs
export function _UploadArea({
  accept,
  areaStyles,
  dropAreaMessage,
  filePreviewActions,
  fieldRestriction,
  hidePreview = false,
  initialFiles = [],
  filePreviewType = FilePreviewType.photoGallery,
  isSelecting = false,
  onChange,
  onChangeWithStatus,
  onSelectFile,
  onRemoveFile,
  selectedFiles,
  targetId,
  title,
  uploadAreaType,
  uploadSingleFile = false,
  uploadService,
  viewMode,
  disabled,
}: UploadAreaProps) {
  const {
    files,
    handleDrop,
    handleInputChange,
    handleRetryFile,
    sortFiles,
    removeFile,
    filesUploadProgress,
    filesWithError,
  } = useManageAssets(targetId, initialFiles, uploadService)
  const [status, setStatus] = useState<IDragAreaStatus>(IDragAreaStatus.idle)
  const areaId = useMemo(() => getNewUuid(), [])

  const isMobile = useIsMobile()
  const isDocument = uploadAreaType === UploadAreaType.document
  const shouldRenderDropArea = isDocument || uploadSingleFile ? isEmpty(files) : true

  const handleRemoveFile = useCallback(
    (index: number, file: AssetWithDocumentProps) => {
      removeFile(index)

      if (onRemoveFile) {
        onRemoveFile(file)
      }
    },
    [removeFile, onRemoveFile],
  )

  const handleSetStatus = useCallback(
    (newStatus: IDragAreaStatus) => (e: React.DragEvent<HTMLLabelElement>) => {
      e.preventDefault()
      setStatus(newStatus)
    },
    [setStatus],
  )

  useEffect(() => {
    if (!isEqual(files, initialFiles)) {
      if (onChange) {
        onChange(files)
      }
    }

    if (!isEqual(files, initialFiles) || Object.keys(filesUploadProgress).length > 0) {
      if (onChangeWithStatus) {
        onChangeWithStatus({ files, filesUploadProgress, filesWithError })
      }
    }

    if (uploadSingleFile) {
      files.map((file, index) => index > 0 && handleRemoveFile(index, file))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, filesUploadProgress, filesWithError])

  const renderFilePreviewWithDraggableGrid = () => {
    const itemsPerLineOnGrid = isMobile ? ITEMS_PER_LINE_GRID_MOBILE : ITEMS_PER_LINE_GRID

    return (
      <S.PreviewContainer filePreviewType={filePreviewType}>
        <S.PreviewTitles>
          {getPreviewTitleByType(filePreviewType)} &bull; {files.length}
        </S.PreviewTitles>
        <DragAndDropGrid
          items={files}
          itemsPerLine={
            viewMode && viewMode === FilePreviewMode.list ? ITEMS_PER_LINE_LIST : itemsPerLineOnGrid
          }
          direction={
            viewMode && viewMode === FilePreviewMode.list
              ? DragAndDropDirection.vertical
              : DragAndDropDirection.horizontal
          }
          onDragEnd={sortFiles}
          renderItem={(file, index) => {
            const handleMakeAsCover = () => {
              sortFiles(index, 0)
            }

            return (
              <FilePreview
                index={index}
                key={file.id}
                file={file}
                allFiles={files}
                filePreviewActions={filePreviewActions}
                fileUploadProgress={
                  filesUploadProgress[file.id as keyof typeof filesUploadProgress]
                }
                filesWithError={filesWithError}
                isFileSelected={selectedFiles?.includes(file.id)}
                filePreviewType={filePreviewType}
                isSelecting={isSelecting}
                onMakeAsCover={handleMakeAsCover}
                onRetryUpload={handleRetryFile}
                onSelectFile={onSelectFile}
                removeFile={() => handleRemoveFile(index, file)}
                viewMode={viewMode}
              />
            )
          }}
        />
      </S.PreviewContainer>
    )
  }

  const Wrapper = title ? FieldHeader.Default : S.Container

  const filesWithErrorsIndexes = getFilesWithErrorsIndexes({ files, filesWithError })

  return (
    <S.Container disabled={disabled}>
      {shouldRenderDropArea && (
        <Wrapper title={title ?? ''} restriction={fieldRestriction}>
          <S.HiddenInput
            id={areaId}
            accept={accept}
            multiple={!uploadSingleFile}
            type="file"
            onChange={handleInputChange}
          />
          <S.DropArea
            htmlFor={areaId}
            active={status === IDragAreaStatus.dragOver}
            onDragOver={handleSetStatus(IDragAreaStatus.dragOver)}
            onDragLeave={handleSetStatus(IDragAreaStatus.idle)}
            onDrop={handleDrop}
            style={areaStyles}
          >
            {dropAreaMessage ?? (
              <p>
                Drag here or <span>browse</span> to upload
              </p>
            )}
          </S.DropArea>
        </Wrapper>
      )}
      {filesWithErrorsIndexes.length > 0 ? (
        <S.ErrorContainer>
          <IconInfo />
          <span>
            Image <S.ErrorIndexes>{filesWithErrorsIndexes.join(', ')}</S.ErrorIndexes> has not been
            uploaded. Try to upload again
          </span>
        </S.ErrorContainer>
      ) : null}

      {!hidePreview &&
        isDocument &&
        !isEmpty(files) &&
        files.map(
          (file, index) =>
            filesUploadProgress !== undefined && (
              <FilePreview
                index={index}
                key={file.id}
                allFiles={files}
                file={file}
                fileUploadProgress={
                  filesUploadProgress[file.id as keyof typeof filesUploadProgress]
                }
                filesWithError={filesWithError}
                filePreviewType={filePreviewType}
                removeFile={() => handleRemoveFile(index, file)}
                uploadAreaType={uploadAreaType}
                viewMode={FilePreviewMode.grid}
              />
            ),
        )}

      {!hidePreview && !isDocument && renderFilePreviewWithDraggableGrid()}
    </S.Container>
  )
}

/**
 * @deprecated import UploadArea from  @avantstay/backoffice-core instead
 */
export const UploadArea = _UploadArea
