// @ts-nocheck
import React, { FC } from 'react'

import { Tooltip } from '@avantstay/backoffice-core'

import { IconCheck, IconDownload, IconInfo } from '@avantstay/backoffice-vectors'

import { IconReload, IconTrash } from '@new/__ui/__vectors'

import * as Preview from '../../FilePreview/FilePreview.styles'
import * as S from './FilePreviewActions.styles'

interface FilePreviewActionsProps {
  decodedFileName: string
  isCover: boolean
  isLowResolution: boolean
  isImage: boolean
  isFileUploaded: boolean
  filePosition: number
  resolution?: FileResolutionProps
  isSelecting?: boolean
  fileSize?: number
  isFileSelected?: boolean
  hasError?: boolean
  onRetryUpload: VoidFunction
  onRemoveFile: VoidFunction
  onSelectFile: VoidFunction
  onDownloadFile: VoidFunction
}

export const FilePreviewActions: FC<PropsWithChildren<FilePreviewActionsProps>> = ({
  children,
  decodedFileName,
  isCover,
  isLowResolution,
  isImage,
  isFileUploaded,
  filePosition,
  resolution,
  isSelecting,
  isFileSelected,
  hasError,
  onRetryUpload,
  onRemoveFile,
  onSelectFile,
  onDownloadFile,
}) => {
  return (
    <S.DetailsContainer $isCover={isCover} warning={isLowResolution} error={hasError}>
      <S.FileTitle>
        <S.FileIndex error={hasError}>{filePosition}</S.FileIndex>
        {hasError ? <Preview.Error> &bull; Upload failed</Preview.Error> : null}
      </S.FileTitle>
      <S.DetailActions>
        {!hasError && !isSelecting && (
          <Tooltip
            content={
              <S.FileInfoContainer>
                <S.FileInfoLabel>
                  Name: <S.FileInfoContent>{decodedFileName}</S.FileInfoContent>
                </S.FileInfoLabel>
                {isImage && resolution?.height && resolution?.width ? (
                  <S.FileInfoLabel>
                    Dimension:{' '}
                    <S.FileInfoContent>
                      {resolution.height} x {resolution.width}
                    </S.FileInfoContent>
                  </S.FileInfoLabel>
                ) : null}
              </S.FileInfoContainer>
            }
            placement="top"
          >
            <IconInfo />
          </Tooltip>
        )}
        {children}
        {hasError && (
          <Tooltip content="Retry" placement="top">
            <IconReload onClick={onRetryUpload} />
          </Tooltip>
        )}
        {isFileUploaded && !isSelecting && (
          <Tooltip content="Download file" placement="top">
            <IconDownload onClick={onDownloadFile} />
          </Tooltip>
        )}
        {!isSelecting && (
          <Tooltip content="Remove" placement="top">
            <IconTrash width={20} height={20} onClick={onRemoveFile} />
          </Tooltip>
        )}
        {isFileUploaded && isSelecting && (
          <S.SelectImageButton isSelected={isFileSelected} onClick={onSelectFile}>
            <IconCheck />
          </S.SelectImageButton>
        )}
      </S.DetailActions>
    </S.DetailsContainer>
  )
}
