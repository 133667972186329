import arriereBackoffice, {
  BookKind,
  BookSectionUpdateInput,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

export default {
  async getSections(kind: BookKind): Promise<
    // TODO: We only need to specify the return here because the generated types are not correct.
    // Region is actually nullable (also undefined here to fix some other types issues related to OptionProps)
    // See https://gitlab.com/avantstay/frontend/tableau/-/merge_requests/43
    Array<{
      region?: null | {
        id: string
        name: string
      }
      title: string
      key: string
      listOrder: number
      defaultValue: string
      propertiesUsing: string[]
      propertiesAvaliable: string[]
    }>
  > {
    return arriereBackoffice.queries.bookSections({
      __args: { kind },
      key: true,
      title: true,
      defaultValue: true,
      listOrder: true,
      propertiesUsing: true,
      propertiesAvaliable: true,
      region: {
        id: true,
        name: true,
      },
    })
  },

  async getSectionsForRegion(regionId: string, kind: BookKind) {
    return arriereBackoffice.queries.bookSectionsForRegion({
      __args: { regionId, kind },
      bookKind: true,
      key: true,
      title: true,
      defaultValue: true,
      listOrder: true,
      propertiesUsing: true,
      propertiesAvaliable: true,
    })
  },

  async createSection(title: string, kind: BookKind) {
    return arriereBackoffice.mutations.homeBooks({
      createSection: {
        __args: { title, kind },
        key: true,
        bookKind: true,
        region: {
          id: true,
          name: true,
        },
        title: true,
        listOrder: true,
        defaultValue: true,
        propertiesUsing: true,
        propertiesAvaliable: true,
      },
    })
  },

  async deleteSection(key: string, kind: BookKind) {
    return arriereBackoffice.mutations.homeBooks({
      deleteSection: {
        __args: { key, kind },
      },
    })
  },

  async updateSections(sections: BookSectionUpdateInput[]) {
    return arriereBackoffice.mutations.homeBooks({
      updateSections: {
        __args: { sections },
      },
    })
  },
}
