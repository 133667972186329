import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconVrbo = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" {...props} ref={ref}>
      <path
        d="M14.5097 7.18349C14.5069 7.19428 14.5112 7.206 14.5205 7.21217C14.5351 7.2219 14.5551 7.2142 14.5602 7.19739C15.6124 3.71124 16.0172 1.97694 15.3467 0.894192C15.1973 0.653007 14.913 0.545898 14.6293 0.545898H13.5957C13.586 0.545898 13.5781 0.553745 13.5781 0.563425C13.5781 0.570817 13.5829 0.577466 13.5899 0.579995C15.237 1.17976 15.6224 2.84972 14.5097 7.18349Z"
        fill="#8EC089"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6367 0.560913C19.3502 0.560913 19.0653 0.631451 18.8314 0.79688C17.4739 1.75726 16.5325 3.48762 14.7249 7.31445C14.7191 7.32677 14.723 7.34186 14.7343 7.34941C14.7478 7.35837 14.7663 7.35337 14.774 7.33912C17.0341 3.14147 18.3349 1.45925 21.25 0.594432C21.2572 0.592279 21.2624 0.585567 21.2624 0.577996C21.2624 0.568561 21.2547 0.560913 21.2453 0.560913H19.6367Z"
        fill="#2474DE"
      />
      <path
        d="M14.6024 7.25627C14.5981 7.268 14.6023 7.28145 14.6127 7.28838C14.6268 7.29776 14.6462 7.29139 14.6529 7.27581C16.5076 2.92074 16.987 1.74119 17.7603 0.596576C17.7628 0.592794 17.7642 0.588324 17.7642 0.58376C17.7642 0.571142 17.754 0.560913 17.7414 0.560913H16.2991C16.2744 0.560913 16.2543 0.580958 16.2543 0.605685C16.4184 2.34819 15.8084 3.95692 14.6024 7.25627Z"
        fill="#E85F24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.41846 0.765598C2.2555 0.624266 2.04113 0.560913 1.82543 0.560913H0.829741C0.819949 0.560913 0.812012 0.568851 0.812012 0.578642C0.812012 0.58595 0.816597 0.592545 0.823398 0.595218C1.91839 1.02565 2.51051 1.81753 2.97625 3.52927C3.08644 3.93427 3.17212 4.34578 3.26115 4.75595C3.81839 7.32318 5.96742 17.1545 6.35718 18.9374C6.39383 19.105 6.54031 19.2155 6.71192 19.2155C6.94498 19.2155 7.11839 19.0001 7.06865 18.7724L3.84671 4.02321C3.37291 1.85743 2.86792 1.15542 2.41846 0.765598Z"
        fill="#76C5F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.96937 17.1856C9.03977 17.5094 9.47948 17.5621 9.62436 17.2641C9.6584 17.1941 9.66805 17.1147 9.65178 17.0385L6.89633 4.14257C6.54151 2.68279 6.49602 1.80202 6.57632 1.18931C6.61661 0.881867 6.39659 0.575806 6.08652 0.575806C5.83894 0.575806 5.63506 0.774161 5.64019 1.02169C5.65382 1.67993 5.74208 2.38702 6.05917 3.79932L8.96937 17.1856Z"
        fill="#2474DE"
      />
      <path
        d="M4.73654 1.13974C4.68498 0.808548 4.40371 0.560913 4.06853 0.560913H3.41955C3.40749 0.560913 3.39771 0.570695 3.39771 0.582761C3.39771 0.587927 3.39956 0.59295 3.40289 0.596904C3.96678 1.26734 4.13154 2.08863 4.56375 3.8889L7.84959 18.9282C7.88625 19.0959 8.03479 19.2155 8.20652 19.2155C8.43956 19.2155 8.61304 19.0003 8.56353 18.7726L5.31121 3.81428C4.9571 2.37817 4.82416 1.70255 4.73654 1.13974Z"
        fill="#318EEE"
      />
      <path
        d="M9.95642 15.0934C10.0268 15.4237 10.4745 15.4796 10.624 15.1768C10.6593 15.1053 10.6695 15.0241 10.6532 14.9461L8.43604 4.36621C8.01884 2.29863 8.19568 1.22438 8.87776 0.596705C8.88205 0.592761 8.88452 0.587199 8.88452 0.581375C8.88452 0.569939 8.87525 0.560669 8.86381 0.560669H8.15006C7.86794 0.560669 7.60231 0.719519 7.51429 0.987555C7.29412 1.65794 7.2856 2.48373 7.62878 4.1722L9.95642 15.0934Z"
        fill="#E85F24"
      />
      <path
        d="M9.22874 4.44104C8.72633 2.03362 9.40584 1.1116 9.93732 0.736766C10.1105 0.614596 10.3253 0.575806 10.5373 0.575806H11.963C11.9721 0.575806 11.9794 0.583132 11.9794 0.59217C11.9794 0.600001 11.9736 0.606779 11.9659 0.608294C10.0633 0.983663 9.51386 2.02908 9.9762 4.44104L11.6572 12.8908C11.6723 12.9663 11.6622 13.0447 11.6285 13.114C11.4808 13.4175 11.0323 13.3603 10.9654 13.0296L9.22874 4.44104Z"
        fill="#8EC089"
      />
    </svg>
  )
}

export const IconVrbo = forwardRef(_IconVrbo)
