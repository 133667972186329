import Analytics from 'analytics'

// @ts-ignore
import segmentPlugin from '@analytics/segment'
import { IS_PRODUCTION } from '@utils/constants'

const PROD_WRITE_KEY = 'RVae9YQGgTl5Gjj5Z8gMZZh9avHnOYcR'
const STAGING_WRITE_KEY = 'lAEabXmpx2zyCrZwhGqUls5PrFGtViz6'

const currentWriteKey = IS_PRODUCTION ? PROD_WRITE_KEY : STAGING_WRITE_KEY

const segment = Analytics({
  app: 'Voyage',
  plugins: [
    segmentPlugin({
      writeKey: currentWriteKey,
    }),
  ],
})

export { segment }
