import styled from 'styled-components'

import {
  _M_Medium,
  _M_Normal,
  mainColors,
  TextFieldStyledProps,
  textFieldStyles,
} from '@avantstay/backoffice-core'

export const Text = styled.p`
  ${_M_Normal};
  color: ${mainColors.neutralExtremelyDark};
`

export const Option = styled.div`
  ${_M_Normal};
  padding: 4px 10px;
  border-radius: 4px;
  background: ${mainColors.neutralExtraLight};
  color: ${mainColors.neutralExtremelyDark};
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: 6.8px;
  }
`

export const OptionsRoot = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  ${Option}:not(:last-child) {
    margin-bottom: 6px;
  }
`

export const BooleanTag = styled.div<{ bg: string; color: string }>`
  ${_M_Medium};
  display: inline-block;
  padding: 2px 8px;
  border-radius: 2px;
  background: ${props => props.bg};
  color: ${props => props.color};
`

export const Markdown = styled.div`
  ${_M_Normal};
  color: ${mainColors.neutralExtremelyDark};
  width: 100%;

  > * {
    width: 100%;
  }
`

export const Container = styled.div<TextFieldStyledProps>`
  ${textFieldStyles};
  display: flex;
  align-items: center;

  input {
    border: none;
  }
`
