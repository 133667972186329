import { MentionsInput } from 'react-mentions'
import styled from 'styled-components'

import { mainColors, shadowColors } from '../../styles/colors'
import { _M_Normal, _XS_Medium } from '../../styles/typography'
import { ellipsis } from '../../styles/utils'
import { rgba } from '../../utils/cssUtils'

export const SuggestionContainer = styled.div<{ focused?: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10px 20px;
  background-color: ${mainColors.white};
  cursor: pointer;

  span {
    ${_XS_Medium};
  }

  &:focus {
    background-color: ${mainColors.neutralLight};
  }

  ${({ focused }) => {
    return focused
      ? `background-color: ${mainColors.neutralLight};
        `
      : null
  }};
`

export const Name = styled.p`
  ${_M_Normal}
  line-height: 20px;
  ${ellipsis};
`

export const Link = styled.a`
  text-decoration: none;
  color: ${mainColors.accentMedium};
  cursor: pointer;
  word-break: break-word;
`

export const StyledMetionsInput = styled(MentionsInput)`
  textarea {
    &:hover {
      border: 2px solid ${mainColors.neutralDark} !important;
    }

    &:focus {
      border: 2px solid ${mainColors.accentMedium} !important;
    }

    &::placeholder {
      color: ${mainColors.neutralExtraDark};
      ${_M_Normal};
    }
  }
`

export const StyledMentionText = styled.p`
  ${_M_Normal};
  color: ${mainColors.accentMedium};
  display: inline;
  cursor: not-allowed;
`

const commonFontStyle = {
  fontFamily: "'Roboto', sans-serif",
  lineHeight: '1.5',
  fontSize: 14,
}

export const mentionStyles = {
  ...commonFontStyle,
  position: 'relative',
  zIndex: 3,
  color: mainColors.accentMedium,
  mixBlendMode: 'plus-lighter',
}

export const mentionsInputStyles = {
  control: {
    fontWeight: 'normal',
    minHeight: 76,
    outline: 'none',
  },
  '&multiLine': {
    control: {
      ...commonFontStyle,
    },
    input: {
      borderRadius: 2,
      padding: 9,
      outline: 'none',
      minHeight: 76,
      border: `2px solid ${mainColors.neutralMedium}`,
    },
  },
  suggestions: {
    transform: 'translateY(20px)',
    border: `1px solid ${mainColors.neutralMedium}`,
    boxShadow: `0px 4px 16px ${rgba(shadowColors.blur, 0.4)}`,
    borderRadius: 2,
    maxHeight: 'auto',
    maxWidth: 320,
    list: {
      maxHeight: 230,
      overflowY: 'auto',
    },
  },
}
