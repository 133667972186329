import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconLogOut = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33333 2.75C3.17862 2.75 3.03025 2.81146 2.92085 2.92085C2.81146 3.03025 2.75 3.17862 2.75 3.33333V12.6667C2.75 12.8214 2.81146 12.9697 2.92085 13.0791C3.03025 13.1885 3.17862 13.25 3.33333 13.25H6C6.41421 13.25 6.75 13.5858 6.75 14C6.75 14.4142 6.41421 14.75 6 14.75H3.33333C2.7808 14.75 2.25089 14.5305 1.86019 14.1398C1.46949 13.7491 1.25 13.2192 1.25 12.6667V3.33333C1.25 2.7808 1.46949 2.2509 1.86019 1.86019C2.2509 1.46949 2.7808 1.25 3.33333 1.25H6C6.41421 1.25 6.75 1.58579 6.75 2C6.75 2.41421 6.41421 2.75 6 2.75H3.33333ZM10.1363 4.13642C10.4292 3.84352 10.9041 3.84352 11.197 4.13642L14.5233 7.46269C14.554 7.4926 14.5821 7.52512 14.6074 7.5599C14.6291 7.58976 14.6483 7.62086 14.665 7.65291C14.7193 7.7567 14.75 7.87476 14.75 8C14.75 8.09282 14.7331 8.1817 14.7023 8.26375C14.6659 8.36099 14.6085 8.45219 14.5303 8.53041L11.197 11.8637C10.9041 12.1566 10.4292 12.1566 10.1363 11.8637C9.84343 11.5709 9.84343 11.096 10.1363 10.8031L12.1894 8.75H6C5.58579 8.75 5.25 8.41421 5.25 8C5.25 7.58579 5.58579 7.25 6 7.25H12.1892L10.1363 5.19708C9.84343 4.90418 9.84343 4.42931 10.1363 4.13642Z"
      fill="currentColor"
    />
  </svg>
)

export const IconLogOut = forwardRef(_IconLogOut)
