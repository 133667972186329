import React, { FC, PropsWithChildren } from 'react'

import * as S from './ToggleField.styles'
import { ToggleBooleanType, ToggleFieldProps, ToggleSwitchInputProps } from './ToggleField.types'

const SquaredToggleButton: FC<ToggleSwitchInputProps> = ({
  onChange,
  id,
  value,
  defaultChecked,
  label,
  title,
  toggleType = ToggleBooleanType.true,
}) => (
  <S.SquaredContainer checked={!!defaultChecked}>
    <S.SquaredHiddenInput
      data-cy={`toggle-option-${id}`}
      type="radio"
      onChange={onChange}
      id={id}
      value={value}
      defaultChecked={defaultChecked}
      name={label}
      toggleType={toggleType}
    />
    <S.ToggleSquared htmlFor={id} data-testid="input-toggle-field">
      <S.SquaredTitle>{title}</S.SquaredTitle>
    </S.ToggleSquared>
  </S.SquaredContainer>
)

// Keep this export clean, free of wrapping HOCs
const ToggleFieldOption: FC<ToggleSwitchInputProps> = ({
  onChange,
  id,
  value,
  defaultChecked,
  label,
  title,
  disabled,
  toggleType = ToggleBooleanType.true,
}) => (
  <S.Container disabled={disabled}>
    <S.HiddenInput
      type="radio"
      onChange={onChange}
      id={id}
      value={value}
      defaultChecked={defaultChecked}
      name={label}
      toggleType={toggleType}
      disabled={disabled}
    />
    <S.ToggleDefault htmlFor={id} data-testid="input-toggle-field">
      <S.Title>{title}</S.Title>
    </S.ToggleDefault>
  </S.Container>
)

const _ToggleField: FC<PropsWithChildren<ToggleFieldProps>> = ({ children, autofill = false }) => (
  <S.ToggleFieldContainer autofill={autofill}>{children}</S.ToggleFieldContainer>
)

// If you need to wrap this component with HOCs, use them here
export const ToggleField = Object.assign(_ToggleField, {
  Option: ToggleFieldOption,
  Squared: SquaredToggleButton,
})
