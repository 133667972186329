export function rgba(color: string, alpha: number) {
  if (alpha < 0 || alpha > 1) {
    throw new Error('Alpha must be between 0 and 1')
  }
  return (
    color +
    Math.round(alpha * 255)
      .toString(16)
      .padStart(2, '0')
  )
}

interface GetStripesProps {
  color: string
  bgColor: string
}

export const getStripes = ({ color, bgColor }: GetStripesProps) => {
  return `repeating-linear-gradient(135deg, ${color}, ${color} 8px, ${bgColor} 8px, ${bgColor} 16px)`
}
