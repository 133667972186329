import styled, { css } from 'styled-components'

import { _S_Medium, mainColors, semanticColors } from '../../'

const BaseMessageStyles = css`
  ${_S_Medium};
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 500px;

  > svg {
    width: 24px;
    height: 24px;
  }
`

export const MessageContainer = styled.div`
  ${BaseMessageStyles};
  color: ${semanticColors.successMedium};

  > svg {
    width: 24px;
    height: 24px;
  }
`

export const ErrorContainer = styled.div`
  ${BaseMessageStyles};
  color: ${semanticColors.failureMedium};
`

export const ExportContainer = styled.div`
  margin-right: 6px;
`

export const LoadingContainer = styled.div`
  ${BaseMessageStyles};
  margin-right: 16px;
  color: ${mainColors.neutralExtraDark};
`

export const ButtonContentWrapper = styled.div``
