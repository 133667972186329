import React, { Ref } from 'react'

function _IconApprove(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4 15C26.4 21.296 21.296 26.4 15 26.4C8.70395 26.4 3.6 21.296 3.6 15C3.6 8.70395 8.70395 3.6 15 3.6C21.296 3.6 26.4 8.70395 26.4 15ZM28 15C28 22.1797 22.1797 28 15 28C7.8203 28 2 22.1797 2 15C2 7.8203 7.8203 2 15 2C22.1797 2 28 7.8203 28 15ZM22.0473 11.5838C22.3697 11.2816 22.386 10.7754 22.0838 10.453C21.7816 10.1307 21.2754 10.1144 20.953 10.4166L13.5181 17.3868L10.5659 14.4345C10.2535 14.1221 9.74693 14.1221 9.43451 14.4345C9.12209 14.7469 9.12209 15.2535 9.43451 15.5659L12.866 18.9974C13.2095 19.3409 13.7636 19.3498 14.118 19.0176L22.0473 11.5838Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconApprove = React.memo(React.forwardRef(_IconApprove))
