import React, { Ref } from 'react'

function _IconExport(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.6998 12.6669C2.6998 12.8348 2.76653 12.9959 2.8853 13.1147C3.00408 13.2335 3.16517 13.3002 3.33314 13.3002L12.6665 13.3002C12.8344 13.3002 12.9955 13.2335 13.1143 13.1147C13.2331 12.9959 13.2998 12.8348 13.2998 12.6669L13.2998 10.0002C13.2998 9.6136 13.6132 9.3002 13.9998 9.3002C14.3864 9.3002 14.6998 9.6136 14.6998 10.0002L14.6998 12.6669C14.6998 13.2061 14.4856 13.7233 14.1043 14.1046C13.7229 14.486 13.2057 14.7002 12.6665 14.7002L3.33314 14.7002C2.79386 14.7002 2.27668 14.486 1.89535 14.1046C1.51403 13.7233 1.2998 13.2061 1.2998 12.6669L1.2998 10.0002C1.2998 9.6136 1.61321 9.3002 1.9998 9.3002C2.3864 9.3002 2.6998 9.6136 2.6998 10.0002L2.6998 12.6669ZM4.17182 5.82818C3.89846 5.55481 3.89846 5.1116 4.17182 4.83823L7.50464 1.50541C7.50477 1.50528 7.50489 1.50516 7.50502 1.50504L7.50516 1.5049C7.77852 1.23153 8.22174 1.23153 8.4951 1.5049L11.8284 4.83823C12.1018 5.1116 12.1018 5.55481 11.8284 5.82818C11.5551 6.10155 11.1119 6.10155 10.8385 5.82818L8.6998 3.6895L8.6998 10.0002C8.6998 10.3868 8.3864 10.7002 7.9998 10.7002C7.61321 10.7002 7.2998 10.3868 7.2998 10.0002L7.2998 3.69014L5.16177 5.82818C4.8884 6.10155 4.44519 6.10155 4.17182 5.82818Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconExport = React.forwardRef(_IconExport)
