import React from 'react'
import 'tippy.js/dist/tippy.css'

import { semanticColors } from '@avantstay/backoffice-core'

import { IconError } from '../__vectors'
import { IInfoTooltip } from './_types'
import { TooltipStyled } from './InfoTooltip.styles'

function InfoTooltip(props: IInfoTooltip) {
  const hasError = Boolean(props.error)
  const backgroundColor = hasError
    ? semanticColors.failureExtraLight
    : semanticColors.warningExtraLight
  const textColor = hasError ? semanticColors.failureMedium : semanticColors.warningDark

  return (
    <TooltipStyled
      backgroundColor={backgroundColor}
      color={textColor}
      content={props.error || props.warning}
      theme="light"
      arrow
      placement="bottom"
    >
      <IconError
        color={props.error ? semanticColors.failureMedium : semanticColors.warningMedium}
      />
    </TooltipStyled>
  )
}

export default InfoTooltip
