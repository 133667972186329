import styled from 'styled-components'

import FloatingContainer from '@avantstay/avantstay-ui/lib/FloatingContainer'

import { mainColors, shadowColors } from '../../styles/colors'
import { _M_Normal } from '../../styles/typography'
import { rgba } from '../../utils/cssUtils'

export const Wrapper = styled.div`
  position: relative;
`

export const SimpleFloatingContainer = styled(FloatingContainer)<{ margin?: string }>`
  background: ${mainColors.white};
  border: 1px solid ${mainColors.neutralLight};
  border-radius: 2px;
  box-shadow: 0px 6px 32px ${rgba(shadowColors.blur, 0.2)};
  padding: 10px 0;
  max-width: 260px;
  margin: ${({ margin }) => margin};
`

export const SimpleItem = styled.div`
  ${_M_Normal};
  padding: 7px 14px;
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 6px;
  cursor: pointer;

  &:hover {
    background: ${mainColors.neutralLight};
  }
`

export const Dot = styled.span<{ color: string }>`
  min-width: 7px;
  width: 7px;
  height: 7px;
  margin-right: 6px;
  border-radius: 22px;
  background: ${({ color }) => color};
`
