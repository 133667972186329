import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'

interface CancelScheduledSmsProps {
  messageId: string
  ticketId: number
}

export const cancelScheduledSms = async ({ messageId, ticketId }: CancelScheduledSmsProps) => {
  return arriereBackoffice.mutations.zendesk.raw({
    __alias: 'cancelScheduledSms',
    sunshineCancelScheduledSms: {
      __args: {
        smsMessageId: messageId,
        ticketId,
      },
    },
  })
}
