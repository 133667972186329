import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconHopper(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="22" height="20" viewBox="5 7 20 15" fill="none" {...props} ref={ref}>
      <path
        d="M8.68999 12.9778C8.83713 13.0223 8.98261 13.0711 9.18684 13.1356C8.73117 13.5308 8.31503 13.8691 7.92468 14.234C7.11655 14.9925 6.37649 15.8062 5.95705 16.8472L5.95443 16.8537C5.79396 17.2517 5.59118 17.7547 5.94882 18.0432C6.37539 18.3874 6.61421 17.7835 6.93208 17.6056C7.40148 17.3427 7.81324 16.9827 8.27769 16.7111C11.032 15.0998 13.883 14.845 16.8147 16.208C18.5358 17.0082 19.949 18.1988 21.1249 19.6653C21.5444 20.1884 21.9193 20.7507 22.4513 21.1746C22.7516 21.4148 23.1063 21.6224 23.4922 21.4148C23.9089 21.1909 23.8085 20.7929 23.736 20.4178C23.6057 19.7448 23.1924 19.2294 22.7794 18.7144L22.7599 18.6899C22.4585 18.3137 21.876 17.9488 21.8831 17.5877C21.8941 17.0169 22.6792 17.1806 23.0986 16.9529C24.2098 16.35 24.4277 15.6414 23.7085 14.6135C23.3835 14.1483 23.0014 13.7222 22.5276 13.4116C22 13.0657 21.9237 12.6406 21.9748 12.0491C22.0829 10.7941 22.016 9.53301 21.3626 8.40099C21.0843 7.91902 20.6599 7.31561 20.0522 7.55361C19.5438 7.75258 19.8007 8.41726 19.9122 8.85532C20.1812 9.91251 20.5194 10.9524 20.8246 12.0009C20.8949 12.2416 21.1288 12.5864 20.8191 12.7078C20.4409 12.8564 20.4573 12.4032 20.3739 12.1912C20.0033 11.2484 19.6651 10.2931 19.3132 9.34271C19.1227 8.82767 18.7906 8.41292 18.3601 8.07191C17.9242 7.72656 17.6377 7.90872 17.541 8.36847C17.4307 8.89381 17.4938 9.42132 17.6508 9.94613C17.9429 10.922 18.6401 11.6501 19.1749 12.4785C19.3533 12.7545 19.8079 13.1069 19.5603 13.3432C19.1908 13.6962 18.9086 13.1611 18.6528 12.9464C16.4007 11.057 13.9692 9.95101 10.9722 11.1345C10.6648 11.256 10.5648 11.1643 10.516 10.8342C10.348 9.69999 10.1108 9.58831 9.05618 10.0724C8.63125 10.2671 8.26067 10.5354 7.98288 10.9079C7.36305 11.7396 7.68642 12.6748 8.68999 12.9778Z"
        fill="white"
      />
    </svg>
  )
}

export const IconHopper = forwardRef(_IconHopper)
