import styled from 'styled-components'

import { _S_Normal, _XL_Bold, mainColors, zIndex } from '../../styles'
import { SlidingDrawer } from '../SlidingDrawer'

export const SlidingDrawerStyled = styled(SlidingDrawer)`
  z-index: ${zIndex.FLOATING_CONTAINER_TOGGLER};
`

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 52px 30px 40px;
`

export const WarningText = styled.p`
  ${_S_Normal};
  text-align: center;
  color: ${mainColors.neutralExtremelyDark};
  margin-bottom: 20px;
  max-width: 250px;
`

export const WarningTitle = styled.h3`
  ${_XL_Bold};
  text-align: center;
  margin: 30px 0 6px;
`
