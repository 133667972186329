import styled from 'styled-components'

import { _XS_Medium, ellipsis } from '../../styles'
import { BadgeContainerProps } from './Badge.types'

export const BadgeDefault = styled.div<BadgeContainerProps>`
  height: auto;
  max-width: max-content;
  min-width: max-content;
  border-radius: 3px;
  display: flex;
  align-items: center;
  ${_XS_Medium};
  line-height: 18px;
  padding: 0 8px;
  ${ellipsis};

  ${({ badgeColor, inactive, dashed }) =>
    badgeColor &&
    `
      ${!inactive && `background: ${badgeColor.background}`};
      color: ${badgeColor.color};
      border: 1px ${dashed ? 'dashed' : 'solid'} ${badgeColor.borderColor || badgeColor.color};
  `};

  ${({ badgeColor, inactive, dashed }) =>
    inactive &&
    `
    cursor: pointer;
    opacity: 0.5;
    border: 1px ${dashed ? 'dashed' : 'solid'} ${badgeColor.borderColor || badgeColor.color};
    &:hover {
      opacity: 1;
      color: ${badgeColor.hover ?? badgeColor.color};
      border-color: ${badgeColor.hover ?? (badgeColor.borderColor || badgeColor.color)};
    }
  `}

  ${({ interactive, badgeColor }) =>
    interactive &&
    `
    cursor: pointer;
    &:hover {
      color: ${badgeColor.hover ?? badgeColor.color};
      border-color: ${badgeColor.hover ?? (badgeColor.borderColor || badgeColor.color)};
    }
  `}

  ${({ hasDropdown }) =>
    hasDropdown &&
    `
    position: relative;
    overflow: visible;
  `}
`
