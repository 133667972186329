import React from 'react'

import { rgba } from '@avantstay/avantstay-ui/lib/utils/cssUtils'

import { mainColors, riskChannelsColors, semanticColors, tapeChartColors } from '../../styles'

export interface BadgeColorsProps {
  color: string
  background: string
  borderColor?: string
  hover?: string
}

export interface BadgeProps extends BadgeContainerProps {
  children: React.ReactNode
  dataCy?: string
  styles?: React.CSSProperties
}

export interface BadgeContainerProps {
  badgeColor: BadgeColorsProps
  className?: string
  interactive?: boolean
  onClick?: () => void
  hasDropdown?: boolean
  dashed?: boolean
  inactive?: boolean
}

export const BadgeColors = {
  primary: {
    color: mainColors.primaryMedium,
    background: rgba(mainColors.primaryMedium, 0.1),
    hover: mainColors.primaryDark,
  },
  accent: {
    color: mainColors.accentMedium,
    background: mainColors.accentExtremelyLight,
    hover: mainColors.accentDark,
  },
  success: {
    color: semanticColors.successMedium,
    background: semanticColors.successExtraLight,
    hover: semanticColors.successDark,
  },
  successDark: {
    color: semanticColors.successDark,
    background: semanticColors.successExtraLight,
    borderColor: semanticColors.successDark,
    hover: semanticColors.successDark,
  },
  failure: {
    color: semanticColors.failureMedium,
    background: semanticColors.failureExtraLight,
    hover: semanticColors.failureDark,
  },
  warning: {
    color: semanticColors.warningDark,
    background: semanticColors.warningExtraLight,
    hover: semanticColors.warningExtraDark,
  },
  neutral: {
    color: mainColors.neutralExtraDark,
    background: mainColors.neutralLight,
    hover: mainColors.neutralExtremelyDark,
  },
  riskVeryHigh: {
    color: riskChannelsColors.riskVeryHigh,
    background: rgba(riskChannelsColors.riskVeryHigh, 0.1),
  },
  riskHigh: {
    color: riskChannelsColors.riskHigh,
    background: rgba(riskChannelsColors.riskHigh, 0.1),
  },
  riskMedium: {
    color: riskChannelsColors.riskMedium,
    background: rgba(riskChannelsColors.riskMedium, 0.1),
  },
  riskLow: {
    color: riskChannelsColors.riskLow,
    background: rgba(riskChannelsColors.riskLow, 0.1),
  },
  riskVeryLow: {
    color: mainColors.neutralExtraDark,
    background: rgba(mainColors.neutralExtraDark, 0.1),
    hover: mainColors.neutralExtremelyDark,
  },
  riskUndefined: {
    color: riskChannelsColors.riskUndefined,
    background: rgba(riskChannelsColors.riskUndefined, 0.1),
  },
  fullyPaid: {
    color: tapeChartColors.fullyPaidPrimaryColor,
    background: tapeChartColors.fullyPaidSecondaryColor,
  },
  notPaid: {
    color: tapeChartColors.notPaidPrimaryColor,
    background: tapeChartColors.notPaidSecondaryColor,
  },
  overPaid: {
    color: tapeChartColors.overPaidPrimaryColor,
    background: tapeChartColors.overPaidSecondaryColor,
  },
  partiallyPaid: {
    color: tapeChartColors.partiallyPaidPrimaryColor,
    background: tapeChartColors.partiallyPaidSecondaryColor,
  },
  listingWarn: {
    color: semanticColors.warningExtraLight,
    background: semanticColors.warningDark,
    hover: semanticColors.warningExtraDark,
  },
  listingProbation: {
    color: semanticColors.failureExtraLight,
    background: semanticColors.failureMedium,
    hover: semanticColors.failureMedium,
  },
  listingAdditionalWarn: {
    color: semanticColors.failureExtraLight,
    background: semanticColors.failureDark,
    hover: semanticColors.failureDark,
  },
  listingPendingRemoval: {
    color: mainColors.white,
    background: riskChannelsColors.riskVeryHighDark,
    borderColor: riskChannelsColors.riskVeryHighDark,
  },
  listingRemoved: {
    color: mainColors.white,
    background: mainColors.black,
    borderColor: mainColors.black,
  },
  menuItem: {
    background: semanticColors.failureMedium,
    color: mainColors.white,
  },
  channelAcquisitionWebsites: {
    color: riskChannelsColors.channelAcquisitionWebsites,
    background: rgba(riskChannelsColors.channelAcquisitionWebsites, 0.1),
  },
  channelAllegiantAir: {
    color: riskChannelsColors.channelAllegiantAir,
    background: rgba(riskChannelsColors.channelAllegiantAir, 0.1),
  },
  channelAirbnb: {
    color: riskChannelsColors.channelAirbnb,
    background: rgba(riskChannelsColors.channelAirbnb, 0.1),
    hover: riskChannelsColors.channelAirbnbDark,
  },
  channelBlueGround: {
    color: riskChannelsColors.channelBlueGroundDark,
    background: riskChannelsColors.channelBlueGroundLight,
  },
  channelBnbFinder: {
    color: riskChannelsColors.channelBnbFinder,
    background: rgba(riskChannelsColors.channelBnbFinder, 0.1),
  },
  channelBonotel: {
    color: riskChannelsColors.channelBonotel,
    background: rgba(riskChannelsColors.channelBonotel, 0.1),
  },
  channelBookingCom: {
    color: riskChannelsColors.channelBookingCom,
    background: rgba(riskChannelsColors.channelBookingCom, 0.1),
  },
  channelExpedia: {
    color: riskChannelsColors.channelExpedia,
    background: rgba(riskChannelsColors.channelExpedia, 0.1),
  },
  channelFrontdesk: {
    color: riskChannelsColors.channelFrontdesk,
    background: rgba(riskChannelsColors.channelFrontdesk, 0.1),
  },
  channelGoogle: {
    color: riskChannelsColors.channelGoogle,
    background: rgba(riskChannelsColors.channelGoogle, 0.1),
  },
  channelGoogleTravel: {
    color: mainColors.accentMedium,
    background: mainColors.accentExtremelyLight,
  },
  channelHomesAndVillas: {
    color: riskChannelsColors.channelHomesAndVillas,
    background: rgba(riskChannelsColors.channelHomesAndVillas, 0.1),
  },
  channelHomeAway: {
    color: riskChannelsColors.channelHomeAway,
    background: rgba(riskChannelsColors.channelHomeAway, 0.1),
    hover: riskChannelsColors.channelHomeAwayDark,
  },
  channelHopper: {
    color: riskChannelsColors.channelHopper,
    background: rgba(riskChannelsColors.channelHopper, 0.1),
  },
  channelLighthouse: {
    color: mainColors.primaryMedium,
    background: mainColors.neutralDark,
    hover: mainColors.primaryMedium,
  },
  channelMarriott: {
    color: riskChannelsColors.channelMarriott,
    background: rgba(riskChannelsColors.channelMarriott, 0.1),
  },
  channelMobile: {
    color: riskChannelsColors.channelMobile,
    background: rgba(riskChannelsColors.channelMobile, 0.1),
  },
  channelNonPayingGuest: {
    color: riskChannelsColors.channelNonPayingGuest,
    background: rgba(riskChannelsColors.channelNonPayingGuest, 0.1),
  },
  channelOther: {
    color: riskChannelsColors.channelOther,
    background: mainColors.neutralLight,
  },
  channelPersona: {
    color: riskChannelsColors.channelPersona,
    background: riskChannelsColors.channelPersonaLight,
    borderColor: riskChannelsColors.channelPersona,
  },
  channelQuintess: {
    color: riskChannelsColors.channelQuintess,
    background: rgba(riskChannelsColors.channelQuintess, 0.1),
  },
  channelRentalsUnited: {
    color: riskChannelsColors.channelRentalsUnited,
    background: rgba(riskChannelsColors.channelRentalsUnited, 0.1),
  },
  channelStayHvn: {
    color: riskChannelsColors.channelStayHvn,
    background: rgba(riskChannelsColors.channelStayHvn, 0.1),
  },
  channelStripe: {
    color: riskChannelsColors.channelStripe,
    background: rgba(riskChannelsColors.channelStripe, 0.1),
  },
  channelTheWesley: {
    color: riskChannelsColors.channelTheWesley,
    background: rgba(riskChannelsColors.channelTheWesley, 0.1),
  },
  channelTopVillas: {
    color: riskChannelsColors.channelTopVillas,
    background: rgba(riskChannelsColors.channelTopVillas, 0.1),
  },
  channelTripAdvisor: {
    color: riskChannelsColors.channelTripAdvisor,
    background: rgba(riskChannelsColors.channelTripAdvisor, 0.1),
  },
  channelTravelStaytion: {
    color: mainColors.black,
    background: rgba(mainColors.black, 0.1),
  },
  channelVoyage: {
    color: riskChannelsColors.channelVoyageDark,
    background: riskChannelsColors.channelVoyage,
    hover: riskChannelsColors.channelVoyageDark,
  },
  channelVRBO: {
    color: riskChannelsColors.channelVRBO,
    background: rgba(riskChannelsColors.channelVRBO, 0.1),
  },
  channelWebsite: {
    color: mainColors.primaryMedium,
    background: mainColors.neutralDark,
    hover: riskChannelsColors.channelWebsiteDark,
  },
  statusCheckedIn: {
    color: mainColors.accentDark,
    background: mainColors.accentExtremelyLight,
    hover: mainColors.accentDark,
  },
} as const
