import React, { Ref } from 'react'

function _IconHistory(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" {...props} {...ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50065 0.666626C7.85934 0.666626 8.17779 0.896151 8.29122 1.23643L12.5007 13.8647L14.2101 8.73646C14.3235 8.39613 14.642 8.16663 15.0006 8.16663H18.334C18.7942 8.16663 19.1673 8.53971 19.1673 8.99996C19.1673 9.46021 18.7942 9.83329 18.334 9.83329H15.6013L13.2912 16.7635C13.1778 17.1038 12.8593 17.3333 12.5007 17.3333C12.142 17.3333 11.8235 17.1038 11.7101 16.7635L7.50065 4.13519L5.79122 9.26346C5.67779 9.60379 5.35934 9.83329 5.00065 9.83329H1.66732C1.20708 9.83329 0.833984 9.46021 0.833984 8.99996C0.833984 8.53971 1.20708 8.16663 1.66732 8.16663H4.40002L6.71008 1.23643C6.82351 0.896151 7.14196 0.666626 7.50065 0.666626Z"
        fill="#C0C9CE"
      />
    </svg>
  )
}

export const IconHistory = React.forwardRef(_IconHistory)
