import styled, { css } from 'styled-components'

import { mainColors } from '../../styles/colors'
import { InputReset } from '../../styles/utils'

export const InputQuantityContainer = styled.div<{
  isDisabled?: boolean
}>`
  display: flex;

  ${({ isDisabled }) => isDisabled && `cursor: not-allowed;`}

  div {
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        background-color: ${mainColors.neutralExtraLight};
        cursor: not-allowed;
      `}
  }

  ${InputReset};
`

export const ArrowButton = styled.div`
  width: 32px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

export const Divider = styled.div`
  height: 2px;
  width: 100%;
  border: 1px solid ${mainColors.neutralMedium};
`

export const InputQuantityIconContainer = styled.div<{
  isDisabled?: boolean
  small?: boolean
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0px 0px 2px 0px;
  border: 2px solid ${mainColors.neutralMedium};
  border-left: none;

  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  svg {
    color: ${mainColors.neutralExtraDark};
  }

  ${({ small }) =>
    small &&
    `
    ${ArrowButton} {
      height: 15px;
    }

    svg {
      width: 15px;
      height: 15px;
    }
  `}
`
