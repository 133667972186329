import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconChannelPlaceholder(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="28" height="28" viewBox="4 7 20 15" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.95 7.5C8.5969 7.5 7.5 8.5969 7.5 9.95V19.05C7.5 20.4031 8.5969 21.5 9.95 21.5H19.05C20.4031 21.5 21.5 20.4031 21.5 19.05V9.95C21.5 8.5969 20.4031 7.5 19.05 7.5H9.95ZM11 12.6625H9.95L14.5 9.57193V9.5125L14.5437 9.54222L14.5875 9.5125V9.57193L19.1375 12.6625H18V18.35H11V12.6625Z"
        fill="white"
      />
    </svg>
  )
}

export const IconChannelPlaceholder = forwardRef(_IconChannelPlaceholder)
