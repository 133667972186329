import {
  BookingPaymentBalanceStatus,
  BookingStatus,
  CasePriority,
  EconFinancialHealth,
  PetPolicyFieldKind,
  RiskLevel,
  WorkOrderStatus,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { BadgeColors } from '../components/Badge'
import { riskChannelsColors, semanticColors } from '../styles'

enum WorkOrderReviewStatus {
  approved = 'APPROVED',
  initial = 'INITIAL',
  pendingReview = 'PENDING_REVIEW',
  rejected = 'REJECTED',
  rejectedFinal = 'REJECTED_FINAL',
}

interface Priority {
  [k: string]: { color: string; background: string }
}

export const badgeColorByCasePriority: Priority = {
  [CasePriority.low]: BadgeColors.riskLow,
  [CasePriority.medium]: BadgeColors.riskMedium,
  [CasePriority.high]: BadgeColors.riskHigh,
  [CasePriority.urgent]: BadgeColors.riskVeryHigh,
}

export const badgeColorsByRiskStatus = {
  [RiskLevel.minimal]: BadgeColors.riskVeryLow,
  [RiskLevel.veryLow]: BadgeColors.riskVeryLow,
  [RiskLevel.low]: BadgeColors.riskLow,
  [RiskLevel.medium]: BadgeColors.riskMedium,
  [RiskLevel.high]: BadgeColors.riskHigh,
  [RiskLevel.veryHigh]: BadgeColors.riskVeryHigh,
  [RiskLevel.undefined]: BadgeColors.riskUndefined,
}

export const badgeColorsByPetStatus = {
  [PetPolicyFieldKind.notApproved]: BadgeColors.failure,
  [PetPolicyFieldKind.approved]: BadgeColors.success,
  [PetPolicyFieldKind.unknown]: BadgeColors.neutral,
  [PetPolicyFieldKind.other]: BadgeColors.neutral,
}

export const badgeColorsByTaskStatus = {
  [WorkOrderStatus.completed]: BadgeColors.success,
  [WorkOrderStatus.completedWithBlock]: BadgeColors.success,
  [WorkOrderStatus.scheduled]: BadgeColors.neutral,
  [WorkOrderStatus.ongoing]: BadgeColors.accent,
  [WorkOrderStatus.ongoingWithBlock]: BadgeColors.accent,
  [WorkOrderStatus.cancelled]: BadgeColors.neutral,
  [WorkOrderStatus.pastDue]: BadgeColors.warning,
  [WorkOrderStatus.closed]: BadgeColors.success,
}

export const badgeColorsByReviewStatus = {
  [WorkOrderReviewStatus.rejectedFinal]: BadgeColors.failure,
  [WorkOrderReviewStatus.rejected]: BadgeColors.failure,
}

export const colorsByBookingStatusType = {
  [BookingStatus.checkedIn]: BadgeColors.warning,
  [BookingStatus.requested]: BadgeColors.neutral,
  [BookingStatus.cancelled]: BadgeColors.failure,
  [BookingStatus.noShow]: BadgeColors.failure,
  [BookingStatus.booked]: BadgeColors.accent,
  [BookingStatus.modified]: BadgeColors.accent,
  [BookingStatus.checkedOut]: BadgeColors.success,
  [BookingStatus.quoted]: BadgeColors.neutral,
}

export const colorByPaymentBalanceStatus = {
  [BookingPaymentBalanceStatus.notPaid]: BadgeColors.failure,
  [BookingPaymentBalanceStatus.partlyPaid]: BadgeColors.riskHigh,
  [BookingPaymentBalanceStatus.fullyPaid]: BadgeColors.success,
  [BookingPaymentBalanceStatus.overPaid]: BadgeColors.riskVeryHigh,
}

export const badgeTextByPaymentBalanceStatus = {
  [BookingPaymentBalanceStatus.notPaid]: 'Not Paid',
  [BookingPaymentBalanceStatus.partlyPaid]: 'Partially Paid',
  [BookingPaymentBalanceStatus.fullyPaid]: 'Fully Paid',
  [BookingPaymentBalanceStatus.overPaid]: 'Over Paid',
}

export const financialHealthBadgesColors = {
  [EconFinancialHealth.gold]: {
    color: riskChannelsColors.riskMedium,
    background: riskChannelsColors.riskMediumLight,
  },
  [EconFinancialHealth.green]: {
    color: semanticColors.successMedium,
    background: semanticColors.successExtraLight,
  },
  [EconFinancialHealth.yellow]: {
    color: semanticColors.warningExtraDark,
    background: semanticColors.warningExtraLight,
  },
  [EconFinancialHealth.red]: {
    color: semanticColors.failureMedium,
    background: semanticColors.failureExtraLight,
  },
}

export const badgeColorsByRiskVerificationStatus = {
  IN_PROGRESS: BadgeColors.warning,
  APPROVED: BadgeColors.success,
  REJECTED: BadgeColors.failure,
  NOT_REVIEWED: BadgeColors.accent,
  PENDING_REJECTION: BadgeColors.neutral,
}

export const badgeLabelByRiskVerificationStatus = {
  IN_PROGRESS: 'In review',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  NOT_REVIEWED: 'Pending review',
  PENDING_REJECTION: 'Pending rejection',
}
