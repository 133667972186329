import styled from 'styled-components'

import { mainColors, semanticColors } from '../../styles/colors'
import { medias } from '../../styles/medias'
import { _2XS_Bold, _XS_Normal } from '../../styles/typography'
import {
  ControlTheme,
  FilterButtonControlProps,
  FilterButtonLabelProps,
} from './ControlButtonsFilter.types'

const BUTTON_HEIGHT = '36px'

export const ButtonContainer = styled.div`
  position: relative;
  min-height: ${BUTTON_HEIGHT};
  float: left;
  width: 100%;

  // rules to prevent double border and bad animation
  &:first-child {
    > * {
      border-left-width: 2px;
      margin-left: -2px;
    }
  }

  &:first-child label {
    border-radius: 2px 0 0 2px;
  }

  &:last-child label {
    border-radius: 0 2px 2px 0;
  }

  ${medias.MD_SCREEN`
    &:first-child {
      > * {
        border-left-width: 2px;
        margin-left: -2px;
      }
    }
  `}
`

export const Title = styled.span`
  ${_XS_Normal};
  padding: 0 14px;
`

export const ControlDefault = styled.label<FilterButtonLabelProps>`
  height: ${BUTTON_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${mainColors.white};
  cursor: pointer;
  border: 2px solid ${mainColors.neutralMedium};
  border-width: 2px 2px 2px 0;

  ${Title} {
    color: ${mainColors.neutralExtremelyDark};
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    min-width: 45px;
  }

  ${({ disabled }) => disabled && `cursor: not-allowed;`}
`

export const HiddenControlInput = styled.input<FilterButtonControlProps>`
  opacity: 0;
  position: absolute;

  &:hover + ${ControlDefault} {
    span {
      color: ${mainColors.neutralExtremelyDark2};
    }

    border: 2px solid ${mainColors.neutralExtraDark};
    margin: 0 0 0 -2px;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      background: ${mainColors.neutralExtraDark};
      width: 2px;
      height: 100%;
      z-index: 10;
    }
  }

  &:checked + ${ControlDefault} {
    background: ${mainColors.neutralMedium};
    border: 2px solid ${mainColors.neutralDark};
    margin: 0 0 0 -2px;
    border-radius: 2px;

    &::after {
      content: '';
      background: transparent;
    }

    span {
      color: ${mainColors.black};
      ${_XS_Normal};
    }
  }
`

export const ControlChildren = styled.div`
  margin-left: 7px;
  display: flex;
  align-items: center;

  & > div {
    width: max-content;
  }
`

// Buttons Group

export const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-left: 2px;

  > * {
    flex: 1;
  }

  &:after {
    content: '';
    clear: both;
    display: table;
  }

  ${medias.MD_SCREEN`
    width: 100%;
    padding-left: 0;
    overflow-x: unset;
  `}
`

export const AmountBadge = styled.div<{ selected?: boolean; $theme?: ControlTheme }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  height: 18px;
  padding: 2px 6px;
  border-radius: 20px;
  margin-left: 6px;
  ${_2XS_Bold};

  ${({ $theme, selected }) =>
    (!$theme || $theme === ControlTheme.failure) && selected
      ? `
    background-color: ${semanticColors.failureMedium};
    color: ${mainColors.white};
  `
      : `
    border: 1.5px solid ${semanticColors.failureLight};
    color: ${semanticColors.failureLight};
  `}

  ${({ $theme, selected }) =>
    $theme === ControlTheme.accent &&
    `
      border: 1.5px solid ${selected ? mainColors.accentMedium : mainColors.accentLight};
      background-color: ${selected ? mainColors.accentMedium : mainColors.white};
      color: ${selected ? mainColors.white : mainColors.accentLight};
    `}
`
