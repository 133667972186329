import styled from 'styled-components'

import { mainColors, shadowColors } from '../../styles/colors'
import { _3XS_Bold } from '../../styles/typography'
import { rgba } from '../../utils/cssUtils'
import { Card } from '../Card'

export const MenuActionContainer = styled.div`
  display: inline-block;
  cursor: pointer;
`

export const MenuActions = styled(Card)`
  min-width: 150px;
  background: ${mainColors.white};
  box-sizing: border-box;
  border: 1px solid ${mainColors.neutralLight};
  box-shadow: 0px 4px 16px ${rgba(shadowColors.blur, 0.4)};
  border-radius: 2px;
  padding: 10px 0;
`

export const MenuActionsTitle = styled.p`
  ${_3XS_Bold};
  margin: 2px 16px 10px 16px;
  color: ${mainColors.neutralExtraDark};
  text-transform: uppercase;
`
