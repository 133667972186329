import React from 'react'
import { FileText } from 'react-feather'

function _IconLeasingDoc(props: React.SVGProps<SVGSVGElement>) {
  return <FileText {...props} style={{ transform: 'scaleY(-1)' }} />
}

const IconLeasingDoc = React.memo(_IconLeasingDoc)

export { IconLeasingDoc }
