import styled from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _M_Normal } from '../../styles/typography'

export const Root = styled.div`
  display: flex;
  ${_M_Normal};

  button {
    min-height: unset;
  }

  span:not(:last-child) {
    margin-right: 8px;
  }
`
export const Text = styled.span`
  color: ${mainColors.neutralExtremelyDark};
`
