import React, { Ref } from 'react'

function _IconImport(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.885 13.115a.633.633 0 0 1-.185-.448V10a.7.7 0 1 0-1.4 0v2.667A2.033 2.033 0 0 0 3.333 14.7h9.333a2.033 2.033 0 0 0 2.034-2.033V10a.7.7 0 1 0-1.4 0v2.667a.633.633 0 0 1-.634.633H3.333a.633.633 0 0 1-.448-.185Zm1.287-6.943a.7.7 0 0 0 0 .99l3.333 3.333a.7.7 0 0 0 .99 0l3.333-3.333a.7.7 0 1 0-.99-.99L8.7 8.31V2a.7.7 0 1 0-1.4 0v6.31L5.162 6.172a.7.7 0 0 0-.99 0Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconImport = React.forwardRef(_IconImport)
