import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconPaymentInDispute(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <circle cx="8.00008" cy="7.99984" r="7.33333" fill="#94A4AD" stroke="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46727 5.33337C8.46727 5.07563 8.25833 4.8667 8.0006 4.8667C7.74287 4.8667 7.53394 5.07563 7.53394 5.33337V8.6667C7.53394 8.92443 7.74287 9.13337 8.0006 9.13337C8.25833 9.13337 8.46727 8.92443 8.46727 8.6667V5.33337ZM8.0006 11.2002C8.25833 11.2002 8.46727 10.9912 8.46727 10.7335C8.46727 10.4758 8.25833 10.2668 8.0006 10.2668C7.74287 10.2668 7.53394 10.4758 7.53394 10.7335C7.53394 10.9912 7.74287 11.2002 8.0006 11.2002Z"
        fill="white"
      />
    </svg>
  )
}

export const IconPaymentInDispute = forwardRef(_IconPaymentInDispute)
