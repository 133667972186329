import styled from 'styled-components'

import { _XS_Medium, mainColors } from '../../styles'

export const Stat = styled.div`
  min-width: 48px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  color: ${mainColors.neutralExtraDark};
`

export const Label = styled.div`
  ${_XS_Medium};
  text-align: left;
`

type ColorProps = {
  color?: string
}

export const Dot = styled.div<ColorProps>`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: ${({ color }) => color || mainColors.neutralExtraDark};
`
