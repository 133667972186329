import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconEye(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.41266 13.0765C6.13436 14.9722 9.00252 16.9985 11.8907 16.9985H11.9867L12.086 16.9991C14.9841 16.9991 17.8576 14.9728 19.5813 13.0758C20.1345 12.4619 20.1339 11.5361 19.58 10.9209C17.857 9.02589 14.9868 7.00092 12.102 7.00092C12.0646 7.00092 12.028 7.00092 12 7.00158C11.966 7.00092 11.9333 7.00092 11.8993 7.00092C9.00518 7.00092 6.13369 9.02589 4.41133 10.9222C3.85876 11.5354 3.85876 12.4613 4.41266 13.0765ZM5.15253 11.5928C6.73025 9.85574 9.33113 8.00007 11.898 8.00007L11.9867 8.00141L12.0906 8.00074C14.6575 8.00074 17.2597 9.85574 18.8381 11.5914C19.0474 11.8241 19.0474 12.174 18.8394 12.4046C17.2604 14.1423 14.6549 15.9987 12.0773 15.9987L11.9867 15.998L11.8993 15.9987C9.33113 15.9987 6.73092 14.1423 5.15387 12.406C4.94457 12.1733 4.94457 11.8234 5.15253 11.5928ZM10.4997 12.0002V11.9994C10.4997 11.1736 11.1734 10.4999 11.9997 10.4999C12.4023 10.4999 12.7758 10.6546 13.0606 10.9389C13.3453 11.2237 13.4997 11.5966 13.4997 11.9988C13.4997 12.4032 13.3448 12.7761 13.0601 13.061C12.7753 13.3453 12.4017 13.4999 11.9992 13.4999C11.5972 13.4999 11.2236 13.3451 10.9385 13.0601C10.6538 12.7754 10.4994 12.4022 10.4997 12.0002ZM11.9992 14.4999C11.3314 14.4999 10.7036 14.2394 10.2314 13.7672C9.75917 13.2949 9.49917 12.6672 9.49973 11.9994C9.49973 10.621 10.6214 9.49994 11.9997 9.49994C12.6675 9.49994 13.2953 9.75994 13.7675 10.2316C14.2397 10.7038 14.4997 11.331 14.4997 11.9988C14.4997 12.6688 14.2392 13.2961 13.7669 13.7683C13.2947 14.2399 12.6669 14.4999 11.9992 14.4999Z"
        fill="currentColor"
      />
    </svg>
  )
}

function _IconWhiteEye(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" {...props} ref={ref}>
      <path
        d="M10.7143 0.5C6.19824 0.5 2.2936 2.90458 0.406096 7.00466C0.115514 7.63587 0.115515 8.36413 0.406096 8.99534C2.2936 13.0954 6.19824 15.5 10.7143 15.5C15.5844 15.5 19.7435 12.7036 21.4286 8C19.7435 3.29643 15.5844 0.5 10.7143 0.5ZM10.7143 4.78571C9.0974 4.78571 7.79221 6.22143 7.79221 8C7.79221 9.77857 9.0974 11.2143 10.7143 11.2143C12.3312 11.2143 13.6364 9.77857 13.6364 8C13.6364 6.22143 12.3312 4.78571 10.7143 4.78571Z"
        fill="white"
      />
      <path
        d="M10.8 3C7.60091 3 4.81693 4.77984 3.37849 7.40978C3.17747 7.77731 3.17747 8.22269 3.37849 8.59022C4.81693 11.2202 7.60091 13 10.8 13C8.03971 13 5.8 10.7603 5.8 8C5.8 5.23972 8.03972 3 10.8 3ZM10.8 3C13.9991 3 16.7831 4.77984 18.2215 7.40978C18.4225 7.77731 18.4225 8.22269 18.2215 8.59022C16.7837 11.2191 14.0014 12.9985 10.804 13C13.5625 12.9978 15.8 10.7589 15.8 8C15.8 5.23972 13.5603 3 10.8 3ZM9.4 8C9.4 7.22429 10.0243 6.6 10.8 6.6C11.5757 6.6 12.2 7.22429 12.2 8C12.2 8.77572 11.5757 9.4 10.8 9.4C10.0243 9.4 9.4 8.77572 9.4 8Z"
        fill="#FF9E0D"
        stroke="#FF9E0D"
        strokeWidth="2"
      />
    </svg>
  )
}

export const IconEye = forwardRef(_IconEye)
export const IconWhiteEye = forwardRef(_IconWhiteEye)
