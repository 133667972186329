import styled, { css } from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _M_Medium, _XS_Medium } from '../../styles/typography'
import { FieldHeaderContainerProps, FieldHeaderSizeProps } from './FieldHeader.types'

export const Root = styled.div`
  width: 100%;
`

export const Container = styled.div<FieldHeaderContainerProps>`
  padding-left: ${props => (props.restriction ? '13px' : 0)};
  position: relative;
  width: 100%;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom + 'px' : undefined)};

  &::before {
    position: absolute;
    left: 0;
    top: 5px;
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${props => props.restrictionColor};
  }
`

export const Header = styled.div<
  FieldHeaderSizeProps & { showHeader?: boolean; removeHeaderMargin?: boolean }
>`
  display: ${props => (props.showHeader ? 'flex' : 'none')};
  align-items: center;
  gap: 10px;
  margin-bottom: ${props => (props.removeHeaderMargin ? 0 : props.small ? '4px' : '10px')};
`

export const FieldHeaderTitle = styled.h4<FieldHeaderSizeProps>`
  ${props => (props.small ? _XS_Medium : _M_Medium)};
  color: ${({ color }) => color ?? mainColors.black};
  line-height: inherit;
  display: flex;
`

export const AfterTitle = styled.div`
  margin: 0 6px;
`

export const TooltipContainer = styled.div<{ $isVisible?: boolean }>`
  margin-right: 12px;
  max-height: 16px;

  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
`

export const HorizontalFieldTheme = css`
  ${Container} {
    display: flex;
    align-items: center;
    flex-grow: 1;

    &::before {
      top: 50%;
      transform: translateY(-50%);
    }

    ${Header} {
      margin-bottom: 0;
      margin-right: 30px;
      color: ${mainColors.neutralExtremelyDark};
    }
  }
`
