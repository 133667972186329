import React, { Ref } from 'react'

const _IconDocument = (props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        d="M6 1C4.34315 1 3 2.34315 3 4V20C3 21.6569 4.34315 23 6 23H18C19.6569 23 21 21.6569 21 20V4C21 2.34315 19.6569 1 18 1H6ZM5 4C5 3.44772 5.44772 3 6 3H18C18.5523 3 19 3.44772 19 4V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20V4ZM8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H8ZM7 16C7 15.4477 7.44772 15 8 15H16C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17H8C7.44772 17 7 16.5523 7 16ZM8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9H10C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7H8Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconDocument = React.forwardRef(_IconDocument)
