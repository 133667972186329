import React, { Ref } from 'react'

function _IconReject(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 26.4C21.296 26.4 26.4 21.296 26.4 15C26.4 8.70395 21.296 3.6 15 3.6C8.70395 3.6 3.6 8.70395 3.6 15C3.6 21.296 8.70395 26.4 15 26.4ZM15 28C22.1797 28 28 22.1797 28 15C28 7.8203 22.1797 2 15 2C7.8203 2 2 7.8203 2 15C2 22.1797 7.8203 28 15 28ZM19.5659 10.4345C19.8783 10.7469 19.8783 11.2535 19.5659 11.5659L16.1316 15.0002L19.5659 18.4345C19.8783 18.7469 19.8783 19.2535 19.5659 19.5659C19.2535 19.8783 18.7469 19.8783 18.4345 19.5659L15.0002 16.1316L11.5659 19.5659C11.2535 19.8783 10.7469 19.8783 10.4345 19.5659C10.1221 19.2535 10.1221 18.7469 10.4345 18.4345L13.8688 15.0002L10.4345 11.5659C10.1221 11.2535 10.1221 10.7469 10.4345 10.4345C10.7469 10.1221 11.2535 10.1221 11.5659 10.4345L15.0002 13.8688L18.4345 10.4345C18.7469 10.1221 19.2535 10.1221 19.5659 10.4345Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconReject = React.forwardRef(_IconReject)
