import { FC, PropsWithChildren } from 'react'

import { IconError } from '@avantstay/backoffice-vectors'

import { semanticColors } from '../../styles'
import { InfoTooltip } from '../InfoTooltip'
import { Tooltip, TooltipTheme } from '../Tooltip'

interface TooltipWrapperProps {
  message?: string
}

export const TooltipWrapper: FC<PropsWithChildren<TooltipWrapperProps>> = ({
  children,
  message,
}) => {
  if (message) {
    return (
      <Tooltip content={message} theme={TooltipTheme.dark} arrow placement="top">
        <div>{children}</div>
      </Tooltip>
    )
  }

  return <>{children}</>
}

interface InfoIconProps {
  error?: string
  warning?: string
  showErrorTooltip?: boolean
}

export const InfoIcon: FC<InfoIconProps> = ({ error, warning, showErrorTooltip }) => {
  const hasError = Boolean(error)
  const hasWarning = Boolean(warning)

  if (showErrorTooltip && (hasError || hasWarning)) {
    return <InfoTooltip error={error} warning={warning} />
  }

  if (hasError || hasWarning) {
    return (
      <IconError color={hasWarning ? semanticColors.warningMedium : semanticColors.failureMedium} />
    )
  }

  return <></>
}
