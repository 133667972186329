import styled from 'styled-components'

import { _L_Medium, _M_Normal, mainColors } from '../../styles'
import { Button } from '../Button'

const MAX_CIRCLE_SIZE = 44
const TITLE_ICON_SIZE = 20
const BUTTON_ICON_SIZE = 16

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

export const IconCircle = styled.div`
  display: flex;
  padding: 12px;
  max-width: ${MAX_CIRCLE_SIZE}px;
  max-height: ${MAX_CIRCLE_SIZE}px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background-color: ${mainColors.neutralLight};

  svg {
    min-width: ${TITLE_ICON_SIZE}px;
    min-height: ${TITLE_ICON_SIZE}px;
    color: ${mainColors.neutralExtremelyDark2};
  }
`

export const MessageTitle = styled.div`
  ${_L_Medium};
  color: ${mainColors.primaryMedium};
`

export const MessageWrapper = styled.div`
  ${_M_Normal};
  text-align: center;
  color: ${mainColors.neutralExtremelyDark};
`

export const ButtonFlat = styled(Button.Flat)`
  svg {
    width: ${BUTTON_ICON_SIZE}px;
    height: ${BUTTON_ICON_SIZE}px;
    margin-right: 6px;
  }
`
