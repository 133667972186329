import styled from 'styled-components'

import { Button } from '../../components/Button'
import { mainColors } from '../../styles/colors'

export const HorizontalButtonGroupContainer = styled.div`
  background-color: ${mainColors.primaryMedium};
  border: 2px solid ${mainColors.white};
  padding: 4px 0px;
  border-radius: 4px;
  display: flex;

  & > * {
    color: unset;
    &:not(:last-child) {
      border-right: 1px solid ${mainColors.neutralMedium};
    }
  }
`

export const HorizontalButton = styled(Button.Flat)`
  padding: 0 20px;
  border-radius: 0px;
  height: 36px;

  svg {
    color: ${mainColors.white};
  }
`
