import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconReview = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.23966 3.22917C2.23966 3.05658 2.37958 2.91667 2.55216 2.91667H12.8647C13.0372 2.91667 13.1772 3.05659 13.1772 3.22917C13.1772 3.6894 13.5503 4.0625 14.0105 4.0625C14.4707 4.0625 14.8438 3.6894 14.8438 3.22917C14.8438 2.1361 13.9577 1.25 12.8647 1.25H2.55216C1.4591 1.25 0.572998 2.13611 0.572998 3.22917V16.9792C0.572998 18.0723 1.45912 18.9583 2.55216 18.9583H12.8647C13.9577 18.9583 14.8438 18.0723 14.8438 16.9792V14.6875C14.8438 14.2272 14.4707 13.8542 14.0105 13.8542C13.5503 13.8542 13.1772 14.2272 13.1772 14.6875V16.9792C13.1772 17.1518 13.0373 17.2917 12.8647 17.2917H2.55216C2.37955 17.2917 2.23966 17.1518 2.23966 16.9792V3.22917ZM16.249 5.46768C16.5948 5.12189 17.1554 5.12189 17.5012 5.46768L18.0561 4.91283L17.5012 5.46768C17.847 5.81345 17.847 6.37407 17.5012 6.71985L10.4056 13.8155L8.94483 14.024L9.1534 12.5633L16.249 5.46768ZM18.6797 4.28916C17.6831 3.2925 16.0672 3.2925 15.0705 4.28916L7.77844 11.5812C7.65093 11.7087 7.56822 11.8742 7.54273 12.0527L7.13787 14.8882C7.1008 15.1479 7.18813 15.4098 7.37358 15.5953C7.55904 15.7807 7.82098 15.8681 8.08063 15.831L10.9162 15.4261C11.0947 15.4006 11.2601 15.3179 11.3876 15.1904L18.6797 7.89837C19.6764 6.90171 19.6764 5.28581 18.6797 4.28916Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconReview = forwardRef(_IconReview)
