import React, { Ref } from 'react'

function _IconMoreActions(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.2998C8.55228 4.2998 9 3.85209 9 3.2998C9 2.74752 8.55228 2.2998 8 2.2998C7.44772 2.2998 7 2.74752 7 3.2998C7 3.85209 7.44772 4.2998 8 4.2998ZM8 8.96649C8.55228 8.96649 9 8.51878 9 7.96649C9 7.41421 8.55228 6.96649 8 6.96649C7.44772 6.96649 7 7.41421 7 7.96649C7 8.51878 7.44772 8.96649 8 8.96649ZM9 12.6331C9 13.1854 8.55228 13.6331 8 13.6331C7.44772 13.6331 7 13.1854 7 12.6331C7 12.0809 7.44772 11.6331 8 11.6331C8.55228 11.6331 9 12.0809 9 12.6331Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconMoreActions = React.forwardRef(_IconMoreActions)
