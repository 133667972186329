import React, { Ref } from 'react'

function _IconReorder2(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.55808 2.31685C9.80216 2.07277 10.1979 2.07277 10.442 2.31685L12.6831 4.55796C13.0768 4.95169 12.798 5.62491 12.2411 5.62491H7.75891C7.20209 5.62491 6.92324 4.95169 7.31696 4.55796L9.55808 2.31685ZM3.75 7.75C3.33579 7.75 3 8.08579 3 8.5C3 8.91421 3.33579 9.25 3.75 9.25H16.25C16.6642 9.25 17 8.91421 17 8.5C17 8.08579 16.6642 7.75 16.25 7.75H3.75ZM3.75 10.75C3.33579 10.75 3 11.0858 3 11.5C3 11.9142 3.33579 12.25 3.75 12.25H16.25C16.6642 12.25 17 11.9142 17 11.5C17 11.0858 16.6642 10.75 16.25 10.75H3.75ZM10.442 17.6832C10.1979 17.9272 9.80216 17.9272 9.55808 17.6832L7.31696 15.442C6.92324 15.0483 7.20209 14.3751 7.75891 14.3751H12.2411C12.798 14.3751 13.0768 15.0483 12.6831 15.442L10.442 17.6832Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconReorder2 = React.forwardRef(_IconReorder2)
