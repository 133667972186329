import React, { Ref } from 'react'

function _IconDirty(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16233 9.27409C5.61176 10.0681 5.22683 10.9703 5.03635 11.9267C4.76266 13.3008 4.90266 14.7253 5.43865 16.0199C5.97463 17.3144 6.88252 18.421 8.04747 19.1995C9.21242 19.978 10.5821 20.3936 11.9832 20.3936C13.3844 20.3936 14.7541 19.978 15.919 19.1995C15.9527 19.177 15.9862 19.1542 16.0194 19.1312L14.9367 18.0485C14.0516 18.6002 13.0285 18.8936 11.9832 18.8936C10.8788 18.8936 9.79918 18.566 8.88092 17.9524C7.96267 17.3387 7.24704 16.4665 6.82456 15.4461C6.40208 14.4256 6.29172 13.3028 6.50746 12.2197C6.63966 11.5559 6.89068 10.9253 7.24503 10.3568L6.16233 9.27409ZM16.096 17.0864L17.1574 18.1478C17.4795 17.8033 17.7666 17.4272 18.0144 17.0249L16.9158 15.9263C16.6925 16.3473 16.4168 16.737 16.096 17.0864ZM9.19244 8.20298L8.20337 9.1938L7.14271 8.13314L8.13178 7.14232L9.19244 8.20298ZM17.4676 14.3568C17.6017 13.654 17.6002 12.9283 17.459 12.2197C17.2433 11.1365 16.711 10.1415 15.9298 9.36077L11.9798 5.41074L10.2522 7.14138L9.1915 6.08072L11.4485 3.81975C11.5891 3.67889 11.7799 3.5997 11.9789 3.59961C12.178 3.59952 12.3689 3.67855 12.5096 3.81928L16.9903 8.29994C17.9813 9.29035 18.6565 10.5526 18.9301 11.9267C19.1733 13.1474 19.0899 14.4079 18.6925 15.5817L17.4676 14.3568Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.17133 4.17182C4.4447 3.89846 4.88792 3.89846 5.16128 4.17182L19.8279 18.8385C20.1013 19.1119 20.1013 19.5551 19.8279 19.8284C19.5546 20.1018 19.1114 20.1018 18.838 19.8284L4.17133 5.16177C3.89797 4.8884 3.89797 4.44519 4.17133 4.17182Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconDirty = React.forwardRef(_IconDirty)
