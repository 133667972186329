import styled from 'styled-components'

import { IconMore } from '@avantstay/backoffice-vectors'

import { mainColors, shadowColors } from '../../styles/colors'
import { medias } from '../../styles/medias'
import { _3XS_Bold, _S_Normal } from '../../styles/typography'
import { rgba } from '../../utils/cssUtils'
import { Card } from '../Card'
import { DefaultLink } from '../Link'
import { LinkProps } from './ActionLinks.types'

export const ActionContainer = styled.div`
  display: inline-block;
  cursor: pointer;
`

export const ActionLink = styled(DefaultLink)<LinkProps>`
  width: 100%;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background-color: ${mainColors.neutralLight};
  }

  ${props =>
    props.active &&
    `
    background-color: ${mainColors.neutralLight};
  `}

  ${props =>
    props.isLoading &&
    `
    cursor: progress;
  `}

${props =>
    props.isDisabled &&
    `
    cursor: not-allowed;
    color: ${mainColors.neutralDark}
  `}
`

interface ActionLinkContentProps {
  isDisabled?: boolean
  shouldHighLightLink?: boolean
}

export const ActionLinkContent = styled.div<ActionLinkContentProps>`
  min-height: 28px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin: 2px 0;
  color: ${({ isDisabled, shouldHighLightLink }) => {
    if (isDisabled) return mainColors.neutralDark
    if (shouldHighLightLink) return mainColors.accentMedium
    return mainColors.neutralExtremelyDark
  }};
  ${_S_Normal};

  svg {
    // Due to styles set in DefaultLink
    stroke: none;
    margin: 0;
  }
`

export const IconMoreStyled = styled(IconMore)`
  width: 24px;
  height: 24px;
  color: ${mainColors.neutralDark};

  &:hover {
    color: ${mainColors.neutralExtraDark};
  }
`

export const ActionLinkContainer = styled(Card)`
  min-width: 150px;
  background: ${mainColors.white};
  box-sizing: border-box;
  border: 1px solid ${mainColors.neutralLight};
  box-shadow: 0px 4px 16px ${rgba(shadowColors.blur, 0.4)};
  border-radius: 2px;
  padding: 10px 0;
`

export const ActionLinkNestedContainer = styled(ActionLinkContainer)`
  min-width: max-content;
  max-width: max-content;
  position: absolute;
  right: 0;
  margin-top: 35px;

  ${medias.SM_SCREEN`
    right: 100%;
    margin-top: -10px;
  `}

  ${medias.XXL_SCREEN`
    right: 0;
    left: 100%;
  `}
`

export const ActionsTitle = styled.p`
  ${_3XS_Bold};
  margin: 12px 16px;
  color: ${mainColors.neutralExtraDark};
  text-transform: uppercase;
`

export const IconWrapper = styled.div<{ isMenuOpen?: boolean }>`
  margin-left: 8px;

  svg {
    transform: ${({ isMenuOpen }) => (isMenuOpen ? 'scaleY(-1)' : 'none')};
  }
`
