import { CustomFieldKind } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { default as Asset } from './Asset'
import { default as BooleanKind } from './Boolean'
import { default as DateKind } from './Date'
import { default as DateTime } from './DateTime'
import { default as Markdown } from './Markdown'
import { default as Money } from './Money'
import { default as NumberField } from './Number'
import { default as OptionSingle } from './OptionSingle'
import { default as OptionsMultiple } from './OptionsMultiple'
import { default as Phone } from './Phone'
import { default as Text } from './Text'
import { default as Time } from './Time'
import { default as TimeRange } from './TimeRange'

export default {
  [CustomFieldKind.boolean]: BooleanKind,
  [CustomFieldKind.text]: Text,
  [CustomFieldKind.email]: Text,
  [CustomFieldKind.optionsSingle]: OptionSingle,
  [CustomFieldKind.optionsMultiple]: OptionsMultiple,
  [CustomFieldKind.money]: Money,
  [CustomFieldKind.time]: Time,
  [CustomFieldKind.asset]: Asset,
  [CustomFieldKind.date]: DateKind,
  [CustomFieldKind.dateTime]: DateTime,
  [CustomFieldKind.markdown]: Markdown,
  [CustomFieldKind.number]: NumberField,
  [CustomFieldKind.phone]: Phone,
  [CustomFieldKind.timeRange]: TimeRange,
  [CustomFieldKind.duration]: Text,
}
