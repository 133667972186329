import { get } from 'lodash'
import * as React from 'react'
import { Redirect, Route, Router, Switch, withRouter } from 'react-router-dom'
import { StyleSheetManager } from 'styled-components'
import { QueryParamProvider } from 'use-query-params'

import { AlertContext, Loading } from '@avantstay/backoffice-core'

import Authorized from '@new/__global/components/Authorized'

import AuthProvider from '@contexts/Auth/AuthProvider'
import StoreValuesProvider from '@contexts/StoreValuesContext/StoreValuesProvider'
import isPropValid from '@emotion/is-prop-valid'
import { ROUTES } from '@routes'
import GlobalStyle from '@styles/globalStyles'
import history from '@utils/history'
import { FeatureToggleProvider } from '@utils/optimizely'
import { withErrorBoundary } from '@utils/withErrorBoundary'

const Dashboard = withErrorBoundary(React.lazy(() => import('@screens/Dashboard.screen')))
const Login = withErrorBoundary(React.lazy(() => import('@screens/Login.screen')))
const Properties = withErrorBoundary(React.lazy(() => import('@screens/Properties.screen')))
const Property = withErrorBoundary(React.lazy(() => import('@screens/Property.screen')))
const Hotel = withErrorBoundary(React.lazy(() => import('@screens/Hotel.screen')))
const Bookings = withErrorBoundary(React.lazy(() => import('@screens/Bookings.screen')))
const Booking = withErrorBoundary(React.lazy(() => import('@screens/Booking.screen')))
const Settings = withErrorBoundary(React.lazy(() => import('@screens/Settings.screen')))
const Marketing = withErrorBoundary(React.lazy(() => import('@screens/Marketing.screen')))
const Operations = withErrorBoundary(React.lazy(() => import('@screens/Operations.screen')))
const ErrorScreen = React.lazy(() => import('@screens/Error.screen'))
const RatingsScreen = withErrorBoundary(React.lazy(() => import('@/screens/Ratings.screen')))
const ScheduledSms = withErrorBoundary(React.lazy(() => import('@/screens/ScheduledSms.screen')))
const PeopleScreen = withErrorBoundary(React.lazy(() => import('@screens/People.screen')))
const FinanceScreen = withErrorBoundary(React.lazy(() => import('@screens/Finance.screen')))
const ErrorAuthProcess = React.lazy(() => import('@screens/ErrorAuth.screen'))

const isPathnameRegion = history.location.pathname.includes('/region')
const isPathnameRegions = history.location.pathname.includes('/regions')

const renderAuthorizing = () => <Route path={ROUTES.login.path} exact component={Login} />

const renderUnauthorized = withRouter(({ location }) => {
  const shouldRedirect = location.pathname !== ROUTES.login.path
  return (
    <>
      <Route path={ROUTES.login.path} exact component={Login} />
      {shouldRedirect && (
        <Redirect
          to={{
            pathname: ROUTES.login.path,
            state: { nextLocation: location },
          }}
        />
      )}
    </>
  )
})

function MainRouter() {
  return (
    <StyleSheetManager
      shouldForwardProp={(propName, elementToBeRendered) => {
        return typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
      }}
    >
      <Router history={history}>
        <QueryParamProvider history={history}>
          <AuthProvider>
            <FeatureToggleProvider>
              <StoreValuesProvider>
                <React.Suspense fallback={<Loading absolute />}>
                  <Authorized
                    renderUnauthorized={renderUnauthorized}
                    renderAuthorizing={renderAuthorizing}
                    errorComponent={ErrorAuthProcess}
                  >
                    {({ isSignedIn, location }) => {
                      const shouldRedirectToDashboard =
                        isSignedIn &&
                        location.pathname === ROUTES.login.path &&
                        get(location.state, 'nextLocation.pathname') === ROUTES.home.path
                      return shouldRedirectToDashboard ? (
                        <Switch>
                          <Redirect to={ROUTES.dashboard.path} />
                        </Switch>
                      ) : (
                        <Switch>
                          <Route {...ROUTES.dashboard} component={Dashboard} />
                          <Route {...ROUTES.properties} render={Properties} />
                          <Route {...ROUTES.property} component={Property} />
                          <Route {...ROUTES.hotel} component={Hotel} />
                          <Route {...ROUTES.fastTapeChart} component={Bookings} />
                          <Route {...ROUTES.bookings} component={Bookings} />
                          <Route {...ROUTES.accountingResolutionCenter} component={FinanceScreen} />
                          <Route {...ROUTES.servicesRequests} component={Bookings} />
                          <Route {...ROUTES.idVerification} component={Bookings} />
                          <Route {...ROUTES.refundRequests} component={Bookings} />
                          <Route {...ROUTES.tapeChart} component={Bookings} />
                          <Route {...ROUTES.bookingConflicts} component={Bookings} />
                          <Route {...ROUTES.marketing} component={Marketing} />
                          <Route {...ROUTES.settings} component={Settings} />
                          <Route {...ROUTES.booking} component={Booking} />
                          <Route {...ROUTES.error} component={ErrorScreen} />
                          <Route {...ROUTES.people} component={PeopleScreen} />
                          <Route {...ROUTES.finance} component={FinanceScreen} />
                          <Route {...ROUTES.revenueMapping} component={FinanceScreen} />
                          <Route {...ROUTES.fees} component={FinanceScreen} />
                          <Route {...ROUTES.pricingTapeChart} component={FinanceScreen} />
                          <Route {...ROUTES.revenueBookingsTapeChart} component={FinanceScreen} />
                          <Route {...ROUTES.revenueSettings} component={FinanceScreen} />
                          <Route {...ROUTES.seasonGroups} component={FinanceScreen} />
                          <Route {...ROUTES.seasons} component={FinanceScreen} />
                          <Route {...ROUTES.yieldMaps} component={FinanceScreen} />
                          <Route {...ROUTES.yieldGroups} component={FinanceScreen} />
                          <Route {...ROUTES.discounts} component={FinanceScreen} />
                          <Route {...ROUTES.markups} component={FinanceScreen} />
                          <Route {...ROUTES.ownerIncomeDashboard} component={FinanceScreen} />
                          <Route {...ROUTES.pricingTool} component={FinanceScreen} />
                          <Route {...ROUTES.ratings} component={RatingsScreen} />
                          <Route {...ROUTES.cancelScheduledSms} component={ScheduledSms} />
                          <Route {...ROUTES.operations} component={Operations} />
                          <Redirect from={ROUTES.reports.path} to={ROUTES.ratingsSuperhost.path} />

                          {/* Support old url */}
                          {!isPathnameRegions && !isPathnameRegion && (
                            <Redirect from="*" to={ROUTES.dashboard.path} />
                          )}
                          <Redirect from="/region/:id" to={ROUTES.ratingsMarketsReview.path} />
                          <Redirect from="/guests" to={ROUTES.guests.path} />
                        </Switch>
                      )
                    }}
                  </Authorized>
                </React.Suspense>
                <GlobalStyle />
                <AlertContext />
              </StoreValuesProvider>
            </FeatureToggleProvider>
          </AuthProvider>
        </QueryParamProvider>
      </Router>
    </StyleSheetManager>
  )
}

export default MainRouter
