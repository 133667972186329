import React, { Ref } from 'react'

function _IconError(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        d="M8.00016 14.6666C11.6821 14.6666 14.6668 11.6818 14.6668 7.99992C14.6668 4.31802 11.6821 1.33325 8.00016 1.33325C4.31826 1.33325 1.3335 4.31802 1.3335 7.99992C1.3335 11.6818 4.31826 14.6666 8.00016 14.6666Z"
        fill="currentColor"
      />
      <path
        d="M8.6998 5.3333C8.6998 4.9467 8.3864 4.6333 7.9998 4.6333C7.61321 4.6333 7.2998 4.9467 7.2998 5.3333V7.99997C7.2998 8.38657 7.61321 8.69997 7.9998 8.69997C8.3864 8.69997 8.6998 8.38657 8.6998 7.99997V5.3333ZM7.9998 11.4C8.3864 11.4 8.6998 11.0866 8.6998 10.7C8.6998 10.3134 8.3864 10 7.9998 10C7.61321 10 7.2998 10.3134 7.2998 10.7C7.2998 11.0866 7.61321 11.4 7.9998 11.4Z"
        fill="white"
      />
    </svg>
  )
}

export const IconError = React.forwardRef(_IconError)
