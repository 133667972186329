import MaskedInput from 'react-text-mask'
import styled, { css } from 'styled-components'

import {
  _M_Bold,
  _M_Light,
  _M_Normal,
  mainColors,
  semanticColors,
  TextFieldStyledProps,
} from '@avantstay/backoffice-core'

import { ITimeRangeFieldContainerProps } from '@new/__ui/TimeRangeField/_types'

const styleByState = css<ITimeRangeFieldContainerProps>`
  ${props =>
    !props.focus &&
    `
    &:hover {
      border-color: ${mainColors.neutralDark};
    }`}

  ${props =>
    props.focus &&
    `
    border-color: ${mainColors.accentMedium};
  `}

  ${props =>
    props.disabled &&
    `
    border-color: ${mainColors.neutralLight};
    color: ${mainColors.neutralDark};
    cursor: not-allowed;
    pointer-events: none;

    &::placeholder {
      color: ${mainColors.neutralDark} !important;
    }
  `}

  ${props =>
    props.small &&
    `
    height: 36px;
    padding: 8px 14px;
    ${_M_Light};
    font-size: 14px !important;
  `}

  ${props =>
    props.readOnly &&
    `
    background-color: ${mainColors.neutralLight};
    color: ${mainColors.black} ;
    border-color: transparent;
    cursor: not-allowed;
    pointer-events: none;
  `}

  ${props =>
    props.error &&
    `
    border-color: ${semanticColors.failureMedium};

    &:hover {
      border-color: ${semanticColors.failureMedium};
    }
  `}

  ${props =>
    props.warning &&
    `
    border-color: ${semanticColors.warningMedium};

    &:hover {
      border-color: ${semanticColors.warningMedium};
    }
  `}
`
export const Container = styled.div<ITimeRangeFieldContainerProps>`
  position: relative;
  height: 50px;
  padding: 14px;
  display: flex;
  border: 2px solid ${mainColors.neutralMedium};
  border-radius: 2px;
  ${styleByState};
  width: 100%;
`

export const TimeRangeInput = styled(MaskedInput)`
  max-width: 120px;
  max-height: 100%;
  margin-right: 30px;
  background-color: transparent;
  border: none;
  color: ${mainColors.black};
  outline: none;
  ${_M_Normal};

  &:disabled {
    color: ${mainColors.neutralDark};
    cursor: not-allowed;
    pointer-events: none;
  }
`

export const IssueContainer = styled.div<TextFieldStyledProps>`
  position: absolute;
  top: 0;
  right: 11px;
  height: ${props => (props.small ? '36px' : '50px')};
  display: flex;
  align-items: center;
`

export const TimeLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  color: ${mainColors.neutralExtraDark};
  ${_M_Bold};
`
