import styled from 'styled-components'

import { FlexDirectionType, JustifyContentType } from './_types'

const SortableContainer = styled.div<{
  flexDirection: FlexDirectionType
  justifyContent: JustifyContentType
}>`
  display: flex;
  align-items: center;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: 12px;
  cursor: pointer;
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export { IconContainer, SortableContainer }
