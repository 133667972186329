import React, { Ref } from 'react'

function _IconTxt(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" {...props} ref={ref}>
      <path
        d="M10.5884 55.4706V4.52942C10.5884 3.97713 11.0361 3.52942 11.5884 3.52942H38.8237L52.9413 19.4118V55.4706C52.9413 56.0229 52.4936 56.4706 51.9413 56.4706H11.5884C11.0361 56.4706 10.5884 56.0229 10.5884 55.4706Z"
        fill="#F2F5F7"
      />
      <path
        d="M38.8237 18.4118V3.52942L52.9414 19.4118H39.8237C39.2714 19.4118 38.8237 18.9641 38.8237 18.4118Z"
        fill="#E9EEF1"
      />
      <rect x="5.29395" y="28.2353" width="38.8235" height="19.4118" rx="1" fill="#3788F7" />
      <path
        d="M19.929 34.8736H16.8545V43.2353H14.7865V34.8736H11.7534V33.1985H19.929V34.8736Z"
        fill="white"
      />
      <path
        d="M24.7061 36.659L26.588 33.1985H28.9662L26.0434 38.1756L29.042 43.2353H26.6362L24.7061 39.7197L22.7759 43.2353H20.3701L23.3688 38.1756L20.446 33.1985H22.8242L24.7061 36.659Z"
        fill="white"
      />
      <path
        d="M37.645 34.8736H34.5705V43.2353H32.5025V34.8736H29.4694V33.1985H37.645V34.8736Z"
        fill="white"
      />
    </svg>
  )
}

export const IconTxt = React.forwardRef(_IconTxt)
