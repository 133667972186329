import React, { Ref } from 'react'

function _IconPencil(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7306 3.4693C15.34 3.36462 14.9231 3.47631 14.6372 3.76229L5.50849 12.891L4.90813 15.0923L7.10944 14.4919L16.2381 5.36325C16.5241 5.07726 16.6358 4.66044 16.5311 4.26978C16.4264 3.87912 16.1213 3.57398 15.7306 3.4693ZM13.4881 2.61325C14.1846 1.91675 15.1998 1.64473 16.1512 1.89967C17.1027 2.15461 17.8458 2.89776 18.1007 3.8492C18.3557 4.80063 18.0837 5.8158 17.3872 6.5123L8.10592 15.7935C8.00593 15.8935 7.88159 15.9657 7.74517 16.0029L3.96392 17.0341C3.68263 17.1109 3.38179 17.031 3.17562 16.8248C2.96944 16.6186 2.88955 16.3178 2.96627 16.0365L3.99752 12.2552C4.03472 12.1188 4.10688 11.9945 4.20687 11.8945L13.4881 2.61325Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconPencil = React.forwardRef(_IconPencil)
