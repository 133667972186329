import { forwardRef, Ref, SVGProps } from 'react'

function _IconPriorityUrgent(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <rect width="16" height="16" rx="2" fill="#C51A39" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49478 3.00483C8.22141 2.73146 7.7782 2.73146 7.50483 3.00483L3.75483 6.75483C3.48146 7.0282 3.48146 7.47141 3.75483 7.74478C4.0282 8.01815 4.47141 8.01815 4.74478 7.74478L7.9998 4.48975L11.2548 7.74478C11.5282 8.01815 11.9714 8.01815 12.2448 7.74478C12.5181 7.47141 12.5181 7.0282 12.2448 6.75483L8.49478 3.00483ZM12.2448 12.0048L8.49478 8.25483C8.22141 7.98146 7.7782 7.98146 7.50483 8.25483L3.75483 12.0048C3.48146 12.2782 3.48146 12.7214 3.75483 12.9948C4.0282 13.2681 4.47141 13.2681 4.74478 12.9948L7.9998 9.73975L11.2548 12.9948C11.5282 13.2681 11.9714 13.2681 12.2448 12.9948C12.5181 12.7214 12.5181 12.2782 12.2448 12.0048Z"
        fill="white"
      />
    </svg>
  )
}

export const IconPriorityUrgent = forwardRef(_IconPriorityUrgent)
