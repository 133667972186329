import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 120px);
  gap: 8px;
  color: black;
`

const Icon = styled.div`
  border: 1px solid darkgrey;
  border-radius: 3px;
  width: 120px;
  height: 100px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  svg {
    max-width: 80%;
  }
`

export const getVectorsGrid = (vectors: any, size?: number, color?: string) => (
  <Wrapper>
    {Object.keys(vectors).map(vector => {
      const Vector = vectors[vector]
      return (
        <Icon key={vector}>
          <Vector width={size ?? 24} height={size ?? 24} color={color} />
          {vector}
        </Icon>
      )
    })}
  </Wrapper>
)
