import React from 'react'

export const IconClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7561 4.42262C13.0816 4.09717 13.0816 3.56953 12.7561 3.24408C12.4307 2.91864 11.903 2.91864 11.5776 3.24408L8.0001 6.82156L4.42262 3.24408C4.09717 2.91864 3.56953 2.91864 3.24408 3.24408C2.91864 3.56953 2.91864 4.09717 3.24408 4.42262L6.82156 8.0001L3.24408 11.5776C2.91864 11.903 2.91864 12.4307 3.24408 12.7561C3.56953 13.0816 4.09717 13.0816 4.42262 12.7561L8.0001 9.17863L11.5776 12.7561C11.903 13.0816 12.4307 13.0816 12.7561 12.7561C13.0816 12.4307 13.0816 11.903 12.7561 11.5776L9.17863 8.0001L12.7561 4.42262Z"
      fill="currentColor"
    />
  </svg>
)
