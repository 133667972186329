import React, { Ref } from 'react'

function _IconFullscreen(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 5.78689C13 5.3523 13.3523 5 13.7869 5H18.2131C18.228 5 18.2429 5.00042 18.2576 5.00123C18.4505 4.9898 18.6473 5.0578 18.7948 5.20524C18.9422 5.35268 19.0102 5.54947 18.9988 5.74244C18.9996 5.75715 19 5.77197 19 5.78689V10.2131C19 10.6477 18.6477 11 18.2131 11C17.7785 11 17.4262 10.6477 17.4262 10.2131V7.56475L14.1962 10.7948C13.9226 11.0684 13.4789 11.0684 13.2052 10.7948C12.9316 10.5211 12.9316 10.0774 13.2052 9.80378L16.4352 6.57377H13.7869C13.3523 6.57377 13 6.22147 13 5.78689ZM5.78689 13C6.22147 13 6.57377 13.3523 6.57377 13.7869V16.4352L9.80378 13.2052C10.0774 12.9316 10.5211 12.9316 10.7948 13.2052C11.0684 13.4789 11.0684 13.9226 10.7948 14.1962L7.56475 17.4262H10.2131C10.6477 17.4262 11 17.7785 11 18.2131C11 18.6477 10.6477 19 10.2131 19H5.78689C5.77197 19 5.75715 18.9996 5.74244 18.9988C5.54947 19.0102 5.35268 18.9422 5.20524 18.7948C5.0578 18.6473 4.9898 18.4505 5.00123 18.2576C5.00042 18.2429 5 18.228 5 18.2131V13.7869C5 13.3523 5.3523 13 5.78689 13Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export const IconFullscreen = React.forwardRef(_IconFullscreen)
