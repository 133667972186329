import { kebab } from 'case'
import { camelCase } from 'lodash'
import slugify from 'slugify'

import {
  ExtendedCustomFieldSection,
  OnlineTravelAgency,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { capitalizeEachFirstLetter } from '@avantstay/backoffice-core'

const filterCustomFieldBySectionId = (
  customFieldSections: ExtendedCustomFieldSection[],
  sectionIds: string[] | string,
): ExtendedCustomFieldSection[] => {
  return customFieldSections.filter(section => sectionIds.includes(section.id))
}

const formatSectionNameToUrl = (name: string): string => slugify(name, { lower: true })

export { filterCustomFieldBySectionId, formatSectionNameToUrl }

const RENTALS_UNITED_TITLE = 'Rentals United OTAs'

export const formatOTAName = (ota: OnlineTravelAgency | string) => {
  if (ota === OnlineTravelAgency.vrbo) {
    return ota
  }

  if (ota === OnlineTravelAgency.rentalsUnited) {
    return RENTALS_UNITED_TITLE
  }

  return capitalizeEachFirstLetter(ota.replace('_', ' ').replace('_', ' '))
}

export const formatSectionNameForPropertiesSections = (section: string) => {
  if (section === RENTALS_UNITED_TITLE) {
    return kebab(OnlineTravelAgency.rentalsUnited)
  }

  return formatSectionNameToUrl(section)
}

export const getOTAFromActiveSection = (activeSection: string) => {
  if (activeSection === kebab(OnlineTravelAgency.rentalsUnited)) {
    return camelCase(OnlineTravelAgency.rentalsUnited)
  }

  return camelCase(activeSection)
}
