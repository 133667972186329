import humanizeString from 'humanize-string'
import { isArray } from 'lodash'
import React, { isValidElement } from 'react'

import { Tooltip } from '../../Tooltip'
import * as S from './FilterTag.styles'

export interface FilterTagProps<FilterValueType> {
  filterTotalLength?: number
  filterValue?: FilterValueType
  hideValue?: boolean
  isDateRange?: boolean
  maximumItemCount?: number
  name?: string
  onClearFilter: VoidFunction
  shouldDisplayFilterValueAsIs?: boolean
  showFullSize?: boolean
}

export const FilterTag = <FilterValueType,>({
  filterTotalLength,
  filterValue,
  hideValue = false,
  isDateRange = false,
  maximumItemCount = 5,
  name,
  onClearFilter,
  shouldDisplayFilterValueAsIs = false,
  showFullSize = true,
}: FilterTagProps<FilterValueType>) => {
  const arrayValue = isArray(filterValue)
  const isCustomComponent = isValidElement(filterValue)
  const displayedFilters = () => {
    if (!arrayValue) {
      return String(filterValue)
    }

    if (filterValue.length <= maximumItemCount) {
      return filterValue.join(', ')
    }

    if (filterTotalLength && filterValue.length >= filterTotalLength) {
      return ''
    }

    return `${filterValue.slice(0, maximumItemCount).join(', ')} +${
      filterValue.length - maximumItemCount
    }`
  }

  const getFilterValueText = () => {
    if (!filterValue || hideValue) {
      return null
    }

    if (isDateRange) {
      return String(filterValue)
    }

    if (shouldDisplayFilterValueAsIs) {
      return displayedFilters()
    }

    return humanizeString(displayedFilters())
  }

  const withTooltip = arrayValue && filterValue.length > maximumItemCount

  return (
    <S.AppliedFiltersBox>
      <S.ClickableArea showFullSize={showFullSize}>
        {name && <S.FilterName>{name}</S.FilterName>}
        {filterValue && !hideValue && (
          <Tooltip
            isVisible={withTooltip}
            hideOnClick={false}
            placement="top"
            content={isArray(filterValue) && filterValue.join(', ')}
          >
            <S.SelectedFilters isCapitalized={!shouldDisplayFilterValueAsIs}>
              {isCustomComponent ? filterValue : getFilterValueText()}
            </S.SelectedFilters>
          </Tooltip>
        )}
      </S.ClickableArea>
      <S.IconClose onClick={onClearFilter} />
    </S.AppliedFiltersBox>
  )
}
