import React, { Ref } from 'react'

const _IconEdit = (props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5845 2.77524C12.272 2.6915 11.9385 2.78085 11.7097 3.00964L4.40679 10.3126L3.92651 12.0736L5.68755 11.5933L12.9905 4.2904C13.2193 4.06162 13.3086 3.72815 13.2249 3.41563C13.1411 3.1031 12.897 2.85899 12.5845 2.77524ZM10.7905 2.0904C11.3477 1.5332 12.1598 1.31559 12.921 1.51954C13.6821 1.72349 14.2766 2.31801 14.4806 3.07916C14.6845 3.84031 14.4669 4.65244 13.9097 5.20964L6.48473 12.6346C6.40475 12.7146 6.30527 12.7724 6.19614 12.8021L3.17114 13.6271C2.9461 13.6885 2.70543 13.6246 2.54049 13.4596C2.37556 13.2947 2.31164 13.054 2.37302 12.829L3.19802 9.804C3.22778 9.69486 3.28551 9.59539 3.36549 9.5154L10.7905 2.0904Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconEdit = React.forwardRef(_IconEdit)

export { IconEdit }
