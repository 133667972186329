import { RefObject } from 'react'

import { useEventListener } from './useEventListener'

export const modalOnClassName = 'js-modal-on'

export type ClickOutsideHandler = (e: MouseEvent | TouchEvent) => void

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: ClickOutsideHandler,
): void => {
  const shouldIgnore = (element: any) => {
    let node = element

    while (node != null) {
      if (node.classList?.contains(modalOnClassName)) {
        return true
      }

      node = node.parentNode
    }

    return false
  }

  const listener = (event: MouseEvent | TouchEvent) => {
    const el = ref.current
    // Do nothing if clicking ref's element or descendent elements
    if (!el || el.contains(event.target as Node) || shouldIgnore(event.target)) {
      return
    }

    handler(event)
  }

  useEventListener('mousedown', listener)
  useEventListener('touchstart', listener)
}
