// @ts-nocheck
import React, { FC, PropsWithChildren } from 'react'

import { MarginProps } from './Margin.types'

const Margin: FC<PropsWithChildren<MarginProps>> = ({
  children,
  left,
  right,
  top,
  bottom,
  style,
}) => (
  <div
    style={{
      marginLeft: left,
      marginRight: right,
      marginTop: top,
      marginBottom: bottom,
      display: 'inline-flex',
      ...style,
    }}
  >
    {children}
  </div>
)

export { Margin }
