import React from 'react'

function _IconRemoveCircle(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM2.27273 8C2.27273 11.1631 4.83691 13.7273 8 13.7273C11.1631 13.7273 13.7273 11.1631 13.7273 8C13.7273 4.83691 11.1631 2.27273 8 2.27273C4.83691 2.27273 2.27273 4.83691 2.27273 8ZM7.10005 8L5.64093 9.45911C5.39242 9.70763 5.39242 10.1106 5.64093 10.3591C5.88945 10.6076 6.29237 10.6076 6.54089 10.3591L8 8.89995L9.45911 10.3591C9.70763 10.6076 10.1106 10.6076 10.3591 10.3591C10.6076 10.1106 10.6076 9.70763 10.3591 9.45911L8.89995 8L10.3591 6.54089C10.6076 6.29237 10.6076 5.88945 10.3591 5.64093C10.1106 5.39242 9.70763 5.39242 9.45911 5.64093L8 7.10005L6.54089 5.64093C6.29237 5.39242 5.88945 5.39242 5.64093 5.64093C5.39242 5.88945 5.39242 6.29237 5.64093 6.54089L7.10005 8Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconRemoveCircle = React.memo(React.forwardRef(_IconRemoveCircle))

export { IconRemoveCircle }
