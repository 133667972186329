import styled from 'styled-components'

import { semanticColors } from '../../styles/colors'
import { rgba } from '../../utils/cssUtils'

const MessageBase = styled.div<{ absolute?: boolean }>`
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border-style: solid;
  border-width: 2px;
  padding: 12px 15px;
  position: ${props => (props.absolute ? 'absolute' : 'relative')};
  ${props => (props.absolute ? `top: 50%; left: 50%; transform: translate(-50%, -50%);` : '')};
`

const ErrorMessage = styled(MessageBase)`
  color: ${semanticColors.failureDark};
  border-color: ${semanticColors.failureDark};
  background-color: ${p => rgba(semanticColors.failureLight, 0.05)};
`

const SuccessMessage = styled(MessageBase)`
  color: ${semanticColors.successDark};
  border-color: ${semanticColors.successDark};
  background-color: ${p => rgba(semanticColors.successLight, 0.05)};
`

export { ErrorMessage, SuccessMessage }
