import { forwardRef, Ref, SVGProps } from 'react'

function _IconPriorityHigh(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <rect width="16" height="16" rx="2" fill="#EC4678" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.21811 10.7722C3.50893 11.0759 3.98043 11.0759 4.27125 10.7722L8 6.87772L11.7288 10.7722C12.0196 11.0759 12.4911 11.0759 12.7819 10.7722C13.0727 10.4685 13.0727 9.97599 12.7819 9.67225L8.52657 5.22781C8.23575 4.92407 7.76425 4.92407 7.47343 5.22781L3.21811 9.67225C2.9273 9.97599 2.9273 10.4685 3.21811 10.7722Z"
        fill="white"
      />
    </svg>
  )
}

export const IconPriorityHigh = forwardRef(_IconPriorityHigh)
