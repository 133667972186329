import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconArrowUpRight(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props} ref={ref}>
      <path
        d="M13.8333 22.1667L22.1666 13.8334"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8333 13.8334H22.1666V22.1667"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconArrowUpRight = forwardRef(_IconArrowUpRight)
