import styled from 'styled-components'

import { _2XS_Bold } from '../../styles'
import { rgba } from '../../utils'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  position: relative;
`

export const LineWrapper = styled.div<{
  justifyContent?: string
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 425px;
  gap: 4px;
`

export const LineStage = styled.div<{
  color: string
  isHighlighted?: boolean
}>`
  background-color: ${({ color }) => color};
  height: 6px;
  border-radius: 30px;
  flex: 1 1 0;

  ${({ isHighlighted, color }) =>
    isHighlighted &&
    `
    height: 13px;
    box-shadow: 3px 3px 15px 0px ${rgba(color, 0.3)};
  `}
`

export const LineTitle = styled.div<{
  color: string
  isHighlighted?: boolean
  isVisible?: boolean
  textAlign?: string
}>`
  visibility: hidden;
  color: ${({ color }) => color};
  ${_2XS_Bold};
  flex: 1 1 0;
  text-align: ${({ textAlign }) => textAlign || 'center'};

  ${({ isHighlighted, color }) =>
    isHighlighted &&
    `
    text-align: center;
  `}

  ${({ isVisible, color }) =>
    isVisible &&
    `
    visibility: visible;
  `}
`
