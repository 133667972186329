import React, { Ref } from 'react'

function _IconPin(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props} ref={ref}>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" fillOpacity={0.01} d="M0 0h24v24H0z" />
        <path
          fill="#878787"
          fillRule="nonzero"
          stroke="#878787"
          strokeWidth={0.2}
          d="M4.48 19.36a.475.475 0 0 1-.34-.14.475.475 0 0 1 0-.679l4.784-4.783-2.288-2.288a1.439 1.439 0 0 1-.376-1.382c.096-.373.333-.685.665-.88a5.29 5.29 0 0 1 3.56-.641l2.482-3.9A1.428 1.428 0 0 1 14.182 4c.384 0 .746.15 1.018.422l3.74 3.74a1.43 1.43 0 0 1 .387 1.33 1.432 1.432 0 0 1-.633.902l-3.898 2.482a5.285 5.285 0 0 1-.64 3.56 1.43 1.43 0 0 1-1.243.713c-.387-.001-.749-.152-1.02-.424l-2.289-2.29L4.82 19.22a.477.477 0 0 1-.339.14zm5.1-9.91a4.33 4.33 0 0 0-2.171.588.477.477 0 0 0-.095.752l5.257 5.257a.478.478 0 0 0 .754-.096 4.312 4.312 0 0 0 .466-3.19.48.48 0 0 1 .208-.517l4.179-2.66a.479.479 0 0 0 .082-.744L14.522 5.1a.477.477 0 0 0-.68 0 .506.506 0 0 0-.065.083l-2.66 4.179a.477.477 0 0 1-.517.209 4.358 4.358 0 0 0-1.02-.121z"
        />
      </g>
    </svg>
  )
}

export const IconPin = React.forwardRef(_IconPin)
