import { mainColors, semanticColors } from '../../styles/colors'

export const EVENT_NAME = 'push-alert'
export const MARGIN_BETWEEN_ALERTS = 16

export enum EventType {
  SUCCESS,
  ERROR,
  INFO,
}

export type AlertAction = {
  label: string
  color?: string
  onClick: VoidFunction
}

export enum AlertType {
  Other,
  CustomFieldIssue,
}

export type AlertProps = {
  id: string
  eventType: EventType
  type?: AlertType
  message?: string
  title?: string
  actions?: Array<AlertAction>
  timeout?: number
}

export const BASE_COLOR_BY_EVENT_TYPE = {
  [EventType.ERROR]: semanticColors.failureMedium,
  [EventType.INFO]: mainColors.primaryMedium,
  [EventType.SUCCESS]: semanticColors.successMedium,
}

export const DEFAULT_TITLE_BY_EVENT_TYPE = {
  [EventType.ERROR]: 'There is an error',
  [EventType.INFO]: 'Info',
  [EventType.SUCCESS]: 'Saved',
}
