import { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { FloatingContainer } from '@avantstay/avantstay-ui'

import { zIndex } from '../../styles'
import { mainColors } from '../../styles/colors'

export const Picker = styled.div`
  margin-top: 13px;
  margin-right: -12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;

  width: 193px;
  height: 180px;

  background: ${mainColors.white};
  border: 1px solid ${mainColors.neutralMedium};
  box-shadow: 0px 4px 16px rgba(232, 235, 237, 0.4);
  border-radius: 2px;
  user-select: none;
`

export const YearRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  padding: 5px 10px;
`

export const MonthsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  text-align: center;
  row-gap: 8px;
  font-size: 12px;
`

export const currentIndicator = css`
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - 4px);
    width: 8px;
    height: 2px;
    border-radius: 2px;
    background-color: ${mainColors.accentMedium};
  }
`

export const MonthLink = styled.div<{ active: boolean; current: boolean; disabled: boolean }>`
  position: relative;
  cursor: pointer;
  padding: 4px;
  font-weight: ${props => (props.active ? 500 : 400)};

  color: ${props => (props.active ? mainColors.accentMedium : mainColors.neutralExtraDark)};

  &:hover {
    color: ${props => (props.active ? mainColors.accentDark : mainColors.neutralExtremelyDark)};
  }

  &:active {
    color: ${props => (props.active ? mainColors.accentDark : mainColors.accentDark)};
  }

  ${props => (props.current ? currentIndicator : '')}

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    cursor: not-allowed;
    text-decoration-line: line-through;
  `}
`

export const linkInteraction = css`
  cursor: pointer;
  color: ${mainColors.neutralExtraDark};

  &:hover {
    color: ${mainColors.neutralExtremelyDark};
  }

  &:active {
    color: ${mainColors.accentMedium};
  }
`

export const ChevronLink = styled.div<{ disabled?: boolean }>`
  background: transparent;
  height: 24px;
  color: ${mainColors.neutralMedium};

  ${props => (!props.disabled ? linkInteraction : '')}
`

export const FloatingContainerStyled = styled(FloatingContainer)<
  PropsWithChildren<{ verticalValue?: number }>
>`
  z-index: ${zIndex.INFINITE} !important;
`
