import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconChevronLeftLarge(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9427 3.05752C17.4634 3.57822 17.4634 4.42244 16.9427 4.94314L9.88546 12.0003L16.9427 19.0575C17.4634 19.5782 17.4634 20.4224 16.9427 20.9431C16.422 21.4638 15.5777 21.4638 15.057 20.9431L7.05703 12.9431C6.53633 12.4224 6.53633 11.5782 7.05703 11.0575L15.057 3.05752C15.5777 2.53682 16.422 2.53682 16.9427 3.05752Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconChevronLeftLarge = forwardRef(_IconChevronLeftLarge)
