import { css } from 'styled-components'

// https://stackoverflow.com/questions/23211656/remove-ios-input-shadow
// remove outer spin in input type number
const InputReset = css`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`

const ellipsis = css`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Disabled = css`
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.4;

  &:hover {
    cursor: not-allowed;
  }
`

export { ellipsis, Disabled, InputReset }
