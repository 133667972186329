import styled, { css } from 'styled-components'

import { DropAreaRootProps, FilePreviewType } from './UploadArea.types'
import { getFileSizeByType } from './UploadArea.utils'
import { FilePreviewStyles } from './components/FilePreview'
import { _M_Medium, _M_Normal, _S_Bold, _S_Normal, mainColors, semanticColors } from '../../styles'
import { MAX_DRAG_DROP_GRID_WIDTH } from '../DragAndDropGrid/DragAndDropWrapper/DragAndDropWrapper.styles'

export const Placeholder = styled.div`
  width: 104px;
  height: 188px;
  border: 2px dotted ${mainColors.neutralMedium};
  margin-bottom: 15px;
  margin-right: 10px;

  &:first-child {
    margin-right: 10px;
  }
`

export const DropArea = styled.label<DropAreaRootProps>`
  ${_M_Normal};
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  max-width: 700px;
  color: ${mainColors.neutralExtremelyDark};
  padding: 22px 10px;
  margin-bottom: 10px;
  border: 2px dashed ${mainColors.neutralMedium};
  border-radius: 2px;
  cursor: pointer;

  span {
    color: ${mainColors.accentMedium};
  }

  &:hover {
    border-color: ${mainColors.neutralDark};
    background: ${mainColors.neutralExtraLight};
  }

  ${({ active }) =>
    active &&
    `
      border-color: ${mainColors.neutralDark};
      background: ${mainColors.neutralExtraLight};
  `};

  ${({ disabled }) =>
    disabled &&
    `
      background-color: ${mainColors.neutralExtraLight};
      cursor: not-allowed;
      pointer-events: none;

      &:hover {
        border-color: ${mainColors.neutralMedium};
      }
  `}
`

const disabledAreaStyles = css`
  cursor: not-allowed;
  pointer-events: none;
`

export const Container = styled.div<{ disabled?: boolean; $hasFlexDirection?: boolean }>`
  position: relative;
  max-height: 100%;
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    `

    ${disabledAreaStyles}

      ${FilePreviewStyles.Container} {
        opacity: 0.6;
      }
  `}

  ${({ $hasFlexDirection }) =>
    $hasFlexDirection &&
    `
      display: flex;
      flex-direction: row-reverse;
      gap: 12px;
  `}
`

export const DropAreaWrapper = styled(Container)<{ $disabledUpload?: boolean }>`
  ${({ $disabledUpload }) =>
    $disabledUpload &&
    `
     ${disabledAreaStyles}
  `}
`

export const PreviewContainer = styled.div<{ filePreviewType?: FilePreviewType }>`
  margin-top: 24px;

  ${({ filePreviewType }) =>
    filePreviewType === FilePreviewType.photoGallery &&
    `
    padding-left: 13px;

    ${Placeholder} {
      width: ${getFileSizeByType(filePreviewType).width}px;
      height: ${getFileSizeByType(filePreviewType).height}px;
    }
  `}

  ${({ filePreviewType }) =>
    filePreviewType === FilePreviewType.referencePhoto &&
    `
    margin-top: 0;

  `}
`

export const PreviewTitles = styled.div`
  ${_M_Medium};
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  max-width: ${MAX_DRAG_DROP_GRID_WIDTH}px;
`

export const HiddenInput = styled.input`
  visibility: hidden;
  padding: 0;
  width: 0;
  height: 0;
  top: 0;
  position: absolute;
`

export const ErrorContainer = styled.div`
  ${_S_Normal};
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  max-width: 700px;
  height: 36px;
  margin-left: 13px;
  padding: 11px 17px;
  color: ${semanticColors.failureMedium};
  background-color: ${semanticColors.failureExtraLight};
`

export const ErrorIndexes = styled.span`
  ${_S_Bold};
`
