import { forwardRef, Ref, SVGProps } from 'react'

function _IconPriorityMedium(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <rect width="16" height="16" rx="2" fill="#FF9E0D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.85829 8.84853C2.38966 8.3799 2.38966 7.6201 2.85829 7.15147L7.15141 2.85836C7.62004 2.38973 8.37984 2.38973 8.84846 2.85836L13.1416 7.15147C13.6102 7.6201 13.6102 8.3799 13.1416 8.84853L8.84846 13.1416C8.37984 13.6103 7.62004 13.6103 7.15141 13.1416L2.85829 8.84853ZM3.98966 8L7.99994 12.0103L12.0102 8L7.99994 3.98973L3.98966 8Z"
        fill="white"
      />
    </svg>
  )
}

export const IconPriorityMedium = forwardRef(_IconPriorityMedium)
