// @ts-nocheck
import React, { useEffect, useRef } from 'react'

import { Button } from '../../components/Button'
import { InfoMessage } from '../../components/InfoMessage'
import { isIOS } from '../../hooks/useDeviceDetector'
import { formatPhone, formatPhoneString } from '../../utils/format'
import * as S from './PhoneVerification.styles'
import { PhoneVerificationInput } from './PhoneVerificationInput'
import { PhoneVerificationResendCode } from './PhoneVerificationResendCode'
import { PhoneVerificationProps } from './types'

const PhoneVerification = ({
  phone,
  digits,
  isEnabled = true,
  error,
  restriction,
  onChangeCodeDigit,
  onClickEditPhone,
  onClickResendCode,
}: PhoneVerificationProps): JSX.Element => {
  const iOS = isIOS()
  const securityCodeContainerRef = useRef<HTMLDivElement>(null)

  const isCodeEmpty = digits.every(digit => digit === undefined)
  const phoneFormatted = formatPhone(formatPhoneString(phone))

  // Input digit handler
  const createInputChangeHandler = (index: number) => (value: number | undefined) => {
    onChangeCodeDigit(index, value)

    if (value === undefined) {
      focusOnInput(index - 1)
    } else {
      focusOnInput(index + 1)
    }
  }

  // Change input focus
  const focusOnInput = (inputIndexToFocus: number) => {
    const securityCodeContainerElement = securityCodeContainerRef.current

    const nextInputElement = securityCodeContainerElement?.children[
      inputIndexToFocus
    ] as HTMLInputElement

    nextInputElement?.focus()
    nextInputElement?.select()
    nextInputElement?.scrollIntoView()
  }

  // Effect for code
  useEffect(() => {
    // Avoid focusing on iOS
    if (iOS || !isCodeEmpty) {
      return
    }

    focusOnInput(0)
  }, [iOS, isCodeEmpty])

  return (
    <S.Root hasRestriction={Boolean(restriction)}>
      <S.SecurityCodeHeader>
        <S.SecurityCodeHeaderTitle>Enter your security code</S.SecurityCodeHeaderTitle>
        <Button.Flat onClick={onClickEditPhone}>Edit phone</Button.Flat>
      </S.SecurityCodeHeader>

      <S.SecurityCodeMessage>
        Please input the verification code texted to <strong>{phoneFormatted}</strong>. It may take
        a minute to arrive.
      </S.SecurityCodeMessage>

      <S.SecurityCodeContainer ref={securityCodeContainerRef}>
        {digits.map((digit: number | undefined, index: number) => (
          <PhoneVerificationInput
            key={`${digit}-${index}`}
            value={digit}
            disabled={!isEnabled}
            hasError={error !== undefined}
            onChange={createInputChangeHandler(index)}
          />
        ))}
      </S.SecurityCodeContainer>

      {error !== undefined && (
        <InfoMessage message={error} messageType="error" margin="0 0 20px"></InfoMessage>
      )}

      <PhoneVerificationResendCode onClick={onClickResendCode} />
    </S.Root>
  )
}

export { PhoneVerification }
