import { Month } from '../enums/calendar'

const NOW = new Date()
const YEAR_RANGE = [
  NOW.getFullYear() - 1,
  NOW.getFullYear(),
  NOW.getFullYear() + 1,
  NOW.getFullYear() + 2,
]
const MAX_YEAR = Math.max(...YEAR_RANGE)
const MIN_YEAR = Math.min(...YEAR_RANGE)

const NOW_DAY = NOW.getDate()
const NOW_MONTH: Month = NOW.getMonth() + 1 // 1 - 12
const NOW_YEAR = NOW.getFullYear()

const MONTH_NAMES = Object.keys(Month).filter(key => !(parseInt(key, 10) >= 0))

const FILTER_YEAR_MAX = NOW.getFullYear()
const FILTER_YEAR_MIN = NOW.getFullYear() - 2

const setupSelectYearRange = () => {
  const startYear = NOW.getFullYear() - 6
  return Array.from(new Array(NOW.getFullYear() - startYear + 1), (v, idx) => startYear + idx)
}
const SELECT_YEAR_RANGE = setupSelectYearRange()

export {
  MONTH_NAMES,
  NOW_DAY,
  NOW_MONTH,
  NOW_YEAR,
  YEAR_RANGE,
  MIN_YEAR,
  MAX_YEAR,
  FILTER_YEAR_MAX,
  FILTER_YEAR_MIN,
  SELECT_YEAR_RANGE,
}
