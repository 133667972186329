import React, { Ref } from 'react'

function _IconFloorRate(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <g clipPath="url(#clip0_31525_256618)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99935 2.91675C10.4596 2.91675 10.8327 3.28984 10.8327 3.75008V4.58343H12.0827C12.5429 4.58343 12.916 4.95652 12.916 5.41676C12.916 5.877 12.5429 6.25009 12.0827 6.25009H8.95768C8.79192 6.25009 8.63295 6.31594 8.51574 6.43315C8.39853 6.55036 8.33268 6.70933 8.33268 6.87509C8.33268 7.04085 8.39853 7.19983 8.51574 7.31704C8.63295 7.43425 8.79192 7.50009 8.95768 7.50009H11.041C11.6488 7.50009 12.2317 7.74154 12.6615 8.17131C13.0912 8.60108 13.3327 9.18397 13.3327 9.79176C13.3327 10.3995 13.0912 10.9824 12.6615 11.4122C12.2317 11.842 11.6488 12.0834 11.041 12.0834H10.8327V12.9167C10.8327 13.377 10.4596 13.7501 9.99935 13.7501C9.53911 13.7501 9.16602 13.377 9.16602 12.9167V12.0834H7.49935C7.03911 12.0834 6.66602 11.7103 6.66602 11.2501C6.66602 10.7899 7.03911 10.4168 7.49935 10.4168H11.041C11.2068 10.4168 11.3657 10.3509 11.483 10.2337C11.6002 10.1165 11.666 9.95752 11.666 9.79176C11.666 9.626 11.6002 9.46703 11.483 9.34982C11.3657 9.23261 11.2068 9.16676 11.041 9.16676H8.95768C8.34989 9.16676 7.767 8.92532 7.33723 8.49555C6.90746 8.06578 6.66602 7.48288 6.66602 6.87509C6.66602 6.26731 6.90746 5.68441 7.33723 5.25464C7.767 4.82487 8.34989 4.58343 8.95768 4.58343H9.16602V3.75008C9.16602 3.28984 9.53911 2.91675 9.99935 2.91675ZM3.74935 16.2501C3.28911 16.2501 2.91602 16.6232 2.91602 17.0834C2.91602 17.5437 3.28911 17.9167 3.74935 17.9167H16.2493C16.7096 17.9167 17.0827 17.5437 17.0827 17.0834C17.0827 16.6232 16.7096 16.2501 16.2493 16.2501H3.74935Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_31525_256618">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconFloorRate = React.forwardRef(_IconFloorRate)
