import { get, isEqual } from 'lodash'

import { PropsType } from '@contexts/Auth/Auth.types'
import { ROUTES } from '@routes'

export const startNavigation = (props: PropsType) => {
  const currentPathName = window.location.pathname

  const nextLocation = get(props, 'location.state.nextLocation', {
    pathname: currentPathName,
  })

  const disabledPathsWhenLogged = [ROUTES.login.path, ROUTES.home.path]

  const shouldGoToDashboard = disabledPathsWhenLogged.includes(nextLocation.pathname)
  const shouldForcePush = disabledPathsWhenLogged.includes(currentPathName)

  if (!isEqual(nextLocation.pathname, currentPathName) || shouldForcePush) {
    props.history.push(shouldGoToDashboard ? ROUTES.dashboard.path : nextLocation)
  }
}
