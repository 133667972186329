import React from 'react'

import * as S from './ToggleAmountTypeInput.styles'
import { ToggleAmountPolarityProps, ToggleInputPolarity } from './ToggleAmountTypeInput.types'

const nextPolarityBasedOnCurrentPolarity: { [key: number]: ToggleInputPolarity } = {
  [ToggleInputPolarity.negative]: ToggleInputPolarity.positive,
  [ToggleInputPolarity.positive]: ToggleInputPolarity.negative,
}

function ToggleAmountPolarity({
  polarity,
  small,
  onChange,
}: ToggleAmountPolarityProps): JSX.Element {
  const handleNextPolarity = () => {
    onChange(nextPolarityBasedOnCurrentPolarity[polarity])
  }

  return (
    <S.ToggleContainer small={small} margin={8}>
      <S.ToggleItem
        active={polarity === ToggleInputPolarity.negative}
        small={small}
        onClick={handleNextPolarity}
      >
        -
      </S.ToggleItem>
      <S.ToggleItem
        active={polarity === ToggleInputPolarity.positive}
        small={small}
        onClick={handleNextPolarity}
      >
        +
      </S.ToggleItem>
    </S.ToggleContainer>
  )
}

export { ToggleAmountPolarity }
