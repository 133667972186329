import styled from 'styled-components'

import { _M_Medium } from '../../styles/typography'

export const BooleanTag = styled.div<{ bg: string; color: string }>`
  ${_M_Medium};
  display: inline-block;
  padding: 2px 8px;
  border-radius: 2px;
  background: ${props => props.bg};
  color: ${props => props.color};
`
