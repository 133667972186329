import React, { useEffect, useRef, useState } from 'react'

import { Button } from '../../components/Button'
import * as S from './PhoneVerificationResendCode.styles'
import { PhoneVerificationResendCodeProps } from './types'

const INITIAL_TIMER = 45

const PhoneVerificationResendCode = ({
  disabled,
  onClick,
}: PhoneVerificationResendCodeProps): JSX.Element => {
  const intervalIdRef = useRef<number>()
  const [timer, setTimer] = useState(INITIAL_TIMER)

  const isTimerActive = timer > 0

  const clearCounter = () => {
    setTimer(0)
    window.clearInterval(intervalIdRef.current)
  }

  const resetCounter = () => {
    setTimer(INITIAL_TIMER)
    intervalIdRef.current = window.setInterval(() => {
      setTimer(prevValue => Math.max(prevValue - 1, 0))
    }, 1000)
  }

  const clickHandler = () => {
    onClick()
    resetCounter()
  }

  useEffect(() => {
    resetCounter()
    return clearCounter
  }, [])

  useEffect(() => {
    if (timer === 0) {
      clearCounter()
    }
  }, [timer])

  return (
    <S.Root>
      {isTimerActive ? (
        <>
          <S.Text>{`0:${timer.toString(10).padStart(2, '0')}`}</S.Text>
          <span>•</span>
        </>
      ) : (
        <S.Text>Didn't get the code?</S.Text>
      )}

      <Button.Flat onClick={clickHandler} disabled={disabled || isTimerActive}>
        Try again
      </Button.Flat>
    </S.Root>
  )
}

export { PhoneVerificationResendCode }
