import React, { Ref } from 'react'

function _IconUser2(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6254 8.42519C14.6254 7.11352 15.6887 6.0502 17.0004 6.0502C18.3121 6.0502 19.3754 7.11352 19.3754 8.42519C19.3754 9.73687 18.3121 10.8002 17.0004 10.8002C15.6887 10.8002 14.6254 9.73687 14.6254 8.42519ZM17.0004 3.4502C14.2528 3.4502 12.0254 5.67758 12.0254 8.42519C12.0254 10.1115 12.8644 11.6019 14.1478 12.5017C11.9593 13.562 10.4502 15.8046 10.4502 18.3996V21.5496C10.4502 22.2676 11.0322 22.8496 11.7502 22.8496H12.7026L13.6102 30.1108C13.6916 30.7614 14.2446 31.2496 14.9002 31.2496H19.1002C19.7558 31.2496 20.3088 30.7614 20.3902 30.1108L21.2978 22.8496H22.2502C22.9682 22.8496 23.5502 22.2676 23.5502 21.5496V18.3996C23.5502 15.8047 22.0412 13.5622 19.8528 12.5018C21.1363 11.602 21.9754 10.1116 21.9754 8.42519C21.9754 5.67758 19.748 3.4502 17.0004 3.4502ZM13.0502 18.3996C13.0502 16.2181 14.8187 14.4496 17.0002 14.4496C19.1817 14.4496 20.9502 16.2181 20.9502 18.3996V20.2496H20.1502C19.4946 20.2496 18.9416 20.7378 18.8602 21.3884L17.9526 28.6496H16.0478L15.1402 21.3884C15.0588 20.7378 14.5058 20.2496 13.8502 20.2496H13.0502V18.3996Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconUser2 = React.forwardRef(_IconUser2)
