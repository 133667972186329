import styled, { css } from 'styled-components'

import { mainColors, semanticColors } from '../../styles/colors'
import { _M_Normal } from '../../styles/typography'
import { PhoneFieldContainerProps } from './PhoneField.types'

const styleByState = css<PhoneFieldContainerProps>`
  ${props =>
    props.disabled &&
    `
      background-color: ${mainColors.neutralExtraLight};
      border-color: ${mainColors.neutralMedium};
      color: ${mainColors.neutralExtremelyDark};
  `}

  ${props =>
    !props.disabled &&
    !props.focus &&
    !props.error &&
    `
    &:hover {
      border-color: ${mainColors.neutralDark};
    `}

  ${props =>
    !props.disabled &&
    props.focus &&
    !props.error &&
    `
    border-color: ${mainColors.accentMedium};
  `}

  ${props =>
    props.error &&
    `
    border-color: ${semanticColors.failureMedium};

    &:hover {
      border-color: ${semanticColors.failureMedium};
    }
  `}

  ${props =>
    props.warning &&
    `
    border-color: ${semanticColors.warningMedium};

    &:hover {
      border-color: ${semanticColors.warningMedium};
    }
  `}
`

export const Container = styled.div<PhoneFieldContainerProps>`
  ${_M_Normal}
  width: 100%;
  height: ${({ isSmall }) => (isSmall ? '36px' : '48px')};
  background-color: transparent;
  border: 2px solid ${mainColors.neutralMedium};
  border-radius: 2px;
  box-sizing: border-box;
  position: relative;

  ${styleByState}
`

export const IssueContainer = styled.div`
  position: absolute;
  top: 2px;
  right: 11px;
  height: 100%;
  display: flex;
  align-items: center;

  span {
    background: transparent !important;

    &:hover {
      background: transparent !important;
    }
  }
`

export const ContainerStyle = {
  top: 0,
  left: 0,
  height: '44px',
  border: 'none',
}

export const SmallContainerStyle = {
  top: 0,
  left: 0,
  height: '32px',
  border: 'none',
}

export const InputStyle = {
  height: '44px',
  width: '100%',
  paddingLeft: '60px',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '14px',
  fontWeight: 400,
  background: 'none',
  border: 'none',
}

export const SmallInputStyle = {
  height: '32px',
  width: '100%',
  paddingLeft: '60px',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '13px',
  fontWeight: 400,
  background: 'none',
  border: 'none',
}

export const ButtonStyle = {
  display: 'flex',
  justifyContent: 'center',
  width: '55px',
  height: '44px',
  background: 'transparent',
  border: 'none',
}

export const SmallButtonStyle = {
  display: 'flex',
  justifyContent: 'center',
  width: '55px',
  height: '32px',
  background: 'transparent',
  border: 'none',
}

export const DropdownStyle = {
  marginTop: '50px',
  transform: 'translateX(122px)',
}
