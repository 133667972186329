import React, { Ref } from 'react'

function _IconSettings(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1764 8.62364C16.534 7.32948 16.8853 5.36309 16.0531 3.71607L13.7469 5.9066C13.1543 6.4694 12.1937 6.4694 11.6011 5.9066C11.0086 5.34379 11.0086 4.43131 11.6011 3.86851L13.9067 1.67734C12.1732 0.882796 10.1002 1.21654 8.73917 2.50929C7.37814 3.80205 7.02678 5.771 7.86328 7.41755L1.94464 13.0393C1.35192 13.6021 1.35177 14.5147 1.9443 15.0777C2.53683 15.6407 3.49765 15.6408 4.09037 15.078L10.0104 9.45563C11.7441 10.2457 13.8138 9.91239 15.1764 8.62364Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconSettings = React.forwardRef(_IconSettings)
