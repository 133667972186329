import React, { Ref } from 'react'

function _IconTrash(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1377 5.72181C10.2618 5.59282 10.4301 5.52036 10.6056 5.52036H13.3917C13.5672 5.52036 13.7355 5.59282 13.8595 5.72181C13.9836 5.85079 14.0534 6.02573 14.0534 6.20814V6.89521H9.9439V6.20814C9.9439 6.02573 10.0136 5.85079 10.1377 5.72181ZM8.48121 6.89521V6.20814C8.48121 5.62251 8.70503 5.06086 9.10343 4.64675C9.50183 4.23264 10.0422 4 10.6056 4H13.3917C13.9551 4 14.4954 4.23264 14.8938 4.64675C15.2922 5.06086 15.516 5.62251 15.516 6.20814V6.89521H18.2687C18.6726 6.89521 19 7.23556 19 7.65539C19 8.07523 18.6726 8.41557 18.2687 8.41557H17.6056V17.7919C17.6056 18.3775 17.3818 18.9391 16.9834 19.3532C16.585 19.7674 16.0446 20 15.4812 20H8.51604C7.95262 20 7.41227 19.7674 7.01387 19.3532C6.61548 18.9391 6.39166 18.3775 6.39166 17.7919V8.41557H5.73134C5.32743 8.41557 5 8.07523 5 7.65539C5 7.23556 5.32743 6.89521 5.73134 6.89521H8.48121ZM7.85435 17.7919V8.41629H16.1429V17.7919C16.1429 17.9743 16.0732 18.1492 15.9491 18.2782C15.825 18.4072 15.6567 18.4796 15.4812 18.4796H8.51604C8.34055 18.4796 8.17224 18.4072 8.04815 18.2782C7.92406 18.1492 7.85435 17.9743 7.85435 17.7919ZM10.6063 10.5158C11.0102 10.5158 11.3376 10.8562 11.3376 11.276V15.6199C11.3376 16.0397 11.0102 16.3801 10.6063 16.3801C10.2023 16.3801 9.87492 16.0397 9.87492 15.6199V11.276C9.87492 10.8562 10.2023 10.5158 10.6063 10.5158ZM14.123 11.276C14.123 10.8562 13.7956 10.5158 13.3917 10.5158C12.9878 10.5158 12.6603 10.8562 12.6603 11.276V15.6199C12.6603 16.0397 12.9878 16.3801 13.3917 16.3801C13.7956 16.3801 14.123 16.0397 14.123 15.6199V11.276Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconTrash = React.forwardRef(_IconTrash)
