import isEmpty from 'lodash/isEmpty'
import React from 'react'

import { formatDate, TextField } from '@avantstay/backoffice-core'

// eslint-disable-next-line import/no-cycle
import { jsonParseCustomFieldValue } from '@utils/customFields'

import {
  CustomFieldProps,
  CustomFieldTypeReturn,
  IInputProps,
  IMountComponentProps,
} from '../_types'
import * as S from './types.styles'

const MASK = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ':',
  /\d/,
  /\d/,
]

const parseValueToShow = (customField: CustomFieldProps) => {
  const value = jsonParseCustomFieldValue(customField)

  return isEmpty(value) || !value ? null : formatDate(value, 'MM/dd/yyyy hh:mm')
}

function ComponentReadMode({ customField }: IMountComponentProps) {
  return <S.Text>{parseValueToShow(customField)}</S.Text>
}

function ComponentWriteMode({
  inputProps,
  error,
  warning,
  customField,
  isReadOnly,
  disabled,
}: IMountComponentProps) {
  if (isReadOnly) return <ComponentReadMode customField={customField} />

  return (
    <TextField.Masked
      {...(inputProps as IInputProps)}
      mask={MASK}
      error={error}
      warning={warning}
      disabled={disabled}
    />
  )
}

const parseValueToSave = (customField: CustomFieldProps) => {
  const value = JSON.stringify(customField.value)

  return isEmpty(value) || !value ? JSON.stringify(null) : formatDate(value, 'yyyy-MM-ddThh:mm:ss')
}

export default {
  read: { Component: ComponentReadMode },
  write: { Component: ComponentWriteMode },
  parseValueToShow,
  parseValueToSave,
} as CustomFieldTypeReturn
