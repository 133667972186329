import { getNewUuid } from '../../utils/getNewUuid'
import { AlertAction, AlertProps, EVENT_NAME, EventType } from './Alert.definitions'

function dispatchEvent(detail: AlertProps) {
  window.dispatchEvent(
    new CustomEvent(EVENT_NAME, {
      bubbles: true,
      detail,
    }),
  )
}

type AlertFunctionOptionsType = {
  title?: string
  actions?: Array<AlertAction>
  timeout?: number
}

const createAlertFunction =
  (eventType: EventType) => (message?: string, options?: AlertFunctionOptionsType) => {
    dispatchEvent({
      id: getNewUuid(),
      eventType,
      message,
      ...(options || {}),
    })
  }

const Error = createAlertFunction(EventType.ERROR)
const Info = createAlertFunction(EventType.INFO)
const Success = createAlertFunction(EventType.SUCCESS)

export const Alert = { Error, Info, Success }
