import styled from 'styled-components'

import { _S_Normal, _XS_Medium, mainColors, medias } from '../../styles'

export const Container = styled.div<{ rectangular: boolean }>`
  position: relative;
  height: ${p => (p.rectangular ? 30 : 24)}px;
`

interface IControlProps {
  disabled?: boolean
  checked?: boolean
}

export const ControlDefault = styled.label<
  IControlProps & {
    rectangular: boolean
  }
>`
  display: flex;
  align-items: center;
  height: ${p => (p.rectangular ? 30 : 24)}px;
  width: max-content;
  background: ${mainColors.neutralExtraLight};
  border-radius: ${p => (p.rectangular ? 2 : 20)}px;
  padding: 0 10px !important;
  cursor: pointer;

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    ${Title} {
      color: ${mainColors.neutralExtraDark};
    }
  `}
`

export const HiddenControlInput = styled.input<IControlProps>`
  display: none;
  cursor: pointer;
  position: absolute;

  &:hover + label {
    background: ${mainColors.neutralMedium};
  }

  &:checked + label {
    background: ${mainColors.accentMedium};

    span {
      color: ${mainColors.white};
    }
  }
`

export const ControlChildren = styled.span<{ noMargin: boolean }>`
  margin-left: ${p => (p.noMargin ? 0 : 7)}px;
  display: flex;
  align-items: center;
  color: ${mainColors.neutralDark};
`

export const Title = styled.span`
  ${_S_Normal};
  color: ${mainColors.neutralExtremelyDark};
`

export const GroupTitle = styled.label`
  display: none;
  ${_XS_Medium};
  margin-right: 12px;
  color: ${mainColors.neutralExtraDark};

  ${medias.LG_SCREEN`
    display: block;
  `}
`

export const Root = styled.div`
  display: flex;
  align-items: center;
`

export const GroupContainer = styled.div<{ rectangular: boolean }>`
  background: ${mainColors.neutralExtraLight};
  border: 1px solid ${mainColors.neutralMedium};
  border-radius: ${p => (p.rectangular ? 4 : 60)}px;
  padding: 3px;
  display: flex;
  align-items: center;
  width: max-content;

  ${Container}:not(:last-child) {
    margin-right: 4px;
  }

  ${Container}:not(:first-child) {
    margin-left: 4px;
  }
`
