import styled from 'styled-components'

import { mainColors, shadowColors } from '../../styles/colors'
import { _M_Normal } from '../../styles/typography'
import { rgba } from '../../utils/cssUtils'

interface MenuItemProps {
  disabled?: boolean
  color?: string
  iconFirst?: boolean
}

interface MenuContainerProps {
  verticalPosition?: string
  horizontalPosition?: string
  textAlign?: string
}

interface RelativeContainerProps {
  width?: string
  height?: string
}

export const RelativeContainer = styled.div<RelativeContainerProps>`
  position: relative;

  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
`

export const ChildrenWrapper = styled.div<{ isTriggerActive?: boolean }>`
  width: inherit;
  height: inherit;

  ${({ isTriggerActive }) =>
    isTriggerActive &&
    `svg {
      color: ${mainColors.accentMedium};

      &:hover {
        color: ${mainColors.accentMedium};
      }
    }`}
`

export const MenuItem = styled.div<MenuItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${_M_Normal};
  line-height: 20px;
  padding: 5px 16px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ color, disabled }) =>
    disabled ? mainColors.neutralDark : color || mainColors.neutralExtremelyDark};
  white-space: nowrap;
  gap: 6px;

  &:hover {
    background: ${mainColors.neutralExtraLight};
  }

  ${({ iconFirst }) =>
    iconFirst &&
    `justify-content: flex-end;
    flex-direction: row-reverse;
  `}
`

export const MenuContainer = styled.div<MenuContainerProps>`
  position: absolute;
  padding: 9px 0px;
  background: ${mainColors.white};
  border-radius: 2px;
  box-shadow: 0px 4px 16px ${rgba(shadowColors.blur, 0.4)};
  border: 1px solid ${mainColors.neutralMedium};
  text-align: ${p => p.textAlign};
  z-index: 99;
  min-width: 145px;

  ${({ verticalPosition }) => verticalPosition === 'top' && 'bottom: calc(100% + 18px)'};
  ${({ verticalPosition }) => verticalPosition === 'bottom' && 'top: calc(100% + 10px)'};

  ${({ horizontalPosition }) => horizontalPosition === 'left' && 'left: 0px'};
  ${({ horizontalPosition }) => horizontalPosition === 'right' && 'right: 20%'};
  ${({ horizontalPosition }) => horizontalPosition === 'top-left' && 'left: 20px; top: 0;'};
  ${({ horizontalPosition }) => horizontalPosition === 'bottom-left' && 'right: 0; top: 25px;'};
`
