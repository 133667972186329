import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

import { _M_Medium, _S_Bold, _S_Normal, _XS_Bold, mainColors } from '../../styles'

export const TooltipContainer = styled.div`
  max-width: 300px;
  ${_S_Normal};
  line-height: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  li + li {
    margin-top: 10px;
  }

  ul li::before {
    content: '';
    display: inline-block;

    background: white;
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }

  strong {
    ${_S_Bold};
  }
`

export const IndicatorTooltipWrapper = styled.span``

export const TooltipTitle = styled.div`
  ${_M_Medium};
  text-align: left;
`

export const TooltipSectionTitle = styled.h4`
  color: ${mainColors.accentMedium};
  ${_XS_Bold};
  text-align: left;
`

export const Markdown = styled(ReactMarkdown)`
  text-align: left;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`
