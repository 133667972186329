import React, { forwardRef, SVGProps } from 'react'

export const IconEyeBlocked = forwardRef<SVGSVGElement>((props: SVGProps<SVGSVGElement>, ref) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.41681 13.0766C6.13851 14.9722 9.00667 16.9985 11.8948 16.9985H11.9908L12.0901 16.9992C14.9883 16.9992 17.8618 14.9729 19.5855 13.0759C20.1387 12.462 20.138 11.5362 19.5841 10.9209C17.8611 9.02595 14.9909 7.00098 12.1061 7.00098C12.0688 7.00098 12.0321 7.00098 12.0041 7.00164C11.9701 7.00098 11.9375 7.00098 11.9035 7.00098C9.00933 7.00098 6.13784 9.02595 4.41548 10.9223C3.86291 11.5355 3.86291 12.4613 4.41681 13.0766ZM5.15668 11.5928C6.7344 9.8558 9.33528 8.00013 11.9022 8.00013L11.9908 8.00147L12.0948 8.0008C14.6617 8.0008 17.2639 9.8558 18.8423 11.5915C19.0516 11.8241 19.0516 12.1741 18.8436 12.4047C17.2645 14.1424 14.659 15.9987 12.0815 15.9987L11.9908 15.9981L11.9035 15.9987C9.33528 15.9987 6.73507 14.1424 5.15802 12.406C4.94872 12.1734 4.94872 11.8235 5.15668 11.5928ZM9.87785 14.119C10.4444 14.6856 11.1976 14.9982 11.9988 14.9982H11.9995C12.8007 14.9982 13.5532 14.6863 14.1198 14.1204C14.6863 13.5538 14.9989 12.8013 14.9989 11.9974C14.9989 11.1962 14.687 10.4437 14.1204 9.87711C13.5539 9.31121 12.8007 8.99927 11.9995 8.99927C10.3458 8.99927 9 10.3444 9 11.9981C8.99934 12.7993 9.31128 13.5525 9.87785 14.119ZM10.0005 11.9981C10.0005 10.8956 10.8977 9.99909 12.0001 9.99909C12.534 9.99909 13.036 10.2071 13.4132 10.5837C13.7912 10.9609 13.9991 11.4628 13.9991 11.9987C13.9991 12.5326 13.7905 13.0352 13.4132 13.4125C13.036 13.7898 12.534 13.9977 11.9995 13.9977C11.4656 13.9977 10.9637 13.7898 10.5857 13.4118C10.2085 13.0346 10.0005 12.532 10.0005 11.9981Z"
        fill="#94A4AD"
      />
      <path d="M4.90002 5.5L17.4 18.5" stroke="#94A4AD" stroke-width="1.2" stroke-linecap="round" />
      <path d="M5.59998 4.5L18.1 17.5" stroke="white" stroke-width="1.2" stroke-linecap="round" />
    </svg>
  )
})
