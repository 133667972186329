import React, { FC } from 'react'

import { HorizontalButtonGroupButton } from './HorizontalButtonGroup.Button'
import { HorizontalButtonGroupContainer } from './HorizontalButtonGroup.styles'
import { HorizontalButtonGroupProps } from './HorizontalButtonGroup.types'

const _HorizontalButtonGroup: FC<HorizontalButtonGroupProps> = ({ children }) => (
  <HorizontalButtonGroupContainer>{children}</HorizontalButtonGroupContainer>
)
export const HorizontalButtonGroup = Object.assign(_HorizontalButtonGroup, {
  Button: HorizontalButtonGroupButton,
})
