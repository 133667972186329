import isEqual from 'lodash/isEqual'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'

import { datadogInitializer } from '@/datadogInitializer'
import { PropsType, StateType } from '@contexts/Auth/Auth.types'
import AuthContext, { initialContext, UserProfile } from '@contexts/Auth/AuthContext'
import { startNavigation } from '@contexts/Auth/methods/authMethods'
import { googleAuth } from '@contexts/Auth/methods/googleAuth'
import { identifyCurrentUser } from '@contexts/Auth/methods/identifyCurrentUser'
import {
  cleanToken,
  setToken,
  startTokenRenewalListener,
} from '@contexts/Auth/methods/tokenMethods'
import {
  extractUserFromGoogleAuth,
  extractUserFromToken,
} from '@contexts/Auth/utils/extractUserMethods'
import { ROUTES } from '@routes'
import { trackError, trackEvent } from '@tracking/customEventReport'
import { IS_PRODUCTION } from '@utils/constants'

import { AuthStore } from './AuthStore'

const MERGE_REQUEST_AUTH_TOKEN = process.env.REACT_APP_TABLEAU_MERGE_REQUEST_AUTH_TOKEN

class AuthProvider extends PureComponent<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props)
    this.state = {
      ...initialContext,
      isMounted: false,
      showAuthenticatingMessage: false,
    }
  }

  async componentDidUpdate(_prevProps: PropsType, prevState: StateType) {
    const { user, isSignedIn } = this.state

    if (!MERGE_REQUEST_AUTH_TOKEN) {
      await setToken()
    }

    if (isSignedIn && !!user && !isEqual(prevState.user, user) && !user.permissions) {
      await this.getUserPermission(user)
    }
  }

  // as we are using withRouter, the props are gonna get updated
  // as soon as we update any state from this auth context
  // making the redirect after login stop working as expected
  // since it needs this.props.location state in order to know what is next router
  getPrevProps() {
    return this.props
  }

  getUserPermission = async (user: UserProfile | null) => {
    try {
      const userInfo = await AuthStore.__ASYNC_userPermissions
      const userWithPermissions = {
        ...user,
        voyageId: userInfo?.voyageId,
        permissions: userInfo?.userPermissions,
      } as UserProfile
      this.setUser(userWithPermissions)
    } catch (err) {
      trackError('Error fetching user permissions', { error: err })
    }
  }

  signIn = async () => {
    try {
      trackEvent('Auth: Attempting to sign in', {})
      this.setState({ isAuthorizing: true })
      const auth = await googleAuth
      await auth.signIn()
      const tokenId = await setToken()

      if (!tokenId) {
        throw new Error('No user token Id')
      }

      const userInfo = extractUserFromGoogleAuth(auth)

      trackEvent('Auth: User was able to sign in', { ...userInfo })
    } catch (e) {
      trackError('Auth: User was unable to sign in.', { error: e })
      this.setUser(null)
    }
  }

  signOut = async () => {
    const auth = await googleAuth
    await auth.signOut()

    this.setUser(null)
    cleanToken()
    identifyCurrentUser(false)
  }

  setUser = (user: UserProfile | null) => {
    this.setState({
      user: user as UserProfile,
      isAuthorizing: !!user && false,
      isSignedIn: !!user && true,
    })
  }

  updateSignInStatus = async (userInfo: UserProfile, isSignedIn: boolean) => {
    if (!isSignedIn) return

    // please read the description on top of getPrevProps function
    const prevProps = this.getPrevProps()

    this.setUser(userInfo)
    setToken()
    identifyCurrentUser(userInfo)
    startNavigation(prevProps)
  }

  toggleAuthenticationMessage = () => {
    this.setState({ showAuthenticatingMessage: true })
  }

  skipAuth = async () => {
    const runAsEmail = new URLSearchParams(window.location.search).get('runAs')
    const presetAuthToken = MERGE_REQUEST_AUTH_TOKEN || sessionStorage.getItem('test_token') || ''

    if (IS_PRODUCTION || !presetAuthToken || runAsEmail) return false

    const user =
      MERGE_REQUEST_AUTH_TOKEN || sessionStorage.getItem('test_token')
        ? {
            id: '1',
            name: 'John Doe',
            email: 'dev@avantstay.com',
            photo: 'https://thispersondoesnotexist.com',
          }
        : extractUserFromToken(presetAuthToken)

    localStorage.setItem('id_token', presetAuthToken)
    localStorage.setItem('teammate', user.email)

    // please read the description on top of getPrevProps function
    const prevProps = this.getPrevProps()

    await this.getUserPermission(user)
    this.setState(
      {
        isMounted: true,
        isSignedIn: true,
        showAuthenticatingMessage: false,
      },
      () => startNavigation(prevProps),
    )

    return true
  }

  runAs = async () => {
    const runAsEmail = new URLSearchParams(window.location.search).get('runAs')

    if (IS_PRODUCTION || !MERGE_REQUEST_AUTH_TOKEN || !runAsEmail) return false

    const viewAsUser = {
      id: '1',
      name: `Running as ${runAsEmail}`,
      email: runAsEmail,
      photo: '',
    }

    localStorage.setItem('id_token', MERGE_REQUEST_AUTH_TOKEN)
    localStorage.setItem('teammate', viewAsUser.email)
    localStorage.setItem('runAs', viewAsUser.email)

    if (!MERGE_REQUEST_AUTH_TOKEN) {
      const auth = await googleAuth
      const currentUser = extractUserFromGoogleAuth(auth)

      localStorage.setItem('currentUserName', currentUser.name ?? 'Dev')
      localStorage.setItem('currentUserEmail', currentUser.email ?? 'dev@avantstay.com')
      localStorage.setItem('currentUserPhoto', currentUser.photo ?? '')
    } else {
      localStorage.setItem('currentUserName', 'Dev')
      localStorage.setItem('currentUserEmail', 'dev@avantstay.com')
      localStorage.setItem('currentUserPhoto', '')
    }

    const prevProps = this.getPrevProps()

    this.setUser(viewAsUser)
    this.setState(
      {
        isMounted: true,
        isSignedIn: true,
        showAuthenticatingMessage: false,
      },
      () => startNavigation(prevProps),
    )

    return true
  }

  removeRunAs = () => {
    localStorage.removeItem('id_token')
    localStorage.removeItem('teammate')
    localStorage.removeItem('runAs')
    localStorage.removeItem('currentUserName')
    localStorage.removeItem('currentUserEmail')
    localStorage.removeItem('currentUserPhoto')
  }

  componentDidMount = async () => {
    if (await this.skipAuth()) return
    if (await this.runAs()) return
    this.removeRunAs()

    const currentPathName = window.location.pathname

    if (currentPathName !== ROUTES.login.path) {
      this.setState({ isAuthorizing: true })
    }

    const authMessageTime = setTimeout(this.toggleAuthenticationMessage, 2000)
    const auth = await googleAuth

    if (auth.isSignedIn.get()) {
      const tokenId = await setToken()

      if (!tokenId) {
        this.signOut()
      }

      const userInfo = extractUserFromGoogleAuth(auth)
      identifyCurrentUser(userInfo)

      await this.getUserPermission(userInfo)

      startNavigation(this.props)
      startTokenRenewalListener()
    } else {
      this.setState({ isAuthorizing: false })
    }

    this.setState({ isMounted: true, showAuthenticatingMessage: false }, () =>
      clearTimeout(authMessageTime),
    )

    // Side effect
    auth.isSignedIn.listen((isSignedIn: boolean) =>
      this.updateSignInStatus(extractUserFromGoogleAuth(auth), isSignedIn),
    )
  }

  render() {
    const { showAuthenticatingMessage, isMounted, user, isAuthorizing, isSignedIn } = this.state

    if (showAuthenticatingMessage) {
      return 'Authenticating...'
    }

    if (!isMounted) {
      return null
    }

    const contextValue = {
      user,
      isAuthorizing,
      isSignedIn,
      signIn: this.signIn,
      signOut: this.signOut,
    }

    const datadogUser = {
      id: user?.id,
      email: user?.email,
      name: user?.name,
    }

    if (IS_PRODUCTION) {
      datadogInitializer(datadogUser)
    }

    const { children } = this.props

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  }
}

export default withRouter(AuthProvider)
