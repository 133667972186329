import React, { Ref } from 'react'

function _IconHalfBathroom(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9 6.08173C13.9 5.19732 14.6397 4.52173 15.5 4.52173H17.5C18.3603 4.52173 19.1 5.19732 19.1 6.08173V11.8417C19.1 12.5308 18.651 13.0932 18.0457 13.3079C17.8621 14.1811 17.211 14.901 16.3336 15.1895C16.1766 15.2521 16.0955 15.3913 16.0997 15.5228C16.0998 15.5251 16.0998 15.5274 16.0999 15.5298C16.1 15.5339 16.1 15.538 16.1 15.5421V19.5229C16.1 19.8543 15.8314 20.1229 15.5 20.1229H9C8.81544 20.1229 8.64114 20.0379 8.52742 19.8926C8.4137 19.7472 8.37322 19.5576 8.41764 19.3784L8.8058 17.8134C7.16034 16.8389 6.08329 15.1632 5.92128 13.2958C5.33277 13.0725 4.9 12.5182 4.9 11.8417C4.9 10.9573 5.63967 10.2817 6.5 10.2817H13.9V6.08173ZM13.9 11.4817V12.2017H6.5C6.25577 12.2017 6.1 12.0177 6.1 11.8417C6.1 11.6658 6.25577 11.4817 6.5 11.4817H13.9ZM17.5 12.2017H15.1L15.1 10.8817L15.1 10.8807V6.08173C15.1 5.90575 15.2558 5.72173 15.5 5.72173H17.5C17.7442 5.72173 17.9 5.90575 17.9 6.08173V11.8417C17.9 12.0177 17.7442 12.2017 17.5 12.2017ZM9.76397 16.9681C8.30986 16.2557 7.34411 14.9117 7.14027 13.4029H16.7448C16.5849 13.6985 16.3076 13.9374 15.9496 14.0525C15.9409 14.0553 15.9323 14.0583 15.9238 14.0615C15.3051 14.2921 14.8832 14.881 14.9 15.5505V18.9229H9.76699L10.0824 17.6513C10.1504 17.377 10.0178 17.0924 9.76397 16.9681Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconHalfBathroom = React.forwardRef(_IconHalfBathroom)
