import React, { Ref } from 'react'

function _IconUser4(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 1.75C5.04822 1.75 2.25 4.54822 2.25 8C2.25 9.5754 2.83288 11.0147 3.79474 12.1139C4.62112 10.9939 5.82022 10.2073 7.16893 9.89207C5.86784 9.36548 4.95001 8.08995 4.95001 6.60005C4.95001 4.63944 6.5394 3.05005 8.50001 3.05005C10.4606 3.05005 12.05 4.63944 12.05 6.60005C12.05 8.08995 11.1322 9.36548 9.8311 9.89207C11.1798 10.2073 12.3789 10.9939 13.2053 12.1139C14.1671 11.0146 14.75 9.57539 14.75 8C14.75 4.54822 11.9518 1.75 8.5 1.75ZM12.0821 13.1222C11.2786 11.9537 9.94366 11.2388 8.50002 11.2388C7.05638 11.2388 5.72145 11.9537 4.91787 13.1223C5.93224 13.833 7.16742 14.25 8.5 14.25C9.83259 14.25 11.0678 13.8329 12.0821 13.1222ZM0.75 8C0.75 3.71979 4.21979 0.25 8.5 0.25C12.7802 0.25 16.25 3.71979 16.25 8C16.25 12.2802 12.7802 15.75 8.5 15.75C4.21979 15.75 0.75 12.2802 0.75 8ZM8.50001 4.55005C7.36783 4.55005 6.45001 5.46786 6.45001 6.60005C6.45001 7.73223 7.36783 8.65005 8.50001 8.65005C9.6322 8.65005 10.55 7.73223 10.55 6.60005C10.55 5.46786 9.6322 4.55005 8.50001 4.55005Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconUser4 = React.forwardRef(_IconUser4)
