import { addDays, addMonths, addYears, subDays, subMonths, subYears } from 'date-fns'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import slugify from 'slugify'

import { IDate } from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { formatDateRange } from '@avantstay/format-date-range'

import { formatDate, parseISODate } from './format'
import { pluralize } from './strings'

type addOrSubDateToNowParamsType = {
  range: number
  rangeType: rangeTypeValues
  shouldAdd: boolean
  fixedToday?: Date
}

export enum PeriodPresets {
  TODAY = 'today',
  NEXT_7_DAYS = 'next 7 days',
  PREV_7_DAYS = 'prev 7 days',
  NEXT_30_DAYS = 'next 30 days',
  PREV_30_DAYS = 'prev 30 days',
  NEXT_90_DAYS = 'next 90 days',
  PREV_90_DAYS = 'prev 90 days',
  NEXT_6_MONTHS = 'next 6 months',
  PREV_6_MONTHS = 'prev 6 months',
  PREV_12_MONTHS = 'prev 12 months',
  NEXT_12_MONTHS = 'next 12 months',
  NEXT_1_YEAR = 'next 1 year',
  PREV_1_YEAR = 'prev 1 year',
}

export type rangeTypeValues = 'days' | 'day' | 'month' | 'months' | 'year' | 'years'

export function getTimeUnitsToManage(rangeType: rangeTypeValues) {
  const shouldManageDays = ['days', 'day'].includes(rangeType)
  const shouldManageMonths = ['months', 'month'].includes(rangeType)
  const shouldManageYears = ['years', 'year'].includes(rangeType)

  return { shouldManageDays, shouldManageMonths, shouldManageYears }
}

export function addOrSubDateToNow({
  range,
  rangeType,
  shouldAdd,
  fixedToday,
}: addOrSubDateToNowParamsType) {
  const { shouldManageDays, shouldManageMonths, shouldManageYears } =
    getTimeUnitsToManage(rangeType)

  const today = fixedToday || new Date()

  if (shouldManageDays) {
    return formatDate(shouldAdd ? addDays(today, range) : subDays(today, range))
  }

  if (shouldManageMonths) {
    return formatDate(shouldAdd ? addMonths(today, range) : subMonths(today, range))
  }

  if (shouldManageYears) {
    return formatDate(shouldAdd ? addYears(today, range) : subYears(today, range))
  }

  return formatDate(today)
}

export function getDatesFromPeriod(period?: string | undefined, fixedToday?: Date) {
  const today = formatDate(fixedToday || new Date())

  if (!period || period.includes('today')) {
    return { fromDate: today, toDate: today }
  }

  const isPredefinedValue = period.includes('next') || period.includes('prev')

  if (isPredefinedValue) {
    const [timeDefinition, range, rangeType] = period.toLowerCase().split('-')
    const isFuture = timeDefinition === 'next'

    return {
      fromDate: addOrSubDateToNow({
        range: isFuture ? 1 : parseInt(range, 10),
        rangeType: isFuture ? ('day' as rangeTypeValues) : (rangeType as rangeTypeValues),
        shouldAdd: isFuture,
        fixedToday,
      }),
      toDate: addOrSubDateToNow({
        range: isFuture ? parseInt(range, 10) : 1,
        rangeType: isFuture ? (rangeType as rangeTypeValues) : ('day' as rangeTypeValues),
        shouldAdd: isFuture,
        fixedToday,
      }),
    }
  }

  const dates = period.split('_')

  const fromDate = dates[0] ? formatDate(dates[0]) : ''
  const toDate = dates[1] ? formatDate(dates[1]) : ''

  return { fromDate, toDate }
}

export function getTitleFromSlug(slug: string) {
  const slugInArray = slug.split('-')

  const { shouldManageDays, shouldManageMonths, shouldManageYears } = getTimeUnitsToManage(
    slugInArray[2] as rangeTypeValues,
  )

  if (shouldManageDays) {
    return `${slugInArray[1]}d`
  }

  if (shouldManageMonths) {
    return `${slugInArray[1]}m`
  }

  if (shouldManageYears) {
    return `${slugInArray[1]}y`
  }

  return 'Today'
}

export function getPeriodOption(period: string): {
  title: string
  label: string
  value: string
  id: string
} {
  const value = slugify(period)
  const title = getTitleFromSlug(value)

  return {
    id: value,
    label: title,
    title,
    value,
  }
}

export const getPeriodCustomOption = (
  period: string,
  customTitle?: string,
): {
  title: string
  label: string
  value: string
  id: string
} => {
  const value = slugify(period)
  const title = customTitle || getTitleFromSlug(value)

  return {
    id: value,
    label: title,
    title,
    value,
  }
}

export const getFilterSelectedTitle = (periodValue: string) => {
  const { fromDate: customFrom, toDate: customTo } = getDatesFromPeriod(periodValue)
  const selectedValueTitle = Object.values(PeriodPresets).filter(
    (it: string) => slugify(it) === periodValue,
  )

  return selectedValueTitle.length ? selectedValueTitle[0] : formatDateRange(customFrom, customTo)
}

interface FormatOptionalDateRangeFormattingOptions {
  yearFormat?: string
  monthFormat?: string
  dayFormat?: string
}

export const formatOptionalDateRange = (
  from: Date,
  to: Date,
  format?: FormatOptionalDateRangeFormattingOptions,
): string => {
  const yearFormat = format?.yearFormat || 'YY'
  const monthFormat = format?.monthFormat || 'MMM'
  const dayFormat = format?.dayFormat || 'DD'

  // because of date-fns v2 update
  const newYearFormat = format?.yearFormat?.toLowerCase() || 'yy'
  const newDayFormat = format?.dayFormat?.toLowerCase() || 'dd'

  if (from && to) {
    return formatDateRange(from, to, 'en-US', {
      formats: {
        defaultStart: `${monthFormat} ${dayFormat}, ${yearFormat} `,
        defaultEnd: ` ${monthFormat} ${dayFormat}, ${yearFormat}`,
        sameMonthStart: `${monthFormat} ${dayFormat}`,
        sameMonthEnd: `${dayFormat}, ${yearFormat}`,
        sameYearStart: `${monthFormat} ${dayFormat} `,
        sameYearEnd: ` ${monthFormat} ${dayFormat}, ${yearFormat}`,
      },
    })
  }

  if (!from && to)
    return `Until ${formatDate(to, `${monthFormat} ${newDayFormat}, ${newYearFormat}`)}`

  if (from && !to)
    return `After ${formatDate(from, `${monthFormat} ${newDayFormat}, ${newYearFormat}`)}`

  return '-'
}

export const numberOfNights = (start: IDate, end: IDate) => {
  const nights = differenceInCalendarDays(parseISODate(end), parseISODate(start))
  return `${nights} ${pluralize(nights, 'nights', 'night')}`
}
