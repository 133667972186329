export enum MaintenanceStatus {
  CLEAN = 'CLEAN',
  DIRTY = 'DIRTY',
  OCCUPIED = 'OCCUPIED',
  VACANT = 'VACANT',
  PENDING_INSPECTION = 'PENDING_INSPECTION',
}

export enum LiveStatus {
  LIVE = 'LIVE',
  LEASE_SIGNED = 'LEASE_SIGNED',
  BEING_ON_BOARDED = 'BEING_ON_BOARDED',
}

export enum OnBoardingStatus {
  PRE_ON_BOARDING = 'PRE_ON_BOARDING',
  ON_BOARDING = 'ON_BOARDING',
  LIVE = 'LIVE',
  OFF_BOARDED = 'OFF_BOARDED',
  STABILIZED = 'STABILIZED',
  NEW = 'NEW',
}

export enum OnBoardingStage {
  INSPECTION = 'INSPECTION',
  COMPLETED = 'COMPLETED',
  OFF_BOARDED = 'OFF_BOARDED',
}
