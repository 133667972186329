import styled from 'styled-components'

import { _M_Medium, mainColors, medias } from '../../styles'

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${_M_Medium};
`

const FRAME_BORDER_WIDTH = 2
const FRAME_HEIGHT = 36
const FRAME_VERTICAL_PADDING = 10

export const ButtonFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${mainColors.white};
  border: ${FRAME_BORDER_WIDTH}px solid ${mainColors.neutralMedium};
  border-radius: 2px;
  position: relative;
  max-width: max-content;
  height: ${FRAME_HEIGHT}px;
  padding: ${FRAME_VERTICAL_PADDING}px 0;

  > * {
    padding: 0 14px;
    height: ${FRAME_HEIGHT}px;

    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        top: ${FRAME_VERTICAL_PADDING}px;
        right: 0;
        height: calc(${FRAME_HEIGHT}px - 2 * ${FRAME_VERTICAL_PADDING}px);
        width: 1px;
        background-color: ${mainColors.neutralDark};
      }
    }
  }

  ${medias.XL_MEDIUM_SCREEN`
    background-color: transparent;
    border: none;
    gap: 5px;

      > * {
        &:not(:last-child) {
          &:after {
            display: none;
          }
        }
    }
  `}
`
