// @ts-nocheck
import { MouseEvent, ReactNode } from 'react'
import { MultiGridProps } from 'react-virtualized/dist/es/MultiGrid'
import { FlattenSimpleInterpolation } from 'styled-components'

import { SortingDirection } from '../../hooks'

export type RowWithSubRows<RowDataType> = DataTypeBase<RowDataType> & RowDataType

export interface DataTypeBase<RowDataType> {
  subRows?: RowDataType[]
}

export interface ColumnDataType<RowDataType> {
  columnKey: string
  isLastRow?: boolean
  hasSingleRow?: boolean
  onToggleSubRows?: VoidFunction
  rowData: RowWithSubRows<RowDataType>
  value: any
  activeRow?: boolean
}

export enum ColumnBorderOptions {
  right = 'right',
  left = 'left',
}

export interface Columns<RowDataType> {
  header: ReactNode
  headerFilter?: ReactNode
  key: string
  width?: number
  minWidth?: number
  borderStyle?: ColumnBorderOptions
  column?: ({
    columnKey,
    isLastRow,
    hasSingleRow,
    onToggleSubRows,
    rowData,
    value,
  }: ColumnDataType<RowDataType>) => ReactNode
}

export interface AutoSizeGridProps<RowDataType>
  extends Omit<
    MultiGridProps,
    'cellRenderer' | 'columnWidth' | 'columnCount' | 'rowCount' | 'rowHeight' | 'height' | 'width'
  > {
  columns: Columns<RowDataType>[]
  data?: RowWithSubRows<RowDataType>[]
  renderRowBelowHeader?: ReactNode
  maxWidth?: number
  minWidth?: number
  resizable?: boolean
  onRowClick?: (
    rowData: RowDataType,
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent> | undefined,
  ) => void
  styleByRow?: (rowData: RowDataType) => FlattenSimpleInterpolation | undefined
  heightByRow?: (rowData: RowDataType) => number
  isLoadingContent?: boolean
  emptyDataComponent?: ReactNode
  customRowHeight?: number
  disabledHeaderCellFullWidth?: boolean
  hasStickyDataRowBelowHeader?: boolean
  customActiveRowHeight?: number
}

export interface GetColumnTextProps {
  columnValue: string | number | boolean
}

export interface GridRowProps {
  background: string
  isFirstColumn: boolean
  isLastColumn: boolean
  hasRowBelowHeader: boolean
  fixedColumn?: boolean
  resizable?: boolean
  withSeparator?: boolean
  styleByRow?: FlattenSimpleInterpolation
  borderStyle?: ColumnBorderOptions
}

export type ColumnSorting = { columnKey: string; direction: SortingDirection } | undefined

export enum CellClassNameOptions {
  default = 'item',
  hover = 'hoveredItem',
}
