import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconDrag = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg width="6" height="10" fill="none" viewBox="0 0 6 10" {...props} ref={ref}>
      <circle cx="1" cy="1" r="1" fill="currentColor" />
      <circle cx="1" cy="5" r="1" fill="currentColor" />
      <circle cx="1" cy="9" r="1" fill="currentColor" />
      <circle cx="5" cy="1" r="1" fill="currentColor" />
      <circle cx="5" cy="5" r="1" fill="currentColor" />
      <circle cx="5" cy="9" r="1" fill="currentColor" />
    </svg>
  )
}

export const IconDrag = forwardRef(_IconDrag)
