import React from 'react'

function _IconAccounting(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 5.5C12.5523 5.5 13 5.94772 13 6.5V7.50002H14.5C15.0523 7.50002 15.5 7.94773 15.5 8.50002C15.5 9.0523 15.0523 9.50002 14.5 9.50002H10.75C10.5511 9.50002 10.3603 9.57903 10.2197 9.71969C10.079 9.86034 10 10.0511 10 10.25C10 10.4489 10.079 10.6397 10.2197 10.7803C10.3603 10.921 10.5511 11 10.75 11H13.25C13.9793 11 14.6788 11.2897 15.1945 11.8055C15.7103 12.3212 16 13.0207 16 13.75C16 14.4794 15.7103 15.1788 15.1945 15.6946C14.6788 16.2103 13.9793 16.5 13.25 16.5H13V17.5C13 18.0523 12.5523 18.5 12 18.5C11.4477 18.5 11 18.0523 11 17.5V16.5H9C8.44771 16.5 8 16.0523 8 15.5C8 14.9477 8.44771 14.5 9 14.5H13.25C13.4489 14.5 13.6397 14.421 13.7803 14.2803C13.921 14.1397 14 13.9489 14 13.75C14 13.5511 13.921 13.3603 13.7803 13.2197C13.6397 13.079 13.4489 13 13.25 13H10.75C10.0207 13 9.32118 12.7103 8.80546 12.1946C8.28973 11.6788 8 10.9794 8 10.25C8 9.52067 8.28973 8.8212 8.80546 8.30547C9.32118 7.78975 10.0207 7.50002 10.75 7.50002H11V6.5C11 5.94772 11.4477 5.5 12 5.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconAccounting = React.memo(React.forwardRef(_IconAccounting))
