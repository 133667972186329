import styled from 'styled-components'

import { _S_Medium, mainColors } from '@avantstay/backoffice-core'

interface TrackProps {
  active?: boolean
  left?: string
  right?: string
  width?: string
}

const SLIDER_SIZE = 16
const SLIDER_TRACK_HEIGHT = 4

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SliderContainer = styled.div`
  position: relative;
  height: 16px;
`

export const Track = styled.div<TrackProps>`
  position: absolute;
  height: ${SLIDER_TRACK_HEIGHT}px;
  border-radius: 50px;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${({ active }) =>
    active ? mainColors.accentMedium : mainColors.neutralMedium};
  left: ${({ left }) => left || '0'};
  right: ${({ right }) => right || '0'};
  width: ${({ width }) => width || 'auto'};
`

export const ThumbWrapper = styled.div<{ position: string }>`
  position: absolute;
  display: grid;
  place-items: center;
  width: ${SLIDER_SIZE}px;
  height: ${SLIDER_SIZE}px;
  top: 0;
  left: ${({ position }) => `calc(${position} - 8px)` || '8px'};
`

export const Thumb = styled.div`
  background-color: ${mainColors.accentMedium};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: ${SLIDER_SIZE}px;
  height: ${SLIDER_SIZE}px;
  display: grid;
  place-items: center;
`

export const StyledRangeInput = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  position: absolute;
  margin: auto;
  top: 0;
  height: ${SLIDER_SIZE}px;
  background-color: transparent;
  pointer-events: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: ${SLIDER_SIZE}px;
    width: ${SLIDER_SIZE}px;
    background-color: transparent;
    cursor: pointer;
    pointer-events: auto;
  }

  &::-moz-range-thumb {
    border: none;
    outline: none;
    -webkit-appearance: none;
    height: ${SLIDER_SIZE}px;
    width: ${SLIDER_SIZE}px;
    cursor: pointer;
    background-color: transparent;
    pointer-events: auto;
  }
`

export const EdgesWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${_S_Medium};
  color: ${mainColors.neutralExtremelyDark};
`
