import React from 'react'

import * as S from './ToggleAmountTypeInput.styles'
import { ToggleAmountTypeProps, ToggleInputTypes } from './ToggleAmountTypeInput.types'

const nextTypeBasedOnCurrentType: { [key: number]: ToggleInputTypes } = {
  [ToggleInputTypes.money]: ToggleInputTypes.percentage,
  [ToggleInputTypes.percentage]: ToggleInputTypes.money,
}

function ToggleAmountType({ type, small, onChange }: ToggleAmountTypeProps): JSX.Element {
  const handleNextType = () => {
    onChange(nextTypeBasedOnCurrentType[type])
  }

  return (
    <S.ToggleContainer small={small}>
      <S.ToggleItem active={type === ToggleInputTypes.money} small={small} onClick={handleNextType}>
        $
      </S.ToggleItem>

      <S.ToggleItem
        active={type === ToggleInputTypes.percentage}
        small={small}
        onClick={handleNextType}
        data-testid="percentage"
      >
        %
      </S.ToggleItem>
    </S.ToggleContainer>
  )
}

export { ToggleAmountType }
