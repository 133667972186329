import React, { Ref } from 'react'

function _IconPendingClipboard(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.21228 6.19165H8.01025V6.1919C8.01025 7.07332 8.72479 7.78785 9.6062 7.78785H14.3941C15.2755 7.78785 15.99 7.07332 15.99 6.1919V6.19165H16.788C17.2287 6.19165 17.586 6.54892 17.586 6.98963V18.1613C17.586 18.602 17.2287 18.9593 16.788 18.9593H7.21228C6.77157 18.9593 6.41431 18.602 6.41431 18.1613V6.98963C6.41431 6.54892 6.77157 6.19165 7.21228 6.19165ZM16.788 4.5957H15.99C15.9899 3.7144 15.2754 3 14.3941 3H9.6062C8.72487 3 8.01039 3.7144 8.01025 4.5957H7.21228C5.89016 4.5957 4.81836 5.6675 4.81836 6.98963V18.1613C4.81836 19.4834 5.89016 20.5552 7.21228 20.5552H16.788C18.1101 20.5552 19.1819 19.4834 19.1819 18.1613V6.98963C19.1819 5.6675 18.1101 4.5957 16.788 4.5957ZM9.60621 5.39368L9.6062 5.39647V6.1919H14.3941V4.59595H9.6062V5.39089L9.60621 5.39368ZM11.3604 13.7285C11.2497 13.9987 11.1943 14.3617 11.1943 14.8174H12.4443L12.4639 14.5195C12.4997 14.2005 12.6413 13.9222 12.8887 13.6846L13.2842 13.3086C13.5934 13.0091 13.8099 12.7373 13.9336 12.4932C14.0573 12.2458 14.1191 11.9837 14.1191 11.707C14.1191 11.0983 13.9287 10.6279 13.5479 10.2959C13.167 9.96061 12.6315 9.79297 11.9414 9.79297C11.2578 9.79297 10.7174 9.96875 10.3203 10.3203C9.92643 10.6719 9.72624 11.1585 9.71973 11.7803H11.1357C11.1423 11.5199 11.2171 11.3164 11.3604 11.1699C11.5068 11.0202 11.7005 10.9453 11.9414 10.9453C12.4492 10.9453 12.7031 11.2204 12.7031 11.7705C12.7031 11.9528 12.6543 12.127 12.5566 12.293C12.459 12.4557 12.262 12.6689 11.9658 12.9326C11.6729 13.193 11.471 13.4583 11.3604 13.7285ZM11.2725 15.7793C11.126 15.9193 11.0527 16.0983 11.0527 16.3164C11.0527 16.5312 11.1243 16.7087 11.2676 16.8486C11.4141 16.9886 11.6061 17.0586 11.8438 17.0586C12.0814 17.0586 12.2718 16.9886 12.415 16.8486C12.5615 16.7087 12.6348 16.5312 12.6348 16.3164C12.6348 16.0983 12.5599 15.9193 12.4102 15.7793C12.2637 15.6361 12.0749 15.5645 11.8438 15.5645C11.6126 15.5645 11.4222 15.6361 11.2725 15.7793Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconPendingClipboard = React.forwardRef(_IconPendingClipboard)
