import React, { Ref } from 'react'

function _IconMoveDown(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.999902 13.6006C0.668532 13.6006 0.399902 13.332 0.399902 13.0006C0.399902 12.6692 0.668532 12.4006 0.999902 12.4006L14.9999 12.4006C15.3313 12.4006 15.5999 12.6692 15.5999 13.0006C15.5999 13.332 15.3313 13.6006 14.9999 13.6006L0.999902 13.6006ZM4.24214 7.24263C4.00783 7.47694 4.00783 7.85684 4.24214 8.09116L7.57498 11.424L7.57548 11.4245C7.60476 11.4538 7.63633 11.4794 7.6696 11.5014C7.76433 11.564 7.87786 11.6004 7.9999 11.6004C8.17159 11.6004 8.32644 11.5283 8.4358 11.4127L11.7573 8.09116C11.9917 7.85684 11.9917 7.47694 11.7573 7.24263C11.523 7.00832 11.1431 7.00832 10.9088 7.24263L8.5999 9.55154V3.00039C8.5999 2.66902 8.33127 2.40039 7.9999 2.40039C7.66853 2.40039 7.3999 2.66902 7.3999 3.00039V9.55186L5.09067 7.24263C4.85636 7.00832 4.47646 7.00832 4.24214 7.24263Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconMoveDown = React.forwardRef(_IconMoveDown)
