import * as React from 'react'

const _IconCheckSquare = (props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) => (
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" {...props} ref={ref}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.022 1.522a.493.493 0 0 1 .348-.144h5.704a.544.544 0 0 0 0-1.09H2.37A1.582 1.582 0 0 0 .79 1.87v7.26A1.581 1.581 0 0 0 2.37 10.71h7.26A1.581 1.581 0 0 0 11.21 9.13V5.5a.544.544 0 0 0-1.089 0v3.63a.493.493 0 0 1-.492.492H2.37a.493.493 0 0 1-.492-.492V1.87c0-.13.052-.256.144-.348Zm9.81-.306a.544.544 0 1 0-.775-.766L6.06 5.503 4.832 4.262a.544.544 0 0 0-.775.765l1.616 1.634a.544.544 0 0 0 .774 0l5.385-5.445Z"
      fill="currentColor"
    />
  </svg>
)

export const IconCheckSquare = React.forwardRef(_IconCheckSquare)
