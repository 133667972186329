import { forwardRef, Ref, SVGProps } from 'react'

const _IconOperations = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1765 10.2617C17.534 8.89946 17.8853 6.82956 17.0531 5.09586L14.7469 7.40168C14.1543 7.9941 13.1937 7.9941 12.6011 7.40168C12.0086 6.80925 12.0086 5.84874 12.6011 5.25632L14.9067 2.94983C13.1732 2.11347 11.1002 2.46478 9.73919 3.82557C8.37815 5.18636 8.02678 7.25894 8.86329 8.99215L2.94464 14.9097C2.35192 15.5022 2.35177 16.4628 2.9443 17.0554C3.53683 17.6481 4.49766 17.6482 5.09037 17.0558L11.0104 11.1375C12.7441 11.9692 14.8138 11.6183 16.1765 10.2617Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IconOperations = forwardRef(_IconOperations)
