import { datadogRum } from '@datadog/browser-rum'

interface InitializerProps {
  id?: string
  name?: string
  email?: string
}

export function datadogInitializer({ id, name, email }: InitializerProps): void {
  const datadogConfig = {
    applicationId: 'da7adb4e-b5e4-4521-b990-7f8fc669d32f',
    clientToken: 'pub010a222f3cf13fa323412b09e8e39b06',
    site: 'datadoghq.com',
  }

  datadogRum.init({
    ...datadogConfig,
    service: 'voyage',
    env: 'production',
    version: '1.0.0',
    sampleRate: 100,
    silentMultipleInit: true,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingOrigins: ['https://arriere.prod.avantstay.com'],
  })

  if (id) {
    datadogRum.setUser({
      id,
      name,
      email,
    })
  }

  datadogRum.startSessionReplayRecording()
}
