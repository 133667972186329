import styled, { css } from 'styled-components'

import { ImgLite } from '@avantstay/avantstay-ui'
import { mainColors, zIndex } from '../../../../styles'
import { rgba } from '../../../../utils'
import { Button } from '../../../Button'

const IMAGE_WIDTH = '80vw'
const IMAGE_HEIGHT = '90vh'

export const CarouselContainer = styled.div`
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: ${zIndex.MODAL};
  background-color: ${rgba(mainColors.primaryDark, 0.8)};
`

export const CarouselBackground = styled.div`
  position: relative;
  width: ${IMAGE_WIDTH};
  height: ${IMAGE_HEIGHT};
  background-color: ${mainColors.neutralMedium};
  border-radius: 2px;
`

export const ImagePreview = styled(ImgLite)`
  width: ${IMAGE_WIDTH};
  height: ${IMAGE_HEIGHT};
  background-size: contain;
  border-radius: 2px;
`

const carouselButton = css`
  padding: 12px;
  border: 2px solid ${mainColors.white};
  border-radius: 50%;
  background-color: ${mainColors.primaryDark};
  z-index: ${zIndex.PORTAL};
`

export const CloseCarouselButton = styled(Button.Flat)`
  position: absolute;
  top: 20px;
  right: 20px;
  ${carouselButton};

  svg {
    width: 15px;
    height: 15px;
    color: ${mainColors.white};
  }
`

export const CarouselArrowButton = styled(Button.Flat)<{ $isLeftAligned?: boolean }>`
  position: absolute;
  top: 50%;
  ${({ $isLeftAligned }) => ($isLeftAligned ? 'left: 20px;' : 'right: 20px;')};
  ${carouselButton};

  svg {
    width: 15px;
    height: 15px;
    color: ${mainColors.white};
  }

  &:disabled {
    display: none;
    background-color: ${mainColors.neutralDark};
    border-color: ${mainColors.neutralDark};

    svg {
      color: ${mainColors.neutralExtraDark};
    }
  }
`
