import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { setRoutingStore } from 'mobx-react-router-utils'

import customHistory from '@utils/history'

class _RouterStore extends RouterStore {
  constructor() {
    super()
    setRoutingStore(this)
    syncHistoryWithStore(customHistory, this)
  }

  setup() {
    // this is just to ensure we call constructor of this store before other stores
  }
}

export const RoutingStore = new _RouterStore()
