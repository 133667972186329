import styled from 'styled-components'

import { _S_Normal, mainColors, semanticColors, zIndex } from '../../styles'
import { InputBeforeAfter, TextFieldInputContainer, TextFieldIssueContainer } from '../TextField'

const MIN_INPUT_SIZE = 15
const MAX_INPUT_SIZE = 20
const INPUT_HEIGHT = 24

export const Container = styled.div<{
  $hasError?: boolean
  $isFocused?: boolean
}>`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  border: 2px solid ${mainColors.neutralMedium};
  border-radius: 2px;
  padding: 4px 8px 4px 4px;
  background-color: ${mainColors.white};

  &:hover {
    border-color: ${mainColors.neutralDark};
  }

  ${({ $isFocused }) =>
    $isFocused &&
    `
    border-color: ${mainColors.accentMedium};

    &:hover {
      border-color: ${mainColors.accentDark};
    }
  `}

  ${({ $hasError }) =>
    $hasError &&
    `
    border-color: ${semanticColors.failureMedium};

    &:hover {
      border-color: ${semanticColors.failureMedium};
    }
  `}
`

export const Label = styled.div`
  color: ${mainColors.neutralExtremelyDark};
  ${_S_Normal};
  align-content: center;
  max-height: ${INPUT_HEIGHT}px;
`

export const TextContainer = styled.div<{ $isDisabled?: boolean; $hasError?: boolean }>`
  display: flex;
  gap: 8px;

  ${({ $hasError }) =>
    $hasError &&
    `
    gap: 15px;
  `}

  ${TextFieldInputContainer} {
    border: none;
    min-height: ${INPUT_HEIGHT}px;
    height: ${INPUT_HEIGHT}px;
    padding: 4px 0;
  }

  ${InputBeforeAfter} {
    justify-content: end;
    min-width: fit-content;
    width: fit-content;
    margin-right: 0;
    color: ${mainColors.neutralExtremelyDark};
  }

  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    ${_S_Normal};
    text-align: right;
    min-width: ${MIN_INPUT_SIZE}px;
    max-width: ${MAX_INPUT_SIZE}px;

    &::placeholder {
      color: ${mainColors.neutralExtraDark} !important;
    }
  }

  &:last-child {
    padding-right: 8px;
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
    cursor: not-allowed;
    background-color: ${mainColors.neutralLight};

    ${TextFieldInputContainer} {
      background-color: ${mainColors.neutralLight};
    }
  `};

  ${TextFieldIssueContainer} {
    position: absolute;
    right: -${MIN_INPUT_SIZE}px;
    z-index: ${zIndex.DEFAULT};
  }
`
