import type {
  CreateHomeServicesArgs,
  StockedFridgePackageOption,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'

import type { UpdateHomeServiceProps } from '@new/domains/properties/screens/propertyValueAddedServices/_types'

export default {
  async getHomeServicesFridgeStockOptions(
    homeId: string,
    serviceId: string,
  ): Promise<Omit<StockedFridgePackageOption, 'items' | 'images'>[]> {
    const options = await arriereBackoffice.queries.stockedFridgeServiceOptions({
      __args: {
        homeId,
        serviceId,
      },
      serviceEnabled: true,
      packages: {
        id: true,
        name: true,
        price: true,
        tax: true,
        vendor: {
          id: true,
          name: true,
          listOrder: true,
        },
        description: true,
        enabled: true,
        listOrder: true,
      },
    })

    return options.packages
  },

  async getServices() {
    return arriereBackoffice.queries.services({
      __alias: 'getServices',
      __args: { active: true },
      id: true,
      kind: true,
      name: true,
      defaultPrice: true,
    })
  },

  async createHomeServices({ homeIds, input }: CreateHomeServicesArgs) {
    return arriereBackoffice.mutations.createHomeServices({
      __alias: 'createHomeServices',
      __args: { homeIds, input },
    })
  },
}

export const getHomeServicesGrouped = async (homeId?: string) => {
  return homeId
    ? arriereBackoffice.queries.homeServicesGrouped({
        __args: {
          homeId,
        },
        title: true,
        homeServices: {
          homeId: true,
          serviceId: true,
          price: true,
          enabled: true,
          localSettings: {
            nightlyRatio: true,
            cleaningRatio: true,
          },
          service: {
            defaultPrice: true,
            id: true,
            name: true,
            globalSettings: {
              nightlyRatio: true,
              cleaningRatio: true,
            },
            kind: true,
            availableFrom: true,
            description: true,
          },
        },
      })
    : Promise.reject(new Error('homeId is missing'))
}

// ------- Mutations

export const updateHomeServices = ({
  serviceId,
  homeId,
  enabled,
  price,
  settings,
}: UpdateHomeServiceProps) =>
  arriereBackoffice.mutations.updateHomeServices({
    __args: {
      homeIds: [homeId],
      input: {
        serviceId,
        enabled,
        price,
        settings,
      },
    },
  })
