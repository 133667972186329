import styled from 'styled-components'

import { mainColors, riskChannelsColors, semanticColors } from '../../styles/colors'
import { medias } from '../../styles/medias'
import { _M_Medium } from '../../styles/typography'
import { AvatarProps } from './Avatar.types'

export const defaultBackgroundColor = `linear-gradient(${riskChannelsColors.channelMobile}, ${riskChannelsColors.channelWebsite}) border-box`

export const AvatarRoot = styled.div<AvatarProps>`
  border-radius: 50%;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 2px;
  width: ${props => props.widthValue ?? '34px'};
  height: ${props => props.heightValue ?? '34px'};
  background: ${props => props.bgColor ?? defaultBackgroundColor};
  position: relative;

  ${props =>
    props.dashedBorder &&
    `
      border: 2px dashed ${mainColors.neutralDark};
    `}

  ${props =>
    !props.noBorder &&
    !props.viewAsBorder &&
    `
   border: 2px solid rgba(55, 136, 247, 0.3);
   box-shadow: 0 0 0 2px rgba(55, 136, 247, 0.3);
  `}

  background-color: ${props => props.bgColor ?? defaultBackgroundColor};

  ${({ viewAsBorder }) => viewAsBorder && `border: 2px solid ${semanticColors.warningDark}; `}

  ${props =>
    props.src &&
    `
    background-image: url("${props.src}");
    background-size: cover;
  `}

  &:hover {
    cursor: pointer;
  }

  ${medias.MD_SCREEN`
    &:hover {
      cursor: inherit;
    }
  `}
`

export const Initials = styled.span<AvatarProps>`
  color: ${({ initialsColor }) => initialsColor ?? mainColors.white};
  font-size: ${props => (!props.fontSizeValue ? props.fontSizeValue : `14px`)};
  ${_M_Medium};
  text-transform: uppercase;
`

export const Badge = styled.div<{ top?: boolean }>`
  position: absolute;
  ${({ top }) =>
    top
      ? `
      top: -4px;
      right: -12px;
    `
      : `
    top: -2px;
    right: -8px;
  `}
`
