import { useLayoutEffect, useState } from 'react'

interface UseCreatePortalRoot {
  rootId: string
}

function useCreatePortalRoot({ rootId }: UseCreatePortalRoot) {
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(document.getElementById(rootId))

  useLayoutEffect(() => {
    if (Boolean(portalRoot)) {
      return
    }

    const root = document.createElement('div')
    root.id = rootId

    document.body.appendChild(root)
    setPortalRoot(root)
  }, [portalRoot, rootId])

  return portalRoot
}

export { useCreatePortalRoot }
