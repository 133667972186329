import React, { Ref } from 'react'

function _IconLearn(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        d="M11.53 1.617a1 1 0 01.94 0l11 5.845a1 1 0 01-.046 1.788L20 10.855V17a1 1 0 01-.606.92l-7 3a1 1 0 01-.788 0l-7-3A1 1 0 014 17v-6.145l-2-.937V13a1 1 0 11-2 0V8.5l.003-.078a1.003 1.003 0 01.528-.96l11-5.845zm.894 12.788L18 11.793v4.548l-6 2.571-6-2.571v-4.549l5.576 2.614a1 1 0 00.848 0zM3.237 8.29L12 12.396l8.763-4.107L12 3.632 3.237 8.29z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconLearn = React.forwardRef(_IconLearn)
