import styled, { css } from 'styled-components'

import { mainColors } from '../../styles'
import { Button } from '../Button'
import { ZoomControllerTheme } from './ZoomControllers'

export const ButtonFlat = styled(Button.Flat)``

const primaryHorizontalThemeConfig = css`
  background-color: ${mainColors.primaryMedium};
  border-color: ${mainColors.white};
  flex-direction: row;

  svg {
    color: ${mainColors.white};
  }
`

const neutralVerticalThemeConfig = css`
  background-color: ${mainColors.white};
  border-color: ${mainColors.neutralMedium};
  flex-direction: column-reverse;

  svg {
    color: ${mainColors.neutralExtraDark};
  }
`

export const ButtonGroup = styled.div<{ theme: ZoomControllerTheme }>`
  display: flex;
  padding: 4px 0;
  border: 2px solid;
  border-radius: 4px;

  & > * {
    color: unset;

    &:not(:last-child) {
      border-right: 1px solid ${mainColors.neutralMedium};
    }
  }

  ${ButtonFlat} {
    padding: 0 20px;
    border-radius: 0;
    height: 36px;
  }

  ${({ theme }) => theme === ZoomControllerTheme.primaryHorizontal && primaryHorizontalThemeConfig};
  ${({ theme }) => theme === ZoomControllerTheme.neutralVertical && neutralVerticalThemeConfig};
`
