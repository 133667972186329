import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'

import type { OmniSearchParams } from '@arriere/types/OmniSearch.types'

export const getOmniSearchHomes = ({ term }: OmniSearchParams) => {
  return arriereBackoffice.queries.omniSearchHomes({
    __alias: 'getOmniSearchHomes',
    __args: { term },
    total: true,
    results: {
      id: true,
      name: true,
      region: {
        name: true,
      },
      thumbnail: {
        url: true,
      },
    },
  })
}

export const getOmniSearchBookings = ({ endDate, startDate, term }: OmniSearchParams) => {
  return arriereBackoffice.queries.bookingOmniSearch({
    __alias: 'getOmniSearchBookings',
    __args: { term, from: startDate, to: endDate },
    total: true,
    pages: true,
    results: {
      hash: true,
      guestName: true,
      checkInTiming: {
        effectiveTime: true,
      },
      checkOutTiming: {
        effectiveTime: true,
      },
      folio: {
        summary: {
          total: true,
          balance: true,
          isPaid: true,
          paid: {
            total: true,
            credits: true,
          },
        },
      },
      home: {
        id: true,
        name: true,
        region: {
          name: true,
        },
      },
    },
  })
}

export const getOmniSearchGuests = ({ term }: OmniSearchParams) => {
  return arriereBackoffice.queries.guestsOmniSearch({
    __alias: 'getOmniSearchGuests',
    __args: { terms: term, pagination: { size: 20, page: 1 } },
    total: true,
    results: {
      id: true,
      userId: true,
      fullName: true,
      email: true,
      ongoing: {
        hash: true,
        checkInTiming: {
          effectiveTime: true,
        },
        checkOutTiming: {
          effectiveTime: true,
        },
        home: {
          name: true,
        },
      },
      next: {
        hash: true,
        checkInTiming: {
          effectiveTime: true,
        },
        checkOutTiming: {
          effectiveTime: true,
        },
        home: {
          name: true,
        },
      },
    },
  })
}
