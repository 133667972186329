import { CustomFieldKind } from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { EMAIL_PATTERN, moneyMask, numberMask } from '@avantstay/backoffice-core'

export const timeRegex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9]) [A-Za-z]{2}?$/
export const durationRegex = /^[0-9]+ (days|day|hours|minutes|seconds|hour|minute|second)?$/
export const timeRangeRegex =
  /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(am|pm|AM|PM)[,]([0-1]?[0-9]|2[0-4]):([0-5][0-9])(am|pm|AM|PM)?$/

export const validationByFieldType = {
  [CustomFieldKind.text]: {
    presence: {
      allowEmpty: true,
    },
  },
  [CustomFieldKind.number]: {
    presence: { allowEmpty: true },
  },
  [CustomFieldKind.phone]: {
    presence: { allowEmpty: true },
  },
  [CustomFieldKind.money]: {
    presence: { allowEmpty: true },
  },
  [CustomFieldKind.date]: {
    presence: { allowEmpty: true },
    datetime: {
      message: '^format is invalid, it should be MM/DD/YYYY',
    },
  },
  [CustomFieldKind.email]: {
    format: {
      pattern: EMAIL_PATTERN,
      message: '^format is invalid, please provide a valid email',
    },
  },
  [CustomFieldKind.time]: {
    presence: { allowEmpty: true },
    format: {
      pattern: timeRegex,
      message: '^format is invalid, it should be HH:mm and the suffix am or pm',
    },
  },
  [CustomFieldKind.duration]: {
    presence: { allowEmpty: true },
    format: {
      pattern: durationRegex,
      message: '^format is invalid, you should use days, hours, minutes as suffix',
    },
  },
  [CustomFieldKind.dateTime]: {
    presence: { allowEmpty: true },
    datetime: {
      message: '^format is invalid, it should be MM/DD/YYYY HH:mm',
    },
  },
  [CustomFieldKind.timeRange]: {
    presence: { allowEmpty: true },
    format: {
      pattern: timeRangeRegex,
      message: '^format is invalid, it should be HH:mm(am,pm)',
    },
  },
  default: {
    presence: { allowEmpty: true },
  },
}

export const fieldsValidationRequired = [CustomFieldKind.phone]

export const FIRST_COLUMN_WIDTH = 320
export const SECOND_COLUMN_WIDTH = 180
export const THIRD_COLUMN_WIDTH = 223
export const VALIDATION_LEVEL_COLUMN_WIDTH = 400

export const maskByFieldType = {
  [CustomFieldKind.date]: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  [CustomFieldKind.dateTime]: [
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ':',
    /\d/,
    /\d/,
  ],
  [CustomFieldKind.time]: [/\d/, /\d/, ':', /\d/, /\d/, ' ', /[A-Za-z]/, /[A-Za-z]/],
  [CustomFieldKind.money]: moneyMask,
  [CustomFieldKind.number]: numberMask,
  [CustomFieldKind.timeRange]: [/\d/, /\d/, ':', /\d/, /\d/, /[A-Za-z]/, /[A-Za-z]/],
}
