import React from 'react'

function _IconAlertError(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props} ref={ref}>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" fillOpacity=".01" d="M0 0h16v16H0z" />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M8.9 8l1.46 1.46a.636.636 0 1 1-.9.9L8 8.9l-1.46 1.46a.636.636 0 1 1-.9-.9L7.1 8 5.64 6.54a.636.636 0 1 1 .9-.9L8 7.1l1.46-1.46a.636.636 0 1 1 .9.9L8.9 8zM8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0-1.273A5.727 5.727 0 1 0 8 2.273a5.727 5.727 0 0 0 0 11.454z"
        />
      </g>
    </svg>
  )
}

const IconAlertError = React.memo(React.forwardRef(_IconAlertError))

export { IconAlertError }
