import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconStar = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99937 0.666992C8.25311 0.666992 8.48486 0.81104 8.59717 1.03858L10.5022 4.89797L14.7625 5.52067C15.0135 5.55736 15.2219 5.73337 15.3002 5.9747C15.3785 6.21604 15.3129 6.48088 15.1312 6.6579L12.049 9.6599L12.7764 13.901C12.8193 14.1511 12.7165 14.4039 12.5112 14.553C12.3059 14.7022 12.0337 14.7218 11.8091 14.6037L7.99937 12.6002L4.18967 14.6037C3.96506 14.7218 3.69286 14.7022 3.48755 14.553C3.28223 14.4039 3.1794 14.1511 3.2223 13.901L3.94969 9.6599L0.867547 6.6579C0.685801 6.48088 0.620286 6.21604 0.698538 5.9747C0.776789 5.73337 0.985243 5.55736 1.23628 5.52067L5.49653 4.89797L7.40156 1.03858C7.51388 0.81104 7.74562 0.666992 7.99937 0.666992ZM7.99937 2.83983L6.53717 5.80208C6.44015 5.99864 6.25269 6.13495 6.03579 6.16665L2.76498 6.64473L5.13119 8.94942C5.28844 9.10258 5.36021 9.32334 5.32311 9.53969L4.76482 12.7948L7.68907 11.2569C7.88332 11.1548 8.11541 11.1548 8.30967 11.2569L11.2339 12.7948L10.6756 9.53969C10.6385 9.32334 10.7103 9.10258 10.8675 8.94942L13.2338 6.64473L9.96295 6.16665C9.74605 6.13495 9.55859 5.99864 9.46156 5.80208L7.99937 2.83983Z"
      fill="currentColor"
    />
  </svg>
)

export const IconStar = forwardRef(_IconStar)
