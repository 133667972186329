import React, { Ref } from 'react'

function _CellCrossedLines(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" {...props} ref={ref}>
      <g opacity="0.3">
        <path d="M63 -14L-2 51" stroke="currentColor" strokeWidth="6" />
        <path d="M79 -14L14 51" stroke="currentColor" strokeWidth="6" />
        <path d="M47 -14L-18 51" stroke="currentColor" strokeWidth="6" />
        <path d="M31 -14L-34 51" stroke="currentColor" strokeWidth="6" />
        <path d="M15 -14L-50 51" stroke="currentColor" strokeWidth="6" />
        <path d="M95 -14L30 51" stroke="currentColor" strokeWidth="6" />
        <path d="M111 -14L46 51" stroke="currentColor" strokeWidth="6" />
      </g>
    </svg>
  )
}

export const CellCrossedLines = React.forwardRef(_CellCrossedLines)
