import styled, { css } from 'styled-components'

import { mainColors, shadowColors } from '../../styles/colors'
import { medias } from '../../styles/medias'
import { _M_Normal, _XS_Bold } from '../../styles/typography'
import { zIndex } from '../../styles/zIndex'
import { rgba } from '../../utils/cssUtils'
import { ITableHeaderItemProps, ITableItemProps, tableTheme } from './_types'

/**
 * @deprecated import DataGrid from "@avantstay/backoffice-core" instead
 */
export const TableStyled = styled.table<{
  marginBottom?: number
  tableLayout?: string
  width?: string
}>`
  width: ${({ width }) => width || 'max-content'};
  min-width: 100%;
  color: ${mainColors.black};
  border-collapse: separate;
  border-spacing: 0;
  background: ${mainColors.white};
  position: relative;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};
  ${({ tableLayout }) => !!tableLayout && `table-layout: ${tableLayout}`};
`

/**
 * @deprecated import DataGrid from "@avantstay/backoffice-core" instead
 */
export const TableHeaderStyled = styled.thead<{ fixed?: boolean; top?: number }>`
  ${({ fixed, top }) =>
    fixed &&
    `
  position: sticky;
  top: ${top || 0}px;
  z-index: ${zIndex.TABLE_PORTAL};
`};
`

/**
 * @deprecated import DataGrid from "@avantstay/backoffice-core" instead
 */
export const TableHeaderItemStyled = styled.th<ITableHeaderItemProps>`
  ${_M_Normal};
  padding: ${({ padding }) => padding || '20px 0'};
  color: ${mainColors.neutralExtremelyDark};
  border-top: 1px solid ${mainColors.neutralMedium};
  border-bottom: 1px solid ${mainColors.neutralMedium};
  text-align: ${({ textAlign }) => textAlign};
  width: ${({ colWidth }) => colWidth};
  min-width: ${({ minColWidth }) => minColWidth};
  max-width: ${({ maxColWidth }) => maxColWidth};
  background: ${mainColors.white};
  font-weight: ${({ bold }) => bold && 'bold'};
  border-right: ${({ borderRight }) => borderRight};
  border-left: ${({ borderLeft }) => borderLeft};
  justify-content: ${({ justifyContent }) => justifyContent};

  ${({ fixed }) =>
    fixed &&
    `
  position: sticky;
  left: 0;
  z-index: ${zIndex.TABLE_PORTAL};
`};
`

/**
 * @deprecated import DataGrid from "@avantstay/backoffice-core" instead
 */
export const TableRowStyled = styled.tr<{ backgroundColor?: string; clickable?: boolean }>`
  ${({ backgroundColor }) =>
    backgroundColor
      ? `background: ${backgroundColor};`
      : `
      background: ${mainColors.white};

      &:nth-child(2n + 1) {
        background: ${mainColors.neutralExtraLight};
      }
  `}

  > *:nth-child(n) {
    padding-left: 18px;
    padding-right: 18px;
  }

  & > *:first-child {
    padding-left: 16px;
  }

  & > *:last-child {
    padding-right: 16px;
  }

  &:hover {
    & .table-row-actions-container {
      border-color: ${mainColors.neutralMedium};
      box-shadow: 0px 4px 16px ${rgba(shadowColors.blur, 0.4)};
    }

    & .table-row-actions {
      opacity: 1;
    }

    ${p => p.clickable && `background: ${mainColors.neutralLight}; cursor: pointer;`}
  }

  ${medias.MD_SCREEN`
    > *:nth-child(n) {
      padding-left: 5px;
      padding-right: 5px;
    }

    > *:nth-child(2) {
      padding-left: 40px;
    }

    & > *:first-child {
      padding-left: 40px;
    }


    & > *:last-child {
      padding-right: 40px;
    }
  `}
`

/**
 * @deprecated import DataGrid from "@avantstay/backoffice-core" instead
 */
export const TableBodyStyled = styled.tbody<{ theme?: tableTheme }>`
  background-color: ${mainColors.white};

  ${props =>
    props.theme === tableTheme.basic &&
    `
      & > *:nth-child(n) {
        background: ${mainColors.neutralExtraLight};
      }

      tr td {
        border-bottom: 1px solid ${mainColors.neutralMedium};
      }
  `}
`

/**
 * @deprecated import DataGrid from "@avantstay/backoffice-core" instead
 */
export const TableFooterStyled = styled.tfoot`
  ${TableRowStyled} {
    background: ${mainColors.white};
  }
`

/**
 * @deprecated import DataGrid from "@avantstay/backoffice-core" instead
 */
export const TableColumnStyled = styled.td<ITableItemProps>`
  ${_M_Normal};
  color: ${mainColors.primaryDark};
  padding: 16px 0;
  width: ${props => props.colWidth ?? 'max-content'};
  max-width: ${props => props.maxColWidth};
  min-width: ${props => props.minColWidth};
  text-align: ${props => props.textAlign};
  text-transform: ${props => props.textTransform};
`

const border = css`1px solid ${mainColors.neutralLight}`

/**
 * @deprecated import DataGrid from "@avantstay/backoffice-core" instead
 */
export const TableFixedRow = styled.div`
  ${_XS_Bold};
  line-height: 18px;
  color: ${mainColors.neutralExtremelyDark};
  background-color: ${mainColors.neutralLight};
  border-top: ${border};
  border-bottom: ${border};
  padding: 6px 30px;
  display: flex;
  align-items: center;
  width: 100%;
`

const gradientEffectContent = css`
  content: '';
  position: absolute;
  height: 100%;
  width: 35px;
  z-index: ${zIndex.DEFAULT};
  top: 0;
`

const leftGradientEffect = css`
  ${gradientEffectContent};
  background: linear-gradient(
    90deg,
    ${rgba(mainColors.white, 0.9)},
    ${rgba(mainColors.white, 0.3)}
  );
  left: 0;
`

const rightGradientEffect = css`
  ${gradientEffectContent};
  background: linear-gradient(
    270deg,
    ${rgba(mainColors.white, 0.9)},
    ${rgba(mainColors.white, 0.3)}
  );
  right: 0;
`

/**
 * @deprecated import DataGrid from "@avantstay/backoffice-core" instead
 */
export const tableGradientEffect = css`
  &:before {
    ${leftGradientEffect};
  }

  &:after {
    ${rightGradientEffect};
  }
`
