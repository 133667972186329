import styled from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _L_Medium, _M_Normal } from '../../styles/typography'

export const Root = styled.div<{ hasRestriction?: boolean }>`
  display: flex;
  flex-direction: column;
  ${p => p.hasRestriction && `margin-left: 16px;`}
`

export const SecurityCodeHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const SecurityCodeHeaderTitle = styled.span`
  ${_L_Medium};
  color: ${mainColors.primaryMedium};
`

export const SecurityCodeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 42px);
  gap: 30px;
  margin: 20px 0;
`

export const SecurityCodeMessage = styled.p`
  ${_M_Normal};
  color: ${mainColors.neutralExtremelyDark};
`
