import { JustifyContentType } from './SortableTableHeaderItem/_types'

export interface ITableHeaderItemProps {
  textAlign?: 'left' | 'center' | 'right'
  textTransform?: 'capitalize' | 'lowercase' | 'none' | 'uppercase'
  colWidth?: string
  minColWidth?: string
  maxColWidth?: string
  padding?: string
  bold?: boolean
  borderRight?: string
  borderLeft?: string
  justifyContent?: JustifyContentType
  fixed?: boolean
  isPair?: boolean
}

export interface ITableItemProps extends ITableHeaderItemProps {
  isExpandable?: boolean
}

export enum tableTheme {
  default,
  basic,
}
