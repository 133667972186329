import styled, { css } from 'styled-components'

import {
  _S_Medium,
  _XS_Bold,
  _XS_Normal,
  mainColors,
  semanticColors,
} from '@avantstay/backoffice-core'

import { ToggleBooleanType, ToggleFieldLabelProps, ToggleFieldProps } from './ToggleField.types'

interface SquaredContainerProps {
  checked: boolean
}

const Container = styled.div<{ disabled?: boolean }>`
  position: relative;
  min-height: 24px;
  width: 100%;
  z-index: 0;

  ${({ disabled }) =>
    disabled &&
    `
    > * {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.6;
    }

  `}

  > input:checked {
    z-index: 1;
  }
`

const ToggleDefault = styled.label`
  display: flex;
  align-items: center;
  padding: 2px 12px;
  background: ${mainColors.neutralLight};
  border: 2px solid transparent;
  border-radius: 20px;
  cursor: pointer;

  span {
    color: ${mainColors.neutralExtremelyDark};
  }
`

const HiddenInput = styled.input<ToggleFieldLabelProps>`
  opacity: 0;
  cursor: pointer;
  position: absolute;

  &:hover + ${ToggleDefault} {
    span {
      color: ${props =>
        props.toggleType === ToggleBooleanType.false
          ? semanticColors.failureMedium
          : mainColors.accentMedium};
    }
  }

  &:checked + ${ToggleDefault} {
    border-color: ${props =>
      props.toggleType === ToggleBooleanType.false
        ? semanticColors.failureMedium
        : mainColors.accentMedium};

    span {
      color: ${props =>
        props.toggleType === ToggleBooleanType.false
          ? semanticColors.failureMedium
          : mainColors.accentMedium};
    }
  }
`

const Title = styled.span`
  ${_S_Medium};
  margin: 0 auto;
`

export const SquaredContainer = styled.div<SquaredContainerProps>`
  position: relative;
  width: 100%;

  z-index: ${({ checked }) => (checked ? '1' : '0')};

  &:hover {
    z-index: 2;
  }
`

const ToggleFieldContainer = styled.div<ToggleFieldProps>`
  background: ${mainColors.neutralLight};
  border-radius: 22px;
  padding: 3px;
  height: 30px;
  width: ${props => (props.autofill ? '100%' : 'max-content')};

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 8px;
  grid-template-areas: '. .';
  align-items: center;
`

export const ToggleSquared = styled.label`
  display: flex;
  align-items: center;
  padding: 2px 12px;
  background: ${mainColors.white};

  height: 48px;
  border: 2px solid ${mainColors.neutralMedium};
  cursor: pointer;

  span {
    color: ${mainColors.neutralExtremelyDark};
  }
`

const squaredHoverStyles = (toggleType?: ToggleBooleanType) => css`
  background: ${toggleType === ToggleBooleanType.false
    ? mainColors.neutralMedium
    : mainColors.accentExtraLight};

  border: 2px solid
    ${toggleType === ToggleBooleanType.false
      ? mainColors.neutralExtraDark
      : mainColors.accentMedium};

  span {
    ${_XS_Bold};
    color: ${toggleType === ToggleBooleanType.false
      ? mainColors.neutralExtraDark
      : mainColors.accentMedium};
  }
`

export const SquaredTitle = styled.span`
  ${_XS_Normal};
  margin: 0 auto;
`
export const SquaredHiddenInput = styled.input<ToggleFieldLabelProps>`
  opacity: 0;
  cursor: pointer;
  position: absolute;

  &:hover + ${ToggleSquared} {
    ${({ toggleType }) => squaredHoverStyles(toggleType)}
  }

  &:checked + ${ToggleSquared} {
    ${({ toggleType }) => squaredHoverStyles(toggleType)}
  }
`

export { Container, ToggleDefault, HiddenInput, Title, ToggleFieldContainer }
