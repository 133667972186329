import styled from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _S_Normal } from '../../styles/typography'
import { zIndex } from '../../styles/zIndex'
import { SwitchButtonLabelProps } from './SwitchButton.types'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const InputContainer = styled.div<SwitchButtonLabelProps>`
  position: relative;
  width: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`

export const SwitchLabel = styled.label<SwitchButtonLabelProps>`
  position: absolute;
  left: 0;
  width: 32px;
  height: 18px;
  background: ${mainColors.neutralDark};
  border-radius: 22px;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin: 2px;
    background: ${mainColors.white};
    transition: all 0.2s ease;
  }

  &:disabled {
    background: ${mainColors.neutralDark};
    cursor: not-allowed;
  }
`

export const HiddenInput = styled.input<SwitchButtonLabelProps>`
  opacity: 0;
  z-index: ${zIndex.DEFAULT};
  cursor: pointer;

  &:checked + ${SwitchLabel} {
    background: ${mainColors.accentMedium};

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      margin-left: 16px;
      transition: all 0.2s ease;
    }
  }

  &:disabled + ${SwitchLabel} {
    opacity: 0.3;
    cursor: not-allowed;

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
    }
  }
`

interface TitleProps {
  titleColor?: string
}

export const Title = styled.label<TitleProps>`
  ${_S_Normal};
  line-height: 19px;
  color: ${({ titleColor }) => titleColor};
`
