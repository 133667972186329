import styled, { css } from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _M_Bold, _M_Normal, _S_Bold, _S_Medium } from '../../styles/typography'
import { rgba } from '../../utils/cssUtils'
import { ButtonDefault } from '../Button'
import {
  ControlWrapper,
  DropdownIndicator,
  Menu,
  SingleValue,
  ValueContainer,
} from '../SelectField/SelectField.styles'

export const iconsStyles = {
  color: mainColors.accentMedium,
  borderRadius: '4px',
  width: '30px',
  height: '30px',
  padding: '5px 5px',
}

export const PaginateContainer = styled.div<{ justifyCenter: boolean; isMobile: boolean }>`
  display: flex;
  width: inherit;
  justify-content: ${({ justifyCenter }) => (justifyCenter ? 'center' : 'space-between')};
  background-color: ${mainColors.white};
  padding: 12px 0 !important;

  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-direction: column;
      gap: 12px;
    `}
`

export const PaginationContainer = styled.div<{
  isMobile: boolean
  hideAdditionalPages?: boolean
  hidePages?: boolean
}>`
  margin-right: 30px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
    `}

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 0 !important;
    list-style: none;
    background-color: ${mainColors.white};

    li {
      height: 30px;
      min-width: 30px;
    }

    li a {
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      margin: 0;
      border-radius: 4px;
      ${_M_Normal};
      color: ${mainColors.neutralExtremelyDark};
      height: 100%;
    }

    .active {
      ${p => (p.hidePages ? 'display: none;' : '')}

      a {
        background: ${p => (p.hideAdditionalPages ? 'transparent' : mainColors.accentMedium)};
        color: ${p => (p.hideAdditionalPages ? mainColors.neutralExtremelyDark : mainColors.white)};
      }
    }

    .previous {
      margin-right: ${p => (p.hidePages || p.hideAdditionalPages ? '5px' : '24px')};
    }

    .previous a,
    .next a {
      display: flex;
      background: ${mainColors.accentExtremelyLight};

      &:hover {
        background: ${mainColors.accentExtraLight};
      }
    }
  }

  .next {
    margin-left: ${p => (p.hidePages || p.hideAdditionalPages ? '5px' : '24px')};
  }

  .disabled {
    opacity: 0.3;
    filter: grayscale(1);

    & a {
      cursor: default !important;
    }
  }

  .active .paginationLink {
    background: transparent;
    color: ${mainColors.accentMedium};
    ${_M_Bold};
  }

  .paginationLink {
    padding: 4px 10px;
    color: ${mainColors.neutralExtraDark};

    &:hover {
      background: ${mainColors.accentExtremelyLight};
      color: ${mainColors.accentMedium};
    }
  }

  .paginationEllipsis {
    ${p => (p.hideAdditionalPages ? 'display: none;' : '')}

    a {
      color: ${mainColors.neutralExtraDark};
    }
  }
`

export const EmptyDiv = styled.div`
  width: 200px;
`

export const SelectPageItemsContainer = styled.div<{ isMenuOpen: boolean; isMobile: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ isMobile }) => (isMobile ? '0px' : '30px')};
  column-gap: 12px;
  max-height: 30px;

  color: ${mainColors.neutralExtremelyDark};
  ${_M_Normal};

  .select-pagination__control {
    max-height: 30px !important;

    background-color: ${({ isMenuOpen }) =>
      isMenuOpen ? mainColors.accentExtraLight : mainColors.accentExtremelyLight};
  }

  .select-pagination__value-container--has-value {
    padding-left: 10px !important;
    height: 30px !important;
    min-height: 30px !important;
  }

  .select-pagination__indicators {
    margin-right: 4px;
  }

  .select-pagination__single-value {
    ${_S_Bold};
    margin-left: 0;
    color: ${mainColors.accentMedium};
    width: auto;

    span {
      ${_S_Medium};
      color: ${rgba(mainColors.accentMedium, 0.7)};
    }
  }

  .select-field-container {
    width: 60px;
    height: 30px;
  }

  .select-pagination__dropdown-indicator {
    svg {
      color: ${mainColors.accentMedium};
    }
  }

  .select-pagination__menu {
    min-width: 39px;
    max-width: 65px;
    right: 0;
  }

  .select-pagination__value-container {
    width: 40px;
  }
`

export const SelectFieldWrapper = styled.div`
  display: flex;
  width: auto;
  height: 30px;
  padding: 5px 8px 5px 10px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background-color: ${mainColors.accentExtremelyLight};

  span {
    color: ${mainColors.accentMedium};
    ${_S_Medium}
  }

  ${ControlWrapper} {
    background: ${mainColors.accentExtremelyLight};
    max-height: 30px;
    min-height: auto;
  }

  ${ValueContainer} {
    padding: 0;
    height: auto;
  }

  ${ButtonDefault} {
    padding: 0;
  }

  ${SingleValue} {
    color: ${mainColors.accentMedium};
    ${_S_Bold};
  }

  ${DropdownIndicator} {
    margin-left: 5px;
    color: ${mainColors.accentMedium};

    svg {
      color: ${mainColors.accentMedium};
    }
  }

  ${Menu} {
    bottom: 20px;
  }
`

export const ResultsWrapper = styled.div`
  display: flex;
  gap: 7px;
`
