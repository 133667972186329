import React, { Ref } from 'react'

function _IconApproveFilled(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.999 31.7323C25.136 31.7323 31.7323 25.136 31.7323 16.999C31.7323 8.86196 25.136 2.26562 16.999 2.26562C8.86196 2.26562 2.26562 8.86196 2.26562 16.999C2.26562 25.136 8.86196 31.7323 16.999 31.7323Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0272 11.8491C25.3697 12.2144 25.3512 12.7881 24.9859 13.1306L15.9993 21.5556C15.5976 21.9321 14.9697 21.922 14.5804 21.5327L10.6913 17.6436C10.3373 17.2895 10.3373 16.7155 10.6913 16.3614C11.0454 16.0073 11.6195 16.0073 11.9736 16.3614L15.3195 19.7073L23.7457 11.8077C24.111 11.4652 24.6848 11.4838 25.0272 11.8491Z"
        fill="white"
      />
    </svg>
  )
}

export const IconApproveFilled = React.memo(React.forwardRef(_IconApproveFilled))
