import styled from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _2XS_Normal, _3XS_Normal, _XS_Bold, _XS_Medium } from '../../styles/typography'
import { zIndex } from '../../styles/zIndex'
import { rgba } from '../../utils/cssUtils'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Description = styled.div`
  ${_3XS_Normal};
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: ${mainColors.neutralExtraDark};
`

export const DashedLine = styled.div`
  width: 20px;
  border-top: 1px dashed ${mainColors.neutralExtraDark};
  margin-right: 6px;
`

export const tooltipWrapperStyles = { zIndex: zIndex.TOOLTIP }

export const LineChartTooltipContainer = styled.div`
  background-color: ${mainColors.white};
  border: 1px solid ${mainColors.neutralLight};
  border-radius: 2px;
  padding: 8px;
`
export const TooltipInfo = styled.div`
  padding-bottom: 3px;
`
export const TooltipName = styled.div`
  ${_2XS_Normal};
  color: ${mainColors.neutralExtraDark};
`
export const TooltipValue = styled.div`
  ${_XS_Bold};
  color: ${mainColors.black};
`

export const YAxisLine = styled.span<{ bottom?: boolean }>`
  position: relative;
  display: block;
  width: calc(100% - 9px);
  border-top: 2px solid ${mainColors.neutralMedium};
  top: ${props => (props.bottom ? '-10px' : 0)};
  margin-top: ${props => (props.bottom ? '20px' : 0)};
  margin-left: 5px;

  span {
    ${_2XS_Normal};
    color: ${rgba(mainColors.neutralExtraDark, 0.6)};
    position: absolute;
    top: -17px;
  }
`

export const XAxis = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  margin-top: -3px;
`

export const XAxisItem = styled.div`
  ${_XS_Medium};
  color: ${mainColors.neutralDark};
  position: relative;

  &::before {
    content: '';
    width: 2px;
    height: 5px;
    background-color: ${mainColors.neutralMedium};
    position: absolute;
    top: -7px;
  }

  &:nth-child(2) {
    &::before {
      left: calc(50% - 2px);
    }
  }

  &:last-of-type {
    &::before {
      left: unset;
      right: -1px;
    }
  }
`
