import styled from 'styled-components'

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs'

import { mainColors } from '../../styles/colors'
import { _M_Medium } from '../../styles/typography'
import { Disabled } from '../../styles/utils'

const HEADER_HEIGHT = 44
const FOOTER_HEIGHT = 64

export const TabListStyled = styled(TabList)`
  display: flex;
  height: ${HEADER_HEIGHT}px;
  background-color: ${mainColors.white} !important;
  border-bottom: 1px solid ${mainColors.neutralMedium};
`

export const CustomSelectOption = styled.span`
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -1px;
  right: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`

export const TabPanelsStyled = styled(TabPanels)`
  height: calc(100% - ${HEADER_HEIGHT}px);
`

export const TabsStyled = styled(Tabs)`
  height: calc(100% - ${FOOTER_HEIGHT}px);
  padding-bottom: 40px;
`

export const TabStyled = styled(Tab)<{ disabled?: boolean }>`
  &[data-reach-tab] {
    all: unset;
    flex-grow: 1;
    text-align: center;
    max-width: fit-content;
    ${_M_Medium};
    color: ${mainColors.neutralExtraDark};
    outline: none;
    background: transparent;
    padding: 0.25em;

    ${props => props.disabled && Disabled};

    &:not(:last-child) {
      margin-left: 30px;
    }

    &:last-child {
      margin-left: 30px;
      margin-right: 30px;
    }

    &:active {
      background: transparent;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &[data-selected] {
    color: ${mainColors.accentMedium};
    position: relative;

    ${CustomSelectOption} {
      background: ${mainColors.accentMedium};
    }
  }
`

export const TabPanelStyled = styled(TabPanel)`
  height: 100%;
  outline: none;
`
