import arriereBackoffice, {
  OpCaseCreationOptions,
  PaginationInput,
  ServiceRequestStatusFilter,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { Awaited } from '@avantstay/backoffice-core'

import type { BookingDatesFilter } from '@new/domains/bookings/screens/bookingsList/BookingsList.types'

import type {
  AdjustRequestedServiceParams,
  MakePurchaseParams,
  RequestFridgeStockingServiceParams,
  RequestServicesInput,
} from '@/services/arriere/types/BookingServices.types'

export type BookingServiceRequest = Awaited<
  ReturnType<typeof bookingServices.getBookingServicesRequests>
>[number]

const bookingServices = {
  confirmServiceRequest(requestId: string) {
    return arriereBackoffice.mutations
      .booking({
        confirmServiceRequest: {
          __args: { requestId },
        },
      })
      .then(booking => booking.confirmServiceRequest)
  },

  rejectServiceRequest(requestId: string) {
    return arriereBackoffice.mutations
      .booking({
        rejectServiceRequest: {
          __args: { requestId },
        },
      })
      .then(booking => booking.rejectServiceRequest)
  },

  cancelServiceRequest(requestId: string) {
    return arriereBackoffice.mutations
      .booking({
        cancelServiceRequest: { __args: { requestId } },
      })
      .then(booking => booking.cancelServiceRequest)
  },

  async getServices() {
    return arriereBackoffice.queries.services({
      __alias: 'getServices',
      __args: {},
      id: true,
      name: true,
    })
  },

  async getBookingsWithServicesRequestsCount(
    regionId?: string,
    filter?: string,
    from?: string | Date,
    to?: string | Date,
    serviceIds?: string[],
    status?: ServiceRequestStatusFilter,
    pagination?: PaginationInput,
    dates?: BookingDatesFilter,
  ) {
    return arriereBackoffice.queries
      .bookingsWithTimeChangeRequests({
        __alias: 'getBookingsWithServicesRequestsCount',
        __args: {
          regionId,
          from,
          to,
          filter,
          serviceIds,
          status,
          pagination,
          dates,
        },
        bookings: {
          total: true,
        },
      })
      .then(response => response.bookings.total)
  },

  async getBookingsWithServicesRequests(
    regionId?: string,
    filter?: string,
    from?: string | Date,
    to?: string | Date,
    serviceIds?: string[],
    status?: ServiceRequestStatusFilter,
    pagination?: PaginationInput,
    dates?: BookingDatesFilter,
  ) {
    return arriereBackoffice.queries.bookingsWithTimeChangeRequests({
      __alias: 'getBookingsWithServicesRequests',
      __args: {
        regionId,
        from,
        to,
        filter,
        serviceIds,
        status,
        pagination,
        dates,
      },
      bookings: {
        total: true,
        pages: true,
        paginationParams: {
          page: true,
          size: true,
        },
        results: {
          id: true,
          hash: true,
          userId: true,
          confirmationId: true,
          guestName: true,
          emails: true,
          guestId: true,
          guests: true,
          homeId: true,
          period: {
            start: true,
            end: true,
          },
          detailedCheckIn: {
            effectiveTime: true,
            custom: {
              requestId: true,
              time: true,
              status: true,
              cost: {
                price: true,
                paid: true,
              },
            },
          },
          detailedCheckOut: {
            effectiveTime: true,
            custom: {
              requestId: true,
              time: true,
              status: true,
              cost: {
                price: true,
                paid: true,
              },
            },
          },
          reservationId: true,
          home: {
            id: true,
            name: true,
            title: true,
            region: {
              id: true,
              name: true,
            },
          },
          services: {
            requests: {
              __args: { status, serviceIds },
              id: true,
              serviceId: true,
              name: true,
              creator: {
                name: true,
                internal: true,
              },
              comment: true,
              createdAt: true,
              data: {
                dateTime: true,
                dates: true,
                quantity: true,
                name: true,
                packages: {
                  projection: {
                    fridgePackage: {
                      id: true,
                      name: true,
                      price: true,
                      vendorId: true,
                      listOrder: true,
                      packageGroup: true,
                      description: true,
                    },
                    items: { packageId: true, name: true, quantity: true },
                    images: {
                      listOrder: true,
                      url: true,
                      description: true,
                    },
                    taxes: true,
                  },
                  price: true,
                  quantity: true,
                },
              },
              status: true,
              expiresAt: true,
              paymentInfo: {
                requestId: true,
                paymentId: true,
                status: true,
                amount: true,
                refunded: true,
                lastPaymentUpdate: true,
              },
              contactInfo: {
                guestName: true,
                phone: true,
                email: true,
              },
              price: true,
            },
          },
        },
      },
      summary: {
        kind: {
          all: true,
          eci: true,
          lco: true,
          checkTime: true,
          poolHeating: true,
          midStayCleaning: true,
          itemBased: true,
          basic: true,
          arbitrary: true,
          stockedFridge: true,
        },
        status: {
          all: true,
          pending: true,
          approved: true,
          rejected: true,
        },
      },
    })
  },

  async getBookingServicesRequests(
    bookingId: string,
    status?: ServiceRequestStatusFilter,
    serviceIds?: string[],
  ) {
    return arriereBackoffice.queries
      .booking({
        __alias: 'getBookingServicesRequests',
        __args: {
          bookingId,
        },
        services: {
          requests: {
            __args: { status, serviceIds },
            id: true,
            serviceId: true,
            name: true,
            comment: true,
            isCancellable: true,
            creator: {
              name: true,
              internal: true,
            },
            createdAt: true,
            data: {
              dateTime: true,
              dates: true,
              date: true,
              quantity: true,
              name: true,
              vendorName: true,
              description: true,
              packages: {
                projection: {
                  fridgePackage: {
                    id: true,
                    name: true,
                    price: true,
                    vendorId: true,
                    listOrder: true,
                    packageGroup: true,
                    description: true,
                  },
                  vendor: {
                    id: true,
                    name: true,
                    listOrder: true,
                    hoursInAdvance: true,
                    description: true,
                    image: {
                      listOrder: true,
                      url: true,
                      description: true,
                    },
                  },
                  items: { packageId: true, name: true, quantity: true },
                  images: {
                    listOrder: true,
                    url: true,
                    description: true,
                  },
                  taxes: true,
                },
                packageOrder: {
                  serviceRequestId: true,
                  packageId: true,
                  status: true,
                  orderedBy: true,
                  createdAt: true,
                  updatedAt: true,
                },
                price: true,
                quantity: true,
              },
            },
            status: true,
            expiresAt: true,
            paymentInfo: {
              requestId: true,
              paymentId: true,
              status: true,
              amount: true,
              refunded: true,
              lastPaymentUpdate: true,
              securityDeposit: true,
              refundedSecurityDeposit: true,
            },
            contactInfo: {
              guestName: true,
              phone: true,
              email: true,
            },
            price: true,
            balance: true,
            adjustment: true,
            deposit: true,
            approvedBy: {
              approvedAt: true,
              internal: true,
              name: true,
            },
          },
        },
      })
      .then(booking => booking?.services?.requests)
  },
  async getBookingServicesRequestsSubtotal(bookingId: string) {
    return arriereBackoffice.queries
      .booking({
        __alias: 'getBookingServicesRequestsSubtotal',
        __args: {
          bookingId,
        },
        folio: {
          summary: {
            servicesSubtotal: true,
          },
        },
      })
      .then(booking => booking?.folio?.summary.servicesSubtotal)
  },

  async getAvailableServices(bookingId: string) {
    return arriereBackoffice.queries
      .booking({
        __alias: 'getAvailableServices',
        __args: {
          bookingId,
        },
        services: {
          availableServices: {
            id: true,
            kind: true,
            name: true,
            priority: true,
            requiresDeposit: true,
            serviceGroup: true,
          },
        },
      })
      .then(booking => booking.services.availableServices)
  },

  async getBasicServicePrice(bookingId: string, serviceId: string) {
    return arriereBackoffice.queries.booking
      .raw({
        __alias: 'getBasicServicePrice',
        __args: {
          bookingId,
        },
        services: {
          basicServicePrice: {
            __args: {
              serviceId,
            },
          },
        },
      })
      .then(booking => ({
        data: booking.data.services.basicServicePrice,
        warnings: booking.warnings,
      }))
  },

  async getCheckTimeOptions(bookingId: string, serviceId: string) {
    return arriereBackoffice.queries.booking
      .raw({
        __alias: 'getCheckTimeOptions',
        __args: {
          bookingId,
        },
        services: {
          checkTimeOptions: {
            __args: {
              serviceId,
            },
            cost: true,
            costCents: true,
            time: true,
          },
        },
      })
      .then(booking => ({
        data: booking.data.services.checkTimeOptions,
        warnings: booking.warnings,
      }))
  },

  async getMidStayCleaningOptions(bookingId: string, serviceId: string) {
    return arriereBackoffice.queries.booking
      .raw({
        __alias: 'getMidStayCleaningOptions',
        __args: {
          bookingId,
        },
        services: {
          midStayCleaningOptions: {
            __args: {
              serviceId,
            },
            cost: true,
            date: true,
            taxes: true,
          },
        },
      })
      .then(booking => ({
        data: booking.data.services.midStayCleaningOptions,
        warnings: booking.warnings,
      }))
  },

  async getPoolHeatingOption(bookingId: string, serviceId: string) {
    return arriereBackoffice.queries.booking
      .raw({
        __alias: 'getPoolHeatingOption',
        __args: {
          bookingId,
        },
        services: {
          poolHeatingOption: {
            __args: {
              serviceId,
            },
            cost: true,
            period: {
              start: true,
              end: true,
            },
            taxes: true,
          },
        },
      })
      .then(booking => ({
        data: booking.data.services.poolHeatingOption,
        warnings: booking.warnings,
      }))
  },

  async getDateBasedServiceOption(bookingId: string, serviceId: string) {
    return arriereBackoffice.queries.booking
      .raw({
        __alias: 'getDateBasedServiceOption',
        __args: {
          bookingId,
        },
        services: {
          datesBasedServiceOption: {
            __args: {
              serviceId,
            },
            cost: true,
            period: {
              start: true,
              end: true,
            },
            taxes: true,
          },
        },
      })
      .then(booking => ({
        data: booking.data.services.datesBasedServiceOption,
        warnings: booking.warnings,
      }))
  },

  async getItemBasedOptions(bookingId: string, serviceId: string) {
    return arriereBackoffice.queries.booking
      .raw({
        __alias: 'getItemBasedOptions',
        __args: {
          bookingId,
        },
        services: {
          itemBasedOptions: {
            __args: {
              serviceId,
            },
            min: true,
            max: true,
            baseCost: true,
            taxes: true,
            deposit: true,
          },
        },
      })
      .then(booking => ({
        data: booking.data.services.itemBasedOptions,
        warnings: booking.warnings,
      }))
  },

  async getServicePriceBreakdown(
    bookingId: string,
    serviceId: string,
    netPrice: number,
    securityDeposit?: number,
  ) {
    return arriereBackoffice.queries
      .booking({
        __alias: 'getServicePriceBreakdown',
        __args: {
          bookingId,
        },
        services: {
          servicePriceBreakdown: {
            __args: { netPrice, serviceId, securityDeposit },
            net: true,
            gross: true,
            deposit: true,
            feesAndTaxes: {
              name: true,
              value: true,
            },
          },
        },
      })
      .then(booking => booking.services.servicePriceBreakdown)
  },

  async getFridgeStockingPackages(bookingId: string, serviceId: string) {
    return arriereBackoffice.queries.booking
      .raw({
        __alias: 'getFridgeStockingPackages',
        __args: {
          bookingId,
        },
        services: {
          stockedFridgeServiceOptions: {
            __args: { serviceId },
            packages: {
              id: true,
              name: true,
              price: true,
              tax: true,
              vendor: {
                id: true,
                name: true,
              },
              listOrder: true,
              description: true,
            },
          },
        },
      })
      .then(booking => ({
        data: booking.data.services.stockedFridgeServiceOptions.packages,
        warnings: booking.warnings,
      }))
  },

  requestCheckTimeService({
    bookingId,
    serviceId,
    time,
    price,
    cardId,
    sendComms,
    payLater,
  }: {
    bookingId: string
    serviceId: string
    time: string
    price: number
    cardId?: string
    sendComms?: boolean
    payLater?: boolean
  }) {
    return arriereBackoffice.mutations
      .booking({
        requestCheckTime: {
          __args: {
            bookingId,
            serviceId,
            time,
            price,
            cardId,
            sendComms,
            payLater,
          },
        },
      })
      .then(result => result.requestCheckTime)
  },

  requestMidStayCleanService({
    bookingId,
    serviceId,
    date,
    price,
    cardId,
    sendComms,
    payLater,
  }: {
    bookingId: string
    serviceId: string
    date: string
    price: number
    cardId?: string
    sendComms?: boolean
    payLater?: boolean
  }) {
    return arriereBackoffice.mutations
      .booking({
        requestMidStayClean: {
          __args: {
            bookingId,
            serviceId,
            date,
            price,
            cardId,
            sendComms,
            payLater,
          },
        },
      })
      .then(result => result.requestMidStayClean)
  },

  requestPoolHeating({
    bookingId,
    serviceId,
    price,
    cardId,
    sendComms,
    payLater,
  }: {
    bookingId: string
    serviceId: string
    price: number
    cardId?: string
    sendComms?: boolean
    payLater?: boolean
  }) {
    return arriereBackoffice.mutations
      .booking({
        requestPoolHeatingRemainingStay: {
          __args: {
            bookingId,
            serviceId,
            price,
            cardId,
            sendComms,
            payLater,
          },
        },
      })
      .then(result => result.requestPoolHeatingRemainingStay)
  },

  requestItemBasedService({
    bookingId,
    serviceId,
    number,
    price,
    securityDeposit,
    cardId,
    sendComms,
    payLater,
  }: {
    bookingId: string
    serviceId: string
    number: number
    price: number
    securityDeposit?: number
    cardId?: string
    sendComms?: boolean
    payLater?: boolean
  }) {
    return arriereBackoffice.mutations
      .booking({
        requestItemBasedService: {
          __args: {
            bookingId,
            serviceId,
            number,
            price,
            cardId,
            sendComms,
            securityDeposit,
            payLater,
          },
        },
      })
      .then(result => result.requestItemBasedService)
  },

  requestBasicService({
    bookingId,
    serviceId,
    price,
    cardId,
    sendComms,
    vendorName,
    payLater,
  }: {
    bookingId: string
    serviceId: string
    price: number
    cardId?: string
    sendComms?: boolean
    vendorName?: string
    payLater?: boolean
  }) {
    return arriereBackoffice.mutations
      .booking({
        requestBasicService: {
          __args: {
            bookingId,
            serviceId,
            price,
            cardId,
            sendComms,
            vendorName,
            payLater,
          },
        },
      })
      .then(result => result.requestBasicService)
  },

  requestArbitraryService({
    bookingId,
    serviceId,
    name,
    price,
    cardId,
    sendComms,
    description,
    payLater,
  }: {
    bookingId: string
    serviceId: string
    name: string
    price: number
    cardId?: string
    sendComms?: boolean
    description?: string
    payLater?: boolean
  }) {
    return arriereBackoffice.mutations
      .booking({
        requestArbitraryService: {
          __args: {
            bookingId,
            serviceId,
            name,
            price,
            cardId,
            sendComms,
            description,
            payLater,
          },
        },
      })
      .then(result => result.requestArbitraryService)
  },

  requestFridgeStocking({
    bookingId,
    serviceId,
    securityDeposit,
    cardId,
    sendComms,
    packages,
    note,
    payLater,
  }: {
    bookingId: string
  } & RequestFridgeStockingServiceParams) {
    return arriereBackoffice.mutations
      .booking({
        requestStockedFridgeService: {
          __args: {
            bookingId,
            serviceId,
            packages,
            securityDeposit,
            cardId,
            sendComms,
            comment: note,
            payLater,
          },
        },
      })
      .then(result => result.requestStockedFridgeService)
  },

  sendBatchPaymentLink(requestsIds: string[], guestName: string, email?: string, phone?: string) {
    return arriereBackoffice.mutations
      .booking({
        sendBatchPaymentLink: {
          __args: {
            requestsIds,
            guestName,
            email,
            phone,
          },
        },
      })
      .then(result => result.sendBatchPaymentLink)
  },

  async requestMultipleServices({
    bookingId,
    servicesInput,
  }: {
    bookingId: string
    servicesInput: RequestServicesInput[]
  }) {
    const { data, errors } = await arriereBackoffice.mutations.booking.raw({
      requestServices: {
        __args: {
          bookingId,
          servicesInput,
          payLater: true,
        },
      },
    })

    if (errors?.length > 0) {
      throw new Error(errors[0].message)
    }

    return data
  },

  async makePurchase({
    bookingId,
    requestIds,
    email,
    phone,
    guestName,
    cardId,
    amount,
    wholesalePayment,
    useTravelCredit,
    depositIds,
  }: MakePurchaseParams) {
    return arriereBackoffice.mutations.booking({
      __alias: 'makePurchase',
      makePurchase: {
        __args: {
          bookingId,
          requestIds,
          email,
          phone,
          guestName,
          cardId,
          amount,
          wholesalePayment,
          useTravelCredit,
          depositIds,
        },
      },
    })
  },

  async getSalesForceCaseCreationRules({
    bookingId,
    serviceId,
  }: {
    bookingId: string
    serviceId: string
  }): Promise<OpCaseCreationOptions> {
    return arriereBackoffice.queries
      .booking({
        __alias: 'getSalesForceCaseCreationRules',
        __args: {
          bookingId,
        },
        services: {
          opCaseCreationOptions: {
            __args: { serviceId },
            salesforceCaseCreation: true,
            showDueDate: true,
            showDescription: true,
          },
        },
      })
      .then(booking => booking.services.opCaseCreationOptions)
  },

  async adjustRequestedService({
    requestId,
    adjustment,
    reason,
    executeRefund,
  }: AdjustRequestedServiceParams) {
    return arriereBackoffice.mutations
      .booking({
        __alias: 'adjustRequestedService',
        adjustRequestedService: {
          __args: {
            requestId,
            adjustment,
            reason,
            executeRefund,
          },
        },
      })
      .then(result => result.adjustRequestedService)
  },
}

export const getBookingServicesRequestsForCancellation = async (bookingId: string) => {
  return arriereBackoffice.queries
    .booking({
      __alias: 'getBookingServicesRequestsForCancellation',
      __args: {
        bookingId,
      },
      services: {
        requests: {
          __args: {},
          id: true,
          serviceId: true,
          name: true,
          price: true,
          data: {
            dateTime: true,
            dates: true,
            date: true,
            quantity: true,
            name: true,
            packages: {
              projection: {
                fridgePackage: {
                  id: true,
                  name: true,
                  price: true,
                  vendorId: true,
                  listOrder: true,
                  description: true,
                  packageGroup: true,
                },
                vendor: {
                  id: true,
                  name: true,
                  listOrder: true,
                  hoursInAdvance: true,
                  description: true,
                  image: {
                    listOrder: true,
                    url: true,
                    description: true,
                  },
                },
                items: { packageId: true, name: true, quantity: true },
                images: {
                  listOrder: true,
                  url: true,
                  description: true,
                },
                taxes: true,
              },
              packageOrder: {
                serviceRequestId: true,
                packageId: true,
                status: true,
                orderedBy: true,
                createdAt: true,
                updatedAt: true,
              },
              price: true,
              quantity: true,
            },
          },
          status: true,
          paymentInfo: {
            requestId: true,
            paymentId: true,
            status: true,
            amount: true,
            refunded: true,
          },
        },
      },
    })
    .then(booking => booking?.services?.requests)
}

export const getAvailableServices = async (bookingId: string) => {
  return arriereBackoffice.queries
    .booking({
      __alias: 'getAvailableServices',
      __args: {
        bookingId,
      },
      services: {
        availableServices: {
          id: true,
          kind: true,
          name: true,
          priority: true,
          requiresDeposit: true,
          serviceGroup: true,
          description: true,
        },
      },
    })
    .then(booking => booking.services.availableServices)
}

export default bookingServices
