import React, { Ref } from 'react'

function _IconSelected(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0025 4.51275C13.2716 4.79029 13.2648 5.23345 12.9873 5.50258L6.79979 11.5026C6.52827 11.7659 6.09671 11.7659 5.82519 11.5026L3.01269 8.77531C2.73515 8.50618 2.72833 8.06301 2.99746 7.78547C3.26659 7.50793 3.70975 7.50112 3.98729 7.77025L6.31249 10.025L12.0127 4.49752C12.2902 4.22839 12.7334 4.23521 13.0025 4.51275Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconSelected = React.forwardRef(_IconSelected)
