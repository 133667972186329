export interface FieldTagProps {
  tags?: string[]
  internalTag?: boolean
}

export enum TagKind {
  guestFacing = 'GUEST_FACING',
  public = 'PUBLIC',
  internal = 'INTERNAL',
  internalInformation = 'INTERNAL_INFORMATION',
  systemConfig = 'SYSTEM_CONFIGURATION',
  ota = 'OTA_SETTINGS',
  salesForceCase = 'SFDC',
}

export interface TagOptionsProps {
  title: string
  value: TagKind
}
