import { CSSProperties } from 'react'

import { BookingPaymentBalanceStatus } from '@avantstay/arriere-clients/dist/arriereBackoffice'

// eslint-disable-next-line import/no-cycle
import { BookingsTapeChartViewType } from '@new/domains/bookingsTapeChart/BookingsTapeChart.types'
import { HomeDatePrices } from '@new/domains/properties/_types'

import { PeriodGroup } from '../PeriodRenderer/PeriodRenderer.types'
import { TapeChartType } from '../TapeChart.types'

enum PeriodSectionKind {
  // Booking
  booked,
  checkedIn,
  checkedOut,
  nonCompliant,
  rejected,

  // Payment
  waitingPayment,
  paid,
  partiallyPaid,
  overPaid,

  // Other
  block,
  childBlock,
  backToBack,
  conflict,
  generic,

  // Revenue
  revenue,
}

enum PeriodSectionKindPaymentMapping {
  NOT_PAID = PeriodSectionKind.waitingPayment,
  FULLY_PAID = PeriodSectionKind.paid,
  PARTLY_PAID = PeriodSectionKind.partiallyPaid,
  OVER_PAID = PeriodSectionKind.overPaid,
}

interface PeriodSectionProps {
  children: React.ReactNode
  kind: PeriodSectionKind
  leftBorder: boolean
  hasBorder?: boolean
  rightBorder: boolean
  tooltipContent?: string | React.ReactElement
  blockInfoTooltipContent?: string
  active?: boolean
  className?: string
  style?: CSSProperties
  onClick?: () => void
  item: PeriodGroup
  blockType?: string
  isOneNight?: boolean
  rowPrices: HomeDatePrices[]
  isInactivePropertyCell?: boolean
  viewType: BookingsTapeChartViewType
  paymentBalanceStatus?: BookingPaymentBalanceStatus
  type?: TapeChartType
  isDefaultBlock?: boolean
  handleShowPreviewPeriod?: () => void
  homeId: string
  shouldFade?: boolean
  onScrollToBlock?: (horizontalOffset: number) => void
  isShowingPrices?: boolean
  dataCy?: string
}

export { PeriodSectionKind, PeriodSectionKindPaymentMapping, PeriodSectionProps }
