import { DIRECT_AND_OTHER_SETTINGS_SECTION_ID, LIFECYCLE_SECTION_ID } from '@utils/customFields'

export const homeBasicInfo = {
  id: true,
  name: true,
  thumbnail: {
    url: true,
  },
  region: {
    id: true,
    name: true,
  },
}

export const homeProfile = {
  ...homeBasicInfo,
  active: true,
  details: {
    onBoardingStage: true,
    onBoardingStatus: true,
    liveSince: true,
  },

  lifecycle: {
    riskLevel: true,
    guestReadinessStatus: true,
    listingStatus: true,
  },
  createdAt: true,
  customFields: {
    __args: {
      sectionIds: [LIFECYCLE_SECTION_ID, DIRECT_AND_OTHER_SETTINGS_SECTION_ID], // Lifecycle Section, used to get the listed date custom field
    },
    fields: {
      name: true,
      id: true,
      value: true,
      tooltip: true,
    },
  },
}
