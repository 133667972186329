import styled, { css } from 'styled-components'

import { mainColors } from '../../styles/colors'
import { ProgressBarProps } from './ProgressBar.types'

const fullProgressCommonStyles = css`
  height: 8px;
  border-radius: 20px;
`

const fullProgressStyles = css<ProgressBarProps>`
  width: 100%;
  background-color: ${mainColors.neutralMedium};
  margin-right: 0;
  ${fullProgressCommonStyles};

  &:after {
    ${fullProgressCommonStyles};
    ${({ height }) =>
      height &&
      `
    height: ${height}px`};
  }

  ${({ isShadowBlurVisible }) =>
    isShadowBlurVisible &&
    `
    &:before {
     ${fullProgressCommonStyles};
    }`};
`

const commonStyles = css`
  display: inline-block;
  height: 5px;
  border-radius: 2.5px;
`

export const ProgressBarContainer = styled.div<ProgressBarProps>`
  position: relative;
  width: 80px;
  margin-right: 10px;
  background-color: ${mainColors.neutralDark};
  ${commonStyles};

  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: ${({ percentage }) => percentage}%;
    background-color: ${({ color }) => color};
    ${commonStyles};
    ${({ height }) =>
      height &&
      `
    height: ${height}px`};
  }

  ${({ isShadowBlurVisible, percentage, color }) =>
    isShadowBlurVisible &&
    `
    &:before {
      content: '';
      position: absolute;
      left: 1px;
      top: 2px;
      opacity: 0.4;
      width: ${percentage}%;
      background-color: ${color};
      filter: blur(4px);
    }`};

  ${({ type }) => type === 'full' && fullProgressStyles};

  ${({ height }) =>
    height &&
    `
    height: ${height}px`};
`

export const DotsRoot = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  width: 35px;
`

export const Dot = styled.div<{ color?: string }>`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${({ color }) => color || mainColors.neutralDark};
  margin-right: 2px;
`
