import convertTime from 'convert-time'
import isEmpty from 'lodash/isEmpty'
import * as React from 'react'

import * as S from '@new/__global/components/CustomField/types/types.styles'
import TimeRangeField from '@new/__ui/TimeRangeField/TimeRangeField'

// eslint-disable-next-line import/no-cycle
import { jsonParseCustomFieldValue } from '@utils/customFields'
import { addZeroToTimeString, removeSpaces } from '@utils/strings'

import {
  CustomFieldProps,
  CustomFieldTypeReturn,
  IInputProps,
  IMountComponentProps,
} from '../_types'

const parseTime = (value?: string) => {
  const chunks = value?.split(',')

  if (isEmpty(value)) {
    return null
  }
  return chunks
    ?.map((it: string) => {
      const newValue = addZeroToTimeString(convertTime(it))
      return newValue ? removeSpaces(newValue) : value
    })
    .join(', ')
}

const parseValueToShow = (customField: CustomFieldProps) => {
  const value = jsonParseCustomFieldValue(customField)

  return parseTime(value)
}

const parseValueToSave = (customField: CustomFieldProps) => {
  return customField.value ? JSON.stringify(parseTime(customField.value)) : JSON.stringify(null)
}

function ComponentReadMode({ customField }: IMountComponentProps) {
  if (customField?.value) {
    return <S.Text>{JSON.parse(customField.value)}</S.Text>
  }

  return <></>
}

function ComponentWriteMode({
  inputProps,
  error,
  warning,
  customField,
  isReadOnly,
  disabled,
}: IMountComponentProps) {
  if (isReadOnly) return <ComponentReadMode customField={customField} />

  return (
    <TimeRangeField
      {...(inputProps as IInputProps)}
      error={error}
      warning={warning}
      disabled={disabled}
    />
  )
}

export default {
  read: { Component: ComponentReadMode },
  write: { Component: ComponentWriteMode },
  parseValueToShow,
  parseValueToSave,
} as CustomFieldTypeReturn
