import { InfoMessage as InfoMessageDefault } from './InfoMessage'
import { InfoMessageCollapse } from './InfoMessageCollapse'

export * from './InfoMessage'
export * from './InfoMessage.types'
export * from './InfoMessage.styles'

function InfoMessageComponent() {}

InfoMessageComponent.Default = InfoMessageDefault
InfoMessageComponent.Collapse = InfoMessageCollapse

export { InfoMessageComponent }
