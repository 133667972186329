import React, { Ref } from 'react'

function _IconDevice(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" {...props} ref={ref}>
      <path
        d="M9.63341 2.9751C7.52092 2.9751 5.80841 4.68761 5.80841 6.8001V27.2001C5.80841 29.3126 7.52092 31.0251 9.63341 31.0251H22.9501C25.0626 31.0251 26.7751 29.3126 26.7751 27.2001V6.8001C26.7751 4.68761 25.0626 2.9751 22.9501 2.9751H9.63341ZM8.35841 6.8001C8.35841 6.09593 8.92925 5.5251 9.63341 5.5251H22.9501C23.6542 5.5251 24.2251 6.09593 24.2251 6.8001V27.2001C24.2251 27.9043 23.6542 28.4751 22.9501 28.4751H9.63341C8.92925 28.4751 8.35841 27.9043 8.35841 27.2001V6.8001ZM17.6072 24.65C17.6072 25.3541 17.0182 25.925 16.2917 25.925C15.5652 25.925 14.9762 25.3541 14.9762 24.65C14.9762 23.9458 15.5652 23.375 16.2917 23.375C17.0182 23.375 17.6072 23.9458 17.6072 24.65Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconDevice = React.forwardRef(_IconDevice)
