import styled from 'styled-components'

import { FloatingContainer } from '@avantstay/avantstay-ui'
import { IconChevronDown } from '@avantstay/backoffice-vectors'

import { Button } from '../../components/Button'
import { mainColors, shadowColors } from '../../styles/colors'
import { _M_Bold, _M_Normal, _XS_Normal } from '../../styles/typography'
import { zIndex } from '../../styles/zIndex'
import { rgba } from '../../utils/cssUtils'

export const SelectContainer = styled.div<{ disabled?: boolean; hasValue?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
  > div {
    background-color: ${mainColors.neutralExtraLight} !important;
  };`};

  ${({ hasValue }) =>
    hasValue &&
    `
    .propertySelectInput::placeholder {
      color: ${mainColors.black} !important;
      opacity: 1;
    }
  }`}
`

export const ArrowIndicator = styled(IconChevronDown)<{ isOpen: boolean }>`
  cursor: pointer;
  width: 20px;
  ${({ isOpen }) => isOpen && `transform: rotate(180deg);`}
`

export const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
`

export const GroupLabel = styled.span`
  ${_M_Bold};
  color: ${mainColors.black};
  text-transform: capitalize;
`

export const ButtonClear = styled(Button.Flat)`
  ${_M_Bold};
  color: ${mainColors.accentMedium};
  text-transform: uppercase;
`

export const OptionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  width: 100%;
`

export const Option = styled.div`
  ${_M_Normal};
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 47px;
  background-color: ${mainColors.white};
  color: ${mainColors.black};
  padding: 8px 18px;
  cursor: pointer;
  line-height: normal;

  &:hover {
    background-color: ${mainColors.neutralExtraLight};
    color: ${mainColors.black};
  }
`

export const OptionSubtitle = styled.div`
  ${_XS_Normal};
  color: ${mainColors.neutralExtremelyDark};
`

export const EmptyContainer = styled.div`
  ${_M_Normal};
  color: ${mainColors.neutralExtremelyDark};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  height: 100%;
`

export const SelectDropdown = styled(FloatingContainer)<{
  width?: number
  isLoading?: boolean
}>`
  ${({ width }) => width && `width: ${width}px;`};
  background: ${mainColors.white};
  border: 1px solid ${mainColors.neutralMedium};
  box-shadow: 0 4px 16px ${rgba(shadowColors.blur, 0.4)};
  height: ${({ isLoading }) => (isLoading ? '50px' : '300px')};
  z-index: ${zIndex.INFINITE};
  margin-top: 2px;
`
