import styled from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _M_Normal } from '../../styles/typography'

export const PreviewContainer = styled.div`
  line-height: 1.5;

  a:link {
    color: #00f;
    text-decoration: none;
  }

  a:visited {
    color: #00a;
    text-decoration: none;
  }

  a:hover {
    color: #f60;
    text-decoration: underline;
  }

  a:active {
    color: #f60;
    text-decoration: underline;
  }

  /* html tags */

  /*  Work around IE/Win code size bug - courtesy Jesper, waffle.wootest.net  */

  * html code {
    font-size: 101%;
  }

  * html pre {
    font-size: 101%;
  }

  /* code */

  pre,
  code {
    font-size: 11px;
    font-family: 'Roboto', sans-serif;
  }

  pre {
    border: 1px solid #c7cfd5;
    background: #f1f5f9;
    margin: 20px 0;
    padding: 8px;
    text-align: left;
  }

  hr {
    color: #919699;
    size: 1;
    width: 100%;
    noshade: 'noshade';
  }

  /* headers */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  h1 {
    margin-top: 1em;
    margin-bottom: 24px;
    color: #000;
    font-weight: bold;
    font-size: 30px;
  }

  h2 {
    font-size: 18px;
    color: #000;
    padding-bottom: 2px;

    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  h3 {
    margin-bottom: 0.5em;
    font-size: 16px;
    color: #000;

    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  h4 {
    margin-bottom: 0.5em;
    font-size: 14px;
    color: #000;

    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  h5 {
    margin-top: 10px;
    margin-bottom: 0.5em;
    padding: 0;
    font-size: 12px;
    color: #000;
  }

  h6 {
    margin-top: 10px;
    margin-bottom: 0.5em;
    padding: 0;
    font-size: 10px;
    color: #000;
  }

  p {
    margin-top: 0px;
    margin-bottom: 10px;
    ${_M_Normal};
    color: ${mainColors.neutralExtremelyDark};
  }

  /* lists */

  ul {
    list-style: square outside;
    margin: 0 0 0 30px;
    padding: 0 0 12px 6px;
  }

  li {
    margin-top: 7px;
  }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
    margin: 0 0 0 30px;
    padding: 0 0 12px 6px;
  }

  ol ol {
    list-style-type: lower-alpha;
    list-style-position: outside;
    margin: 7px 0 0 30px;
    padding: 0 0 0 10px;
  }

  ul ul {
    margin-left: 40px;
    padding: 0 0 0 6px;
  }

  li > p {
    display: inline;
  }
  li > p + p {
    display: block;
  }
  li > a + p {
    display: block;
  }

  /* table */

  table {
    width: 100%;
    border-top: 1px solid #919699;
    border-left: 1px solid #919699;
    border-spacing: 0;
  }

  table th {
    padding: 4px 8px 4px 8px;
    background: #e2e2e2;
    font-size: 12px;
    border-bottom: 1px solid #919699;
    border-right: 1px solid #919699;
  }
  table th p {
    font-weight: bold;
    margin-bottom: 0px;
  }

  table td {
    padding: 8px;
    font-size: 12px;
    vertical-align: top;
    border-bottom: 1px solid #919699;
    border-right: 1px solid #919699;
  }
  table td p {
    margin-bottom: 0px;
  }
  table td p + p {
    margin-top: 5px;
  }
  table td p + p + p {
    margin-top: 5px;
  }

  /* forms */

  form {
    margin: 0;
  }

  button {
    margin: 3px 0 10px 0;
  }
  input {
    vertical-align: middle;
    padding: 0;
    margin: 0 0 5px 0;
  }

  select {
    vertical-align: middle;
    padding: 0;
    margin: 0 0 3px 0;
  }

  textarea {
    margin: 0 0 10px 0;
    width: 100%;
  }

  blockquote {
    border-left: 4px solid #f4f4f4;
    padding-left: 20px;
  }

  strong {
    font-weight: bold;
  }
`
