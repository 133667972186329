// @ts-nocheck
import React from 'react'

import { RiskLevel } from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { IconAlert } from '@avantstay/backoffice-vectors'

import { Badge, BadgeColors } from '../../components/Badge'
import { MaintenanceStatus, OnBoardingStatus } from '../../enums/statuses'
import { HouseStatusProps } from './HouseStatus.types'

export const houseStatusBackground = {
  [OnBoardingStatus.STABILIZED]: BadgeColors.success,
  [OnBoardingStatus.PRE_ON_BOARDING]: BadgeColors.neutral,
  [OnBoardingStatus.OFF_BOARDED]: BadgeColors.neutral,
  [OnBoardingStatus.ON_BOARDING]: BadgeColors.neutral,
  [OnBoardingStatus.NEW]: BadgeColors.warning,
  [OnBoardingStatus.LIVE]: BadgeColors.warning,
  [MaintenanceStatus.CLEAN]: BadgeColors.success,
  [MaintenanceStatus.OCCUPIED]: BadgeColors.warning,
  [MaintenanceStatus.VACANT]: BadgeColors.success,
  [MaintenanceStatus.DIRTY]: BadgeColors.warning,
  [RiskLevel.veryHigh]: BadgeColors.riskVeryHigh,
  [RiskLevel.high]: BadgeColors.riskVeryHigh,
  [RiskLevel.medium]: BadgeColors.riskMedium,
  [RiskLevel.low]: BadgeColors.riskLow,
  [RiskLevel.undefined]: BadgeColors.riskVeryLow,
  default: BadgeColors.neutral,
}

const HouseStatus = ({ status, label, styles }: HouseStatusProps): JSX.Element => (
  <Badge
    styles={{ marginRight: '4px', marginBottom: '4px', ...styles }}
    badgeColor={houseStatusBackground[status] || houseStatusBackground.default}
  >
    {status === RiskLevel.veryHigh && (
      <IconAlert
        width={'9px'}
        style={{ marginRight: '4px' }}
        color={houseStatusBackground[status].color}
      />
    )}
    {label}
  </Badge>
)

export { HouseStatus }
