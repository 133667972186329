import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconBed2(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41 4C5.71718 4 5.19 4.57907 5.19 5.25V8.1758C4.55766 8.34932 4.11 8.94553 4.11 9.625V12V13.5C4.11 13.7761 4.33386 14 4.61 14C4.88614 14 5.11 13.7761 5.11 13.5V12.5H14.91V13.5C14.91 13.7761 15.1339 14 15.41 14C15.6861 14 15.91 13.7761 15.91 13.5L15.91 12M15.91 11.9986V9.625C15.91 8.94553 15.4624 8.34932 14.83 8.1758V5.25C14.83 4.57908 14.3028 4 13.61 4H6.41M13.83 8.125V5.25C13.83 5.09249 13.7125 5 13.61 5H6.41C6.30754 5 6.19 5.09249 6.19 5.25V8.125H7.35001V7.25C7.35001 6.71715 7.76973 6.25 8.33001 6.25H11.69C12.2503 6.25 12.67 6.71715 12.67 7.25V8.125H13.83ZM11.67 8.125H8.35001V7.25H11.67V8.125ZM5.69 9.125H7.85001H12.17H14.33H14.45C14.685 9.125 14.91 9.32941 14.91 9.625V11.5H5.11V9.625C5.11 9.32941 5.33498 9.125 5.57 9.125H5.69Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconBed2 = forwardRef(_IconBed2)
