import { Alert } from '../components'

const hasFileExt = (fileName: string): boolean => {
  const allowedExts = ['.pdf', '.jpg', '.jpeg', '.png', '.txt', '.xls', '.csv']
  return allowedExts.map(ext => fileName.includes(ext)).filter(has => has).length > 0
}

export const downloadFile = async (uri: string, fileName: string, init?: RequestInit) => {
  return fetch(uri, init)
    .then(response => {
      if (!response.ok) {
        throw new Error(`Error in download: ${response.status} ${response.statusText}`)
      }
      return response.blob()
    })
    .then(blob => {
      const newUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      const fileNameWithExt = hasFileExt(fileName) ? fileName : `${fileName}.pdf`

      link.target = '_blank'
      link.href = newUrl
      link.download = fileNameWithExt

      document.body.appendChild(link)
      link.onclick = function onClick(e) {
        e.stopPropagation()
      }
      link.click()
      document.body.removeChild(link)
    })
    .catch(error => {
      Alert.Error(error)
      console.error('Fetch error:', error)
    })
}
