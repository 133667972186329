import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconExpedia = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="4 7 20 15" fill="none" {...props} ref={ref}>
    <path
      d="M9.68013 7.50001C9.47698 7.50001 9.3493 7.54669 9.24171 7.65427L7.0632 9.83279C7.02274 9.87324 7.00001 9.92815 7 9.98535C6.99999 10.1045 7.09657 10.201 7.21567 10.201H16.6597L10.8537 16.0069C10.7393 16.1213 10.6728 16.1878 10.6728 16.4124L10.6728 17.9399C10.6728 18.0325 10.7148 18.1126 10.7752 18.173C10.8356 18.2334 10.9157 18.2754 11.0082 18.2754H12.5359C12.7604 18.2754 12.8269 18.2089 12.9413 18.0945L18.7472 12.2886V21.7325C18.7472 21.8516 18.8438 21.9482 18.9629 21.9482C19.0201 21.9482 19.075 21.9255 19.1154 21.885L21.2939 19.7065C21.4015 19.5989 21.4482 19.4712 21.4482 19.2681L21.4482 8.95579C21.4482 8.76461 21.4105 8.5753 21.3374 8.39867C21.2642 8.22204 21.157 8.06156 21.0218 7.92637C20.8866 7.79119 20.7261 7.68396 20.5495 7.6108C20.3728 7.53765 20.1835 7.49999 19.9923 7.5L9.68013 7.50001Z"
      fill="#191E3B"
    />
    <path
      d="M7.82983 18.6998H9.92535C10.1006 18.6998 10.2418 18.8409 10.2418 19.0162V21.1249C10.2418 21.3002 10.1006 21.4413 9.92535 21.4413H7.82983C7.65454 21.4413 7.51343 21.3002 7.51343 21.1249V19.0162C7.51343 18.8409 7.65454 18.6998 7.82983 18.6998Z"
      fill="#191E3B"
    />
  </svg>
)

export const IconExpedia = forwardRef(_IconExpedia)
