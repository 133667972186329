import arriereBackoffice, {
  RecurringBookingCaseSettingSorting,
  SortOrder,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { CreationBookingBasedCase } from '@new/domains/operations/screens/BookingBasedCase/components/BookingBasedCaseModal/BookingBasedCaseModal.types'

interface SearchSettingsFilterProps {
  pagination: { size: number; page: number }
  regionIds?: string[]
  homeIds?: string[]
  term?: string
  sortBy?: RecurringBookingCaseSettingSorting
  sortOrder?: SortOrder
}

export const searchRecurring = async (args: SearchSettingsFilterProps) => {
  const response = await arriereBackoffice.queries.recurringBookingCaseSetting({
    __alias: 'searchSettings',
    searchSettings: {
      __args: args,
      pages: true,
      total: true,
      results: {
        id: true,
        subject: true,
        recordType: true,
        active: true,
        vendorName: true,
        caseOwnerName: true,
        guestTags: {
          id: true,
          name: true,
          type: true,
        },
        createdAt: true,
        properties: { id: true, name: true, regionName: true },
        regions: {
          id: true,
          name: true,
          homesCountingStats: { preListedCount: true, listedCount: true },
        },
        shiftHours: true,
        shiftToBookingDay: true,
      },
    },
  })

  return response.searchSettings
}

export const findSetting = async (args: { id: string }) => {
  const response = await arriereBackoffice.queries.recurringBookingCaseSetting({
    __alias: 'findSetting',
    findSetting: {
      __args: args,
      id: true,
      subject: true,
      recordType: true,
      active: true,
      vendorName: true,
      vendorId: true,
      caseOwnerName: true,
      caseOwnerSfdcId: true,
      typeSfdcApiName: true,
      statusSfdcApiName: true,
      categorySfdcApiName: true,
      subcategorySfdcApiName: true,
      properties: { id: true, name: true, regionName: true },
      regions: {
        id: true,
        name: true,
        homesCountingStats: { preListedCount: true, listedCount: true },
      },
      amenities: { id: true, name: true },
      description: true,
      priority: true,
      startDate: true,
      endDate: true,
      guestTags: {
        id: true,
        name: true,
        type: true,
      },
      createdAt: true,
      shiftHours: true,
      shiftToBookingDay: true,
      images: {
        id: true,
        url: true,
      },
    },
  })

  return response.findSetting
}

export const getAmenities = async () => {
  const response = await arriereBackoffice.queries.amenities({
    __alias: 'amenities',
    id: true,
    name: true,
  })
  return response
}
export const getGuestTags = async () => {
  const response = await arriereBackoffice.queries.guestTags({
    __alias: 'guestTags',
    id: true,
    name: true,
    type: true,
  })
  return response
}

export const createRecurring = async (setting: CreationBookingBasedCase) => {
  const response = await arriereBackoffice.mutations.recurringBookingCaseSetting({
    __alias: 'createBookingCaseBased',
    createSetting: {
      __args: { setting },
    },
  })
  return response.createSetting
}

export const updateRecurring = async (id: string, setting: CreationBookingBasedCase) => {
  const response = await arriereBackoffice.mutations.recurringBookingCaseSetting({
    __alias: 'updateBookingCaseBased',
    updateSetting: {
      __args: { id, setting },
    },
  })
  return response.updateSetting
}
