import arriereBackoffice, {
  BookingQuoteRequest,
  HomeQuerySorting,
  IDate,
  RevenueModel,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import arrierePublic, { BookingRequestInput } from '@avantstay/arriere-clients/dist/arrierePublic'

import { QuotesHistoryQueryFilters } from '@new/domains/marketing/screens/QuotesHistory/QuotesHistory.types'

import { BookingQuoteRequestWithoutHomes, NonBrandedQuoteHomes } from '@arriere/types/Quotes.types'

export const getPreBookingPrice = async ({
  homeId,
  from,
  until,
  guests,
  pets,
}: BookingRequestInput) =>
  arrierePublic.queries.preBookingPriceBreakdown({
    __args: {
      bookingRequest: {
        homeId,
        from,
        until,
        guests,
        pets,
      },
    },
    taxesAndFees: {
      items: {
        name: true,
        amount: true,
      },
      total: true,
      petFee: true,
    },
    nights: {
      date: true,
      price: true,
    },
    longTermDiscount: {
      percentage: true,
      value: true,
    },
    subtotal: {
      total: true,
      withDiscount: true,
    },
    couponDiscount: {
      percentage: true,
      value: true,
    },
    totalOfStay: {
      total: true,
      withDiscount: true,
    },
    securityDeposits: {
      booking: true,
      pet: true,
    },
  })

export const getHouseCalendar = async ({
  homeId,
  from,
  to,
}: {
  homeId: string
  from: string
  to: string
}) =>
  arriereBackoffice.queries.home({
    __alias: 'getHouseCalendar',
    __args: {
      homeId,
    },
    calendar: {
      __args: {
        from,
        to,
      },
      status: true,
      minStay: true,
    },
  })

export const sendQuoteTravelAgent = async (params: {
  email: string
  homeIds?: string[]
  homes?: NonBrandedQuoteHomes[]
  request?: BookingQuoteRequestWithoutHomes
}) =>
  arriereBackoffice.mutations.sendTravelAgent({
    __args: {
      ...params,
    },
  })

export const sendQuoteHomeList = async ({
  email,
  fullName,
  homeIds,
  request,
}: {
  email: string
  fullName: string
  homeIds: string[]
  request?: BookingQuoteRequestWithoutHomes
}) =>
  arriereBackoffice.mutations.sendQuoteHomeList({
    __args: {
      homeIds,
      email,
      guestName: fullName,
      request,
    },
  })

interface HomeDetailsParams {
  pagination: { size: number; page: number }
  minPrice: number
  maxPrice: number
  terms?: string
  dateRange?: {
    startDate: string | null
    endDate: string | null
  }
  minOccupancy?: number
  minBathrooms?: number
  minBedrooms?: number
  minPets?: number
  revenueModel: RevenueModel[]
  filterableAmenityIds?: string[]
  regionIds?: string[]
  sort: HomeQuerySorting
}

export const homeDetailsToCreateQuotes = async ({
  pagination,
  minPrice,
  maxPrice,
  terms,
  dateRange,
  minOccupancy,
  minBathrooms,
  minBedrooms,
  minPets,
  revenueModel,
  filterableAmenityIds,
  regionIds,
  sort,
}: HomeDetailsParams) =>
  arriereBackoffice.queries.homes({
    __alias: 'homeDetailsToCreateQuotes',
    __args: {
      pagination: { size: pagination.size, page: pagination.page },
      minPrice,
      maxPrice,
      name: terms,
      checkIn: dateRange?.startDate || undefined,
      checkOut: dateRange?.endDate || undefined,
      minOccupancy,
      minBathrooms,
      minBedrooms,
      minPets,
      revenueModel,
      filterableAmenityIds,
      regionIds,
      sort,
    },
    pages: true,
    total: true,
    results: {
      thumbnail: { url: true },
      id: true,
      name: true,
      region: {
        id: true,
        name: true,
      },
      maxPets: true,
      maxOccupancy: true,
      bathroomsCount: true,
      bedroomsCount: true,
      hasPool: true,
      revenueModel: true,
      price:
        dateRange && dateRange.startDate && dateRange.endDate
          ? {
              __args: {
                period: {
                  start: dateRange.startDate as IDate,
                  end: dateRange.endDate as IDate,
                },
              },
              totalPrice: true,
            }
          : undefined,
    },
  })

export const createBookingQuote = async (bookingRequest: BookingQuoteRequest) => {
  return arriereBackoffice.mutations.createBookingQuote({
    __args: { bookingRequest },
    id: true,
  })
}

export const getQuotesHistory = async (variables: QuotesHistoryQueryFilters) => {
  return arriereBackoffice.queries.bookingQuotes
    .raw({
      __alias: 'getQuotesHistory',
      __args: {
        ...variables,
      },
      pages: true,
      total: true,
      results: {
        id: true,
        createdAt: true,
        salesTo: true,
        checkIn: true,
        checkOut: true,
        guest: {
          fullName: true,
          emails: true,
        },
        createdBy: {
          kind: true,
          name: true,
        },
        homes: {
          id: true,
          name: true,
          region: {
            name: true,
          },
          thumbnail: {
            url: true,
          },
        },
        kind: true,
        homeQuote: {
          plainPrice: true,
          quotePrice: true,
          discount: {
            discountKind: true,
            discountAmount: true,
          },
          coupon: {
            usageCount: true,
          },
        },
        notifyLog: {
          id: true,
          status: true,
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors && errors?.length) {
        throw new Error(errors[0].message)
      }

      return {
        ...data,
        results: data.results.map(historyResults => ({
          ...historyResults,
          // Todo: temporary solution to not display bug of 3000+ homes
          homes: historyResults.homes.length > 100 ? undefined : historyResults.homes,
        })),
      }
    })
}

export const getQuotesHistoryAuthors = async (variables: QuotesHistoryQueryFilters) => {
  return arriereBackoffice.queries.bookingQuotes
    .raw({
      __alias: 'getQuotesHistoryAuthors',
      __args: {
        ...variables,
      },
      results: {
        createdBy: {
          kind: true,
          name: true,
        },
        createdByBackofficeUserId: true,
      },
    })
    .then(({ data, errors }) => {
      if (errors && errors?.length) {
        throw new Error(errors[0].message)
      }

      return data
    })
}

export const getHomeDataToExportQuotes = async (homeIds: string[]) => {
  return arriereBackoffice.queries.homes
    .raw({
      __alias: 'getHomeDataToExportQuotes',
      __args: { homeIds },
      results: {
        id: true,
        name: true,
        address: {
          address: true,
          city: true,
          zipCode: true,
        },
        region: {
          name: true,
        },
        bedroomsCount: true,
        bathroomsCount: true,
        halfBathroomsCount: true,
        maxOccupancy: true,
        petPolicy: true,
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[0]?.message)
      }

      return data.results
    })
}
