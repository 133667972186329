// @ts-nocheck
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { MarkdownPreviewProps } from '../../components/MarkdownField/_types'
import { PreviewContainer } from '../../components/MarkdownField/MarkdownPreview.styles'

export const MarkdownPreview = ({ source, maxLength }: MarkdownPreviewProps) => {
  const valueFormatted = typeof source === 'string' ? source.replace(/\n/gi, '<br>') : ''
  const value =
    maxLength && valueFormatted.length > maxLength
      ? `${valueFormatted.slice(0, maxLength)}...`
      : valueFormatted
  return (
    <PreviewContainer data-testid="preview">
      <ReactMarkdown children={value} rehypePlugins={[rehypeRaw]} />
    </PreviewContainer>
  )
}
