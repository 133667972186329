import { CustomFieldRestriction } from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { mainColors, semanticColors } from '@avantstay/backoffice-core'

export const restrictionColor = {
  [CustomFieldRestriction.essential]: semanticColors.warningMedium,
  [CustomFieldRestriction.optional]: mainColors.neutralDark,
  [CustomFieldRestriction.required]: semanticColors.failureMedium,
}

export const customFieldsTooltipContentWriteMode = {
  'Check-In Type': {
    Standard:
      'This is our standard self check-in. Guests receive entry instructions 3 days before their reservation.',
    'In person':
      'This requires CX to release entry instructions manually. Guests receive a live check-in email 3 days before check-in.',
    Video:
      'This requires CX to release entry instructions manually. Guests need to receive a video check-in email 3 days before check-in.',
  },
}

const MINIMUM_AGE_OF_RESP_PARTY_ID = '8e6dc68a-230d-11ea-a01a-503eaadea111'

export const customFieldsTooltipContentReadMode = (fieldId?: string) =>
  fieldId?.includes(MINIMUM_AGE_OF_RESP_PARTY_ID) ? 'Synced from Salesforce' : undefined
