import React, { FC } from 'react'

import * as S from './ProgressBar.styles'

export const ProgressBar: FC<S.ProgressBarProps> = ({ percentage }) => {
  const barWidth = 75
  const value = percentage ? percentage * barWidth : 0

  return (
    <S.Progress percentage={barWidth}>
      <S.Bar percentage={value} />
    </S.Progress>
  )
}
