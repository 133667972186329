import React, { Ref } from 'react'

function _IconReset(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.24209 5.19185C9.74143 4.3755 11.4702 4.06188 13.1674 4.29959C14.8645 4.53729 16.4352 5.31296 17.6437 6.50699C17.6517 6.51488 17.6598 6.52266 17.668 6.53031L19.9655 8.6667H16.3889C15.7753 8.6667 15.2778 9.16416 15.2778 9.77781C15.2778 10.3915 15.7753 10.8889 16.3889 10.8889H21.9445C22.5582 10.8889 23.0556 10.3915 23.0556 9.77781V4.22224C23.0556 3.60858 22.5582 3.11112 21.9445 3.11112C21.3309 3.11112 20.8334 3.60858 20.8334 4.22224V6.43921L19.1932 4.91406C17.644 3.38856 15.6376 2.40166 13.4756 2.09884C11.308 1.79523 9.09844 2.19532 7.17944 3.24016C5.26024 4.28512 3.73423 5.91925 2.83404 7.8987C1.93375 9.87836 1.70915 12.0944 2.19488 14.212C2.68058 16.3295 3.84952 18.2313 5.5226 19.632C7.19545 21.0325 9.28202 21.8568 11.4675 21.9831C13.653 22.1093 15.822 21.5309 17.6479 20.3331C19.4741 19.1352 20.859 17.3819 21.5912 15.3354C21.7979 14.7576 21.4971 14.1216 20.9194 13.9149C20.3416 13.7082 19.7056 14.009 19.4989 14.5868C18.9307 16.1747 17.8544 17.54 16.429 18.475C15.0035 19.4102 13.3071 19.8634 11.5957 19.7645C9.88434 19.6657 8.25369 19.0203 6.94913 17.9281C5.6448 16.8361 4.73745 15.357 4.36086 13.7152C3.9843 12.0735 4.15811 10.3553 4.85692 8.81864C5.55583 7.28179 6.74296 6.00809 8.24209 5.19185Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconReset = React.forwardRef(_IconReset)
