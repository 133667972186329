import differenceInDays from 'date-fns/differenceInDays'

import { parseISODate } from '@avantstay/backoffice-core'

export function ensureRight(anything: string, suffix: string): string {
  if (anything === '-') return anything

  const anythingStr = `${anything}`
  return anythingStr.endsWith(suffix) ? anythingStr : `${anythingStr}${suffix}`
}

export function ensureLeft(anything: string, prefix: string): string {
  if (anything === '-') return anything

  const anythingStr = `${anything}`
  return anythingStr.startsWith(prefix) ? anythingStr : `${prefix}${anythingStr}`
}

export const removeSpaces = (s?: string): string | undefined => (s ? s.replace(/\s/g, '') : s)

export function pluralize(
  number: number | undefined,
  plural: string,
  singular: string,
): string | undefined {
  if (number === undefined) {
    return undefined
  }

  return number !== 1 ? plural : singular
}

export const addZeroToTimeString = (str?: string): string | undefined => {
  if (!str) return str

  const strSplited = str.split(':')

  if (strSplited[0].length <= 1) {
    return 0 + str
  }

  return str
}

export const totalNights = (start: string | Date, end: string | Date): string =>
  `${differenceInDays(parseISODate(end), parseISODate(start))} ${pluralize(
    differenceInDays(parseISODate(end), parseISODate(start)),
    'nights',
    'night',
  )}`
