import React, { Ref } from 'react'

function _IconWheelhouseLogo(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="162"
      height="162"
      viewBox="0 0 162 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        d="M70.8605 93.1962C70.2414 94.3717 68.5543 94.3725 67.9342 93.1973L39.0303 38.417C38.451 37.3188 39.2491 36 40.4932 36H80.4737L73.973 50.8216L89.7604 57.3061L70.8605 93.1962Z"
        fill="#B31FAD"
      />
      <path
        d="M90.0859 93.2009C90.7058 94.3753 92.3916 94.3751 93.0113 93.2006L121.917 38.417C122.496 37.3188 121.698 36 120.454 36H80.4737L86.9743 50.8216L72.1156 59.1587L90.0859 93.2009Z"
        fill="#D926D2"
      />
    </svg>
  )
}

export const IconWheelhouseLogo = React.forwardRef(_IconWheelhouseLogo)
