import styled from 'styled-components'

import { mainColors } from '../../../styles/colors'
import { _M_Normal } from '../../../styles/typography'
import { zIndex } from '../../../styles/zIndex'
import { GridCell } from '../AutoSizeGrid.styles'
import { ColumnBorderOptions } from '../AutoSizeGrid.types'

export const HeaderRow = styled(GridCell)`
  ${_M_Normal};
  position: relative;
  color: ${mainColors.neutralExtremelyDark};
  background-color: ${({ background }) => background};
  border-right: ${({ borderStyle, fixedColumn }) =>
    borderStyle === ColumnBorderOptions.right || fixedColumn
      ? `1px solid ${mainColors.neutralMedium}`
      : 'none'};
  border-bottom: ${({ hasRowBelowHeader }) =>
    hasRowBelowHeader ? 'none' : `1px solid ${mainColors.neutralMedium}`};
  ${({ borderStyle }) =>
    borderStyle === ColumnBorderOptions.left &&
    `border-left: 1px solid ${mainColors.neutralMedium}`};

  ${props =>
    props.resizable &&
    `
      &:hover::before {
        content: '';
        width: 1px;
        height: 100%;
        border-left: 1px dashed ${mainColors.neutralDark};
        position: absolute;
        left: 0;
      }

      &:hover::after {
        content: '';
        width: 1px;
        height: 100%;
        border-right: 1px dashed ${mainColors.neutralDark};
        position: absolute;
        right: 0;
      }
  `}
`

export const HeaderCell = styled.div<{ disabledHeaderCellFullWidth: boolean }>`
  ${({ disabledHeaderCellFullWidth }) => !disabledHeaderCellFullWidth && 'width: 100%;'};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const DragLine = styled.div`
  height: 100%;
  width: 1px;
  border-right: 1px dashed ${mainColors.neutralDark};
  padding-left: 15px;
  cursor: col-resize;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${zIndex.INFINITE};

  &:hover {
    opacity: 1;
  }
`
