import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconFilter = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        d="M14.7559 0H1.24573C0.900806 0 0.571395 0.147898 0.335868 0.408235C0.100364 0.668577 -0.020221 1.01809 0.00277781 1.37365L0.0306063 1.69007C0.377065 4.94284 2.53855 7.66015 5.51077 8.6621L5.7848 8.74802L5.78579 14.7124C5.78597 15.2122 6.06567 15.6667 6.50342 15.8785C6.94113 16.0903 7.45792 16.0214 7.82927 15.7016L9.76353 14.0355L9.87782 13.924C10.0912 13.6875 10.2122 13.3739 10.2119 13.0457L10.2113 8.74802L10.487 8.66248C13.5563 7.62922 15.7618 4.76698 15.9972 1.37752C16.0202 1.01756 15.8994 0.668434 15.6641 0.408389C15.429 0.148478 15.1003 0.000656471 14.7559 0ZM14.4466 1.57498L14.4122 1.84275C13.9916 4.58044 11.9666 6.79708 9.29724 7.3596L9.18281 7.39345C8.88867 7.50678 8.68716 7.7988 8.68716 8.13169L8.68654 12.911L7.30956 14.0966L7.31055 8.13169C7.31055 7.75731 7.05568 7.43462 6.7008 7.35967L6.42709 7.29552C3.89468 6.64261 1.994 4.48516 1.58775 1.83883L1.55182 1.57498H14.4466Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconFilter = forwardRef(_IconFilter)
