import styled, { css } from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _M_Medium, _M_Normal } from '../../styles/typography'

const sharedFieldStyles = css`
  line-height: 20px;
  min-height: 20px;
  display: flex;
  align-items: flex-start;
  gap: 4px;

  svg {
    color: ${mainColors.neutralExtraDark};
    width: 16px;
    min-width: 16px;
    height: 20px;

    &:hover {
      color: ${mainColors.neutralExtremelyDark};
    }
  }

  span {
    width: max-content;
  }
`

export const Field = styled.div<{ marginTop?: number }>`
  margin-top: ${({ marginTop }) => marginTop ?? 0}px;
`

export const Title = styled.div`
  ${_M_Medium};
  margin-bottom: 4px;

  ${sharedFieldStyles};
`

export const Info = styled.div`
  ${_M_Normal};

  ${sharedFieldStyles};
`

export const FieldSubtitle = styled(Info)`
  color: ${mainColors.neutralExtremelyDark};

  ${sharedFieldStyles};
`

export const FieldAvatar = styled.div`
  width: 34px;
  height: 34px;
  color: ${mainColors.neutralExtremelyDark};
  background-color: ${mainColors.neutralLight};
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
