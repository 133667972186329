export const EMAIL_PATTERN = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,}/

export const PHONE_PATTERN = /^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/

export const UUID_PATTERN =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/

export const USER_MENTION_PATTERN =
  /@\[[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ',()\- ]+\]\([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\)/g

export const MENTION_PATTERN = /(@([A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ',()\- ]+$))/

export const PERIOD_ISO_PATTERN = /\d{4}-\d{2}-\d{2}_\d{4}-\d{2}-\d{2}/

export const DATE_ISO_PATTERN = /^(\d{4}-\d{2}-\d{2})$/
