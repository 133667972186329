import { RefObject } from 'react'

export enum Direction {
  Up = 'up',
  Down = 'down',
}

export interface SlidingDrawerProps {
  onClose?: () => void
  className?: string
  baseRef?: RefObject<HTMLDivElement>
  direction?: Direction
  hideCloseButton?: boolean
}
