import humanizeString from 'humanize-string'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import React, { ChangeEvent } from 'react'

import { Checkbox, mainColors } from '@avantstay/backoffice-core'

import { IconSelected } from '@new/__ui/__vectors'

// eslint-disable-next-line import/no-cycle
import { jsonParseCustomFieldValue } from '@utils/customFields'

import {
  CustomFieldProps,
  CustomFieldTypeReturn,
  IInputProps,
  IMountComponentProps,
} from '../_types'
import { stringifyValue } from '../CustomField.utils'
import * as S from './types.styles'

function ComponentReadMode({ customField }: IMountComponentProps) {
  const values = typeof customField?.value === 'string' ? JSON.parse(customField.value) : []

  return (
    <S.OptionsRoot>
      {values?.map((it: string, index: number) => (
        <S.Option key={index}>
          <IconSelected fill={mainColors.neutralExtremelyDark} />
          {humanizeString(it)}
        </S.Option>
      ))}
    </S.OptionsRoot>
  )
}

function ComponentWriteMode({
  customField,
  inputProps,
  error,
  warning,
  isReadOnly,
  disabled,
}: IMountComponentProps) {
  if (isReadOnly) return <ComponentReadMode customField={customField} />

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value
    const newValue = inputProps?.value?.includes(selectedValue)
      ? inputProps?.value?.filter((it: string) => it !== selectedValue)
      : [...(inputProps?.value || []), selectedValue]

    inputProps?.onChange({
      target: { value: newValue },
    })
  }

  const parsedOptions =
    (customField.options as unknown as any[])?.map(option => ({
      title: option.title || option,
      value: option.value || option,
      id: option.id || option,
    })) || []

  return (
    <Checkbox.Group>
      {parsedOptions.map(option => (
        <Checkbox.Regular
          {...(inputProps as IInputProps)}
          key={option.id}
          onChange={handleOnChange}
          id={option.id}
          value={option.value}
          title={option.title}
          defaultChecked={inputProps?.value?.includes?.(option.value)}
          error={Boolean(error)}
          warning={Boolean(warning)}
          disabled={disabled}
        />
      ))}
    </Checkbox.Group>
  )
}

const parseValueToShow = (customField: CustomFieldProps) => {
  const value = jsonParseCustomFieldValue(customField)

  return isEmpty(value) ? [] : value
}

const parseValueToSave = (customField: CustomFieldProps) => {
  return isArray(customField.value)
    ? JSON.stringify(customField.value)
    : stringifyValue(customField.value)
}

export default {
  read: { Component: ComponentReadMode },
  write: { Component: ComponentWriteMode },
  parseValueToShow,
  parseValueToSave,
} as CustomFieldTypeReturn
