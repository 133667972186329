import React, { SVGProps } from 'react'

export const IconQuote = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M5.32292 3.33325L2 7.7981V8.01815V9.35148V11.3515C2 12.0868 2.598 12.6848 3.33333 12.6848H6C6.73533 12.6848 7.33333 12.0868 7.33333 11.3515V8.68481C7.33333 7.95015 6.73533 7.35148 6 7.35148H3.99349L6.98568 3.33325H5.32292ZM11.9766 3.33325L8.66667 7.77987V7.99992V9.33325V11.3333C8.66667 12.0686 9.26467 12.6666 10 12.6666H12.6667C13.402 12.6666 14 12.0686 14 11.3333V8.66659C14 7.93125 13.402 7.33325 12.6667 7.33325H10.6602L13.638 3.33325H11.9766Z"
      fill="currentColor"
    />
  </svg>
)
