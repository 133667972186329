import styled from 'styled-components'

import { mainColors } from '../../../styles/colors'

export const IndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 24px;
`

export const IconWrapper = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;

  svg {
    width: 10px;
    height: 6px;
    color: ${({ active }) => (active ? mainColors.neutralExtremelyDark : mainColors.neutralDark)};

    &:hover {
      color: ${({ active }) => (active ? mainColors.black : mainColors.neutralExtremelyDark)};
    }
  }
`
