import { maskArray } from 'react-text-mask'

export enum ToggleInputTypes {
  'money',
  'percentage',
}

export enum ToggleInputPolarity {
  'negative',
  'positive',
}

export interface ToggleAmountTypeInputSizeProps {
  small?: boolean
  $isTiny?: boolean
}

export interface ToggleAmountPolarityProps extends ToggleAmountTypeInputSizeProps {
  polarity: ToggleInputPolarity
  onChange: (inputPolarity: ToggleInputPolarity) => void
}

export interface ToggleAmountTypeProps extends ToggleAmountTypeInputSizeProps {
  type: ToggleInputTypes
  onChange: (inputType: ToggleInputTypes) => void
}

export interface ToggleAmountTypeInputStyleProps extends ToggleAmountTypeInputSizeProps {
  error?: string
  warning?: string
  focus?: boolean
}

export interface ToggleAmountTypeInputProps extends ToggleAmountTypeInputStyleProps {
  className?: string
  type: ToggleInputTypes
  polarity?: ToggleInputPolarity
  hasPolarity?: boolean
  value: string
  mask?: maskArray | ((value: string) => maskArray)
  extraInfo?: React.ReactNode
  setLoading?: (value: boolean) => void
  onTypeChange: (inputType: ToggleInputTypes) => void
  onPolarityChange?: (inputPolarity: ToggleInputPolarity) => void
  onValueChange: (value: string) => void
  onCleanValueClick?: VoidFunction
  debounceTime?: number
  allowEmptyValue?: boolean
  placeholder?: string
}
