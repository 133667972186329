import React, { useEffect, useState } from 'react'

import { Button } from '../../components/Button'
import { FieldHeader } from '../../components/FieldHeader'
import { InfoMessage } from '../../components/InfoMessage'
import { PhoneField } from '../../components/PhoneField'
import { PhoneVerification } from '../../components/PhoneVerification'
import { usePhoneVerification } from '../../hooks/usePhoneVerification'
import * as S from './PhoneFieldWithVerification.styles'
import { PhoneFieldWithVerificationProps } from './PhoneFieldWithVerification.types'

const _PhoneFieldWithVerification = ({
  title = 'Phone',
  restriction,
  value,
  message,
  error,
  showVerifyButton = false,
  onChange,
  onVerified,
}: PhoneFieldWithVerificationProps): JSX.Element => {
  const [showVerification, setShowVerification] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const phoneVerification = usePhoneVerification({
    phone: value,
    onVerified: onVerified,
  })

  const { isPhoneVerified } = phoneVerification

  // Hide phone verification after phone is verified
  if (showVerification && isPhoneVerified) {
    setShowVerification(false)
  }

  // Reset info message after 5 seconds
  useEffect(() => {
    if (isPhoneVerified) {
      setShowMessage(true)

      setTimeout(() => {
        setShowMessage(false)
      }, 5000)
    }
  }, [isPhoneVerified])

  return (
    <S.Root>
      {!showVerification && (
        <FieldHeader.Default title={title} restriction={restriction}>
          <S.Phone>
            <PhoneField
              autoFocus={false}
              copyNumbersOnly={false}
              value={value}
              error={error}
              onChange={onChange}
            />

            {!isPhoneVerified && showVerifyButton && (
              <>
                {message && <InfoMessage message={message} messageType="warning" />}

                <Button.Primary
                  disabled={isPhoneVerified}
                  onClick={e => {
                    e.preventDefault()
                    if (value && value.toString().length > 5) {
                      setShowVerification(true)
                      phoneVerification.sendVerificationCode()
                      setShowErrorMessage(false)
                    } else {
                      setShowErrorMessage(true)
                    }
                  }}
                >
                  Verify my phone
                </Button.Primary>

                {showErrorMessage && (
                  <InfoMessage message="Please fill out the phone number" messageType="error" />
                )}
              </>
            )}
          </S.Phone>
        </FieldHeader.Default>
      )}

      {showVerification && (
        <PhoneVerification
          phone={value}
          restriction={restriction}
          digits={phoneVerification.digits}
          error={phoneVerification.error}
          isEnabled={!phoneVerification.isVerifyingPhoneNumber}
          onChangeCodeDigit={phoneVerification.changeCodeDigit}
          onClickResendCode={phoneVerification.sendVerificationCode}
          onClickEditPhone={() => setShowVerification(false)}
        />
      )}

      {showMessage && (
        <InfoMessage
          message="Your phone was verified successfully."
          messageType="success"
          margin="10px 0 0"
        />
      )}
    </S.Root>
  )
}

const PhoneFieldWithVerification = React.memo(_PhoneFieldWithVerification)

export { PhoneFieldWithVerification }
