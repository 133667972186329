import React from 'react'

import * as S from './ToggleInputType.styles'
import { ToggleTypeProps } from './ToggleInputType.types'

export function ToggleType<InputType>({
  inputType,
  typeOptions,
  small,
  $isTiny,
  onChange,
}: ToggleTypeProps<InputType>) {
  return (
    <S.ToggleContainer small={small} $isTiny={$isTiny}>
      {typeOptions.map((item, index) => (
        <S.ToggleItem
          key={`${index}-${String(item.value)}`}
          active={inputType === item.value}
          small={small}
          $isTiny={$isTiny}
          onClick={() => onChange(item.value)}
        >
          {item.label}
        </S.ToggleItem>
      ))}
    </S.ToggleContainer>
  )
}
