import React, { Ref } from 'react'

function _IconClean(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9789 3.59961C12.178 3.59952 12.3689 3.67855 12.5096 3.81928L16.9903 8.29994C16.9903 8.3 16.9902 8.29988 16.9903 8.29994C17.9813 9.29035 18.6565 10.5526 18.9301 11.9267C19.2038 13.3008 19.0638 14.7253 18.5278 16.0199C17.9918 17.3144 17.084 18.421 15.919 19.1995C14.7541 19.978 13.3844 20.3936 11.9832 20.3936C10.5821 20.3936 9.21242 19.978 8.04747 19.1995C6.88252 18.421 5.97463 17.3144 5.43865 16.0199C4.90266 14.7253 4.76266 13.3008 5.03635 11.9267C5.31 10.5527 5.98495 9.2907 6.97583 8.30032C6.97595 8.30019 6.9757 8.30045 6.97583 8.30032L11.4485 3.81975C11.5891 3.67889 11.7799 3.5997 11.9789 3.59961ZM11.9798 5.41074L15.9298 9.36077C16.711 10.1415 17.2433 11.1365 17.459 12.2197C17.6748 13.3028 17.5644 14.4256 17.1419 15.4461C16.7194 16.4665 16.0038 17.3387 15.0856 17.9524C14.1673 18.566 13.0877 18.8936 11.9832 18.8936C10.8788 18.8936 9.79918 18.566 8.88092 17.9524C7.96267 17.3387 7.24704 16.4665 6.82456 15.4461C6.40208 14.4256 6.29172 13.3028 6.50746 12.2197C6.72319 11.1365 7.25531 10.1416 8.03652 9.36095L11.9798 5.41074Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconClean = React.forwardRef(_IconClean)
