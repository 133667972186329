import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconInfoSquare = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833328 3.33301C0.833328 1.9523 1.95262 0.833008 3.33333 0.833008H16.6667C18.0474 0.833008 19.1667 1.9523 19.1667 3.33301V16.6663C19.1667 18.0471 18.0474 19.1663 16.6667 19.1663H3.33333C1.95262 19.1663 0.833328 18.0471 0.833328 16.6663V3.33301ZM3.33333 2.49967C2.87309 2.49967 2.49999 2.87277 2.49999 3.33301V16.6663C2.49999 17.1266 2.87309 17.4997 3.33333 17.4997H16.6667C17.1269 17.4997 17.5 17.1266 17.5 16.6663V3.33301C17.5 2.87277 17.1269 2.49967 16.6667 2.49967H3.33333ZM10 9.16634C10.4602 9.16634 10.8333 9.53944 10.8333 9.99967V13.7497C10.8333 14.2099 10.4602 14.583 10 14.583C9.53976 14.583 9.16666 14.2099 9.16666 13.7497V9.99967C9.16666 9.53944 9.53976 9.16634 10 9.16634ZM10 7.49967C10.4602 7.49967 10.8333 7.12658 10.8333 6.66634C10.8333 6.2061 10.4602 5.83301 10 5.83301C9.53976 5.83301 9.16666 6.2061 9.16666 6.66634C9.16666 7.12658 9.53976 7.49967 10 7.49967Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconInfoSquare = forwardRef(_IconInfoSquare)
