import React, { Ref } from 'react'

function _IconCopy(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6C8 5.44772 8.44772 5 9 5H18C18.5523 5 19 5.44772 19 6V15C19 15.5523 18.5523 16 18 16V9C18 7.34315 16.6569 6 15 6H8ZM6 6C6 4.34315 7.34315 3 9 3H18C19.6569 3 21 4.34315 21 6V15C21 16.6569 19.6569 18 18 18C18 19.6569 16.6569 21 15 21H6C4.34315 21 3 19.6569 3 18V9C3 7.34315 4.34315 6 6 6ZM5 9C5 8.44772 5.44772 8 6 8H15C15.5523 8 16 8.44772 16 9V18C16 18.5523 15.5523 19 15 19H6C5.44772 19 5 18.5523 5 18V9Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconCopy = React.forwardRef(_IconCopy)
