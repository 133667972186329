import { getPeriodOption, PeriodPresets } from './dateRange'

export const periodPresetOptions = [
  PeriodPresets.TODAY,
  PeriodPresets.PREV_7_DAYS,
  PeriodPresets.PREV_30_DAYS,
  PeriodPresets.PREV_90_DAYS,
  PeriodPresets.PREV_6_MONTHS,
  PeriodPresets.PREV_12_MONTHS,
].map(getPeriodOption)
