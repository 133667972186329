import * as validate from 'validate.js'

import { formatDate } from '@avantstay/backoffice-core'

validate.extend(validate.validators.datetime, {
  parse(value: any) {
    return formatDate(value, 'x')
  },
  format(value: any, options: any) {
    return formatDate(
      value,
      options.dateOnly ? 'yyyy-MM-dd' : options.timeOnly ? 'hh:mm:ss' : 'yyyy-MM-dd hh:mm:ss',
    )
  },
})
