import React, { FC, PropsWithChildren } from 'react'

import { IconMinus, IconPlus } from '@avantstay/backoffice-vectors'

import * as S from './ZoomControllers.styles'

export enum ZoomControllerTheme {
  primaryHorizontal = 'PRIMARY_HORIZONTAL',
  neutralVertical = 'NEUTRAL_VERTICAL',
}

interface ZoomControllerProps {
  theme?: ZoomControllerTheme
  onClickZoomIn: VoidFunction
  onClickZoomOut: VoidFunction
}

export const ZoomControllers: FC<PropsWithChildren<ZoomControllerProps>> = ({
  theme = ZoomControllerTheme.primaryHorizontal,
  onClickZoomIn,
  onClickZoomOut,
}) => {
  return (
    <S.ButtonGroup theme={theme}>
      <S.ButtonFlat type="button" onClick={onClickZoomOut}>
        <IconMinus />
      </S.ButtonFlat>
      <S.ButtonFlat type="button" onClick={onClickZoomIn}>
        <IconPlus />
      </S.ButtonFlat>
    </S.ButtonGroup>
  )
}
