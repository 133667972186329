import React, { Ref } from 'react'

function _IconAvantStayCircle(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 518 518" fill="none" {...props} ref={ref}>
      <circle cx="259" cy="259" r="259" fill="#02254A" />
      <path
        d="M217.364 289.553c2.442-.615 3.432-.791 4.382-1.103 24.607-8.227 49.334-16.054 74.42-22.692 5.108-1.35 6.106-2.995 3.791-7.908-10.336-22.029-20.305-44.226-30.482-66.327-.71-1.55-1.316-3.331-2.985-3.331-1.676 0-2.195 1.749-2.873 3.179-7.175 15.16-14.295 30.344-21.439 45.512-8.085 17.165-16.178 34.33-24.814 52.67zm152.871 106.32c-1.684 0-3.512-.056-5.659-1.246-2.802-1.549-3.991-4.361-4.55-5.591-16.258-35.784-32.86-71.4-48.99-107.239-2.427-5.392-4.678-6.869-10.48-5.064-17.169 5.343-34.896 8.842-51.713 15.376-15.476 6.006-16.729 10.982-3.24 20.527 5.507 3.898 10.926 7.972 15.62 12.876 9.793 10.232 9.186 20.2-2.291 28.539-25.517 18.523-54.506 28.635-85.275 34.059-15.78 2.787-31.663 3.77-47.61 1.262-6.186-.967-12.5-2.309-15.141-8.819-2.618-6.437.933-11.669 4.381-16.989 7.024-10.839 18.574-9.896 28.99-11.486 3.503-.535 4.286 2.772 2.178 5.544-2.091 2.763-4.828 5.04-6.88 7.819-4.238 5.727-3.16 8.659 3.879 10.288 7.056 1.63 14.311 1.342 21.399.447 19.084-2.404 37.274-7.596 53.054-19.201 8.747-6.43 8.731-10.672-.048-16.838-7.527-5.296-15.317-10.232-22.844-15.536-7.191-5.072-8.684-10.982-5.228-18.65a224457.83 224457.83 0 0057.747-128.215c7.223-16.046 14.455-32.101 21.566-48.204 1.852-4.169 3.943-7.556 9.235-7.532 5.204.024 7.495 3.243 9.41 7.412 27.808 60.665 55.672 121.298 83.512 181.938 11.7 25.488 23.393 50.976 35.055 76.48 1.564 3.427 2.115 5.487 1.34 6.678-1.045 1.605-3.727 1.525-6.632 1.469-3.592-.064-8.956-.12-15.963-.008-4.909-.096-9.913 0-14.822-.096z"
        fill="#fff"
      />
    </svg>
  )
}

const IconAvantStayCircle = React.memo(React.forwardRef(_IconAvantStayCircle))

export { IconAvantStayCircle }
