import { ChevronLeft, ChevronRight } from 'react-feather'
import styled, { css } from 'styled-components'

import { IconCalendar } from '@avantstay/backoffice-vectors'

import { mainColors } from '../../../styles/colors'
import { medias } from '../../../styles/medias'
import { _L_Medium, _L_Normal, _M_Medium, _S_Normal } from '../../../styles/typography'
import { zIndex } from '../../../styles/zIndex'

export const Selector = styled.div<{ isSelected?: boolean }>`
  background: ${mainColors.white};
  border: 2px solid
    ${({ isSelected }) => (isSelected ? mainColors.accentMedium : mainColors.neutralMedium)};
  padding: 12px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-height: 48px;

  ${medias.SM_SCREEN`
    display: grid;
    grid-template-columns: repeat(2, max-content);
    column-gap: 20px;
  `}
`

export const Label = styled.span`
  color: ${mainColors.neutralExtremelyDark};
  ${_S_Normal};
`

export const SelectionContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Selection = styled.span`
  color: ${mainColors.black};
  ${_S_Normal};
  margin-right: 8px;
`

export const IconCalendarStyled = styled(IconCalendar)`
  color: ${mainColors.neutralDark};
`

export const IconChevronLeftStyled = styled(ChevronLeft)`
  color: ${mainColors.neutralExtraDark};
`

export const IconChevronRightStyled = styled(ChevronRight)`
  color: ${mainColors.neutralExtraDark};
`

const linkInteraction = css`
  cursor: pointer;
  color: ${mainColors.neutralExtraDark};

  &:hover {
    color: ${mainColors.neutralExtremelyDark};
  }

  &:active {
    color: ${mainColors.accentMedium};
  }
`

export const ChevronLink = styled.div<{ disabled?: boolean }>`
  background: transparent;
  height: 24px;
  color: ${mainColors.neutralMedium};

  ${({ disabled }) => (!disabled ? linkInteraction : '')}
`

export const Picker = styled.div`
  position: absolute;
  z-index: ${zIndex.FILTERS};
  border: 1px solid ${mainColors.neutralMedium};
  background: ${mainColors.white};
  margin-top: 6px;
`

export const YearPicker = styled.div`
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
`

export const SelectedYear = styled.span`
  color: ${mainColors.black};
  ${_L_Medium};
`

export const MonthGrid = styled.div`
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 2px;
`

export const Month = styled.div<{ isSelected?: boolean }>`
  padding: 10px 20px;
  color: ${mainColors.neutralExtremelyDark};
  ${_L_Normal};
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    `
    background: ${mainColors.accentExtremelyLight};
    color: ${mainColors.accentMedium};
  `}
`

export const Divider = styled.hr`
  margin: 20px 16px 10px;
  border: 0;
  border-top: 1px solid ${mainColors.neutralMedium};
`

export const ClearButton = styled.button`
  color: ${mainColors.accentMedium};
  ${_M_Medium};
  margin: 0 0 16px 16px;
  border: none;
  background: none;
  cursor: pointer;
`
