import React from 'react'

import * as S from './Pill.styles'
import { PillProps } from './Pill.types'

export const Pill = React.forwardRef(
  ({ children, pillColor, className, interactive, styles, onClick, dataCy }: PillProps, ref) => (
    <S.PillDefault
      pillColor={pillColor}
      interactive={interactive}
      className={className}
      style={{ ...styles }}
      // @ts-ignore https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
      ref={ref}
      onClick={onClick}
      data-cy={dataCy}
    >
      {children}
    </S.PillDefault>
  ),
)
