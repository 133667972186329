import React from 'react'

function _IconResizer(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49478 12.495L12.4948 4.49502C12.7681 4.22166 12.7681 3.77844 12.4948 3.50507C12.2214 3.23171 11.7782 3.23171 11.5048 3.50507L3.50483 11.5051C3.23146 11.7784 3.23146 12.2217 3.50483 12.495C3.7782 12.7684 4.22141 12.7684 4.49478 12.495ZM9.49478 12.495L12.4948 9.49502C12.7681 9.22166 12.7681 8.77844 12.4948 8.50507C12.2214 8.23171 11.7782 8.23171 11.5048 8.50507L8.50483 11.5051C8.23146 11.7784 8.23146 12.2217 8.50483 12.495C8.7782 12.7684 9.22141 12.7684 9.49478 12.495ZM12.4998 4.00005C12.4998 4.12799 12.451 4.25593 12.3534 4.35355L4.35336 12.3536C4.15809 12.5488 3.84151 12.5488 3.64625 12.3536C3.56678 12.2741 3.51965 12.1745 3.50487 12.0712C3.51964 12.1745 3.56677 12.2741 3.64625 12.3536C3.84151 12.5489 4.1581 12.5489 4.35336 12.3536L12.3534 4.3536C12.451 4.25597 12.4998 4.12801 12.4998 4.00005ZM12.4998 9.00005C12.4998 9.12799 12.451 9.25593 12.3534 9.35355L9.35335 12.3536C9.15809 12.5488 8.84151 12.5488 8.64625 12.3536C8.57456 12.2819 8.52918 12.1938 8.51013 12.1014C8.52917 12.1938 8.57455 12.2819 8.64625 12.3536C8.84151 12.5489 9.1581 12.5489 9.35336 12.3536L12.3534 9.3536C12.451 9.25597 12.4998 9.12801 12.4998 9.00005Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconResizer = React.memo(React.forwardRef(_IconResizer))

export { IconResizer }
