import {
  CustomFieldSectionProjection,
  OnlineTravelAgency,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import {
  customFieldsSectionIdList,
  propertyListingAndDistributionCustomFieldsSections,
} from '@new/domains/properties/constants'
import { DistributionChannelsSubSection } from '@new/domains/properties/screens/propertyDistributionChannels/DistributionChannels.types'

import {
  HomesDistributions,
  HomesDistributionsAndListings,
  HomesListings,
} from '@arriere/types/Properties.types'
import { formatOTAName } from '@utils/formatSectionName'

export const PETS_CUSTOM_FIELD_ID = 'b0d57417-4141-11ea-b6d1-e9276cc1d34f'
export const GUEST_RESTRICTIONS_CUSTOM_FIELD_ID = 'e95b937a-fe83-4407-a70e-c032d715d283'

export const DISTRIBUTION_CHANNELS = [
  OnlineTravelAgency.airbnb,
  OnlineTravelAgency.rentalsUnited,
  OnlineTravelAgency.vrbo,
]

export const DISTRIBUTION_SUB_SECTIONS = {
  AIRBNB: [
    {
      label: 'Listing Description',
      subSection: DistributionChannelsSubSection.listingDescription,
    },
    {
      label: 'Listing Info',
      subSection: DistributionChannelsSubSection.listingInfo,
    },
    {
      label: 'Property Details',
      subSection: DistributionChannelsSubSection.propertyDetails,
    },
    {
      label: 'Guest Controls',
      subSection: DistributionChannelsSubSection.guestControls,
    },
    {
      label: 'Trip Issues',
      subSection: DistributionChannelsSubSection.tripIssues,
    },
  ],
  RENTALS_UNITED: [
    {
      label: 'Listing Description',
      subSection: DistributionChannelsSubSection.listingDescription,
    },
  ],
  VRBO: [
    {
      label: 'Listing Description',
      subSection: DistributionChannelsSubSection.listingDescription,
    },
  ],
}

interface GetSortedDistributionChannelsProps {
  distributionChannels: OnlineTravelAgency[]
  homeDistributionsAndListings?: HomesDistributionsAndListings
}

export interface DistributionChannelProps {
  currentDistribution?: HomesDistributions[0]
  currentListing?: HomesListings[0]
  label: string
  ota: OnlineTravelAgency
}

export const getSortedDistributionChannels = ({
  distributionChannels,
  homeDistributionsAndListings,
}: GetSortedDistributionChannelsProps): DistributionChannelProps[] => {
  const formattedDistributionChannels = distributionChannels.reduce(
    (
      accDistributionChannel: DistributionChannelProps[],
      currentDistributionChannel: OnlineTravelAgency,
    ) => {
      const currentDistribution = homeDistributionsAndListings?.distributions?.find(
        homeDistribution => homeDistribution.agencyAccount.agency === currentDistributionChannel,
      )

      const currentListing = homeDistributionsAndListings?.listings?.find(
        homeListing => homeListing.agencyAccount?.agency === currentDistributionChannel,
      )

      return [
        ...accDistributionChannel,
        {
          currentDistribution,
          currentListing,
          label: formatOTAName(currentDistributionChannel),
          ota: currentDistributionChannel,
        },
      ]
    },
    [],
  )

  const sortedDistributionChannels = formattedDistributionChannels.sort(
    (distributionChannelA, distributionChannelB) => {
      if (
        distributionChannelA.currentListing?.active &&
        distributionChannelB.currentListing?.active
      ) {
        return 0
      }

      if (distributionChannelA.currentListing?.active) {
        return -1
      }

      return 1
    },
  )

  return sortedDistributionChannels
}

export const listingAndDistributionSectionsIds =
  propertyListingAndDistributionCustomFieldsSections.map(
    section => customFieldsSectionIdList[section],
  )

export const onlyListingAndDistributionSections = <T extends CustomFieldSectionProjection>(
  customFields: T,
) =>
  [
    ...listingAndDistributionSectionsIds,
    PETS_CUSTOM_FIELD_ID,
    GUEST_RESTRICTIONS_CUSTOM_FIELD_ID,
  ].includes(customFields?.section.id)
