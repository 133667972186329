import React, { Ref } from 'react'

function _IconClose(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4243 5.42417C11.6586 5.18985 11.6586 4.80995 11.4243 4.57564C11.19 4.34132 10.8101 4.34132 10.5758 4.57564L8.00002 7.15137L5.42429 4.57564C5.18997 4.34132 4.81007 4.34132 4.57576 4.57564C4.34145 4.80995 4.34145 5.18985 4.57576 5.42417L7.1515 7.9999L4.57576 10.5756C4.34145 10.81 4.34145 11.1899 4.57576 11.4242C4.81007 11.6585 5.18997 11.6585 5.42429 11.4242L8.00002 8.84843L10.5758 11.4242C10.8101 11.6585 11.19 11.6585 11.4243 11.4242C11.6586 11.1899 11.6586 10.81 11.4243 10.5756L8.84855 7.9999L11.4243 5.42417Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconClose = React.forwardRef(_IconClose)
