import {
  BookingPaymentBalanceStatus,
  BookingStatus,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

// eslint-disable-next-line import/no-cycle
import { colorsByPeriodSectionKind } from '@new/__global/components/TapeChart/PeriodSection/PeriodSection.constants'
// eslint-disable-next-line import/no-cycle
import { PeriodSectionKind } from '@new/__global/components/TapeChart/PeriodSection/PeriodSection.types'

export const API_MAX_USAGE_INFINITY_VALUE = 99999

export const tableConstants = {
  HOME_COLUMN_WIDTH: '293px',
  HOME_COLUMN_WIDTH_MOBILE: '243px',
  HOME_COLUMN_MAX_WIDTH: '500px',
  HOME_COLUMN_MIN_WIDTH: '90px',
  HOME_COLUMN_PADDING_LEFT: '30px',
  HOME_COLUMN_PADDING_LEFT_MOBILE: '16px',
  PRICING_TAPE_CHART_HOME_COLUMN_PADDING_LEFT: '8PX',
  PTC_HOME_COLUMN_WIDTH: '330px',
}

export interface LegendOption<T = string> {
  label: string
  value: T
  dot: string
  rowStart?: number
}

export const bookingReservationStatusOptions: LegendOption<BookingStatus>[] = [
  {
    label: 'Booked',
    value: BookingStatus.booked,
    dot: colorsByPeriodSectionKind[PeriodSectionKind.booked].default.color,
  },
  {
    label: 'Checked-In',
    value: BookingStatus.checkedIn,
    dot: colorsByPeriodSectionKind[PeriodSectionKind.checkedIn].default.color,
  },
  {
    label: 'Checked-Out',
    value: BookingStatus.checkedOut,
    dot: colorsByPeriodSectionKind[PeriodSectionKind.checkedOut].default.color,
  },
]

export const bookingPaymentOptions: LegendOption<BookingPaymentBalanceStatus>[] = [
  {
    label: 'Fully Paid',
    value: BookingPaymentBalanceStatus.fullyPaid,
    dot: colorsByPeriodSectionKind[PeriodSectionKind.paid].default.color,
  },
  {
    label: 'Not Paid',
    value: BookingPaymentBalanceStatus.notPaid,
    dot: colorsByPeriodSectionKind[PeriodSectionKind.block].default.color,
  },
  {
    label: 'Over Paid',
    value: BookingPaymentBalanceStatus.overPaid,
    dot: colorsByPeriodSectionKind[PeriodSectionKind.overPaid].default.color,
  },
  {
    label: 'Partially Paid',
    value: BookingPaymentBalanceStatus.partlyPaid,
    dot: colorsByPeriodSectionKind[PeriodSectionKind.partiallyPaid].default.color,
  },
]
