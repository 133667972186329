import React, { Ref } from 'react'

function _IconFile(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 54 54" fill="none" {...props} ref={ref}>
      <path
        d="M8 51V2C8 1.44772 8.44771 1 9 1H36L50 16V51C50 51.5523 49.5523 52 49 52H9C8.44772 52 8 51.5523 8 51Z"
        fill="#F4F4F4"
      />
      <path d="M36 15V1L50 16H37C36.4477 16 36 15.5523 36 15Z" fill="#E3E3E3" />
    </svg>
  )
}

export const IconFile = React.forwardRef(_IconFile)
