// @ts-nocheck
import React, {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  Ref,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'

import { IndicatorsTooltip } from '../../../components/IndicatorsTooltip'
import { TextAlign } from '../../../enums/common'
import { IndicatorDocProps } from '../../IndicatorsTooltip/IndicatorsTooltip.types'
import * as S from './CustomHeader.styles'

interface CustomHeaderProps extends HTMLAttributes<HTMLDivElement> {
  label: string | ReactNode
  subtitle?: ReactNode
  documentationKey?: string
  tooltipVariables?: { [key: string]: any }
  tooltipIndicator?: IndicatorDocProps
  isSortedBy?: boolean
  alignItems?: string
  justifyContent?: string
  containerWidth?: string
  textAlign?: TextAlign
}

const _CustomHeader = (
  {
    label,
    subtitle,
    children,
    isSortedBy,
    tooltipVariables,
    documentationKey,
    tooltipIndicator,
    alignItems,
    justifyContent,
    containerWidth,
    textAlign,
    ...params
  }: CustomHeaderProps,
  ref?: Ref<HTMLDivElement>,
): JSX.Element => {
  const [labelWidth, setLabelWidth] = useState<number | undefined>()
  const labelRef = useRef<HTMLDivElement>()

  useEffect(() => setLabelWidth(labelRef.current?.clientWidth), [])

  const renderLabelContent = (content: ReactNode) => {
    if (documentationKey) {
      return (
        <IndicatorsTooltip
          title={label}
          documentationKey={documentationKey}
          variables={tooltipVariables}
          labelWidth={labelWidth}
          indicatorDocs={tooltipIndicator}
        >
          {content}
        </IndicatorsTooltip>
      )
    }

    return content
  }

  return (
    <S.Container
      ref={ref}
      justifyContent={justifyContent}
      alignItems={alignItems}
      width={containerWidth}
      textAlign={textAlign}
      {...params}
    >
      <S.GridLabel isSortedBy={isSortedBy} ref={labelRef as RefObject<HTMLDivElement>}>
        {renderLabelContent(
          <>
            {label}
            {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
          </>,
        )}
      </S.GridLabel>

      {children && <S.Children>{children}</S.Children>}
    </S.Container>
  )
}

export const CustomHeader = forwardRef(_CustomHeader)
