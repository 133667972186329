import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconSuperhost(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.05263 1.47363C4.47128 1.47363 4 1.94491 4 2.52626V6.39899C4 6.75322 4.17816 7.08369 4.47409 7.27838L7.42146 9.21744C7.77257 9.44843 8.22743 9.44843 8.57854 9.21744L11.5259 7.27838C11.8218 7.08369 12 6.75322 12 6.39899V2.52626C12 1.94491 11.5287 1.47363 10.9474 1.47363H5.05263ZM5.26316 6.2855V2.73679H10.7368V6.2855L8 8.08605L5.26316 6.2855ZM6.94734 12.2105C6.94734 11.6292 7.41862 11.1579 7.99997 11.1579C8.58132 11.1579 9.0526 11.6292 9.0526 12.2105C9.0526 12.7919 8.58132 13.2631 7.99997 13.2631C7.41862 13.2631 6.94734 12.7919 6.94734 12.2105ZM7.99997 9.89473C6.72099 9.89473 5.68418 10.9315 5.68418 12.2105C5.68418 13.4895 6.72099 14.5263 7.99997 14.5263C9.27894 14.5263 10.3158 13.4895 10.3158 12.2105C10.3158 10.9315 9.27894 9.89473 7.99997 9.89473Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconSuperhost = forwardRef(_IconSuperhost)
