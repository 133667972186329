export enum HomeInfoState {
  Opaque = 'opaque',
  Normal = 'normal',
  Active = 'active',
}

export interface HomeInfoProps {
  state?: HomeInfoState
  thumbnail?: string
  title: string
  avatarSize?: string
  region: string
  url?: string
  maxWidth?: number
  isLarge?: boolean
}
