import React, { Ref } from 'react'

function _IconMenuBg(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 220 64" fill="none" {...props} ref={ref}>
      <path
        d="M0 18C0 14.6863 2.68629 12 6 12H205.834C212.855 12 218.846 6.92466 220 0V64C218.846 57.0753 212.855 52 205.834 52H6C2.68629 52 0 49.3137 0 46V18Z"
        fill="white"
      />
    </svg>
  )
}

export const IconMenuBg = React.forwardRef(_IconMenuBg)
