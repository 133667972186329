import React from 'react'

export interface ToggleFieldProps {
  children?: React.ReactNode[]
  autofill?: boolean
  className?: string
}

export interface ToggleOptionsProps extends ToggleFieldLabelProps {
  id: string
  title: string
  defaultChecked?: boolean
  className?: string
}

export interface ToggleSwitchInputProps extends ToggleFieldLabelProps {
  id: string
  title: string
  defaultChecked?: boolean
  className?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  label?: string
}

export enum ToggleBooleanType {
  true,
  false,
}

export interface ToggleFieldLabelProps {
  value: any
  checked?: boolean
  toggleType?: ToggleBooleanType
  fill?: boolean
}

export interface ToggleInputProps {
  toggleType?: ToggleBooleanType
  fill?: boolean
}
