import React, { Ref } from 'react'

function _IconCustomFields(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V14C21 13.4477 20.5523 13 20 13H4C3.44772 13 3 13.4477 3 14L3 18ZM19 17H5V15H19V17ZM3 10C3 10.5523 3.44772 11 4 11H20C20.5523 11 21 10.5523 21 10V6C21 5.44772 20.5523 5 20 5L4 5C3.44772 5 3 5.44772 3 6L3 10ZM19 9L5 9L5 7L19 7V9Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconCustomFields = React.forwardRef(_IconCustomFields)
