import React, { Ref } from 'react'

function _IconTime(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.6998 7.9998C2.6998 5.0727 5.0727 2.6998 7.9998 2.6998C10.9269 2.6998 13.2998 5.0727 13.2998 7.9998C13.2998 10.9269 10.9269 13.2998 7.9998 13.2998C5.0727 13.2998 2.6998 10.9269 2.6998 7.9998ZM7.9998 1.2998C4.2995 1.2998 1.2998 4.2995 1.2998 7.9998C1.2998 11.7001 4.2995 14.6998 7.9998 14.6998C11.7001 14.6998 14.6998 11.7001 14.6998 7.9998C14.6998 4.2995 11.7001 1.2998 7.9998 1.2998ZM8.6998 4.9998C8.6998 4.61321 8.3864 4.2998 7.9998 4.2998C7.61321 4.2998 7.2998 4.61321 7.2998 4.9998V8.7498C7.2998 8.99116 7.42414 9.21549 7.62881 9.3434L9.62881 10.5934C9.95664 10.7983 10.3885 10.6986 10.5934 10.3708C10.7983 10.043 10.6986 9.6111 10.3708 9.40621L8.6998 8.36183V4.9998Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconTime = React.forwardRef(_IconTime)
