import React, { Ref } from 'react'

function _IconBed(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2234 6.21122V17.4993H15.7834V6.21122H8.2234ZM6.7834 6.0701V17.4993H5.7034C5.30575 17.4993 4.9834 17.8152 4.9834 18.2049C4.9834 18.5945 5.30575 18.9104 5.7034 18.9104H7.4588C7.47355 18.9113 7.48842 18.9118 7.5034 18.9118H16.5034C16.5184 18.9118 16.5332 18.9113 16.548 18.9104H18.3034C18.701 18.9104 19.0234 18.5945 19.0234 18.2049C19.0234 17.8152 18.701 17.4993 18.3034 17.4993H17.2234V6.0701C17.2234 5.3418 16.6044 4.80005 15.9034 4.80005H8.1034C7.40237 4.80005 6.7834 5.3418 6.7834 6.0701ZM14.1031 12.7281C14.6002 12.7281 15.0031 12.3332 15.0031 11.8461C15.0031 11.359 14.6002 10.9641 14.1031 10.9641C13.6061 10.9641 13.2031 11.359 13.2031 11.8461C13.2031 12.3332 13.6061 12.7281 14.1031 12.7281Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconBed = React.forwardRef(_IconBed)
