import React from 'react'

function _BaseMarker(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="26" height="32" viewBox="0 0 26 32" fill="none" {...props} ref={ref}>
      <path
        d="M25.5 13.2727C25.5 21.6231 15.934 28.93 13.5387 30.6287C13.2144 30.8586 12.7856 30.8586 12.4613 30.6287C10.066 28.93 0.5 21.6231 0.5 13.2727C0.5 10.0178 1.81696 6.89618 4.16116 4.5946C6.50537 2.29302 9.68479 1 13 1C16.3152 1 19.4946 2.29302 21.8388 4.5946C24.183 6.89618 25.5 10.0178 25.5 13.2727Z"
        fill="currentColor"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconBaseMarker = React.forwardRef(_BaseMarker)
