import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconAsset(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="43" height="54" viewBox="0 0 43 54" fill="none" {...props} ref={ref}>
      <path
        d="M0.588379 52.4706V1.52942C0.588379 0.977134 1.03609 0.529419 1.58838 0.529419H28.8237L42.9413 16.4118V52.4706C42.9413 53.0229 42.4936 53.4706 41.9413 53.4706H1.58838C1.03609 53.4706 0.588379 53.0229 0.588379 52.4706Z"
        fill="#F2F5F7"
      />
      <path
        d="M28.8237 15.4118V0.529419L42.9414 16.4118H29.8237C29.2714 16.4118 28.8237 15.9641 28.8237 15.4118Z"
        fill="#E9EEF1"
      />
    </svg>
  )
}

export const IconAsset = forwardRef(_IconAsset)
