import { AssetWithDocumentProps, FileWithError } from '@new/__ui/UploadArea/hooks/useManageAssets'

import { FilePreviewType } from './_types'
import { FilePreviewMode } from './FilePreview/_enums'

interface GetFilesWithErrorsIndexesProps {
  files: AssetWithDocumentProps[]
  filesWithError: FileWithError
}

export const getFilesWithErrorsIndexes = ({
  files,
  filesWithError,
}: GetFilesWithErrorsIndexesProps) => {
  const filesWithErrorsIndexes = files.reduce(
    (fileIndexes: string[], currentFile: AssetWithDocumentProps, index: number) => {
      const fileHasError = Object.keys(filesWithError).includes(currentFile.id)
      if (fileHasError) {
        return [...fileIndexes, `#${index}`]
      }

      return fileIndexes
    },
    [],
  )

  return filesWithErrorsIndexes
}

export const getFileSizeByType = (
  filePreviewType?: FilePreviewType,
  viewMode?: FilePreviewMode,
) => {
  if (filePreviewType === FilePreviewType.photoGallery) {
    return viewMode === FilePreviewMode.list
      ? {
          width: 212,
          height: 212,
        }
      : {
          width: 168,
          height: 188,
        }
  }

  return {
    width: 104,
    height: 104,
  }
}

export const getPreviewTitleByType = (filePreviewType?: FilePreviewType) => {
  if (filePreviewType === FilePreviewType.photoGallery) return 'Photo Gallery'

  return 'Files'
}
