import React from 'react'

import { CustomFieldKind } from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { TextField } from '@avantstay/backoffice-core'

// eslint-disable-next-line import/no-cycle
import { jsonParseCustomFieldValue } from '@utils/customFields'

import {
  CustomFieldProps,
  CustomFieldTypeReturn,
  IInputProps,
  IMountComponentProps,
} from '../_types'
import * as S from './types.styles'

const parseValueToShow = (customField: CustomFieldProps) => {
  return jsonParseCustomFieldValue(customField)
}

function ComponentReadMode({ customField }: IMountComponentProps) {
  return <S.Text>{parseValueToShow(customField)}</S.Text>
}

function ComponentWriteMode({
  inputProps,
  error,
  warning,
  customField,
  isReadOnly,
  disabled,
}: IMountComponentProps) {
  if (isReadOnly) return <ComponentReadMode customField={customField} />

  return (
    <TextField.Default
      {...(inputProps as IInputProps)}
      error={error}
      warning={warning}
      disabled={disabled}
    />
  )
}

const parseValueToSave = (customField: CustomFieldProps) => {
  if (!customField.value && customField.kind === CustomFieldKind.duration)
    return JSON.stringify(null)

  return JSON.stringify(customField.value)
}

export default {
  read: { Component: ComponentReadMode },
  write: { Component: ComponentWriteMode },
  parseValueToShow,
  parseValueToSave,
} as CustomFieldTypeReturn
