import React, { Ref } from 'react'

function _IconUndo(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49494 1.49478C5.76831 1.22141 5.76831 0.778197 5.49494 0.50483C5.22158 0.231463 4.77836 0.231463 4.505 0.50483L1.14642 3.86341C0.794944 4.21488 0.794945 4.78473 1.14642 5.1362L4.505 8.49478C4.77836 8.76815 5.22158 8.76815 5.49494 8.49478C5.76831 8.22141 5.76831 7.7782 5.49494 7.50483L3.18992 5.1998H9.00005C11.3749 5.1998 13.3 7.12498 13.3 9.4998C13.3 11.8746 11.3749 13.7998 9.00005 13.7998H2.00005C1.61345 13.7998 1.30005 14.1132 1.30005 14.4998C1.30005 14.8864 1.61345 15.1998 2.00005 15.1998H9.00005C12.1481 15.1998 14.7 12.6478 14.7 9.4998C14.7 6.35178 12.1481 3.7998 9.00005 3.7998H3.18992L5.49494 1.49478Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconUndo = React.forwardRef(_IconUndo)
