import { contains } from '../../../utils/strings'
import { OptionProps, OptionsGroup, SelectFieldProps } from '../SelectField.types'

export function filterOptionsByInput<OptionValueType>(
  options: OptionProps<OptionValueType>[],
  inputValue: string,
  filterOption?: SelectFieldProps<OptionValueType>['filterOption'],
) {
  if (filterOption) {
    return options.filter(option => filterOption(option, inputValue))
  }

  return options.filter(option => {
    if (option.value && contains(option.value.toString(), inputValue)) {
      return true
    }
    if (option.label && typeof option.label === 'string' && contains(option.label, inputValue)) {
      return true
    }
    return false
  })
}

export function hasOptionsGrouping<OptionValueType>(
  optionsOrGroups: OptionProps<OptionValueType>[] | OptionsGroup<OptionValueType>[],
): optionsOrGroups is OptionsGroup<OptionValueType>[] {
  return optionsOrGroups.length > 0 && Object.hasOwn(optionsOrGroups[0], 'options')
}
