export const indicatorInfo = {
  granularity: true,
  aggregation: true,
  mostRecentValue: true,
  aggregatedValue: true,
  period: {
    start: true,
    end: true,
  },
  variation: true,
  values: {
    value: true,
    date: true,
  },
}

export const basicIndicatorInfo = {
  mostRecentValue: true,
  aggregatedValue: true,
  variation: true,
}
