import { useEffect, useState } from 'react'

import { MultiSelectFieldStyledProps, ValueType } from './SelectField.types'

export function isMultiValue<OptionValueType>(
  value: ValueType<OptionValueType>,
  isMulti?: boolean,
): value is MultiSelectFieldStyledProps<OptionValueType>['value'] {
  return Boolean(isMulti) && Array.isArray(value)
}

export const useDidMount = () => {
  const [didMount, setDidMount] = useState(false)
  useEffect(() => {
    setDidMount(true)
  }, [])

  return didMount
}
