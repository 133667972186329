import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconStarFilled = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.44902 1.11267C8.36478 0.942021 8.19098 0.833984 8.00067 0.833984C7.81036 0.833984 7.63655 0.942021 7.55231 1.11267L5.60853 5.05055L1.26168 5.68591C1.0734 5.71343 0.917065 5.84543 0.858376 6.02643C0.799687 6.20744 0.848823 6.40607 0.985133 6.53883L4.13007 9.602L3.38786 13.9295C3.35569 14.1171 3.43281 14.3066 3.5868 14.4185C3.74079 14.5304 3.94493 14.5451 4.11339 14.4565L8.00067 12.4122L11.8879 14.4565C12.0564 14.5451 12.2605 14.5304 12.4145 14.4185C12.5685 14.3066 12.6456 14.1171 12.6135 13.9295L11.8713 9.602L15.0162 6.53883C15.1525 6.40607 15.2016 6.20744 15.143 6.02643C15.0843 5.84543 14.9279 5.71343 14.7396 5.68591L10.3928 5.05055L8.44902 1.11267Z"
      fill="currentColor"
    />
  </svg>
)

export const IconStarFilled = forwardRef(_IconStarFilled)
