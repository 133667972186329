import React from 'react'

import { PhoneField } from '@avantstay/backoffice-core'

// eslint-disable-next-line import/no-cycle
import { jsonParseCustomFieldValue } from '@utils/customFields'

import {
  CustomFieldProps,
  CustomFieldTypeReturn,
  IInputProps,
  IMountComponentProps,
} from '../_types'
import { stringifyValue } from '../CustomField.utils'
import * as S from './types.styles'

const parseValueToShow = (customField: CustomFieldProps) => {
  return jsonParseCustomFieldValue(customField)
}

const parseValueToSave = (customField: CustomFieldProps) => {
  return stringifyValue(customField.value)
}

function ComponentReadMode({ customField }: IMountComponentProps) {
  return <S.Text>{parseValueToShow(customField)}</S.Text>
}

function ComponentWriteMode({
  inputProps,
  error,
  warning,
  customField,
  isReadOnly,
  disabled,
}: IMountComponentProps) {
  if (isReadOnly) return <ComponentReadMode customField={customField} />

  return (
    <PhoneField
      {...(inputProps as IInputProps)}
      error={error}
      warning={warning}
      disabled={disabled}
    />
  )
}

export default {
  read: { Component: ComponentReadMode },
  write: { Component: ComponentWriteMode },
  parseValueToShow,
  parseValueToSave,
} as CustomFieldTypeReturn
