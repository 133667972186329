import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconPercentage(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33317 3.41675C3.82691 3.41675 3.4165 3.82715 3.4165 4.33341C3.4165 4.83968 3.82691 5.25008 4.33317 5.25008C4.83943 5.25008 5.24984 4.83968 5.24984 4.33341C5.24984 3.82715 4.83943 3.41675 4.33317 3.41675ZM1.9165 4.33341C1.9165 2.99873 2.99848 1.91675 4.33317 1.91675C5.66786 1.91675 6.74984 2.99873 6.74984 4.33341C6.74984 5.6681 5.66786 6.75008 4.33317 6.75008C2.99848 6.75008 1.9165 5.6681 1.9165 4.33341ZM13.1972 2.80292C13.4901 3.09582 13.4901 3.57069 13.1972 3.86358L3.86383 13.1969C3.57093 13.4898 3.09606 13.4898 2.80317 13.1969C2.51027 12.904 2.51027 12.4291 2.80317 12.1363L12.1365 2.80292C12.4294 2.51003 12.9043 2.51003 13.1972 2.80292ZM10.75 11.6667C10.75 11.1604 11.1604 10.75 11.6667 10.75C12.1729 10.75 12.5833 11.1604 12.5833 11.6667C12.5833 12.1729 12.1729 12.5833 11.6667 12.5833C11.1604 12.5833 10.75 12.1729 10.75 11.6667ZM11.6667 9.25C10.332 9.25 9.25 10.332 9.25 11.6667C9.25 13.0014 10.332 14.0833 11.6667 14.0833C13.0014 14.0833 14.0833 13.0014 14.0833 11.6667C14.0833 10.332 13.0014 9.25 11.6667 9.25Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconPercentage = forwardRef(_IconPercentage)
