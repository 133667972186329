import styled from 'styled-components'

import { mainColors, semanticColors } from '../../styles/colors'
import { medias } from '../../styles/medias'
import { _2XS_Bold, _3XS_Bold } from '../../styles/typography'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const TagContainer = styled.span`
  line-height: 18px;
  display: flex;
  align-items: center;
`

export const Tag = styled.div`
  padding: 2px 8px;
  border-radius: 2px;
  background-color: ${semanticColors.failureMedium};
  color: ${mainColors.white};
  ${_3XS_Bold};
  margin-left: 4px;

  ${medias.XXL_SCREEN`
    ${_2XS_Bold};
    margin-left: 8px;
  `}
`
