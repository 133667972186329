import React, { Ref } from 'react'

function _IconAddComment(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C0 6.44772 0.447715 6 1 6H15C15.5523 6 16 6.44772 16 7C16 7.55228 15.5523 8 15 8H1C0.447715 8 0 7.55228 0 7ZM0 12C0 11.4477 0.447715 11 1 11H10C10.5523 11 11 11.4477 11 12C11 12.5523 10.5523 13 10 13H1C0.447715 13 0 12.5523 0 12ZM1 16C0.447715 16 0 16.4477 0 17C0 17.5523 0.447715 18 1 18H15C15.5523 18 16 17.5523 16 17C16 16.4477 15.5523 16 15 16H1ZM19 8C19.5523 8 20 8.44772 20 9V11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H20V15C20 15.5523 19.5523 16 19 16C18.4477 16 18 15.5523 18 15V13H16C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11H18V9C18 8.44772 18.4477 8 19 8Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconAddComment = React.forwardRef(_IconAddComment)
