import React, { SyntheticEvent, useEffect, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { StyledComponent } from 'styled-components'

import { IconMinusCircle, IconPlusCircle } from '@avantstay/backoffice-vectors'

import { usePrevious } from '../../hooks/usePrevious'
import * as S from './Checkbox.styles'
import {
  CheckboxCounterProps,
  CheckboxGroupProps,
  CheckboxInputProps,
  CheckboxProps,
} from './Checkbox.types'

function createCheckboxComponent(
  Component: StyledComponent<'label', HTMLLabelElement, CheckboxProps>,
) {
  return ({
    id,
    value,
    title,
    label,
    dot,
    defaultChecked,
    className,
    counter,
    disabled,
    error,
    warning,
    checkWithText,
    counterActions,
    onChange,
    hasFullWidth,
    dataCy,
    partial,
    subtitle,
    ...inputProps
  }: CheckboxInputProps) => {
    return (
      <S.Container hasFullWidth={hasFullWidth} className={className}>
        <S.HiddenCheckbox
          type="checkbox"
          onChange={onChange}
          id={id}
          value={value}
          disabled={disabled}
          defaultChecked={defaultChecked}
          error={error}
          warning={warning}
          name={label}
          counter={counter}
          checked={inputProps.checked}
          data-cy={dataCy}
          {...inputProps}
        />
        <Component htmlFor={id} disabled={disabled} error={error} warning={warning}>
          {!counter && !checkWithText && (partial ? <S.PartialCheckedIcon /> : <S.CheckedIcon />)}
          {!counter && checkWithText && (
            <S.CheckedText>
              {dot && <S.CheckboxDot dot={dot} />}
              {title}
            </S.CheckedText>
          )}
        </Component>
        {title && !checkWithText && (
          <S.Title checked={inputProps.checked} hasFullWidth={hasFullWidth} htmlFor={id}>
            {dot && <S.CheckboxDot dot={dot} />} {title}
            {subtitle && <S.CheckboxSubtitle>&nbsp;{`• ${subtitle}`}</S.CheckboxSubtitle>}
          </S.Title>
        )}
        {counterActions}
      </S.Container>
    )
  }
}

const Checkbox = () => createCheckboxComponent(S.CheckboxDefault)

Checkbox.Regular = createCheckboxComponent(S.CheckboxDefault)
Checkbox.Rounded = createCheckboxComponent(S.CheckboxRounded)

Checkbox.Counter = function CheckboxCounter({
  title,
  id,
  initialCounterValue = 0,
  error,
  onChange,
}: CheckboxCounterProps) {
  const [counter, setCounter] = useState(initialCounterValue)
  const prevCounter = usePrevious(counter)

  const handleIncrement = (event: SyntheticEvent<HTMLElement>) => {
    setCounter(prev => prev + 1)
    event.stopPropagation()
  }

  const handleDecrement = (event: SyntheticEvent<HTMLElement>) => {
    setCounter(prev => (prev === 0 ? prev : prev - 1))
    event.stopPropagation()
  }

  useEffect(() => {
    // Should only call onChange if the counter effectively changed
    // prev is undefined on the first render, when we also don't want to call onChange
    if (prevCounter === undefined || counter === prevCounter) return
    onChange(counter)
  }, [prevCounter, counter, onChange])

  const counterIsActive = counter > 0

  return (
    <S.CounterWrapper>
      <Checkbox.Regular
        onChange={handleIncrement}
        id={id}
        value={counter}
        counter={counter}
        title={title}
        error={error}
        checked={counterIsActive}
        counterActions={
          <S.Actions>
            {counterIsActive ? (
              <S.ActionWrapper onClick={handleDecrement}>
                <IconMinusCircle />
              </S.ActionWrapper>
            ) : null}
            <S.ActionWrapper onClick={handleIncrement}>
              <IconPlusCircle />
            </S.ActionWrapper>
          </S.Actions>
        }
      />
    </S.CounterWrapper>
  )
}

Checkbox.Group = function CheckboxGroup({ className, children }: CheckboxGroupProps) {
  return <S.Group className={className}>{children}</S.Group>
}

export { Checkbox }
