import memoize from 'moize'

const getScrollParent = memoize((element: HTMLElement, includeHidden?: boolean): HTMLElement => {
  let style = getComputedStyle(element)
  const excludeStaticParent = style.position === 'absolute'
  const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/

  if (style.position === 'fixed') {
    return document.body
  }

  let parent: HTMLElement | null = element

  while (parent !== document.body) {
    parent = parent.parentElement
    if (parent === null) {
      break
    }
    style = getComputedStyle(parent)
    if (excludeStaticParent && style.position === 'static') {
      // eslint-disable-next-line no-continue
      continue
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      return parent
    }
  }

  return document.body
})

export const isInView = (
  element: HTMLElement,
  fullyVisible: boolean,
  container: HTMLElement = getScrollParent(element),
) => {
  const elementRect = element.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  return (
    containerRect.top < (fullyVisible ? elementRect.top : elementRect.bottom) &&
    containerRect.left < (fullyVisible ? elementRect.left : elementRect.right) &&
    containerRect.right > (fullyVisible ? elementRect.right : elementRect.left) &&
    containerRect.bottom > (fullyVisible ? elementRect.bottom : elementRect.top)
  )
}
