import styled from 'styled-components'

import { Button } from '../../../components/Button'
import { mainColors } from '../../../styles/colors'
import { _2XS_Normal, _XS_Normal } from '../../../styles/typography'

export const MultiValue = styled.div`
  display: flex;
  background: ${mainColors.neutralLight};
  border-radius: 4px !important;
  padding: 4px 10px;
  align-items: center;
  height: 100%;

  &:hover {
    background-color: ${mainColors.neutralLight};
  }
`

export const MultiValueLabel = styled.div`
  display: flex;
  flex-flow: column;
`

export const SelectedLabel = styled.span`
  ${_XS_Normal};
  color: ${mainColors.black};
  line-height: normal;
`

export const SelectedSubtitle = styled.span`
  ${_2XS_Normal};
  color: ${mainColors.neutralExtremelyDark};
  line-height: normal;
`

export const MultiValueDeselect = styled(Button.FlatNeutral)`
  margin-left: 10px;
`
