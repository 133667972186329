import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'

import {
  ManualBookingExistingGuestsProps,
  ManualBookingsCandidatesProps,
} from '@new/__global/components/GuestSearchSelect/GuestSearchSelect.types'
// eslint-disable-next-line import/no-cycle
import {
  CreateBookingForExistingGuestProps,
  CreateBookingForNewGuestProps,
} from '@new/domains/bookingsTapeChart/components/CreateBookingForm/CreateBookingForm.types'

export default {
  async getManualBookingExistingGuests({ phone, email }: ManualBookingExistingGuestsProps) {
    return arriereBackoffice.queries.manualBookingExistingGuests
      .raw({
        __alias: 'getManualBookingExistingGuests',
        __args: { phone, email },
        total: true,
        results: {
          guestId: true,
          userId: true,
          kind: {
            id: true,
            type: true,
            subType: true,
          },
          name: true,
          emails: true,
          phones: true,
          stays: {
            total: true,
          },
        },
      })
      .then(({ data, errors }) => {
        if (errors) {
          throw Error(errors[0])
        }

        return data
      })
  },

  async getManualBookingCandidates({ contactInfoTerm, pagination }: ManualBookingsCandidatesProps) {
    return arriereBackoffice.queries.manualBookingCandidates
      .raw({
        __alias: 'getManualBookingCandidates',
        __args: { contactInfoTerm, pagination },
        total: true,
        paginationParams: { page: true },
        results: {
          guestId: true,
          userId: true,
          kind: {
            id: true,
            type: true,
            subType: true,
          },
          name: true,
          emails: true,
          phones: true,
          stays: {
            total: true,
          },
        },
      })
      .then(({ data, errors }) => {
        if (errors) {
          throw Error(errors[0])
        }

        return data
      })
  },

  // Mutations

  async createBookingForExistingGuest({
    bookingRequestInfo,
    guestId,
  }: CreateBookingForExistingGuestProps) {
    return arriereBackoffice.mutations.manualBooking.raw({
      __alias: 'createBookingForExistingGuest',
      createForExistingGuest: {
        __args: { request: bookingRequestInfo, guestId },
        id: true,
        hash: true,
      },
    })
  },

  async createBookingForNewGuest({ bookingRequestInfo, guestData }: CreateBookingForNewGuestProps) {
    return arriereBackoffice.mutations.manualBooking.raw({
      __alias: 'createBookingForNewGuest',
      createForNewGuest: {
        __args: { request: bookingRequestInfo, guestData },
        hash: true,
        id: true,
        guestId: true,
      },
    })
  },
}
