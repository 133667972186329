import styled from 'styled-components'

import { mainColors, semanticColors } from '../../styles/colors'
import { _3XS_Bold, _M_Medium, _S_Normal } from '../../styles/typography'
import { ButtonStyledProps, sizeTypes } from './Button.types'

const createStyledButtonFactory =
  (Component: typeof ButtonDefault) =>
  (stylesByState: {
    default: string
    hover: string
    active: string
    disabled: string
    loading?: string
  }) => {
    return styled(Component)`
      ${stylesByState.default};

      &:hover {
        ${stylesByState.hover};
      }

      &:active,
      &:focus {
        ${stylesByState.active};
      }

      &:disabled {
        ${stylesByState.disabled};
        cursor: not-allowed;
      }

      ${({ isLoading }) =>
        isLoading &&
        `
      ${stylesByState.loading};
    `}
    `
  }

const paddingBySizeType = {
  [sizeTypes.large]: '10px 14px',
  [sizeTypes.medium]: '8px 14px',
}

export const ButtonDefault = styled.button<ButtonStyledProps>`
  width: ${({ auto }) => (auto ? 'auto' : '100%')};
  min-width: 100px;
  min-height: 36px;
  padding: ${({ size }) => paddingBySizeType[size || sizeTypes.large]};
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  border: none;

  // important because of global textarea font-size
  font-size: 14px !important;
  ${_M_Medium};

  svg {
    width: 14px;
  }
`

const createStyledButton = createStyledButtonFactory(ButtonDefault)

export const ButtonPrimary = createStyledButton({
  default: `
    background-color: ${mainColors.accentMedium};
    color: ${mainColors.white};
  `,
  hover: `background-color: ${mainColors.accentDark};`,
  active: `background-color: ${mainColors.accentExtraDark};`,
  disabled: `
    background-color: ${mainColors.accentMedium};
    opacity: 0.5;
  `,
  loading: `
    span {
      background-color: ${mainColors.white};
    }
  `,
})

export const ButtonSecondary = createStyledButton({
  default: `
    background-color: ${mainColors.neutralLight};
    color: ${mainColors.neutralExtraDark};
  `,
  hover: `background-color: ${mainColors.neutralMedium};`,
  active: `background-color: ${mainColors.neutralMedium}; color: ${mainColors.neutralExtremelyDark}`,
  disabled: `
    background-color: ${mainColors.neutralExtraLight};
    color: ${mainColors.neutralDark};
  `,
  loading: `
    span {
      background-color: ${mainColors.neutralExtraDark};
    }
  `,
})

export const ButtonTertiary = createStyledButton({
  default: `
    background-color: ${mainColors.accentExtremelyLight};
    color: ${mainColors.accentMedium};
  `,
  hover: `background-color: ${mainColors.accentExtraLight};`,
  active: `
    background-color: ${mainColors.accentMedium};
    color: ${mainColors.white};
  `,
  disabled: `
    background-color: ${mainColors.neutralLight};
    color: ${mainColors.neutralDark};
  `,
  loading: `
    span {
      background-color: ${mainColors.accentMedium};
    }
    &:active {
      span {
        background-color: ${mainColors.white};
      }
    }
  `,
})

export const ButtonNeutral = createStyledButton({
  default: `
    background-color: ${mainColors.neutralExtremelyDark};
    color: ${mainColors.white};
  `,
  hover: `background-color: ${mainColors.neutralExtremelyDark2};`,
  active: `
    background-color: ${mainColors.neutralExtremelyDark3};
  `,
  disabled: `
    background-color: ${mainColors.neutralDark};
  `,
  loading: `
    span {
      background-color: ${mainColors.white};
    }
    &:active {
      span {
        background-color: ${mainColors.white};
      }
    }
  `,
})

export const ButtonDanger = createStyledButton({
  default: `
    background-color: ${semanticColors.failureMedium};
    color: ${mainColors.white};
  `,
  hover: `background-color: ${semanticColors.failureDark};`,
  active: `background-color: ${semanticColors.failureMedium};`,
  disabled: `
    background-color: ${semanticColors.failureMedium};
    opacity: 0.5;
  `,
  loading: `
    span {
      background-color: ${mainColors.white};
    }
  `,
})

export const ButtonSuccess = createStyledButton({
  default: `
    background-color: ${semanticColors.successMedium};
    color: ${mainColors.white};
  `,
  hover: `background-color: ${semanticColors.successDark};`,
  active: `background-color: ${semanticColors.successMedium};`,
  disabled: `
    background-color: ${semanticColors.successMedium};
    opacity: 0.5;
  `,
  loading: `
    span {
      background-color: ${mainColors.white};
    }
  `,
})

export const ButtonFlat = createStyledButton({
  default: `
    min-width: max-content;
    width: max-content;
    min-height: 0;
    padding: 0;
    background: none;
    color: ${mainColors.accentMedium};
  `,
  hover: `color: ${mainColors.accentDark};`,
  active: `color: ${mainColors.accentExtraDark};`,
  disabled: `color: ${mainColors.neutralDark};`,
})

export const ButtonFlatNeutral = createStyledButton({
  default: `
    min-width: max-content;
    width: max-content;
    padding: 0;
    background: none;
    color: ${mainColors.neutralExtremelyDark};
  `,
  hover: `color: ${mainColors.neutralDark};`,
  active: `color: ${mainColors.neutralExtremelyDark};`,
  disabled: `color: ${mainColors.neutralLight};`,
})

export const ButtonFlatDanger = createStyledButton({
  default: `
    min-width: max-content;
    width: max-content;
    padding: 0;
    background: none;
    color: ${semanticColors.failureMedium};
  `,
  hover: `color: ${semanticColors.failureDark};`,
  active: `color: ${semanticColors.failureMedium};`,
  disabled: `color: ${mainColors.neutralDark};`,
})

export const ButtonMore = createStyledButton({
  default: `
    min-width: max-content;
    min-height: 24px;
    padding: 0;
    background: none;
    color: ${mainColors.neutralExtraDark};
    svg {
      width: 32px;
    }

    &:focus {
     color: ${mainColors.accentMedium};
    }
  `,
  hover: `color: ${mainColors.neutralMedium};`,
  active: `color: ${mainColors.accentMedium};`,
  disabled: `color: ${mainColors.neutralDark};`,
})

export const ButtonDropdown = createStyledButton({
  default: `
    min-width: max-content;
    padding: 8px 11px 8px 14px;
    justify-content: space-between;
    background: ${mainColors.white};
    color: ${mainColors.neutralExtremelyDark};
    border: 2px solid ${mainColors.neutralMedium};
    border-radius: 2px;
    ${_S_Normal};

    &:focus {
      border-color: ${mainColors.accentMedium};
    }
  `,
  hover: `border-color: ${mainColors.neutralDark};`,
  active: `border-color: ${mainColors.accentMedium};`,
  disabled: `background-color: ${mainColors.neutralDark};`,
})

export const ButtonRoundedOutline = createStyledButton({
  default: `
    span {
      ${_3XS_Bold};
    }
    min-height: 19px;
    padding: 4px 14px;
    border-radius: 10px;
    border: 1px solid ${mainColors.accentMedium} !important;
    background-color: ${mainColors.accentExtremelyLight};
    color: ${mainColors.accentMedium};
  `,
  hover: `
    border-color: ${mainColors.accentDark} !important;
    background-color: ${mainColors.accentExtraLight};
    color: ${mainColors.accentDark};
  `,
  active: `
    border-color: ${mainColors.accentExtraDark} !important;
    background-color: ${mainColors.accentExtraLight};
    color: ${mainColors.accentExtraDark};
  `,
  disabled: `
    border-color: ${mainColors.neutralLight} !important;
    background-color: ${mainColors.neutralExtraLight};
    color: ${mainColors.neutralDark};
  `,
  loading: `
    span {
      background-color: ${mainColors.accentMedium};
    }
  `,
})
