import styled from 'styled-components'

import { IconCalendar } from '@avantstay/backoffice-vectors'

import { mainColors } from '../../styles/colors'
import { medias } from '../../styles/medias'
import { _S_Normal } from '../../styles/typography'
import { zIndex } from '../../styles/zIndex'
import { TextFieldInputContainer } from '../TextField'

export const Root = styled.div<{ $isDisabled?: boolean }>`
  width: 100%;
  min-width: 115px;

  ${TextFieldInputContainer} {
    z-index: ${zIndex.DEFAULT};

    input {
      padding: 0;
    }
  }

  &:hover {
    cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  }

  ${medias.LG_SCREEN`
    min-width: 170px;
  `};
`

export const IconCalendarStyled = styled(IconCalendar)`
  color: ${mainColors.neutralExtraDark};
`

export const OriginalRangeContainer = styled.div`
  margin-right: 5px;
  width: max-content;
  padding-top: 1px;
`

export const OriginalRange = styled.p`
  ${_S_Normal};
  text-decoration: line-through;
  color: ${mainColors.neutralExtremelyDark};
`
