import React from 'react'

function _IconNetSuiteLogo(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <circle cx="12" cy="12" r="12" fill="#1C4267" />
      <path
        d="M6.151 9.75044H8.67597V15.3804H9.93312V17.5804H6.151V9.75044ZM17.8383 14.9304H15.3133V9.30044H14.0562V7.10043H17.8383V14.9304Z"
        fill="white"
      />
      <path
        d="M5.56506 6.56036H13.5448V12.9904L10.4765 9.28036H5.56506V6.56036ZM18.4136 18.1303H10.4339V11.7004L13.5022 15.4104H18.4136"
        fill="white"
      />
    </svg>
  )
}

const IconNetSuiteLogo = React.memo(React.forwardRef(_IconNetSuiteLogo))

export { IconNetSuiteLogo }
