import React, { SVGProps } from 'react'

export const IconBold = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M7.89648 8.6084H5.33984L5.32617 7.2002H7.47266C7.85091 7.2002 8.16081 7.15234 8.40234 7.05664C8.64388 6.95638 8.82389 6.81283 8.94238 6.62598C9.06543 6.43457 9.12695 6.20215 9.12695 5.92871C9.12695 5.61882 9.06771 5.36816 8.94922 5.17676C8.83529 4.98535 8.65527 4.84635 8.40918 4.75977C8.16764 4.67318 7.85547 4.62988 7.47266 4.62988H6.05078V12.9766H4V3.02344H7.47266C8.05143 3.02344 8.56868 3.07812 9.02441 3.1875C9.4847 3.29688 9.87435 3.46322 10.1934 3.68652C10.5124 3.90983 10.7562 4.19238 10.9248 4.53418C11.0934 4.87142 11.1777 5.27246 11.1777 5.7373C11.1777 6.14746 11.0843 6.52572 10.8975 6.87207C10.7152 7.21842 10.4258 7.50098 10.0293 7.71973C9.63737 7.93848 9.12467 8.05924 8.49121 8.08203L7.89648 8.6084ZM7.80762 12.9766H4.7793L5.5791 11.377H7.80762C8.16764 11.377 8.46159 11.32 8.68945 11.2061C8.91732 11.0876 9.08594 10.9281 9.19531 10.7275C9.30469 10.527 9.35938 10.2969 9.35938 10.0371C9.35938 9.74544 9.30924 9.49251 9.20898 9.27832C9.11328 9.06413 8.95833 8.90007 8.74414 8.78613C8.52995 8.66764 8.2474 8.6084 7.89648 8.6084H5.9209L5.93457 7.2002H8.39551L8.86719 7.75391C9.47331 7.74479 9.96094 7.85189 10.3301 8.0752C10.7038 8.29395 10.9749 8.57878 11.1436 8.92969C11.3167 9.2806 11.4033 9.65658 11.4033 10.0576C11.4033 10.6956 11.2643 11.2334 10.9863 11.6709C10.7083 12.1038 10.3005 12.4297 9.7627 12.6484C9.22949 12.8672 8.5778 12.9766 7.80762 12.9766Z"
      fill="currentColor"
    />
  </svg>
)
