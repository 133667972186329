import React from 'react'

import { formatNumber, numberMask, removeMask, TextField } from '@avantstay/backoffice-core'

// eslint-disable-next-line import/no-cycle
import { jsonParseCustomFieldValue } from '@utils/customFields'

import {
  CustomFieldProps,
  CustomFieldTypeReturn,
  IInputProps,
  IMountComponentProps,
} from '../_types'
import * as S from './types.styles'

function ComponentReadMode({ customField: { value } }: IMountComponentProps) {
  return (
    <S.Text>{formatNumber(typeof value === 'string' ? JSON.parse(value || '') : value)}</S.Text>
  )
}

function ComponentWriteMode({
  inputProps,
  error,
  warning,
  customField,
  isReadOnly,
  disabled,
}: IMountComponentProps) {
  if (isReadOnly) return <ComponentReadMode customField={customField} />

  return (
    <TextField.Masked
      {...(inputProps as IInputProps)}
      mask={numberMask}
      error={error}
      warning={warning}
      disabled={disabled}
    />
  )
}

const parseValueToShow = (customField: CustomFieldProps) => {
  const value = jsonParseCustomFieldValue(customField)

  if (!value) {
    return null
  }

  return JSON.parse(value)
}

const parseValueToSave = (customField: CustomFieldProps) => {
  const numberField =
    typeof customField.value === 'number' ? JSON.stringify(customField.value) : customField.value

  return numberField ? removeMask(`${customField.value}`) : JSON.stringify(null)
}

export default {
  read: { Component: ComponentReadMode },
  write: { Component: ComponentWriteMode },
  parseValueToShow,
  parseValueToSave,
} as CustomFieldTypeReturn
