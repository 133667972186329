import React, { Ref } from 'react'

function _IconBlacklistedEmail(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 7C5.5 5.34315 6.84315 4 8.5 4C10.1569 4 11.5 5.34315 11.5 7C11.5 8.65685 10.1569 10 8.5 10C6.84315 10 5.5 8.65685 5.5 7ZM8.5 2C5.73858 2 3.5 4.23858 3.5 7C3.5 9.76142 5.73858 12 8.5 12C11.2614 12 13.5 9.76142 13.5 7C13.5 4.23858 11.2614 2 8.5 2ZM5 14C3.67392 14 2.40215 14.5268 1.46447 15.4645C0.526784 16.4021 0 17.6739 0 19V21C0 21.5523 0.447715 22 1 22C1.55228 22 2 21.5523 2 21V19C2 18.2044 2.31607 17.4413 2.87868 16.8787C3.44129 16.3161 4.20435 16 5 16H12C12.7956 16 13.5587 16.3161 14.1213 16.8787C14.6839 17.4413 15 18.2043 15 19V21C15 21.5523 15.4477 22 16 22C16.5523 22 17 21.5523 17 21V19C17 17.6739 16.4732 16.4021 15.5355 15.4645C14.5979 14.5268 13.3261 14 12 14H5ZM16.8452 13.957C16.4547 13.5664 16.4547 12.9333 16.8452 12.5428L18.2356 11.1524L16.7976 9.71437C16.4071 9.32384 16.4071 8.69068 16.7976 8.30015C17.1881 7.90963 17.8213 7.90963 18.2118 8.30015L19.6498 9.73816L21.0879 8.30012C21.4784 7.90959 22.1116 7.90959 22.5021 8.30012C22.8926 8.69064 22.8926 9.32381 22.5021 9.71433L21.0641 11.1524L22.4545 12.5428C22.845 12.9333 22.845 13.5665 22.4545 13.957C22.064 14.3475 21.4308 14.3475 21.0403 13.957L19.6498 12.5666L18.2595 13.957C17.8689 14.3475 17.2358 14.3475 16.8452 13.957Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconBlacklistedEmail = React.forwardRef(_IconBlacklistedEmail)
