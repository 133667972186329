import React from 'react'

function _IconSearch(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1667 3.5C6.93248 3.5 3.5 6.93248 3.5 11.1667C3.5 15.4008 6.93248 18.8333 11.1667 18.8333C12.9233 18.8333 14.5419 18.2426 15.8347 17.2489L18.7929 20.2071C19.1834 20.5976 19.8166 20.5976 20.2071 20.2071C20.5976 19.8166 20.5976 19.1834 20.2071 18.7929L17.2489 15.8347C18.2426 14.5419 18.8333 12.9233 18.8333 11.1667C18.8333 6.93248 15.4008 3.5 11.1667 3.5ZM15.256 15.0895C16.2329 14.0713 16.8333 12.6891 16.8333 11.1667C16.8333 8.03705 14.2963 5.5 11.1667 5.5C8.03705 5.5 5.5 8.03705 5.5 11.1667C5.5 14.2963 8.03705 16.8333 11.1667 16.8333C12.6891 16.8333 14.0713 16.2329 15.0895 15.256C15.1136 15.2255 15.1397 15.1961 15.1679 15.1679C15.1961 15.1397 15.2255 15.1136 15.256 15.0895Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconSearch = React.memo(React.forwardRef(_IconSearch))

export { IconSearch }
