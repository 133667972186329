import React from 'react'

function _IconChevronDown(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.50507 6.50507C3.77844 6.23171 4.22166 6.23171 4.49502 6.50507L8.00005 10.0101L11.5051 6.50507C11.7784 6.23171 12.2217 6.23171 12.495 6.50507C12.7684 6.77844 12.7684 7.22166 12.495 7.49502L8.49502 11.495C8.22166 11.7684 7.77844 11.7684 7.50507 11.495L3.50507 7.49502C3.23171 7.22166 3.23171 6.77844 3.50507 6.50507Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconChevronDown = React.memo(React.forwardRef(_IconChevronDown))

export { IconChevronDown }
