import { uniqBy } from 'lodash'

import { Country } from '../enums/countries'

export interface CountryCodes {
  name: string
  code: string
}

export const base: CountryCodes[] = [
  {
    name: 'Aruba',
    code: Country.Aruba,
  },
  {
    name: 'Afghanistan',
    code: Country.Afghanistan,
  },
  {
    name: 'Angola',
    code: Country.Angola,
  },
  {
    name: 'Anguilla',
    code: Country.Anguilla,
  },
  {
    name: 'Åland Islands',
    code: Country.AlandIslands,
  },
  {
    name: 'Albania',
    code: Country.Albania,
  },
  {
    name: 'Andorra',
    code: Country.Andorra,
  },
  {
    name: 'United Arab Emirates',
    code: Country.UnitedArabEmirates,
  },
  {
    name: 'Argentina',
    code: Country.Argentina,
  },
  {
    name: 'Armenia',
    code: Country.Armenia,
  },
  {
    name: 'American Samoa',
    code: Country.AmericanSamoa,
  },
  {
    name: 'Antarctica',
    code: Country.Antarctica,
  },
  {
    name: 'French Southern and Antarctic Lands',
    code: Country.FrenchSouthernTerritories,
  },
  {
    name: 'Antigua and Barbuda',
    code: Country.AntiguaAndBarbuda,
  },
  {
    name: 'Australia',
    code: Country.Australia,
  },
  {
    name: 'Austria',
    code: Country.Austria,
  },
  {
    name: 'Azerbaijan',
    code: Country.Azerbaijan,
  },
  {
    name: 'Burundi',
    code: Country.Burundi,
  },
  {
    name: 'Belgium',
    code: Country.Belgium,
  },
  {
    name: 'Benin',
    code: Country.Benin,
  },
  {
    name: 'Burkina Faso',
    code: Country.BurkinaFaso,
  },
  {
    name: 'Bangladesh',
    code: Country.Bangladesh,
  },
  {
    name: 'Bulgaria',
    code: Country.Bulgaria,
  },
  {
    name: 'Bahrain',
    code: Country.Bahrain,
  },
  {
    name: 'Bahamas',
    code: Country.Bahamas,
  },
  {
    name: 'Bosnia and Herzegovina',
    code: Country.BosniaAndHerzegovina,
  },
  {
    name: 'Saint Barthélemy',
    code: Country.SaintBarthelemy,
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    code: Country.SaintHelena,
  },
  {
    name: 'Belarus',
    code: Country.Belarus,
  },
  {
    name: 'Belize',
    code: Country.Belize,
  },
  {
    name: 'Bermuda',
    code: Country.Bermuda,
  },
  {
    name: 'Bolivia',
    code: Country.Bolivia,
  },
  {
    name: 'Caribbean Netherlands',
    code: Country.BonaireSintEustatiusSaba,
  },
  {
    name: 'Brazil',
    code: Country.Brazil,
  },
  {
    name: 'Barbados',
    code: Country.Barbados,
  },
  {
    name: 'Brunei',
    code: Country.BruneiDarussalam,
  },
  {
    name: 'Bhutan',
    code: Country.Bhutan,
  },
  {
    name: 'Bouvet Island',
    code: Country.BouvetIsland,
  },
  {
    name: 'Botswana',
    code: Country.Botswana,
  },
  {
    name: 'Central African Republic',
    code: Country.CentralAfricanRepublic,
  },
  {
    name: 'Canada',
    code: Country.Canada,
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: Country.CocosKeelingIslands,
  },
  {
    name: 'Switzerland',
    code: Country.Switzerland,
  },
  {
    name: 'Chile',
    code: Country.Chile,
  },
  {
    name: 'China',
    code: Country.China,
  },
  {
    name: 'Ivory Coast',
    code: Country.CoteDIvoire,
  },
  {
    name: 'Cameroon',
    code: Country.Cameroon,
  },
  {
    name: 'DR Congo',
    code: Country.CongoDemocraticRepublic,
  },
  {
    name: 'Republic of the Congo',
    code: Country.Congo,
  },
  {
    name: 'Cook Islands',
    code: Country.CookIslands,
  },
  {
    name: 'Colombia',
    code: Country.Colombia,
  },
  {
    name: 'Comoros',
    code: Country.Comoros,
  },
  {
    name: 'Cape Verde',
    code: Country.CapeVerde,
  },
  {
    name: 'Costa Rica',
    code: Country.CostaRica,
  },
  {
    name: 'Cuba',
    code: Country.Cuba,
  },
  {
    name: 'Curaçao',
    code: Country.Curaçao,
  },
  {
    name: 'Christmas Island',
    code: Country.ChristmasIsland,
  },
  {
    name: 'Cayman Islands',
    code: Country.CaymanIslands,
  },
  {
    name: 'Cyprus',
    code: Country.Cyprus,
  },
  {
    name: 'Czechia',
    code: Country.CzechRepublic,
  },
  {
    name: 'Germany',
    code: Country.Germany,
  },
  {
    name: 'Djibouti',
    code: Country.Djibouti,
  },
  {
    name: 'Dominica',
    code: Country.Dominica,
  },
  {
    name: 'Denmark',
    code: Country.Denmark,
  },
  {
    name: 'Dominican Republic',
    code: Country.DominicanRepublic,
  },
  {
    name: 'Algeria',
    code: Country.Algeria,
  },
  {
    name: 'Ecuador',
    code: Country.Ecuador,
  },
  {
    name: 'Egypt',
    code: Country.Egypt,
  },
  {
    name: 'Eritrea',
    code: Country.Eritrea,
  },
  {
    name: 'Western Sahara',
    code: Country.WesternSahara,
  },
  {
    name: 'Spain',
    code: Country.Spain,
  },
  {
    name: 'Estonia',
    code: Country.Estonia,
  },
  {
    name: 'Ethiopia',
    code: Country.Ethiopia,
  },
  {
    name: 'Finland',
    code: Country.Finland,
  },
  {
    name: 'Fiji',
    code: Country.Fiji,
  },
  {
    name: 'Falkland Islands',
    code: Country.FalklandIslands,
  },
  {
    name: 'France',
    code: Country.France,
  },
  {
    name: 'Faroe Islands',
    code: Country.FaroeIslands,
  },
  {
    name: 'Micronesia',
    code: Country.Micronesia,
  },
  {
    name: 'Gabon',
    code: Country.Gabon,
  },
  {
    name: 'United Kingdom',
    code: Country.UnitedKingdom,
  },
  {
    name: 'Georgia',
    code: Country.Georgia,
  },
  {
    name: 'Guernsey',
    code: Country.Guernsey,
  },
  {
    name: 'Ghana',
    code: Country.Ghana,
  },
  {
    name: 'Gibraltar',
    code: Country.Gibraltar,
  },
  {
    name: 'Guinea',
    code: Country.Guinea,
  },
  {
    name: 'Guadeloupe',
    code: Country.Guadeloupe,
  },
  {
    name: 'Gambia',
    code: Country.Gambia,
  },
  {
    name: 'Guinea-Bissau',
    code: Country.GuineaBissau,
  },
  {
    name: 'Equatorial Guinea',
    code: Country.EquatorialGuinea,
  },
  {
    name: 'Greece',
    code: Country.Greece,
  },
  {
    name: 'Grenada',
    code: Country.Grenada,
  },
  {
    name: 'Greenland',
    code: Country.Greenland,
  },
  {
    name: 'Guatemala',
    code: Country.Guatemala,
  },
  {
    name: 'French Guiana',
    code: Country.FrenchGuiana,
  },
  {
    name: 'Guam',
    code: Country.Guam,
  },
  {
    name: 'Guyana',
    code: Country.Guyana,
  },
  {
    name: 'Hong Kong',
    code: Country.HongKong,
  },
  {
    name: 'Heard Island and McDonald Islands',
    code: Country.HeardIslandMcdonaldIslands,
  },
  {
    name: 'Honduras',
    code: Country.Honduras,
  },
  {
    name: 'Croatia',
    code: Country.Croatia,
  },
  {
    name: 'Haiti',
    code: Country.Haiti,
  },
  {
    name: 'Hungary',
    code: Country.Hungary,
  },
  {
    name: 'Indonesia',
    code: Country.Indonesia,
  },
  {
    name: 'Isle of Man',
    code: Country.IsleOfMan,
  },
  {
    name: 'India',
    code: Country.India,
  },
  {
    name: 'British Indian Ocean Territory',
    code: Country.BritishIndianOceanTerritory,
  },
  {
    name: 'Ireland',
    code: Country.Ireland,
  },
  {
    name: 'Iran',
    code: Country.Iran,
  },
  {
    name: 'Iraq',
    code: Country.Iraq,
  },
  {
    name: 'Iceland',
    code: Country.Iceland,
  },
  {
    name: 'Israel',
    code: Country.Israel,
  },
  {
    name: 'Italy',
    code: Country.Italy,
  },
  {
    name: 'Jamaica',
    code: Country.Jamaica,
  },
  {
    name: 'Jersey',
    code: Country.Jersey,
  },
  {
    name: 'Jordan',
    code: Country.Jordan,
  },
  {
    name: 'Japan',
    code: Country.Japan,
  },
  {
    name: 'Kazakhstan',
    code: Country.Kazakhstan,
  },
  {
    name: 'Kenya',
    code: Country.Kenya,
  },
  {
    name: 'Kyrgyzstan',
    code: Country.Kyrgyzstan,
  },
  {
    name: 'Cambodia',
    code: Country.Cambodia,
  },
  {
    name: 'Kiribati',
    code: Country.Kiribati,
  },
  {
    name: 'Saint Kitts and Nevis',
    code: Country.SaintKittsAndNevis,
  },
  {
    name: 'South Korea',
    code: Country.Korea,
  },
  {
    name: 'Kosovo',
    code: Country.Kosovo,
  },
  {
    name: 'Kuwait',
    code: Country.Kuwait,
  },
  {
    name: 'Laos',
    code: Country.LaoPeoplesDemocraticRepublic,
  },
  {
    name: 'Lebanon',
    code: Country.Lebanon,
  },
  {
    name: 'Liberia',
    code: Country.Liberia,
  },
  {
    name: 'Libya',
    code: Country.LibyanArabJamahiriya,
  },
  {
    name: 'Saint Lucia',
    code: Country.SaintLucia,
  },
  {
    name: 'Liechtenstein',
    code: Country.Liechtenstein,
  },
  {
    name: 'Sri Lanka',
    code: Country.SriLanka,
  },
  {
    name: 'Lesotho',
    code: Country.Lesotho,
  },
  {
    name: 'Lithuania',
    code: Country.Lithuania,
  },
  {
    name: 'Luxembourg',
    code: Country.Luxembourg,
  },
  {
    name: 'Latvia',
    code: Country.Latvia,
  },
  {
    name: 'Macau',
    code: Country.Macao,
  },
  {
    name: 'Saint Martin',
    code: Country.SaintMartin,
  },
  {
    name: 'Morocco',
    code: Country.Morocco,
  },
  {
    name: 'Monaco',
    code: Country.Monaco,
  },
  {
    name: 'Moldova',
    code: Country.Moldova,
  },
  {
    name: 'Madagascar',
    code: Country.Madagascar,
  },
  {
    name: 'Maldives',
    code: Country.Maldives,
  },
  {
    name: 'Mexico',
    code: Country.Mexico,
  },
  {
    name: 'Marshall Islands',
    code: Country.MarshallIslands,
  },
  {
    name: 'Macedonia',
    code: Country.Macedonia,
  },
  {
    name: 'Mali',
    code: Country.Mali,
  },
  {
    name: 'Malta',
    code: Country.Malta,
  },
  {
    name: 'Myanmar',
    code: Country.Myanmar,
  },
  {
    name: 'Montenegro',
    code: Country.Montenegro,
  },
  {
    name: 'Mongolia',
    code: Country.Mongolia,
  },
  {
    name: 'Northern Mariana Islands',
    code: Country.NorthernMarianaIslands,
  },
  {
    name: 'Mozambique',
    code: Country.Mozambique,
  },
  {
    name: 'Mauritania',
    code: Country.Mauritania,
  },
  {
    name: 'Montserrat',
    code: Country.Montserrat,
  },
  {
    name: 'Martinique',
    code: Country.Martinique,
  },
  {
    name: 'Mauritius',
    code: Country.Mauritius,
  },
  {
    name: 'Malawi',
    code: Country.Malawi,
  },
  {
    name: 'Malaysia',
    code: Country.Malaysia,
  },
  {
    name: 'Mayotte',
    code: Country.Mayotte,
  },
  {
    name: 'Namibia',
    code: Country.Namibia,
  },
  {
    name: 'New Caledonia',
    code: Country.NewCaledonia,
  },
  {
    name: 'Niger',
    code: Country.Niger,
  },
  {
    name: 'Norfolk Island',
    code: Country.NorfolkIsland,
  },
  {
    name: 'Nigeria',
    code: Country.Nigeria,
  },
  {
    name: 'Nicaragua',
    code: Country.Nicaragua,
  },
  {
    name: 'Niue',
    code: Country.Niue,
  },
  {
    name: 'Netherlands',
    code: Country.Netherlands,
  },
  {
    name: 'Norway',
    code: Country.Norway,
  },
  {
    name: 'Nepal',
    code: Country.Nepal,
  },
  {
    name: 'Nauru',
    code: Country.Nauru,
  },
  {
    name: 'New Zealand',
    code: Country.NewZealand,
  },
  {
    name: 'Oman',
    code: Country.Oman,
  },
  {
    name: 'Pakistan',
    code: Country.Pakistan,
  },
  {
    name: 'Panama',
    code: Country.Panama,
  },
  {
    name: 'Pitcairn Islands',
    code: Country.Pitcairn,
  },
  {
    name: 'Peru',
    code: Country.Peru,
  },
  {
    name: 'Philippines',
    code: Country.Philippines,
  },
  {
    name: 'Palau',
    code: Country.Palau,
  },
  {
    name: 'Papua New Guinea',
    code: Country.PapuaNewGuinea,
  },
  {
    name: 'Poland',
    code: Country.Poland,
  },
  {
    name: 'Puerto Rico',
    code: Country.PuertoRico,
  },
  {
    name: 'North Korea',
    code: Country.KoreaDemocraticPeoplesRepublic,
  },
  {
    name: 'Portugal',
    code: Country.Portugal,
  },
  {
    name: 'Paraguay',
    code: Country.Paraguay,
  },
  {
    name: 'Palestine',
    code: Country.PalestinianTerritory,
  },
  {
    name: 'French Polynesia',
    code: Country.FrenchPolynesia,
  },
  {
    name: 'Qatar',
    code: Country.Qatar,
  },
  {
    name: 'Réunion',
    code: Country.Reunion,
  },
  {
    name: 'Romania',
    code: Country.Romania,
  },
  {
    name: 'Russia',
    code: Country.RussianFederation,
  },
  {
    name: 'Rwanda',
    code: Country.Rwanda,
  },
  {
    name: 'Saudi Arabia',
    code: Country.SaudiArabia,
  },
  {
    name: 'Sudan',
    code: Country.Sudan,
  },
  {
    name: 'Senegal',
    code: Country.Senegal,
  },
  {
    name: 'Singapore',
    code: Country.Singapore,
  },
  {
    name: 'South Georgia',
    code: Country.SouthGeorgiaAndSandwichIsl,
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: Country.SvalbardAndJanMayen,
  },
  {
    name: 'Solomon Islands',
    code: Country.SolomonIslands,
  },
  {
    name: 'Sierra Leone',
    code: Country.SierraLeone,
  },
  {
    name: 'El Salvador',
    code: Country.ElSalvador,
  },
  {
    name: 'San Marino',
    code: Country.SanMarino,
  },
  {
    name: 'Somalia',
    code: Country.Somalia,
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: Country.SaintPierreAndMiquelon,
  },
  {
    name: 'Serbia',
    code: Country.Serbia,
  },
  {
    name: 'South Sudan',
    code: Country.SouthSudan,
  },
  {
    name: 'São Tomé and Príncipe',
    code: Country.SaoTomeAndPrincipe,
  },
  {
    name: 'Suriname',
    code: Country.Suriname,
  },
  {
    name: 'Slovakia',
    code: Country.Slovakia,
  },
  {
    name: 'Slovenia',
    code: Country.Slovenia,
  },
  {
    name: 'Sweden',
    code: Country.Sweden,
  },
  {
    name: 'Swaziland',
    code: Country.Swaziland,
  },
  {
    name: 'Sint Maarten',
    code: Country.SintMaarten,
  },
  {
    name: 'Seychelles',
    code: Country.Seychelles,
  },
  {
    name: 'Syria',
    code: Country.SyrianArabRepublic,
  },
  {
    name: 'Turks and Caicos Islands',
    code: Country.TurksAndCaicosIslands,
  },
  {
    name: 'Chad',
    code: Country.Chad,
  },
  {
    name: 'Togo',
    code: Country.Togo,
  },
  {
    name: 'Thailand',
    code: Country.Thailand,
  },
  {
    name: 'Tajikistan',
    code: Country.Tajikistan,
  },
  {
    name: 'Tokelau',
    code: Country.Tokelau,
  },
  {
    name: 'Turkmenistan',
    code: Country.Turkmenistan,
  },
  {
    name: 'Timor-Leste',
    code: Country.TimorLeste,
  },
  {
    name: 'Tonga',
    code: Country.Tonga,
  },
  {
    name: 'Trinidad and Tobago',
    code: Country.TrinidadAndTobago,
  },
  {
    name: 'Tunisia',
    code: Country.Tunisia,
  },
  {
    name: 'Turkey',
    code: Country.Turkey,
  },
  {
    name: 'Tuvalu',
    code: Country.Tuvalu,
  },
  {
    name: 'Taiwan',
    code: Country.Taiwan,
  },
  {
    name: 'Tanzania',
    code: Country.Tanzania,
  },
  {
    name: 'Uganda',
    code: Country.Uganda,
  },
  {
    name: 'Ukraine',
    code: Country.Ukraine,
  },
  {
    name: 'United States Minor Outlying Islands',
    code: Country.UnitedStatesOutlyingIslands,
  },
  {
    name: 'Uruguay',
    code: Country.Uruguay,
  },
  {
    name: 'United States',
    code: Country.UnitedStates,
  },
  {
    name: 'Uzbekistan',
    code: Country.Uzbekistan,
  },
  {
    name: 'Vatican City',
    code: Country.HolySeeVaticanCityState,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: Country.SaintVincentAndGrenadines,
  },
  {
    name: 'Venezuela',
    code: Country.Venezuela,
  },
  {
    name: 'British Virgin Islands',
    code: Country.VirginIslandsBritish,
  },
  {
    name: 'United States Virgin Islands',
    code: Country.VirginIslandsUS,
  },
  {
    name: 'Vietnam',
    code: Country.Vietnam,
  },
  {
    name: 'Vanuatu',
    code: Country.Vanuatu,
  },
  {
    name: 'Wallis and Futuna',
    code: Country.WallisAndFutuna,
  },
  {
    name: 'Samoa',
    code: Country.Samoa,
  },
  {
    name: 'Yemen',
    code: Country.Yemen,
  },
  {
    name: 'South Africa',
    code: Country.SouthAfrica,
  },
  {
    name: 'Zambia',
    code: Country.Zambia,
  },
  {
    name: 'Zimbabwe',
    code: Country.Zimbabwe,
  },
]

export const countries =
  process.env.NODE_ENV === 'test'
    ? []
    : uniqBy(
        [
          {
            label: 'United States',
            value: Country.UnitedStates,
          },
          {
            label: 'Canada',
            value: Country.Canada,
          },
          {
            label: 'United Kingdom',
            value: Country.UnitedKingdom,
          },
          {
            label: 'Australia',
            value: Country.Australia,
          },
          {
            label: 'Russia',
            value: Country.RussianFederation,
          },
          {
            label: 'Mexico',
            value: Country.Mexico,
          },
          ...base.map(it => ({
            label: it.name,
            value: it.code,
          })),
        ],
        'value',
      )
