import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'

export const TERMS_AND_CONDITIONS_CF_ID = 'c832482a-3fac-11eb-bd17-00155d3f6ec7'

const getTermsAndConditionsVersions = async () =>
  arriereBackoffice.queries.globalCFChanges({
    __args: {
      customFieldId: TERMS_AND_CONDITIONS_CF_ID,
    },
    id: true,
    author: { name: true },
    update: {
      defaultValue: true,
    },
    time: true,
  })

const getTermsAndConditionsValue = () =>
  arriereBackoffice.queries.customFields({
    __args: {
      customFieldSectionId: TERMS_AND_CONDITIONS_CF_ID,
    },
    defaultValue: true,
    updatedAt: true,
    createdAt: true,
  })

export default {
  getTermsAndConditionsVersions,
  getTermsAndConditionsValue,
}
