import React, { Ref } from 'react'

function _IconLockClosed(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00004 2.40337C7.35097 2.40337 6.72849 2.66121 6.26952 3.12017C5.81056 3.57913 5.55272 4.20162 5.55272 4.85069V6.66819H10.4474V4.85069C10.4474 4.20162 10.1895 3.57913 9.73056 3.12017C9.2716 2.66121 8.64911 2.40337 8.00004 2.40337ZM4.14911 4.85069V6.66819H3.59122C2.50794 6.66819 1.62976 7.54636 1.62976 8.62965V13.0384C1.62976 14.1217 2.50794 14.9999 3.59122 14.9999H12.4088C13.4921 14.9999 14.3702 14.1217 14.3702 13.0384V8.62965C14.3702 7.54636 13.4921 6.66819 12.4088 6.66819H11.851V4.85069C11.851 3.82936 11.4453 2.84986 10.7231 2.12767C10.0009 1.40548 9.02137 0.999756 8.00004 0.999756C6.97871 0.999756 5.99921 1.40548 5.27702 2.12767C4.55483 2.84986 4.14911 3.82936 4.14911 4.85069ZM3.59122 8.0718C3.28313 8.0718 3.03337 8.32156 3.03337 8.62965V13.0384C3.03337 13.3465 3.28313 13.5963 3.59122 13.5963H12.4088C12.7169 13.5963 12.9666 13.3465 12.9666 13.0384V8.62965C12.9666 8.32156 12.7169 8.0718 12.4088 8.0718H3.59122ZM9.07973 10.1593C9.07973 10.5589 8.86261 10.9079 8.53989 11.0945V11.779C8.53989 12.0771 8.29819 12.3188 8.00004 12.3188C7.70189 12.3188 7.46019 12.0771 7.46019 11.779V11.0946C7.13746 10.9079 6.92032 10.5589 6.92032 10.1593C6.92032 9.56299 7.40372 9.07959 8.00003 9.07959C8.59633 9.07959 9.07973 9.56299 9.07973 10.1593Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconLockClosed = React.forwardRef(_IconLockClosed)
