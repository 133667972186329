import React, { Ref } from 'react'

function _IconKey(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1392 23.7649C11.6607 23.7649 11.2728 23.377 11.2728 22.8984L11.2728 21.3143L8.89627 21.3143C8.17845 21.3143 7.59654 20.7323 7.59654 20.0145L7.59654 16.5921C7.59654 15.8743 8.17845 15.2924 8.89627 15.2924L11.2728 15.2924V11.921C10.1322 11.7443 9.0354 11.2167 8.15674 10.338C5.95724 8.13851 5.95724 4.57242 8.15674 2.37292C10.3562 0.173425 13.9223 0.173425 16.1218 2.37292C18.3213 4.57242 18.3213 8.13851 16.1218 10.338C15.2431 11.2167 14.1464 11.7443 13.0057 11.921L13.0057 16.1589L13.0057 20.4478L13.0057 22.8984C13.0057 23.377 12.6178 23.7649 12.1392 23.7649ZM11.2728 19.5813L11.2728 17.0254H9.32952V19.5813L11.2728 19.5813ZM9.38214 3.59832C7.85941 5.12105 7.85941 7.58988 9.38214 9.11261C10.9049 10.6353 13.3737 10.6353 14.8964 9.11261C16.4192 7.58988 16.4192 5.12105 14.8964 3.59832C13.3737 2.07559 10.9049 2.07559 9.38214 3.59832Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconKey = React.forwardRef(_IconKey)
