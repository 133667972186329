import styled from 'styled-components'

import { TextAlign } from '../../../enums/common'
import { mainColors } from '../../../styles/colors'
import { _3XS_Medium } from '../../../styles/typography'
import { ellipsis } from '../../../styles/utils'

export const HEADER_CHILDREN_MIN_WIDTH = 25

interface ContainerProps {
  alignItems?: string
  justifyContent?: string
  width?: string
  textAlign?: TextAlign
}

export const Container = styled.div<ContainerProps>`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  align-items: ${({ alignItems }) => alignItems ?? 'center'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
  text-align: ${({ textAlign }) => textAlign};
`

export const Children = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-width: ${HEADER_CHILDREN_MIN_WIDTH}px;

  > * {
    margin-left: 8px;
  }
`

export const GridLabel = styled.div<{ isSortedBy?: boolean }>`
  font-weight: ${({ isSortedBy }) => isSortedBy && 500};
  ${ellipsis};
  width: max-content;
`

export const Subtitle = styled.div`
  ${_3XS_Medium};
  color: ${mainColors.neutralDark};
  padding-top: 8px;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  &::before {
    content: '';
    margin-top: 8px;
    display: inline-block;
    border-top: 1px solid ${mainColors.neutralMedium};
    position: relative;
    width: 70%;
    top: -8px;
  }
`
