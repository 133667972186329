import styled from 'styled-components'

import { _3XS_Bold } from '../../styles/typography'
import { ellipsis } from '../../styles/utils'
import { PillContainerProps } from './Pill.types'

export const PillDefault = styled.div<PillContainerProps>`
  ${_3XS_Bold};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  max-width: max-content;
  min-width: max-content;
  border-radius: 99px;
  line-height: 18px;
  padding: 0 8px;
  ${ellipsis};

  ${props =>
    props.pillColor &&
    `
      background: ${props.pillColor.backgroundColor};
      color: ${props.pillColor.color};
  `};

  ${props =>
    props.interactive &&
    `
    cursor: pointer;
  `}
`
