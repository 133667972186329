import styled from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _M_Normal, _XS_Normal } from '../../styles/typography'
import { rgba } from '../../utils/cssUtils'
import { LabelSizeProps } from './Label.types'

const Label = styled.span<LabelSizeProps>`
  ${props => (props.small ? _XS_Normal : _M_Normal)};
  padding: ${props => (props.small ? '2px 10px' : '4px 16px')};
  background: ${rgba(mainColors.neutralMedium, 0.6)};
  border-radius: 2px;
  color: ${mainColors.black};

  &:not(:last-child) {
    margin-right: 4px;
  }
`

export { Label }
