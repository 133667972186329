import React, { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react'

import { capitalizeAll, injectVariables } from '../../utils'
import { Tooltip, TooltipProps } from '../Tooltip'
import * as S from './IndicatorsTooltip.styles'
import { IndicatorDocProps } from './IndicatorsTooltip.types'

interface IndicatorsTooltipProps {
  title?: ReactNode
  documentationKey?: string
  extraText?: string
  variables?: { [key: string]: any }
  labelWidth?: number
  indicatorDocs?: IndicatorDocProps
  tooltipStyles?: CSSProperties
  tooltipProps?: Partial<TooltipProps>
}

// This Max length is based on min-width set on AutoSizeGrid
const MAX_TITLE_NAME_LENGTH = 64

export const IndicatorsTooltip: FC<PropsWithChildren<IndicatorsTooltipProps>> = ({
  title,
  children,
  documentationKey,
  extraText,
  variables = {},
  labelWidth,
  indicatorDocs,
  tooltipStyles,
  tooltipProps,
}) => {
  const renderWithSimpleTooltip = () => (
    <Tooltip
      content={typeof title === 'string' ? capitalizeAll(title) : title}
      placement="top"
      disabled={labelWidth ? labelWidth > MAX_TITLE_NAME_LENGTH : true}
      {...tooltipProps}
    >
      <S.IndicatorTooltipWrapper data-testid="tooltip">{children}</S.IndicatorTooltipWrapper>
    </Tooltip>
  )

  if (!documentationKey || !indicatorDocs) return renderWithSimpleTooltip()

  if (indicatorDocs[documentationKey]) {
    return (
      <Tooltip
        interactive
        placement="bottom"
        content={
          <S.TooltipContainer style={tooltipStyles}>
            <div>
              {title && <S.TooltipTitle>{title}</S.TooltipTitle>}
              <S.TooltipSectionTitle>Definition</S.TooltipSectionTitle>
              <S.Markdown
                children={injectVariables(indicatorDocs[documentationKey].definition, {
                  variables,
                })}
              />
            </div>

            <div>
              <S.TooltipSectionTitle>Scope</S.TooltipSectionTitle>
              <S.Markdown
                children={injectVariables(indicatorDocs[documentationKey].scope, {
                  variables,
                })}
              />
            </div>

            {extraText ? <S.Markdown children={extraText} /> : null}
          </S.TooltipContainer>
        }
        {...tooltipProps}
      >
        <S.IndicatorTooltipWrapper data-testid={`tooltip-${documentationKey}`}>
          {children}
        </S.IndicatorTooltipWrapper>
      </Tooltip>
    )
  }

  return renderWithSimpleTooltip()
}
