import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconProperty(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.40925 0.806937C7.16851 0.619693 6.83145 0.619693 6.59071 0.806937L0.590686 5.4736C0.428293 5.5999 0.333313 5.79411 0.333313 5.99984V13.3332C0.333313 13.8636 0.544026 14.3723 0.9191 14.7474C1.29417 15.1224 1.80288 15.3332 2.33331 15.3332H4.99884C4.99922 15.3332 4.9996 15.3332 4.99998 15.3332C5.00036 15.3332 5.00074 15.3332 5.00112 15.3332H8.99884C8.99922 15.3332 8.9996 15.3332 8.99998 15.3332C9.00036 15.3332 9.00074 15.3332 9.00112 15.3332H11.6666C12.1971 15.3332 12.7058 15.1224 13.0808 14.7474C13.4559 14.3723 13.6666 13.8636 13.6666 13.3332V5.99984C13.6666 5.79411 13.5716 5.5999 13.4092 5.4736L7.40925 0.806937ZM9.66665 13.9998H11.6666C11.8434 13.9998 12.013 13.9296 12.138 13.8046C12.263 13.6796 12.3333 13.51 12.3333 13.3332V6.32589L6.99998 2.17774L1.66665 6.32589V13.3332C1.66665 13.51 1.73689 13.6796 1.86191 13.8046C1.98693 13.9296 2.1565 13.9998 2.33331 13.9998H4.33331V7.99984C4.33331 7.63164 4.63179 7.33317 4.99998 7.33317H8.99998C9.36818 7.33317 9.66665 7.63164 9.66665 7.99984V13.9998ZM5.66665 13.9998H8.33331V8.6665H5.66665V13.9998Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconProperty = forwardRef(_IconProperty)
