import React from 'react'

import { SelectFieldProps, Size } from '../SelectField.types'

export function withSizeProp(
  SelectFieldComponent: React.ComponentType<SelectFieldProps<any>>,
  size: Size,
) {
  return function <OptionValueType>(props: SelectFieldProps<OptionValueType>) {
    return <SelectFieldComponent size={size} {...props} />
  }
}
