import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconRating(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.44995C12.3806 1.44995 12.7283 1.66602 12.8967 2.00733L15.7543 7.79642L22.1447 8.73047C22.5212 8.78551 22.8339 9.04951 22.9513 9.41152C23.0687 9.77353 22.9704 10.1708 22.6978 10.4363L18.0746 14.9393L19.1656 21.3009C19.23 21.6761 19.0757 22.0553 18.7678 22.279C18.4598 22.5027 18.0515 22.5322 17.7146 22.355L12 19.3498L6.28548 22.355C5.94856 22.5322 5.54027 22.5027 5.2323 22.279C4.92432 22.0553 4.77007 21.6761 4.83442 21.3009L5.92551 14.9393L1.3023 10.4363C1.02968 10.1708 0.931405 9.77353 1.04878 9.41152C1.16616 9.04951 1.47884 8.78551 1.8554 8.73047L8.24577 7.79642L11.1033 2.00733C11.2718 1.66602 11.6194 1.44995 12 1.44995ZM12 4.70921L9.80674 9.15258C9.6612 9.44742 9.38001 9.65188 9.05466 9.69944L4.14844 10.4166L7.69776 13.8736C7.93364 14.1033 8.0413 14.4345 7.98564 14.759L7.14821 19.6416L11.5346 17.3349C11.826 17.1816 12.1741 17.1816 12.4655 17.3349L16.8518 19.6416L16.0144 14.759C15.9588 14.4345 16.0664 14.1033 16.3023 13.8736L19.8516 10.4166L14.9454 9.69944C14.62 9.65188 14.3389 9.44742 14.1933 9.15258L12 4.70921Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconRating = forwardRef(_IconRating)
