import styled, { css } from 'styled-components'

import { IconCheck, IconMinus } from '@avantstay/backoffice-vectors'

import { _M_Bold, _M_Normal, _XS_Bold, mainColors, medias, semanticColors } from '../../styles'
import { CheckboxProps } from './Checkbox.types'

export const counterNumberStyle = css`
  color: ${mainColors.white};
  ${_XS_Bold};
  width: 14px;
  text-align: center;
`

export const Title = styled.label<{
  checked?: boolean
  hasFullWidth?: boolean
  marginLeft?: number
}>`
  ${_M_Normal};
  display: flex;
  align-items: center;
  margin-left: ${props => props.marginLeft || 24}px;
  width: 100%;
  color: ${props => (props.checked ? mainColors.black : mainColors.neutralExtremelyDark)};
  cursor: pointer;
  ${props =>
    !props.hasFullWidth &&
    css`
      min-width: max-content;
    `};
`

export const Container = styled.div<{ hasFullWidth?: boolean }>`
  position: relative;
  width: ${props => (props.hasFullWidth ? '100%' : '18px')};
  height: 18px;
  display: flex;
  align-items: center;
`

export const CheckboxDefault = styled.label<CheckboxProps>`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px;
  background: transparent;
  border: 2px solid ${mainColors.neutralDark};
  border-radius: 2px;
  cursor: pointer;

  ${props =>
    props.error &&
    `
      border-color: ${semanticColors.failureMedium};
  `}

  ${props =>
    props.warning &&
    `
    border-color: ${semanticColors.warningMedium};
  `}
`

export const CheckboxDot = styled.span<CheckboxProps>`
  width: 7px;
  height: 7px;
  margin-right: 6px;
  border-radius: 22px;
  background: ${({ dot }) => dot};
`

export const CheckedIcon = styled(IconCheck)`
  color: ${mainColors.white};
  left: -1px;
  top: -1px;
  position: absolute;
  display: none;
`

export const CheckboxSubtitle = styled.span`
  ${_M_Bold};
`

export const PartialCheckedIcon = styled(IconMinus)`
  color: ${mainColors.white};
  left: 1px;
  top: 6px;
  position: absolute;
  display: none;
`

export const CheckedText = styled.span`
  color: ${mainColors.white};
  left: -1px;
  top: 0;
  position: absolute;
  display: none;
  font-size: 12px;
`

export const HiddenCheckbox = styled.input<CheckboxProps>`
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  width: 23px;
  height: 23px;

  ${medias.MD_SCREEN`
    width: 15px;
    height: 15px;
  `}

  &:hover + ${CheckboxDefault} {
    border-color: ${mainColors.neutralExtraDark};

    ${({ error }) =>
      error &&
      `
        border-color: ${semanticColors.failureMedium};
    `}

    ${({ warning }) =>
      warning &&
      `
        border-color: ${semanticColors.warningMedium};
    `}
  }

  &:checked + ${CheckboxDefault} {
    background: ${mainColors.neutralExtraDark};
    border-color: ${mainColors.neutralExtraDark};

    ${Title} {
      color: ${mainColors.black};
    }

    svg {
      display: block;
    }

    span {
      display: block;
    }

    ${({ error }) =>
      error &&
      `
        border-color: ${semanticColors.failureMedium};
        background: ${semanticColors.failureMedium};
    `}
    ${({ warning }) =>
      warning &&
      `
        border-color: ${semanticColors.warningMedium};
        background: ${semanticColors.warningMedium};
    `}

    &::after {
      content: '${props => (props.counter ? props.counter : '')}';
      ${props => props.counter && counterNumberStyle}
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled + ${CheckboxDefault} {
    background: ${mainColors.neutralMedium};
    border-color: ${mainColors.neutralMedium};
    cursor: not-allowed;
    pointer-events: none;

    svg {
      display: hidden;
      color: ${mainColors.neutralExtremelyDark};
    }

    &::after {
      content: '${props => (props.counter ? props.counter : '')}';
      ${props => props.counter && counterNumberStyle}
      color: ${mainColors.neutralExtremelyDark};
    }
  }
`

export const CheckboxRounded = styled(CheckboxDefault)`
  border-radius: 50%;
`

export const Group = styled.div`
  > * {
    margin-bottom: 14px;
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;

  > * + * {
    margin-left: 16px;
  }
`
export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: ${mainColors.accentMedium};
    width: 18px;
    height: 18px;
    cursor: pointer;

    &:hover {
      color: ${mainColors.accentExtraDark};
    }
  }
`

export const CounterWrapper = styled.div`
  display: flex;
  align-items: center;

  ${Actions} {
    visibility: hidden;
  }

  &:hover {
    cursor: pointer;

    ${Actions} {
      visibility: visible;
    }
  }
`
