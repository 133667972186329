import React, { Ref } from 'react'

function _IconCamera(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50002 2.90039C6.29941 2.90039 6.11207 3.00065 6.00079 3.16757L5.17891 4.40039H3.50002C3.07568 4.40039 2.66871 4.56896 2.36865 4.86902C2.0686 5.16908 1.90002 5.57604 1.90002 6.00039V11.5004C1.90002 11.9247 2.0686 12.3317 2.36865 12.6318C2.66871 12.9318 3.07568 13.1004 3.50002 13.1004H12.5C12.9244 13.1004 13.3313 12.9318 13.6314 12.6318C13.9315 12.3317 14.1 11.9247 14.1 11.5004V6.00039C14.1 5.57604 13.9315 5.16908 13.6314 4.86902C13.3313 4.56896 12.9244 4.40039 12.5 4.40039H10.8211L9.99926 3.16757C9.88798 3.00065 9.70064 2.90039 9.50002 2.90039H6.50002ZM5.99925 5.33321L6.82113 4.10039H9.17891L10.0008 5.33321C10.1121 5.50013 10.2994 5.60039 10.5 5.60039H12.5C12.6061 5.60039 12.7079 5.64253 12.7829 5.71755C12.8579 5.79256 12.9 5.8943 12.9 6.00039V11.5004C12.9 11.6065 12.8579 11.7082 12.7829 11.7832C12.7079 11.8582 12.6061 11.9004 12.5 11.9004H3.50002C3.39394 11.9004 3.2922 11.8582 3.21718 11.7832C3.14217 11.7082 3.10002 11.6065 3.10002 11.5004V6.00039C3.10002 5.8943 3.14217 5.79256 3.21718 5.71755C3.2922 5.64253 3.39394 5.60039 3.50002 5.60039H5.50002C5.70064 5.60039 5.88798 5.50013 5.99925 5.33321ZM6.60002 8.50039C6.60002 7.72719 7.22683 7.10039 8.00002 7.10039C8.77322 7.10039 9.40002 7.72719 9.40002 8.50039C9.40002 9.27359 8.77322 9.90039 8.00002 9.90039C7.22683 9.90039 6.60002 9.27359 6.60002 8.50039ZM8.00002 5.90039C6.56408 5.90039 5.40002 7.06445 5.40002 8.50039C5.40002 9.93633 6.56408 11.1004 8.00002 11.1004C9.43596 11.1004 10.6 9.93633 10.6 8.50039C10.6 7.06445 9.43596 5.90039 8.00002 5.90039Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconCamera = React.forwardRef(_IconCamera)
