import { css } from 'styled-components'

import { mainColors } from '../styles/colors'
import { _M_Normal, _S_Normal } from '../styles/typography'
import { rgba } from '../utils/cssUtils'

export const rsuite = css`
  .rs {
    &-anim-fade {
      opacity: 0;
      transition: opacity 0.05s linear;
      pointer-events: none;

      &.rs-anim-in {
        opacity: 1;
        pointer-events: unset;
      }
    }

    &-picker {
      &-block {
        width: 100%;
      }

      &-check {
        &-menu-items {
          margin: 0;
          padding-bottom: 6px;
          overflow-y: auto;

          .picker-menu-items-reset {
            margin-bottom: 6px;
            overflow-y: auto;

            ul {
              list-style: none;
              margin: 0;
              padding: 0;
            }
          }
        }
      }

      &-default {
        padding: 6px 0 !important;

        .rs-picker-toggle {
          display: inline-block;
          position: relative;
          padding: 6px !important;
          color: #575757;
          background: none !important;
          border: none !important;
          cursor: pointer;
          z-index: 5;
        }
      }

      &-none {
        ${_M_Normal};
        color: ${mainColors.neutralExtraDark};
        line-height: 1;
        padding: 20px;
        text-align: center;
      }

      &-search-bar {
        &-input {
          ${_S_Normal};
          display: block;
          width: 100%;
          min-width: 200px;
          font-size: 13px !important;
          color: ${mainColors.neutralExtremelyDark} !important;
          text-indent: 16px;
          padding: 7px 36px 7px 11px;
          line-height: 1.42857143;
          background-color: ${mainColors.white};
          border: 2px solid ${mainColors.neutralMedium} !important;
          border-radius: 2px !important;
          transition: border-color ease-in-out 0.3s;

          &:hover {
            border-color: ${mainColors.neutralDark} !important;
          }

          &:active,
          &:focus {
            border-color: ${mainColors.accentMedium} !important;
            color: ${mainColors.black} !important;
            box-shadow: 0 0 0 3px ${rgba(mainColors.accentMedium, 0.25)};
            outline: 0;
          }

          &::placeholder {
            color: ${mainColors.neutralExtraDark} !important;
          }
        }

        &-search-icon {
          display: none;
        }

        &:after {
          display: none;
        }
      }

      &-toggle {
        display: flex;
        align-items: center;
        width: 100%;
        font-weight: normal;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0;
        margin-bottom: 0;
        vertical-align: middle;
        overflow: hidden;
        border: none;
        outline: 0 !important;
        user-select: none;
        text-decoration: none;
        border-radius: 6px;
        font-size: 14px;
        line-height: 20px;

        &-block {
          display: block;
        }

        &-caret {
          display: none;
        }

        &-disabled {
          opacity: 0.3;
        }

        &-placeholder {
          ${_S_Normal};
          color: ${mainColors.neutralExtremelyDark} !important;
          margin-left: 8px;
        }

        &-textbox {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border: 1px solid ${mainColors.black};
          padding-left: 10px;
          padding-right: 32px;
          color: #575757;
          background-color: ${mainColors.white};
          outline: none;
        }

        &-read-only {
          opacity: 0;
          pointer-events: none;
        }

        &-value {
          color: ${mainColors.black} !important;
          height: auto;
        }

        &-wrapper {
          padding-right: 0;
          display: inline-block;
          vertical-align: middle;
          max-width: 100%;
        }

        .rs-btn {
          &.rs-ripple-pond {
            display: none !important;
          }
        }

        &.rs-btn-lg &-textbox {
          padding-left: 14px;
        }

        &.rs-btn-sm &-textbox {
          padding-left: 8px;
        }

        &.rs-btn-xs &-textbox {
          padding-left: 6px;
        }
      }

      &-value {
        &-count {
          background-color: ${mainColors.neutralExtraDark} !important;
        }
      }

      &-menu {
        position: absolute;
        display: flex;
        flex-direction: column;
        text-align: left;
        overflow: hidden;

        &.rs-picker-check-menu {
          padding-top: 6px;
        }

        .rs-picker-search-bar {
          position: relative;
          padding: 6px 12px 12px;
        }

        .rs-picker-menu-group-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 12px;
        }
      }
    }

    &-check {
      &-item {
        &:not(.rs-checkbox-disabled):hover,
        &-focus {
          background-color: ${mainColors.neutralLight} !important;
        }
      }
    }

    &-checkbox {
      ${_M_Normal};
      display: block;
      position: relative;
      vertical-align: middle;

      &:hover {
        .rs-checkbox-inner,
        .rs-checkbox-wrapper {
          &::before {
            border-color: ${mainColors.neutralExtraDark};
          }
        }
      }

      &-wrapper {
        &::before {
          border: 2px solid ${mainColors.neutralDark};
          border-radius: 1.6px;
        }
      }

      &-checked {
        .rs-checkbox-checker {
          color: ${mainColors.black} !important;
        }

        .rs-checkbox-inner,
        .rs-checkbox-wrapper {
          &::before {
            background-color: ${mainColors.neutralExtraDark} !important;
            border-color: ${mainColors.neutralExtraDark} !important;
          }
        }
      }

      &-checker {
        position: relative;
        display: flex;
        align-items: center;
        color: ${mainColors.neutralExtraDark};
        min-height: 36px;
        line-height: 1;

        > label {
          position: relative;
          display: block;
          width: 100%;
          margin-bottom: 0;
          transition: color 0.3s linear, background-color 0.3s linear;
          line-height: 20px;
          cursor: pointer;
        }
      }

      &-inner {
        &::before {
          border: 2px solid ${mainColors.neutralDark} !important;
          border-radius: 1.6px !important;
        }
      }
    }
  }

  .av-picker-menu {
    .rs-check-item {
      .rs-checkbox {
        &-checker > label {
          padding: 8px 12px !important;
        }

        &-wrapper {
          display: none !important;
        }
      }
    }
  }
`
