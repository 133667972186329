// @ts-nocheck
import humanizeString from 'humanize-string'
import findIndex from 'lodash/findIndex'
import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import omit from 'lodash/omit'

import {
  CustomFieldKind,
  CustomFieldSectionProjection,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { removeMoneyMask } from '@avantstay/backoffice-core'

import { CustomFieldProps } from '@new/__global/components/CustomField/_types'
// eslint-disable-next-line import/no-cycle
import customFieldsDefinitions from '@new/__global/components/CustomField/types'
import { ownerStatusCustomFieldId } from '@new/domains/properties/screens/propertyInfo/_utils'
import { omitCustomFieldsSectionsIds } from '@new/domains/properties/screens/propertyInfo/contants'
import { maskByFieldType } from '@new/domains/settings/screens/customFieldsConfig/CustomFieldsConfig.definitions'

import { CustomFieldProjection } from '@arriere/types/CustomFields.types'

export const SECURITY_DEPOSIT_CUSTOM_FIELD_ID = 'f482b75c-26f8-11ea-a680-503eaadea111'
export const SQUARE_FOOTAGE_CUSTOM_FIELD_ID = '7e7b70db-6e9a-11eb-b511-295ac0a8b868'
export const BOOKING_LEAD_TIME_CUSTOM_FIELD_ID = '9ba80ac0-ea38-4dde-8046-5f5de0f05ed9'
export const PROPERTY_TYPE_CUSTOM_FIELD = 'd593ebbc-159b-41e9-9e81-c1922fe33aa3'
export const REVENUE_CONFIGURATION_SECTION_ID = '6844f51f-e09e-4713-8b93-211618c38036'
export const DIRECT_AND_OTHER_SETTINGS_SECTION_ID = 'a268c2bc-1c4a-11ea-b8f4-d787ffaf67ea'
export const LIFECYCLE_SECTION_ID = '3878fb22-91d1-4249-a118-4eab3851e60c'

interface CustomFieldsConfigProps {
  id: string
  fields?: CustomFieldProjection[]
}

export const findCustomFieldById = ({ id, fields }: CustomFieldsConfigProps) => {
  const index = findIndex(fields, { id })

  return {
    customFieldData: fields?.[index],
    index,
  }
}

export const optionsByCustomFieldKind = {
  [CustomFieldKind.boolean]: [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ],
}

export const hasOptions = [CustomFieldKind.optionsMultiple, CustomFieldKind.optionsSingle]

export function parseValuesToOptions(
  id: string,
  kind: CustomFieldKind,
  defaultValue: any,
  values?: Array<any>,
) {
  const isMultipleOptions = kind === CustomFieldKind.optionsMultiple

  return values
    ? values.map(v => {
        const value = JSON.parse(v)

        const defaultChecked =
          isMultipleOptions && defaultValue ? defaultValue.includes(value) : false

        return {
          title: humanizeString(value),
          value,
          default: defaultChecked,
          id: `${id}(${value})`,
        }
      })
    : []
}

interface IGetCustomField {
  customFields: CustomFieldSectionProjection[]
  id: string
  type: 'fields' | 'section'
}

export function getCustomField<T>({ customFields, id, type }: IGetCustomField): T | undefined {
  let field

  if (type === 'section') {
    field = customFields.find(it => it.section.id === id)
  } else {
    field = customFields
      .reduce((prev: any, it) => [...prev, ...it.fields], [])
      .find((it: CustomFieldProps) => it.id === id)
  }

  return field
}

export const notReadOnlySection = <T extends CustomFieldSectionProjection>(it: T) =>
  !it.section.readOnly

export function withFields<T extends CustomFieldSectionProjection>(it: T) {
  return !isEmpty(it.fields)
}

export const jsonParseCustomFieldValue = (customField: CustomFieldProps) => {
  try {
    return customField.value !== null || customField.value !== undefined
      ? JSON.parse(customField.value)
      : ''
  } catch (error) {
    return customField.value
  }
}

export function parseSectionFields<T extends CustomFieldSectionProjection>(it: T): T {
  return {
    ...it,
    fields: it.fields.map(field => {
      const fieldOptions = hasOptions.includes(field.kind)
        ? parseValuesToOptions(
            field.id,
            field.kind,
            JSON.parse(field.value as string),
            field.options,
          )
        : []

      return {
        ...field,
        title: field.name,
        mask: maskByFieldType[field.kind],
        value: field.value === null ? '' : field.value,
        restriction: field.restriction,
        options: optionsByCustomFieldKind[field.kind]
          ? optionsByCustomFieldKind[field.kind]
          : fieldOptions,
      }
    }),
  }
}

export function getCustomFieldsInitialValues(customFields: CustomFieldSectionProjection[]) {
  return customFields
    .map(it => {
      return it.fields.map(field => {
        const typeDefinition = customFieldsDefinitions[field.kind] || undefined

        return {
          id: field.id,
          value: typeDefinition
            ? typeDefinition.parseValueToShow(field)
            : jsonParseCustomFieldValue(field),
        }
      })
    })
    .flat()
    .reduce((prev, curr) => {
      // eslint-disable-next-line no-param-reassign
      prev[curr.id] = curr.value
      return prev
    }, {} as ValuesObject)
}

export type ValuesObject = { [key: string]: CustomFieldProps }

const filterCustomFields = (fields: CustomFieldProps[], searchTerm: string) => {
  return fields.filter(field => {
    return field.name.toLowerCase().includes(searchTerm)
  })
}

export function filterCustomFieldsBySearchTerm(
  customFields: CustomFieldSectionProjection[],
  searchTerm?: string,
): CustomFieldSectionProjection[] {
  if (!searchTerm) return customFields

  const searchTermLowerCase = searchTerm ? searchTerm.toLowerCase() : ''

  return customFields.reduce((prev: CustomFieldSectionProjection[], next) => {
    const fieldsFiltered = filterCustomFields(next.fields, searchTermLowerCase)

    const sectionExist =
      next.section.name.toLowerCase().includes(searchTermLowerCase) ||
      omitCustomFieldsSectionsIds.includes(next.section.id)

    if (fieldsFiltered.length || sectionExist) {
      return [...prev, { ...next, fields: fieldsFiltered }]
    }

    return prev
  }, [])
}

export const filterCustomFieldsByIds = (
  customFields: CustomFieldSectionProjection[],
  fieldsToRemove?: string[],
): CustomFieldSectionProjection[] => {
  if (!fieldsToRemove?.length) return customFields

  return customFields.reduce((prev: CustomFieldSectionProjection[], next) => {
    const fieldsFiltered = next.fields.filter(field => {
      return !fieldsToRemove.includes(field.id)
    })

    const sectionExist = omitCustomFieldsSectionsIds.includes(next.section.id)

    if (fieldsFiltered.length || sectionExist) {
      return [...prev, { ...next, fields: fieldsFiltered }]
    }

    return prev
  }, [])
}

export const filterCustomFieldsBySectionIds = (
  customFields: CustomFieldSectionProjection[],
  sectionsToRemove?: string[],
): CustomFieldSectionProjection[] => {
  if (!sectionsToRemove?.length) return customFields

  return customFields.filter(customField => !sectionsToRemove.includes(customField.section.id))
}

export function normalizeCustomFields(prev: any, customField: CustomFieldSectionProjection) {
  return {
    sections: [...prev.sections, customField.section],
    customFieldsbySectionId: {
      ...prev.customFieldsbySectionId,
      [customField.section.id]: customField.fields,
    },
  }
}

export function newParseCustomFieldsToSave(
  formValues: { [key: string]: any },
  customFieldsProjection: CustomFieldProps[],
) {
  const parsedFormValues = omit(formValues, [ownerStatusCustomFieldId])
  const parsedCustomFields = customFieldsProjection?.filter(
    val => val.id !== ownerStatusCustomFieldId,
  )

  return Object.entries(parsedFormValues).map(([customFieldId, value]: any) => {
    const { customFieldData } = findCustomFieldById({
      fields: parsedCustomFields,
      id: customFieldId,
    })

    const typeDefinition = customFieldsDefinitions[customFieldData?.kind]

    let parsedValue
    if (typeDefinition && typeDefinition.parseValueToSave)
      parsedValue = typeDefinition.parseValueToSave({ ...customFieldData, value })
    else if ((value === '' || value === null) && customFieldData.restriction === 'OPTIONAL')
      parsedValue = 'null'
    else parsedValue = value

    return {
      customFieldId,
      value: parsedValue,
    }
  })
}

export const isFieldEmpty = (
  fieldValue: string | Array<string> | number | boolean | null | undefined,
) => {
  if (
    fieldValue === null ||
    fieldValue === undefined ||
    fieldValue === 'null' ||
    fieldValue === 'undefined'
  ) {
    return true
  }

  if (Array.isArray(fieldValue)) {
    return fieldValue.length === 0
  }

  if (isBoolean(fieldValue) || isNumber(fieldValue)) {
    return fieldValue === null
  }

  if (typeof fieldValue === 'string') {
    return !!fieldValue.match(/\[\n]/) || isEmpty(fieldValue.trim()) || fieldValue === '""'
  }

  return false
}

export const getCustomFieldValue = (
  customFields: CustomFieldSectionProjection[],
  customFieldId: string,
) => {
  const findFieldById = (field: CustomFieldProps) => field.id === customFieldId

  const value = customFields
    .find(section => section.fields.find(findFieldById))
    ?.fields.find(findFieldById)?.value

  return value ? JSON.parse(value) : undefined
}

interface FormatCustomFieldValueProps {
  kind: CustomFieldKind
  value: string
}
export const formatCustomFieldValue = ({ kind, value }: FormatCustomFieldValueProps) => {
  switch (kind) {
    case CustomFieldKind.money:
      return removeMoneyMask(value)
    case CustomFieldKind.number:
    case CustomFieldKind.boolean:
      return value
    default:
      return JSON.stringify(value)
  }
}
