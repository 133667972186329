import styled from 'styled-components'

import { TextField } from '../../components/TextField'
import { mainColors } from '../../styles/colors'
import { _2XS_Medium, _L_Bold } from '../../styles/typography'

export const TimePickerContainer = styled.div`
  display: flex;
  align-items: center;
`

export const MaskedTextField = styled(TextField.Masked)`
  text-align: center;
`

export const TimeDivider = styled.p`
  ${_L_Bold};
  color: ${mainColors.neutralExtremelyDark};
  margin: 0 4px;
`

export const PeriodContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TimePeriod = styled.div<{ isActive?: boolean }>`
  ${_2XS_Medium};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  padding: 5px 8px;
  margin-left: 8px;
  color: ${({ isActive }) =>
    isActive ? mainColors.accentMedium : mainColors.neutralExtremelyDark};
  background-color: ${({ isActive }) =>
    isActive ? mainColors.accentExtraLight : mainColors.neutralLight};
  cursor: pointer;

  &:first-of-type {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }

  &:last-of-type {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
`
