import React from 'react'

function _IconCloseCircle(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  const { opacity, fill } = props

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <circle opacity={opacity || '0.5'} cx="8" cy="8" r="8" fill={fill || 'currentColor'} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7.16652L10.9939 4.17262C11.2241 3.94246 11.5972 3.94246 11.8274 4.17262C12.0575 4.40278 12.0575 4.77594 11.8274 5.0061L8.83348 8L11.8274 10.9939C12.0575 11.2241 12.0575 11.5972 11.8274 11.8274C11.5972 12.0575 11.2241 12.0575 10.9939 11.8274L8 8.83348L5.0061 11.8274C4.77594 12.0575 4.40278 12.0575 4.17262 11.8274C3.94246 11.5972 3.94246 11.2241 4.17262 10.9939L7.16652 8L4.17262 5.0061C3.94246 4.77594 3.94246 4.40278 4.17262 4.17262C4.40278 3.94246 4.77594 3.94246 5.0061 4.17262L8 7.16652Z"
        fill="white"
      />
    </svg>
  )
}

const IconCloseCircle = React.memo(React.forwardRef(_IconCloseCircle))

export { IconCloseCircle }
