import { ReactNode } from 'react'

export interface Location {
  id: string
  index: number
}

export interface IDragAndDropResultProps {
  source: Location
  destination: Location
}

export interface Chunk<T> {
  id: string
  items: T[]
}

export enum DragAndDropDirection {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export interface IDragAndDropGridProps<T> {
  items: T[]
  itemsPerLine: number
  direction: DragAndDropDirection
  renderItem(item: T, index: number): ReactNode
  onDragEnd(sourceIndex: number, destinationIndex: number): void
}

export interface IDragAndDropWrapperProps<T> {
  chunks: Chunk<T>[]
  itemsPerLine: number
  direction: DragAndDropDirection
  render(item: T, index: number): ReactNode
  onDragEnd(result: IDragAndDropResultProps): void
}

export interface IDragAndDropItemProps<T> {
  item: T
  index: number
  render(item: T, index: number): ReactNode
}
