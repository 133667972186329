import React, { Ref } from 'react'

function _IconVolume(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" {...props} ref={ref}>
      <path
        d="M25.2633 8.73685C24.8485 8.32185 24.1757 8.32175 23.7607 8.73662C23.3457 9.15149 23.3456 9.82423 23.7605 10.2392C25.5532 12.0325 26.5603 14.4643 26.5603 16.9999C26.5603 19.5355 25.5532 21.9674 23.7605 23.7606C23.3456 24.1756 23.3457 24.8483 23.7607 25.2632C24.1757 25.6781 24.8485 25.678 25.2633 25.263C27.4544 23.0712 28.6853 20.099 28.6853 16.9999C28.6853 13.9008 27.4544 10.9286 25.2633 8.73685ZM21.5127 12.4875C21.0978 12.0725 20.4251 12.0724 20.0101 12.4872C19.5951 12.9021 19.595 13.5749 20.0099 13.9898C20.8066 14.7868 21.2542 15.8677 21.2542 16.9946C21.2542 18.1215 20.8066 19.2024 20.0099 19.9994C19.595 20.4143 19.5951 21.0871 20.0101 21.502C20.4251 21.9168 21.0978 21.9167 21.5127 21.5017C22.7078 20.3062 23.3792 18.685 23.3792 16.9946C23.3792 15.3042 22.7078 13.683 21.5127 12.4875ZM17 9.56249C17 9.15406 16.7659 8.78179 16.3978 8.60486C16.0296 8.42793 15.5927 8.47768 15.2738 8.73282L10.2523 12.75H6.375C5.7882 12.75 5.3125 13.2257 5.3125 13.8125V20.1875C5.3125 20.7743 5.7882 21.25 6.375 21.25H10.2523L15.2738 25.2672C15.5927 25.5223 16.0296 25.5721 16.3978 25.3951C16.7659 25.2182 17 24.8459 17 24.4375V9.56249ZM11.2887 14.6422L14.875 11.7732V22.2268L11.2887 19.3578C11.1003 19.2071 10.8663 19.125 10.625 19.125H7.4375V14.875H10.625C10.8663 14.875 11.1003 14.7929 11.2887 14.6422Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconVolume = React.forwardRef(_IconVolume)
