import React, { Ref } from 'react'

function _IconXls(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" {...props} ref={ref}>
      <path
        d="M10.5884 55.4706V4.52942C10.5884 3.97713 11.0361 3.52942 11.5884 3.52942H38.8237L52.9413 19.4118V55.4706C52.9413 56.0229 52.4936 56.4706 51.9413 56.4706H11.5884C11.0361 56.4706 10.5884 56.0229 10.5884 55.4706Z"
        fill="#F2F5F7"
      />
      <path
        d="M38.8237 18.4118V3.52942L52.9414 19.4118H39.8237C39.2714 19.4118 38.8237 18.9641 38.8237 18.4118Z"
        fill="#E9EEF1"
      />
      <rect x="5.29395" y="28.2353" width="38.8235" height="19.4118" rx="1" fill="#22C4A6" />
      <path
        d="M16.5371 36.659L18.419 33.1985H20.7972L17.8744 38.1756L20.873 43.2353H18.4673L16.5371 39.7197L14.607 43.2353H12.2012L15.1998 38.1756L12.277 33.1985H14.6552L16.5371 36.659Z"
        fill="white"
      />
      <path d="M23.9889 41.574H28.3799V43.2353H21.9208V33.1985H23.9889V41.574Z" fill="white" />
      <path
        d="M34.777 40.602C34.777 40.2114 34.6391 39.9127 34.3634 39.7059C34.0877 39.4945 33.5913 39.2739 32.8744 39.0441C32.1575 38.8098 31.59 38.58 31.1718 38.3548C30.0321 37.739 29.4622 36.9095 29.4622 35.8663C29.4622 35.324 29.6139 34.8415 29.9172 34.4187C30.2251 33.9913 30.6639 33.6581 31.2338 33.4191C31.8082 33.1802 32.4516 33.0607 33.1639 33.0607C33.8809 33.0607 34.5196 33.1916 35.0803 33.4536C35.641 33.7109 36.0753 34.0763 36.3832 34.5496C36.6957 35.023 36.8519 35.5607 36.8519 36.1627H34.7839C34.7839 35.7031 34.6391 35.347 34.3496 35.0942C34.0601 34.8369 33.6534 34.7082 33.1295 34.7082C32.624 34.7082 32.231 34.8162 31.9507 35.0322C31.6704 35.2436 31.5302 35.5239 31.5302 35.8732C31.5302 36.1995 31.6934 36.4729 32.0196 36.6935C32.3505 36.9141 32.8354 37.1209 33.4741 37.3139C34.6506 37.6677 35.5077 38.1066 36.0454 38.6305C36.5831 39.1544 36.8519 39.807 36.8519 40.5882C36.8519 41.4568 36.5233 42.1393 35.8662 42.6356C35.209 43.1273 34.3243 43.3732 33.2122 43.3732C32.4401 43.3732 31.737 43.233 31.1028 42.9527C30.4686 42.6677 29.9838 42.2794 29.6483 41.7877C29.3174 41.296 29.152 40.7261 29.152 40.0781H31.2269C31.2269 41.1857 31.8887 41.7394 33.2122 41.7394C33.7039 41.7394 34.0877 41.6406 34.3634 41.443C34.6391 41.2408 34.777 40.9605 34.777 40.602Z"
        fill="white"
      />
    </svg>
  )
}

export const IconXls = React.forwardRef(_IconXls)
