import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconStatusOk(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        fill="#DEECFF"
        stroke="#53ACFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0745 8.4423C17.3821 8.75949 17.3743 9.26596 17.0571 9.57354L10.8696 15.5735C10.5593 15.8744 10.0661 15.8744 9.75578 15.5735L6.94328 12.8463C6.62609 12.5387 6.6183 12.0322 6.92588 11.715C7.23345 11.3978 7.73992 11.39 8.05711 11.6976L10.3127 13.8849L15.9433 8.4249C16.2605 8.11732 16.7669 8.12511 17.0745 8.4423Z"
        fill="#3788F7"
      />
    </svg>
  )
}

export const IconStatusOk = forwardRef(_IconStatusOk)
