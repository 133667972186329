import React, { Ref } from 'react'

function _IconStatusNeutral(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        fill="#F2F5F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.19995 11.9992C7.19995 11.5574 7.55812 11.1992 7.99995 11.1992H16C16.4418 11.1992 16.8 11.5574 16.8 11.9992C16.8 12.441 16.4418 12.7992 16 12.7992H7.99995C7.55812 12.7992 7.19995 12.441 7.19995 11.9992Z"
        fill="#ACB8BF"
      />
    </svg>
  )
}

export const IconStatusNeutral = React.forwardRef(_IconStatusNeutral)
