import React from 'react'

import * as S from './ToggleField.styles'
import { ToggleBooleanType, ToggleFieldProps, ToggleSwitchInputProps } from './ToggleField.types'

// Keep this export clean, free of wrapping HOCs
const ToggleButton = ({
  onChange,
  id,
  value,
  defaultChecked,
  label,
  title,
  toggleType = ToggleBooleanType.true,
  checked,
  fill,
}: ToggleSwitchInputProps) => (
  <S.Container>
    <S.HiddenInput
      type="radio"
      onChange={onChange}
      id={id}
      value={value}
      checked={checked}
      defaultChecked={defaultChecked}
      name={label}
      toggleType={toggleType}
      fill={fill}
    />
    <S.ToggleDefault htmlFor={id} data-testid="input-toggle-field">
      <S.Title>{title}</S.Title>
    </S.ToggleDefault>
  </S.Container>
)

const ToggleField = ({ children, autofill = false, className }: ToggleFieldProps): JSX.Element => (
  <S.ToggleFieldContainer autofill={autofill} className={className}>
    {children}
  </S.ToggleFieldContainer>
)

ToggleField.Option = ToggleButton

// If you need to wrap this component with HOCs, use them here
export { ToggleField }
