import axios from 'axios'

import { AssetContextType } from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { UploadFileStore } from '@stores/UploadFileStore'

export interface CreateAssetProps {
  context: AssetContextType
  file: File
  fileId: string
  targetId: string
  public: boolean
  onUploadProgress?: (fileId: string) => (percentage: number) => void

  processFileToUpload?(file: File): Promise<File>
}

export async function createAsset({
  processFileToUpload,
  public: assetIsPublic,
  context,
  file,
  fileId,
  targetId,
  onUploadProgress,
}: CreateAssetProps) {
  const extension = file.name.split('.').pop()
  const fileName = encodeURIComponent(file.name)
  const fileToUpload = processFileToUpload ? await processFileToUpload(file) : file

  const result = await UploadFileStore.createAsset({
    context,
    extension: extension || '',
    public: assetIsPublic,
    targetId,
    size: fileToUpload.size,
    fileName,
  })

  const uploadProgressCb = onUploadProgress?.(fileId)

  if (result?.url) {
    await axios.put(result?.url, fileToUpload, {
      headers: {
        'Content-Type': fileToUpload.type,
        'Content-Disposition': `attachment; filename="${fileName}"`,
      },
      onUploadProgress: (e: ProgressEvent) => {
        uploadProgressCb?.(e.loaded / e.total)
      },
    })

    return {
      externalUrl: result?.externalUrl,
      assetId: result?.assetId,
    localUrl: URL.createObjectURL(file),
    fileName: fileName,
  }
}

  return {
    externalUrl: '',
    assetId: '',
    localUrl: '',
  }
}
