import { CustomFieldSectionProjection } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import {
  customFieldsSectionIdList,
  propertyInternalConfigurationCustomFieldsSections,
} from '@new/domains/properties/constants'

export const internalConfigurationSectionsIds =
  propertyInternalConfigurationCustomFieldsSections.map(
    section => customFieldsSectionIdList[section],
  )

export const onlyInternalConfigurationSections = <T extends CustomFieldSectionProjection>(
  customFields: T,
) => internalConfigurationSectionsIds.includes(customFields?.section.id)
