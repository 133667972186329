import { useCallback, useEffect, useState } from 'react'

export function useDisableBodyScroll(shouldDisable: boolean, disableBodyScroll?: boolean) {
  const body = document.body
  const [bodyHeight] = useState(body.style.height)
  const [bodyOverflowY] = useState(body.style.overflowY)

  const enableScroll = useCallback(() => {
    body.style.height = bodyHeight
    body.style.overflowY = bodyOverflowY
  }, [body, bodyHeight, bodyOverflowY])

  useEffect(() => {
    if (Boolean(disableBodyScroll)) return

    if (shouldDisable) {
      body.style.height = '100vh'
      body.style.overflowY = 'hidden'
    } else {
      enableScroll()
    }

    return enableScroll
  }, [enableScroll, shouldDisable, disableBodyScroll, bodyHeight, bodyOverflowY, body])
}
