import React, { forwardRef, Ref, SVGProps } from 'react'

function _ConfigurationStatusWarning(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        fill="#FFBD5A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0955 9.77036C11.3535 9.62312 11.6596 9.56813 11.9585 9.6179C12.2571 9.66764 12.5232 9.81768 12.7121 10.0355C12.9005 10.2528 13.0006 10.5239 13.0002 10.8003L13.0002 10.8015C13.0002 11.0318 12.8089 11.3229 12.3363 11.6288C12.1273 11.7641 11.9125 11.869 11.7465 11.9406C11.6647 11.9759 11.5976 12.0019 11.5528 12.0185C11.5305 12.0267 11.5139 12.0326 11.504 12.036L11.4937 12.0395C11.0745 12.1763 10.8449 12.6266 10.9808 13.0463C11.1168 13.4667 11.5679 13.6972 11.9882 13.5611L11.7421 12.8007C11.9882 13.5611 11.989 13.5609 11.9892 13.5608L11.9904 13.5604L11.9933 13.5595L12.0013 13.5568L12.0261 13.5484C12.0463 13.5414 12.0739 13.5316 12.1079 13.5191C12.1756 13.494 12.2693 13.4576 12.3805 13.4096C12.6005 13.3147 12.9002 13.1698 13.2058 12.972C13.7623 12.6117 14.5999 11.9038 14.6002 10.8021C14.6011 10.1348 14.3586 9.4921 13.921 8.98741C13.4838 8.48312 12.8808 8.14948 12.2213 8.03964C11.5619 7.92982 10.8826 8.04967 10.3025 8.3807C9.72192 8.71199 9.27538 9.2354 9.04806 9.86281C8.89756 10.2782 9.1123 10.737 9.52771 10.8875C9.94311 11.038 10.4019 10.8232 10.5524 10.4078C10.6477 10.1448 10.8379 9.91735 11.0955 9.77036ZM11.7172 12.7238L11.4956 12.0389C11.4957 12.0388 11.4957 12.0388 11.5243 12.1274C11.5523 12.214 11.6077 12.3852 11.7172 12.7238ZM11.8002 16.9C12.2421 16.9 12.6002 16.5419 12.6002 16.1C12.6002 15.6582 12.2421 15.3 11.8002 15.3C11.3584 15.3 11.0002 15.6582 11.0002 16.1C11.0002 16.5419 11.3584 16.9 11.8002 16.9Z"
        fill="#FF9E0D"
      />
    </svg>
  )
}

export const ConfigurationStatusWarning = forwardRef(_ConfigurationStatusWarning)
