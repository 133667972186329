import styled from 'styled-components'

import {
  _S_Bold,
  _S_Normal,
  FilePreviewActionsStyles,
  mainColors,
} from '@avantstay/backoffice-core'

import { ImageContainerProps } from '../../FilePreview/_types'

// File Details
export const DetailsContainer = styled.div<ImageContainerProps>`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 4px 6px 10px;
  height: 32px;
  width: 100%;
  background-color: ${({ $isCover, error }) =>
    FilePreviewActionsStyles.getDetailContainerBackgroundColor({ $isCover, error })};
  border-radius: 4px 4px 0 0;

  svg,
  p {
    color: ${({ $isCover, error }) =>
      $isCover || error ? mainColors.neutralExtraLight : mainColors.neutralExtraDark};
  }
`

export const FileTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`

export const FileIndex = styled.p<{ error?: boolean }>`
  ${_S_Bold};
  color: ${mainColors.neutralExtremelyDark};
  ${({ error }) => error && 'opacity: 0.7'};
`

export const DetailActions = styled.div`
  display: grid;
  grid-auto-columns: 22px;
  grid-auto-flow: column;
  align-items: center;
  gap: 2px;

  > svg,
  > div {
    align-self: center;
    justify-self: center;
  }
`

export const FileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
`

export const FileInfoLabel = styled.p`
  ${_S_Normal};
  color: ${mainColors.neutralDark};
`

export const FileInfoContent = styled.span`
  color: ${mainColors.white};
`

export const SelectImageButton = styled.button<{ isSelected?: boolean }>`
  position: relative;
  width: 18px;
  height: 18px;
  background-color: ${mainColors.white};
  border-radius: 50%;
  ${({ isSelected }) => !isSelected && `border: 2px solid ${mainColors.neutralDark}`};

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    color: ${mainColors.white};
    background-color: ${mainColors.accentMedium};
    border-radius: 50%;
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
    transform: translate(-50%, -50%);
  }
`
