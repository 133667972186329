import { useEffect } from 'react'

import { AlertProps, EVENT_NAME } from '../Alert.definitions'

type EventCallback = (e: { detail: AlertProps }) => void

interface CustomWindow {
  addEventListener(type: string, callback: EventCallback): void
  removeEventListener(type: string, callback: EventCallback): void
}

export function useSubscribeEvent(eventCallback: (alert: AlertProps) => void) {
  useEffect(() => {
    const typedWindow = window as CustomWindow
    typedWindow.addEventListener(EVENT_NAME, e => eventCallback(e.detail))

    return () => typedWindow.removeEventListener(EVENT_NAME, e => eventCallback(e.detail))
  }, [eventCallback])
}
