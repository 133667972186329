import React, { Ref } from 'react'

function _IconSendAgain(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22275 7.69857C8.34683 7.49002 8.59566 7.33519 8.9033 7.33519H20.7743C21.0819 7.33519 21.3308 7.49006 21.4549 7.69864L14.8389 11.86L8.22275 7.69857ZM8.13703 9.25799L14.4373 13.2208C14.68 13.3734 14.9978 13.3734 15.2404 13.2208L21.5405 9.25808V16C21.5405 16.3414 21.2188 16.6647 20.7743 16.6647H8.9033C8.45878 16.6647 8.13703 16.3414 8.13703 16C8.13703 15.6307 7.81575 15.3314 7.41943 15.3314C7.02311 15.3314 6.70183 15.6307 6.70183 16C6.70183 17.1251 7.71556 18.0019 8.9033 18.0019H20.7743C21.962 18.0019 22.9757 17.1251 22.9757 16V8.01265C22.9759 8.00274 22.9759 7.99282 22.9757 7.98289C22.9655 6.86592 21.956 5.99805 20.7743 5.99805H8.9033C7.71638 5.99805 6.70324 6.87355 6.70183 7.99761L6.70183 7.99995V9.35204C6.70183 9.72128 7.02311 10.0206 7.41943 10.0206C7.81575 10.0206 8.13703 9.72128 8.13703 9.35204V9.25799ZM0.0244141 11.3333C0.0244141 10.9641 0.345695 10.6647 0.742014 10.6647H8.9033C9.29962 10.6647 9.6209 10.9641 9.6209 11.3333C9.6209 11.7025 9.29962 12.0019 8.9033 12.0019H0.742014C0.345695 12.0019 0.0244141 11.7025 0.0244141 11.3333ZM5.19355 13.3314C4.79723 13.3314 4.47595 13.6307 4.47595 13.9999C4.47595 14.3692 4.79723 14.6685 5.19355 14.6685H8.90322C9.29954 14.6685 9.62082 14.3692 9.62082 13.9999C9.62082 13.6307 9.29954 13.3314 8.90322 13.3314H5.19355Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconSendAgain = React.forwardRef(_IconSendAgain)
