import styled, { css } from 'styled-components'

import {
  ButtonDanger,
  ButtonNeutral,
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
} from '../../components/Button/Button.styles'

const commonStyles = css`
  width: auto;
  padding: 5px;
  min-width: 0;
  min-height: 0;
  border-radius: 50%;

  & svg {
    width: 20px;
    height: 20px;
  }
`

export const IconButtonDanger = styled(ButtonDanger)`
  ${commonStyles}
`

export const IconButtonNeutral = styled(ButtonNeutral)`
  ${commonStyles}
`

export const IconButtonPrimary = styled(ButtonPrimary)`
  ${commonStyles}
`

export const IconButtonSecondary = styled(ButtonSecondary)`
  ${commonStyles}
`

export const IconButtonTertiary = styled(ButtonTertiary)`
  ${commonStyles}
`
