import arriereBackoffice, {
  CouponCounters,
  CouponUpdateInput,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import {
  AddCouponMigration,
  CouponQueryFilters,
  CouponResult,
  PaginatedCouponsResult,
} from '@new/domains/marketing/screens/Coupons/__types'

export default {
  getCouponsCounter(): Promise<CouponCounters> {
    return arriereBackoffice.queries.couponCounters({
      active: true,
      inactive: true,
      expired: true,
      total: true,
    })
  },
  getPaginatedCoupons(variables: CouponQueryFilters): Promise<PaginatedCouponsResult> {
    return arriereBackoffice.queries.coupons({
      __args: variables,
      results: {
        code: true,
        description: true,
        isActive: true,
        discountValue: true,
        discountType: true,
        salesFrom: true,
        salesTo: true,
        bookingPeriodType: true,
        bookingFrom: true,
        bookingTo: true,
        minimumPrice: true,
        minimumStay: true,
        maximumUsages: true,
        createdAt: true,
        usages: true,
      },
      total: true,
      pages: true,
    })
  },
  createCoupon(coupon: AddCouponMigration): Promise<string> {
    return arriereBackoffice.mutations.createCoupon({
      __args: {
        ...coupon,
      },
    })
  },
  getCoupon(code: string): Promise<CouponResult> {
    return arriereBackoffice.queries.coupon({
      __alias: 'getCoupon',
      __args: {
        code,
      },
      code: true,
      description: true,
      salesFrom: true,
      salesTo: true,
      bookingPeriodType: true,
      bookingFrom: true,
      bookingTo: true,
      maximumDiscount: true,
      maximumUsages: true,
      minimumPrice: true,
      minimumStay: true,
      discountType: true,
      discountValue: true,
      applicableTo: true,
      homes: {
        id: true,
        name: true,
        region: {
          name: true,
        },
        revenueModel: true,
        links: {
          listings: {
            leadSource: true,
            url: true,
          },
        },
      },
      createdAt: true,
      isActive: true,
      usages: true,
    })
  },
  updateCoupon(newCode: string, coupon: CouponUpdateInput): Promise<string> {
    return arriereBackoffice.mutations.updateCoupon({
      __args: {
        input: coupon,
        newCode,
      },
    })
  },
  enableCoupon(code: string): Promise<string> {
    return arriereBackoffice.mutations.enableCoupon({
      __args: {
        code,
      },
    })
  },
  disableCoupon(code: string): Promise<string> {
    return arriereBackoffice.mutations.disableCoupon({
      __args: {
        code,
      },
    })
  },
}
