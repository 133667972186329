import styled from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _L_Medium, _M_Medium, _M_Normal, _S_Normal, _XS_Medium } from '../../styles/typography'
import { mobileMediaQuery } from '../../utils/mediaQueries'

export const ReviewCardContainer = styled.div`
  max-width: 700px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${mainColors.neutralMedium};
  }
`

export const ReviewBreakdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  border-bottom: 1px solid ${mainColors.neutralMedium};
  padding-bottom: 20px;

  ${mobileMediaQuery`
    flex-flow: column;
    align-items: flex-start;
  `}
`

export const OverallContainer = styled.div`
  padding-right: 20px;
`

export const ReviewRatingLabel = styled.p`
  ${_M_Medium};
`

export const ReviewCategoryLabel = styled.p`
  ${_XS_Medium};
  color: ${mainColors.neutralExtraDark};
`

export const BookingDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 15px;
  padding-bottom: 12px;
`

export const GuestName = styled.p`
  ${_L_Medium};
  color: ${mainColors.accentMedium};
  margin-bottom: 2px;
  cursor: pointer;
`

export const BookingPeriodDates = styled.p`
  ${_S_Normal};
  color: ${mainColors.neutralExtremelyDark};
`

export const ReviewCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ReviewCommentHeader = styled.p`
  ${_M_Medium};
  margin-bottom: 2px;
`

export const ReviewComment = styled.p`
  ${_M_Normal};
  color: ${mainColors.neutralExtremelyDark};
`
