import React, { useRef } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { useOnClickOutside } from '@avantstay/backoffice-core'

import { IconChevronLeft, IconChevronRight, IconClose } from '@avantstay/backoffice-vectors'

import { ImagePreviewProps } from './_types'
import * as S from './FilePreview.styles'

function ImagePreviewModal({
  allFiles,
  imageIndex,
  onCloseImagePreview,
}: ImagePreviewProps): JSX.Element {
  const backgroundRef = useRef(null)

  useOnClickOutside(backgroundRef, () => onCloseImagePreview())
  return (
    <S.CarouselContainer>
      <S.CarouselBackground ref={backgroundRef}>
        <S.CloseCarouselButton onClick={onCloseImagePreview}>
          <IconClose />
        </S.CloseCarouselButton>
        <Carousel
          showArrows={allFiles.length > 1}
          showIndicators={false}
          showStatus={false}
          selectedItem={imageIndex}
          renderArrowPrev={(handleClick, isEnable) => (
            <S.CarouselArrowButton isPrevious onClick={handleClick} disabled={!isEnable}>
              <IconChevronLeft />
            </S.CarouselArrowButton>
          )}
          renderArrowNext={(handleClick, isEnable) => (
            <S.CarouselArrowButton onClick={handleClick} disabled={!isEnable}>
              <IconChevronRight />
            </S.CarouselArrowButton>
          )}
        >
          {allFiles?.map(photo => <S.ImagePreview key={photo.id} src={photo.url} quality={80} />)}
        </Carousel>
      </S.CarouselBackground>
    </S.CarouselContainer>
  )
}

export default ImagePreviewModal
