import { _3XS_Normal, mainColors, semanticColors } from '../../../../styles'
import styled from 'styled-components'

export const Progress = styled.div<{
  width?: number
}>`
  height: 4px;
  width: ${({ width }) => width}px;
  border-radius: 20px;
  background: ${mainColors.neutralLight};
  color: ${mainColors.neutralDark};
`

export const Bar = styled(Progress)`
  background: ${semanticColors.successMedium};
  transition: 0.5s ease;
`

export const ProgressContainer = styled.div`
  position: absolute;
  bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export const ProgressValue = styled.div`
  ${_3XS_Normal};
  color: ${mainColors.white};
  width: 25px;
  text-align: right;
`
