import styled from 'styled-components'

import { IconFullscreen } from '@avantstay/backoffice-vectors'

import { TextAreaDefault } from '../../components/TextArea/TextArea.styles'
import { mainColors, semanticColors } from '../../styles/colors'
import { _3XS_Normal, _S_Normal } from '../../styles/typography'
import { MarkdownActionButtonProps, MarkdownFieldStyledProps } from './_types'

const borderColor = ({
  preview,
  error,
  focus,
  hover,
}: {
  preview?: boolean
  error?: string
  focus?: boolean
  hover?: boolean
}) => {
  if (focus) {
    return mainColors.accentMedium
  }
  if (error) {
    return semanticColors.failureMedium
  }
  if (preview) {
    return mainColors.neutralExtraLight
  }
  if (hover) {
    return mainColors.neutralDark
  }
  return mainColors.neutralMedium
}

export const Container = styled.div<MarkdownFieldStyledProps>`
  position: relative;
  border: solid 2px ${({ preview, error, focus }) => borderColor({ preview, error, focus })};
  border-radius: 2px;

  &:hover {
    border-color: ${({ error, focus, preview }) =>
      borderColor({ error, focus, preview, hover: true })};
  }
`

export const MarkdownTextArea = styled(TextAreaDefault)`
  padding: 15px;
  border: 0;
  min-height: 200px;
  display: block;
  max-height: 500px;

  &::placeholder {
    color: ${mainColors.neutralExtraDark};
  }
`

export const ActionButtonsContainer = styled.div<MarkdownFieldStyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: solid 2px ${({ preview }) => borderColor({ preview })};
`

export const ViewSwitch = styled.div`
  display: flex;
  gap: 10px;
`

export const ViewSwitchButton = styled.button<{ active: boolean }>`
  background: transparent;
  color: ${props => (props.active ? mainColors.primaryDark : mainColors.neutralDark)};
  ${_S_Normal};
`

export const Actions = styled.div`
  display: flex;
  gap: 10px;
`

export const ActionButton = styled.button<MarkdownActionButtonProps>`
  background: ${mainColors.neutralLight};
  border-radius: 2px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;

  svg {
    color: ${mainColors.neutralExtraDark};
  }

  &:hover {
    background: ${mainColors.neutralMedium};
    svg {
      color: ${mainColors.neutralExtremelyDark};
    }
  }
`

export const MarkdownHint = styled(ActionButtonsContainer)`
  ${_3XS_Normal};
  bottom: 0;
  right: 0;
  top: auto;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  height: 25px;
  width: calc(100% - 15px);
  color: ${mainColors.neutralDark};
  background-color: ${mainColors.white};
`

export const PreviewRoot = styled.div`
  padding: 15px;
  min-height: 200px;
  overflow-y: auto;
  max-height: 500px;
`

export const Formatting = styled.div`
  display: flex;
  gap: 10px;
  color: ${mainColors.neutralExtraDark};
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Fullscreen = styled(IconFullscreen)`
  width: 22px;
  height: 22px;
`
