import styled from 'styled-components'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`

export const Phone = styled.div`
  display: grid;
  gap: 20px;

  button {
    width: fit-content;
    padding: 10px 54px;
  }
`
