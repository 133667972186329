import {
  BookingLeadChannel,
  BookingLeadViewStatus,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { BadgeColors, BadgeColorsProps } from '@avantstay/backoffice-core'

export const bookingLeadStatusLabel: Record<string, string> = {
  [BookingLeadViewStatus.contacted]: 'Contacted',
  [BookingLeadViewStatus.converted]: 'Converted',
  [BookingLeadViewStatus.engaged]: 'Engaged',
  [BookingLeadViewStatus.lostInterest]: 'Lost Interest',
  [BookingLeadViewStatus.newInquiry]: 'New Inquiry',
  [BookingLeadViewStatus.quoteSent]: 'Quote Sent',
}

export const badgeColorByBookingLeadStatus: Record<string, BadgeColorsProps> = {
  [BookingLeadViewStatus.contacted]: BadgeColors.channelBookingCom,
  [BookingLeadViewStatus.converted]: BadgeColors.successDark,
  [BookingLeadViewStatus.engaged]: BadgeColors.channelWebsite,
  [BookingLeadViewStatus.lostInterest]: BadgeColors.neutral,
  [BookingLeadViewStatus.newInquiry]: BadgeColors.accent,
  [BookingLeadViewStatus.quoteSent]: BadgeColors.channelPersona,
}

export const bookingLeadChannelLabel: Record<string, string> = {
  [BookingLeadChannel.chat]: 'Chat',
  [BookingLeadChannel.email]: 'Email',
  [BookingLeadChannel.ota]: 'OTA',
  [BookingLeadChannel.other]: 'Other',
  [BookingLeadChannel.phone]: 'Phone',
  [BookingLeadChannel.text]: 'Text',
}

export const followUpLeadLabel: Record<string, string> = {
  true: 'Needs Follow Up',
  false: 'No Follow Up',
}

export const badgeColorByFollowUpLead: Record<string, BadgeColorsProps> = {
  true: BadgeColors.accent,
  false: BadgeColors.neutral,
}
