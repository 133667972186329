import React, { SVGProps } from 'react'

export const IconPreformatted = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M1.05126 8.05156L4.86931 9.58781V11.0269L0 8.76807V7.81475L1.05126 8.05156ZM4.86931 6.79464L1.05126 8.35517L0 8.56769V7.61437L4.86931 5.36162V6.79464Z"
      fill="currentColor"
    />
    <path d="M10.0332 3.19995L6.75812 12.8H5.66643L8.94729 3.19995H10.0332Z" fill="currentColor" />
    <path
      d="M14.8968 8.29445L11.0094 6.77035V5.35555L16 7.6083V8.56769L14.8968 8.29445ZM11.0094 9.60602L14.9083 8.05156L16 7.80868V8.762L11.0094 11.0208V9.60602Z"
      fill="currentColor"
    />
  </svg>
)
