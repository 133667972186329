import React, { Ref } from 'react'

function _IconReviews2(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 4.07125H3.5V17.5344H7.89291C8.87952 17.5344 9.83133 17.912 10.5654 18.5947L12 19.9288L13.4346 18.5947C14.1687 17.912 15.1205 17.5344 16.1071 17.5344H20.5V4.07125ZM3.5 2C2.39543 2 1.5 2.92733 1.5 4.07125V17.5344C1.5 18.6783 2.39543 19.6056 3.5 19.6056H7.89291C8.38622 19.6056 8.86212 19.7944 9.22916 20.1358L10.6637 21.4699C11.4238 22.1767 12.5762 22.1767 13.3363 21.4699L14.7708 20.1358C15.1379 19.7944 15.6138 19.6056 16.1071 19.6056H20.5C21.6046 19.6056 22.5 18.6783 22.5 17.5344V4.07125C22.5 2.92733 21.6046 2 20.5 2H3.5ZM6.5 8C6.5 7.44772 6.94772 7 7.5 7H16.5C17.0523 7 17.5 7.44772 17.5 8C17.5 8.55228 17.0523 9 16.5 9H7.5C6.94772 9 6.5 8.55228 6.5 8ZM7.5 12C6.94772 12 6.5 12.4477 6.5 13C6.5 13.5523 6.94772 14 7.5 14H16.5C17.0523 14 17.5 13.5523 17.5 13C17.5 12.4477 17.0523 12 16.5 12H7.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconReviews2 = React.forwardRef(_IconReviews2)
