export const stringifyValue = (fieldValue: any) => {
  const singleQuotesRegex = /".*"/
  const doubleQuotesRegex = /"".*""/

  if (fieldValue?.match(doubleQuotesRegex)) {
    return fieldValue.replace(doubleQuotesRegex, '')
  }

  if (fieldValue?.match(singleQuotesRegex)) {
    return fieldValue
  }

  return JSON.stringify(fieldValue)
}
