import React, { Ref, SVGProps } from 'react'

function _IconUser3(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.83217 9.775C8.83217 8.2032 10.1379 6.90373 11.78 6.90373C13.4222 6.90373 14.7278 8.2032 14.7278 9.775C14.7278 11.3468 13.4222 12.6463 11.78 12.6463C10.1379 12.6463 8.83217 11.3468 8.83217 9.775ZM11.78 5.5C9.39081 5.5 7.42844 7.40003 7.42844 9.775C7.42844 11.4729 8.43139 12.928 9.87624 13.6197C7.60883 14.3391 5.93115 16.309 5.93115 18.7075C5.93115 19.0952 6.24539 19.4094 6.63302 19.4094C7.02065 19.4094 7.33488 19.0952 7.33488 18.7075C7.33488 17.4926 7.95846 16.3724 8.96017 15.6327C9.47081 16.665 10.5464 17.3682 11.7803 17.3682C13.0141 17.3682 14.0896 16.6652 14.6004 15.633C15.6018 16.3728 16.2252 17.4927 16.2252 18.7075C16.2252 19.0952 16.5394 19.4094 16.927 19.4094C17.3147 19.4094 17.6289 19.0952 17.6289 18.7075C17.6289 16.309 15.9512 14.3391 13.6838 13.6197C15.1286 12.928 16.1316 11.4729 16.1316 9.775C16.1316 7.40003 14.1692 5.5 11.78 5.5ZM11.78 14.7303C12.3388 14.7303 12.8684 14.8209 13.3543 14.9853C13.0826 15.5581 12.4848 15.9645 11.7803 15.9645C11.0758 15.9645 10.478 15.558 10.2063 14.9851C10.692 14.8209 11.2214 14.7303 11.78 14.7303Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconUser3 = React.forwardRef(_IconUser3)
