import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconBlockedEye(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.41681 13.0765C6.13851 14.9722 9.00667 16.9985 11.8948 16.9985H11.9908L12.0901 16.9991C14.9883 16.9991 17.8618 14.9728 19.5855 13.0758C20.1387 12.4619 20.138 11.5361 19.5841 10.9209C17.8611 9.02589 14.9909 7.00092 12.1061 7.00092C12.0688 7.00092 12.0321 7.00092 12.0041 7.00158C11.9701 7.00092 11.9375 7.00092 11.9035 7.00092C9.00933 7.00092 6.13784 9.02589 4.41548 10.9222C3.86291 11.5354 3.86291 12.4613 4.41681 13.0765ZM5.15668 11.5928C6.7344 9.85574 9.33528 8.00007 11.9022 8.00007L11.9908 8.00141L12.0948 8.00074C14.6617 8.00074 17.2639 9.85574 18.8423 11.5914C19.0516 11.8241 19.0516 12.174 18.8436 12.4046C17.2645 14.1423 14.659 15.9987 12.0815 15.9987L11.9908 15.998L11.9035 15.9987C9.33528 15.9987 6.73507 14.1423 5.15802 12.406C4.94872 12.1733 4.94872 11.8234 5.15668 11.5928ZM9.87785 14.119C10.4444 14.6855 11.1976 14.9981 11.9988 14.9981H11.9995C12.8007 14.9981 13.5532 14.6862 14.1198 14.1203C14.6863 13.5537 14.9989 12.8012 14.9989 11.9973C14.9989 11.1962 14.687 10.4436 14.1204 9.87705C13.5539 9.31115 12.8007 8.99921 11.9995 8.99921C10.3458 8.99921 9 10.3443 9 11.998C8.99934 12.7992 9.31128 13.5524 9.87785 14.119ZM10.0005 11.998C10.0005 10.8955 10.8977 9.99903 12.0001 9.99903C12.534 9.99903 13.036 10.207 13.4132 10.5836C13.7912 10.9609 13.9991 11.4628 13.9991 11.9987C13.9991 12.5326 13.7905 13.0352 13.4132 13.4124C13.036 13.7897 12.534 13.9977 11.9995 13.9977C11.4656 13.9977 10.9637 13.7897 10.5857 13.4118C10.2085 13.0345 10.0005 12.5319 10.0005 11.998Z"
        fill="#94A4AD"
      />
      <path d="M4.90002 5.5L17.4 18.5" stroke="#94A4AD" strokeWidth="1.2" strokeLinecap="round" />
      <path d="M5.59998 4.5L18.1 17.5" stroke="white" strokeWidth="1.2" strokeLinecap="round" />
    </svg>
  )
}

export const IconBlockedEye = forwardRef(_IconBlockedEye)
