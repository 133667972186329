import _ from 'lodash'

import { Chunk } from '../_types'

export function chunkItems<T>(
  itemsPerChunk: number,
  items: T[],
  createId: () => string,
): Chunk<T>[] {
  const slicedItemsIntoChunks: T[][] = _.chunk(items, itemsPerChunk)

  return slicedItemsIntoChunks.map(addIdToChunk(createId))
}

function addIdToChunk<T>(addId: () => string) {
  return function (items: T[]): Chunk<T> {
    return {
      id: addId(),
      items,
    }
  }
}
