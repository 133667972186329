import React from 'react'

function _IconChevronUp(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.50507 10.4952C3.77844 10.7685 4.22166 10.7685 4.49502 10.4952L8.00005 6.99014L11.5051 10.4952C11.7784 10.7685 12.2217 10.7685 12.495 10.4952C12.7684 10.2218 12.7684 9.77859 12.495 9.50522L8.49502 5.50522C8.22166 5.23185 7.77844 5.23185 7.50507 5.50522L3.50507 9.50522C3.23171 9.77859 3.23171 10.2218 3.50507 10.4952Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconChevronUp = React.memo(React.forwardRef(_IconChevronUp))

export { IconChevronUp }
