import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'

import { ITimeRangeFieldProps } from '@new/__ui/TimeRangeField/_types'

import { removeSpaces } from '@utils/strings'

import InfoTooltip from '../InfoTooltip/InfoTooltip'
import * as S from './TimeRangeField.styles'

const MASK = [/\d/, /\d/, ':', /\d/, /\d/, /[A-Za-z]/, /[A-Za-z]/]

function TimeRangeField(props: ITimeRangeFieldProps) {
  const [focus, setFocus] = useState(false)
  const [lowerEnd, setLowerEnd] = React.useState<string>('')
  const [upperEnd, setUpperEnd] = React.useState<string>('')

  const handleOnChange = () => {
    const lowerEndWithoutSpaces = removeSpaces(lowerEnd)
    const upperEndWithoutSpaces = removeSpaces(upperEnd)

    const activeValue =
      lowerEndWithoutSpaces && upperEndWithoutSpaces
        ? [lowerEndWithoutSpaces, upperEndWithoutSpaces].join(',')
        : ''

    props.onChange(activeValue)
  }

  React.useEffect(handleOnChange, [lowerEnd, upperEnd])

  React.useEffect(() => {
    if (!isEmpty(lowerEnd) || !isEmpty(upperEnd)) return

    const splitValues = (props.value || '').split(',')
    setLowerEnd(splitValues[0])
    setUpperEnd(splitValues[1])
  }, [props.value, lowerEnd, upperEnd])

  return (
    <S.Container
      focus={focus}
      error={props.error}
      warning={props.warning}
      readOnly={props.readOnly}
      disabled={props.disabled}
      small={props.small}
    >
      <S.TimeLabel>START</S.TimeLabel>
      <S.TimeRangeInput
        type="text"
        placeholder="__:__ AM/PM"
        mask={MASK}
        value={lowerEnd}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        onChange={e => setLowerEnd(e.target.value as string)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <S.TimeLabel>END</S.TimeLabel>
      <S.TimeRangeInput
        type="text"
        placeholder="__:__ AM/PM"
        mask={MASK}
        value={upperEnd}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        onChange={e => setUpperEnd(e.target.value as string)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <S.IssueContainer small={props.small}>
        {(props.error || props.warning) && (
          <InfoTooltip error={props.error} warning={props.warning} />
        )}
      </S.IssueContainer>
    </S.Container>
  )
}

export default TimeRangeField
