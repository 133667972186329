import React, { Ref } from 'react'

/**
 * @deprecated import @avantstay/tableau-core/vectors/IconEmptySearch instead
 */
function _IconEmptySearch(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="110" height="120" viewBox="0 0 110 120" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 24C32 28.4183 28.4183 32 24 32H0V92C0 96.4183 3.58172 100 8 100H33.7563C30.996 96.163 28.9723 91.7607 27.8921 87H18C15.7909 87 14 85.2091 14 83C14 80.7909 15.7909 79 18 79H27C27 76.2505 27.3082 73.5729 27.8921 71H18C15.7909 71 14 69.2091 14 67C14 64.7909 15.7909 63 18 63H30.7421C32.1894 60.0876 34.0196 57.399 36.1667 55H18C15.7909 55 14 53.2091 14 51C14 48.7909 15.7909 47 18 47H46.4912C51.4361 44.4437 57.0495 43 63 43C70.4006 43 77.2796 45.2331 83 49.0622V8C83 3.58172 79.4183 0 75 0H32V24ZM27 21V0L0 27H21C24.3137 27 27 24.3137 27 21ZM44 27C44 24.7909 45.7909 23 48 23H66C68.2091 23 70 24.7909 70 27C70 29.2091 68.2091 31 66 31H48C45.7909 31 44 29.2091 44 27ZM31 78.4293C31 95.782 45.0749 109.857 62.4282 109.857C69.1105 109.859 75.6172 107.718 80.9919 103.747L96.4249 117.908C99.5778 120.802 104.48 120.592 107.373 117.439C110.267 114.286 110.057 109.384 106.904 106.49L90.8585 91.7616C96.3307 80.0978 94.127 66.2753 85.2992 56.8913C76.4714 47.5073 62.809 44.4643 50.833 49.2146C38.8571 53.9649 30.9953 65.5456 31 78.4293ZM85.2518 78.4293C85.2503 91.0326 75.0326 101.249 62.4292 101.248C49.8258 101.247 39.609 91.0304 39.6088 78.4271C39.6085 65.8237 49.8249 55.6062 62.4282 55.6051C68.4818 55.6042 74.2877 58.0086 78.5682 62.2892C82.8486 66.5697 85.2529 72.3757 85.2518 78.4293Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconEmptySearch = React.forwardRef(_IconEmptySearch)
