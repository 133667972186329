import React, { Ref } from 'react'

function _IconPricingTapeChart(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49 58H55V52H49V58ZM49 82H55V76H49V82ZM73 58H79V52H73V58ZM25 82H31V76H25V82ZM91 28H13V22C13 18.688 15.688 16 19 16H31V19C31 20.659 32.341 22 34 22C35.659 22 37 20.659 37 19V16H67V19C67 20.659 68.341 22 70 22C71.659 22 73 20.659 73 19V16H85C88.312 16 91 18.688 91 22V28ZM85 58C85 61.312 82.312 64 79 64H73C69.688 64 67 61.312 67 58V52C67 48.688 69.688 46 73 46H79C82.312 46 85 48.688 85 52V58ZM61 58C61 61.312 58.312 64 55 64H49C45.688 64 43 61.312 43 58V52C43 48.688 45.688 46 49 46H55C58.312 46 61 48.688 61 52V58ZM61 82C61 85.312 58.312 88 55 88H49C45.688 88 43 85.312 43 82V76C43 72.688 45.688 70 49 70H55C58.312 70 61 72.688 61 76V82ZM37 58C37 61.312 34.312 64 31 64H25C21.688 64 19 61.312 19 58V52C19 48.688 21.688 46 25 46H31C34.312 46 37 48.688 37 52V58ZM37 82C37 85.312 34.312 88 31 88H25C21.688 88 19 85.312 19 82V76C19 72.688 21.688 70 25 70H31C34.312 70 37 72.688 37 76V82ZM85 10H73V7C73 5.344 71.659 4 70 4C68.341 4 67 5.344 67 7V10H37V7C37 5.344 35.659 4 34 4C32.341 4 31 5.344 31 7V10H19C12.373 10 7 15.373 7 22V88C7 94.627 12.373 100 19 100H85C91.627 100 97 94.627 97 88V22C97 15.373 91.627 10 85 10ZM25 58H31V52H25V58Z"
        fill="#C0C9CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.6516 71.1232C86.6881 72.0354 86.7889 73.6151 85.8768 74.6516L74.9472 87.0716C73.9555 88.1985 72.2183 88.2539 71.1569 87.1924L66.7322 82.7678C65.7559 81.7915 65.7559 80.2086 66.7322 79.2323C67.7085 78.256 69.2915 78.256 70.2678 79.2323L72.8836 81.8481L82.1232 71.3485C83.0354 70.3119 84.6151 70.2111 85.6516 71.1232Z"
        fill="#FAFBFC"
      />
    </svg>
  )
}

export const IconPricingTapeChart = React.forwardRef(_IconPricingTapeChart)
