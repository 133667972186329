// @ts-nocheck
import React from 'react'
import { StyledComponent } from 'styled-components'

import * as S from './Label.styles'
import { LabelProps, LabelSizeProps } from './Label.types'

function createLabelComponent<T>(
  Component: StyledComponent<any, any, LabelProps>,
  size?: LabelSizeProps,
) {
  return ({ value, style, ...props }: LabelProps & T) => {
    return (
      <Component style={style} {...size} {...props}>
        {value}
      </Component>
    )
  }
}

const Label = (): React.ReactNode => createLabelComponent(S.Label)

Label.Small = createLabelComponent(S.Label, { small: true })
Label.Default = createLabelComponent(S.Label)

export { Label }
