import React, { FC, memo, ReactNode } from 'react'

import { ContainerProps, TitleProps } from './Card.styles'
import * as S from './Card.styles'

type CardProps = {
  children?: ReactNode
  title?: string
} & ContainerProps &
  TitleProps

const _Card: FC<CardProps> = ({ title, children, className, isLoading, full, style }) => (
  <S.CardRoot style={style} className={className} full={full}>
    {title ? <S.CardTitle isLoading={isLoading}>{title}</S.CardTitle> : null}
    {children}
  </S.CardRoot>
)

const Card = memo(_Card)

export { Card }
