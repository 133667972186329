import React, { Ref } from 'react'

function _IconNOk(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        fill="#C0C9CE"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5649 9.56588C15.8773 9.25346 15.8773 8.74693 15.5649 8.43451C15.2525 8.12209 14.746 8.12209 14.4335 8.43451L11.9992 10.8688L9.5649 8.43451C9.25248 8.12209 8.74595 8.12209 8.43353 8.43451C8.12111 8.74693 8.12111 9.25346 8.43353 9.56588L10.8678 12.0002L8.43353 14.4345C8.12111 14.7469 8.12111 15.2535 8.43353 15.5659C8.74595 15.8783 9.25248 15.8783 9.5649 15.5659L11.9992 13.1316L14.4335 15.5659C14.746 15.8783 15.2525 15.8783 15.5649 15.5659C15.8773 15.2535 15.8773 14.7469 15.5649 14.4345L13.1306 12.0002L15.5649 9.56588Z"
        fill="#6A7F8A"
      />
    </svg>
  )
}

export const IconNOk = React.forwardRef(_IconNOk)
