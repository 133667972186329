import styled from 'styled-components'

import {
  _M_Medium,
  _M_Normal,
  _S_Bold,
  _S_Normal,
  mainColors,
  semanticColors,
} from '@avantstay/backoffice-core'

import { Placeholder } from '@new/__global/components/DragAndDropGrid/DragAndDropWrapper/DragAndDropWrapper.styles'
import { getFileSizeByType } from '@new/__ui/UploadArea/_utils'

import { FilePreviewType, IDropAreaRoot } from './_types'
import { Container as FileContainer } from './FilePreview/FilePreview.styles'

export const DocumentDropAreaStyles = {
  width: '100%',
  height: '220px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '2px',
}

export const DocumentDropAreaStylesError = {
  ...DocumentDropAreaStyles,
  borderColor: semanticColors.failureMedium,
}

export const Container = styled.div<{ disabled?: boolean }>`
  position: relative;
  max-height: 100%;
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      pointer-events: none;

      ${DropArea} {
        background-color: ${mainColors.neutralExtraLight};

        &:hover {
         border-color: ${mainColors.neutralMedium};
        }
      }

      ${FileContainer} {
        opacity: 0.6;
      }
  `}
`

export const PreviewContainer = styled.div<{ filePreviewType?: FilePreviewType }>`
  margin-top: 24px;

  ${({ filePreviewType }) =>
    filePreviewType === FilePreviewType.photoGallery &&
    `
    padding-left: 13px;

    ${Placeholder} {
      width: ${getFileSizeByType(filePreviewType).width}px;
      height: ${getFileSizeByType(filePreviewType).height}px;
    }
  `}
`

export const PreviewTitles = styled.p`
  ${_M_Medium};
  margin-bottom: 14px;
`

export const DropArea = styled.label<IDropAreaRoot>`
  ${_M_Normal};
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  max-width: 700px;
  color: ${mainColors.neutralExtremelyDark};
  padding: 22px 10px;
  margin-bottom: 10px;
  border: 2px dashed ${mainColors.neutralMedium};
  border-radius: 2px;
  cursor: pointer;

  span {
    color: ${mainColors.accentMedium};
  }

  &:hover {
    border-color: ${mainColors.neutralDark};
    background: ${mainColors.neutralExtraLight};
  }

  ${props =>
    props.active &&
    `
      border-color: ${mainColors.neutralDark};
      background: ${mainColors.neutralExtraLight};
  `}
`

export const HiddenInput = styled.input`
  visibility: hidden;
  padding: 0;
  width: 0;
  height: 0;
  top: 0;
  position: absolute;
`

export const ErrorContainer = styled.div`
  ${_S_Normal};
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  max-width: 700px;
  height: 36px;
  margin-left: 13px;
  padding: 11px 17px;
  color: ${semanticColors.failureMedium};
  background-color: ${semanticColors.failureExtraLight};
`

export const ErrorIndexes = styled.span`
  ${_S_Bold};
`
