import { observer } from 'mobx-react'
import { FC, MouseEvent, useState } from 'react'

import { DateRangePicker } from '@avantstay/avantstay-ui'
import { IconClose } from '@avantstay/backoffice-vectors'

import { formatDate } from '../../utils'
import * as S from './MultiDatePicker.styles'

interface MultiDatePickerProps {
  onChange: (date: Date) => void
  handleRemoveDate: (date: Date) => void
  selectedDates?: Array<Date>
  blockedDays?: Array<Date>
  maxDate?: Date
  firstDayOfWeek?: number
}

const sortDates = (list: Date[]) => {
  return list.sort((dateA: Date, dateB: Date) => dateA.getTime() - dateB.getTime())
}

const _MultiDatePicker: FC<MultiDatePickerProps> = ({
  onChange,
  handleRemoveDate,
  selectedDates = [],
  blockedDays = [],
  maxDate,
  firstDayOfWeek,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleCalendar = () => {
    setIsOpen(prevState => !prevState)
  }

  return (
    <S.Container>
      <S.SelectDateContainer isOpen={isOpen} onClick={toggleCalendar}>
        {sortDates(selectedDates).map(date => (
          <S.DateOption key={date.toString()}>
            {formatDate(date, 'MM/dd')}
            <S.RemoveButton
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation()
                handleRemoveDate(date)
              }}
            >
              <IconClose />
            </S.RemoveButton>
          </S.DateOption>
        ))}
      </S.SelectDateContainer>
      {isOpen && (
        <DateRangePicker
          show={isOpen}
          className="multiDateRangePicker"
          rootClassName="rootMultiDateRangePicker"
          minDate={new Date()}
          disableDaysBeforeToday
          singleDateRange
          shouldStartEmpty
          showSingleMonthPicker
          blockedDates={blockedDays.map(date => formatDate(date))}
          maxDate={maxDate}
          onChange={date => {
            onChange(date?.startDate as Date)
          }}
          onClickOut={() => {
            setIsOpen(false)
          }}
          multiSelectedDates={selectedDates}
          firstDayOfWeek={firstDayOfWeek}
        />
      )}
    </S.Container>
  )
}

export const MultiDatePicker = observer(_MultiDatePicker)
