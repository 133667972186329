import React, { Ref } from 'react'

function _IconMessage(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.10654 5.55403C3.2719 5.22696 3.61201 5 4 5H20C20.388 5 20.7281 5.22694 20.8934 5.55399L12 11.7794L3.10654 5.55403ZM1.00007 5.9797C0.99984 5.99143 0.999817 6.00315 1 6.01486V18C1 19.6523 2.34772 21 4 21H20C21.6523 21 23 19.6523 23 18V6.00915M21 7.92072V18C21 18.5477 20.5477 19 20 19H4C3.45228 19 3 18.5477 3 18V7.92077L11.4265 13.8193C11.7708 14.0603 12.2291 14.0603 12.5734 13.8193L21 7.92072ZM23 5.98666C22.9927 4.34047 21.6478 3 20 3H4C2.35449 3 1.01105 4.33669 1.00007 5.9797"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconMessage = React.forwardRef(_IconMessage)
