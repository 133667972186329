import { format } from 'date-fns'

import { MONTH_NAMES } from '../../../configuration'
import { Period } from './MonthMultiPicker.types'

export const shortMonthNames = MONTH_NAMES.map(month => month.substr(0, 3))

export const getShortMonthFromStringDate = (date: string | Date) =>
  shortMonthNames[Number(date.toString().split('-')[1]) - 1]

export const formatPeriod = (period: Period, showYear?: boolean) => {
  const { start, end } = period

  if (start && end) {
    const [startYear] = start.toString().split('-')
    const [endYear] = end.toString().split('-')
    const equalYear = startYear === endYear

    const formattedStart = getShortMonthFromStringDate(start)
    const formattedEnd = getShortMonthFromStringDate(end)

    if (showYear) {
      if (equalYear) {
        if (formattedStart === formattedEnd) {
          return `${formattedStart} ${startYear}`
        }
        return `${formattedStart} - ${formattedEnd}, ${startYear}`
      }

      return `${formattedStart} ${startYear} - ${formattedEnd} ${endYear}`
    }
    if (formattedStart === formattedEnd) {
      return formattedStart
    }
    return `${formattedStart} - ${formattedEnd}`
  }
}

export const isInsidePeriod = (year: number, month: number, period: Period) => {
  if (!period?.start || !period?.end) return false

  const [startYear, startMonth] = period.start.toString().split('-')
  const [endYear, endMonth] = period.end.toString().split('-')

  const selectedDate = +`${year}${month < 10 ? 0 : ''}${month}`
  const periodStart = +`${startYear}${startMonth}`
  const periodEnd = +`${endYear}${endMonth}`

  const isAfterStart = selectedDate >= periodStart
  const isBeforeEnd = selectedDate <= periodEnd

  return isAfterStart && isBeforeEnd
}

export const getISOString = (date: Date) => format(date, 'yyyy-MM-dd')
