import { forwardRef, Ref, SVGProps } from 'react'

function _IconPriorityLow(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <rect width="16" height="16" rx="2" fill="#22C4A6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.21811 6.22781C3.50893 5.92406 3.98043 5.92406 4.27125 6.22781L8 10.1223L11.7288 6.22781C12.0196 5.92406 12.4911 5.92406 12.7819 6.22781C13.0727 6.53155 13.0727 7.02401 12.7819 7.32775L8.52657 11.7722C8.23575 12.0759 7.76425 12.0759 7.47343 11.7722L3.21811 7.32775C2.9273 7.02401 2.9273 6.53155 3.21811 6.22781Z"
        fill="white"
      />
    </svg>
  )
}

export const IconPriorityLow = forwardRef(_IconPriorityLow)
