import React from 'react'

const _IconExclamation = (props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
    <circle cx="8.00002" cy="8.00008" r="7.33333" fill="#94A4AD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.46721 5.33312C8.46721 5.07539 8.25827 4.86646 8.00054 4.86646C7.74281 4.86646 7.53387 5.07539 7.53387 5.33312V8.66645C7.53387 8.92419 7.74281 9.13312 8.00054 9.13312C8.25827 9.13312 8.46721 8.92419 8.46721 8.66645V5.33312ZM8.00054 11.1999C8.25827 11.1999 8.46721 10.991 8.46721 10.7333C8.46721 10.4755 8.25827 10.2666 8.00054 10.2666C7.74281 10.2666 7.53387 10.4755 7.53387 10.7333C7.53387 10.991 7.74281 11.1999 8.00054 11.1999Z"
      fill="white"
    />
  </svg>
)

export const IconExclamation = React.memo(React.forwardRef(_IconExclamation))
