export const listingDistributionCustomFieldId = 'a268c2bc-1c4a-11ea-b8f4-d787ffaf67ea'
export const featuredCustomFieldId = '38dc1eb8-7c35-4058-917f-926650cbe948'
export const omitCustomFieldsSectionsIds = [listingDistributionCustomFieldId]
export const omitCustomFieldsIds = [featuredCustomFieldId]

export const InternalSectionName = 'Internal'
export const FeaturedCustomFieldName = 'Featured'

export const shortDescriptionRequiredValidation = {
  presence: { allowEmpty: false, message: "can't be blank" },
  length: {
    tooLong: 'maximum is %{count} characters',
    maximum: 300,
  },
}

export const shortDescriptionValidation = {
  ...shortDescriptionRequiredValidation,
  presence: { allowEmpty: true },
}
