export enum ConfirmActionFooterTheme {
  primary = 'PRIMARY',
  danger = 'DANGER',
}

export interface ConfirmActionFooterBaseProps {
  cancelText?: string
  confirmButtonType?: 'submit' | 'reset' | 'button'
  confirmText?: string
  containerClassName?: string
  message: React.ReactNode | string
  isActionLoading?: boolean
  title: string
  theme?: ConfirmActionFooterTheme
}

export interface ConfirmActionFooterContentProps extends ConfirmActionFooterBaseProps {
  onCancel: () => void
  onConfirm: () => void
  shouldDisplayActionContainer: boolean
}

export interface ConfirmActionFooterProps extends ConfirmActionFooterBaseProps {
  children: React.ReactNode
  className?: string
  onCancel?: () => void
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  onConfirm?: () => void
  onToggleMessage?: (isShowingMessage: boolean) => void
  shouldCloseOnSubmit?: boolean
  isConfirmLoading?: boolean
}
