import React from 'react'

function _IconExclude(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0002 7.99935C16.0002 12.4183 12.4179 16.0007 7.99886 16.0007C3.57986 16.0007 -0.00244141 12.4183 -0.00244141 7.99935C-0.00244141 3.58035 3.57986 -0.00195312 7.99886 -0.00195312C12.4179 -0.00195312 16.0002 3.58035 16.0002 7.99935ZM11.3901 4.60948C11.6713 4.89071 11.6713 5.34666 11.3901 5.62788L9.01882 7.99915L11.3901 10.3704C11.6713 10.6516 11.6713 11.1076 11.3901 11.3888C11.1089 11.67 10.6529 11.67 10.3717 11.3888L8.00042 9.01755L5.62915 11.3888C5.34793 11.67 4.89198 11.67 4.61075 11.3888C4.32953 11.1076 4.32953 10.6516 4.61075 10.3704L6.98202 7.99915L4.61075 5.62788C4.32953 5.34666 4.32953 4.89071 4.61075 4.60948C4.89198 4.32826 5.34793 4.32826 5.62915 4.60948L8.00042 6.98075L10.3717 4.60948C10.6529 4.32826 11.1089 4.32826 11.3901 4.60948Z"
        fill="#6A7F8A"
      />
    </svg>
  )
}

const IconExclude = React.memo(React.forwardRef(_IconExclude))

export { IconExclude }
