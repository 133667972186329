import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconMarriott = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <g clipPath="url(#clip0_51914_14114)">
        <path
          d="M5.48198 5.37983C5.80624 6.25116 6.56284 7.15146 6.02241 8.16232C5.77922 9.18897 4.77941 10.0208 3.72557 10.1867C2.61769 10.2841 1.59085 10.1024 0.753192 9.45221C0.185729 6.48809 1.42873 3.74509 3.83365 2.11035L5.48198 5.37983Z"
          fill="currentColor"
        />
        <path
          d="M9.72496 14.4115C9.18453 16.2042 3.75315 15.0275 2.05078 12.0844C4.29358 12.3266 6.80663 10.3944 6.86068 8.19103L9.72496 14.4115Z"
          fill="currentColor"
        />
        <path
          d="M11.9676 14.1797C12.2108 14.0718 12.6971 13.8296 12.8322 13.311L6.99556 0.943796C6.59023 0.896412 6.32005 0.862186 5.80664 1.20704L11.9676 14.1797Z"
          fill="currentColor"
        />
        <path
          d="M14.5882 11.4951C17.0742 7.8228 14.9935 1.79716 9.61621 0.952148L14.5882 11.4951Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_51914_14114">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconMarriott = forwardRef(_IconMarriott)
