export function getThumbnailUrl({
  height = 150,
  width = 150,
  quality = 80,
  originalSrc,
}: {
  height?: number
  width?: number
  quality?: number
  originalSrc: string
}) {
  if (!originalSrc || /^(blob|data):/i.test(originalSrc)) {
    return `${originalSrc}`
  }

  const assetKey = originalSrc.replace('https://ik.imagekit.io/avantstay/', '')

  return `https://imglite.avantstay.com/${width}x${height}/${quality}/${assetKey}`
}

export function isImage(extension: string) {
  return ['JPG', 'PNG', 'JPEG'].includes(extension)
}
