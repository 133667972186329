import styled, { css } from 'styled-components'

import { IconCloseCircle } from '@avantstay/backoffice-vectors'

import { Button } from '../../components/Button'
import { mainColors, semanticColors } from '../../styles/colors'
import { _M_Normal, _S_Normal } from '../../styles/typography'
import { ellipsis } from '../../styles/utils'
import { zIndex } from '../../styles/zIndex'
import {
  ControlCustomProps,
  MenuPlacement,
  SelectFieldIssuesProps,
  SelectIndicatorProps,
  Size,
} from './SelectField.types'

export const SelectIssueContainer = styled.div<SelectFieldIssuesProps>`
  position: absolute;
  top: 2px;
  right: 11px;
  height: 100%;
  display: flex;
  align-items: center;
`

export const SelectContainer = styled.div<{ $fullLength?: boolean }>`
  position: relative;
  padding: 0;
  cursor: pointer;

  ${({ $fullLength }) =>
    $fullLength &&
    `
    width: 100%;

    ${ValueContainer} {
      gap: 0;
    }

    ${SingleValue} {
      width: max-content;
      min-width: 15px;
      margin-right: 4px;
    }


  `};
`

export const Placeholder = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  text-align: left;
  color: ${mainColors.neutralExtraDark} !important;
`

export const SearchInput = styled.input`
  border: 0;
  background-color: transparent;
  flex-grow: 1;
  outline: 0;
  font-size: 14px;
  box-sizing: content-box;
  opacity: 1;
  padding: 0;
  color: inherit;

  svg {
    color: ${mainColors.neutralExtraDark};

    &:hover {
      color: ${mainColors.accentMedium};
    }
  }

  &::placeholder {
    color: ${mainColors.neutralDark};
  }
`
export const FixedWrapper = styled.div<{ width?: number }>`
  position: fixed;
  z-index: ${zIndex.MODAL};
  ${({ width }) => (width && width > 0 ? `width: ${width}px` : '')};
`

export const ValueContainer = styled.div<{ isMulti?: boolean; size: Size }>`
  ${_M_Normal};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 4px;
  flex: 1 1 0;
  flex-wrap: wrap;
  height: ${({ isMulti }) => (isMulti ? 'min-content' : '44px')};
  min-height: 44px;
  box-shadow: none;
  margin-right: 0;
  justify-content: center;
  padding: 10px 0 10px 14px;

  ${({ size }) =>
    size === Size.Small &&
    `
    height: 32px;
    min-height: 32px;
    ${_S_Normal};
    line-height: 20px;
    padding: 8px 0 8px 14px;
  `}

  ${({ size }) => size === Size.Big && `min-height: 60px;`};

  ${({ isMulti }) =>
    isMulti &&
    `
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
  `};
`

export const Menu = styled.div<{ menuPlacement?: MenuPlacement }>`
  width: 100%;
  min-width: inherit;
  background-color: ${mainColors.white};
  border: 1px solid ${mainColors.neutralLight};
  border-radius: 2px;
  box-shadow: 0 4px 16px rgba(232, 235, 237, 0.4) !important;
  box-sizing: border-box;
  ${({ menuPlacement }) => (menuPlacement === 'top' ? 'bottom: 100%' : 'top: 100%')};
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  z-index: ${zIndex.MODAL};
`

export const MenuList = styled.div`
  border: none;
  padding: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const InfoWrapper = styled.div`
  ${_M_Normal};
  padding: 8px 12px;
  text-align: center;
  color: ${mainColors.neutralExtraDark};
`

export const SingleValue = styled.div<{ hasDot?: boolean; size: Size }>`
  color: ${mainColors.black};
  ${({ hasDot }) => hasDot && 'width: auto;'};
  ${ellipsis};
  display: flex;
  min-height: 20px;
`

export const Title = styled.span`
  color: ${mainColors.neutralExtremelyDark};
  margin-right: 6px;
`

export const IndicatorContainer = styled.div<SelectIndicatorProps>`
  svg {
    color: ${mainColors.neutralExtraDark};
  }
`

export const DropdownIndicator = styled(Button.Flat)<{ isDropdownOpen: boolean }>`
  padding: 0 10px !important;

  ${({ isDropdownOpen }) =>
    isDropdownOpen &&
    `
    transform: scaleY(-1);
  `}
`

export const ControlWrapper = styled.div<ControlCustomProps>`
  display: flex;
  border: ${({ removeBorder }) =>
    removeBorder ? 'none' : `2px solid ${mainColors.neutralMedium}`};
  border-left: ${({ removeBorder }) => (removeBorder ? 'none' : '')};
  border-right: ${({ removeBorder }) => (removeBorder ? 'none' : '')};
  max-height: ${({ isMulti }) => (isMulti ? 'min-content' : '60px')};
  min-height: 36px;
  margin-right: 0;
  border-radius: 2px;
  outline: none;
  position: relative;
  background: ${({ removeBackground }) => (removeBackground ? 'transparent' : mainColors.white)};

  ${({ isFocused }) =>
    !isFocused &&
    `
    &:hover {
      border-color: ${mainColors.neutralDark};
    }
  `}

  ${({ isFocused }) =>
    isFocused &&
    `
    border-color: ${mainColors.accentMedium};
  `}

  ${({ error }) =>
    error &&
    `
    border-color: ${semanticColors.failureMedium};

    &:hover {
      border-color: ${semanticColors.failureMedium};
    }

    svg {
      color: ${semanticColors.failureMedium};
    }
  `}

  ${({ warning }) =>
    warning &&
    `
    border-color: ${semanticColors.warningMedium};

    &:hover {
      border-color: ${semanticColors.warningMedium};
    }

    svg {
      color: ${semanticColors.warningMedium};
    }
  `}

  ${({ isDisabled }) =>
    isDisabled &&
    `
      &:hover {
        border-color: ${mainColors.neutralMedium};
        div {
          cursor: not-allowed;
        }
      }
    `}
`

export const Control = styled.div<{ isDisabled?: boolean }>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  min-width: 0;

  ${({ isDisabled }) =>
    isDisabled &&
    `
      background-color: ${mainColors.neutralExtraLight} !important;
    `}
`

export const StyledIconCloseCircle = styled(IconCloseCircle)`
  color: ${mainColors.neutralExtraDark};
  cursor: pointer;
`

export const FieldTitle = styled.div<{ $hasValue?: boolean }>`
  width: max-content;
  ${_S_Normal};
  line-height: 20px;
  color: ${mainColors.neutralExtremelyDark};

  ${({ $hasValue }) => $hasValue && `margin-right: 12px;`};
`

export const SelectWithoutBorderTheme = css<{
  color?: string
  error?: string
  hasDot?: boolean
  isDisabled?: boolean
}>`
  width: 100%;
  border-radius: 2px;

  opacity: ${({ isDisabled }) => (isDisabled ? '30%' : 'unset')};
  ${Placeholder} {
    color: ${mainColors.black} !important;
  }

  ${ValueContainer} {
    & > input {
      width: 100%;
    }
  }

  ${SingleValue} {
    display: ${({ hasDot }) => (hasDot ? 'flex' : 'block')};
    min-height: unset;
  }

  ${MenuList} {
    & > div {
      display: ${({ hasDot }) => (hasDot ? 'flex' : 'block')};
      height: unset;
      min-height: unset;
      ${ellipsis};
    }
  }

  ${ControlWrapper} {
    border: none;
    background-color: ${({ color }) => color ?? mainColors.neutralMedium} !important;
    border-radius: 2px;

    ${({ error }) =>
      error
        ? css`
            border: 2px solid ${semanticColors.failureMedium};
          `
        : ''}

    & > div {
      background-color: ${({ color }) => color ?? mainColors.neutralMedium} !important;
      border-radius: 2px;
    }
  }
`
