import styled from 'styled-components'

import { mainColors, semanticColors, shadowColors } from '../../styles/colors'
import { _L_Medium, _M_Normal } from '../../styles/typography'
import { zIndex } from '../../styles/zIndex'
import { rgba } from '../../utils/cssUtils'
import { ConfirmActionFooterTheme } from './ConfirmActionFooter.types'

export const ChildrenContainer = styled.div`
  width: 100%;
  cursor: pointer;
`

export const ConfirmActionContentContainer = styled.div<{ shouldDisplayActionContainer: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  display: ${({ shouldDisplayActionContainer }) =>
    shouldDisplayActionContainer ? 'flex' : 'none'};
  flex-flow: column;
  width: 100%;
  min-height: 100%;
  background: ${mainColors.white};
  border-top: solid 1px ${mainColors.neutralMedium};
  z-index: ${zIndex.PORTAL};

  &:before {
    content: '';
    position: absolute;
    left: -1px;
    top: -215px;
    height: 215px;
    width: 100%;
    background: linear-gradient(
      180deg,
      ${rgba(shadowColors.neutralBlur, 0)} 0%,
      ${rgba(shadowColors.blur, 0.4)} 100%
    );
    pointer-events: none;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 16px;
`

export const TitleHeader = styled.div<{ theme?: ConfirmActionFooterTheme }>`
  display: flex;
  align-items: center;
  color: ${({ theme }) =>
    theme === ConfirmActionFooterTheme.danger
      ? semanticColors.failureMedium
      : mainColors.accentMedium};
  gap: 10px;

  svg {
    width: 16px;
    height: 16px;
  }
`

export const Title = styled.div`
  ${_L_Medium};
`

export const Message = styled.div`
  ${_M_Normal};
  white-space: normal;
  color: ${mainColors.neutralExtraDark};
`

export const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  padding-top: 20px;
  border-top: solid 1px ${mainColors.neutralMedium};
`
