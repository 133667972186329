import React, { Ref } from 'react'

function _IconThermostat(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8322 7.96347C12.8322 5.60883 14.741 3.70001 17.0956 3.70001C19.4503 3.70001 21.3591 5.60883 21.3591 7.96347V20.2333C22.9214 21.8682 23.4194 24.2704 22.6028 26.4084C21.7306 28.6917 19.5399 30.2 17.0956 30.2C14.6514 30.2 12.4607 28.6917 11.5885 26.4084C10.7719 24.2704 11.2699 21.8682 12.8322 20.2333V7.96347ZM17.0956 5.70001C15.8456 5.70001 14.8322 6.7134 14.8322 7.96347V20.6562C14.8322 20.9407 14.7109 21.2118 14.4988 21.4015C13.2951 22.4782 12.8806 24.186 13.4569 25.6947C14.0331 27.2034 15.4806 28.2 17.0956 28.2C18.7106 28.2 20.1581 27.2034 20.7344 25.6947C21.3107 24.186 20.8962 22.4782 19.6924 21.4015C19.4803 21.2118 19.3591 20.9407 19.3591 20.6562V7.96347C19.3591 6.7134 18.3457 5.70001 17.0956 5.70001ZM17.0957 8.59521C17.648 8.59521 18.0957 9.04293 18.0957 9.59521V21.8459C19.0529 22.2397 19.7268 23.1815 19.7268 24.2808C19.7268 25.7342 18.5486 26.9125 17.0951 26.9125C15.6416 26.9125 14.4634 25.7342 14.4634 24.2808C14.4634 23.1811 15.1379 22.2389 16.0957 21.8454V9.59521C16.0957 9.04293 16.5434 8.59521 17.0957 8.59521ZM17.0923 23.6491C16.7447 23.6505 16.4634 23.9328 16.4634 24.2808C16.4634 24.6297 16.7462 24.9125 17.0951 24.9125C17.444 24.9125 17.7268 24.6297 17.7268 24.2808C17.7268 23.933 17.4458 23.6508 17.0983 23.6491C17.0975 23.6491 17.0966 23.6491 17.0957 23.6491C17.0946 23.6491 17.0935 23.6491 17.0923 23.6491Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconThermostat = React.forwardRef(_IconThermostat)
