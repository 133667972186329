import React from 'react'

function _IconStripeLogo(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props} ref={ref}>
      <circle cx="15" cy="15" r="15" fill="#6772E5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3 12.2001C14.3 11.5101 14.87 11.2001 15.79 11.2001C17.3145 11.2331 18.8106 11.6199 20.16 12.3301V8.24013C18.7716 7.69567 17.2912 7.42405 15.8 7.44013C12.24 7.44013 9.85999 9.30013 9.85999 12.4401C9.85999 17.3001 16.54 16.5101 16.54 18.6101C16.54 19.4201 15.83 19.6801 14.86 19.6801C13.1512 19.5859 11.4875 19.0964 9.99999 18.2501V22.4401C11.5157 23.0951 13.1488 23.4353 14.8 23.4401C18.45 23.4401 20.97 21.6401 20.97 18.4401C21 13.2301 14.3 14.1701 14.3 12.2001V12.2001Z"
        fill="white"
      />
    </svg>
  )
}

const IconStripeLogo = React.memo(React.forwardRef(_IconStripeLogo))

export { IconStripeLogo }
