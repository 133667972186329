import { CSSProperties } from 'react'

export enum ConfirmActionTheme {
  primary = 'PRIMARY',
  danger = 'DANGER',
}

export enum ContainerVerticalAlignment {
  top = 'TOP',
  bottom = 'BOTTOM',
  center = 'CENTER',
}

export interface ConfirmActionContentProps {
  message: React.ReactNode
  title?: string
  onConfirm?: () => void
  onCancel?: () => void
  theme?: ConfirmActionTheme
  cancelText?: string
  confirmText?: string
  confirmButtonType?: 'submit' | 'reset' | 'button'
  formId?: string
  confirmButtonId?: string
  containerStyles?: CSSProperties
  customVerticalAlignment?: ContainerVerticalAlignment
  focusOnDisplay?: boolean
  isConfirmLoading?: boolean
  isSubmitButtonDisabled?: boolean
}

export interface ConfirmActionProps extends ConfirmActionContentProps {
  children: React.ReactNode
  horizontalAlignment?: 'left' | 'right'
  verticalAlignment?: ContainerVerticalAlignment
  onToggleMessage?: (isShowingMessage: boolean) => void
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  containerStyle?: React.CSSProperties
  offsetHeight?: number
  ref?: any
  isSubmitButtonDisabled?: boolean
}

export interface ConfirmActionStyledProps {
  theme?: ConfirmActionTheme
}

export interface FloatingContainerProps {
  customVerticalAlignment?: ContainerVerticalAlignment
  verticalValue?: number
}
