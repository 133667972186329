import styled from 'styled-components'

import { _M_Medium, mainColors } from '../../styles'

export const DefaultLink = styled.a<{ $isDisabled?: boolean }>`
  ${_M_Medium};
  display: flex;
  align-items: center;
  color: ${mainColors.accentMedium};
  stroke: ${mainColors.accentMedium};

  svg {
    margin-right: 8px;
  }

  &:hover {
    text-decoration: underline;
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
    pointer-events: none;
    cursor: default;
    color: ${mainColors.black};
    stroke: ${mainColors.black};
  `}
`
