import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconPaymentFailed(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <circle cx="8.00008" cy="7.99984" r="7.33333" fill="#EC4678" stroke="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.1152 5.44023C5.92861 5.25365 5.62609 5.25365 5.4395 5.44023C5.25291 5.62682 5.25291 5.92934 5.4395 6.11593L7.32393 8.00036L5.4395 9.88479C5.25291 10.0714 5.25291 10.3739 5.4395 10.5605C5.62609 10.7471 5.92861 10.7471 6.1152 10.5605L7.99963 8.67605L9.88405 10.5605C10.0706 10.7471 10.3732 10.7471 10.5597 10.5605C10.7463 10.3739 10.7463 10.0714 10.5597 9.88479L8.67532 8.00036L10.5597 6.11593C10.7463 5.92934 10.7463 5.62682 10.5597 5.44023C10.3732 5.25365 10.0706 5.25365 9.88405 5.44023L7.99963 7.32466L6.1152 5.44023Z"
        fill="white"
      />
    </svg>
  )
}

export const IconPaymentFailed = forwardRef(_IconPaymentFailed)
