import styled from 'styled-components'
import 'tippy.js/dist/border.css'

import Tippy from '@tippyjs/react'

import { mainColors, semanticColors } from '../../styles/colors'
import { _S_Normal } from '../../styles/typography'
import { TooltipProps, TooltipTheme } from './Tooltip.types'

export const Container = styled(Tippy)<TooltipProps>`
  &.tippy-box {
    display: ${({ isVisible }) => !isVisible && 'none'};
    border: 1px solid transparent;
    border-radius: 2px;
    ${_S_Normal};

    ${({ theme }) =>
      theme === TooltipTheme.danger &&
      `
        background-color: ${semanticColors.failureExtraLight};
        border-color: ${semanticColors.failureExtraLight};
        color: ${semanticColors.failureMedium};

        &[data-placement^='top'] > .tippy-arrow::before {
          border-top-color: ${semanticColors.failureExtraLight};
        }
        &[data-placement^='bottom'] > .tippy-arrow::before {
          border-bottom-color: ${semanticColors.failureExtraLight};
        }
        &[data-placement^='left'] > .tippy-arrow::before {
          border-left-color: ${semanticColors.failureExtraLight};
        }
        &[data-placement^='right'] > .tippy-arrow::before {
          border-right-color: ${semanticColors.failureExtraLight};
        }
      `}

    ${({ theme }) =>
      theme === TooltipTheme.alert &&
      `
        background-color: ${semanticColors.warningExtraLight};
        border-color: ${semanticColors.warningExtraLight};
        color: ${semanticColors.warningDark};

        &[data-placement^='top'] > .tippy-arrow::before {
          border-top-color: ${semanticColors.warningExtraLight};
        }
        &[data-placement^='bottom'] > .tippy-arrow::before {
          border-bottom-color: ${semanticColors.warningExtraLight};
        }
        &[data-placement^='left'] > .tippy-arrow::before {
          border-left-color: ${semanticColors.warningExtraLight};
        }
        &[data-placement^='right'] > .tippy-arrow::before {
          border-right-color: ${semanticColors.warningExtraLight};
        }
      `}

    ${({ theme }) =>
      theme === TooltipTheme.dark &&
      `
        background-color: ${mainColors.primaryMedium};
        border-color: ${mainColors.primaryMedium};
        color: ${mainColors.white};

        // Border color here is the same as the content, so removing it
        // instead to not increase the arrow size
        > .tippy-arrow::after {
          border-color: transparent;
        }

        &[data-placement^='top'] > .tippy-arrow::before {
          border-top-color: ${mainColors.primaryMedium};
        }
        &[data-placement^='bottom'] > .tippy-arrow::before {
          border-bottom-color: ${mainColors.primaryMedium};
        }
        &[data-placement^='left'] > .tippy-arrow::before {
          border-left-color: ${mainColors.primaryMedium};
        }
        &[data-placement^='right'] > .tippy-arrow::before {
          border-right-color: ${mainColors.primaryMedium};
        }
      `}

    ${({ theme }) =>
      theme === TooltipTheme.light &&
      `
        background-color: ${mainColors.white};
        border-color: ${mainColors.neutralLight};
        color: ${mainColors.black};

        &[data-placement^='top'] > .tippy-arrow::before {
          border-top-color: ${mainColors.white};
        }
        &[data-placement^='bottom'] > .tippy-arrow::before {
          border-bottom-color: ${mainColors.white};
        }
        &[data-placement^='left'] > .tippy-arrow::before {
          border-left-color: ${mainColors.white};
        }
        &[data-placement^='right'] > .tippy-arrow::before {
          border-right-color: ${mainColors.white};
        }
      `}
  }
`

export const TriggerWrapper = styled.div`
  cursor: pointer;
`
