import { FieldTagProps, TagKind, TagOptionsProps } from './FieldTag.types'

export const labelByTagKind = {
  [TagKind.guestFacing]: 'Public',
  [TagKind.public]: 'Public',
  [TagKind.internalInformation]: 'Internal Info',
  [TagKind.internal]: 'System Config',
  [TagKind.systemConfig]: 'System Config',
  [TagKind.ota]: 'OTA',
  [TagKind.salesForceCase]: 'SFDC',
}

export const getMatchingLabels = ({ tags, internalTag }: FieldTagProps) => {
  const internalLabel = internalTag ? [TagKind.internal] : []

  const allTags = tags?.concat(internalLabel).filter(tag => labelByTagKind[tag as TagKind])

  return allTags?.filter((uniqueTag, index) => allTags?.indexOf(uniqueTag) === index) as TagKind[]
}

export const getTagsOptions = () => {
  const tagsOptions = Object.values(TagKind).map(tagKind => ({
    title: labelByTagKind[tagKind],
    value: tagKind,
  }))

  const uniqueOptions = Array.from(new Set(tagsOptions.map(option => option.title))).map(title =>
    tagsOptions.find(option => option.title === title),
  ) as TagOptionsProps[]

  return {
    tagsOptions,
    uniqueOptions,
  }
}
