import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'

// Fragment used for source Type
export const paymentSourceFragment = {
  brand: true,
  kind: true,
  last4: true,
  name: true,
  paymentMethodId: true,
  bankName: {
    bankName: true,
    last4: true,
    chargeId: true,
  },
  link: true,
  linkTargets: {
    contactKind: true,
    value: true,
  },
}

export default {
  async pushBookingToNetsuite(bookingId: string) {
    return arriereBackoffice.mutations.accounting({
      __alias: 'pushBookingToNetsuite',
      pushBookings: {
        __args: {
          bookingsIds: [bookingId],
        },
      },
    })
  },
}

export const getBookingPayments = async (bookingId: string) => {
  return arriereBackoffice.queries
    .booking({
      __alias: 'getBookingPayments',
      __args: {
        bookingId,
      },
      folio: {
        payments: {
          balance: true,
          fake: true,
          amount: true,
          bookingId: true,
          paymentId: true,
          disputes: {
            amount: true,
            id: true,
            operator: {
              internal: true,
              name: true,
            },
            time: true,
            statuses: {
              status: true,
              time: true,
            },
          },
          refunds: {
            amount: true,
            description: true,
            id: true,
            operator: {
              internal: true,
              name: true,
            },
            time: true,
          },
          source: {
            ...paymentSourceFragment,
          },
          statusTrack: {
            operator: {
              internal: true,
              name: true,
            },
            status: true,
            time: true,
          },
          title: true,
        },
      },
    })
    .then(booking => booking?.folio?.payments)
}

export const getAvailableBookingPaymentCards = async (bookingId: string) => {
  return arriereBackoffice.queries.availableBookingPaymentCards({
    __alias: 'getAvailableBookingPaymentCards',
    __args: {
      bookingId,
    },
    id: true,
    customerId: true,
    brand: true,
    last4: true,
    type: true,
    description: true,
  })
}

export const getAvailablePaymentBanks = async (bookingId: string) => {
  return arriereBackoffice.queries.availableBookingPaymentBanks({
    __alias: 'getAvailablePaymentBanks',
    __args: {
      bookingId,
    },
    customerId: true,
    bankName: true,
    last4: true,
  })
}
