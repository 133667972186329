// @ts-nocheck
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export interface CreateNumberMaskReturn {
  (value: string): string[]
}

export const getMoneyMask = ({ allowDecimal }: { allowDecimal: boolean }): CreateNumberMaskReturn =>
  createNumberMask({
    prefix: '$',
    allowDecimal,
    allowLeadingZeroes: false,
  })

export const moneyMask = getMoneyMask({ allowDecimal: true })

export const negativeMoneyMask: CreateNumberMaskReturn = createNumberMask({
  prefix: '-$',
  allowDecimal: true,
  allowLeadingZeroes: false,
})

export const numberMask: CreateNumberMaskReturn = createNumberMask({
  prefix: '',
})

export const numberMaskWithDecimals: CreateNumberMaskReturn = createNumberMask({
  prefix: '',
  allowDecimal: true,
})

export const percentageMask: CreateNumberMaskReturn = createNumberMask({
  prefix: '',
  suffix: ' %',
  allowDecimal: true,
  integerLimit: 3,
})

export const percentageWithDecimalsMask: CreateNumberMaskReturn = createNumberMask({
  prefix: '',
  suffix: ' %',
  integerLimit: 3,
  allowDecimal: true,
  allowLeadingZeroes: true,
  allowNegative: true,
})

export const plusMask: CreateNumberMaskReturn = createNumberMask({
  prefix: '',
  suffix: ' +',
  integerLimit: 3,
})

export const removeMoneyMask = (value: string) => value.replace(/[^\d.-]/g, '')

export const removeMask = (value: string) => value.replace(/[^\d.?]/g, '').trim()

export const parsePrice = (price: string): Cents => (parseFloat(removeMask(price)) * 100) as Cents

// Todo: review all use cases for parsePrice to use parseInt
export const parseVASPrice = (price: string): Cents =>
  (parseInt(removeMask(price), 10) * 100) as Cents
