import React, { Ref } from 'react'

function _IconRoomDetails(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.42499 3.62988V20.5299H17.55V3.62988H6.42499ZM4.42499 3.46988C4.42499 2.4156 5.30332 1.62988 6.29999 1.62988H17.675C18.6717 1.62988 19.55 2.4156 19.55 3.46988V20.5299H21.175C21.7273 20.5299 22.175 20.9776 22.175 21.5299C22.175 22.0822 21.7273 22.5299 21.175 22.5299H2.79999C2.2477 22.5299 1.79999 22.0822 1.79999 21.5299C1.79999 20.9776 2.2477 20.5299 2.79999 20.5299H4.42499V3.46988ZM14.9875 12.9999C15.8159 12.9999 16.4875 12.3283 16.4875 11.4999C16.4875 10.6715 15.8159 9.99988 14.9875 9.99988C14.1591 9.99988 13.4875 10.6715 13.4875 11.4999C13.4875 12.3283 14.1591 12.9999 14.9875 12.9999Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconRoomDetails = React.forwardRef(_IconRoomDetails)
