import styled from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _M_Normal, _S_Normal } from '../../styles/typography'
import { ellipsis } from '../../styles/utils'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

export const Text = styled.div`
  ${ellipsis};
  ${_M_Normal};
  color: ${mainColors.black};
  text-align: left;
`

export const SubText = styled.span`
  ${ellipsis};
  ${_S_Normal};
  color: ${mainColors.neutralExtremelyDark};
  margin-top: 2px;
  text-align: left;
`
