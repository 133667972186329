import React, { Ref } from 'react'

function _IconVipIndicator(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29178 2.81921C7.47007 2.61455 7.72821 2.49707 7.99965 2.49707C8.27108 2.49707 8.52922 2.61455 8.70752 2.81921C8.72033 2.83391 8.73226 2.84936 8.74327 2.86546L11.1865 6.4392L13.6594 4.55666C13.6841 4.53785 13.7105 4.52139 13.7382 4.50749C14.0516 4.35042 14.4264 4.3808 14.7104 4.5863C14.9932 4.79096 15.1393 5.13543 15.0899 5.48081L14.3159 11.3161C14.283 11.5646 14.071 11.7503 13.8203 11.7503H2.179C1.92827 11.7503 1.71631 11.5646 1.68334 11.3161L0.909371 5.48077C0.860026 5.13541 1.00605 4.79095 1.28888 4.5863C1.57289 4.3808 1.94766 4.35042 2.26106 4.50749C2.28876 4.52137 2.31512 4.5378 2.33978 4.55657L4.81332 6.43844L7.25602 2.86546C7.26703 2.84936 7.27897 2.83391 7.29178 2.81921ZM4.86294 6.47651C4.86347 6.47692 4.864 6.47734 4.86453 6.47776ZM7.99965 3.54962L5.60299 7.05526C5.45419 7.27212 5.2209 7.41748 4.96057 7.45484C4.70356 7.49172 4.4429 7.42071 4.24021 7.25893L1.91983 5.49358L2.61706 10.7503H13.3822L14.0795 5.49367L11.7589 7.26023C11.5558 7.42201 11.2948 7.49274 11.0376 7.45535C10.7773 7.41752 10.5447 7.27242 10.3963 7.05526L7.99965 3.54962Z"
        fill="#FFBD5A"
      />
    </svg>
  )
}

export const IconVipIndicator = React.memo(React.forwardRef(_IconVipIndicator))
