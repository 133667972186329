import React, { Ref } from 'react'

function _IconRestore(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.44624 2.25145C7.72316 1.98167 7.72895 1.53849 7.45917 1.26158C7.1894 0.984667 6.74622 0.978879 6.46931 1.24865L4.1785 3.4804C3.90158 3.75017 3.8958 4.19335 4.16557 4.47026L6.39731 6.76107C6.66709 7.03798 7.11027 7.04377 7.38718 6.774C7.66409 6.50422 7.66988 6.06105 7.40011 5.78413L6.34404 4.70011L8.77095 4.70015C9.40077 4.77249 9.96554 5.00116 10.2341 5.1461C10.9975 5.55813 11.5998 6.1994 11.9534 6.97044C12.3069 7.74119 12.3939 8.60198 12.2023 9.42406C12.0106 10.2463 11.5498 10.9885 10.8856 11.5373C10.2211 12.0862 9.38965 12.4112 8.51604 12.4602C7.6424 12.5093 6.77745 12.2797 6.0518 11.8081C5.3264 11.3367 4.78068 10.65 4.4934 9.85385C4.36218 9.4902 3.961 9.30178 3.59736 9.43301C3.23371 9.56423 3.04529 9.9654 3.17651 10.329C3.56894 11.4166 4.31153 12.3468 5.28891 12.982C6.26604 13.617 7.4261 13.9237 8.59459 13.858C9.76311 13.7924 10.8802 13.3577 11.7773 12.6166C12.6746 11.8753 13.3035 10.8669 13.5658 9.74185C13.828 8.61658 13.7084 7.43876 13.2259 6.38679C12.7436 5.33511 11.9258 4.46829 10.899 3.9141C10.4966 3.6969 9.74072 3.39426 8.8825 3.30399C8.85818 3.30143 8.83373 3.30015 8.80928 3.30015L6.36982 3.30011L7.44624 2.25145Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconRestore = React.forwardRef(_IconRestore)
