import React, { FC, PropsWithChildren, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { useCreatePortalRoot } from '../../hooks'
import * as S from './SlidingDrawer.styles'
import { Direction, SlidingDrawerProps } from './SlidingDrawer.types'

const ROOT_ID = 'portalSlidingDrawer'

export const SlidingDrawer: FC<PropsWithChildren<SlidingDrawerProps>> = ({
  children,
  className,
  baseRef,
  direction = Direction.Up,
  hideCloseButton,
  onClose,
}) => {
  const portalRoot = useCreatePortalRoot({ rootId: ROOT_ID })
  const rootRef = useRef<HTMLDivElement | null>(null)
  const [unmounting, setUnmounting] = useState(false)

  const handleClose = () => {
    setUnmounting(true)
    setTimeout(() => {
      onClose?.()
    }, 250)
  }

  const topOffset = useMemo(() => {
    const rect = baseRef?.current?.getBoundingClientRect()
    return rect?.bottom || 0
  }, [baseRef])

  // Return null if portal root is not found
  if (!portalRoot) {
    return null
  }

  return createPortal(
    <S.SlidingDrawerRoot
      className={className}
      topOffset={topOffset}
      unmounting={unmounting}
      ref={rootRef}
    >
      <S.Background onClick={handleClose} unmounting={unmounting} />
      <S.SlidingDrawerContainer unmounting={unmounting} direction={direction}>
        {!hideCloseButton && <S.ButtonClose onClick={handleClose} />}

        {children}
      </S.SlidingDrawerContainer>
    </S.SlidingDrawerRoot>,
    portalRoot,
  )
}
