import React, { Ref } from 'react'

function _IconPayment(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7 8C13.7 11.148 11.148 13.7 8 13.7C4.85198 13.7 2.3 11.148 2.3 8C2.3 4.85198 4.85198 2.3 8 2.3C11.148 2.3 13.7 4.85198 13.7 8ZM15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM8.06211 3.2002C8.42109 3.2002 8.71211 3.49121 8.71211 3.8502V4.28364C9.3921 4.41891 10.0087 4.79787 10.4375 5.36196C10.6548 5.64773 10.5993 6.05552 10.3135 6.27278C10.0277 6.49005 9.61992 6.43451 9.40266 6.14873C9.09777 5.7477 8.61947 5.51767 8.11698 5.52891C8.09889 5.53042 8.08059 5.5312 8.06211 5.5312C8.05226 5.5312 8.04247 5.53098 8.03272 5.53054C7.6035 5.53638 7.23907 5.66972 6.99429 5.85326C6.7442 6.04079 6.6501 6.25033 6.6501 6.42734C6.6501 6.60471 6.7443 6.81453 6.99422 7.00216C7.24439 7.18997 7.61976 7.32534 8.0621 7.32534C8.75829 7.32534 9.41398 7.5357 9.91036 7.90745C10.4071 8.27943 10.7751 8.84322 10.7751 9.52134C10.7751 10.1993 10.4073 10.7634 9.91081 11.1359C9.57276 11.3895 9.16069 11.5683 8.71211 11.6566V12.0999C8.71211 12.4589 8.42109 12.7499 8.06211 12.7499C7.70312 12.7499 7.41211 12.4589 7.41211 12.0999V11.6655C6.73224 11.5298 6.11583 11.1508 5.68681 10.5869C5.46944 10.3012 5.52482 9.89343 5.81051 9.67606C6.0962 9.45868 6.50401 9.51406 6.72139 9.79976C7.03244 10.2086 7.52331 10.44 8.0366 10.4198C8.04509 10.4195 8.0536 10.4193 8.0621 10.4193C8.50446 10.4193 8.88016 10.284 9.13064 10.096C9.38092 9.90826 9.4751 9.69843 9.4751 9.52134C9.4751 9.34446 9.38114 9.13526 9.13109 8.94799C8.88072 8.76049 8.5049 8.62534 8.0621 8.62534C7.36544 8.62534 6.70981 8.41422 6.21372 8.04178C5.71739 7.66916 5.3501 7.10498 5.3501 6.42734C5.3501 5.74935 5.718 5.1854 6.2144 4.81318C6.55226 4.55984 6.964 4.38127 7.41211 4.29311V3.8502C7.41211 3.49121 7.70312 3.2002 8.06211 3.2002Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconPayment = React.forwardRef(_IconPayment)
