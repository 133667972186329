import React, { Ref } from 'react'

function _IconBath(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.38005 6.3999H4.97615L4.89005 6.40518C4.55173 6.44694 4.29146 6.73163 4.29146 7.07469V9.22593H3.08469L2.99859 9.23121C2.66027 9.27298 2.39999 9.55766 2.39999 9.90072C2.39999 12.6961 3.99415 15.21 6.44815 16.4558C6.26406 16.6436 6.20043 16.928 6.30812 17.1837C6.41505 17.4374 6.66566 17.5999 6.94067 17.5999C7.03114 17.5999 7.12114 17.5821 7.20601 17.547L8.28041 17.1012L8.58431 17.1587C8.99119 17.2269 9.40465 17.2615 9.82193 17.2615H14.1781L14.4873 17.2551C15.0013 17.2341 15.5078 17.1605 16.0012 17.0364L17.2319 17.5469L17.3183 17.5763C17.3768 17.592 17.4369 17.5999 17.4974 17.5999C17.7724 17.5999 18.0231 17.4374 18.1299 17.1837C18.2683 16.8552 18.1238 16.4791 17.8043 16.3217C20.1192 15.0324 21.6 12.5951 21.6 9.90072C21.6 9.52908 21.2945 9.22593 20.9153 9.22593H5.66085V7.74948H6.69535V8.40467L6.70066 8.48912C6.74278 8.82116 7.02998 9.07946 7.38005 9.07946C7.75928 9.07946 8.06474 8.77632 8.06474 8.40467V7.07469L8.05943 6.99025C8.01731 6.6582 7.73011 6.3999 7.38005 6.3999ZM3.8073 10.5754H20.1928C19.8528 13.5891 17.2692 15.9119 14.1781 15.9119H9.82193C6.79665 15.9119 4.25747 13.6869 3.83203 10.7669L3.8073 10.5754Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconBath = React.forwardRef(_IconBath)
