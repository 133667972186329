import React, { Ref } from 'react'

function _IconInfo(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.58 8.03996C15.58 4.15187 12.4281 0.999956 8.54 0.999956C4.65192 0.999956 1.5 4.15187 1.5 8.03996C1.5 11.928 4.65192 15.08 8.54 15.08C12.4281 15.08 15.58 11.928 15.58 8.03996ZM2.78 8.03996C2.78 4.8588 5.35884 2.27996 8.54 2.27996C11.7212 2.27996 14.3 4.8588 14.3 8.03996C14.3 11.2211 11.7212 13.8 8.54 13.8C5.35884 13.8 2.78 11.2211 2.78 8.03996ZM7.9 10.6V8.03996C7.9 7.68649 8.18654 7.39996 8.54 7.39996C8.89346 7.39996 9.18 7.68649 9.18 8.03996V10.6C9.18 10.9534 8.89346 11.24 8.54 11.24C8.18654 11.24 7.9 10.9534 7.9 10.6ZM9.18 5.47996C9.18 5.12649 8.89346 4.83996 8.54 4.83996C8.18654 4.83996 7.9 5.12649 7.9 5.47996C7.9 5.83342 8.18654 6.11996 8.54 6.11996C8.89346 6.11996 9.18 5.83342 9.18 5.47996Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconInfo = React.forwardRef(_IconInfo)

export { IconInfo }
