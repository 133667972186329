import React from 'react'

function _IconSquaresPlus(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.69995 3.49995C3.69995 1.95355 4.95355 0.699951 6.49995 0.699951H14.5C16.0463 0.699951 17.3 1.95355 17.3 3.49995V11.5C17.3 13.0463 16.0463 14.3 14.5 14.3H14.3V14.5C14.3 16.0463 13.0463 17.3 11.5 17.3H3.49995C1.95355 17.3 0.699951 16.0463 0.699951 14.5V6.49995C0.699951 4.95355 1.95355 3.69995 3.49995 3.69995H3.69995V3.49995ZM14.5 12.7H14.3V6.49995C14.3 4.95355 13.0463 3.69995 11.5 3.69995H5.29995V3.49995C5.29995 2.83721 5.83721 2.29995 6.49995 2.29995H14.5C15.1627 2.29995 15.7 2.83721 15.7 3.49995V11.5C15.7 12.1627 15.1627 12.7 14.5 12.7ZM3.49995 5.29995C2.83721 5.29995 2.29995 5.83721 2.29995 6.49995V14.5C2.29995 15.1627 2.83721 15.7 3.49995 15.7H11.5C12.1627 15.7 12.7 15.1627 12.7 14.5V6.49995C12.7 5.83721 12.1627 5.29995 11.5 5.29995H3.49995ZM8.29995 7.99995C8.29995 7.55812 7.94178 7.19995 7.49995 7.19995C7.05812 7.19995 6.69995 7.55812 6.69995 7.99995V9.69995H4.99995C4.55812 9.69995 4.19995 10.0581 4.19995 10.5C4.19995 10.9418 4.55812 11.3 4.99995 11.3H6.69995V13C6.69995 13.4418 7.05812 13.8 7.49995 13.8C7.94178 13.8 8.29995 13.4418 8.29995 13V11.3H9.99995C10.4418 11.3 10.8 10.9418 10.8 10.5C10.8 10.0581 10.4418 9.69995 9.99995 9.69995H8.29995V7.99995Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconSquaresPlus = React.memo(React.forwardRef(_IconSquaresPlus))

export { IconSquaresPlus }
