import React, { Ref } from 'react'

function _IconBlocked(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  const { className } = props
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={ref}
      className={className}
    >
      <circle cx="12" cy="12" r="11" stroke="white" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.219 3.80498C20.6028 4.20207 20.5921 4.83514 20.195 5.21899L5.19502 19.719C4.79794 20.1028 4.16486 20.0921 3.78101 19.695C3.39716 19.2979 3.40789 18.6649 3.80498 18.281L18.805 3.78101C19.2021 3.39716 19.8351 3.40789 20.219 3.80498Z"
        fill="white"
      />
    </svg>
  )
}

export const IconBlocked = React.forwardRef(_IconBlocked)
