import arriereBackoffice, { BookingStatus } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { CalendarBlocksProps, EditCalendarBlockProps } from '@arriere/types/CalendarBlocks.types'

const validBookingStatus = [
  BookingStatus.booked,
  BookingStatus.checkedIn,
  BookingStatus.checkedOut,
  BookingStatus.modified,
  BookingStatus.noShow,
  BookingStatus.quoted,
  BookingStatus.requested,
]

export default {
  async getBookingsForTapechartBlocks(query: string) {
    return arriereBackoffice.queries
      .bookings({
        __alias: 'getBookingsForTapechartBlocks',
        __args: { filter: query, status: validBookingStatus },
        results: {
          detailedCheckIn: {
            effectiveTime: true,
          },
          detailedCheckOut: {
            effectiveTime: true,
          },
          guestName: true,
          hash: true,
          home: {
            name: true,
          },
          id: true,
        },
      })
      .then(data => data.results)
  },

  async createTapeChartBlock({
    homeId,
    kind,
    period,
    note,
    expiresAt,
    notificationSetting,
    bookingId,
  }: CalendarBlocksProps) {
    return arriereBackoffice.mutations.createBlock.raw({
      __alias: 'createTapeChartBlock',
      __args: {
        homeId,
        kind,
        note,
        period,
        expiresAt,
        notificationSetting,
        bookingId,
      },
      id: true,
      source: true,
      createdAt: true,
      createdBy: true,
    })
  },

  async editTapeChartBlock({
    id,
    newNote,
    newKind,
    newEnd,
    newStart,
    newExpiresAt,
    newNotificationSetting,
  }: EditCalendarBlockProps) {
    return arriereBackoffice.mutations.editBlock.raw({
      __alias: 'editTapeChartBlock',
      __args: {
        id,
        newNote,
        newKind,
        newStart,
        newEnd,
        newExpiresAt,
        newNotificationSetting,
      },
    })
  },

  async cancelTapeChartBlock(blockId: string) {
    return arriereBackoffice.mutations.deleteBlock.raw({
      __alias: 'cancelTapeChartBlock',
      __args: {
        id: blockId,
      },
    })
  },
}
