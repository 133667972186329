import loadScript from '@utils/loadScript'

export const googleAuthConfig = {
  client_id: process.env.REACT_APP_TABLEAU_GOOGLE_CLIENT_ID,
  hosted_domain: 'avantstay.com',
}

export const googleAuth: Promise<any> = new Promise((resolve, reject) => {
  loadScript('https://apis.google.com/js/platform.js')
    .then(() => {
      const googleApi = (window as any).gapi

      googleApi.load('auth2', async () => {
        try {
          const auth = await googleApi.auth2.init(googleAuthConfig)
          resolve(auth)
        } catch (err) {
          reject(err)
        }
      })
    })
    .catch(err => {
      reject(err)
    })
})
