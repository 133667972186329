import { ReactNode } from 'react'

export interface FilterButtonLabelProps {
  checked?: boolean
  disabled?: boolean
}

export interface FilterButtonControlProps extends FilterButtonLabelProps {
  id: string
  value: any
  title?: string
  label?: string
  count?: number
  className?: string
  defaultChecked?: boolean
  multipleSelect?: boolean
  children?: ReactNode
}

export interface FilterButtonsGroupProps {
  allowNoSelectedValue?: boolean
  className?: string
  options: FilterButtonControlProps[]
  groupLabel: string
  currentValue?: any
  multipleSelect?: boolean
  onClick: (value: any) => void
}

export enum ControlTheme {
  accent = 'ACCENT',
  failure = 'FAILURE',
}
