import { forwardRef, Ref, SVGProps } from 'react'

const _IconRoundCheckEmpty = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        id="Oval"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  )
}

export const IconRoundCheckEmpty = forwardRef(_IconRoundCheckEmpty)
