import { ChangeEvent } from 'react'

interface ToggleFieldProps {
  autofill?: boolean
}

interface ToggleSwitchInputProps extends ToggleFieldLabelProps {
  id: string
  title: string
  defaultChecked?: boolean
  className?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  label?: string
  disabled?: boolean
}

enum ToggleBooleanType {
  true,
  false,
}

interface ToggleFieldLabelProps {
  value: any
  checked?: boolean
  toggleType?: ToggleBooleanType
}

export { ToggleFieldProps, ToggleSwitchInputProps, ToggleFieldLabelProps, ToggleBooleanType }
