import React, { useState } from 'react'

import StoreValuesContext from './StoreValuesContext'

type StoreValuesProviderProps = {
  children: React.ReactNode
}

function StoreValuesProvider(props: StoreValuesProviderProps) {
  const [state, setState] = useState<Record<string, any>>({})

  const getSetState = (storeName: string) => {
    return (newValues: { [key: string]: any }) =>
      setState(prev => ({
        ...prev,
        [storeName]: {
          ...prev[storeName],
          ...newValues,
        },
      }))
  }

  const finalState = React.useMemo(() => ({ state, getSetState }), [state])

  return (
    <StoreValuesContext.Provider value={finalState}>{props.children}</StoreValuesContext.Provider>
  )
}

export default StoreValuesProvider
