import React, { forwardRef, Ref, SVGProps } from 'react'

function _ConfigurationStatusOk(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        fill="#DEECFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0743 8.4423C17.3818 8.75949 17.3741 9.26596 17.0569 9.57354L10.8694 15.5735C10.5591 15.8744 10.0658 15.8744 9.75554 15.5735L6.94304 12.8463C6.62585 12.5387 6.61805 12.0322 6.92563 11.715C7.23321 11.3978 7.73968 11.39 8.05687 11.6976L10.3125 13.8849L15.943 8.4249C16.2602 8.11732 16.7667 8.12511 17.0743 8.4423Z"
        fill="#3788F7"
      />
    </svg>
  )
}

export const ConfigurationStatusOk = forwardRef(_ConfigurationStatusOk)
