import React, { Ref } from 'react'

function _IconLockOpen(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.40381C7.35095 2.40381 6.72849 2.66164 6.26954 3.12059C5.8106 3.57953 5.55276 4.202 5.55276 4.85105V6.66848H12.4086C13.4919 6.66848 14.37 7.54662 14.37 8.62987V13.0385C14.37 14.1217 13.4919 14.9999 12.4086 14.9999H3.59139C2.50815 14.9999 1.63 14.1217 1.63 13.0385V8.62987C1.63 7.54662 2.50815 6.66848 3.59139 6.66848H4.1492V4.85105C4.1492 3.82975 4.55491 2.85028 5.27707 2.12812C5.99924 1.40595 6.9787 1.00024 8 1.00024C9.0213 1.00024 10.0008 1.40595 10.7229 2.12812C10.997 2.40218 10.997 2.84652 10.7229 3.12059C10.4489 3.39465 10.0045 3.39465 9.73046 3.12059C9.27151 2.66164 8.64905 2.40381 8 2.40381ZM4.85098 8.07204L4.84981 8.07204H3.59139C3.28332 8.07204 3.03357 8.32179 3.03357 8.62987V13.0385C3.03357 13.3466 3.28332 13.5963 3.59139 13.5963H12.4086C12.7167 13.5963 12.9665 13.3466 12.9665 13.0385V8.62987C12.9665 8.32179 12.7167 8.07204 12.4086 8.07204H4.85215L4.85098 8.07204ZM9.07971 10.1592C9.07971 10.5588 8.86259 10.9078 8.53987 11.0944V11.7788C8.53987 12.0769 8.29818 12.3186 8.00004 12.3186C7.7019 12.3186 7.46021 12.0769 7.46021 11.7788V11.0944C7.1375 10.9077 6.92038 10.5588 6.92038 10.1592C6.92038 9.56292 7.40377 9.07954 8.00005 9.07954C8.59633 9.07954 9.07971 9.56292 9.07971 10.1592Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconLockOpen = React.forwardRef(_IconLockOpen)
