import React, { useMemo } from 'react'
import 'tippy.js/dist/tippy.css'

import { IconError, IconInfo } from '@avantstay/backoffice-vectors'

import { Tooltip, TooltipTheme } from '../../components/Tooltip'
import { mainColors, semanticColors } from '../../styles/colors'
import { InfoTooltipProps } from './InfoTooltip.types'

const InfoTooltip = ({
  error,
  warning,
  info,
  disabled,
  interactive,
  placement = 'bottom',
  maxWidth,
}: InfoTooltipProps): JSX.Element => {
  const hasError = Boolean(error)
  const hasWarning = Boolean(warning)

  const tooltipColors = useMemo(() => {
    if (hasError) {
      return {
        theme: TooltipTheme.danger,
        icon: <IconError color={semanticColors.failureMedium} />,
      }
    }
    if (hasWarning) {
      return { theme: TooltipTheme.alert, icon: <IconError color={semanticColors.warningMedium} /> }
    }
    return {
      theme: TooltipTheme.dark,
      icon: <IconInfo color={mainColors.neutralExtraDark} />,
    }
  }, [hasError, hasWarning])

  return (
    <Tooltip
      content={error || warning || info}
      disabled={disabled}
      interactive={interactive}
      theme={tooltipColors.theme}
      arrow
      placement={placement}
      maxWidth={maxWidth}
    >
      {tooltipColors.icon}
    </Tooltip>
  )
}

export { InfoTooltip }
