import styled from 'styled-components'
import { mainColors } from '../../styles'
import { BadgeDefault } from '../Badge'

export const TagContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${mainColors.neutralDark};

  ${BadgeDefault} {
    padding: 0 0 0 6px;
  }
`
