import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconWarning(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9997 1.177C10.5488 1.177 10.1036 1.28735 9.70653 1.49985C9.30928 1.71246 8.97152 2.02143 8.72947 2.40076L9.40562 2.83222L8.72703 2.40461L0.831252 14.9346L0.82442 14.9459C0.586859 15.337 0.459366 15.7837 0.458019 16.2412C0.456671 16.6988 0.581532 17.1462 0.816845 17.5387C1.05195 17.9308 1.38793 18.2526 1.78743 18.4761C2.18671 18.6994 2.6376 18.818 3.09561 18.8228L18.8954 18.8229L18.9037 18.8228C19.3617 18.818 19.8126 18.6994 20.2119 18.4761C20.6114 18.2526 20.9474 17.9308 21.1825 17.5387C21.4178 17.1462 21.5427 16.6988 21.5413 16.2412C21.54 15.7837 21.4126 15.3369 21.175 14.9458L13.2723 2.4046L13.2699 2.40076C13.0278 2.02143 12.6901 1.71246 12.2928 1.49985C11.8958 1.28735 11.4505 1.177 10.9997 1.177ZM10.0818 3.26367L10.0828 3.26207C10.1731 3.12122 10.3029 3.00014 10.4635 2.9142C10.6249 2.82784 10.8097 2.78117 10.9997 2.78117C11.1897 2.78117 11.3745 2.82784 11.5359 2.9142C11.6964 3.00011 11.8262 3.12116 11.9165 3.26197L11.9176 3.26367L19.8068 15.7835C19.8929 15.9272 19.9367 16.0864 19.9372 16.246C19.9376 16.4073 19.8938 16.5685 19.8067 16.7137C19.7194 16.8594 19.5905 16.9856 19.4288 17.076C19.268 17.166 19.0824 17.2161 18.8906 17.2187H3.10873C2.91698 17.2161 2.73138 17.166 2.57053 17.076C2.40888 16.9856 2.27995 16.8594 2.19264 16.7137C2.10553 16.5685 2.0617 16.4073 2.06218 16.246C2.06265 16.0864 2.10645 15.9272 2.19257 15.7835L10.0818 3.26367ZM10.9997 6.87494C11.4426 6.87494 11.8017 7.23404 11.8017 7.67702V11.1145C11.8017 11.5575 11.4426 11.9166 10.9997 11.9166C10.5567 11.9166 10.1976 11.5575 10.1976 11.1145V7.67702C10.1976 7.23404 10.5567 6.87494 10.9997 6.87494ZM11.8017 14.2081C11.8017 14.6511 11.4426 15.0102 10.9997 15.0102C10.5567 15.0102 10.1976 14.6511 10.1976 14.2081C10.1976 13.7651 10.5567 13.406 10.9997 13.406C11.4426 13.406 11.8017 13.7651 11.8017 14.2081Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconWarning = forwardRef(_IconWarning)
