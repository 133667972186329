import React from 'react'
import styled from 'styled-components'

import { mainColors } from '@avantstay/backoffice-core'

export const horizontalStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginBottom: '15px',
}

export const Placeholder = styled.div`
  width: 104px;
  height: 188px;
  border: 2px dotted ${mainColors.neutralMedium};
  margin-bottom: 15px;
  margin-right: 10px;

  &:first-child {
    margin-right: 10px;
  }
`

export const PlaceholderContainer = styled.div<{
  isDraggingOver: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  max-width: 712px;
  position: absolute;
  z-index: 0;
  margin-top: 0;

  ${Placeholder}:first-child {
    border: none;
  }

  ${props =>
    !props.isDraggingOver &&
    `
    visibility: hidden;
  `}
`
