import React, { Ref } from 'react'

function _IconOccupied(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34998 7.57495C9.34998 6.1252 10.5252 4.94995 11.975 4.94995C13.4247 4.94995 14.6 6.1252 14.6 7.57495C14.6 9.0247 13.4247 10.2 11.975 10.2C10.5252 10.2 9.34998 9.0247 9.34998 7.57495ZM11.975 3.19995C9.55873 3.19995 7.59998 5.15871 7.59998 7.57495C7.59998 9.9912 9.55873 11.95 11.975 11.95C14.3912 11.95 16.35 9.9912 16.35 7.57495C16.35 5.15871 14.3912 3.19995 11.975 3.19995ZM8.47498 13.7C7.31465 13.7 6.20185 14.1609 5.38138 14.9814C4.56091 15.8018 4.09998 16.9146 4.09998 18.075V19.825C4.09998 20.3082 4.49173 20.7 4.97498 20.7C5.45822 20.7 5.84998 20.3082 5.84998 19.825V18.075C5.84998 17.3788 6.12654 16.7111 6.61882 16.2188C7.1111 15.7265 7.77878 15.45 8.47498 15.45H15.475C16.1712 15.45 16.8388 15.7265 17.3311 16.2188C17.8234 16.7111 18.1 17.3788 18.1 18.075V19.825C18.1 20.3082 18.4917 20.7 18.975 20.7C19.4582 20.7 19.85 20.3082 19.85 19.825V18.075C19.85 16.9146 19.389 15.8018 18.5686 14.9814C17.7481 14.1609 16.6353 13.7 15.475 13.7H8.47498Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconOccupied = React.forwardRef(_IconOccupied)
