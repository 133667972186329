import React from 'react'

function _IconShare(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2871 3.80691C12.3755 3.84351 12.4584 3.89776 12.5303 3.96967L15.5303 6.96967C15.8232 7.26256 15.8232 7.73744 15.5303 8.03033C15.2374 8.32322 14.7626 8.32322 14.4697 8.03033L12.75 6.31066V14.25C12.75 14.6642 12.4142 15 12 15C11.5858 15 11.25 14.6642 11.25 14.25V6.31066L9.53033 8.03033C9.23744 8.32322 8.76256 8.32322 8.46967 8.03033C8.17678 7.73744 8.17678 7.26256 8.46967 6.96967L11.4697 3.96967C11.6161 3.82322 11.8081 3.75 12 3.75C12.1017 3.75 12.1987 3.77024 12.2871 3.80691ZM6 11.25C6.41421 11.25 6.75 11.5858 6.75 12V18C6.75 18.1989 6.82902 18.3897 6.96967 18.5303C7.11032 18.671 7.30109 18.75 7.5 18.75H16.5C16.6989 18.75 16.8897 18.671 17.0303 18.5303C17.171 18.3897 17.25 18.1989 17.25 18V12C17.25 11.5858 17.5858 11.25 18 11.25C18.4142 11.25 18.75 11.5858 18.75 12V18C18.75 18.5967 18.5129 19.169 18.091 19.591C17.669 20.0129 17.0967 20.25 16.5 20.25H7.5C6.90326 20.25 6.33097 20.0129 5.90901 19.591C5.48705 19.169 5.25 18.5967 5.25 18V12C5.25 11.5858 5.58579 11.25 6 11.25Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconShare = React.memo(React.forwardRef(_IconShare))

export { IconShare }
