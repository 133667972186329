import { useCallback, useEffect, useReducer, useRef, useState } from 'react'

import arrierePublic from '@avantstay/arriere-clients/dist/arrierePublic'

import { formatPhoneString } from '../utils/format'

enum CodeReducerActionType {
  Change = 'change',
  Reset = 'reset',
}

type CodeReducerState = Array<number | undefined>

interface CodeReducerAction {
  payload?: {
    index: number
    value: number | undefined
  }
  type: CodeReducerActionType
}

interface UsePhoneVerificationParams {
  phone: string
  onVerified?: (code: string, phone: string) => void
}

const CODE_REDUCER_INITIAL_STATE: CodeReducerState = new Array(4).fill(undefined)

function codeReducer(state: CodeReducerState, action: CodeReducerAction) {
  switch (action.type) {
    case CodeReducerActionType.Change:
      return state.map((value, index) => {
        const { payload } = action
        return index === payload?.index ? payload?.value : value
      })
    case CodeReducerActionType.Reset:
      return [...CODE_REDUCER_INITIAL_STATE]
    default:
      return state
  }
}

export function usePhoneVerification({ phone, onVerified }: UsePhoneVerificationParams) {
  const clearErrorTimeoutId = useRef<number>()
  const [digits, dispatchCodeAction] = useReducer(codeReducer, CODE_REDUCER_INITIAL_STATE)

  const [error, setError] = useState<string>()
  const [isPhoneVerified, setIsPhoneVerified] = useState(false)
  const [isVerifyingPhoneNumber, setIsVerifyingPhoneNumber] = useState(false)

  const code = digits.reduce((code, digit) => `${code}${digit !== undefined ? digit : ''}`, '')
  const isCodeComplete = digits.every(digit => digit !== undefined)

  const changeCodeDigit = (index: number, value: number | undefined) => {
    dispatchCodeAction({ payload: { index, value }, type: CodeReducerActionType.Change })

    window.clearTimeout(clearErrorTimeoutId.current)
    setError(undefined)
  }

  const resetVerificationCode = () => {
    dispatchCodeAction({ type: CodeReducerActionType.Reset })
  }

  const sendVerificationCode = async () => {
    const formattedPhone = formatPhoneString(phone) as string
    try {
      await arrierePublic.mutations.requestPhoneVerificationCode({
        __args: { phone: formattedPhone },
      })
    } catch (error) {
      throw error
    }
  }

  // Temporary error message for invalid code
  const createError = useCallback((error: string, timeout: number = 0, reset: boolean = false) => {
    window.clearTimeout(clearErrorTimeoutId.current)
    setError(error)

    if (timeout !== 0) {
      clearErrorTimeoutId.current = window.setTimeout(() => {
        if (reset) {
          resetVerificationCode()
        }
        setError(undefined)
      }, timeout)
    }
  }, [])

  // Callback for execution after verification
  const runAfterVerification = useCallback(
    (code: string, phone: string) => {
      onVerified?.(code, phone)
    },
    [onVerified],
  )

  // Callback for phone number verification
  const verifyPhoneNumber = useCallback(async () => {
    setIsVerifyingPhoneNumber(true)

    try {
      const formattedPhone = formatPhoneString(phone)
      const isValid = await arrierePublic.mutations.verifyPhoneVerificationCode({
        __args: { code: parseInt(code, 10), phone: formattedPhone! },
      })

      if (isValid) {
        runAfterVerification(code, formattedPhone!)
        resetVerificationCode()
      } else {
        createError('The code is not valid. Try again', 2000, true)
      }

      setIsPhoneVerified(isValid)
    } catch (error) {
      resetVerificationCode()
      setIsPhoneVerified(false)
      throw error
    } finally {
      setIsVerifyingPhoneNumber(false)
    }
  }, [code, createError, phone, runAfterVerification])

  // Effect for state reset when phone is modified
  useEffect(() => {
    resetVerificationCode()

    setError(undefined)
    setIsPhoneVerified(false)
    setIsVerifyingPhoneNumber(false)

    window.clearTimeout(clearErrorTimeoutId.current)
  }, [phone])

  // Effect for phone number verification
  useEffect(() => {
    if (isCodeComplete && phone) {
      verifyPhoneNumber()
    }
  }, [isCodeComplete, phone, verifyPhoneNumber])

  return {
    changeCodeDigit,
    code,
    createError,
    digits,
    error,
    isPhoneVerified,
    isVerifyingPhoneNumber,
    sendVerificationCode,
  }
}
