export enum HorizontalAlignment {
  Left = 'left',
  Right = 'right',
}

export enum TextAlign {
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum TextWeight {
  light,
  normal,
  medium,
  bold,
}
