import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconChevronFilledDown = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.61589 5.53907C4.81579 5.77894 5.18421 5.77894 5.38411 5.53907L9.31659 0.820091C9.58798 0.494428 9.3564 0 8.93248 0H1.06752C0.643602 0 0.412024 0.494429 0.68341 0.820092L4.61589 5.53907Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconChevronFilledDown = forwardRef(_IconChevronFilledDown)
