// @ts-nocheck
import humanizeString from 'humanize-string'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import { mainColors } from '@avantstay/backoffice-core'

import { IconSelected } from '@new/__ui/__vectors'
import RadioField from '@new/__ui/RadioField/RadioField'

// eslint-disable-next-line import/no-cycle
import { jsonParseCustomFieldValue } from '@utils/customFields'

import {
  CustomFieldProps,
  CustomFieldTypeReturn,
  IInputProps,
  IMountComponentProps,
} from '../_types'
import { customFieldsTooltipContentWriteMode } from '../constants'
import { stringifyValue } from '../CustomField.utils'
import * as S from './types.styles'

function ComponentReadMode({ customField: { value } }: IMountComponentProps) {
  return (
    <S.Option>
      <IconSelected fill={mainColors.neutralExtremelyDark} />
      {typeof value === 'string' && !!value ? humanizeString(JSON.parse(value) || '') : ''}
    </S.Option>
  )
}

function ComponentWriteMode({
  customField,
  inputProps,
  isReadOnly,
  error,
  disabled,
}: IMountComponentProps) {
  if (isReadOnly) return <ComponentReadMode customField={customField} />

  return (
    <RadioField.Group
      {...(inputProps as IInputProps)}
      value={inputProps?.value}
      label={customField.id}
      // eslint-disable-next-line
      options={customField.options as unknown as any}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      error={error}
      customTooltipContent={customFieldsTooltipContentWriteMode[customField.name]}
      disabled={disabled}
    />
  )
}

const parseValueToShow = (customField: CustomFieldProps) => {
  const value = jsonParseCustomFieldValue(customField)

  return isEmpty(value) ? null : value
}

const parseValueToSave = (customField: CustomFieldProps) => {
  return stringifyValue(customField.value)
}

export default {
  read: { Component: ComponentReadMode },
  write: { Component: ComponentWriteMode },
  parseValueToShow,
  parseValueToSave,
} as CustomFieldTypeReturn
