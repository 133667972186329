import styled, { css } from 'styled-components'

import { Button } from '../../components/Button'
import { mainColors } from '../../styles/colors'
import { zIndex } from '../../styles/zIndex'
import { rgba } from '../../utils/cssUtils'

const bottomShadowFilterSearchContainerStyle = css`
  position: relative;

  &::after {
    background: linear-gradient(180deg, ${mainColors.white} 20%, ${rgba(mainColors.white, 0)} 100%);
    content: '';
    height: 28px;
    position: absolute;
    top: 100%;
    width: 100%;
  }
`

const stickyFilterSearchContainerStyle = css`
  background-color: white;
  left: 0;
  position: sticky;
  top: 0;
  z-index: 1;
`

export const ClearButton = styled(Button.Flat)`
  min-height: 20px;
  margin-bottom: 0;
`

export const ShowColumnButton = styled(ClearButton)`
  svg {
    width: 24px;
    height: 24px;
  }
`

export const Container = styled.div<{ isVisible?: boolean }>`
  ${({ isVisible }) => !isVisible && `visibility: hidden;`}
  position: absolute;
  z-index: ${zIndex.EDITABLE_ACTION_BAR};
  border: 1px solid ${mainColors.neutralMedium};
  background: ${mainColors.white};
  margin-top: 6px;
  margin-left: 46px;
  padding: 18px 15px;
  min-width: 148px;
  max-width: 176px;
  flex-shrink: 0;
`

export const FilterSearchContainer = styled.div<{ hasBottomShadow: boolean; isSticky: boolean }>`
  ${p => p.hasBottomShadow && bottomShadowFilterSearchContainerStyle};
  ${p => p.isSticky && stickyFilterSearchContainerStyle};
  margin-bottom: 14px;
  padding-top: 10px;
`
