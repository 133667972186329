import React, { Ref } from 'react'

function _IconBed(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.23888 1.78125C2.51578 1.78125 1.96557 2.38563 1.96557 3.08587V6.13952C1.3056 6.32062 0.838379 6.94288 0.838379 7.65204V10.1308V10.1309V11.6964C0.838379 11.9846 1.07202 12.2182 1.36023 12.2182C1.64844 12.2182 1.88208 11.9846 1.88208 11.6964V10.6527H12.1103V11.6964C12.1103 11.9846 12.3439 12.2182 12.6322 12.2182C12.9204 12.2182 13.154 11.9846 13.154 11.6964V10.1309L13.154 10.1294V7.65204C13.154 6.94289 12.6868 6.32062 12.0268 6.13952V3.08587C12.0268 2.38563 11.4766 1.78125 10.7535 1.78125H3.23888ZM11.5057 7.1302L11.505 7.1302L11.5043 7.1302H9.25058H4.74181H2.48813L2.48742 7.1302L2.48671 7.1302H2.36218C2.11689 7.1302 1.88208 7.34354 1.88208 7.65204V9.60898H12.1103V7.65204C12.1103 7.34354 11.8755 7.1302 11.6302 7.1302H11.5057ZM5.26366 6.0865H8.72873V5.17326H5.26366V6.0865ZM9.77243 6.0865V5.17326C9.77243 4.61714 9.33437 4.12957 8.7496 4.12957H5.24279C4.65802 4.12957 4.21996 4.61713 4.21996 5.17326V6.0865H3.00927V3.08587C3.00927 2.92148 3.13194 2.82495 3.23888 2.82495H10.7535C10.8604 2.82495 10.9831 2.92148 10.9831 3.08587V6.0865H9.77243Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconBed = React.forwardRef(_IconBed)
