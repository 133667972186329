import React, { ChangeEvent, forwardRef, useEffect, useRef, useState } from 'react'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { IconChevronDown, IconChevronUp } from '@avantstay/backoffice-vectors'

import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { TextField } from '../TextField'
import { Tooltip } from '../Tooltip'
import * as S from './QuantitySelectField.styles'
import { QuantitySelectFieldProps } from './QuantitySelectField.types'

export const QuantitySelectField = forwardRef<HTMLDivElement, QuantitySelectFieldProps>(
  (
    {
      value,
      onChange,
      max = Infinity,
      min,
      disabled,
      isWholeNumberOnly = false,
      small,
      minTooltip,
      maxTooltip,
      sufix,
      prefix,
      ...props
    },
    ref,
  ): JSX.Element => {
    const localRef = useRef<HTMLDivElement | null>(null)
    const [quantity, setQuantity] = useState(value || 0)

    useEffect(() => {
      if (value !== undefined && value !== quantity) setQuantity(value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
      if (onChange) onChange(quantity)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity])

    useOnClickOutside(localRef, () => {
      if (quantity === '') {
        setQuantity(min || 0)
      }
    })

    const handleValueChange = (factor: number) => {
      if (disabled) return

      const newValue = parseInt(value || 0, 10) + factor
      setQuantity(newValue)
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(e.target.value)

      if (newValue >= min && newValue <= max && newValue !== quantity) {
        if (isWholeNumberOnly) {
          /**
           * To validate if it is a whole number, we subtract the value from it's floored value
           * if it's 0 then the value is the same therefore it's a whole number, if it isn't then it has decimal values
           * */
          const isWholeNumber = newValue - Math.floor(newValue) === 0
          if (isWholeNumber) {
            setQuantity(newValue)
          }
        } else {
          setQuantity(newValue)
        }
      }

      if (e.target.value === '') {
        setQuantity(e.target.value)
      }
    }

    return (
      <S.InputQuantityContainer isDisabled={disabled} ref={ref}>
        <TextField.Default
          {...props}
          ref={localRef}
          type="number"
          mask={createNumberMask({
            allowDecimal: false,
            allowLeadingZeroes: false,
          })}
          disabled={disabled}
          value={quantity}
          small={small}
          afterValue={sufix}
          beforeValue={prefix}
          onChange={handleOnChange}
        />
        <S.InputQuantityIconContainer isDisabled={disabled} small={small}>
          <Tooltip content={maxTooltip} disabled={!maxTooltip || value < max} placement="top">
            <S.ArrowButton
              onClick={() => {
                if (min <= value && value < max) handleValueChange(1)
              }}
              aria-hidden="true"
            >
              <IconChevronUp />
            </S.ArrowButton>
          </Tooltip>

          <S.Divider />
          <Tooltip content={minTooltip} disabled={!minTooltip || value > min} placement="bottom">
            <S.ArrowButton
              aria-hidden="true"
              onClick={() => {
                if (min < value && value <= max) handleValueChange(-1)
              }}
            >
              <IconChevronDown />
            </S.ArrowButton>
          </Tooltip>
        </S.InputQuantityIconContainer>
      </S.InputQuantityContainer>
    )
  },
)
