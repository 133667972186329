import React from 'react'

function _IconCancel(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C13.7319 4.75 15.322 5.35727 16.5689 6.3705L6.3705 16.5689C5.35727 15.322 4.75 13.7319 4.75 12ZM5.80515 18.1795C5.80767 18.1821 5.81021 18.1847 5.81278 18.1872C5.81535 18.1898 5.81793 18.1924 5.82052 18.1949C7.40335 19.7738 9.58767 20.75 12 20.75C16.8325 20.75 20.75 16.8325 20.75 12C20.75 9.5864 19.7728 7.40104 18.1924 5.81801C18.1907 5.81627 18.1889 5.81454 18.1872 5.81281C18.1855 5.81107 18.1837 5.80934 18.182 5.80763C16.599 4.22724 14.4136 3.25 12 3.25C7.16751 3.25 3.25 7.16751 3.25 12C3.25 14.4123 4.22622 16.5967 5.80515 18.1795ZM17.6295 7.43116C18.6427 8.67806 19.25 10.2681 19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C10.2681 19.25 8.67806 18.6427 7.43116 17.6295L17.6295 7.43116Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconCancel = React.memo(React.forwardRef(_IconCancel))

export { IconCancel }
