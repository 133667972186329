import React, { useEffect, useState } from 'react'

import {
  colorsByExtension,
  downloadFile,
  FileResolutionProps,
  getImageDimensions,
  Tooltip,
} from '@avantstay/backoffice-core'
import { IconClose } from '@avantstay/backoffice-vectors'

import { IconAsset } from '@new/__ui/__vectors'
import { FilePreviewType, UploadAreaType } from '@new/__ui/UploadArea/_types'
import { FilePreviewActions } from '@new/__ui/UploadArea/components/FilePreviewActions'
import ImagePreviewModal from '@new/__ui/UploadArea/FilePreview/ImagePreviewModal'

import { isImage as checkIsImage } from '@utils/images'

import { ProgressBar } from '../components/ProgressBar'
import { FilePreviewMode } from './_enums'
import { FilePreviewProps } from './_types'

import * as S from './FilePreview.styles'

export const UUID_REGEX = `\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b`

const MIN_IMAGE_WIDTH = 1300 // px

function FilePreview({
  allFiles,
  file,
  filePreviewActions,
  fileUploadProgress,
  filesWithError,
  index,
  isFileSelected,
  filePreviewType,
  isSelecting,
  onMakeAsCover,
  onRetryUpload,
  onSelectFile,
  providedDraggable,
  uploadAreaType,
  removeFile,
  viewMode = FilePreviewMode.grid,
}: FilePreviewProps) {
  // isGallery prop allows us to differentiate image header with edit actions and preview sizes

  const [isLowResolution, setIsLowQuality] = useState(false)
  const [openImagePreview, setOpenImagePreview] = useState(false)
  const [resolution, setResolution] = useState<FileResolutionProps | undefined>({
    width: 0,
    height: 0,
  })

  const decodedFileName = decodeURIComponent(file?.fileName || '')
  const extension = (((file.fileName || file.url) ?? '').split('.').pop() ?? '').toUpperCase()
  const filePosition = index + 1
  const isCover = index === 0
  const isDocument = uploadAreaType === UploadAreaType.document
  const isImage = checkIsImage(extension)

  const isPhotoGallery = filePreviewType === FilePreviewType.photoGallery

  const fileCurrentId = isDocument ? file.documentId : file.id
  const fileUploaded = !!file.s3Key

  /**
   * We pass files with errors with the original File object
   * in order to be able to retry to upload the file
   */
  const originalFile = filesWithError && fileCurrentId && filesWithError[fileCurrentId]
  const hasError = !!originalFile

  const isFileUploadedWithoutErrors = fileUploaded && !hasError

  const Wrapper = isDocument ? S.DocumentPreviewContainer : S.FilePreviewContainer

  const removeFileCb = () => removeFile(index)

  useEffect(() => {
    getImageDimensions(file?.url)
      .then(setResolution)
      .catch(() => setResolution(undefined))
  }, [file.url, filePreviewType])

  useEffect(() => {
    if (resolution?.width && resolution?.width < MIN_IMAGE_WIDTH && isPhotoGallery) {
      setIsLowQuality(true)
    }
  }, [resolution])

  const fileUploadProgressContainer = () => (
    <S.ProgressContainer>
      {/*
      as number used bc even though isUploading is used to verify
      fileUploadProgress, TS still reads as possibly undefined
      */}
      <ProgressBar percentage={fileUploadProgress as number} />
      <S.ProgressValue>
        {fileUploadProgress ? (fileUploadProgress * 100).toFixed(0) : 0}%
      </S.ProgressValue>
    </S.ProgressContainer>
  )

  const renderPreview = () => {
    if (isDocument) {
      return (
        <S.DocumentPlaceholder>
          <S.DocumentPreview src={file.url} />
        </S.DocumentPlaceholder>
      )
    }

    if (isImage) {
      return (
        <S.ImagePlaceholder
          error={hasError}
          warning={isLowResolution}
          $isCover={isCover}
          $hasFlatBorder={filePreviewType === FilePreviewType.photoGallery}
        >
          {isLowResolution ? (
            <S.ImageOverlay
              warning={isLowResolution}
              error={hasError}
              onClick={() => setOpenImagePreview(true)}
            />
          ) : null}
          <S.Image
            src={
              isPhotoGallery
                ? `https://imglite.avantstay.com/${file.url}?avif=false&webp=true&quality=1`
                : file.url
            }
            onClick={() => setOpenImagePreview(true)}
            $hasFlatBorder={filePreviewType === FilePreviewType.photoGallery}
          />
          {isPhotoGallery ? (
            isCover ? (
              <S.Cover>Gallery Cover</S.Cover>
            ) : (
              onMakeAsCover && (
                <S.MakeAsCoverButton onClick={onMakeAsCover}>Use as cover</S.MakeAsCoverButton>
              )
            )
          ) : null}
        </S.ImagePlaceholder>
      )
    }

    return (
      <S.FileContainer>
        <IconAsset />
        <S.Extension
          background={colorsByExtension[extension]?.background}
          color={colorsByExtension[extension]?.background}
        >
          {extension}
        </S.Extension>
      </S.FileContainer>
    )
  }

  const handleOnRetryUpload = () => {
    if (onRetryUpload && originalFile) {
      onRetryUpload(originalFile)
    }
  }

  const handleDownloadFile = async () => {
    await downloadFile(file?.url, decodedFileName, {
      method: 'GET',
      cache: 'no-store',
      headers: {
        'Content-Type': 'image/*',
      },
    })
  }

  const handleSelectFile = () => {
    if (onSelectFile && fileCurrentId) {
      onSelectFile(fileCurrentId)
    }
  }

  return (
    <S.Container viewMode={viewMode}>
      <Wrapper
        data-testid="upload_item"
        ref={providedDraggable?.innerRef}
        {...providedDraggable?.draggableProps}
        {...providedDraggable?.dragHandleProps}
        filePreviewType={filePreviewType}
        viewMode={viewMode}
      >
        {renderPreview()}

        {hasError && <S.Error>Upload failed</S.Error>}
        {!!fileUploadProgress && !hasError ? fileUploadProgressContainer() : null}

        {isPhotoGallery ? (
          <FilePreviewActions
            isCover={isCover}
            isFileSelected={isFileSelected}
            isSelecting={isSelecting}
            isFileUploaded={fileUploaded}
            isLowResolution={isLowResolution}
            isImage={isImage}
            resolution={resolution}
            fileSize={file.size}
            hasError={hasError}
            onDownloadFile={handleDownloadFile}
            onRetryUpload={handleOnRetryUpload}
            onSelectFile={handleSelectFile}
            filePosition={filePosition}
            onRemoveFile={removeFileCb}
            decodedFileName={decodedFileName}
          >
            {!isSelecting && filePreviewActions
              ? fileUploaded &&
                viewMode === FilePreviewMode.grid &&
                filePreviewActions({
                  index,
                  warning: isLowResolution,
                  error: hasError,
                })
              : null}
          </FilePreviewActions>
        ) : (
          <S.ActionWrapper>
            {!hasError ? (
              <Tooltip content="Remove" placement="top">
                <IconClose onClick={removeFileCb} />
              </Tooltip>
            ) : null}
          </S.ActionWrapper>
        )}

        {isImage && isFileUploadedWithoutErrors && openImagePreview && (
          <ImagePreviewModal
            allFiles={allFiles}
            imageIndex={index}
            onCloseImagePreview={() => setOpenImagePreview(false)}
          />
        )}
      </Wrapper>
      {filePreviewActions
        ? viewMode === FilePreviewMode.list &&
          filePreviewActions({
            index,
            warning: isLowResolution,
            error: hasError,
          })
        : null}
    </S.Container>
  )
}

export default FilePreview
