import MaskedInput from 'react-text-mask'
import styled from 'styled-components'

import { IconClear } from '@avantstay/backoffice-vectors'

import { _M_Normal, _S_Medium, _S_Normal, mainColors, semanticColors } from '../../styles'
import { ToggleAmountTypeInputStyleProps } from './ToggleAmountTypeInput.types'

const Root = styled.div<ToggleAmountTypeInputStyleProps>`
  border: 2px solid ${mainColors.neutralMedium};
  border-radius: 2px;
  width: ${props => (props.small ? '200px' : '100%')};
  height: ${props => (props.small ? '36px' : '48px')};
  min-height: ${props => (props.small ? '36px' : '48px')};
  background: ${mainColors.white};
  padding: ${props => (props.small ? '4px' : '9px')};
  display: flex;
  align-items: center;
  gap: 2px;

  ${({ $isTiny }) =>
    $isTiny &&
    `
    width: 100%;
    height: 32px;
    min-height: 32px;
    padding: 2px 10px 2px 2px;
    ${_S_Normal};

    input {
      max-height: 32px;
      padding: 0;
      ${_S_Normal};
    }
  `};

  &:hover {
    border-color: ${mainColors.neutralDark};
  }

  ${props =>
    props.focus &&
    `
    border-color: ${mainColors.accentMedium};

     &:hover {
      border-color: ${mainColors.accentMedium};
    }
  `}

  ${props =>
    props.error &&
    `
    border-color: ${semanticColors.failureMedium};

    &:hover {
      border-color: ${semanticColors.failureMedium};
    }
  `}

  ${props =>
    props.warning &&
    `
    border-color: ${semanticColors.warningDark};

    &:hover {
      border-color: ${semanticColors.warningDark};
    }
  `}
`

const Input = styled(MaskedInput)`
  ${_M_Normal};
  width: 100%;
  padding: 0 12px;
  background: transparent;
  border: none;
  color: ${mainColors.black};
  text-align: right;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${mainColors.neutralDark};
  }
`

const ClearButton = styled(IconClear)`
  min-width: 16px;
  color: ${mainColors.neutralExtraDark};
  cursor: pointer;
`

const TooltipContainer = styled.div`
  height: 16px;
`

const ToggleContainer = styled.div<{ small?: boolean; margin?: number; $isTiny?: boolean }>`
  background: ${mainColors.neutralLight};
  border-radius: 22px;
  min-width: ${props => (props.small ? '64px' : '74px')};
  height: ${props => (props.small ? '28px' : '30px')};
  padding: 3px;
  display: flex;
  justify-content: space-between;
  ${props => props.margin && 'margin-right: 8px'};

  ${({ $isTiny }) =>
    $isTiny &&
    `
    height: 24px;
    min-width: 50px;
    padding: 2px;
  `};
`

interface ItemProps {
  active?: boolean
  small?: boolean
  $isTiny?: boolean
}

const ToggleItem = styled.div<ItemProps>`
  ${_S_Medium};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${mainColors.neutralExtremelyDark};
  border-radius: 20px;
  max-width: ${props => (props.small ? '28px' : '30px')};
  width: ${props => (props.small ? '28px' : '30px')};
  height: ${props => (props.small ? '22px' : '24px')};
  line-height: ${props => (props.small ? '20px' : '22px')};
  padding: 2px 6px;
  cursor: pointer;

  &:hover {
    color: ${mainColors.accentMedium};
  }

  ${({ active }) =>
    active &&
    `
    border: 2px solid ${mainColors.accentMedium};
    color: ${mainColors.accentMedium};
  `}

  ${({ $isTiny, active }) =>
    $isTiny &&
    `
    padding: 0 7px;
    height: 20px;
    width: 24px;
    line-height: 18px;

    ${
      active &&
      `
      color: ${mainColors.white};
      background-color: ${mainColors.accentMedium};
      border: none;

       &:hover {
        color: ${mainColors.white};
       }
    `
    };
  `};
`

export { Root, Input, TooltipContainer, ClearButton, ToggleContainer, ToggleItem }
