import React, { Ref } from 'react'

function _IconValueAddedServices(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6667 10.0013C16.6667 13.6832 13.682 16.668 10.0001 16.668C6.31818 16.668 3.33341 13.6832 3.33341 10.0013C3.33341 6.3194 6.31818 3.33464 10.0001 3.33464C13.682 3.33464 16.6667 6.3194 16.6667 10.0013ZM18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013ZM10.0001 5.83465C10.4603 5.83465 10.8334 6.20774 10.8334 6.66798V9.16797H13.3334C13.7937 9.16797 14.1667 9.54106 14.1667 10.0013C14.1667 10.4615 13.7937 10.8346 13.3334 10.8346H10.8334V13.3346C10.8334 13.7949 10.4603 14.168 10.0001 14.168C9.53984 14.168 9.16675 13.7949 9.16675 13.3346V10.8346H6.66675C6.20651 10.8346 5.83341 10.4615 5.83341 10.0013C5.83341 9.54106 6.20651 9.16797 6.66675 9.16797H9.16675V6.66798C9.16675 6.20774 9.53984 5.83465 10.0001 5.83465Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconValueAddedServices = React.forwardRef(_IconValueAddedServices)
