import { get } from 'lodash'
import React, { useMemo } from 'react'
import { Draggable } from 'react-beautiful-dnd'

import { getNewUuid } from '@avantstay/backoffice-core'

import { IDragAndDropItemProps } from '@new/__global/components/DragAndDropGrid/_types'

export function DragAndDropItem<T>({ item, index, render }: IDragAndDropItemProps<T>) {
  const hash = useMemo(() => getNewUuid(), [])
  const itemId = get(item, 'id')
  return (
    <Draggable draggableId={`file-${hash}-${itemId}`} index={index}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {render(item, index)}
        </div>
      )}
    </Draggable>
  )
}
