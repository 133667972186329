import styled from 'styled-components'

import { rgba } from '@avantstay/avantstay-ui/lib/utils/cssUtils'

import { mainColors } from '../../styles/colors'
import { medias } from '../../styles/medias'
import { _3XS_Medium, _M_Medium, _S_Normal, _XS_Medium } from '../../styles/typography'
import { zIndex } from '../../styles/zIndex'
import { Checkbox } from '../Checkbox'
import { Title } from '../Checkbox/Checkbox.styles'

interface NavControlProps {
  isRightSide?: boolean
  isDisabled?: boolean
}

interface FilterButtonProps {
  isFilterActive?: boolean
  isActive: boolean
  buttonHeight?: number
  buttonWidth?: number
}

interface ActiveFilterIndicatorProps {
  isActive: boolean
  hasActiveFilter?: boolean
}

interface FilterContainerProps {
  isOverflowing?: boolean
}

export const NAV_BUTTON_WIDTH = 34

export const FilterContainer = styled.div<FilterContainerProps>`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  ${medias.LG_SCREEN`
    min-width: 216px;
    max-width: 271px;
    flex-shrink: 0;
    margin-bottom: 0;
  `};

  ${({ isOverflowing }) =>
    isOverflowing &&
    medias.LG_SCREEN`
    position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 32px;
        background: linear-gradient(180deg, ${rgba(mainColors.neutralExtraLight, 0)} 0%, ${
      mainColors.neutralExtraLight
    } 94.14%);
      }
  `};
`

export const FilterHeader = styled.div<{ isOpen?: boolean; isInlineFilter?: boolean }>`
  ${_XS_Medium};
  background-color: ${mainColors.white};
  line-height: 20px;
  user-select: none;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  padding: 14px 0 4px;

  ${medias.LG_SCREEN`
    &:hover {
      background-color: ${mainColors.neutralLight};
    }

    ${_M_Medium};
    min-height: 44px;
    height: max-content;
    padding: 14px 20px 10px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
  `}

  ${({ isOpen }) => isOpen && `padding: 14px 20px 5px;`}

  ${({ isInlineFilter }) =>
    isInlineFilter &&
    `
    display: flex;
    align-items: center;
  `}
`

export const FilterHeaderOptions = styled.div`
  position: absolute;
  right: 20px;
  top: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const FilterOptions = styled.div<{ isOpen?: boolean }>`
  display: none;
  flex-direction: column;
  align-items: flex-start;

  ${({ isOpen }) =>
    isOpen &&
    `
      display: flex;
      padding-bottom: 24px;
      margin-top: 4px;

      ${medias.LG_SCREEN`
        display: block;
        padding: 5px 20px 14px;
        overflow-y: auto;
        margin-top: 0;
      `}
  `}
`

export const IconContainer = styled.div`
  margin-left: 10px;
  display: flex;
  cursor: pointer;
  &:hover {
    & path:first-child {
      fill: ${mainColors.accentMedium};
    }
    & path:nth-child(2) {
      stroke: ${mainColors.accentMedium};
    }
  }
`

export const NavControl = styled.button<NavControlProps>`
  position: absolute;
  top: 0;
  left: ${({ isRightSide }) => !isRightSide && 0};
  right: ${({ isRightSide }) => isRightSide && 0};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${NAV_BUTTON_WIDTH}px;
  height: 100%;
  background-color: ${mainColors.neutralMedium};
  z-index: ${zIndex.DEFAULT};

  svg {
    width: 24px;
    height: 24px;
    color: ${mainColors.neutralExtraDark};
  }

  ${({ isDisabled }) => isDisabled && 'cursor: not-allowed;'}
`

export const CheckboxStyled = styled(Checkbox.Regular)`
  width: 100%;

  ${Title} {
    width: 100%;
  }
`

export const ActiveFilterIndicator = styled.div<ActiveFilterIndicatorProps>`
  ${_3XS_Medium};
  opacity: ${({ hasActiveFilter }) => (hasActiveFilter ? 1 : 0)};

  ${({ isActive }) =>
    isActive
      ? `
    color: ${mainColors.accentMedium};
    background-color: ${mainColors.white};
    border: 2px solid ${mainColors.accentMedium};
  `
      : `
    color: ${mainColors.white};
    background-color: ${mainColors.accentMedium};
    border: 2px solid ${mainColors.white};
  `};
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: -8px;
  right: -8px;
`

export const FilterButton = styled.div<FilterButtonProps>`
  width: ${({ buttonWidth }) => `${buttonWidth || 39}px`};
  height: ${({ buttonHeight }) => `${buttonHeight || 36}px`};
  ${({ isActive }) => !isActive && `border: 2px solid ${mainColors.neutralMedium}`};
  border-radius: 2px;
  background-color: ${({ isActive }) => (isActive ? mainColors.accentMedium : mainColors.white)};
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease-in-out;

  svg {
    ${({ isActive, isFilterActive }) => {
      if (isFilterActive && !isActive) {
        return `color: ${mainColors.accentMedium};`
      }

      return `color: ${isActive ? mainColors.white : mainColors.neutralDark}`
    }};
  }

  &:hover {
    border-color: ${mainColors.neutralDark};
    background-color: ${mainColors.neutralLight};
    ${({ isActive }) =>
      isActive &&
      `
      background-color: ${mainColors.accentDark};
      ${ActiveFilterIndicator} {
        border-color: ${mainColors.accentDark};
      }
    `}
    svg {
      color: ${({ isActive }) => (isActive ? mainColors.white : mainColors.neutralExtraDark)};
    }
  }
`

export const TagRow = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  gap: 10px;
`

export const Label = styled.span<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? mainColors.white : mainColors.neutralExtremelyDark)};
  ${_S_Normal};
`

export const IndicatorContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export const MobileWrapper = styled.div<{ isInlineFilter?: boolean }>`
  > * {
    padding-left: 16px;
    padding-right: 16px;
  }

  > * + * {
    padding-top: 6px;
  }
`
