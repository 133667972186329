import React from 'react'

import * as S from './PhoneVerificationInput.styles'
import { PhoneVerificationInputProps } from './types'

const PhoneVerificationInput = ({
  value,
  hasError = false,
  onChange,
  ...props
}: PhoneVerificationInputProps): JSX.Element => {
  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = event => {
    const nextValue = event.target.value
    const isNextValueValid = /^\d?$/.test(nextValue)

    if (isNextValueValid) {
      onChange(nextValue.length > 0 ? +nextValue : undefined)
    }
  }

  return (
    <S.Input
      {...props}
      autoComplete="off"
      inputMode="numeric"
      value={value == null ? '' : value}
      hasError={hasError}
      onChange={onChangeHandler}
    />
  )
}

export { PhoneVerificationInput }
