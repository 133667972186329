import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconChild(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.2001 5.90039C7.2001 6.5079 6.70761 7.00039 6.1001 7.00039C5.49258 7.00039 5.0001 6.5079 5.0001 5.90039C5.0001 5.29288 5.49258 4.80039 6.1001 4.80039C6.70761 4.80039 7.2001 5.29288 7.2001 5.90039ZM6.7999 8.30112C7.84001 7.99845 8.6001 7.03818 8.6001 5.90039C8.6001 4.51968 7.48081 3.40039 6.1001 3.40039C4.71939 3.40039 3.6001 4.51968 3.6001 5.90039C3.6001 7.03804 4.35999 7.99821 5.3999 8.30101V10.9002C5.3999 12.3922 6.61139 13.6002 8.10173 13.6002H13.5999C13.9865 13.6002 14.2999 13.2868 14.2999 12.9002C14.2999 12.5136 13.9865 12.2002 13.5999 12.2002H8.10173C7.38292 12.2002 6.7999 11.6173 6.7999 10.9002V8.30112Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconChild = forwardRef(_IconChild)
