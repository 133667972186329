// @ts-nocheck
import React, { FC } from 'react'

import { Asset } from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { colorsByExtension } from '@avantstay/backoffice-core'

import { IconAsset } from '@new/__ui/__vectors'
import { FilePreviewType } from '@new/__ui/UploadArea/_types'

import { isImage as checkIsImage } from '@utils/images'

import * as Preview from '../../FilePreview/FilePreview.styles'

interface FilePreviewReadModeProps {
  file: Asset
  filePreviewType?: FilePreviewType
}

export const FilePreviewReadMode: FC<FilePreviewReadModeProps> = ({ file, filePreviewType }) => {
  const extension = ((file.fileName || '').split('.').pop() ?? '').toUpperCase()
  const isImage = checkIsImage(extension)

  return (
    <Preview.FilePreviewContainer
      onClick={() => window.open(file.url)}
      filePreviewType={filePreviewType}
    >
      {isImage ? (
        <Preview.ImagePlaceholder filePreviewType={filePreviewType}>
          <Preview.Image src={file.url} />
        </Preview.ImagePlaceholder>
      ) : (
        <Preview.FileContainer filePreviewType={filePreviewType}>
          <IconAsset />
          <Preview.Extension
            background={colorsByExtension[extension]?.background}
            color={colorsByExtension[extension]?.background}
          >
            {extension}
          </Preview.Extension>
        </Preview.FileContainer>
      )}
    </Preview.FilePreviewContainer>
  )
}
