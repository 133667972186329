import React, { SVGProps } from 'react'

function _IconUsers(props: SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5.83317C5 4.45246 6.11929 3.33317 7.5 3.33317C8.88071 3.33317 10 4.45246 10 5.83317C10 7.21388 8.88071 8.33317 7.5 8.33317C6.11929 8.33317 5 7.21388 5 5.83317ZM7.5 1.6665C5.19881 1.6665 3.33333 3.53198 3.33333 5.83317C3.33333 8.13436 5.19881 9.99984 7.5 9.99984C9.80119 9.99984 11.6667 8.13436 11.6667 5.83317C11.6667 3.53198 9.80119 1.6665 7.5 1.6665ZM4.16667 11.6665C3.0616 11.6665 2.00179 12.1055 1.22039 12.8869C0.438987 13.6683 0 14.7281 0 15.8332V17.4998C0 17.9601 0.373096 18.3332 0.833333 18.3332C1.29357 18.3332 1.66667 17.9601 1.66667 17.4998V15.8332C1.66667 15.1701 1.93006 14.5342 2.3989 14.0654C2.86774 13.5966 3.50363 13.3332 4.16667 13.3332H10.8333C11.4964 13.3332 12.1323 13.5966 12.6011 14.0654C13.0699 14.5342 13.3333 15.1701 13.3333 15.8332V17.4998C13.3333 17.9601 13.7064 18.3332 14.1667 18.3332C14.6269 18.3332 15 17.9601 15 17.4998V15.8332C15 14.7281 14.561 13.6683 13.7796 12.8869C12.9982 12.1055 11.9384 11.6665 10.8333 11.6665H4.16667ZM15.8598 12.3999C15.9749 11.9543 16.4294 11.6863 16.875 11.8014C17.7689 12.0322 18.5608 12.5533 19.1264 13.283C19.692 14.0126 19.9993 14.9095 20 15.8327V17.4999C20 17.9602 19.6269 18.3333 19.1667 18.3333C18.7064 18.3333 18.3333 17.9602 18.3333 17.4999V15.8339C18.3329 15.2801 18.1485 14.7418 17.8092 14.3041C17.4698 13.8663 16.9947 13.5536 16.4583 13.4151C16.0127 13.3001 15.7447 12.8456 15.8598 12.3999ZM13.54 1.80098C13.0942 1.68682 12.6402 1.95572 12.526 2.40157C12.4119 2.84743 12.6808 3.30141 13.1266 3.41557C13.6644 3.55325 14.141 3.866 14.4814 4.30451C14.8218 4.74302 15.0065 5.28233 15.0065 5.83744C15.0065 6.39255 14.8218 6.93186 14.4814 7.37037C14.141 7.80888 13.6644 8.12163 13.1266 8.25932C12.6808 8.37347 12.4119 8.82745 12.526 9.27331C12.6402 9.71916 13.0942 9.98806 13.54 9.8739C14.4363 9.64442 15.2307 9.12317 15.798 8.39233C16.3653 7.66148 16.6732 6.76262 16.6732 5.83744C16.6732 4.91226 16.3653 4.0134 15.798 3.28256C15.2307 2.55171 14.4363 2.03046 13.54 1.80098Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconUsers = React.forwardRef(_IconUsers)
