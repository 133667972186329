import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconLeadUser(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.75C4.54822 1.75 1.75 4.54822 1.75 8C1.75 9.5754 2.33288 11.0147 3.29474 12.1139C4.12112 10.9939 5.32022 10.2073 6.66893 9.89207C5.36784 9.36548 4.45001 8.08995 4.45001 6.60005C4.45001 4.63944 6.0394 3.05005 8.00001 3.05005C9.96062 3.05005 11.55 4.63944 11.55 6.60005C11.55 8.08995 10.6322 9.36548 9.3311 9.89207C10.6798 10.2073 11.8789 10.9939 12.7053 12.1139C13.6671 11.0146 14.25 9.57539 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75ZM11.5821 13.1222C10.7786 11.9537 9.44366 11.2388 8.00002 11.2388C6.55638 11.2388 5.22145 11.9537 4.41787 13.1223C5.43224 13.833 6.66742 14.25 8 14.25C9.33259 14.25 10.5678 13.8329 11.5821 13.1222ZM0.25 8C0.25 3.71979 3.71979 0.25 8 0.25C12.2802 0.25 15.75 3.71979 15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8ZM8.00001 4.55005C6.86783 4.55005 5.95001 5.46786 5.95001 6.60005C5.95001 7.73223 6.86783 8.65005 8.00001 8.65005C9.1322 8.65005 10.05 7.73223 10.05 6.60005C10.05 5.46786 9.1322 4.55005 8.00001 4.55005Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconLeadUser = forwardRef(_IconLeadUser)
