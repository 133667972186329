import React, { Ref } from 'react'

function _IconBlockEmailAddress(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 12C18.8928 12 19.7172 11.7075 20.3828 11.2131C20.3941 11.2042 20.4057 11.1956 20.4174 11.1871C21.3791 10.4566 22 9.30081 22 8C22 7.46019 21.8931 6.94535 21.6992 6.47551L15.5015 11.1238C16.186 11.6721 17.0547 12 18 12ZM14.3007 9.52439C14.1069 9.05458 14 8.53978 14 8C14 7.10723 14.2925 6.28277 14.7868 5.61722C14.7958 5.60586 14.8045 5.5943 14.8129 5.58256C15.5434 4.62093 16.6992 4 18 4C18.9452 4 19.8139 4.32787 20.4985 4.87609L14.3007 9.52439ZM18 14C18.7013 14 19.3744 13.8797 20 13.6586V19C20 19.5477 19.5477 20 19 20H3C2.45228 20 2 19.5477 2 19V8.92062L10.4266 14.8193C10.8791 15.136 11.5026 15.0259 11.8193 14.5735C12.136 14.121 12.026 13.4975 11.5735 13.1808L2.10659 6.55392C2.27198 6.22691 2.61205 6 3 6H12.3414C12.1203 6.62556 12 7.29873 12 8C12 11.3137 14.6863 14 18 14ZM18 2C16.223 2 14.6264 2.7725 13.5278 4H3C1.34772 4 0 5.34772 0 7V19C0 20.6523 1.34772 22 3 22H19C20.6523 22 22 20.6523 22 19V12.4722C23.2275 11.3736 24 9.777 24 8C24 4.68629 21.3137 2 18 2Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconBlockEmailAddress = React.forwardRef(_IconBlockEmailAddress)
