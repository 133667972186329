import memoize from 'fast-memoize'

import arriereBackoffice, {
  CreateHomeItemArgs,
  HomeItemPatch,
  Permission,
  UUID,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { Translations } from '@new/domains/settings/screens/GlobalWorkOrderTemplates/GlobalWorkOrderTemplates.types'

import AuthContext from '@contexts/Auth/AuthContext'

const HOME_ITEM_SELECTION = {
  id: true,
  enabled: true,
  position: true,
  titleTranslations: {
    en: true,
    es: true,
  },
  instructionsTranslations: {
    en: true,
    es: true,
  },
  requiresExecutionPhoto: true,
  referencePhotoId: true,
  referencePhoto: {
    id: true,
    url: true,
  },
  kind: true,
  wantedResponse: true,
  globalItem: {
    id: true,
    locked: true,
    enabled: true,
  },
  answerType: true,
  answerOptions: {
    optionId: true,
    label: {
      en: true,
      es: true,
    },
    expected: true,
  },
  caseCreationType: true,
}

const HOME_SECTION_SELECTION = {
  id: true,
  globalSection: { id: true, locked: true, enabled: true, repeatBy: true },
  repetitionIndex: true,
  titleTranslations: {
    en: true,
    es: true,
  },
  enabled: true,
  position: true,
  items: HOME_ITEM_SELECTION,
}

const GLOBAL_ITEM_SELECTION = {
  id: true,
  titleTranslations: {
    en: true,
    es: true,
  },
  instructionsTranslations: {
    en: true,
    es: true,
  },
  requiresExecutionPhoto: true,
  enabled: true,
  locked: true,
  referencePhotoCandidates: true,
  kind: true,
  wantedResponse: true,
  position: true,
  answerType: true,
  answerOptions: {
    optionId: true,
    label: {
      en: true,
      es: true,
    },
    expected: true,
  },
  caseCreationType: true,
  targetAmenities: true,
  targetRegions: true,
}

export const getHomeTemplate = (homeId: UUID, templateId: UUID) =>
  arriereBackoffice.queries.homeTemplate({
    __args: {
      homeId,
      templateId,
    },
    ...HOME_SECTION_SELECTION,
  })

export const getListOfAllTemplates = memoize(() =>
  arriereBackoffice.queries
    .templates({
      title: true,
      id: true,
      type: true,
      propertyType: true,
    })
    .then(templates => templates.sort((a, b) => (a.title > b.title ? 1 : -1))),
)

export const getGlobalItem = (itemId: string) =>
  arriereBackoffice.queries.globalItem({
    __args: {
      itemId,
    },
    ...GLOBAL_ITEM_SELECTION,
  })

export const getHomeItem = (homeId: UUID, itemId: UUID, templateId: UUID) =>
  arriereBackoffice.queries.homeItem({
    __args: {
      homeId,
      itemId,
      templateId,
    },
    ...HOME_ITEM_SELECTION,
  })

export const createHomeSection = (
  homeId: UUID,
  templateId: UUID,
  title: Translations,
  position: number,
) =>
  arriereBackoffice.mutations.createHomeSection({
    __args: {
      homeId,
      templateId,
      titleTranslations: title,
      position,
    },
    ...HOME_SECTION_SELECTION,
  })

export const patchHomeItem = (
  homeId: UUID,
  sectionId: string,
  itemId: UUID,
  patch: HomeItemPatch,
) =>
  arriereBackoffice.mutations.patchHomeItem({
    __args: {
      homeId,
      sectionId,
      id: itemId,
      patch,
    },
    ...HOME_ITEM_SELECTION,
  })

export const createHomeItem = (__args: CreateHomeItemArgs) =>
  arriereBackoffice.mutations.createHomeItem({
    __args,
    ...HOME_ITEM_SELECTION,
  })

export const loadTemplateSections = (templateId: UUID) => {
  return arriereBackoffice.queries.globalTemplate({
    __args: {
      templateId,
    },
    id: true,
    titleTranslations: {
      en: true,
    },
  })
}

export const loadUserPermissions = () =>
  arriereBackoffice.queries.userPermissions({
    __args: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      userEmail: AuthContext._currentValue.user.email,
    },
  })

export const loadPermissions = () =>
  arriereBackoffice.queries.permissions({
    email: true,
    name: true,
    permissions: {
      permission: true,
      createdAt: true,
      createdBy: true,
    },
  })

export type UserPermissions = Awaited<ReturnType<typeof loadPermissions>>[0]

export const deleteUserPermissions = (email: string) =>
  arriereBackoffice.mutations.deleteUserPermissions({
    __args: {
      email,
    },
  })

export const createUserPermissions = (email: string, permissions: Permission[]) =>
  arriereBackoffice.mutations.createUserPermissions({
    __args: {
      email,
      permissions,
    },
    permissions: {
      permission: true,
    },
  })

export const patchUserPermissions = (email: string, permissions: Permission[]) =>
  arriereBackoffice.mutations.patchUserPermissions({
    __args: {
      email,
      permissions,
    },
    permissions: {
      permission: true,
    },
  })
