import React, { Ref } from 'react'

function _ConfigurationStatusError(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        fill="#F5A3BC"
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8 7.99922C12.8 7.55739 12.4418 7.19922 12 7.19922C11.5581 7.19922 11.2 7.55739 11.2 7.99922V11.9992C11.2 12.441 11.5581 12.7992 12 12.7992C12.4418 12.7992 12.8 12.441 12.8 11.9992V7.99922ZM12.8 15.9992C12.8 16.441 12.4418 16.7992 12 16.7992C11.5581 16.7992 11.2 16.441 11.2 15.9992C11.2 15.5574 11.5581 15.1992 12 15.1992C12.4418 15.1992 12.8 15.5574 12.8 15.9992Z"
        fill="#EC4678"
      />
    </svg>
  )
}

export const ConfigurationStatusError = React.forwardRef(_ConfigurationStatusError)
