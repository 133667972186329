import React, { Ref } from 'react'

function _IconFeaturedOn(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.44853 1.11218C8.3643 0.941532 8.19049 0.833496 8.00018 0.833496C7.80987 0.833496 7.63606 0.941532 7.55182 1.11218L5.60805 5.05006L1.2612 5.68542C1.07292 5.71294 0.916576 5.84494 0.857888 6.02595C0.799199 6.20695 0.848335 6.40558 0.984644 6.53834L4.12958 9.60151L3.38737 13.929C3.3552 14.1166 3.43232 14.3062 3.58631 14.418C3.7403 14.5299 3.94445 14.5446 4.1129 14.456L8.00018 12.4118L11.8875 14.456C12.0559 14.5446 12.2601 14.5299 12.414 14.418C12.568 14.3062 12.6452 14.1166 12.613 13.929L11.8708 9.60151L15.0157 6.53834C15.152 6.40558 15.2012 6.20695 15.1425 6.02595C15.0838 5.84494 14.9274 5.71294 14.7392 5.68542L10.3923 5.05006L8.44853 1.11218Z"
        fill="#FFBD5A"
      />
    </svg>
  )
}

export const IconFeaturedOn = React.forwardRef(_IconFeaturedOn)
