import { format, parseISO } from 'date-fns'
import memoize from 'fast-memoize'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import prettyBytes from 'pretty-bytes'
import slugify from 'slugify'

import { IDate } from '@avantstay/arriere-clients/dist/arriereBackoffice'
import {
  shortFormatCurrency,
  shortFormatNumber,
  shortFormatPercentage,
} from '@avantstay/auto-format-number'
import { formatDateRange } from '@avantstay/format-date-range'

export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1)

export const capitalizeAll = (string: string) => string.split(' ').map(capitalize).join(' ')

export const capitalizeEachFirstLetter = (string: string) =>
  string
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word[0].toUpperCase() + word.substring(1)
    })
    .join(' ')

export const formatMoney = (value: any, hideDecimals = false) => {
  if (value === undefined || value === null) return

  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: hideDecimals ? 0 : undefined,
    minimumFractionDigits: hideDecimals ? 0 : undefined,
  })
}

export const formatMoneyCents = (value?: number, onlyInteger = false) => {
  if (value === undefined) return
  return formatMoney(value / 100, onlyInteger)
}

export const formatIntegerMoney = (value: any) => formatMoney(value, true)

export const formatFileSize = (value: number) => prettyBytes(value)

export const shortFormatMoney = memoize((value: any) =>
  (value && value !== -1) || value === 0 ? shortFormatCurrency(value) : '-',
)

export const formatNumber = memoize((value: any) => {
  return (value && value !== -1) || value === 0 ? shortFormatNumber(value) : '-'
})

export const formatPercentage = (value: any, showZero = false, forceSign?: boolean) => {
  if (value === 0 && showZero) return '0%'

  if (!value && value !== 0) return '-'

  return `${forceSign && value > 0 ? '+' : ''}${shortFormatPercentage(value)}`
}

export const formatPercentageReviewOverviews = memoize((value: number, showZero = false) => {
  if (value === 0 && showZero) return '0%'

  if (!value && value !== 0) return '-'

  const formattedValue = Number(value).toLocaleString()
  return `${formattedValue}%`
})

export const formatSignaledPercentage = memoize((value: any) => {
  if (!value && value !== 0) return '-'

  if (value === 0) return shortFormatPercentage(value)

  return `${value > 0 ? '+' : ''}${shortFormatPercentage(value)}`
})

// parseISODate used to migrate date-fns from v1 to v2
export const parseISODate = (date: Date | string | IDate | number) => {
  if (typeof date === 'string') {
    const dateWithTimeAndZone = date.match(
      /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,6})?Z?)$/,
    )
    const dateWithTime = date.match(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})$/)

    if (dateWithTimeAndZone || dateWithTime) {
      return parseISO(date)
    }

    const newDate = new Date(date)
    // We use UTC here since expected date doesn't have specific time and would provide local time
    return new Date(
      newDate.getUTCFullYear(),
      newDate.getUTCMonth(),
      newDate.getUTCDate(),
      newDate.getUTCHours(),
      newDate.getUTCMinutes(),
      newDate.getUTCSeconds(),
      newDate.getUTCMilliseconds(),
    )
  }

  return new Date(date)
}

export const formatDate = (date: any, mask = 'yyyy-MM-dd') => {
  const dateValue = parseISODate(date)

  return format(dateValue, mask)
}

export const numberWithCommas = (number: string | number) => {
  return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatDateRangeMonthly = (start: Date | string, end: Date | string) => {
  const startDate = parseISODate(start)
  const endDate = parseISODate(end)

  return formatDateRange(startDate, endDate, 'en-US', {
    formats: {
      defaultStart: 'MMM, YYYY',
      defaultEnd: 'MMM, YYYY',
      sameMonthStart: 'MMM',
      sameMonthEnd: ' YYYY',
      sameYearStart: 'MMM ',
      sameYearEnd: 'MMM, YYYY',
    },
  })
}

export const formatPhone = (phone: string) => {
  const formatted = parsePhoneNumberFromString(phone)
  return formatted ? formatted.formatInternational() : phone
}

export const formatPhoneUS = (phone: string) => {
  const formatted = parsePhoneNumberFromString(phone)
  return formatted ? formatted.formatNational() : phone
}

export const formatPhoneString = (phone?: string) => {
  if (!phone) {
    return
  }
  return phone.startsWith('+') ? phone : `+${phone}`
}

export const formatAsSlug = (text: string) => slugify(text)

export const padTo2Digits = (num: number) => num.toString().padStart(2, '0')

export const convertMsToHM = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)

  const formattedSeconds = seconds % 60
  const adjustedMinutes = formattedSeconds >= 30 ? minutes + 1 : minutes
  const formattedMinutes = adjustedMinutes % 60
  const formattedHours = hours % 24

  return `${formattedHours}h ${padTo2Digits(formattedMinutes)}min`
}

export const formatShorterNumber = (n: number, forceDecimal?: boolean) => {
  const num = n.toString()
  return num.indexOf('.') !== -1
    ? num.slice(0, num.indexOf('.') + 2)
    : forceDecimal
    ? `${num}.0`
    : num
}

export const formatDateRangeExtraOptions = {
  formats: {
    defaultStart: 'MMM D, YY',
    defaultEnd: 'MMM D, YY',
    sameMonthStart: 'MMM D',
    sameMonthEnd: 'D, YY',
    sameYearStart: 'MMM D',
    sameYearEnd: 'MMM D, YY',
  },
}

export const formatAbbreviatedMoney = (value?: number) => {
  if (!value) return undefined
  const stringNumber = String(value)
  const withoutCents = stringNumber.substring(0, stringNumber.length - 2)
  return shortFormatCurrency(Number(withoutCents))
}

export const combineDateAndTime = (date: string, time: string) => {
  return `${date.split('T')[0]}T${time}`
}
