import { useEffect, useRef, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'

import { MONTH_NAMES, NOW_MONTH, NOW_YEAR } from '../../configuration'
import * as S from './MonthPicker.styles'
import { MonthPickerProps } from './MonthPicker.types'

const shortMonthNames = MONTH_NAMES.map(month => month.substr(0, 3))

const getMinMaxFromRange = (yearRange: number[]) => ({
  min: Math.min(...yearRange),
  max: Math.max(...yearRange),
})

const MonthPicker = ({
  children,
  month,
  onClickOut = undefined,
  open,
  year,
  yearRange,
  disableBeforeToday = false,
  onChange,
  verticalAlignment,
}: MonthPickerProps): JSX.Element => {
  const [activeYear, setActiveYear] = useState(year)
  const [containerPosition, setContainerPosition] = useState<number | undefined>(undefined)
  const minMaxYear = useRef(yearRange ? getMinMaxFromRange(yearRange) : null)

  useEffect(() => {
    if (yearRange) {
      minMaxYear.current = getMinMaxFromRange(yearRange)
    }
  }, [yearRange])

  const handlePrevMonth = () => {
    if (minMaxYear?.current?.min) {
      setActiveYear(Math.max(minMaxYear.current.min, activeYear - 1))
    } else {
      setActiveYear(prevState => prevState - 1)
    }
  }

  const handleNextMonth = () => {
    if (minMaxYear?.current?.max) {
      setActiveYear(Math.min(minMaxYear.current.max, activeYear + 1))
    } else {
      setActiveYear(prevState => prevState + 1)
    }
  }

  const handleMonthClick = (selectedMonth: number) => {
    onChange({
      month: selectedMonth,
      year: activeYear,
    })
  }

  return (
    <div
      ref={el => {
        if (!el) return
        setContainerPosition(el.getBoundingClientRect().y)
      }}
    >
      {children}
      <S.FloatingContainerStyled
        show={open}
        onClickOut={onClickOut}
        horizontalAlignment="right"
        verticalAlignment={verticalAlignment}
        verticalValue={containerPosition}
      >
        <S.Picker>
          <S.YearRow>
            <S.ChevronLink
              onClick={handlePrevMonth}
              disabled={minMaxYear?.current?.min ? activeYear === minMaxYear.current.min : false}
            >
              <ChevronLeft width={20} />
            </S.ChevronLink>
            {activeYear}
            <S.ChevronLink
              onClick={handleNextMonth}
              disabled={minMaxYear?.current?.max ? activeYear === minMaxYear.current.max : false}
            >
              <ChevronRight width={20} />
            </S.ChevronLink>
          </S.YearRow>
          <S.MonthsGrid>
            {shortMonthNames.map((monthName, index) => {
              const monthNumber = index + 1
              const monthFromPastYears = activeYear === NOW_YEAR && monthNumber < NOW_MONTH

              const disabledMonth = disableBeforeToday
                ? monthFromPastYears || activeYear < NOW_YEAR
                : false

              return (
                <S.MonthLink
                  key={monthName}
                  onClick={() => handleMonthClick(monthNumber)}
                  active={monthNumber === month && activeYear === year}
                  current={monthNumber === NOW_MONTH && activeYear === NOW_YEAR}
                  disabled={disabledMonth}
                >
                  {monthName}
                </S.MonthLink>
              )
            })}
          </S.MonthsGrid>
        </S.Picker>
      </S.FloatingContainerStyled>
    </div>
  )
}

export { MonthPicker }
