import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconTurnPhone = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg width="114" height="114" viewBox="0 0 114 114" fill="none" {...props} ref={ref}>
      <rect x="1" y="1" width="68" height="112" rx="2" stroke="#E9EEF1" strokeWidth="2" />
      <rect x="1" y="94" width="68" height="19" fill="#E9EEF1" />
      <rect
        x="113"
        y="45"
        width="68"
        height="112"
        rx="2"
        transform="rotate(90 113 45)"
        stroke="#C0C9CE"
        strokeWidth="2"
      />
      <rect x="20" y="45" width="68" height="19" transform="rotate(90 20 45)" fill="#C0C9CE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81 16C80.4477 16 80 16.4477 80 17C80 17.5523 80.4477 18 81 18H88C89.6569 18 91 19.3431 91 21V30.5858L87.7071 27.2929C87.3166 26.9024 86.6834 26.9024 86.2929 27.2929C85.9024 27.6834 85.9024 28.3166 86.2929 28.7071L91.2929 33.7071C91.6834 34.0976 92.3166 34.0976 92.7071 33.7071L97.7071 28.7071C98.0976 28.3166 98.0976 27.6834 97.7071 27.2929C97.3166 26.9024 96.6834 26.9024 96.2929 27.2929L93 30.5858V21C93 18.2386 90.7614 16 88 16H81Z"
        fill="#94A4AD"
      />
    </svg>
  )
}

export const IconTurnPhone = forwardRef(_IconTurnPhone)
