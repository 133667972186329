import React, { Ref } from 'react'

const _IconDots = (props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="4"
      viewBox="0 0 20 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.95461 1.99998C3.95461 2.95392 3.18127 3.72725 2.2273 3.72725C1.27334 3.72725 0.5 2.95392 0.5 1.99998C0.5 1.04603 1.27334 0.272705 2.2273 0.272705C3.18127 0.272705 3.95461 1.04603 3.95461 1.99998ZM11.7272 1.99998C11.7272 2.95392 10.9538 3.72725 9.99986 3.72725C9.0459 3.72725 8.27256 2.95392 8.27256 1.99998C8.27256 1.04603 9.0459 0.272705 9.99986 0.272705C10.9538 0.272705 11.7272 1.04603 11.7272 1.99998ZM17.7724 3.72725C18.7264 3.72725 19.4997 2.95392 19.4997 1.99998C19.4997 1.04603 18.7264 0.272705 17.7724 0.272705C16.8185 0.272705 16.0451 1.04603 16.0451 1.99998C16.0451 2.95392 16.8185 3.72725 17.7724 3.72725Z"
        fill="white"
      />
    </svg>
  )
}

export const IconDots = React.forwardRef(_IconDots)
