import arriereBackoffice, { LeadSource } from '@avantstay/arriere-clients/dist/arriereBackoffice'

export default {
  async getAirbnbLeadSourceAccounts() {
    return arriereBackoffice.queries.leadSourceAccounts
      .raw({
        __args: { source: LeadSource.airBnb },
        name: true,
        active: true,
        accountId: true,
      })
      .then(({ data, errors }) => {
        if (errors) {
          throw Error(errors[0]?.message)
        }

        return data
      })
  },

  async getAirbnbSuperhostIndicators(leadSourceAccount: string) {
    return arriereBackoffice.queries
      .globalIndicators({
        __args: {},
        customerExperience: {
          airbnbSuperhostRating: {
            __args: { leadSourceAccount },
          },
          airbnbSuperhostPeriod: {
            start: true,
            end: true,
          },
          airbnbFiveStarReviewsToSuperhost: {
            __args: { leadSourceAccount },
          },
        },
      })
      .then(response => {
        return response.customerExperience
      })
  },
}
