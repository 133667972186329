import React from 'react'

function _IconChevronLeft(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4243 12.4244C10.6586 12.19 10.6586 11.8101 10.4243 11.5758L6.84856 8.0001L10.4243 4.42436C10.6586 4.19005 10.6586 3.81015 10.4243 3.57583C10.19 3.34152 9.81009 3.34152 9.57577 3.57583L5.57577 7.57583C5.34146 7.81015 5.34146 8.19005 5.57577 8.42436L9.57577 12.4244C9.81009 12.6587 10.19 12.6587 10.4243 12.4244Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconChevronLeft = React.memo(React.forwardRef(_IconChevronLeft))

export { IconChevronLeft }
