import React, { Ref } from 'react'

function _IconMoveUp(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.999902 2.39941C0.668532 2.39941 0.399902 2.66804 0.399902 2.99941C0.399902 3.33078 0.668532 3.59941 0.999902 3.59941L14.9999 3.59941C15.3313 3.59941 15.5999 3.33078 15.5999 2.99941C15.5999 2.66804 15.3313 2.39941 14.9999 2.39941L0.999902 2.39941ZM4.24214 8.75737C4.00783 8.52306 4.00783 8.14316 4.24214 7.90884L7.57498 4.576L7.57548 4.57551C7.60476 4.54622 7.63633 4.52059 7.6696 4.49862C7.76433 4.43604 7.87786 4.39961 7.9999 4.39961C8.17159 4.39961 8.32644 4.47172 8.4358 4.58731L11.7573 7.90884C11.9917 8.14316 11.9917 8.52306 11.7573 8.75737C11.523 8.99168 11.1431 8.99168 10.9088 8.75737L8.5999 6.44846V12.9996C8.5999 13.331 8.33127 13.5996 7.9999 13.5996C7.66853 13.5996 7.3999 13.331 7.3999 12.9996V6.44814L5.09067 8.75737C4.85636 8.99168 4.47646 8.99168 4.24214 8.75737Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconMoveUp = React.forwardRef(_IconMoveUp)
