// @ts-nocheck
import isBoolean from 'lodash/isBoolean'
import React, { ChangeEvent } from 'react'

import { mainColors, semanticColors } from '@avantstay/backoffice-core'

import { ToggleField } from '@new/__ui/ToggleField/ToggleField'
import { ToggleBooleanType } from '@new/__ui/ToggleField/ToggleField.types'

// eslint-disable-next-line import/no-cycle
import { jsonParseCustomFieldValue } from '@utils/customFields'

import { CustomFieldProps, CustomFieldTypeReturn, IMountComponentProps } from '../_types'
import * as S from './types.styles'

const getParseLegacyValue = (legacyValue: string) => {
  const lowerCaseLegacyValue = legacyValue.toLowerCase()
  if (lowerCaseLegacyValue === 'y' || lowerCaseLegacyValue === 'yes') return true
  if (lowerCaseLegacyValue === 'n' || lowerCaseLegacyValue === 'no') return false
  return legacyValue
}

const parseValueToShow = (customField: CustomFieldProps) => {
  const value = jsonParseCustomFieldValue(customField)

  return typeof value === 'string' ? getParseLegacyValue(value) : value
}

const parseValueToSave = (customField: CustomFieldProps) => {
  const customFieldValue = jsonParseCustomFieldValue(customField)

  const fieldValue =
    typeof customFieldValue === 'string' ? getParseLegacyValue(customFieldValue) : customFieldValue

  if (isBoolean(fieldValue)) {
    return `${fieldValue}`
  }

  return JSON.stringify(fieldValue)
}

function ComponentReadMode({ customField }: IMountComponentProps) {
  const value = parseValueToShow(customField)

  const bg = value ? mainColors.accentExtremelyLight : semanticColors.failureExtraLight
  const color = value ? mainColors.accentMedium : semanticColors.failureMedium

  return (
    <S.BooleanTag bg={bg} color={color}>
      {value ? 'Yes' : 'No'}
    </S.BooleanTag>
  )
}

function ComponentWriteMode({
  customField,
  inputProps,
  isReadOnly,
  disabled,
}: IMountComponentProps) {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    inputProps?.onChange({
      target: { value: e.target.value },
    })
  }

  if (isReadOnly) return <ComponentReadMode customField={customField} />

  const isString = typeof inputProps?.value === 'string'

  return (
    <ToggleField>
      <ToggleField.Option
        label={customField.id}
        onChange={handleOnChange}
        value={true}
        defaultChecked={inputProps?.value === (isString ? 'true' : true)}
        id={`${customField.id}-yes`}
        title="Yes"
        disabled={disabled}
      />
      <ToggleField.Option
        label={customField.id}
        onChange={handleOnChange}
        value={false}
        defaultChecked={inputProps?.value === (isString ? 'false' : false)}
        id={`${customField.id}-no`}
        toggleType={ToggleBooleanType.false}
        title="No"
        disabled={disabled}
      />
    </ToggleField>
  )
}

export default {
  read: { Component: ComponentReadMode },
  write: { Component: ComponentWriteMode },
  parseValueToShow,
  parseValueToSave,
} as CustomFieldTypeReturn
