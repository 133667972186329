import { ReactNode } from 'react'

export enum CollapseTheme {
  default = 'DEFAULT',
  collapseList = 'COLLAPSE_LIST',
  collapseListSmall = 'COLLAPSE_LIST_SMALL',
}

export interface CollapseProps {
  className?: string
  title: ReactNode
  children: ReactNode
  hasBorder?: boolean
  hasChevronAtStart?: boolean
  isOpen?: boolean
  titleContent?: ReactNode
  onClick?: VoidFunction
  titleJustifyContent?: string
  iconSize?: number
  theme?: CollapseTheme
}
