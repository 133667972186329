import React, { Ref } from 'react'

function _IconHide(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.3125 4.12256C5.3125 3.70835 4.97671 3.37256 4.5625 3.37256C4.14829 3.37256 3.8125 3.70835 3.8125 4.12256V19.8726C3.8125 20.2868 4.14829 20.6226 4.5625 20.6226C4.97671 20.6226 5.3125 20.2868 5.3125 19.8726V4.12256ZM10.2803 8.65473C10.5732 8.94762 10.5732 9.4225 10.2803 9.71539L8.74816 11.2476H19.4375C19.8517 11.2476 20.1875 11.5833 20.1875 11.9976C20.1875 12.4118 19.8517 12.7476 19.4375 12.7476H8.74816L10.2803 14.2797C10.5732 14.5726 10.5732 15.0475 10.2803 15.3404C9.98744 15.6333 9.51256 15.6333 9.21967 15.3404L6.40747 12.5282C6.27157 12.3924 6.1875 12.2048 6.1875 11.9976C6.1875 11.7946 6.26815 11.6104 6.39917 11.4754L6.40717 11.4672L9.21967 8.65473C9.51256 8.36184 9.98744 8.36184 10.2803 8.65473Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconHide = React.forwardRef(_IconHide)
