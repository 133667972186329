import React, { CSSProperties, memo, useMemo } from 'react'

import { mainColors, riskChannelsColors, semanticColors } from '../../styles/colors'
import * as S from './UserAvatar.styles'

const DEFAULT_COLORS = [
  riskChannelsColors.channelFrontdesk,
  riskChannelsColors.channelPersona,
  mainColors.accentMedium,
  riskChannelsColors.channelWebsite,
  semanticColors.successMedium,
  semanticColors.warningMedium,
  riskChannelsColors.channelExpedia,
]

interface IUserAvatarProps {
  alt?: string
  className?: string
  colors?: string[]
  isActive?: boolean
  src?: string
  size: number
  solid?: boolean
  style?: CSSProperties
  customInitials?: string
}

function _UserAvatar({
  alt,
  className,
  colors = DEFAULT_COLORS,
  isActive,
  size,
  src,
  style,
  customInitials,
  ...otherProps
}: IUserAvatarProps) {
  const initials = useMemo(() => {
    return alt
      ? alt
          .split(/\s+/)
          .slice(0, 2)
          .map(it => it && it[0].toUpperCase())
          .join('')
      : ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const altColor = useMemo(() => {
    if (src || !alt || !isActive) {
      return mainColors.neutralDark
    }

    const cleanName = alt.toLowerCase().replace(/[^a-z]/g, '')
    const colorIndex = parseInt(cleanName, 36) % colors.length
    const newBackgroundColor = colors[colorIndex]

    return newBackgroundColor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const _style = useMemo(() => {
    return {
      ...style,
      width: size,
      height: size,
      background: src ? `url('${src}')` : altColor,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      opacity: src && !isActive ? 0.7 : 1,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.AvatarContainer className={className} style={_style} {...(otherProps as any)}>
      {!src && (
        <S.Initials isActive={isActive} size={size}>
          {customInitials || initials}
        </S.Initials>
      )}
    </S.AvatarContainer>
  )
}

export const UserAvatar = memo(_UserAvatar)
