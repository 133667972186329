import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconDelete(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        d="M2 4H3.33333H14"
        stroke="#EC4678"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6668 3.99992V13.3333C12.6668 13.6869 12.5264 14.026 12.2763 14.2761C12.0263 14.5261 11.6871 14.6666 11.3335 14.6666H4.66683C4.31321 14.6666 3.97407 14.5261 3.72402 14.2761C3.47397 14.026 3.3335 13.6869 3.3335 13.3333V3.99992M5.3335 3.99992V2.66659C5.3335 2.31296 5.47397 1.97382 5.72402 1.72378C5.97407 1.47373 6.31321 1.33325 6.66683 1.33325H9.3335C9.68712 1.33325 10.0263 1.47373 10.2763 1.72378C10.5264 1.97382 10.6668 2.31296 10.6668 2.66659V3.99992"
        stroke="#EC4678"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6665 7.33325V11.3333"
        stroke="#EC4678"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3335 7.33325V11.3333"
        stroke="#EC4678"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconDelete = forwardRef(_IconDelete)
