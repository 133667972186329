import arriereBackoffice, {
  DateRangeInput,
  SortOrder,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { Alert } from '@avantstay/backoffice-core'

import {
  CreateVendorMutation,
  UpdateVendorMutation,
} from '@new/domains/operations/screens/Vendors/components/CreateVendor/CreateVendor.types'
import { VendorSorting } from '@new/domains/operations/screens/Vendors/Vendors.types'

interface GetVendorsParameters {
  activityStatus?: boolean
  regionIds?: string[]
  term?: string
  vendorTypeLabels?: string[]
  pagination: {
    page: number
    size: number
  }
  sortBy?: VendorSorting
  sortOrder?: SortOrder
  availabilityRange: DateRangeInput
  isRecurringStatus?: boolean
}

export const getVendors = async (params: GetVendorsParameters) => {
  const { availabilityRange, ...searchParams } = params
  const response = await arriereBackoffice.queries.vendors({
    __alias: 'searchVendors',
    searchVendors: {
      __args: searchParams,
      pages: true,
      total: true,
      results: {
        active: true,
        email: true,
        id: true,
        name: true,
        notes: true,
        phone: true,
        isHousekeepingType: true,
        startDate: true,
        vendorHomesInRegionsStats: { regionId: true, regionName: true },
        vendorTypes: true,
        hasAssignedCases: true,
        isRecurring: true,
        hourlyRate: true,
        vendorServicesHomesCount: {
          __args: {
            regionIds: searchParams.regionIds ?? [],
          },
        },
        availabilities: {
          __args: {
            availabilityRange,
          },
          date: true,
          times: {
            startTime: true,
            endTime: true,
          },
          kind: true,
        },
      },
    },
  })

  return response.searchVendors
}

export const getVendorTypeLabels = async (): Promise<string[]> => {
  const response = await arriereBackoffice.queries.vendors({
    __alias: 'getAllVendorTypeLabels',
    getAllVendorTypeLabels: true,
  })

  return response.getAllVendorTypeLabels
}

export const createVendor = (body: CreateVendorMutation) => {
  return arriereBackoffice.mutations
    .vendors({
      __alias: 'createVendor',
      createVendor: {
        __args: { ...(body as CreateVendorMutation) },
      },
    })
    .then(results => {
      Alert.Success(`Vendor successfully created.`, {})
      return !!results.createVendor
    })
    .catch(error => {
      Alert.Error((error as Error).message)
      return false
    })
}

export const updateVendor = (body: UpdateVendorMutation) => {
  return arriereBackoffice.mutations
    .vendors({
      __alias: 'updateVendor',
      updateVendor: {
        __args: { ...(body as UpdateVendorMutation) },
      },
    })
    .then(results => {
      Alert.Success(`Vendor successfully updated.`, {})
      return !!results.updateVendor
    })
    .catch(error => {
      Alert.Error((error as Error).message)
      return false
    })
}
