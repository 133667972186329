import { semanticColors } from '../../../../styles'

export const colorsByExtension: Record<string, { background: string }> = {
  PDF: {
    background: semanticColors.failureMedium,
  },
  XLS: {
    background: semanticColors.successMedium,
  },
  XLSX: {
    background: semanticColors.successMedium,
  },
  TXT: {
    background: semanticColors.failureMedium,
  },
}
