import React, { Ref } from 'react'

function _IconChevronFilledUp(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.61589 0.460932C4.81579 0.221055 5.18421 0.221056 5.38411 0.460933L9.31659 5.17991C9.58798 5.50557 9.3564 6 8.93248 6H1.06752C0.643602 6 0.412024 5.50557 0.68341 5.17991L4.61589 0.460932Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconChevronFilledUp = React.forwardRef(_IconChevronFilledUp)
