import { useEffect, useState } from 'react'

import { useEventListener } from './useEventListener'

export const useOnKeyDown = (targetKey: string, callback?: (keyWasPressed: boolean) => void) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false)

  useEffect(() => {
    if (callback) callback(keyPressed)
  }, [callback, keyPressed])

  // If pressed key is our target key then set to true
  const downHandler = ({ key }: KeyboardEvent) => {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }: KeyboardEvent) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }

  useEventListener('keydown', downHandler)
  useEventListener('keyup', upHandler)

  return keyPressed
}
