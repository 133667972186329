import { useMediaQuery } from 'react-responsive'
import media from 'styled-media-query'

import { sizes } from '../styles/medias'

interface ResponsiveProps {
  children: any
  orientation?: 'landscape' | 'portrait'
}

export const Desktop = ({ children }: ResponsiveProps) => {
  const isDesktop = useMediaQuery({ minWidth: 896 })
  return isDesktop ? children : null
}
export const Tablet = ({ children, orientation }: ResponsiveProps) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 895, orientation })
  return isTablet ? children : null
}
export const Mobile = ({ children, orientation }: ResponsiveProps) => {
  const isMobile = useMediaQuery({ maxWidth: 767, orientation })
  return isMobile ? children : null
}

export const TabletOrMobile = ({ children }: ResponsiveProps) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 895 })
  return isTabletOrMobile ? children : null
}

export const DefaultScreen = ({ children }: ResponsiveProps) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

// @ts-ignore
export const mobileMediaQuery = media.lessThan('767px')
// @ts-ignore
export const tabletOrMobileMediaQuery = media.lessThan('896px')
// @ts-ignore
export const desktopMediaQuery = media.greaterThan('896px')
// @ts-ignore
export const tabletMediaQuery = media.between('768px', '895px')
// @ts-ignore
export const tabletOrDesktopMediaQuery = media.greaterThan('767px')

export const useIsUltraLargeDesktop = () => useMediaQuery({ minWidth: sizes.XXXL_SCREEN })
export const useIsExtraLargeDesktop = () => useMediaQuery({ minWidth: 1440 })
export const useIsLargeDesktop = () => useMediaQuery({ minWidth: sizes.XXL_SCREEN })
export const useIsLaptop = () => useMediaQuery({ minWidth: sizes.XL_SCREEN })
export const useIsSmallDesktop = () => useMediaQuery({ maxWidth: sizes.XL_MEDIUM_SCREEN })
export const useIsMobilePortrait = () => useMediaQuery({ maxWidth: sizes.SM_SCREEN })
export const useIsMobile = () => useMediaQuery({ maxWidth: 767 })
export const useIsTinyMobile = () => useMediaQuery({ maxWidth: sizes.XS_SCREEN })
export const useIsTablet = () => useMediaQuery({ minWidth: 768, maxWidth: 895 })
export const useIsTabletOrMobile = () => {
  const isTablet = useIsTablet()
  const isMobile = useIsMobile()

  return isTablet || isMobile
}
