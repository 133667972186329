interface VariablesProps {
  variables: { [key: string]: any }
}

const getPattern = (display: string) => new RegExp(`[$]{${display}}`, 'gm')

export const injectVariables = (text: string, { variables }: VariablesProps) => {
  return Object.keys(variables).reduce(
    (prev, variable) => prev.replace(getPattern(variable), variables[variable]),
    text,
  )
}
