// @ts-nocheck
import { ReactNode } from 'react'

import { TippyProps } from '@tippyjs/react'

export enum TooltipTheme {
  light = 'light',
  dark = 'dark',
  danger = 'danger',
  alert = 'alert',
}

export interface TooltipProps extends TippyProps {
  content: ReactNode | (() => ReactNode)
  theme?: TooltipTheme
  isVisible?: boolean
}
