import { CustomFieldSectionProjection } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import {
  customFieldsSectionIdList,
  homeownerInfoCustomFieldsSections,
} from '@new/domains/properties/constants'
import { HomeownerInfoSections } from '@new/domains/properties/screens/propertyHomeownerInfo/PropertyHomeownerInfo.types'

export const homeownerInfoSectionsIds = homeownerInfoCustomFieldsSections.map(
  section => customFieldsSectionIdList[section],
)

export const onlyHomeownerInfoSections = <T extends CustomFieldSectionProjection>(
  customFields: T,
) => homeownerInfoSectionsIds.includes(customFields?.section.id)

export const sectionLabelByKind = {
  [HomeownerInfoSections.ownerDocuments]: 'Owner Documents',
}
