import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconBookingCom = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="4 7 20 15" fill="none" {...props} ref={ref}>
    <path
      d="M12.7254 19.0335L10.4702 19.0312V16.2739C10.4702 15.6848 10.6937 15.3782 11.1867 15.3081H12.7254C13.8226 15.3081 14.5325 16.0155 14.5325 17.1605C14.5325 18.3364 13.8406 19.0323 12.7254 19.0335ZM10.4702 11.5966V10.8708C10.4702 10.2358 10.733 9.93376 11.3091 9.89471H12.4637C13.4531 9.89471 14.0461 10.4999 14.0461 11.5139C14.0461 12.2856 13.6396 13.1871 12.4996 13.1871H10.4702V11.5966ZM15.605 14.3481L15.1973 14.1138L15.5534 13.8026C15.9678 13.4386 16.6619 12.6198 16.6619 11.2073C16.6619 9.04377 15.021 7.64849 12.4816 7.64849H9.58403V7.64734H9.25383C8.50135 7.67605 7.89824 8.30192 7.89038 9.07593V21.352H12.5389C15.3613 21.352 17.183 19.7811 17.183 17.3476C17.183 16.0374 16.5945 14.9177 15.605 14.3481Z"
      fill="white"
    />
    <path
      d="M18.3936 19.6562C18.3936 18.7184 19.1333 17.9597 20.0446 17.9597C20.9583 17.9597 21.7015 18.7184 21.7015 19.6562C21.7015 20.5928 20.9583 21.3527 20.0446 21.3527C19.1333 21.3527 18.3936 20.5928 18.3936 19.6562Z"
      fill="#00BAFC"
    />
  </svg>
)

export const IconBookingCom = forwardRef(_IconBookingCom)
