import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconLightBulb(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        d="M10.625 21.625H13.375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 12C17.5036 10.9705 17.2183 9.96063 16.6764 9.08528C16.1345 8.20993 15.3578 7.50425 14.4346 7.04854C13.5114 6.59282 12.4789 6.40535 11.4544 6.50747C10.43 6.60959 9.45479 6.9972 8.63975 7.62619C7.82471 8.25518 7.20258 9.1003 6.84413 10.0654C6.48568 11.0305 6.40531 12.0768 6.61215 13.0854C6.81899 14.0939 7.30475 15.0241 8.01416 15.7702C8.72357 16.5163 9.62814 17.0483 10.625 17.3057V18.875H13.375V17.3057C14.5534 17.0037 15.5981 16.3187 16.3448 15.3583C17.0914 14.3978 17.4978 13.2165 17.5 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3.75V2.375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 10.625H21.625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.375 12H3.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8337 17.1461L18.8063 18.1187"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.19385 4.50623L6.16643 5.47881"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8337 5.47881L18.8063 4.50623"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.19385 18.1187L6.16643 17.1461"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconLightBulb = forwardRef(_IconLightBulb)
