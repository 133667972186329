import { useCallback, useState } from 'react'

export const useToggle = (
  initialValue: boolean = false,
): [boolean, VoidFunction, (newValue: boolean) => void] => {
  const [value, setValue] = useState(initialValue)

  const toggleValue = useCallback(() => {
    setValue(prev => !prev)
  }, [])

  const forceValueTo = useCallback((newValue: boolean) => {
    setValue(newValue)
  }, [])

  return [value, toggleValue, forceValueTo]
}
