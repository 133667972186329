import React, { Ref } from 'react'

function _IconRejectFilled(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.999 31.7323C25.136 31.7323 31.7323 25.136 31.7323 16.999C31.7323 8.86196 25.136 2.26562 16.999 2.26562C8.86196 2.26562 2.26562 8.86196 2.26562 16.999C2.26562 25.136 8.86196 31.7323 16.999 31.7323Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.175 13.1103C22.5291 12.7562 22.5291 12.1821 22.175 11.8281C21.8209 11.474 21.2468 11.474 20.8928 11.8281L17.0005 15.7203L13.1083 11.8281C12.7542 11.474 12.1802 11.474 11.8261 11.8281C11.472 12.1821 11.472 12.7562 11.8261 13.1103L15.7183 17.0025L11.8261 20.8947C11.472 21.2488 11.472 21.8229 11.8261 22.1769C12.1802 22.531 12.7542 22.531 13.1083 22.1769L17.0005 18.2847L20.8928 22.1769C21.2468 22.531 21.8209 22.531 22.175 22.1769C22.5291 21.8229 22.5291 21.2488 22.175 20.8947L18.2828 17.0025L22.175 13.1103Z"
        fill="white"
      />
    </svg>
  )
}

export const IconRejectFilled = React.forwardRef(_IconRejectFilled)
