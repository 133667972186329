import React, { Ref } from 'react'

function _IconInstructions(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 1H20C20.5523 1 21 1.44772 21 2V17V22C21 22.5523 20.5523 23 20 23H6.5C4.567 23 3 21.433 3 19.5V4.5C3 2.567 4.567 1 6.5 1ZM5 19.5C5 20.3284 5.67157 21 6.5 21H19V18H6.5C5.67157 18 5 18.6716 5 19.5ZM19 16H6.5C5.9632 16 5.45463 16.1208 5 16.3368V4.5C5 3.67157 5.67157 3 6.5 3H19V16Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconInstructions = React.forwardRef(_IconInstructions)
