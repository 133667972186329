import React from 'react'

function _IconSuccess(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <circle cx="8.00008" cy="7.99999" r="7.33333" fill="#22C4A6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3351 5.67512C11.5146 5.86015 11.51 6.15559 11.325 6.33501L7.19998 10.335C7.01897 10.5105 6.73126 10.5105 6.55025 10.335L4.67525 8.51683C4.49022 8.33741 4.48567 8.04197 4.66509 7.85694C4.84451 7.67192 5.13996 7.66737 5.32498 7.84679L6.87511 9.34995L10.6752 5.66497C10.8603 5.48555 11.1557 5.4901 11.3351 5.67512Z"
        fill="white"
      />
    </svg>
  )
}

const IconSuccess = React.memo(React.forwardRef(_IconSuccess))

export { IconSuccess }
