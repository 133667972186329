import React, { Ref } from 'react'

function _IconChevronRight(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57576 12.4244C5.34145 12.19 5.34145 11.8101 5.57576 11.5758L9.1515 8.0001L5.57576 4.42436C5.34145 4.19005 5.34145 3.81015 5.57576 3.57583C5.81007 3.34152 6.18997 3.34152 6.42429 3.57583L10.4243 7.57583C10.6586 7.81015 10.6586 8.19005 10.4243 8.42436L6.42429 12.4244C6.18997 12.6587 5.81007 12.6587 5.57576 12.4244Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconChevronRight = React.forwardRef(_IconChevronRight)
