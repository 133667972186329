import React, { Ref } from 'react'

function _IconFinance(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 2.49992C5.85778 2.49992 2.49992 5.85778 2.49992 9.99992C2.49992 14.1421 5.85778 17.4999 9.99992 17.4999C14.1421 17.4999 17.4999 14.1421 17.4999 9.99992C17.4999 5.85778 14.1421 2.49992 9.99992 2.49992ZM0.833252 9.99992C0.833252 4.93731 4.93731 0.833252 9.99992 0.833252C15.0625 0.833252 19.1666 4.93731 19.1666 9.99992C19.1666 15.0625 15.0625 19.1666 9.99992 19.1666C4.93731 19.1666 0.833252 15.0625 0.833252 9.99992ZM9.99992 4.58325C10.4602 4.58325 10.8333 4.95635 10.8333 5.41659V6.24993H12.0833C12.5435 6.24993 12.9166 6.62303 12.9166 7.08327C12.9166 7.54351 12.5435 7.9166 12.0833 7.9166H8.95825C8.79249 7.9166 8.63352 7.98245 8.51631 8.09966C8.3991 8.21687 8.33325 8.37584 8.33325 8.5416C8.33325 8.70736 8.3991 8.86633 8.51631 8.98354C8.63352 9.10075 8.79249 9.1666 8.95825 9.1666H11.0416C11.6494 9.1666 12.2323 9.40804 12.662 9.83782C13.0918 10.2676 13.3333 10.8505 13.3333 11.4583C13.3333 12.0661 13.0918 12.649 12.662 13.0787C12.2323 13.5085 11.6494 13.7499 11.0416 13.7499H10.8333V14.5833C10.8333 15.0435 10.4602 15.4166 9.99992 15.4166C9.53968 15.4166 9.16659 15.0435 9.16659 14.5833V13.7499H7.49992C7.03968 13.7499 6.66658 13.3768 6.66658 12.9166C6.66658 12.4564 7.03968 12.0833 7.49992 12.0833H11.0416C11.2073 12.0833 11.3663 12.0174 11.4835 11.9002C11.6007 11.783 11.6666 11.624 11.6666 11.4583C11.6666 11.2925 11.6007 11.1335 11.4835 11.0163C11.3663 10.8991 11.2073 10.8333 11.0416 10.8333H8.95825C8.35046 10.8333 7.76757 10.5918 7.3378 10.1621C6.90803 9.73228 6.66658 9.14939 6.66658 8.5416C6.66658 7.93381 6.90803 7.35092 7.3378 6.92115C7.76757 6.49138 8.35046 6.24993 8.95825 6.24993H9.16659V5.41659C9.16659 4.95635 9.53968 4.58325 9.99992 4.58325Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconFinance = React.forwardRef(_IconFinance)
