import styled from 'styled-components'

export const Root = styled.div<{ maxHeight: number }>`
  position: fixed;
  bottom: 16px;
  right: 16px;
  height: auto;
  max-height: ${props => props.maxHeight}px;
  transition: max-height 0.4s ease-out;
  z-index: 999;
  pointer-events: none;
`

export const AlertsContainer = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: none;
`
