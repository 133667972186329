import styled, { css } from 'styled-components'

import { Button } from '../../components/Button'
import { mainColors } from '../../styles/colors'
import { _S_Normal } from '../../styles/typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const SelectDateContainer = styled.div<{ isOpen: boolean }>`
  border: 2px solid #e9eef1;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  padding: 10px 14px;
  gap: 6px;
  cursor: pointer;

  ${({ isOpen }) =>
    isOpen &&
    css`
      border: 2px solid ${mainColors.accentMedium};
    `}
`

export const DateOption = styled.div`
  ${_S_Normal};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 10px;
  background-color: ${mainColors.neutralMedium};
  border-radius: 2px;
`

export const RemoveButton = styled(Button.Flat)`
  color: ${mainColors.neutralExtraDark};

  svg {
    color: ${mainColors.neutralExtraDark};
  }
`
