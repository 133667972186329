// @ts-nocheck
import React, { HTMLAttributes } from 'react'
import { StyledComponent } from 'styled-components'

import { InfoTooltip } from '../../components/InfoTooltip/InfoTooltip'
import { restrictionColor } from '../../styles/colors'
import * as S from './FieldHeader.styles'
import { FieldHeaderProps, FieldHeaderSizeProps } from './FieldHeader.types'

function createFieldHeaderComponent<T>(
  Component: StyledComponent<any, any, FieldHeaderProps>,
  size?: FieldHeaderSizeProps,
) {
  return ({
    title,
    restriction,
    error,
    warning,
    info,
    afterTitle,
    children,
    marginBottom,
    showHeader = true,
    removeHeaderMargin = false,
    className,
    titleColor,
    ...props
  }: FieldHeaderProps & HTMLAttributes<HTMLDivElement> & T) => {
    const renderErrorWarningTooltip = () => {
      if (!error && !warning && !info) {
        return null
      }
      return <InfoTooltip error={error} warning={warning} info={info} />
    }

    return (
      <Component {...size} className={className} {...props}>
        <S.Container
          restriction={restriction}
          restrictionColor={restriction && restrictionColor[restriction]}
          marginBottom={marginBottom}
        >
          <S.Header {...size} showHeader={showHeader} removeHeaderMargin={removeHeaderMargin}>
            <S.FieldHeaderTitle {...size} color={titleColor}>
              {title}
              {afterTitle ? <S.AfterTitle>{afterTitle}</S.AfterTitle> : null}
            </S.FieldHeaderTitle>

            <S.TooltipContainer $isVisible={!!(error || warning || info)}>
              {(error || warning || info) && renderErrorWarningTooltip()}
            </S.TooltipContainer>
          </S.Header>

          {children}
        </S.Container>
      </Component>
    )
  }
}

const FieldHeader = (): React.ReactNode => createFieldHeaderComponent(S.Root)

FieldHeader.Default = createFieldHeaderComponent(S.Root)
FieldHeader.Small = createFieldHeaderComponent(S.Root, {
  small: true,
})

export { FieldHeader }
