import React from 'react'

function _IconCloseModal(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1364 8.13649C17.4878 7.78502 17.4878 7.21517 17.1364 6.8637C16.7849 6.51223 16.2151 6.51223 15.8636 6.8637L12 10.7273L8.13637 6.8637C7.7849 6.51223 7.21505 6.51223 6.86358 6.8637C6.51211 7.21517 6.51211 7.78502 6.86358 8.13649L10.7272 12.0001L6.86358 15.8637C6.51211 16.2152 6.51211 16.785 6.86358 17.1365C7.21505 17.488 7.7849 17.488 8.13637 17.1365L12 13.2729L15.8636 17.1365C16.2151 17.488 16.7849 17.488 17.1364 17.1365C17.4878 16.785 17.4878 16.2152 17.1364 15.8637L13.2728 12.0001L17.1364 8.13649Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconCloseModal = React.memo(React.forwardRef(_IconCloseModal))

export { IconCloseModal }
