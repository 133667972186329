enum PeriodType {
  Block = 'block',
  PastBooking = 'past-booking',
  ActiveBooking = 'active-booking',
  FutureBooking = 'future-booking',
}

enum WeekDay {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

enum Month {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}

export { Month, WeekDay, PeriodType }
