import React, { Ref } from 'react'

function _IconDeviceOn(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.40947 9.93903C7.22989 8.26461 9.57322 7.34075 11.9999 7.34075C14.4266 7.34075 16.77 8.26461 18.5904 9.93903C18.8804 10.2058 19.3227 10.1767 19.5784 9.87408C19.834 9.57146 19.8061 9.10989 19.5161 8.84314C17.44 6.93351 14.7675 5.87988 11.9999 5.87988C9.23235 5.87988 6.55985 6.93351 4.48372 8.84314C4.1937 9.10989 4.16584 9.57146 4.42147 9.87408C4.67711 10.1767 5.11945 10.2058 5.40947 9.93903ZM7.78132 12.4219C8.97325 11.386 10.4754 10.8187 12.0267 10.8187C13.5779 10.8187 15.0801 11.386 16.272 12.4219C16.569 12.6801 17.0104 12.6381 17.2578 12.3282C17.5053 12.0182 17.465 11.5576 17.168 11.2994C15.7245 10.0448 13.9053 9.3578 12.0267 9.3578C10.148 9.3578 8.32881 10.0448 6.88532 11.2994C6.58827 11.5576 6.54803 12.0182 6.79546 12.3282C7.04288 12.6381 7.48427 12.6801 7.78132 12.4219ZM12.0035 14.2963C11.3186 14.2963 10.6507 14.5187 10.0923 14.9327C9.77713 15.1663 9.34013 15.0891 9.11623 14.7602C8.89232 14.4314 8.9663 13.9754 9.28146 13.7417C10.0767 13.1522 11.028 12.8355 12.0035 12.8355C12.979 12.8355 13.9304 13.1522 14.7256 13.7417C15.0408 13.9754 15.1148 14.4314 14.8909 14.7602C14.6669 15.0891 14.2299 15.1663 13.9148 14.9327C13.3564 14.5187 12.6885 14.2963 12.0035 14.2963ZM12.0002 17.7391C12.3684 17.7391 12.6668 17.4276 12.6668 17.0434C12.6668 16.6592 12.3684 16.3478 12.0002 16.3478C11.632 16.3478 11.3335 16.6592 11.3335 17.0434C11.3335 17.4276 11.632 17.7391 12.0002 17.7391Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconDeviceOn = React.forwardRef(_IconDeviceOn)
