import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import MaskedInput from 'react-text-mask'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { StyledComponent } from 'styled-components'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { usePrevious } from '../../hooks/usePrevious'
import { InfoTooltip } from '../InfoTooltip'
import { ToggleAmountPolarity } from './ToggleAmountPolarity'
import { ToggleAmountType } from './ToggleAmountType'
import * as S from './ToggleAmountTypeInput.styles'
import {
  ToggleAmountTypeInputProps,
  ToggleAmountTypeInputSizeProps,
  ToggleAmountTypeInputStyleProps,
  ToggleInputPolarity as ToggleInputPolarityEnum,
} from './ToggleAmountTypeInput.types'

const DEBOUNCE_TIME = 300

const defaultMask = createNumberMask({
  allowDecimal: true,
  includeThousandsSeparator: ',',
  prefix: '',
})

// Keep this export clean, free of wrapping HOCs
function createTextFieldComponent(
  Component: StyledComponent<any, any, ToggleAmountTypeInputStyleProps>,
  size?: ToggleAmountTypeInputSizeProps,
) {
  return ({
    className,
    polarity,
    hasPolarity,
    type,
    value,
    error,
    warning,
    mask,
    extraInfo,
    onCleanValueClick,
    onTypeChange,
    onPolarityChange,
    onValueChange,
    setLoading,
    debounceTime: debounceTimeFromProps,
    allowEmptyValue = false,
    placeholder,
  }: ToggleAmountTypeInputProps) => {
    const [focus, setFocus] = useState(false)
    const [_value, _setValue] = useState(value)
    const _prevValue = usePrevious(_value)

    const inputRef = useRef<MaskedInput | null>(null)

    const debounceTime = debounceTimeFromProps === 0 ? 0 : debounceTimeFromProps || DEBOUNCE_TIME

    // debounceTime is unknown dependency for debounce function and that returns an es-lint error
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnChange = useCallback(
      debounce((currentValue: string, newValue: string) => {
        onValueChange(newValue)
        _setValue(currentValue)
        setLoading?.(false)
      }, debounceTime),
      [onValueChange],
    )

    const internalDebouncedOnChange = useCallback(
      (newValue: string) => {
        debouncedOnChange.cancel()
        setLoading?.(true)
        debouncedOnChange(value, newValue)
      },
      [debouncedOnChange, value, setLoading],
    )

    useEffect(() => {
      // We only want to call onChange when the value effectively changes
      if (_prevValue === undefined || _value === _prevValue) return
      internalDebouncedOnChange(_value)
    }, [_prevValue, _value, internalDebouncedOnChange])

    useEffect(() => {
      _setValue(value)
    }, [value])

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.currentTarget.value

      if (allowEmptyValue) {
        _setValue(inputValue)
      } else {
        _setValue(inputValue === '' ? '0' : inputValue)
      }
    }

    const cleanInput = onCleanValueClick ? (
      <S.ClearButton data-testid="clean-icon" width={16} onClick={onCleanValueClick} />
    ) : null

    return (
      <S.Root className={className} error={error} warning={warning} focus={focus} {...size}>
        {hasPolarity && (
          <ToggleAmountPolarity
            polarity={polarity ?? ToggleInputPolarityEnum.positive}
            onChange={currentPolarity => onPolarityChange?.(currentPolarity)}
            {...size}
          />
        )}
        <ToggleAmountType type={type} onChange={onTypeChange} {...size} />
        {extraInfo}
        <Component
          data-testid="toggle-input"
          data-cy="add-adjustment-price-input"
          mask={mask ?? defaultMask}
          onChange={handleOnChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          placeholderChar="0"
          ref={inputRef}
          value={_value}
          placeholder={placeholder}
          inputMode="numeric"
        />

        {error || warning ? (
          <S.TooltipContainer>
            <InfoTooltip error={error} warning={warning} />
          </S.TooltipContainer>
        ) : (
          cleanInput
        )}
      </S.Root>
    )
  }
}

const ToggleAmountTypeInput = () => createTextFieldComponent(S.Input)

ToggleAmountTypeInput.Default = createTextFieldComponent(S.Input)
ToggleAmountTypeInput.Small = createTextFieldComponent(S.Input, { small: true })

// If you need to wrap this component with HOCs, use them here
export { ToggleAmountTypeInput }
