import styled from 'styled-components'

import { mainColors, semanticColors } from '../../styles/colors'
import { _3XL_Bold } from '../../styles/typography'

export const Input = styled.input<{ value: number | string; hasError: boolean }>`
  all: unset;
  width: 42px;
  height: 36px;
  ${_3XL_Bold};
  line-height: 1.6;
  text-align: center;
  color: ${p => (p.hasError ? semanticColors.failureMedium : mainColors.accentMedium)};
  border-bottom: 2px solid transparent;
  border-radius: 2px;

  ${p =>
    p.value === '' &&
    `&:not(:focus) {
      transform: translateY(-50%);
      border-color: ${p.hasError ? semanticColors.failureMedium : mainColors.accentMedium};
  }`}
`
