import jwtDecode from 'jwt-decode'

import { DecodedToken, GoogleAuthResponse, UserFromAuth } from '@contexts/Auth/Auth.types'

export const extractUserFromGoogleAuth = (googleAuthResponse: GoogleAuthResponse): UserFromAuth => {
  const userProfile = googleAuthResponse.currentUser.get().getBasicProfile()

  return {
    id: userProfile?.getId(),
    name: userProfile?.getName(),
    email: userProfile?.getEmail(),
    photo: userProfile?.getImageUrl(),
  }
}

export const extractUserFromToken = (token: string): UserFromAuth => {
  const { name, email, userHash } = jwtDecode<DecodedToken>(token)

  return {
    name,
    id: userHash,
    email,
    photo: 'test',
  }
}
