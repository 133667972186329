import React, { Ref } from 'react'

function _IconUser3(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.67996 9.25464C9.67996 7.91096 10.7961 6.8001 12.2 6.8001C13.6038 6.8001 14.72 7.91096 14.72 9.25464C14.72 10.5983 13.6038 11.7092 12.2 11.7092C10.7961 11.7092 9.67996 10.5983 9.67996 9.25464ZM12.2 5.6001C10.1575 5.6001 8.47996 7.22436 8.47996 9.25464C8.47996 10.7061 9.33733 11.95 10.5725 12.5414C8.63416 13.1563 7.2 14.8403 7.2 16.8907C7.2 17.2221 7.46863 17.4907 7.8 17.4907C8.13137 17.4907 8.4 17.2221 8.4 16.8907C8.4 15.8521 8.93302 14.8946 9.78927 14.2622C10.2258 15.1447 11.1453 15.7458 12.2001 15.7458C13.2548 15.7458 14.1743 15.1448 14.6109 14.2623C15.467 14.8947 16 15.8522 16 16.8907C16 17.2221 16.2686 17.4907 16.6 17.4907C16.9314 17.4907 17.2 17.2221 17.2 16.8907C17.2 14.8403 15.7658 13.1563 13.8275 12.5413C15.0626 11.95 15.92 10.7061 15.92 9.25464C15.92 7.22436 14.2424 5.6001 12.2 5.6001ZM12.2 13.4907C12.6776 13.4907 13.1303 13.5682 13.5456 13.7086C13.3134 14.1984 12.8024 14.5458 12.2001 14.5458C11.5978 14.5458 11.0868 14.1983 10.8545 13.7086C11.2698 13.5682 11.7224 13.4907 12.2 13.4907Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconUser3 = React.forwardRef(_IconUser3)
