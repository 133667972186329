import styled from 'styled-components'

import { _M_Normal, mainColors } from '../../styles'

export const Root = styled.div<{ eventContentGap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ eventContentGap }) => eventContentGap}px;
`

const POINT_WIDTH = 14
const LINE_RIGHT_WIDTH = 13

export const PointWrapper = styled.div`
  position: relative;
  height: 100%;
  width: ${POINT_WIDTH + 2 * LINE_RIGHT_WIDTH}px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Point = styled.div`
  z-index: 2;
  border-radius: 50%;
  min-width: 14px;
  width: 14px;
  height: 14px;
  background-color: ${mainColors.accentExtremelyLight};

  &:before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: ${mainColors.accentMedium};
    border-radius: 50%;
    position: relative;
    top: -5px;
    left: 4px;
  }
`

/* Timeline Item */

const MIN_LINE_DASH_HEIGHT = 2
const BORDER_WIDTH = 0.5

export const Line = styled.div<{ eventContentGap: number; $hasCustomPoint?: boolean }>`
  width: 1px;
  border-left: ${2 * BORDER_WIDTH}px dashed ${mainColors.neutralDark};
  position: absolute;
  height: ${({ eventContentGap }) => `calc(100% + ${eventContentGap - MIN_LINE_DASH_HEIGHT}px)`};
  right: ${LINE_RIGHT_WIDTH + 0.5 * POINT_WIDTH - BORDER_WIDTH}px;

  ${({ $hasCustomPoint }) => $hasCustomPoint && `z-index: -1`};
`

export const ItemActivitiesContainer = styled.div`
  width: 100%;
`

export const HourDisplay = styled.div`
  ${_M_Normal};
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  height: 100%;
  position: relative;
  color: ${mainColors.neutralExtraDark};
`

export const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(70px, 1fr) 1fr 100%;

  &:not(:last-of-type) {
    > ${ItemActivitiesContainer} {
      padding-bottom: 16px;
      border-bottom: 1px solid ${mainColors.neutralMedium};
    }
  }
`
