import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconLogIn(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6667 2.75C12.8214 2.75 12.9697 2.81146 13.0791 2.92085C13.1885 3.03025 13.25 3.17862 13.25 3.33333V12.6667C13.25 12.8214 13.1885 12.9697 13.0791 13.0791C12.9697 13.1885 12.8214 13.25 12.6667 13.25H10C9.58579 13.25 9.25 13.5858 9.25 14C9.25 14.4142 9.58579 14.75 10 14.75H12.6667C13.2192 14.75 13.7491 14.5305 14.1398 14.1398C14.5305 13.7491 14.75 13.2192 14.75 12.6667V3.33333C14.75 2.7808 14.5305 2.2509 14.1398 1.86019C13.7491 1.46949 13.2192 1.25 12.6667 1.25H10C9.58579 1.25 9.25 1.58579 9.25 2C9.25 2.41421 9.58579 2.75 10 2.75H12.6667ZM6.1363 4.13642C6.42919 3.84352 6.90406 3.84352 7.19696 4.13642L10.5222 7.46163C10.6627 7.59794 10.75 7.78877 10.75 8C10.75 8.10429 10.7287 8.2036 10.6903 8.29384C10.6537 8.37979 10.6004 8.46031 10.5303 8.53041L7.19696 11.8637C6.90406 12.1566 6.42919 12.1566 6.1363 11.8637C5.8434 11.5709 5.8434 11.096 6.1363 10.8031L8.18938 8.75H2C1.58579 8.75 1.25 8.41421 1.25 8C1.25 7.58579 1.58579 7.25 2 7.25H8.18922L6.1363 5.19708C5.8434 4.90418 5.8434 4.42931 6.1363 4.13642Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconLogIn = forwardRef(_IconLogIn)
