import styled, { css } from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _S_Bold } from '../../styles/typography'
import { rgba } from '../../utils/cssUtils'

export type ContainerProps = {
  className?: string
  full?: boolean
  style?: React.CSSProperties
}

export type TitleProps = {
  isLoading?: boolean
}

export const CardTitle = styled.h3<TitleProps>`
  ${_S_Bold};
  color: ${props => (props.isLoading ? mainColors.neutralExtraDark : mainColors.black)};
  margin-bottom: 26px;
  text-transform: uppercase;
  text-align: center;
`

const CardRootStyles = css`
  box-shadow: 0 2px 10px 0 ${rgba(mainColors.black, 0.05)};
  background-color: ${mainColors.white};
  margin-bottom: 20px;
  width: 100%;
  min-height: 0;
`

export const CardRoot = styled.div<ContainerProps>`
  ${CardRootStyles};

  ${props =>
    props.full &&
    `
    flex: 1;
    min-height: 400px;
  `}
`
