import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconBarChart(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" {...props} ref={ref}>
      <rect width="44" height="44" rx="22" fill="#F2F5F7" />
      <path
        d="M27 28.6666V20.3333"
        stroke="#505051"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 28.6666V15.3333"
        stroke="#505051"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 28.6667V23.6667"
        stroke="#505051"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconBarChart = forwardRef(_IconBarChart)
