import React, { FC } from 'react'

import * as S from './Loading.styles'
import { Alignment, LoadingProps } from './Loading.types'

const Loading: FC<LoadingProps> = ({
  absolute,
  alignment = Alignment.center,
  className,
  color,
  dataCy,
  size = '8px',
}) => {
  return (
    <S.LoadingStyled
      className={className}
      data-cy={dataCy}
      absolute={absolute}
      alignment={alignment}
    >
      <S.Circle size={size} color={color} />
      <S.Circle size={size} color={color} />
      <S.Circle size={size} color={color} />
    </S.LoadingStyled>
  )
}

export { Loading }
