import React from 'react'

function _IconPlusCircle(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99935 1.99935C4.68564 1.99935 1.99935 4.68564 1.99935 7.99935C1.99935 11.3131 4.68564 13.9994 7.99935 13.9994C11.313 13.9994 13.9993 11.3131 13.9993 7.99935C13.9993 4.68564 11.313 1.99935 7.99935 1.99935ZM0.666016 7.99935C0.666016 3.94926 3.94926 0.666016 7.99935 0.666016C12.0494 0.666016 15.3327 3.94926 15.3327 7.99935C15.3327 12.0495 12.0494 15.3327 7.99935 15.3327C3.94926 15.3327 0.666016 12.0495 0.666016 7.99935ZM7.99967 4.66602C8.36786 4.66602 8.66634 4.96449 8.66634 5.33268V7.33252H10.666C11.0342 7.33252 11.3327 7.631 11.3327 7.99919C11.3327 8.36738 11.0342 8.66586 10.666 8.66586H8.66634V10.6661C8.66634 11.0342 8.36786 11.3327 7.99967 11.3327C7.63148 11.3327 7.33301 11.0342 7.33301 10.6661V8.66586H5.33269C4.9645 8.66586 4.66602 8.36738 4.66602 7.99919C4.66602 7.631 4.9645 7.33252 5.33269 7.33252H7.33301V5.33268C7.33301 4.96449 7.63148 4.66602 7.99967 4.66602Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconPlusCircle = React.memo(React.forwardRef(_IconPlusCircle))

export { IconPlusCircle }
