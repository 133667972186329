import { DocumentNode } from 'graphql'
import { print } from 'graphql/language/printer'
import get from 'lodash/get'

import { Alert } from '@avantstay/backoffice-core'

import { trackError } from '@tracking/customEventReport'
import { IS_PRODUCTION } from '@utils/constants'
import history from '@utils/history'

const isUserNotAuthorized = ({ message }: { message: string }) => {
  if (message === 'User not authorized') {
    history.push('/error?errorType=not_authorized')
  }
}

const fetchApi = <T = any>(
  query: DocumentNode,
  variables?: { [key: string]: any },
  signal?: AbortSignal,
  apiUrl?: string,
): Promise<any> =>
  fetch(apiUrl || (process.env.REACT_APP_ARRIERE_BACKOFFICE as string), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-teammate': `${localStorage.getItem('teammate')}`,
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    },
    body: JSON.stringify({
      query: print(query),
      variables,
    }),
    cache: 'default',
    signal,
  }).then(async response => {
    const data = await response.json()
    const unauthorizedToPerform = response.status === 401
    const errors = get(data, 'errors', [])
    const hasErrors = Boolean(errors.length)

    if (unauthorizedToPerform) {
      Alert.Error(
        'You don’t have permission to perform this action. Please, contact your team administrator.',
      )
    }

    if (hasErrors) {
      if (IS_PRODUCTION) {
        errors.forEach((error: any) => {
          trackError(`API error: ${JSON.stringify(error)}`, error.message)
        })
      }
      isUserNotAuthorized(errors[0])
    }

    return data as { data: T } | { errors: Array<string> }
  })

const fetchPublicApi = (
  query: DocumentNode,
  variables?: { [key: string]: any },
  signal?: AbortSignal,
) => fetchApi(query, variables, signal, process.env.REACT_APP_ARRIERE_PUBLIC as string)

type FetchOptions = {
  url?: string
  headers?: { [key: string]: string }
  body?: { [key: string]: any }
  signal?: AbortSignal
}

const fetchTemplates = async <T = any>(options: FetchOptions): Promise<T> => {
  const emailURL = process.env.REACT_APP_MODELE_EMAIL as string

  const response = await fetch(`${emailURL}/${options.url}`, {
    method: 'POST',
    headers: {
      // Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options.body),
  })

  if (!response.ok) throw new Error(`Fetch failed with status: ${response.status}`)

  const data = await response.json()

  return data
}

export { fetchPublicApi, fetchTemplates }
export default fetchApi
