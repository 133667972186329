import React, { Ref } from 'react'

function _IconClose2(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2803 6.78036C14.5732 6.48747 14.5732 6.01259 14.2803 5.7197C13.9874 5.42681 13.5126 5.42681 13.2197 5.7197L10 8.93937L6.78033 5.7197C6.48744 5.42681 6.01256 5.42681 5.71967 5.7197C5.42678 6.01259 5.42678 6.48747 5.71967 6.78036L8.93934 10L5.71967 13.2197C5.42678 13.5126 5.42678 13.9875 5.71967 14.2804C6.01256 14.5733 6.48744 14.5733 6.78033 14.2804L10 11.0607L13.2197 14.2804C13.5126 14.5733 13.9874 14.5733 14.2803 14.2804C14.5732 13.9875 14.5732 13.5126 14.2803 13.2197L11.0607 10L14.2803 6.78036Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconClose2 = React.forwardRef(_IconClose2)
