import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconDiscount(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6462 6.33308L7.40905 11.94L2.23343 8.95186L5.47059 3.34494L9.17895 2.89816L10.6462 6.33308ZM4.60798 2.84692C4.76474 2.57541 5.04018 2.39354 5.35144 2.35604L9.05981 1.90926C9.49932 1.85631 9.92103 2.09978 10.0949 2.50689L11.5622 5.94182C11.6853 6.23013 11.6656 6.5596 11.5088 6.83111L8.27165 12.438C7.9966 12.9144 7.38743 13.0777 6.91102 12.8026L1.73541 9.81446C1.25901 9.53941 1.09578 8.93024 1.37083 8.45383L4.60798 2.84692ZM8.67206 5.76845C8.39701 6.24486 7.78784 6.40808 7.31144 6.13303C6.83503 5.85798 6.67181 5.24881 6.94686 4.77241C7.22191 4.296 7.83108 4.13278 8.30748 4.40783C8.78389 4.68288 8.94711 5.29205 8.67206 5.76845Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconDiscount = forwardRef(_IconDiscount)
