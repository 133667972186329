import { useCallback, useEffect, useRef } from 'react'

import { getRefElement } from '../utils/getRefElement'

export function useEventListener<KD extends keyof DocumentEventMap>(
  type: KD,
  listener: (this: Document, evt: DocumentEventMap[KD]) => void,
  element?: Document | null,
  options?: boolean | AddEventListenerOptions,
): void

export function useEventListener<KH extends keyof HTMLElementEventMap>(
  type: KH,
  listener: (this: HTMLElement, evt: HTMLElementEventMap[KH]) => void,
  element?: HTMLElement | null,
  options?: boolean | AddEventListenerOptions,
): void

export function useEventListener<KW extends keyof WindowEventMap>(
  type: KW,
  listener: (this: Window, evt: WindowEventMap[KW]) => void,
  element?: Window | null,
  options?: boolean | AddEventListenerOptions,
): void

export function useEventListener(
  type: string,
  listener: (evt: Event) => void,
  element?: Document | HTMLElement | Window | null,
  options?: boolean | AddEventListenerOptions,
): void

export function useEventListener<
  KD extends keyof DocumentEventMap,
  KH extends keyof HTMLElementEventMap,
  KW extends keyof WindowEventMap,
>(
  type: KD | KH | KW | string,
  listener: (
    this: typeof element,
    evt: DocumentEventMap[KD] | HTMLElementEventMap[KH] | WindowEventMap[KW] | Event,
  ) => void,
  element: Document | HTMLElement | Window | null | undefined = window,
  options?: boolean | AddEventListenerOptions,
): void {
  const savedListener = useRef<EventListener>()

  useEffect(() => {
    savedListener.current = listener
  }, [listener])

  const handleEventListener = useCallback((event: Event) => {
    savedListener.current?.(event)
  }, [])

  useEffect(() => {
    const target = getRefElement(element)
    target?.addEventListener(type, handleEventListener, options)
    return () => target?.removeEventListener(type, handleEventListener)
  }, [type, element, options, handleEventListener])
}
