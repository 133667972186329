import styled from 'styled-components'

import { mainColors, shadowColors } from '../../styles/colors'
import { rgba } from '../../utils/cssUtils'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`

export const FormContent = styled.div<{ padding?: string }>`
  min-height: 150px;
  min-width: 150px;
  flex: 1;

  padding: ${p => p.padding ?? '10px 30px 50px 30px'};
  overflow: auto;

  > * + * {
    margin-top: 24px;
  }
`

export const FixedFormContent = styled(FormContent)`
  position: fixed;
  bottom: 0;
  min-width: 490px;
  padding-bottom: 0;
  padding-top: 20px;
  box-shadow: 0 6px 32px ${rgba(shadowColors.neutralBlur, 0.2)};
  border-top: 1px solid ${mainColors.neutralMedium};
  background-color: ${mainColors.white};
  height: 168px;

  > * + * {
    margin-top: 0px;
  }
`

export const FormFooter = styled.div<{ removeBorderTop?: boolean }>`
  display: flex;
  padding: 18px 30px;
  border-top: ${({ removeBorderTop }) =>
    removeBorderTop ? 'none' : `1px solid ${mainColors.neutralMedium}`};

  button {
    height: 36px;
  }

  > * + * {
    margin-left: 16px;
  }
`

export const FixedFormFooter = styled(FormFooter)`
  border-top: none;
`

export const FormGroup = styled.div<{ columns?: number; gap?: string }>`
  display: grid;
  grid-template-columns: repeat(${p => p.columns ?? 2}, 1fr);
  gap: ${p => p.gap ?? '12px'};
`
