import React, { Ref } from 'react'

function _IconInsights(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99299 3.60862C8.99299 2.72034 9.71308 2.00024 10.6014 2.00024H13.3985C14.2868 2.00024 15.0069 2.72034 15.0069 3.60862V20.1817H15.986V7.80451C15.986 6.91623 16.7061 6.19614 17.5943 6.19614H20.3915C21.2798 6.19614 21.9999 6.91623 21.9999 7.80451V20.1817H22.4893C22.9914 20.1817 23.3984 20.5887 23.3984 21.0908C23.3984 21.5929 22.9914 21.9999 22.4893 21.9999H21.1124C21.1052 22.0001 21.098 22.0001 21.0908 22.0001H16.895C16.8878 22.0001 16.8806 22.0001 16.8734 21.9999H14.1123L14.0978 22H9.90207L9.88762 21.9999H7.12173L7.1049 22H2.90915L2.89233 21.9999H1.51058C1.00851 21.9999 0.601501 21.5929 0.601501 21.0908C0.601501 20.5887 1.00851 20.1817 1.51058 20.1817H2.00007V12.0002C2.00007 11.1119 2.72016 10.3918 3.60844 10.3918H6.40561C7.29389 10.3918 8.01398 11.1119 8.01398 12.0002V20.1817H8.99299V3.60862ZM20.1817 20.1817V8.0143H17.8041V20.1817H20.1817ZM13.1887 3.8184V20.1817H10.8111V3.8184H13.1887ZM6.19582 20.1817V12.2099H3.81823V20.1817H6.19582Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconInsights = React.forwardRef(_IconInsights)
