import arriereBackoffice, {
  DistributionChannel,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { GetSuperHostDashboardProps } from '@arriere/types/Rating.types'

export default {
  async getSuperHostDashboard({ channelAccountId, sortBy, sortOrder }: GetSuperHostDashboardProps) {
    return arriereBackoffice.queries.superHostDashboard({
      __alias: 'getSuperHostDashboard',
      __args: { channelAccountId, sortBy, sortOrder },
      airbnbAccounts: {
        accountName: true,
        cancellationRate: {
          count: true,
          totalCount: true,
          value: true,
          quarterlyBreakdown: {
            count: true,
            label: true,
            totalCount: true,
            value: true,
          },
        },
        completedStays: {
          count: true,
          totalCount: true,
          value: true,
          quarterlyBreakdown: {
            label: true,
            value: true,
          },
        },
        id: true,
        isAtRiskOfLosingSuperhost: true,
        isCloseToGainingSuperhost: true,
        isCloseToLosingSuperhost: true,
        isCurrentlySuperHost: true,
        isGainingSuperhost: true,
        isLosingSuperhost: true,
        isMaintainingSuperhost: true,
        isTrendingSuperHost: true,
        numberOfFiveStarReviewsToSuperhost: true,
        overallRating: {
          value: true,
          count: true,
          quarterlyBreakdown: {
            label: true,
            value: true,
            count: true,
          },
        },
        propertyListedCount: true,
        responseRate: true,
        responseRateUpdatedAt: true,
        reviewConversionRate: {
          value: true,
        },
      },
      currentSuperHostPeriod: {
        endDate: true,
        startDate: true,
      },
      previousSuperHostPeriod: {
        endDate: true,
        startDate: true,
      },
      startNextSuperHostPeriod: true,
      totalReviewConversionRate: true,
    })
  },

  async getAirbnbAccounts() {
    return arriereBackoffice.queries
      .distribution({
        __alias: 'getAirbnbAccounts',
        getAgencyAccountCustomInfo: {
          __args: {
            channel: DistributionChannel.airbnb,
          },
          agencyAccount: {
            market: true,
            channelAccountId: true,
          },
        },
      })
      .then(res => res.getAgencyAccountCustomInfo)
  },
}
