import React, { Ref } from 'react'

function _IconConfiguration(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 10V3C5 2.44772 4.55228 2 4 2C3.44772 2 3 2.44772 3 3V10C3 10.5523 3.44772 11 4 11C4.55228 11 5 10.5523 5 10ZM4 13H1C0.447715 13 0 13.4477 0 14C0 14.5523 0.447715 15 1 15H3V21C3 21.5523 3.44772 22 4 22C4.55228 22 5 21.5523 5 21V15H7C7.55228 15 8 14.5523 8 14C8 13.4477 7.55228 13 7 13H4ZM13 12V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM9 9H12H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H13V3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V7H9C8.44771 7 8 7.44772 8 8C8 8.55228 8.44771 9 9 9ZM21 17V21C21 21.5523 20.5523 22 20 22C19.4477 22 19 21.5523 19 21V17H17C16.4477 17 16 16.5523 16 16C16 15.4477 16.4477 15 17 15H20H23C23.5523 15 24 15.4477 24 16C24 16.5523 23.5523 17 23 17H21ZM21 12V3C21 2.44772 20.5523 2 20 2C19.4477 2 19 2.44772 19 3V12C19 12.5523 19.4477 13 20 13C20.5523 13 21 12.5523 21 12Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconConfiguration = React.forwardRef(_IconConfiguration)
