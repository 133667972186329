export function asyncDebounce<F extends (...args: any[]) => ReturnType<F>>(
  func: F,
  delay: number,
  withMemo = true,
  serializer = JSON.stringify,
) {
  const cache = new Map()
  let timer: NodeJS.Timeout | undefined
  let cancel: VoidFunction
  let lastKey: string

  return (...args: Parameters<F>): ReturnType<F> => {
    const key = withMemo ? serializer.call(func, args) : 'x'
    if (timer) {
      clearTimeout(timer)
      cancel()
      if (withMemo) {
        cache.delete(lastKey)
      }
    }
    if (withMemo && cache.has(key)) {
      return cache.get(key)
    }
    const promise = new Promise((resolve, reject) => {
      cancel = reject
      timer = setTimeout(() => {
        resolve(func(...args))
        timer = undefined
      }, delay)
    }) as ReturnType<F>
    if (withMemo) {
      cache.set(key, promise)
      lastKey = key
    }
    return promise
  }
}
