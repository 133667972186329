import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconPinMap(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.21096 4.19045C5.95055 3.45085 6.95366 3.03535 7.99961 3.03535C9.04556 3.03535 10.0487 3.45085 10.7883 4.19045C11.5279 4.93005 11.9434 5.93315 11.9434 6.9791C11.9434 8.5043 10.9472 10.0047 9.82462 11.1896C9.27662 11.7681 8.72641 12.2421 8.31248 12.5717C8.1959 12.6645 8.09055 12.7456 7.99961 12.8139C7.90867 12.7456 7.80332 12.6645 7.68674 12.5717C7.27281 12.2421 6.7226 11.7681 6.1746 11.1896C5.05203 10.0047 4.05586 8.5043 4.05586 6.9791C4.05586 5.93315 4.47136 4.93005 5.21096 4.19045ZM7.63872 14.1551C7.63889 14.1552 7.63905 14.1554 7.99961 13.6145C8.36016 14.1554 8.36077 14.1549 8.36094 14.1548C8.14261 14.3004 7.85705 14.3007 7.63872 14.1551ZM7.63872 14.1551L7.99961 13.6145L8.36094 14.1548L8.36215 14.154L8.3658 14.1516L8.378 14.1433L8.42129 14.1136C8.45826 14.088 8.5111 14.0509 8.57757 14.0029C8.71042 13.9069 8.89807 13.7672 9.12229 13.5887C9.56968 13.2324 10.1679 12.7175 10.7684 12.0837C11.9427 10.8442 13.2434 9.02682 13.2434 6.9791C13.2434 5.58837 12.6909 4.2546 11.7075 3.27121C10.7241 2.28782 9.39034 1.73535 7.99961 1.73535C6.60888 1.73535 5.27511 2.28782 4.29172 3.27121C3.30832 4.2546 2.75586 5.58837 2.75586 6.9791C2.75586 9.02682 4.05657 10.8442 5.23087 12.0837C5.83131 12.7175 6.42954 13.2324 6.87693 13.5887C7.10115 13.7672 7.28879 13.9069 7.42165 14.0029C7.48812 14.0509 7.54096 14.088 7.57793 14.1136L7.62122 14.1433L7.63342 14.1516L7.63707 14.154L7.63872 14.1551ZM7.11836 6.9791C7.11836 6.4924 7.51291 6.09785 7.99961 6.09785C8.48631 6.09785 8.88086 6.4924 8.88086 6.9791C8.88086 7.4658 8.48631 7.86035 7.99961 7.86035C7.51291 7.86035 7.11836 7.4658 7.11836 6.9791ZM7.99961 4.79785C6.79494 4.79785 5.81836 5.77443 5.81836 6.9791C5.81836 8.18377 6.79494 9.16035 7.99961 9.16035C9.20428 9.16035 10.1809 8.18377 10.1809 6.9791C10.1809 5.77443 9.20428 4.79785 7.99961 4.79785Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconPinMap = forwardRef(_IconPinMap)
