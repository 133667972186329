import styled, { css } from 'styled-components'

import { Checkbox } from '../../components/Checkbox'
import { FilterAccordion } from '../../components/FiltersButton'
import { mainColors } from '../../styles/colors'
import { _M_Bold, _M_Normal } from '../../styles/typography'
import { ellipsis } from '../../styles/utils'
import { rgba } from '../../utils/cssUtils'

const bottomShadowContainerStyle = css`
  &::after {
    background: linear-gradient(0deg, ${mainColors.white} 20%, ${rgba(mainColors.white, 0)} 100%);
    bottom: 0;
    content: '';
    height: 28px;
    left: 0;
    position: absolute;
    right: 16px;
  }
`

const scrollableContainerStyle = css`
  margin: -18px -15px -10px;
  max-height: 28rem;
  overflow: auto;
  padding: 0 15px 10px;
`

export const Container = styled(FilterAccordion)<{
  hasBottomShadow: boolean
  isScrollable: boolean
}>`
  ${p => p.hasBottomShadow && bottomShadowContainerStyle};
  ${p => p.isScrollable && scrollableContainerStyle};
`

export const EmptyResults = styled.div`
  ${_M_Normal};
  color: ${mainColors.neutralExtraDark};
  display: flex;
  justify-content: center;
  padding: 0 4px 14px;
`

export const LineSeparator = styled.div`
  background-color: ${mainColors.neutralMedium};
  height: 1px;
`

export const CheckboxRow = styled(Checkbox.Regular)`
  label:last-of-type {
    ${ellipsis};
  }
`

export const GroupLabel = styled.span`
  ${_M_Bold};
  color: ${mainColors.neutralExtremelyDark};
  line-height: 32px;
`
