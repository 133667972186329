import React, { useRef, useState } from 'react'
import MaskedInput from 'react-text-mask'

import { IconError } from '@avantstay/backoffice-vectors'

import { semanticColors } from '../../styles'
import { removeMask } from '../../utils'
import { InfoMessage } from '../TextField/TextField.styles'
import * as S from './ToggleInputType.styles'
import { ToggleInputTypeProps } from './ToggleInputType.types'
import { ToggleType } from './ToggleType'

// Keep this export clean, free of wrapping HOCs
function ToggleInputType<InputType>({
  className,
  inputType,
  value,
  typeOptions,
  error,
  warning,
  extraInfo,
  onCleanValueClick,
  onTypeChange,
  onValueChange,
  placeholder,
  $isTiny,
  small,
}: ToggleInputTypeProps<InputType>) {
  const [focus, setFocus] = useState(false)

  const inputRef = useRef<MaskedInput | null>(null)

  const size = { $isTiny, small }

  const cleanInput = () =>
    onCleanValueClick ? (
      <S.ClearButton data-testid="clean-icon" width={16} onClick={onCleanValueClick} />
    ) : null

  const handleNextType = (clickedType?: InputType) => {
    if (inputType === clickedType) {
      const newType = typeOptions.filter(option => option.value !== clickedType)[0]?.value
      onTypeChange(newType)
    } else {
      onTypeChange(clickedType)
    }
  }

  return (
    <S.Wrapper>
      <S.ToggleInputRoot
        className={className}
        error={error}
        warning={warning}
        focus={focus}
        {...size}
      >
        <ToggleType
          inputType={inputType}
          typeOptions={typeOptions}
          onChange={handleNextType}
          {...size}
        />
        {extraInfo}

        {typeOptions.map((inputOption, index) =>
          inputType === inputOption.value ? (
            <S.Input
              key={`input-${index}`}
              data-testid="simple-toggle-input"
              data-cy="add-adjustment-price-input"
              mask={inputOption.mask}
              onChange={event => onValueChange(removeMask(event.currentTarget.value))}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              placeholderChar="0"
              ref={inputRef}
              value={value}
              placeholder={placeholder}
              {...size}
            />
          ) : null,
        )}

        {error || warning ? (
          <S.AlertIconWrapper>
            <IconError
              color={warning ? semanticColors.warningMedium : semanticColors.failureMedium}
            />
          </S.AlertIconWrapper>
        ) : null}

        {cleanInput()}
      </S.ToggleInputRoot>

      {error || warning ? (
        <InfoMessage $hasError={Boolean(error)} $hasWarning={Boolean(warning)}>
          {error || warning}
        </InfoMessage>
      ) : null}
    </S.Wrapper>
  )
}

function Tiny<InputType>(props: ToggleInputTypeProps<InputType>) {
  return <ToggleInputType {...props} $isTiny />
}

function Small<InputType>(props: ToggleInputTypeProps<InputType>) {
  return <ToggleInputType {...props} small />
}

ToggleInputType.Default = ToggleInputType
ToggleInputType.Small = Small
ToggleInputType.Tiny = Tiny

// If you need to wrap this component with HOCs, use them here
export { ToggleInputType }
