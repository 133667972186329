import * as React from 'react'
import { PropsWithChildren } from 'react'

import { trackError } from '@tracking/customEventReport'

const ErrorScreen = React.lazy(() => import('@screens/Error.screen'))

interface ErrorBoundaryState {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<PropsWithChildren, ErrorBoundaryState> {
  constructor(props: PropsWithChildren) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: any) {
    trackError(error, { error }, false)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorScreen />
    }

    return this.props.children
  }
}
