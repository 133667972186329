import React, { Ref } from 'react'

function _IconEditFile(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} ref={ref}>
      <circle cx="10" cy="10" r="8.75" fill="#041F32" stroke="white" strokeWidth="1.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3167 7.19961C12.2651 7.19961 12.214 7.20977 12.1663 7.22951C12.1187 7.24925 12.0754 7.27818 12.0389 7.31466L7.64218 11.7114L7.43386 12.4752L8.19769 12.2669L12.5944 7.87018C12.6309 7.8337 12.6598 7.7904 12.6796 7.74274C12.6993 7.69508 12.7095 7.644 12.7095 7.59242C12.7095 7.54083 12.6993 7.48975 12.6796 7.4421C12.6598 7.39444 12.6309 7.35114 12.5944 7.31466C12.5579 7.27818 12.5146 7.24925 12.467 7.22951C12.4193 7.20977 12.3682 7.19961 12.3167 7.19961ZM11.7454 6.21324C11.9265 6.13822 12.1206 6.09961 12.3167 6.09961C12.5127 6.09961 12.7068 6.13822 12.8879 6.21324C13.069 6.28826 13.2336 6.39822 13.3722 6.53684C13.5108 6.67546 13.6208 6.84003 13.6958 7.02115C13.7709 7.20226 13.8095 7.39638 13.8095 7.59242C13.8095 7.78846 13.7709 7.98257 13.6958 8.16369C13.6208 8.34481 13.5108 8.50937 13.3722 8.64799L8.87223 13.148C8.80455 13.2157 8.72038 13.2645 8.62804 13.2897L6.7947 13.7897C6.60429 13.8416 6.40064 13.7876 6.26108 13.648C6.12152 13.5084 6.06744 13.3048 6.11937 13.1144L6.61937 11.281C6.64455 11.1887 6.6934 11.1045 6.76108 11.0368L11.2611 6.53684C11.3997 6.39822 11.5643 6.28826 11.7454 6.21324Z"
        fill="white"
      />
    </svg>
  )
}

export const IconEditFile = React.forwardRef(_IconEditFile)
