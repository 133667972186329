import styled from 'styled-components'

import { FloatingContainer } from '@avantstay/avantstay-ui'

import { mainColors, semanticColors, shadowColors } from '../../styles/colors'
import { medias } from '../../styles/medias'
import { _L_Medium, _M_Normal } from '../../styles/typography'
import { rgba } from '../../utils/cssUtils'
import {
  ConfirmActionStyledProps,
  ConfirmActionTheme,
  ContainerVerticalAlignment,
  FloatingContainerProps,
} from './ConfirmAction.types'

export const Container = styled.div``

export const FloatingContainerStyled = styled(FloatingContainer)<FloatingContainerProps>`
  ${props =>
    props.customVerticalAlignment === ContainerVerticalAlignment.top &&
    `
      top: calc(${props.verticalValue}px - 168px) !important;
  `}

  ${props =>
    props.customVerticalAlignment === ContainerVerticalAlignment.bottom &&
    `
      ${medias.MD_SCREEN`
        top: calc(${props.verticalValue}px + 44px);
      `}
  `}
`

export const FloatingContainerMobile = styled(FloatingContainer)<FloatingContainerProps>`
  background: ${rgba(mainColors.primaryMedium, 0.4)};
  position: fixed !important;
  width: 100%;
  height: 100%;
  top: 0 !important;
  left: 0 !important;
  display: flex !important;
  justify-content: center;
  align-items: flex-end;
  ${({ customVerticalAlignment }) =>
    customVerticalAlignment === ContainerVerticalAlignment.center && `align-items: center;`}
`

export const ChildrenContainer = styled.div`
  cursor: pointer;
`

export const ConfirmActionContainer = styled.div<{
  customVerticalAlignment?: ContainerVerticalAlignment
}>`
  display: flex;
  flex-flow: column;
  width: 100%;
  background: ${mainColors.white};
  border: solid 1px ${mainColors.neutralLight};
  border-radius: 6px 6px 0 0;
  box-shadow: 0 4px 16px 0 ${rgba(shadowColors.blur, 0.4)};

  ${({ customVerticalAlignment }) =>
    customVerticalAlignment === ContainerVerticalAlignment.center && `border-radius: 6px;`}

  ${medias.MD_SCREEN`
    max-width: 385px;
    border-radius: 6px;
  `}
`

export const AlertContainer = styled.div`
  display: grid;
  gap: 20px;
  padding: 20px 16px;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  ${medias.MD_SCREEN`
    gap: 8px;
    padding: 16px 20px;
  `}
`

export const AlertHeader = styled.div<ConfirmActionStyledProps>`
  display: flex;
  align-items: center;
  color: ${mainColors.accentMedium};

  ${({ theme }) => theme === ConfirmActionTheme.danger && `color: ${semanticColors.failureMedium}`};
`

export const AlertTitle = styled.div`
  ${_L_Medium};
`

export const AlertMessage = styled.div`
  ${_M_Normal};
  white-space: normal;
  line-height: 1.4;
  color: ${mainColors.neutralExtraDark};
`

export const AlertActions = styled.div<ConfirmActionStyledProps>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  ${medias.MD_SCREEN`
    margin-top: 8px;
  `}
`
