import React, { Ref } from 'react'

function _IconHistoryClock(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.125 9C16.125 12.935 12.935 16.125 9 16.125C5.06497 16.125 1.875 12.935 1.875 9C1.875 5.06497 5.06497 1.875 9 1.875C12.935 1.875 16.125 5.06497 16.125 9ZM9 17.75C13.8325 17.75 17.75 13.8325 17.75 9C17.75 4.16751 13.8325 0.25 9 0.25C4.16751 0.25 0.25 4.16751 0.25 9C0.25 13.8325 4.16751 17.75 9 17.75ZM9.81251 5.25012C9.81251 4.80139 9.44874 4.43762 9.00001 4.43762C8.55128 4.43762 8.18751 4.80139 8.18751 5.25012V9.62512C8.18751 9.93287 8.36138 10.2142 8.63665 10.3518L11.5533 11.8102C11.9547 12.0109 12.4427 11.8482 12.6434 11.4468C12.8441 11.0455 12.6814 10.5574 12.28 10.3567L9.81251 9.12297V5.25012Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconHistoryClock = React.forwardRef(_IconHistoryClock)
