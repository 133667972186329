import React from 'react'

import { Loading } from '../Loading'
import {
  ButtonDanger,
  ButtonDefault,
  ButtonDropdown,
  ButtonFlat,
  ButtonFlatDanger,
  ButtonFlatNeutral,
  ButtonMore,
  ButtonNeutral,
  ButtonPrimary,
  ButtonRoundedOutline,
  ButtonSecondary,
  ButtonSuccess,
  ButtonTertiary,
} from './Button.styles'
import { ButtonProps, ButtonType } from './Button.types'

const createButtonComponent =
  (Component: typeof ButtonDefault | typeof ButtonPrimary) =>
  ({ loading, children, isLoadingStateAbsolute = true, ...props }: ButtonProps) => (
    <Component isLoading={loading} {...props}>
      {loading ? <Loading size="4px" absolute={isLoadingStateAbsolute} /> : children}
    </Component>
  )

const Button = {
  [ButtonType.Default]: createButtonComponent(ButtonDefault),
  [ButtonType.Primary]: createButtonComponent(ButtonPrimary),
  [ButtonType.Secondary]: createButtonComponent(ButtonSecondary),
  [ButtonType.Tertiary]: createButtonComponent(ButtonTertiary),
  [ButtonType.Danger]: createButtonComponent(ButtonDanger),
  [ButtonType.Success]: createButtonComponent(ButtonSuccess),
  [ButtonType.Neutral]: createButtonComponent(ButtonNeutral),
  [ButtonType.Flat]: createButtonComponent(ButtonFlat),
  [ButtonType.FlatNeutral]: createButtonComponent(ButtonFlatNeutral),
  [ButtonType.FlatDanger]: createButtonComponent(ButtonFlatDanger),
  [ButtonType.More]: createButtonComponent(ButtonMore),
  [ButtonType.Dropdown]: createButtonComponent(ButtonDropdown),
  [ButtonType.RoundedOutline]: createButtonComponent(ButtonRoundedOutline),
}

export { Button }
