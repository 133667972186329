import React, { Ref } from 'react'

function _IconExpand(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6875 4.1228C18.6875 3.70859 19.0233 3.3728 19.4375 3.3728C19.8517 3.3728 20.1875 3.70859 20.1875 4.1228V19.8728C20.1875 20.287 19.8517 20.6228 19.4375 20.6228C19.0233 20.6228 18.6875 20.287 18.6875 19.8728V4.1228ZM13.7197 8.65497C13.4268 8.94787 13.4268 9.42274 13.7197 9.71563L15.2518 11.2478H4.5625C4.14829 11.2478 3.8125 11.5836 3.8125 11.9978C3.8125 12.412 4.14829 12.7478 4.5625 12.7478H15.2518L13.7197 14.28C13.4268 14.5729 13.4268 15.0477 13.7197 15.3406C14.0126 15.6335 14.4874 15.6335 14.7803 15.3406L17.5925 12.5284C17.7284 12.3927 17.8125 12.2051 17.8125 11.9978C17.8125 11.7948 17.7318 11.6106 17.6008 11.4756L17.5928 11.4675L14.7803 8.65497C14.4874 8.36208 14.0126 8.36208 13.7197 8.65497Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconExpand = React.forwardRef(_IconExpand)
