import { forwardRef, Ref, SVGProps } from 'react'

const _IconRoundCheck = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <g id="roundcheck/checked">
        <path
          id="Oval"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          id="Path (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0608 8.42846C18.376 8.73805 18.3805 9.24456 18.071 9.55979L11.196 16.5598C11.0455 16.7129 10.8399 16.7992 10.6252 16.7992C10.4105 16.7992 10.2049 16.7129 10.0544 16.5598L6.92944 13.378C6.61984 13.0627 6.62441 12.5562 6.93963 12.2466C7.25485 11.937 7.76136 11.9416 8.07095 12.2568L10.6252 14.8575L16.9294 8.43865C17.239 8.12343 17.7455 8.11887 18.0608 8.42846Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export const IconRoundCheck = forwardRef(_IconRoundCheck)
