import React, { Ref } from 'react'

function _IconClock(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 4.66699C7.94975 4.66699 4.6665 7.95024 4.6665 12.0003C4.6665 16.0504 7.94975 19.3337 11.9998 19.3337C16.0499 19.3337 19.3332 16.0504 19.3332 12.0003C19.3332 7.95024 16.0499 4.66699 11.9998 4.66699ZM2.6665 12.0003C2.6665 6.84567 6.84518 2.66699 11.9998 2.66699C17.1545 2.66699 21.3332 6.84567 21.3332 12.0003C21.3332 17.155 17.1545 21.3337 11.9998 21.3337C6.84518 21.3337 2.6665 17.155 2.6665 12.0003Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C12.5523 6 13 6.44772 13 7V11.382L15.7805 12.7722C16.2745 13.0192 16.4748 13.6199 16.2278 14.1139C15.9808 14.6079 15.3801 14.8081 14.8861 14.5611L11.5528 12.8944C11.214 12.725 11 12.3788 11 12V7C11 6.44772 11.4477 6 12 6Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconClock = React.forwardRef(_IconClock)
