import styled, { css, keyframes } from 'styled-components'

import { IconClose2 } from '@avantstay/backoffice-vectors'

import {
  _2XL_Bold,
  _XL_Bold,
  HEADER_HEIGHT,
  HEADER_WITH_SECONDARY_MENU_HEIGHT,
  mainColors,
  medias,
  shadowColors,
  zIndex,
} from '../../styles'
import { rgba } from '../../utils'
import { AttachmentBarContainerProps } from './AttachmentBar.types'

export const CONTAINER_WIDTH = '441px'

const slideIn = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(0%); }
`

const slideOut = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(100%); }
`

const containerWithFilterStyle = css`
  background: ${mainColors.neutralExtraLight};
  border-bottom: 1px solid ${mainColors.neutralMedium};
`

export const Container = styled.div<AttachmentBarContainerProps>`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: ${zIndex.FIXED_ACTIONS_MOBILE_CONTAINER};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  box-shadow: -9px 4px 50px ${rgba(shadowColors.blur, 0.3)};
  background: ${mainColors.white};
  border-left: 1px solid ${mainColors.neutralLight};

  ${medias.XS_SCREEN`
    animation-duration: 0.25s;
  `}

  ${medias.LG_SCREEN`
    z-index: ${zIndex.PORTAL};
  `}

  ${({ screenHasSecondaryMenu, hasTransitionAnimation, width }) => medias.LG_SCREEN`
    width: ${width ? `${width}px` : CONTAINER_WIDTH};

    height: calc(100% - ${
      screenHasSecondaryMenu ? HEADER_WITH_SECONDARY_MENU_HEIGHT : HEADER_HEIGHT
    });

    top: ${screenHasSecondaryMenu ? HEADER_WITH_SECONDARY_MENU_HEIGHT : HEADER_HEIGHT};

    animation-duration: ${hasTransitionAnimation ? '0.25s' : '0s'};
  `}



  animation-duration: ${p => (p.hasTransitionAnimation ? '0.25s' : '0s')};
  animation-fill-mode: ${p => (p.unmounting ? 'forwards' : 'backwards')};
  animation-name: ${p => (p.unmounting ? slideOut : slideIn)};
`

export const Header = styled.div<{ hasFilter?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  padding: 30px 15px 20px;
  flex-wrap: wrap;
  position: relative;

  ${p => p.hasFilter && containerWithFilterStyle};

  ${medias.MD_SCREEN`
    justify-content: space-between;
    flex-direction: row;
    padding: 20px 30px;
  `}
`

export const Title = styled.h2`
  ${_2XL_Bold};
  line-height: 26px;
  color: ${mainColors.black};
  width: 100%;
  flex-grow: 1;

  ${medias.MD_SCREEN`
    ${_XL_Bold};
    line-height: 24px;
    width: auto;
  `}
`

export const CloseButton = styled(IconClose2)`
  position: absolute;
  right: 20px;
  top: 28px;
  height: 30px;
  width: 30px;
  color: ${mainColors.neutralExtraDark};
  cursor: pointer;

  &:hover {
    color: ${mainColors.neutralExtremelyDark};
  }

  ${medias.MD_SCREEN`
    position: relative;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    margin: 0;

  `}
`

export const FilterContainer = styled.div`
  width: 100%;
  margin: -10px 0 -15px;

  & > div > div {
    padding: 13px 20px;
  }

  ${medias.MD_SCREEN`
    width: auto;
    transform: translate(25px, -10px);

      & > div > div {
        padding: 5px;
      }
  `}
`

export const Footer = styled.div<{ width?: number }>`
  border-top: 1px solid ${mainColors.neutralMedium};
  padding: 20px 0;
  width: 100%;

  ${({ width }) => medias.MD_SCREEN`
    width: ${width ? `${width}px` : CONTAINER_WIDTH};
  `}
`
