import React, { forwardRef, Ref, SVGProps } from 'react'

export const IconHouseKeeping = forwardRef(
  (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.92546 11.2764C3.42651 11.0931 3.98498 11.1608 4.42773 11.4585C4.87047 11.7561 5.14397 12.2477 5.16342 12.7809L5.30227 16.5859C5.32088 17.0959 5.7397 17.4997 6.25001 17.4997C6.76032 17.4997 7.17914 17.0959 7.19775 16.5859L7.33659 12.7809C7.35605 12.2477 7.62955 11.7561 8.07229 11.4585C8.51504 11.1608 9.07351 11.0931 9.57455 11.2764C9.64261 11.3012 9.70946 11.3259 9.77331 11.3494L9.77444 11.3498C9.85236 11.3785 9.9253 11.4053 9.99449 11.4306C9.94263 10.3169 9.53121 9.62069 9.00386 9.17465C8.39957 8.66354 7.46261 8.33301 6.25001 8.33301C5.03741 8.33301 4.10045 8.66354 3.49616 9.17465C2.9688 9.62069 2.55739 10.3169 2.50553 11.4306C2.57472 11.4053 2.64766 11.3785 2.72558 11.3498L2.72837 11.3488C2.79171 11.3254 2.85799 11.301 2.92546 11.2764ZM3.49787 12.8416C3.43179 12.8658 3.36662 12.8898 3.30238 12.9135C2.73575 13.1222 2.24157 13.3042 1.84791 13.3299C1.20936 13.3716 0.833344 13.0027 0.833344 11.6721C0.833344 9.50044 1.8351 8.05251 3.34514 7.29508C4.18461 6.87402 5.18115 6.66634 6.25001 6.66634C7.31887 6.66634 8.31541 6.87402 9.15487 7.29508C10.6649 8.05251 11.6667 9.50044 11.6667 11.6721C11.6667 13.0027 11.2907 13.3716 10.6521 13.3299C10.2585 13.3042 9.76501 13.1225 9.19838 12.9137C9.13407 12.8901 9.06831 12.8658 9.00215 12.8416L8.8633 16.6467C8.81199 18.0529 7.65714 19.1663 6.25001 19.1663C4.84288 19.1663 3.68803 18.0529 3.63672 16.6467L3.49787 12.8416ZM3.80133 5.3349C3.50528 4.87852 3.33334 4.33418 3.33334 3.74967C3.33334 2.13884 4.63918 0.833008 6.25001 0.833008C7.86084 0.833008 9.16668 2.13884 9.16668 3.74967C9.16668 4.33418 8.99474 4.87852 8.69869 5.3349C8.17885 6.13624 7.27633 6.66634 6.25001 6.66634C5.22368 6.66634 4.32117 6.13624 3.80133 5.3349ZM6.25001 4.99967C6.94037 4.99967 7.50001 4.44003 7.50001 3.74967C7.50001 3.05932 6.94037 2.49967 6.25001 2.49967C5.55965 2.49967 5.00001 3.05932 5.00001 3.74967C5.00001 4.44003 5.55965 4.99967 6.25001 4.99967Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6667 2.49967C16.6667 2.03944 16.2936 1.66634 15.8333 1.66634C15.3731 1.66634 15 2.03944 15 2.49967V11.7919C13.139 12.3944 12.5 15.094 12.5 18.333C12.5 18.6152 12.5088 18.8933 12.5258 19.1663H19.1409C19.1579 18.8933 19.1667 18.6152 19.1667 18.333C19.1667 15.094 18.5276 12.3944 16.6667 11.7919V2.49967ZM14.7569 14.2785C14.4345 15.0267 14.2383 16.1262 14.1829 17.4997H17.4838C17.4284 16.1262 17.2322 15.0267 16.9098 14.2785C16.7241 13.8478 16.5326 13.6186 16.3834 13.5016C16.2586 13.4037 16.1026 13.333 15.8333 13.333C15.5641 13.333 15.408 13.4037 15.2833 13.5016C15.1341 13.6186 14.9425 13.8478 14.7569 14.2785Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
