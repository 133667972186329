import convertTime from 'convert-time'
import React from 'react'

import { TextField } from '@avantstay/backoffice-core'

// eslint-disable-next-line import/no-cycle
import { jsonParseCustomFieldValue } from '@utils/customFields'
import { addZeroToTimeString } from '@utils/strings'

import {
  CustomFieldProps,
  CustomFieldTypeReturn,
  IInputProps,
  IMountComponentProps,
} from '../_types'
import * as S from './types.styles'

const MASK = [/\d/, /\d/, ':', /\d/, /\d/, ' ', /[A-Za-z]/, /[A-Za-z]/]
const TIME_PATTERN = /^(1[0-2]|0?[1-9]):([0-5][0-9]) [APap][Mm]$/

const parseValueToShow = (customField: CustomFieldProps) => {
  const value = jsonParseCustomFieldValue(customField)

  if (!value) return null

  return TIME_PATTERN.test(convertTime(value))
    ? addZeroToTimeString(convertTime(value))
    : JSON.parse(value)
}

const parseValueToSave = (customField: CustomFieldProps) => {
  return customField.value
    ? JSON.stringify(addZeroToTimeString(convertTime(customField.value)))
    : JSON.stringify(null)
}

function ComponentReadMode({ customField }: IMountComponentProps) {
  if (customField?.value) {
    return <S.Text>{JSON.parse(customField.value)}</S.Text>
  }

  return <></>
}

function ComponentWriteMode({
  customField,
  inputProps,
  error,
  warning,
  isReadOnly,
  disabled,
}: IMountComponentProps) {
  if (isReadOnly) return <ComponentReadMode customField={customField} />

  return (
    <TextField.Masked
      placeholder="__:__ AM/PM"
      {...(inputProps as IInputProps)}
      mask={MASK}
      error={error}
      warning={warning}
      disabled={disabled}
    />
  )
}

export default {
  read: { Component: ComponentReadMode },
  write: { Component: ComponentWriteMode },
  parseValueToShow,
  parseValueToSave,
} as CustomFieldTypeReturn
