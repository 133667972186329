import isEmpty from 'lodash/isEmpty'
import React from 'react'

import { MarkdownField, MarkdownPreview } from '@avantstay/backoffice-core'

import * as S from '@new/__global/components/CustomField/types/types.styles'

import { jsonParseCustomFieldValue } from '@utils/customFields'

import {
  CustomFieldProps,
  CustomFieldTypeReturn,
  IInputProps,
  IMountComponentProps,
} from '../_types'

function ComponentReadMode({ customField }: IMountComponentProps) {
  const source = typeof customField.value === 'string' ? JSON.parse(customField?.value) : ''

  return (
    <S.Markdown data-testid="md-editor">
      <MarkdownPreview source={source} />
    </S.Markdown>
  )
}

function ComponentWriteMode({
  inputProps,
  error,
  warning,
  customField,
  isReadOnly,
  disabled,
}: IMountComponentProps) {
  if (isReadOnly) return <ComponentReadMode customField={customField} />

  return (
    <S.Markdown>
      <MarkdownField
        {...(inputProps as IInputProps)}
        error={error}
        warning={warning}
        isWriteModeDisabled={disabled}
      />
    </S.Markdown>
  )
}

const parseValueToShow = (customField: CustomFieldProps) => {
  const value = jsonParseCustomFieldValue(customField)

  return isEmpty(value) ? '' : value
}

const parseValueToSave = (customField: CustomFieldProps) => {
  return JSON.stringify(customField.value)
}

export default {
  read: { Component: ComponentReadMode },
  write: { Component: ComponentWriteMode },
  parseValueToShow,
  parseValueToSave,
} as CustomFieldTypeReturn
