import React, { Ref } from 'react'

function _IconReload(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        d="M15.3335 2.66797V6.66797H11.3335"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.666504 13.332V9.33203H4.6665"
        stroke="white"
        stroke-Width="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33984 5.99842C2.67795 5.04295 3.25259 4.18869 4.01015 3.51537C4.7677 2.84205 5.68348 2.3716 6.67203 2.14793C7.66058 1.92426 8.68967 1.95465 9.6633 2.23627C10.6369 2.51789 11.5233 3.04157 12.2398 3.75842L15.3332 6.66509M0.666504 9.33176L3.75984 12.2384C4.47634 12.9553 5.36275 13.479 6.33638 13.7606C7.31 14.0422 8.3391 14.0726 9.32765 13.8489C10.3162 13.6252 11.232 13.1548 11.9895 12.4815C12.7471 11.8082 13.3217 10.9539 13.6598 9.99842"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconReload = React.forwardRef(_IconReload)
