import { debounce } from 'lodash'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
// @ts-ignore
import StackEdit from 'stackedit-js'

import { IconBold, IconItalic, IconPreformatted, IconQuote } from '@avantstay/backoffice-vectors'

import { getNewUuid } from '../../utils'
import { Tooltip, TooltipTheme } from '../Tooltip'
import { MarkdownFieldProps } from './_types'
import * as S from './MarkdownField.styles'
import { MarkdownPreview } from './MarkdownPreview'

const formattingActions = [
  { sampleCode: '**bold**', icon: IconBold },
  { sampleCode: '_italic_', icon: IconItalic },
  { sampleCode: '```preformatted```', icon: IconPreformatted },
  { sampleCode: '>quote', icon: IconQuote },
]

// Keep this export clean, free of wrapping HOCs
export const MarkdownField = ({
  error,
  value,
  placeholder,
  onChange,
  isWriteModeDisabled = false,
}: MarkdownFieldProps) => {
  const [showPreview, setShowPreview] = useState(false)
  const el = useRef<HTMLTextAreaElement | null>(null)
  const [id] = useState(`${getNewUuid()}-textarea`)
  const [focus, setFocus] = useState(false)
  const [currentHeight, setCurrentHeight] = useState('')
  const stackEditRef = useRef(new StackEdit())

  const DEBOUNCE_TIME = 300
  const debouncedOnChange = debounce(onChange, DEBOUNCE_TIME)

  const handleOpenPreviewMode = () => {
    setShowPreview(true)
  }

  const handleClosePreviewMode = () => {
    setShowPreview(false)
  }

  const handleOpenEditor = () => {
    setShowPreview(false)

    setTimeout(() => {
      stackEditRef.current.openFile({
        name: 'Filename',
        content: {
          text: el.current ? el.current.value : '',
          properties: {
            showEditor: true,
          },
        },
      })
    }, 0)
  }

  useEffect(() => {
    if (isWriteModeDisabled) setShowPreview(true)
  }, [isWriteModeDisabled])

  useEffect(() => {
    stackEditRef.current.on(
      'fileChange',
      ({ content: { text } }: { content: { text: string } }) => {
        if (el.current) {
          el.current.value = text
        }
        onChange({ target: { value: text } } as ChangeEvent<HTMLTextAreaElement>)
      },
    )
  }, [el, id, onChange, stackEditRef])

  useEffect(() => {
    if (!showPreview) {
      el.current!.style.height = '0px'
      const { scrollHeight } = el.current!
      el.current!.style.height = `${scrollHeight}px`
    }
  }, [showPreview, currentHeight])

  const getActionButtons = () =>
    // prettier-ignore
    formattingActions.map(({ icon: Icon, sampleCode }, index) => (
          <Tooltip placement="top" key={index} content={<><strong>Use: </strong> {sampleCode}</>}>
        <S.IconWrapper><Icon /></S.IconWrapper>
      </Tooltip>
    ))

  return (
    <Tooltip content={error} theme={TooltipTheme.danger} arrow placement="bottom-end">
      <S.Container data-testid="md-editor" focus={focus} preview={showPreview} error={error}>
        <S.ActionButtonsContainer preview={showPreview} error={error}>
          <S.ViewSwitch>
            <S.ViewSwitchButton
              type="button"
              onClick={handleClosePreviewMode}
              active={!showPreview}
              disabled={isWriteModeDisabled}
            >
              Write
            </S.ViewSwitchButton>
            <S.ViewSwitchButton type="button" onClick={handleOpenPreviewMode} active={showPreview}>
              Preview
            </S.ViewSwitchButton>
          </S.ViewSwitch>
          <S.Actions>
            <S.Formatting>{getActionButtons()}</S.Formatting>
            <S.ActionButton type="button" onClick={handleOpenEditor} disabled={isWriteModeDisabled}>
              <S.Fullscreen />
            </S.ActionButton>
          </S.Actions>
        </S.ActionButtonsContainer>
        {showPreview ? (
          <S.PreviewRoot>
            <MarkdownPreview source={value} />
          </S.PreviewRoot>
        ) : (
          <S.MarkdownTextArea
            id={id}
            ref={el}
            defaultValue={value}
            placeholder={placeholder}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              e.persist()
              debouncedOnChange(e)
              setCurrentHeight(e.target.value)
            }}
          />
        )}
      </S.Container>
    </Tooltip>
  )
}
