import styled, { keyframes } from 'styled-components'

import { Direction } from './SlidingDrawer.types'
import { rgba } from '../../utils'
import { _S_Medium, mainColors, semanticColors, zIndex } from '../../styles'
import { IconClose } from '@avantstay/backoffice-vectors'

const drawerSlideUpEnter = keyframes`
  0% { transform: translateY(100%); }
  100% { transform: translateY(0%); }
`

const drawerSlideUpOut = keyframes`
  0% { transform: translateY(0%); }
  100% { transform: translateY(100%); }
`

const drawerSlideDownEnter = keyframes`
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0%); }
`

const drawerSlideDownOut = keyframes`
  0% { transform: translateY(0%); }
  100% { transform: translateY(-100%); }
`

const backgroundFadeIn = keyframes`
  0% {
    background: ${rgba(mainColors.primaryMedium, 0)};
  }
`

const backgroundFadeOut = keyframes`
  100% {
    background: ${rgba(mainColors.primaryMedium, 0)};
  }
`

export const SlidingDrawerRoot = styled.div<{
  unmounting: boolean
  topOffset?: number
}>`
  pointer-events: ${p => (p.unmounting ? 'none' : 'all')};
  position: fixed;
  top: ${p => p.topOffset || 0}px !important;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: ${zIndex.GLOBAL_LOADING};
  cursor: auto;
`

export const SlidingDrawerContainer = styled.div<{
  unmounting: boolean
  direction?: Direction
}>`
  position: absolute;
  left: 0;
  right: 0;
  width: 100vw;
  background: ${mainColors.white};
  ${p => p.direction === Direction.Up && 'bottom: 0'};
  ${p => p.direction === Direction.Down && 'top: 0'};
  ${p => p.direction === Direction.Up && 'border-radius: 6px 6px 0 0'};
  ${p => p.direction === Direction.Down && 'border-radius: 0 0 6px 6px'};

  animation-duration: 0.25s;
  animation-fill-mode: ${p => (p.unmounting ? 'forwards' : 'backwards')};
  animation-name: ${p =>
    p.unmounting
      ? p.direction === Direction.Up
        ? drawerSlideUpOut
        : drawerSlideDownOut
      : p.direction === Direction.Up
        ? drawerSlideUpEnter
        : drawerSlideDownEnter};
  z-index: 14;
`

export const Background = styled.div<{ unmounting: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 13;
  background: ${rgba(mainColors.primaryMedium, 0.4)};

  animation-duration: 0.25s;
  animation-name: ${p => (p.unmounting ? backgroundFadeOut : backgroundFadeIn)};
  animation-fill-mode: ${p => (p.unmounting ? 'forwards' : 'backwards')};
`

export const ButtonClose = styled(IconClose)`
  position: absolute;
  top: 24px;
  right: 12px;
  width: 30px;
  height: 30px;
  color: ${mainColors.neutralExtraDark};
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`

export const MenuItemsContainer = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & .MenuItem {
    padding: 0 24px;
  }
`

export const MenuItemButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 0 24px;
  padding: 0;
  background: none;
  border: none;
  color: ${mainColors.neutralExtremelyDark};
  justify-content: space-between;
  width: 100%;

  ${p => p.disabled && 'opacity: 0.3;'}

  svg {
    width: 24px;
    height: 24px;
  }

  div {
    display: flex;
    align-items: center;
  }
`

export const News = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
  ${_S_Medium};
  background-color: ${semanticColors.failureDark};
  color: ${mainColors.white};
  border-radius: 10px;
  padding: 0 5px;
`
