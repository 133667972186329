import styled from 'styled-components'

import { mainColors } from '../../styles/colors'
import { rgba } from '../../utils/cssUtils'
import { ImageZoomFrameProps, ImageZoomLocationProps } from './ImageZoom.types'

export const IMAGE_ZOOM_FRAME_SCALE = 5
export const IMAGE_ZOOM_FRAME_PADDING = 7
export const IMAGE_ZOOM_FRAME_MARGIN = 10

export const ImageZoomContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ImageZoomFrame = styled.div<ImageZoomFrameProps>`
  position: absolute;
  bottom: ${IMAGE_ZOOM_FRAME_MARGIN}px;
  width: ${({ width }) => width / IMAGE_ZOOM_FRAME_SCALE}px;
  height: ${({ height }) => height / IMAGE_ZOOM_FRAME_SCALE}px;
  background-color: ${rgba(mainColors.white, 0.5)};
  border: 1px solid ${mainColors.white};
  left: ${IMAGE_ZOOM_FRAME_MARGIN}px;
  border-radius: 2px;
  padding: ${IMAGE_ZOOM_FRAME_PADDING}px;
`

export const ImageZoomLocation = styled.div<ImageZoomLocationProps>`
  position: relative;
  display: inline-block;
  background-color: ${rgba(mainColors.accentMedium, 0.4)};
  border: 1px solid ${mainColors.accentMedium};
  width: ${({ scale }) => 100 / scale}%;
  height: ${({ scale }) => 100 / scale}%;
  left: ${({ positionX }) => positionX}%;
  top: ${({ positionY }) => positionY}%;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
`

export const ImageZoomController = styled.div`
  position: absolute;
  padding: ${IMAGE_ZOOM_FRAME_MARGIN}px;
  display: flex;
  width: 100% !important;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  gap: 10px;
`

export const Image = styled.img<{ isVisible: boolean }>`
  border-radius: 2px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`

export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
