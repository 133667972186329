import MaskedInput from 'react-text-mask'
import styled, { css } from 'styled-components'

import { HorizontalAlignment } from '../../enums'
import { _M_Normal, _S_Normal, InputReset, mainColors, semanticColors } from '../../styles'
import { TextFieldStyledProps } from './TextField.types'

/* !important are used here while migrating FormField in Modal */
export const textFieldStyles = css<TextFieldStyledProps>`
  ${InputReset};
  display: flex;
  width: 100%;
  border: none;
  background: transparent;
  color: ${mainColors.black} !important;
  outline: none;
  text-align: ${props =>
    props.tagAlign === HorizontalAlignment.Left
      ? HorizontalAlignment.Right
      : HorizontalAlignment.Left};
  ${_M_Normal};

  &::placeholder {
    color: ${mainColors.neutralExtraDark} !important;
  }

  &:disabled {
    -webkit-text-fill-color: ${mainColors.neutralExtremelyDark};
    background: transparent;
    cursor: not-allowed;
    pointer-events: none;
    color: ${mainColors.neutralExtremelyDark} !important;
  }

  ${props =>
    props.small &&
    `
    ${_S_Normal};
    font-size: 13px !important;
  `}

  ${props =>
    props.tiny &&
    `
    ${_S_Normal};
  `}

  ${props =>
    props.readOnly &&
    `
    color: ${mainColors.black} !important;
    cursor: not-allowed;
  `}
`

const getTextFieldInputPadding = (props: TextFieldStyledProps) => {
  if (props.hasElementOnLeft) return '4px 14px 4px 4px'
  if (props.small || props.tiny) return '2px 10px 2px 2px'
  return '14px'
}

export const TextFieldInputContainer = styled.div<TextFieldStyledProps>`
  position: relative;
  display: flex;
  align-items: center;

  > svg {
    flex-shrink: 0;
    flex-grow: 0;
  }

  width: 100%;
  height: 48px;
  min-height: 48px;
  padding: ${props => getTextFieldInputPadding(props)};
  background-color: white;
  border: 2px solid ${mainColors.neutralMedium};
  border-radius: 2px;

  ${props =>
    !props.readOnly &&
    `
    &:hover {
      border-color: ${mainColors.neutralDark};
    }
  `}

  ${props =>
    !props.readOnly &&
    props.focus &&
    `
    border-color: ${mainColors.accentMedium};

    &:hover {
    border-color: ${mainColors.accentMedium};
  }
  `}

  ${props =>
    props.disabled &&
    `
    border-color: ${mainColors.neutralMedium};
    background-color: ${mainColors.neutralExtraLight};
    cursor: not-allowed;
    pointer-events: none;

    &::placeholder {
      color: ${mainColors.neutralExtraDark};
    }
  `}

  ${props =>
    props.small &&
    `
    min-height: 36px;
    height: 36px;
  `}


${props =>
    props.tiny &&
    `
    min-height: 32px;
    height: 32px;
  `}

  ${props =>
    props.readOnly &&
    `
    background-color: ${mainColors.neutralLight};
    border-color: transparent;
    cursor: not-allowed;
  `}

  ${props =>
    props.error &&
    `
    border-color: ${semanticColors.failureMedium};

    &:hover {
      border-color: ${semanticColors.failureMedium};
    }
  `}

  ${props =>
    props.warning &&
    `
    border-color: ${semanticColors.warningMedium};

    &:hover {
      border-color: ${semanticColors.warningMedium};
    }
  `}
`

export const InputBeforeAfter = styled.span`
  ${_S_Normal};
  display: flex;
  align-items: center;
  color: ${mainColors.neutralExtremelyDark};
  margin-right: 16px;
  min-width: 48px;
  width: 100%;
  white-space: nowrap;
`

interface InputIconContainerProps {
  hasElementOnLeft?: boolean
  inputLeftIconWidth?: string
}

export const InputIconContainer = styled.div<InputIconContainerProps>`
  display: flex;
  ${({ hasElementOnLeft }) =>
    hasElementOnLeft &&
    css`
      margin-right: 8px;
    `}

  svg {
    color: ${mainColors.neutralExtraDark};
    cursor: pointer;
  }

  &:not(:first-of-type) {
    margin-right: 6px;
  }

  ${({ inputLeftIconWidth }) =>
    inputLeftIconWidth &&
    css`
      width: ${inputLeftIconWidth};
    `}
`

export const TextFieldIssueContainer = styled.div<TextFieldStyledProps>`
  height: ${props => (props.small ? '36px' : '48px')};
  display: flex;
  align-items: center;

  ${({ type }) =>
    type === 'date' &&
    `
      position: absolute;
      right: 2px;
      top: 11px;`};

  ${({ tagAlign }) =>
    tagAlign === HorizontalAlignment.Left &&
    `
      position: absolute;
      left: 5px;
  `};

  svg {
    ${({ hideErrorIcon }) => hideErrorIcon && 'opacity: 0'};
  }
`

export const TextFieldDefault = styled.input<TextFieldStyledProps>`
  ${textFieldStyles};
  min-width: ${props => `${props.width}px`};
`

export const TagContainer = styled.div<TextFieldStyledProps>`
  margin-left: ${props => (props.tagAlign === HorizontalAlignment.Left ? 0 : '10px')};
`

export const TextFieldWithMask = styled(MaskedInput)`
  ${textFieldStyles};
`

export const HelpTag = styled.div`
  ${_S_Normal};
  border-radius: 2px;
  background: ${mainColors.neutralLight};
  color: ${mainColors.neutralExtremelyDark};
  padding: 8px 14px;
  margin: 8px 0;
  width: 100%;
`

export const InfoMessage = styled.div<{ $hasError?: boolean; $hasWarning?: boolean }>`
  ${_S_Normal};
  margin-top: 4px;
  width: 100%;
  text-align: left;

  color: ${({ $hasError, $hasWarning }) => {
    if ($hasError) {
      return semanticColors.failureMedium
    }
    if ($hasWarning) {
      return semanticColors.warningDark
    }
    return mainColors.neutralExtraDark
  }};
`
