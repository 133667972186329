import 'react-virtualized/styles.css'
import styled from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _M_Normal } from '../../styles/typography'
import { ColumnBorderOptions, GridRowProps } from './AutoSizeGrid.types'

export const GridContainer = styled.div`
  position: relative;
  .gridBottomLeft,
  .gridTopRight {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .gridBottomLeft::-webkit-scrollbar,
  .gridTopRight::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }

  .ReactVirtualized__Grid:focus {
    outline: none;
  }
`

export const EmptyResultsContainer = styled.div<{ height: number; width: number }>`
  height: ${({ height }) => `calc(${height}px - 60px)`};
  width: ${({ width }) => `${width}px;`};
  min-height: 150px;

  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 61px;
`

export const GridCell = styled.div<GridRowProps>`
  ${_M_Normal};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ isFirstColumn }) => (isFirstColumn ? '30px' : '15px')};
  padding-right: ${({ isLastColumn }) => (isLastColumn ? '30px' : '15px')};
  background-color: ${({ background }) => background};
  border-right: ${({ borderStyle, fixedColumn }) =>
    borderStyle === ColumnBorderOptions.right || fixedColumn
      ? `1px solid ${mainColors.neutralMedium}`
      : 'none'};
  border-bottom: ${({ withSeparator }) =>
    withSeparator ? `1px solid ${mainColors.neutralMedium}` : 'none'};
  ${({ borderStyle }) =>
    borderStyle === ColumnBorderOptions.left &&
    `border-left: 1px solid ${mainColors.neutralMedium}`};

  &.hoveredItem {
    background-color: ${mainColors.neutralLight};
    cursor: pointer;
  }

  &.renderBottomBorder {
    border-bottom: 1px solid ${mainColors.neutralMedium};
  }

  ${({ styleByRow }) => styleByRow}
`

export const BelowHeaderContainer = styled.div`
  min-height: 30px;
  width: 100%;
  background-color: ${mainColors.neutralLight};
  display: flex;
  align-items: center;
  padding-left: 30px;
  border-top: 1px solid ${mainColors.neutralMedium};
  position: absolute;
  bottom: 0;
  left: 0;
`

export const Chevron = styled.div`
  color: ${mainColors.neutralExtraDark};
  cursor: pointer;
`
