import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconEllipse = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props} ref={ref}>
      <circle cx="7" cy="7" r="7" fill="currentColor" />
    </svg>
  )
}

export const IconEllipse = forwardRef(_IconEllipse)
