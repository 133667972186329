import { ButtonHTMLAttributes, ReactNode } from 'react'

export enum sizeTypes {
  large = 'LARGE',
  medium = 'MEDIUM',
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: sizeTypes
  children: ReactNode
  loading?: boolean
  isLoadingStateAbsolute?: boolean
}

export interface ButtonStyledProps {
  auto?: boolean
  size?: sizeTypes
  isLoading?: boolean
  active?: boolean
  disabled?: boolean
}

export enum ButtonType {
  Default = 'Default',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
  Danger = 'Danger',
  Success = 'Success',
  Neutral = 'Neutral',
  Flat = 'Flat',
  FlatNeutral = 'FlatNeutral',
  FlatDanger = 'FlatDanger',
  More = 'More',
  Dropdown = 'Dropdown',
  RoundedOutline = 'RoundedOutline',
}
