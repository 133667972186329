import React, { Ref } from 'react'

function _IconClear(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6654 8.00004C14.6654 11.6819 11.6806 14.6667 7.9987 14.6667C4.3168 14.6667 1.33203 11.6819 1.33203 8.00004C1.33203 4.31814 4.3168 1.33337 7.9987 1.33337C11.6806 1.33337 14.6654 4.31814 14.6654 8.00004ZM10.8243 5.17574C11.0586 5.41005 11.0586 5.78995 10.8243 6.02426L8.84853 8L10.8243 9.97574C11.0586 10.2101 11.0586 10.5899 10.8243 10.8243C10.5899 11.0586 10.2101 11.0586 9.97574 10.8243L8 8.84853L6.02426 10.8243C5.78995 11.0586 5.41005 11.0586 5.17574 10.8243C4.94142 10.5899 4.94142 10.2101 5.17574 9.97574L7.15147 8L5.17574 6.02426C4.94142 5.78995 4.94142 5.41005 5.17574 5.17574C5.41005 4.94142 5.78995 4.94142 6.02426 5.17574L8 7.15147L9.97574 5.17574C10.2101 4.94142 10.5899 4.94142 10.8243 5.17574Z"
        fill="#C0C9CE"
      />
    </svg>
  )
}

export const IconClear = React.forwardRef(_IconClear)
