import React from 'react'

import { IconInfo } from '@avantstay/backoffice-vectors'

import { InfoTooltip, mainColors, Tooltip } from '../../'
import { RadioFieldProps, RadioGroupProps } from './_types'
import * as S from './RadioField.styles'

// Keep this export clean, free of wrapping HOCs
function RadioField<T extends string>({
  id,
  className,
  label,
  value,
  disabled,
  title,
  titlePrefix,
  subtitle,
  extra,
  checked,
  error,
  onChange,
}: RadioFieldProps<T>) {
  return (
    <S.Container hasSubtitle={Boolean(subtitle)} className={className}>
      <S.RadioHiddenInput
        id={id}
        type="radio"
        disabled={disabled}
        name={label}
        onChange={onChange}
        checked={checked}
        value={value}
        data-extra={extra}
        error={error}
      />
      <S.RadioLabel htmlFor={id} disabled={disabled}>
        <S.Checkbox error={error} />
        <S.TitleColumn>
          <S.Title>
            {titlePrefix}
            {title}
          </S.Title>
          {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
        </S.TitleColumn>
      </S.RadioLabel>
    </S.Container>
  )
}

function RadioGroup<T extends string>({
  className,
  options,
  label,
  value,
  onChange,
  customTooltipContent,
  disabled,
  error,
}: RadioGroupProps<T>) {
  return (
    <S.GroupContainer className={className}>
      {options.map(option => (
        <S.FlexContainer key={option.title}>
          <Tooltip
            disabled={!customTooltipContent}
            content={customTooltipContent?.[option.title]}
            placement="top"
          >
            <S.FlexContainer>
              <RadioField
                id={option.id}
                title={option.title}
                subtitle={option.subtitle}
                value={option.value}
                label={label}
                className={option.className}
                onChange={onChange}
                checked={option.value === value}
                extra={option.extra}
                disabled={disabled || option.disabled}
                error={!!error}
              />
              {customTooltipContent && (
                <S.IconContainer>
                  <IconInfo color={mainColors.neutralExtremelyDark} />
                </S.IconContainer>
              )}
            </S.FlexContainer>
          </Tooltip>
        </S.FlexContainer>
      ))}
      {error ? <InfoTooltip error={error} placement="right" /> : null}
    </S.GroupContainer>
  )
}

RadioField.Group = RadioGroup
export { RadioField }
