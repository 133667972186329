import arriereBackoffice, {
  PayPeriodSorting,
  PayPeriodStatus,
  SortOrder,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { UpdateExpenseStatusProps } from '@new/domains/operations/screens/RecurringExpenses/RecurringExpenses.types'

interface SearchPayPeriodsFilterProps {
  pagination: { size: number; page: number }
  sortBy?: PayPeriodSorting
  sortOrder?: SortOrder
  vendorTypesSfdc: string[]
  regionIds: string[]
  vendorIds: string[]
  payPeriodStatuses: PayPeriodStatus[]
  vendorSearchTerm: string
  periodRange?: { start: string; end: string }
}

export const searchPayPeriods = async (args: SearchPayPeriodsFilterProps) => {
  const response = await arriereBackoffice.queries.payPeriods({
    __alias: 'searchPayPeriods',
    searchPayPeriods: {
      __args: args,
      pages: true,
      total: true,
      results: {
        payPeriod: {
          id: true,
          status: true,
          vendorId: true,
          period: {
            start: true,
            end: true,
          },
          reviewedAt: true,
          reviewedByBackofficeUserId: true,
          reviewedByName: true,
        },
        vendorName: true,
        vendorTypesSfdc: true,
        approvedExpenses: true,
        allCases: true,
        adHocCases: true,
        recurringAmountInCents: true,
        adHocAmountInCents: true,
        totalAmountInCents: true,
      },
    },
  })

  return response.searchPayPeriods
}

export const getExpenseDetails = async (id: string) => {
  const response = await arriereBackoffice.queries.payPeriods({
    __alias: 'getPayPeriodReport',
    getPayPeriodReport: {
      __args: { payPeriodId: id },
      payPeriod: {
        id: true,
        status: true,
        vendorId: true,
        period: {
          start: true,
          end: true,
        },
        reviewedAt: true,
        reviewedByName: true,
        reviewedByBackofficeUserId: true,
      },
      expectedRecurringCostInCents: true,
      recurringTotalCostInCents: true,
      adHocTotalCostInCents: true,
      totalPeriodCostInCents: true,
      vendor: {
        id: true,
        name: true,
        email: true,
        phone: true,
      },
      caseInfos: {
        caseBasic: {
          workOrderId: true,
          subject: true,
          category: true,
          dueDate: true,
          recurringCaseSettingId: true,
          home: {
            id: true,
            name: true,
            region: {
              id: true,
              name: true,
            },
          },
        },
        expenses: {
          id: true,
          workOrderId: true,
          totalAmountInCents: true,
          transactionDate: true,
          isWithoutVendorRate: true,
          isApproved: true,
          isRejected: true,
          rejectionReason: true,
        },
        skippedVisit: {
          comment: true,
          reason: true,
        },
        costInCents: true,
        imagesUrls: true,
        sectionType: true,
        statusType: true,
        isCaseSkippedWithoutAnyReason: true,
      },
    },
  })

  return response.getPayPeriodReport
}

export const searchVendors = async () => {
  const response = await arriereBackoffice.queries.payPeriods({
    __alias: 'payPeriodsVendors',
    payPeriodsVendors: {
      id: true,
      name: true,
    },
  })

  return response.payPeriodsVendors
}

export const getVendorsTypes = async () => {
  const response = await arriereBackoffice.queries.vendors({
    __alias: 'getAllVendorTypeLabels',
    getAllVendorTypeLabels: true,
  })

  return response.getAllVendorTypeLabels
}

export const updateExpenseStatus = async (data: UpdateExpenseStatusProps) => {
  const response = await arriereBackoffice.mutations.expenses({
    __alias: 'updateCaseExpensesStatuses',
    updateCaseExpensesStatuses: {
      __args: data,
    },
  })

  return response.updateCaseExpensesStatuses
}

export const reviewPayPeriod = async (payPeriodId: string) => {
  const response = await arriereBackoffice.mutations.payPeriods({
    __alias: 'reviewPayPeriod',
    reviewPayPeriod: {
      __args: { payPeriodId },
    },
  })
  return response.reviewPayPeriod
}
