import { mainColors, semanticColors } from '../../styles/colors'

const MAX_PERCENTAGE_VALUE = 100
const MIN_PERCENTAGE_VALUE = 10

export function getPercentageOf(currentValue: number, maxValue: number) {
  const result = (currentValue * MAX_PERCENTAGE_VALUE) / maxValue
  return result > MAX_PERCENTAGE_VALUE
    ? MAX_PERCENTAGE_VALUE
    : Math.max(result, MIN_PERCENTAGE_VALUE)
}

export const getRatingColor = (rating?: number) => {
  if (rating === undefined || rating === null) {
    return mainColors.neutralDark
  }

  switch (true) {
    case rating >= 4.8:
      return semanticColors.successMedium
    case rating < 4.8 && rating >= 4.7:
      return semanticColors.warningMedium
    case rating < 4.7 && rating >= 0:
      return semanticColors.failureMedium
    default:
      return mainColors.neutralDark
  }
}

export const getNPSRatingColor = (rating?: number) => {
  if (!rating) {
    return mainColors.neutralDark
  }
  
  switch (true) {
    case rating > 8:
      return semanticColors.successMedium
    case rating <= 8 && rating > 6:
      return semanticColors.warningMedium
    case rating <= 6:
      return semanticColors.failureMedium
    default:
      return mainColors.neutralDark
  }
}

export const getRatingPercentageColor = (rating: number) => {
  switch (true) {
    case rating >= 75:
      return semanticColors.successMedium
    case rating < 75 && rating >= 65:
      return semanticColors.warningMedium
    case rating < 65:
      return semanticColors.failureMedium
    default:
      return mainColors.neutralDark
  }
}
