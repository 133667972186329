import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconComment = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} ref={ref}>
    <path
      d="M18.125 12.7083C18.125 13.1872 17.9348 13.6464 17.5962 13.9851C17.2576 14.3237 16.7983 14.5139 16.3194 14.5139H5.48611L1.875 18.125V3.68056C1.875 3.20169 2.06523 2.74244 2.40383 2.40383C2.74244 2.06523 3.20169 1.875 3.68056 1.875H16.3194C16.7983 1.875 17.2576 2.06523 17.5962 2.40383C17.9348 2.74244 18.125 3.20169 18.125 3.68056V12.7083Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IconComment = forwardRef(_IconComment)
