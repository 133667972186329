import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { FloatingContainer as FloatingContainerComponent } from '@avantstay/avantstay-ui'

import { mainColors, shadowColors } from '../../../styles/colors'
import { _3XS_Medium } from '../../../styles/typography'
import { rgba } from '../../../utils/cssUtils'

export const FilterContainer = styled.div`
  position: relative;
  display: flex;
  height: 24px;
  width: 20px;
  align-items: center;
`
export const IconWrapper = styled.div<{ isFilterActive?: boolean }>`
  cursor: pointer;

  svg {
    color: ${({ isFilterActive }) =>
      isFilterActive ? mainColors.accentMedium : mainColors.neutralDark};

    &:hover {
      color: ${({ isFilterActive }) =>
        isFilterActive ? mainColors.accentMedium : mainColors.neutralExtremelyDark};
    }
  }
`

export const ActiveFilterIndicator = styled.div`
  ${_3XS_Medium};
  color: ${mainColors.white};
  background-color: ${mainColors.accentMedium};
  border: 2px solid ${mainColors.white};
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 4px;
  position: absolute;
  top: -2px;
  left: 7px;
`

export interface FilterPositionProps {
  right?: number
  left?: number
  hideFloatingContainer?: boolean
}

export const FloatingContainer = styled(FloatingContainerComponent)<
  PropsWithChildren<FilterPositionProps>
>`
  max-width: 280px;
  min-width: 175px;
  padding-top: 18px;
  padding-left: 15px;
  padding-bottom: 10px;
  padding-right: 15px;
  background: ${mainColors.white};
  border: 1px solid ${mainColors.neutralMedium};
  box-sizing: border-box;
  box-shadow: 0 4px 16px ${rgba(shadowColors.blur, 0.4)};
  border-radius: 2px;
  margin-top: 30px;
  transform: translateX(60px);
  margin-right: ${({ right }) => `${right}px`};
  margin-left: ${({ left }) => `${left}px`};

  ${({ hideFloatingContainer }) =>
    hideFloatingContainer &&
    `
    width: 0;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    margin-top: 25px;
  `};
`
