import React, { FC } from 'react'

import * as S from './ProgressBar.styles'
import { ProgressBarProps } from './ProgressBar.types'

const dotsArray = new Array(5).fill(0)
// 100 percentage  / 5 points
const dotValue = 20

export const ProgressBar: FC<ProgressBarProps> = ({
  color,
  percentage,
  height,
  type = 'default',
  isShadowBlurVisible = true,
  className,
}) => {
  if (type === 'default' || type === 'full') {
    return (
      <S.ProgressBarContainer
        className={className}
        height={height}
        type={type}
        color={color}
        percentage={percentage}
        isShadowBlurVisible={isShadowBlurVisible}
      />
    )
  }

  const coloredDots = Math.floor(percentage / dotValue) - 1

  return (
    <S.DotsRoot className={className}>
      {dotsArray.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <S.Dot key={`colorPoint-${index}`} color={index <= coloredDots ? color : undefined} />
      ))}
    </S.DotsRoot>
  )
}
