import { createGlobalStyle, css } from 'styled-components'
import WebFont from 'webfontloader'

import { isMacOS } from '../utils'
import { mainColors } from './colors'
import { rsuite } from './rsuite'
import { _L_Normal } from './typography'

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700'],
  },
})

// If you need to change this file, consider using local globalStyles file dedicated to package you are working on
// Changes made here will impact multiple packages (app) using it (lighthouse, core in storybook)

const scrollbarStyle = css`
  scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.3);
  scrollbar-width: thin;

  *::-webkit-scrollbar {
    width: 7px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
  }

  *::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
  }
`

export const DefaultGlobalStyle = createGlobalStyle`
  ${rsuite}

  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent !important;
    margin: 0;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
  }

  body {
    background-color: ${mainColors.neutralExtraLight};
  }

  body,
  button,
  input,
  textarea {
    ${_L_Normal};
    font-family: 'Roboto', sans-serif;
  }

  ${!isMacOS ? scrollbarStyle : ''}
`
