import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconPin(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3932 7.35755L16.6435 2.60792C16.199 2.16339 15.5768 1.94458 14.9519 2.01309C14.3269 2.0816 13.7667 2.43022 13.4291 2.96052L10.3632 7.77892C8.80654 7.54804 7.21047 7.85039 5.83943 8.64732L5.83644 8.64908C5.28235 8.97417 4.91031 9.5378 4.82916 10.1751C4.74803 10.8122 4.96718 11.4513 5.42187 11.9048L8.15445 14.6374L2.25121 20.5406C1.91789 20.874 1.91789 21.4144 2.25121 21.7477C2.58453 22.081 3.12495 22.081 3.45827 21.7477L9.36152 15.8445L12.0967 18.5796C12.5502 19.0343 13.1889 19.253 13.826 19.1719C14.4632 19.0907 15.0269 18.7187 15.352 18.1646L15.3537 18.1616C16.1507 16.7906 16.453 15.1945 16.2221 13.6378L21.0398 10.5719C21.5702 10.2345 21.9188 9.67461 21.9876 9.04975C22.0563 8.42482 21.8376 7.80225 21.3932 7.35755ZM14.8508 3.76367C14.9534 3.60734 15.1714 3.58352 15.3057 3.71357L20.1861 8.56461C20.2644 8.64308 20.3029 8.75282 20.2908 8.863C20.2786 8.97328 20.2169 9.07222 20.1233 9.13176L14.816 12.5093C14.5084 12.705 14.3591 13.0746 14.4444 13.429C14.761 14.7447 14.5585 16.1317 13.879 17.3019C13.8215 17.3991 13.7224 17.4643 13.6103 17.4785C13.4979 17.4928 13.3851 17.4542 13.3051 17.3739L6.62803 10.6969L6.62713 10.696C6.54684 10.6159 6.50821 10.5032 6.52253 10.3907C6.5368 10.2787 6.602 10.1795 6.69916 10.1221C7.86932 9.44255 9.25635 9.24002 10.572 9.55664C10.9265 9.64196 11.2961 9.49264 11.4918 9.18501C11.4918 9.18501 13.4357 5.91998 14.8508 3.76367Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconPin = forwardRef(_IconPin)
