import React from 'react'

function _IconArchive(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0500488 1.25005C0.0500488 0.863449 0.363449 0.550049 0.750049 0.550049H17.25C17.6366 0.550049 17.9501 0.863449 17.9501 1.25005V5.00005C17.9501 5.38665 17.6366 5.70005 17.25 5.70005H16.45V14.75C16.45 15.1366 16.1366 15.45 15.75 15.45H2.25005C1.86345 15.45 1.55005 15.1366 1.55005 14.75V5.70005H0.750049C0.363449 5.70005 0.0500488 5.38665 0.0500488 5.00005V1.25005ZM15.05 14.05V5.70005H2.95005V14.05H15.05ZM16.55 4.30005H15.75H2.25005H1.45005V1.95005H16.55V4.30005ZM7.50005 7.30005C7.11345 7.30005 6.80005 7.61345 6.80005 8.00005C6.80005 8.38665 7.11345 8.70005 7.50005 8.70005H10.5C10.8866 8.70005 11.2 8.38665 11.2 8.00005C11.2 7.61345 10.8866 7.30005 10.5 7.30005H7.50005Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconArchive = React.memo(React.forwardRef(_IconArchive))

export { IconArchive }
