import { computed } from 'mobx'
import { getValue } from 'mobx-async'

import { BackOfficePrivilegeName } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { arriere } from '@arriere/index'

class _AuthStore {
  @computed({ keepAlive: true }) get __ASYNC_userPermissions(): Promise<
    | {
        userPermissions?: BackOfficePrivilegeName[]
        voyageId?: string
      }
    | undefined
  > {
    return arriere.auth.getUserPermissions()
  }

  @computed get userPermissions() {
    return getValue(this.__ASYNC_userPermissions)?.userPermissions
  }

  hasUserPermission(privilegeName: BackOfficePrivilegeName): boolean {
    return this.userPermissions?.includes(privilegeName) ?? false
  }
}

export const AuthStore = new _AuthStore()
