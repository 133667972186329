import React, { Ref } from 'react'

function _IconListings(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.69678C10.6538 2.69678 9.5625 3.78808 9.5625 5.13428C9.5625 5.68656 9.11478 6.13428 8.5625 6.13428H5.125C4.91789 6.13428 4.75 6.30217 4.75 6.50928V20.9468C4.75 21.1539 4.91789 21.3218 5.125 21.3218H18.875C19.0821 21.3218 19.25 21.1539 19.25 20.9468V6.50928C19.25 6.30217 19.0821 6.13428 18.875 6.13428H15.4375C14.8852 6.13428 14.4375 5.68656 14.4375 5.13428C14.4375 3.78808 13.3462 2.69678 12 2.69678ZM7.67563 4.13428C8.12916 2.1651 9.89311 0.696777 12 0.696777C14.1069 0.696777 15.8708 2.1651 16.3244 4.13428H18.875C20.1867 4.13428 21.25 5.1976 21.25 6.50928V20.9468C21.25 22.2585 20.1867 23.3218 18.875 23.3218H5.125C3.81332 23.3218 2.75 22.2585 2.75 20.9468V6.50928C2.75 5.1976 3.81332 4.13428 5.125 4.13428H7.67563ZM6.875 10.6343C6.875 10.082 7.32272 9.63425 7.875 9.63425H16.125C16.6773 9.63425 17.125 10.082 17.125 10.6343C17.125 11.1865 16.6773 11.6343 16.125 11.6343H7.875C7.32272 11.6343 6.875 11.1865 6.875 10.6343ZM6.875 14.0718C6.875 13.5195 7.32272 13.0718 7.875 13.0718H16.125C16.6773 13.0718 17.125 13.5195 17.125 14.0718C17.125 14.6241 16.6773 15.0718 16.125 15.0718H7.875C7.32272 15.0718 6.875 14.6241 6.875 14.0718ZM7.875 16.5093C7.32272 16.5093 6.875 16.957 6.875 17.5093C6.875 18.0616 7.32272 18.5093 7.875 18.5093H16.125C16.6773 18.5093 17.125 18.0616 17.125 17.5093C17.125 16.957 16.6773 16.5093 16.125 16.5093H7.875ZM12 7.00002C12.5523 7.00002 13 6.55231 13 6.00002C13 5.44774 12.5523 5.00002 12 5.00002C11.4477 5.00002 11 5.44774 11 6.00002C11 6.55231 11.4477 7.00002 12 7.00002Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconListings = React.forwardRef(_IconListings)
