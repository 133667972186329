import React, { Ref } from 'react'

function _IconPool(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 2.5C5.67157 2.5 5 3.17157 5 4V7V8V9V10V11C5 11.2761 5.22386 11.5 5.5 11.5C5.77614 11.5 6 11.2761 6 11V10H9V11C9 11.2761 9.22386 11.5 9.5 11.5C9.77614 11.5 10 11.2761 10 11V10V9V8V7V6V5V4C10 3.72386 10.2239 3.5 10.5 3.5C10.7761 3.5 11 3.72386 11 4C11 4.27614 11.2239 4.5 11.5 4.5C11.7761 4.5 12 4.27614 12 4C12 3.17157 11.3284 2.5 10.5 2.5C9.67157 2.5 9 3.17157 9 4V5H6V4C6 3.72386 6.22386 3.5 6.5 3.5C6.77614 3.5 7 3.72386 7 4C7 4.27614 7.22386 4.5 7.5 4.5C7.77614 4.5 8 4.27614 8 4C8 3.17157 7.32843 2.5 6.5 2.5ZM9 8V9H6V8H9ZM9 7V6H6V7H9ZM4.10238 12.9632C4.51491 12.7053 5.04185 12.7201 5.43928 13.0007L5.46803 13.0209C6.21418 13.5476 7.21107 13.5476 7.95721 13.0209C8.35763 12.7383 8.89261 12.7383 9.29303 13.0209L9.3557 13.0652C10.0581 13.561 10.9807 13.6168 11.7377 13.2091L12.2371 12.9402C12.4803 12.8093 12.5712 12.506 12.4403 12.2629C12.3094 12.0198 12.0061 11.9288 11.763 12.0597L11.2636 12.3287C10.8405 12.5565 10.325 12.5253 9.93238 12.2482L9.86971 12.204C9.12357 11.6773 8.12667 11.6773 7.38053 12.204C6.98011 12.4866 6.44513 12.4866 6.04471 12.204L6.01596 12.1837C5.28954 11.6709 4.32641 11.6439 3.57239 12.1152L2.73507 12.6385C2.5009 12.7848 2.42971 13.0933 2.57606 13.3275C2.72242 13.5616 3.03089 13.6328 3.26506 13.4865L4.10238 12.9632Z"
        fill="#94A4AD"
      />
    </svg>
  )
}

export const IconPool = React.forwardRef(_IconPool)
