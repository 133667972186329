import React, { MutableRefObject, useEffect, useRef, useState } from 'react'

import { Tooltip, TooltipTheme } from '../../components/Tooltip'
import * as S from './TextArea.styles'
import { TextAreaProps } from './TextArea.types'

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref): JSX.Element => {
  const {
    defaultValue,
    disabled,
    error,
    placeholder,
    readOnly,
    tag,
    value,
    warning,
    onChange,
    centralizeTag = false,
    ...restProps
  } = props

  const popperOptions = {
    modifiers: [{ name: 'flip', enabled: false }],
  }

  const [tagWidth, setTagWidth] = useState<undefined | number>()

  const tagRef = useRef() as MutableRefObject<HTMLDivElement>

  useEffect(() => {
    setTagWidth(tagRef?.current?.offsetWidth)
  }, [])

  // Tooltip doesn't display if no content is present, so if no warning or error text is found it will not display
  return (
    <Tooltip
      isVisible
      showOnCreate
      sticky
      arrow={false}
      content={error || warning}
      disabled={!error && !warning}
      offset={[0, 2]}
      placement="bottom-end"
      popperOptions={popperOptions}
      theme={error ? TooltipTheme.danger : TooltipTheme.alert}
      trigger="mouseenter click focus"
      interactive
    >
      <S.Container centralizeBadge={centralizeTag}>
        <S.TextAreaDefault
          ref={ref}
          placeholder={placeholder}
          error={error}
          warning={warning}
          onChange={onChange}
          disabled={disabled}
          value={value}
          defaultValue={defaultValue}
          readOnly={readOnly}
          withTag={Boolean(tag)}
          tagWidth={tagWidth}
          {...restProps}
        />
        {tag && <S.TagContainer ref={tagRef}>{tag}</S.TagContainer>}
        <S.IconResize />
      </S.Container>
    </Tooltip>
  )
})

export { TextArea }
