import React, { forwardRef, useEffect, useState } from 'react'

import * as S from './Collapse.styles'
import { CollapseProps, CollapseTheme } from './Collapse.types'

const Collapse = forwardRef<HTMLDivElement, CollapseProps>(
  (
    {
      className,
      title,
      titleContent,
      children,
      hasBorder,
      hasChevronAtStart,
      isOpen,
      onClick,
      iconSize,
      titleJustifyContent,
      theme = CollapseTheme.default,
      ...props
    },
    ref,
  ) => {
    const [isCollapseOpen, setIsCollapseOpen] = useState(isOpen)

    const handleOnClick = () =>
      onClick?.() || setIsCollapseOpen((prevState?: boolean) => !prevState)

    useEffect(() => {
      if (onClick) setIsCollapseOpen(isOpen)
    }, [isOpen])

    return (
      <S.Container
        ref={ref}
        className={className}
        hasBorder={hasBorder}
        hasChevronAtStart={hasChevronAtStart}
        theme={theme}
        {...props}
      >
        <S.TitleGroup
          theme={theme}
          className={isCollapseOpen ? 'active' : ''}
          onClick={handleOnClick}
        >
          <S.Title
            className={className ? `${className}__title` : undefined}
            justifyContent={titleJustifyContent}
            iconSize={iconSize}
            isCollapseOpen={isCollapseOpen}
            hasChevronAtStart={hasChevronAtStart}
          >
            {title} <S.IconChevronDownStyled />
          </S.Title>{' '}
          {titleContent}
        </S.TitleGroup>

        {isCollapseOpen ? (
          <S.Content
            theme={theme}
            isContentVisible={isCollapseOpen}
            className={className ? `${className}__content` : undefined}
          >
            {children}
          </S.Content>
        ) : null}
      </S.Container>
    )
  },
)

export { Collapse }
