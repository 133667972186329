import React, { Ref } from 'react'

function _IconExternalLinks(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0004 1.3999C9.66902 1.3999 9.40039 1.66853 9.40039 1.9999C9.40039 2.33127 9.66902 2.5999 10.0004 2.5999H12.5517L6.24263 8.90897C6.00832 9.14329 6.00832 9.52319 6.24263 9.7575C6.47694 9.99181 6.85684 9.99181 7.09116 9.7575L13.4004 3.44827V5.9999C13.4004 6.33127 13.669 6.5999 14.0004 6.5999C14.3318 6.5999 14.6004 6.33127 14.6004 5.9999V1.9999C14.6004 1.66853 14.3316 1.3999 14.0002 1.3999H10.0004ZM3.33372 3.3999C2.82097 3.3999 2.32922 3.60359 1.96665 3.96616C1.60408 4.32873 1.40039 4.82048 1.40039 5.33324V12.6666C1.40039 13.1793 1.60408 13.6711 1.96665 14.0336C2.32922 14.3962 2.82097 14.5999 3.33372 14.5999H10.6671C11.1798 14.5999 11.6716 14.3962 12.0341 14.0336C12.3967 13.6711 12.6004 13.1793 12.6004 12.6666V8.66657C12.6004 8.3352 12.3318 8.06657 12.0004 8.06657C11.669 8.06657 11.4004 8.3352 11.4004 8.66657V12.6666C11.4004 12.8611 11.3231 13.0476 11.1856 13.1851C11.0481 13.3226 10.8615 13.3999 10.6671 13.3999H3.33372C3.13923 13.3999 2.95271 13.3226 2.81518 13.1851C2.67765 13.0476 2.60039 12.8611 2.60039 12.6666V5.33324C2.60039 5.13874 2.67765 4.95222 2.81518 4.81469C2.95271 4.67716 3.13923 4.5999 3.33372 4.5999H7.33372C7.6651 4.5999 7.93372 4.33127 7.93372 3.9999C7.93372 3.66853 7.6651 3.3999 7.33372 3.3999H3.33372Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconExternalLinks = React.forwardRef(_IconExternalLinks)
